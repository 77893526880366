import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {
  SparklineConfig,
  SparklineMarker,
  TimeSeries
} from 'src/app/shared/components/chartjs-components/models/sparkline-config';
import moment from 'moment';
import {SparklineConfigService} from 'src/app/shared/components/chartjs-components/services/sparkline-config.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {AnomalyCategory} from 'src/app/shared/models/anomalies.model';
import {ICellRendererParams} from 'ag-grid-community';
import {LegacySeverity} from 'src/app/shared/models/severity.model';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';


@Component({
  selector: 'app-sparkline-renderer',
  templateUrl: './sparkline-renderer.component.html',
  styleUrls: ['./sparkline-renderer.component.scss']
})
export class SparklineRendererComponent implements ICellRendererAngularComp {
  timeSeries: TimeSeries[];
  marker: SparklineMarker;
  sparkline: SparklineConfig;
  sparkelineHeight: number = 30;
  sparkelineWidth: number = 215;

  constructor(private sparklineConfig: SparklineConfigService, private kpiDataService: KpiDataService, private dateConverter: TimeManagerService) {
  }

  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    if (params && params.data) {
      if (params.data.timeSeries && params.data.timeSeries.length > 0) {
        this.setTimeSeries(params);
        this.setSparkline(params);
      }
      if (params.data.action && params.data.action.updatedAt) {
        this.setMarker(params);
      }
    }
  }

  /**
   * Initiate Marker param.
   * Check if the current action date is inside the time series span
   */
  setMarker(params: ICellRendererParams) {
    let actionDate = params.data.action.updatedAt;
    if (this.timeSeries && this.timeSeries.length > 0 && actionDate > this.timeSeries[0].date) {
      this.marker = {
        date: moment(actionDate),
        text: params.value + " " + "(" + this.dateConverter.dateByFormat(actionDate, 'DD-MMM-YYYY HH:mm') + ")"
      };
    }
  }

  /**
   * Set the sparkline param with configuration
   * Convert the time on time series to moment object
   * Add additional data according to data nature
   */
  setSparkline(params: ICellRendererParams) {
    this.sparkline = this.sparklineConfig.getTimeseriesConfig(this.getData(), this.getMarkerPointColor(params));
    let dataNature: AnomalyCategory = params.context.componentParent.data as AnomalyCategory;
    if (dataNature) {
      this.sparkline.datasets[0].data.forEach(item => {
        let itemDate = " " + "(" + this.dateConverter.dateByFormat(item.x, 'DD-MMM-YYYY HH:mm') + ")";
        switch (dataNature) {
          case AnomalyCategory.Latency:
            item.data = item.y.toFixed(2) + "ms" + itemDate;
            break;
          case AnomalyCategory.PacketLoss:
            item.data = item.y.toFixed(2) + "%" + itemDate;
            break;
          case AnomalyCategory.Traffic:
            let sizeAndUnit = this.kpiDataService.getBytesSizeAfterCalc(item.y);
            if (sizeAndUnit)
              item.data = sizeAndUnit.value.toFixed(2) + " " + sizeAndUnit.size + itemDate;
            break;
          default:
            break;
        }
      })
    }
  }

  /**
   * Set the makrer point color
   * For colors pallet @see 'src/assets/sass/netop/_vars.scss'
   * @param params grid Params
   */
  getMarkerPointColor(params: ICellRendererParams): string {
    let severity: string = params.data.action.severity;
    switch (LegacySeverity[severity]) {
      case LegacySeverity.Critical:
        return '#000000'
      case LegacySeverity.High:
        return '#ff8780'
      case LegacySeverity.Medium:
        return '#f39915'
      case LegacySeverity.Minor:
        return '#7dd090'
      default:
        break;
    }
  }

  /**
   * Set the time series param
   */
  setTimeSeries(params: ICellRendererParams) {
    this.timeSeries = params.data.timeSeries;
  }

  /**
   * Convert the data on the time series to the chart js time object {x, y} with moment object instead of date
   */
  getData(): { x: any, y: number }[] {
    return this.timeSeries.map(timePoint => {
      return {x: moment(timePoint.date), y: timePoint.value}
    });
  }

  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  refresh(params: any): boolean {
    throw new Error("Method not implemented.");
  }
}

