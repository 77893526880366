import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActionStatus } from 'src/app/shared/models/actions.model';
import {VerticalMenuOptionsModel} from "../../../../components/vertical-menu-options/vertical-menu-options.model";

@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss']
})
export class MenuOptionsComponent implements OnInit {
  @Input() nextPossibleSteps: Array<ActionStatus>;
  @Output() onActionSelected: EventEmitter<ActionStatus> = new EventEmitter();
  menuItems: Array<VerticalMenuOptionsModel<ActionStatus>>
  ActionStatus = ActionStatus;

  constructor() { }

  ngOnInit() {
    this.menuItems = this.nextPossibleSteps?.map(step => ({label: ActionStatus.getActionStatusName(step), item: step}));
  }

  onAction(step: ActionStatus) {
    this.onActionSelected.emit(step);
  }

}
