<ng-container *ngIf="showTeleport">
  <div *teleportTo="'.teleported-panel'">
    <ng-container *ngFor="let subnet of subnets">
      <div class="subnet-container">
        <mat-toolbar-row class="prop-toolbar-row policy-title">
          <div class="col prop-key-col">
            <strong *ngIf="subnet?.region"> Region: {{subnet.region}}, </strong>
            <strong> {{subnet.name}} </strong>
            <strong *ngIf="subnet?.description"> {{subnet.description}} </strong>
          </div>
        </mat-toolbar-row>

        <ng-container *ngIf="subnet?.primaryIpRange">
          <ng-container
            *ngTemplateOutlet="subnetDisplay;context:{subnets: [subnet?.primaryIpRange], title:'Primary IP Range:'}">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="subnet?.secondaryIpRanges?.length > 0">
          <ng-container
            *ngTemplateOutlet="subnetDisplay;context:{subnets: subnet?.secondaryIpRanges, title:'Secondary IP Range:'}">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #subnetDisplay let-subnets="subnets" let-title="title">
  <div *ngIf="subnets?.length > 0">
    <mat-toolbar-row class="prop-toolbar-row subnet-sub-title">
      <div class="col prop-key-col">
        {{title}}
      </div>
    </mat-toolbar-row>
    <ng-container *ngFor="let subnet of subnets">
      <mat-toolbar-row class="prop-toolbar-row" *ngIf="subnet?.ip" [title]="subnet?.ip">
        <div class="col prop-key-col">
          ip: {{subnet?.ip}}
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row class="prop-toolbar-row" *ngIf="subnet?.name" [title]="subnet?.name">
        <div class="col prop-key-col">
          name: {{subnet?.name}}
        </div>
      </mat-toolbar-row>
    </ng-container>
  </div>
</ng-template>
