<ng-container *ngIf="health == -1" [ngTemplateOutlet]="notCalculatedHealth">
</ng-container>
<ng-container *ngIf="health != -1" [ngTemplateOutlet]="calculatedHealth">
</ng-container>

<ng-template #calculatedHealth>
  <i *ngIf="warningTooltipText" class="health-bar-warning" [inlineSVG]="'assets/media/icons/svg/Code/Warning-2.svg'"
     #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltipClass="marker-tooltip" matTooltipShowDelay="1000"
     [matTooltip]="warningTooltipText"></i>

  <div class="progress health-progress">
    <div class="progress-bar" [ngClass]="severityBgColor" role="progressbar" [style.width.%]="healthPercent" aria-valuenow="healthPercent"
         aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <!-- if the healthNum is not eq to -1 the health ws already calculated -->
  <span class="health-num" [ngClass]="severityColor">
    <ng-container *ngIf="display == 'percent'">{{health}}%</ng-container>
    <span *ngIf="display == 'fraction'" fromTo [from]="completeHealth" [to]="health" [fromColor]="severityColor"
          [ngClass]="severityColor" [style.font-weight]="500"></span>
    <ng-content></ng-content>
  </span>
</ng-template>

<ng-template #notCalculatedHealth>
  <div class="progress health-progress health-progress-not-defined">
    <div class="progress-bar bg-health-not-defined" role="progressbar" [style.width.%]="'100'"
         aria-valuenow="healthPercent" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
  <mat-icon class="health-num health-not-defined" mat-list-icon svgIcon="clock"
            title="{{'data.COMMON.NOT_CALCULATED' | translate}}"></mat-icon>
</ng-template>
