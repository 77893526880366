import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bot-step',
  templateUrl: './bot-step.component.html',
  styleUrls: ['./bot-step.component.scss']
})
export class BotStepComponent implements OnInit {

  @Input() question: string;
  @Input() cssClass: string;
  @Input() stepQuestionContainerClass = 'col-md-5';
  @Input() stepContentContainerClass: string = 'col-md-5';

  constructor() { }

  ngOnInit(): void {
  }

}
