import {Type} from '@angular/core';
import {ActionCategory, EntityAction} from './actions.model';
import {DeviceStatus} from './devices.model';
import {TimeSeries} from '../components/chartjs-components/models/sparkline-config';
import {VPNConnectionStatusTrendDTO} from './vpn.model';
import {LegacySeverity} from './severity.model';
import {Entity} from "./entity.model";
import {FromToDatesStrings} from "./time.model";
import {Colors} from "../../config/colors";
import {ColorsUtils} from "../global-utils/colors-utils";

export class AnomalySummary {
  category: string;
  value: number;
}

export enum AnomalySummaryGrouping {
  Category = "Category",
  Type = "Type",
  Severity = "Severity"
}
export namespace AnomalySummaryGrouping {
  export function toList(): AnomalySummaryGrouping[] {
    return [
      AnomalySummaryGrouping.Category,
      AnomalySummaryGrouping.Type,
      AnomalySummaryGrouping.Severity
    ];
  }

  export function getColor(grouping: AnomalySummaryGrouping, group: string): string {
    switch (grouping) {
      case AnomalySummaryGrouping.Category:
        return ActionCategory.getColor(group as ActionCategory);
      case AnomalySummaryGrouping.Type:
        return AnomalyCategory.getColor(group as AnomalyCategory);
      case AnomalySummaryGrouping.Severity:
        return LegacySeverity.getColor(group);
      default:
        return undefined;
    }
  }
}

export class AnomalySummaryByCategory {
  anomalyCategory: AnomalyCategory
  total: number;
  active: number;
  pastAnomaliesTotal?: number;
}

export class AnomalySummaryByEntity {
  entity: Entity;
  anomalySummary: AnomalySummaryByCategory[];
}

export enum AnomalyCategory {
  Devices = "Devices",
  PacketLoss = "PacketLoss",
  Traffic = "Traffic",
  Latency = "Latency",
  VpnStatus = "VpnStatus",
  VpnTraffic = "VpnTraffic",
  VpnMetric = "VpnMetric",
  VpnLatency = "VpnLatency",
  VpnLoss = "VpnLoss",
  VpnJitter = "VpnJitter",
  VpnMos = "VpnMos",
  UsersNumber = "UsersNumber",
  ConnectionFailures = "ConnectionFailures",
  Throughput = "Throughput",
  FabricAnomaly = "FabricAnomaly",
  Cpu = "Cpu",
  Memory = "Memory",
  InterfaceDown = "InterfaceDown",
  SlaQoe = "SlaQoe",
  Integrations = "Integrations",
  InterfaceErrorsRate = "InterfaceErrorsRate"
}

export namespace AnomalyCategory {
  export function anomaliesCategoryArray() {
    return [
      AnomalyCategory.Devices,
      AnomalyCategory.Traffic,
      AnomalyCategory.PacketLoss,
      AnomalyCategory.Latency,
      AnomalyCategory.VpnStatus,
      AnomalyCategory.VpnTraffic,
      AnomalyCategory.VpnLatency,
      AnomalyCategory.VpnLoss,
      AnomalyCategory.VpnJitter,
      AnomalyCategory.VpnMos,
      AnomalyCategory.UsersNumber,
      AnomalyCategory.ConnectionFailures,
      AnomalyCategory.Throughput,
      AnomalyCategory.FabricAnomaly,
      AnomalyCategory.Cpu,
      AnomalyCategory.Memory,
      AnomalyCategory.InterfaceErrorsRate,
      AnomalyCategory.InterfaceDown,
      AnomalyCategory.SlaQoe,
      AnomalyCategory.Integrations
    ]
  }

  export function anomalyAsString(category: string): string {
    let anomalyStrings = {
      [AnomalyCategory.PacketLoss]: 'Loss',
      [AnomalyCategory.Devices]: 'Devices',
      [AnomalyCategory.Traffic]: 'Traffic',
      [AnomalyCategory.Latency]: 'Latency',
      [AnomalyCategory.VpnStatus]: 'Vpn Status',
      [AnomalyCategory.VpnTraffic]: 'Vpn Traffic',
      [AnomalyCategory.VpnMetric]: 'Vpn Metric',
      [AnomalyCategory.VpnLatency]: 'Vpn Latency',
      [AnomalyCategory.VpnLoss]: 'Vpn Loss',
      [AnomalyCategory.VpnJitter]: 'Vpn Jitter',
      [AnomalyCategory.VpnMos]: 'Vpn Mos',
      [AnomalyCategory.UsersNumber]: 'Clients',
      [AnomalyCategory.ConnectionFailures]: 'Connection Failures',
      [AnomalyCategory.Throughput]: 'Throughput',
      [AnomalyCategory.FabricAnomaly]: 'Fabric Anomaly',
      [AnomalyCategory.Cpu]: 'Cpu',
      [AnomalyCategory.Memory]: 'Memory',
      [AnomalyCategory.InterfaceDown]: 'Interface down',
      [AnomalyCategory.SlaQoe]: 'SLA Qoe',
      [AnomalyCategory.Integrations]: 'Integrations',
      [AnomalyCategory.InterfaceErrorsRate]: 'Port Errors'
    };
    return anomalyStrings[category];
  }

  export function getColor(category: AnomalyCategory): string {
    switch (category) {
      case AnomalyCategory.Devices:
        return Colors.Highcharts.PURPLE;
      case AnomalyCategory.Traffic:
        return Colors.Highcharts.SKY_BLUE;
      case AnomalyCategory.PacketLoss:
        return Colors.Highcharts.LIGHT_GREEN;
      case AnomalyCategory.Latency:
        return Colors.Highcharts.ORANGE;
      case AnomalyCategory.VpnStatus:
        return Colors.Highcharts.RED;
      case AnomalyCategory.VpnTraffic:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.9);
      case AnomalyCategory.VpnMetric:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.8);
      case AnomalyCategory.VpnLatency:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.7);
      case AnomalyCategory.VpnLoss:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.6);
      case AnomalyCategory.VpnJitter:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.5);
      case AnomalyCategory.VpnMos:
        return ColorsUtils.hexToRgb(Colors.Highcharts.RED, 0.4);
      case AnomalyCategory.UsersNumber:
        return Colors.Highcharts.BLUE_GREY;
      case AnomalyCategory.ConnectionFailures:
        return Colors.Highcharts.PINK;
      case AnomalyCategory.Throughput:
        return Colors.Highcharts.LIGHT_BLUE;
      case AnomalyCategory.FabricAnomaly:
        return Colors.Highcharts.TAN;
      case AnomalyCategory.Cpu:
        return Colors.Highcharts.LIGHT_BLUE_2;
      case AnomalyCategory.Memory:
        return Colors.Highcharts.BLACK;
      case AnomalyCategory.InterfaceDown:
        return ColorsUtils.hexToRgb(Colors.Highcharts.BLACK, 0.5);
      case AnomalyCategory.SlaQoe:
        return Colors.Highcharts.GREY;
      case AnomalyCategory.Integrations:
        return ColorsUtils.hexToRgb(Colors.Highcharts.BLUE_GREY, 0.6);
      default:
        return undefined;
    }
  }
}

export type AnomalyCategoryName = { [key in keyof AnomalyCategory]: any };

export class Anomaly {
  action: EntityAction;
  tenantName: string;
  venueName: string;
}

export class DeviceIssuesAnomaly extends Anomaly {
  deviceStatusHistory: DeviceStatus[];
}

export class TrendAnomaly extends Anomaly {
  timeSeries: TimeSeries[];
}

export class VPNAnomaly extends Anomaly {
  vpnConnectionStatusHistory: VPNConnectionStatusTrendDTO[];
}

export enum DeviceAnomalies {
  DeviceStatusNeverUp = "Device was never up",
  DeviceStatusJustWentDown = "Device went down",
  DeviceStatusDownForLongTime = "Device down for long time",
  DeviceStatusLongPeriodOffline = "Significant downtime period"
}

export class AnomalyMVC {
  constructor(public component: Type<any>, public data: AnomalyComponentData) {
  }
}

export interface AnomalyComponent {
  data: any;
}

export interface AnomaliesSizes {
  height: string;
  width: string;
}

export interface AnomalyComponentData {
  category: AnomalyCategory;
  currentEntity?: Entity;
  selectedEntity?: Entity;
  dates?: FromToDatesStrings;
  sizes?: AnomaliesSizes;
}

export class AnomalySettingsDTO {
  tenantId: number;
  anomalyType: ActionTypeDescDTO;
  actionSeverity: LegacySeverity;
  enabled: boolean;
  shouldNotify: boolean;
  wording: string;
  properties: AnomalyPropertyDTO[];
}

export class ActionTypeDescDTO {
  desc: string;
  value: string;
}

export class AnomalyPropertyDTO {
  name: string;
  value: string;
  displayName: string;
  type: AnomalyParameterType
}

export enum AnomalyParameterType {
  HOURS = "hours",
  MINUTES = "minutes",
  DAYS = "days",
  PERCENTS = "percents",
  MILLISECONDS = "milliseconds",
  TIMES = "times",
  NUMBER = "number",
  QUALITY_LEVEL = 'quality_level'
}

export enum SLAQoe { }
