<!-- Severity.High,Severity.Medium,Severity.Minor -->
<mat-icon *ngIf="severity == Severity.Critical" class="severity-icon" mat-list-icon svgIcon="severity_critical">
</mat-icon>
<mat-icon *ngIf="severity == Severity.High" class="severity-icon" mat-list-icon svgIcon="severity_high">
</mat-icon>
<mat-icon *ngIf="severity == Severity.Medium" class="severity-icon" mat-list-icon svgIcon="severity_medium">
</mat-icon>
<mat-icon *ngIf="severity == Severity.Minor" class="severity-icon" mat-list-icon svgIcon="severity_low">
</mat-icon>
<mat-icon *ngIf="severity === null || severity == -1" class="severity-icon" mat-list-icon
  svgIcon="severity_not_computed">
</mat-icon>