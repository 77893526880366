import {Pipe, PipeTransform} from '@angular/core';
import {VenueVpnMode} from "../../../models/vpn.model";

@Pipe({
  name: 'vpnTypeEditor'
})
export class VpnTypeEditorPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value === VenueVpnMode.SiteToSite) {
      return 'Site2Site'
    }
    return value;
  }
}
