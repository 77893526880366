<div [ngClass]="insightsDisplayClass">
  <div class="row analyze-pop-over-box-row">
    <div class="col insights-resolution-col">
      <div class="analyze-pop-over-box-title">Issues</div>
      <ng-container *ngIf="isSummary">
        <ng-container *ngFor="let sum of insightSummary">
          <div class="row analyze-pop-over-insight-row analyze-pop-over-insight key" [ngClass]="sum.css">{{sum.key}}</div>
          <ng-container *ngIf="sum.delimitedValues.length > 0">
            <div>{{sum.delimitedValues[0]}}</div>
            <ul class="row analyze-pop-over-insight-row analyze-pop-over-insight">
              <li *ngFor="let value of sum.delimitedValues.slice(1)">{{value}}</li>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isSummary" [ngTemplateOutlet]="noInsightFound"></ng-container>
    </div>
    <div class="col insights-resolution-col">
      <div class="analyze-pop-over-box-title">Insights</div>
      <ul class="pl-0" *ngIf="isRootCauses">
        <li *ngFor="let cause of cleanRootCauses">
          <p>{{cause}}</p>
        </li>
      </ul>
      <ng-container *ngIf="!isRootCauses" [ngTemplateOutlet]="noInsightFound"></ng-container>
    </div>
    <div class="col insights-resolution-col">
      <div class="analyze-pop-over-box-title">Resolutions</div>
      <ul class="pl-0" *ngIf="isSolutions">
        <li *ngFor="let solution of cleanSolutions">
          <p>{{solution}}</p>
        </li>
      </ul>
      <ng-container *ngIf="!isSolutions" [ngTemplateOutlet]="noInsightFound"></ng-container>
    </div>
  </div>
  <div class="row analyze-pop-over-icon-container" *ngIf="iconsName?.length > 0">
    <div class="row fabric-status-single-icon-container" *ngFor="let icon of iconsName"
         [ngStyle]="!icon.isDisabled? {cursor: 'pointer', opacity: 1}: null"
         (click)="onActionSelected(icon)">
      <mat-icon *ngIf="icon?.action" svgIcon="{{icon.action}}-icon"></mat-icon>
      <div>{{icon.action}}</div>
    </div>
  </div>
</div>
<ng-template #noInsightFound>
  <div>
    No Insights Found
  </div>
</ng-template>
