import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityAction, ActionStatus } from '../../models/actions.model';
import { TranslateService } from '@ngx-translate/core';
import { KpiType } from '../../models/kpi.model';
import { dialogType } from '../../components/single-entities/models/single-entity-dialog';

export class KpiChanges {
  key: KpiType;
  value: number;
  change: number
}

export class ActionUserSelection {
  configurationSelection?: ("Negative" | "Neutral" | "Possitive");
  insightSelection?: ("Yes" | "No" | "High" | "Medium" | "Minor");
}
@Component({
  selector: 'app-action-configuration-dialog',
  templateUrl: './action-configuration-dialog.component.html',
  styleUrls: ['./action-configuration-dialog.component.scss']
})
export class ActionConfigurationDialogComponent {
  title: string;
  subTitle: { key: string, value: string };
  titleExplain: string;
  configurationData: { key: string, value: string }[] = [];
  kpiChanges: KpiChanges[];
  userSelection: ActionUserSelection;

  constructor(
    private matDialogRef: MatDialogRef<ActionConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { rowData: EntityAction },
    private translate: TranslateService) {
    this.initiateData();
  }
  /**
   * Save user selection at userSelection variable.
   * In Case of selection from the first toggle group (pssitive, neutral or negative): initate data for the next toggle group
   * @param event The selection event (could be button toggle or radio button)
   */
  setUserSelection(event: ActionUserSelection) {
    this.userSelection = event;
  }
  get isUserSelect() {
    if (this.userSelection && this.userSelection.configurationSelection && this.userSelection.insightSelection)
      return true;
    return false;
  }

  saveSelection() {
    //TODO: Currently the user selection does not go anywhere.
    //When an there is an suitable api - do something with the userSelection data
    this.dialogData.rowData.status = ActionStatus.Complete;
    let dialogSelections = { dialogType: dialogType.actionConfiguraion, rowData: this.dialogData.rowData, userSelection: this.userSelection };
    this.closeDialog(dialogSelections);
  }

  closeDialog(dialogSelections?: any) {
    this.matDialogRef.close(dialogSelections ? dialogSelections : null);
  }

  private initiateData() {
    this.title = this.translate.instant('data.ACTIONS.ACTION_CONFIGURTAION_DIALOG_HEADER');
    this.titleExplain = this.translate.instant('data.ACTIONS.ACTION_CONFIGURTAION_DIALOG_EXPLAIN');
    this.subTitle = {
      key: this.translate.instant('data.ACTIONS.CONFIGURATION_CHANGE_KEY'),
      value: this.dialogData.rowData ? this.dialogData.rowData.description : ''
    };
    this.configurationData = [
      {
        key: this.translate.instant('data.ACTIONS.AFFECTED_FABRIC_KEY'),
        value: this.dialogData.rowData ? this.dialogData.rowData.name : ''
      }
    ];
    this.kpiChanges = [
      {
        key: KpiType.Loss,
        value: 7,
        change: 2.1
      },
      {
        key: KpiType.Latency,
        value: 37,
        change: 19
      },
      {
        key: KpiType.Traffic,
        value: 0.7,
        change: -15
      },
      {
        key: KpiType.Clients,
        value: 15,
        change: -31
      },
    ]
  }
}
