import { Component } from '@angular/core';
import { TrafficOrigin } from '../../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-device-client-connection',
  templateUrl: './device-client-connection.component.html',
  styleUrls: ['./device-client-connection.component.scss']
})
export class DeviceClientConnectionComponent {
  origin: TrafficOrigin = TrafficOrigin.DEVICE_BY_CLIENTS;

  constructor() { }



}
