import { EditAbleKeys } from './editableKeys';

export class EditAbleFabricKeysValues implements EditAbleKeys {
    ssids: {};
    vlans: {};

    constructor(editableKeys: FabricEditableKeys) {
        this.ssids = {};
        this.vlans = {};
        if (editableKeys.ssidParameters.length > 0)
            Object.values(editableKeys.ssidParameters[0])[0]
                .forEach(key => {
                    this.ssids[key] = null;
                });
        if (editableKeys.vlanParameters.length > 0)
            Object.values(editableKeys.vlanParameters[0])[0]
                .forEach(key => {
                    this.vlans[key] = null;
                });
    }

    getTotalKeysList() {
        return Object.keys(this.ssids).concat(Object.keys(this.vlans));
    }
    getPropertyKeys(propName: string) {
        return Object.keys(this[propName.toLowerCase()]);
    }
    getPropertiesList() {
        return [
            EditableFabricProperties.SSIDS,
            EditableFabricProperties.VLANS
        ]
    }
    getEnumsArray(): enumDeatils[] {
        return [
            { enum: Bands, enumName: "bands" },
            { enum: EditableFabricProperties, enumName: "editableFabricProperties" }
        ]
    }
    getPropKeyType(prop: string, key: string): string {
        let specificKey = this[prop][key];
        if (typeof specificKey !== "number")
            return typeof specificKey;
        return "number";
    }
    getEnumFields() {
        return ["bands"];
    }
}

export class EditSSIDS {
    name: string;
    password: string;
    bands: Bands;
    defaultVlanId: number;
}
export class EditVLANS {
    subnet: string;
    applianceIp: string;
    name: string;
}

export class FabricEditableKeys {
    ssidParameters: [{ [id: number]: string[] }]
    vlanParameters: [{ [id: number]: string[] }]
}
export enum EditableFabricProperties {
    SSIDS = "ssids",
    VLANS = "vlans"
}

export enum Bands {
    DUAL_BAND = "Dual band operation",
    FIVE_GHZ_BAND_ONLY = "5 GHz band only",
    DUAL_BAND_OPERATION = "Dual band operation with Band Steering"
}
export namespace Bands {
    export function getEnumAsArray() {
        return [
            Bands.DUAL_BAND,
            Bands.FIVE_GHZ_BAND_ONLY,
            Bands.DUAL_BAND_OPERATION
        ]
    }
}
export class enumDeatils {
    enum: any;
    enumName: string;
}