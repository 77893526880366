import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeIndex'
})
export class RemoveIndexPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value[0] == ' ' && value.toLowerCase().includes('name') || value.toLowerCase().includes('ip')) {
      value = value.substring(1, value.length);
    }
    let regex1 = new RegExp('\\.w*\\s\\w*[0-9]{1,3}');
    let regex2 = new RegExp('[0-9]{1,3}w*\\s\\w*\\.');
    let regex3 = new RegExp('(^\\d*\\.?([a-z]|[A-Z])*$)');

    if (regex3.test(value)) {
      return value.substring(2, value.length);
    }
    if (regex1.test(value)) {
      return value.replace(regex1, "");
    }
    if (regex2.test(value)) {
      return value.replace(regex2, "");
    }

    return value;
  }

}
