import {ChangeDetectorRef, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[checkboxClass]'
})
export class CheckboxClassDirective {
  @Input('checkboxClass') class: string;
  @Input('checkboxClassName') className: string;
  @Input() isSelected: boolean;

  constructor(private _ren: Renderer2, private _el: ElementRef, private cdr: ChangeDetectorRef) {
  }

  ngOnChanges() {
    //SOURCE: https://stackoverflow.com/questions/1720320/how-to-dynamically-create-css-class-in-javascript-and-apply
    if (!this._el.nativeElement.classList.value.includes(this.className)) {
      const style = document.createElement('style');
      style.innerHTML = this.class;
      document.getElementsByTagName('head')[0].appendChild(style);
    }
    this.isSelected ?
      this._ren.addClass(this._el.nativeElement, this.className) :
      this._ren.removeClass(this._el.nativeElement, this.className);
    this.cdr.detectChanges();
  }
}
