import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { v4 as uuid } from 'uuid';;
import {GenericDevice, SingleD3Node, Topology} from '../../models/topology';
import {Logger, LoggerService} from '../../services/logger.service';
import {SingleDevice} from '../../models/single-device.model';
import {TopologyInformationService} from './services/topology-information.service';
import {GenericTopologyService} from './services/generic-topology.service';
import {Subscription} from 'rxjs';
import {PathStyle, SingleLink} from '../../models/single-link.model';
import {TopologyConfiguration, TreeConfiguration} from './models/topology-configuration';
import {NgChanges} from "../../extend-angular-classes/on-changes";
import {TopologyBuilderService} from "./services/topology-builder.service";
import {TopologyZipperService} from "./services/topology-zipper.service";
import {isLink, isToBeZippedNode, isZipper, isZipperChildrenInZipMode} from "./operators/topology-operators";
import {D3TreeEventsService} from "./services/d3-tree-events.service";
import {ClientsTopologyService} from "./services/clients-topology.service";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-network-topology',
  templateUrl: './network-topology.component.html',
  styleUrls: ['./network-topology.component.scss']
})

export class NetworkTopologyComponent {
  @Input() treeData: Topology<any, any>;
  @Input() title: string;
  @Input() isDevice: boolean;
  @Input() isFabricsVenue: boolean;
  @Input() tplgWidth: number;
  @Input() tplgHeight: number;
  @Input() selectedDeviceID: number;
  @Input() selectedFabricName: string;
  @Input() isClients: boolean = false;
  @Input() isWidget: boolean = false;
  @Input() isTopologyGenerated: boolean = undefined;
  @Input() isPropertiesOpen: boolean = false;
  @Input() selectedFabricTopology: Topology<SingleDevice, SingleLink>;
  @ViewChild('topologyContainer', {read: ElementRef, static: true}) tplgContainer: ElementRef;

  topologyConfiguration: TopologyConfiguration;
  treeConfiguration: TreeConfiguration;
  containerID: string;
  disConnectedNodes: any[] = [];
  topologyData: Topology<any, any>;
  d3TopologyData: Topology<GenericDevice<any>, any> = new Topology<GenericDevice<any>, any>();
  FullTopologyData: Topology<GenericDevice<any>, any> = new Topology<GenericDevice<any>, any>();
  tplgRect: DOMRect;
  logger: Logger;
  subsc: Subscription[] = [];
  containerRect: DOMRect;


  constructor(
    private loggerFactory: LoggerService,
    private topologyInformationService: TopologyInformationService,
    private topologyZipperService: TopologyZipperService,
    private d3TreeEventsService: D3TreeEventsService,
    private clientsTopologyService: ClientsTopologyService,
    private topologyBuilder: TopologyBuilderService,
    private genericTopologyService: GenericTopologyService) {
    this.logger = this.loggerFactory.getLogger('TopologyComponent');
  }

  ngOnChanges(changes: NgChanges<NetworkTopologyComponent>) {
    if (changes.treeData) {
      this.logger.debug('Current topology nodes are: ', this.treeData ? this.treeData.nodes : []);
      this.logger.debug('Current topology links are: ', this.treeData ? this.treeData.links : []);
      this.genericTopologyService.deleteNewTopologyData();
      // Creating new object from the tree data necessary in order to change the nodes list (i.g. remove doconnected nodes)
      // without invoking the change detection.
      // Added in favor of the disconnected-device component
      this.containerID = uuid();
      /**
       * Time out for allow the containerRect to retrieve the final container sizes
       */
      setTimeout(() => {
        this.initiateTopologyData(changes);
      });
    }
    if (changes.selectedFabricTopology && this.topologyConfiguration) {
      this.topologyConfiguration.selectedFabricTopology = this.selectedFabricTopology;
    }
  }

  /**
   * The Topology checks if the data is valid to display the graph.
   * If not: it eliminate the configuration data object, and by that eliminate the tree component
   */
  private initiateTopologyData(changes: NgChanges<NetworkTopologyComponent>) {
    if (!changes.treeData.firstChange || changes.isDevice && changes.isDevice.currentValue) {
      this.subscribeToD3TreeClickEvents();
      if (this.treeData && this.treeData.links.length > 0 && this.treeData.nodes.length > 0) {
        this.topologyData = {...this.treeData};
        this.topologyData.nodes = this.topologyInformationService.multiHealthReasonBy100(this.topologyData.nodes);
        this.d3TopologyData = this.topologyInformationService.convertTopologyToGeneric(this.topologyData);
        [this.d3TopologyData.nodes, this.disConnectedNodes] = this.topologyInformationService.removeDisconnectedNodes(this.d3TopologyData.nodes, this.d3TopologyData.links);

        this.topologyConfiguration = {
          selectedFabricTopology: this.selectedFabricTopology ? this.selectedFabricTopology : null,
          isDisconnectedDevices: this.disConnectedNodes.length > 0,
          isDevice: this.isDevice,
          isFabricsVenue: this.isFabricsVenue,
          selectedDeviceID: this.selectedDeviceID ? this.selectedDeviceID : null,
          selectedDevice: null
        };
        this.containerRect = this.getContainerRect();
        this.generateTreeConfiguration();
        this.isTopologyGenerated = true;
        this.logger.debug('Current tree configuration', this.treeConfiguration);

      } else {
        this.treeConfiguration = this.topologyConfiguration = null;
        this.isTopologyGenerated = false;
      }
    }
  }

  /**
   * Re assign treeConfiguration object
   * 1. The method calls the zipper methods that check which nodes will be displayed
   * 2. The method creat new object in order to activate change detection
   * @private
   */
  private generateTreeConfiguration() {
    const nodes = cloneDeep(this.d3TopologyData.nodes);
    this.treeConfiguration = {
      nodes: this.d3TopologyData.nodes.filter(node => this.topologyZipperService.isDisplayNode(node, nodes)),
      links: this.d3TopologyData.links.filter(link => this.topologyZipperService.isDisplayLink(link)),
      height: this.containerRect.height * 0.85,
      width: this.containerRect.width,
      treeDepth: 0,
      pathStyle: PathStyle.RIGHT_ANGLE
    };
  }

  /**
   * Return the Topology container Rect element. The job of this getter is to serve the tooltip calculation, based on its container
   */
  getContainerRect(): DOMRect {
    if (this.tplgContainer && this.tplgContainer.nativeElement) {
      const rect = this.tplgContainer.nativeElement as HTMLElement;
      this.tplgRect = rect.getBoundingClientRect() as DOMRect;
    }
    return this.tplgRect;
  }

  /**
   * When topology structure changes (e.g., when we are adding client to it),
   * The method will regenerate the tree configuration
   * @param newTopology
   */
  onTopologyChanges(newTopology: Topology<GenericDevice<SingleDevice>, SingleLink>) {
    this.d3TopologyData = {...newTopology};
    this.generateTreeConfiguration();
    this.logger.debug('Current tree configuration', this.treeConfiguration);
  }

  /**
   * Update zip mode when zip mechanism trigger was clicked
   * Regenerate tree configuration
   */
  subscribeToD3TreeClickEvents() {
    if (!this.isDevice) {
      const d3EventsSubcs = this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(element => {
        if (element) {
          if (isZipper(element)) {
            this.topologyZipperService.updateZipMode(
              (element as SingleD3Node<GenericDevice<any>>).data.parent_id,
              this.d3TopologyData,
              //For zipper, the original data hold all of its children. Therefore, the updateZipMode will get all of its children id, plus zipper's own id
              ((element as SingleD3Node<GenericDevice<any>>).data.originalData as GenericDevice<any>[]).map(node => node.id).concat((element as SingleD3Node<GenericDevice<any>>).data.id));
          } else if (!isToBeZippedNode(element) && !isLink(element) && isZipperChildrenInZipMode(element)) {
            this.topologyZipperService.updateZipMode(
              (element as SingleD3Node<GenericDevice<any>>).data.id,
              this.d3TopologyData,
              //Pass all of current visible nodes for current selected element
              ((element as SingleD3Node<GenericDevice<any>>).children.map(node => node.data.id)),
              //Close all open zippers for current selected elements
              true);
          }
          this.generateTreeConfiguration();
        }
      });
      this.subsc.push(d3EventsSubcs);
    }
  }

  /**
   * Activate the zipper mechanism
   */
  private activateZipper() {
    if (!this.isDevice) {
      const root = this.topologyBuilder.generateTree(this.d3TopologyData.nodes, this.d3TopologyData.links);
      this.d3TopologyData = this.topologyZipperService.zipTooManyChildren(root, this.d3TopologyData);
    }
  }

  ngOnDestroy() {
    this.subsc.forEach(subscription => {
      subscription ? subscription.unsubscribe() : null;
    });
  }

}
