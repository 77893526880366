<div>
  <div class="row fabric-legend-row">
    <div class="col fabric-legend-col" *ngFor="let legend of legendNames">
      <div class="row fabric-legend-icon-row">
        <mat-icon [svgIcon]="legend.toLowerCase()"></mat-icon>
      </div>
      <div class="row">
      <span class="fabric-legend-text-color">{{legend}}</span>
      </div>
    </div>
  </div>
</div>
