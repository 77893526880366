/**
 * Container rectangle
 */
export class Rect {
    constructor(public left: number, public top: number, public width: number, public height: number) { }
    /**
     * Adds px to all dimantions
     */
    formatPX(): { left: string, top: string, width: string, height: string } {
        return {
            left: this.left + 'px',
            top: this.top + 'px',
            width: this.width + 'px',
            height: this.height + 'px'
        }
    }
}