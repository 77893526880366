import {KpiType} from "../../../../models/kpi.model";
import {splitString} from "../../../../operators/string-opertators";

export class InsightSummaryDisplay {
  css: string;
  key: string;
  value: string;

  get delimitedValues(): string[] {
    return splitString(this.value).filter(value => value && value.length > 0);
  }

  constructor(css: string, key: string, value: string) {
    this.css = css;
    this.key = key;
    this.value = value;
  }
}

export type InsightSummaryData = { [key in KpiType]?: { severity: string, description: string } };
