import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {StoreBrandingService} from "../../services/client-storage-services/internal-storage-services/store-branding.service";


@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ButtonsComponent {
  // This is what the a 'color' property e.g. 'primary', 'secondary' etc.
  @Input() btnStyle: ThemePalette;
  // Custon css class to add to the button
  @Input() btnClass;
  // Th text of the button
  @Input() btnText;
  // The html built in type e.g. submit (you probably can leave this unset)
  @Input() btnType;
  /**
   * The material button variant (i.e. raised, flat, stroked, link, text (for text button with no elevation))
   * raised is the default value
   * Also supports 'context based' button types i.e. cancel.
   */
  @Input() btnVariant = 'raised';
  // SHould the button be disabled
  @Input() btnDisabled;
  // On click handler
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private storeBrandingService: StoreBrandingService) { }

  handleClick($event: MouseEvent) {
    $event.stopPropagation();
    this.click.emit($event);
  }

  get userSelectionButtonStyle() {
    if (this.storeBrandingService && this.storeBrandingService.selectedOrgUI)
      return this.storeBrandingService.selectedOrgUI.buttonPalette;
  }
}
// We might need this in future: https://stackoverflow.com/questions/49415692/can-you-prevent-an-angular-components-host-click-from-firing
// prevent pointer event in case the button is disabled but contains another elements
