import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() date: Date = new Date();
  @Output() dateChange = new EventEmitter<Date>();

  constructor() { }

  ngOnInit(): void {
  }

  onDateSelected(value: Date | unknown) {
    if (value) {
      this.date = new Date(value as string);
      this.dateChange.emit(this.date);
    }
  }
}
