import { Component, OnInit } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-apps',
  templateUrl: './top-apps.component.html',
  styleUrls: ['./top-apps.component.scss']
})
export class TopAppsComponent {
  origin: TopTrafficOrigin = TopTrafficOrigin.APPS;
}
