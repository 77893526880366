import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceBackupDTO, DeviceBackupOnceDTO, DeviceBackupNowDTO } from '../../models/device-backup.model';
import { DevicesService } from '../../services/rest-services/devices.service';
import { DisplaySuccessService } from '../../services/display-success.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-backup-now',
  templateUrl: './backup-now.component.html',
  styleUrls: ['./backup-now.component.scss']
})
export class BackupNowComponent implements OnInit {
  backupForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private deviceService: DevicesService,
    private successService: DisplaySuccessService,
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<BackupNowComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DeviceBackupDTO[]) {
  }

  ngOnInit() {
    this.initateForm();
  }

  initateForm() {
    this.backupForm = this.fb.group({
      deploy: new UntypedFormControl('now'),
      deployDate: new UntypedFormControl(''),
      deployHour: new UntypedFormControl(''),
      numberOf: new UntypedFormControl(''),
      period: new UntypedFormControl(''),
    })
    this.backupForm.valueChanges.subscribe(changes => {
    })
  }

  setBackup() {
    let message;
    if (this.isLater && this.laterHour) {
      let backupOne: DeviceBackupOnceDTO = {
        deviceIds: this.dialogData.map(backup => backup.deviceId),
        runAt: this.laterHour
      }
      this.deviceService.setDeviceBackupLater(backupOne).subscribe(response => {
        this.matDialogRef.close();
        message = this.translate.instant('data.MAINTENANCE.BACKUP_LATER_TOAST');
        this.successService.openMessage(message);
      })
    }
    if (this.isNow) {
      let backupNow: DeviceBackupNowDTO = {
        deviceIds: this.dialogData.map(backup => backup.deviceId),
      }
      this.deviceService.setDeviceBackupNow(backupNow).subscribe(response => {
        this.closeDialog(true);
        message = this.translate.instant('data.MAINTENANCE.BACKUP_NOW_TOAST');
        this.successService.openMessage(message);

      })
    }
  }

  closeDialog(isSubmit: boolean) {
    this.matDialogRef.close(isSubmit);
  }

  get isLater() {
    if (this.backupForm)
      return this.backupForm.controls['deploy'].value == 'later';
  }
  get isNow() {
    if (this.backupForm)
      return this.backupForm.controls['deploy'].value == 'now';
  }
  get isFTP() {
    if (this.backupForm)
      return this.backupForm.controls['storage'].value == 'ftp';
  }

  get laterHour() {
    return this.backupForm.controls['deployDate'].value + ' ' + this.backupForm.controls['deployHour'].value;
  }

}
