import {Bandwidth} from "../../models/bandwidth.model";
import {KPIChartJsAnnotation} from "../../modals/deviation-reports-dialog/components/venue-deviation-reports-dialog/venue-deviation-reports-dialog.component";
import {KpiType, TrafficUnits, KpiTrendDisplay} from "../../models/kpi.model";
import {Observable} from "rxjs";
import {TopTrafficOrigin, TopTrafficTotalDisplay} from "../../models/legacy-reports.model";
import {Entity} from "../../models/entity.model";
import {TrafficService} from "../../services/rest-services/traffic.service";
import {BandwidthService} from "../../services/rest-services/bandwidth.service";
import {ChartjsKpiAnnotationGeneratorService} from "../chartjs-components/services/chartjs-kpi-annotation-generator.service";
import {ChangeDetectorRef, Directive, Inject, Input} from "@angular/core";
import {FULL_COLORS_LIST} from "../../global-utils/colors-utils";
import {ChartJsAnnotation} from "../chartjs-components/models/annotations.model";
import {share} from "rxjs/operators";
import {TimeManagerService} from "../../services/time-manager.service";
import {RestKpiGeneratorService} from "./services/rest-kpi-generator.service";
import {KpiDisplayDataGeneratorService} from "./services/kpi-display-data-generator.service";
import {InsightsService} from "../../services/rest-services/insights.service";
import {AnomalySettingsDTO} from "../../models/anomalies.model";
import {HORIZONTAL_CHART_JS_ANNOTATION} from "../chartjs-components/models/chart-js-injection-token";

@Directive()
export class BaseExpendedKpiDisplay {
  isLoading: boolean;
  bandwidth: Bandwidth;
  lossSettings: AnomalySettingsDTO[];
  latencySettings: AnomalySettingsDTO[];
  annotations: KPIChartJsAnnotation = {
    [KpiType.Traffic]: [],
    [KpiType.Latency]: [],
    [KpiType.Loss]: [],
    [KpiType.Throughput]: [],
  };
  kpiTrends: KpiTrendDisplay[];
  unit: TrafficUnits;
  topClients$: Observable<TopTrafficTotalDisplay>;
  topApps$: Observable<TopTrafficTotalDisplay>;
  @Input() currentEntity: Entity;
  @Input() currentDateIsoFormat: {
    start: string;
    end: string;
  };

  @Input() currentDate: {
    start: string;
    end: string;
  };

  constructor(protected trafficService: TrafficService,
              protected bandwidthService: BandwidthService,
              protected annotationGenerator: ChartjsKpiAnnotationGeneratorService,
              protected timeManagerService: TimeManagerService,
              protected restKpiGeneratorService: RestKpiGeneratorService,
              protected kpiDisplayDataGeneratorService: KpiDisplayDataGeneratorService,
              protected insightsService: InsightsService,
              protected cdr: ChangeDetectorRef,
              @Inject(FULL_COLORS_LIST) readonly fullColorsList: string[],
              @Inject(HORIZONTAL_CHART_JS_ANNOTATION) readonly defaultAnnotation: ChartJsAnnotation,) {
  }

  /**
   * Generate the annotations object. Activated only when entity changed
   */
  protected generateAnnotations() {
    this.annotationGenerator.getKpisAnnotations(this.defaultAnnotation, this.annotations, this.bandwidth, this.lossSettings, this.latencySettings, this.fullColorsList);
  }

  /**
   * Load top traffic data in order to draw progress bar
   * @protected
   */
  protected loadTopDataForVisualization() {
    if (this.currentDate) {
      this.topApps$ = this.trafficService.getTopTrafficData(this.currentEntity, this.currentDate.start, this.currentDate.end, TopTrafficOrigin.APPS).pipe(share());
      this.topClients$ = this.trafficService.getTopTrafficData(this.currentEntity, this.currentDate.start, this.currentDate.end, TopTrafficOrigin.CLIENTS).pipe(share());
    }
    this.cdr.detectChanges();
  }
}
