import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Entity} from "../../../../../../models/entity.model";
import {GlobalEntitiesService} from "../../../../../../services/rest-services/global-entities.service";
import {OrganizationsService} from "../../../../../../services/rest-services/organizations.service";
import {VenuesService} from "../../../../../../services/rest-services/venues.service";
import {EntityType} from "../../../../../../models/entity-type.enum";
import {FiltersBar} from "../../../../../filters-bar/models/filters-bar.model";
import {ActionsListQueryParamsEnum} from "../../../../../../../features/anomalies/components/main-actions-dashboard/models/actions-list-query-params.model";
import {FormFieldsType} from "../../../../../../modals/models/form-actions.model";
import {take} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {BaseEntitiesFilter} from "../../../../../filters-bar/models/base-entities.filter";
import {Page} from "../../../../../../models/page.model";
import {AnomalyLeanGridDTO} from "../../anomalies-lean-grid/anomaly-lean-grid.model";

@Injectable({
  providedIn: 'root'
})
export class AnomaliesEntitiesFiltersService extends BaseEntitiesFilter {

  constructor(
    protected globalEntitiesService: GlobalEntitiesService,
    protected organizationService: OrganizationsService,
    protected venuesService: VenuesService,
  ) {
    super(globalEntitiesService, organizationService, venuesService);
  }

  generateEntitiesFilters(filters: FiltersBar<number, EntityType>[], currentEntity: Entity, entityForSelection: Entity, leanGridData: Page<AnomalyLeanGridDTO>) {
    if (currentEntity) {
      switch (currentEntity.type) {
        case EntityType.ORGANIZATION: {
          filters.push(
            new FiltersBar(ActionsListQueryParamsEnum.Tenant, FormFieldsType.SELECT, [], 'All Tenants'),
          );
          this.getOrgsLevelObservables(currentEntity).pipe(take(1)).subscribe(entitiesData => {
            this.initiateFiltersWithData(filters, entitiesData, currentEntity, entityForSelection);
            filters = this.removeEntitiesWithoutAnomalies(filters, leanGridData);
            filters.forEach(filter => filter.addSelectAllOption());
          });
          break;
        }
        case EntityType.TENANT: {
          filters.push(
            new FiltersBar(ActionsListQueryParamsEnum.Venue, FormFieldsType.SELECT, [], 'All Venues'),
          );
          this.getTenantsLevelObservables(currentEntity).pipe(take(1)).subscribe(entitiesData => {
            this.initiateFiltersWithData(filters, entitiesData, currentEntity, entityForSelection);
            filters = this.removeEntitiesWithoutAnomalies(filters, leanGridData);
            filters.forEach(filter => {
              filter.addSelectAllOption();
            });
          });
        }
          break;
        default:
          break;
      }
    }
  }

  getTenantsLevelObservables(currentEntity: Entity) {
    const actionFiltersObservables$ = forkJoin({
      [EntityType.VENUE]: this.venuesService.fetchVenuesSummaryByTenantId(currentEntity.id),
    });
    return actionFiltersObservables$;
  }

  getOrgsLevelObservables(currentEntity: Entity) {
    const actionFiltersObservables$ = forkJoin({
      [EntityType.ORGANIZATION]: this.organizationService.getOrganizationsOnly(),
      [EntityType.TENANT]: this.organizationService.fetchTenantsById(currentEntity.id),
    });
    return actionFiltersObservables$;
  }

  private removeEntitiesWithoutAnomalies(filters: FiltersBar<number, EntityType>[], leanGridData: Page<AnomalyLeanGridDTO>) {
    const entityIdWithAnomalies = leanGridData.data.filter(entity => entity.entityCategoriesData).map(entity => entity.entityId);
    filters.forEach(filter => {
      filter.data = filter.data.filter(datum => entityIdWithAnomalies.includes(datum.data));
    })
    return filters;
  }
}
