import {ChangeDetectorRef, Component, Inject, Input} from '@angular/core';
import {Entity} from 'src/app/shared/models/entity.model';
import {TrafficService} from 'src/app/shared/services/rest-services/traffic.service';
import {forkJoin, of} from 'rxjs';
import {FULL_COLORS_LIST} from 'src/app/shared/global-utils/colors-utils';
import {catchError, take} from 'rxjs/operators';
import {ChartJsAnnotation} from 'src/app/shared/components/chartjs-components/models/annotations.model';
import {KpiGroupedBy, KpiTrendDisplay, KpiType, ThresholdKpiType} from 'src/app/shared/models/kpi.model';
import {ChartjsKpiAnnotationGeneratorService} from '../../../../components/chartjs-components/services/chartjs-kpi-annotation-generator.service';
import {NgChanges} from 'src/app/shared/extend-angular-classes/on-changes';
import {BandwidthService} from 'src/app/shared/services/rest-services/bandwidth.service';
import {TimeManagerService} from "../../../../services/time-manager.service";
import {BaseExpendedKpiDisplay} from "../../../../components/kpi-display-components/base-expended-kpi-display";
import {RestKpiGeneratorService} from "../../../../components/kpi-display-components/services/rest-kpi-generator.service";
import {KpiDisplayDataGeneratorService} from "../../../../components/kpi-display-components/services/kpi-display-data-generator.service";
import {InsightsService} from "../../../../services/rest-services/insights.service";
import {AnomalyCategory} from "../../../../models/anomalies.model";
import {TrendOptionsDto} from "../../../../components/kpi-display-components/multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.model";
import {MultiKpiChartsService} from "../../../../components/chartjs-components/services/multi-kpi-charts.service";
import {HORIZONTAL_CHART_JS_ANNOTATION} from "../../../../components/chartjs-components/models/chart-js-injection-token";
import {TimeUnit} from "../../../../models/time.model";

export type KPIChartJsAnnotation = { [key in ThresholdKpiType]: ChartJsAnnotation[] };

@Component({
  selector: 'app-venue-deviation-reports-dialog',
  templateUrl: './venue-deviation-reports-dialog.component.html',
  styleUrls: ['./venue-deviation-reports-dialog.component.scss']
})
export class VenueDeviationReportsDialogComponent extends BaseExpendedKpiDisplay {
  @Input() currentTenant: Entity;
  timeDisplayFormat: string = 'HH:mm';
  groupedBy = KpiGroupedBy.Application;


  constructor(
    protected trafficService: TrafficService,
    protected cdr: ChangeDetectorRef,
    protected annotationGenerator: ChartjsKpiAnnotationGeneratorService,
    protected bandwidthService: BandwidthService,
    protected restKpiGeneratorService: RestKpiGeneratorService,
    protected kpiDisplayDataGeneratorService: KpiDisplayDataGeneratorService,
    protected insightsService: InsightsService,
    protected timeManager: TimeManagerService,
    @Inject(FULL_COLORS_LIST) readonly fullColorsList: string[],
    @Inject(HORIZONTAL_CHART_JS_ANNOTATION) readonly defaultAnnotation: ChartJsAnnotation,
    private multiKpiChartsService: MultiKpiChartsService
  ) {
    super(trafficService, bandwidthService, annotationGenerator, timeManager, restKpiGeneratorService, kpiDisplayDataGeneratorService, insightsService, cdr, fullColorsList, defaultAnnotation)
  }

  ngOnChanges(changes: NgChanges<VenueDeviationReportsDialogComponent>) {
    this.loadTopDataForVisualization();
    this.getKpiTrendData();
  }

  /**
   * Update 3 things
   * 1. Annotaion for Traffic chart
   * 2. The kpi Trend Array
   * 3. MaxX and minX values
   */
  getKpiTrendData() {
    this.isLoading = true;
    const nextDayDate = this.timeManager.getNextDayAsString(this.currentDateIsoFormat.end);
    const bandwidthWithKpi = forkJoin({
      kpiData: this.restKpiGeneratorService.getKpiTrendsByIds(this.currentEntity.type, this.currentEntity.id, this.currentTenant.id, 1, nextDayDate, this.groupedBy, this.currentEntity.type, this.currentEntity.id, TimeUnit.DAYS),
      bandwidthData: this.bandwidthService.getVenueBandwidth(this.currentEntity.id),
      latencySettings: this.insightsService.getTenantAnomalySettings(this.currentTenant, AnomalyCategory.Latency),
      lossSettings: this.insightsService.getTenantAnomalySettings(this.currentTenant, AnomalyCategory.PacketLoss)
    });
    bandwidthWithKpi.pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(null);
      })
    ).subscribe({
      next: venueReportData => {
        if (venueReportData) {
          this.isLoading = false;
          const [latencyTrend, lossTrend, usersTrend, trafficSplitTrend, throughputSplitTrend, trafficAppTrend, trafficWired] = venueReportData.kpiData;
          this.unit = trafficSplitTrend[0].unit;
          this.bandwidth = venueReportData.bandwidthData;
          this.lossSettings = venueReportData.lossSettings;
          this.latencySettings = venueReportData.latencySettings;
          this.generateAnnotations();

          let trafficOptionsTrend: TrendOptionsDto = {
            'throughput': {
              option: 'throughput',
              index: 0,
              data: throughputSplitTrend,
              type: KpiType.Throughput,
              unit: throughputSplitTrend[0].unit,
              graphTitle: this.multiKpiChartsService.setKpiTitleWithUnits(KpiType.Throughput, throughputSplitTrend[0].unit)
            },
            'traffic': {
              option: 'traffic',
              index: 0,
              data: trafficSplitTrend,
              type: KpiType.Traffic,
              unit: trafficSplitTrend[0].unit,
              graphTitle: this.multiKpiChartsService.setKpiTitleWithUnits(KpiType.Traffic, trafficSplitTrend[0].unit)
            }
          };
          let currentTrafficOption = this.multiKpiChartsService.getCurrentOptionTrend(trafficOptionsTrend);
          let trafficTrendDisplay = new KpiTrendDisplay(currentTrafficOption.type, currentTrafficOption.data,
            undefined, trafficAppTrend, trafficWired, currentTrafficOption.unit,
            trafficOptionsTrend, currentTrafficOption.option);

          this.kpiTrends = [
            trafficTrendDisplay,
            new KpiTrendDisplay(KpiType.Clients, usersTrend),
            new KpiTrendDisplay(KpiType.Latency, latencyTrend),
            new KpiTrendDisplay(KpiType.Loss, lossTrend),
          ];
        }
      }
    })
  }

}
