import { Injectable } from '@angular/core';
import {DashboardType} from "../models/dashboards.model";

@Injectable({
  providedIn: 'root'
})
export class StoreDashboardService {
  currentDashboard: DashboardType;

  constructor() { }
}
