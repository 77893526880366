import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {VenueData} from "../models/venues.model";
import {SingleDevice} from "../models/single-device.model";


@Injectable({
  providedIn: 'root'
})
export class BaseSingleEntityService {
  notifyEntityData: BehaviorSubject<VenueData | SingleDevice> = new BehaviorSubject(null);
  notifyEntityDataAsObservable$: Observable<VenueData | SingleDevice> = this.notifyEntityData.asObservable()

  constructor() { }

  updateEntityDetails(entityData: VenueData | SingleDevice) {
    this.notifyEntityData.next(entityData);
  }
  deleteEntityDetails() {
    this.notifyEntityData.next(null);
  }
}
