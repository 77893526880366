import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {SingleDevice, SingleDeviceType} from 'src/app/shared/models/single-device.model';
import {GenericDevice, NodeEventData} from 'src/app/shared/models/topology';
import {TopologyTooltipsTypes} from '../topology-tooltips-types.enum';
import {BaseTopologyToolTip} from '../topology-device-tooltip/base-topology-tooltip';
import {FailedConnection} from 'src/app/shared/models/clients.model';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {BaseHealthTopologyTooltip} from "../topology-device-tooltip/base-health-topology-tooltip";


@Component({
  selector: 'app-single-device-tooltip',
  templateUrl: './single-device-tooltip.component.html',
  styleUrls: ['./single-device-tooltip.component.scss'],
  animations: [
    //Source: https://fireship.io/lessons/angular-animations-examples/
    trigger('hoverTooltip', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('0ms ease-out')),
      transition('hide => show', animate('1500ms ease-in'))
    ])
  ]
})
export class SingleDeviceTooltipComponent extends BaseHealthTopologyTooltip {

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected healthColorsService: HealthColorsService) {
    super(TopologyTooltipsTypes.SingleDeviceTooltip, router, activatedRoute, healthColorsService);
  }

  ngOnDestroy() {
    this.subsc.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
