import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'; import { Overlay } from '@angular/cdk/overlay';
import { SnackBarComponent } from '../modals/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DisplaySuccessService {

  constructor(private toastService: MatSnackBar, private translate: TranslateService, ) { }

  openMessage(message: any) {
    this.toastService.openFromComponent(SnackBarComponent,
      {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['bg-success', 'text-white'],
        duration: 2500,
        data: this.translate.instant(message)
      });
  }
}
