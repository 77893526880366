import {ChartTooltipExtensions} from './chart-tooltip-extensions';
import {ChartJsTooltip} from './chartjs-tooltip.model';

export class StackedBarTooltip extends ChartTooltipExtensions {
  /**
   * The stacked bar object. will be generated at the end of the new chart object creation at app-stacked-bar
   */
  stackedBar: any;

  constructor() {
    super();
  }

  /**
   * Create custom stacked bar tooltip
   * The method received the stacked bar tooltip object and then initiate the creating of tooltip element and settins its position
   */
  customStackedTooltips = (context) => {
    const {chart, tooltip} = context;
    this.chartObj = chart;
    if (this.stackedBar) {
      let tooltipEl = this.setCustomTooltipStyling(tooltip, this.stackedBar);
      this.setCustomTooltipPosition(tooltipEl, tooltip, this.stackedBar);
      this.tooltipClasss = 'stacked-tooltip';
    }
  };

  /**
   * Set the stacked bar custom tooltip position
   */
  private setCustomTooltipPosition(tooltipEl: HTMLElement, tooltip: ChartJsTooltip, tooltipThis: any) {
    if (tooltipEl) {
      this.tooltipActualDirection = "under";
      tooltipEl.style.left = tooltip.caretX - tooltip.xPadding * 2 + 'px';
      tooltipEl.style.left = this.stackedBar.width / 2 + 'px';
      // tooltipEl.style.top = -tooltip.height - tooltip.yPadding * 2 + 'px';
      tooltipEl.classList.add(this.tooltipElArrowClass + "-" + this.tooltipActualDirection);
    }
  }
}
