import { Pipe, PipeTransform } from '@angular/core';
import { stringToCamelcase } from '../operators/string-opertators';

@Pipe({
  name: 'formattedCamel'
})
export class FormattedCamelPipe implements PipeTransform {

  transform(key: any, ...args: any[]): any {
    return stringToCamelcase(key);
  }
}
