import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameToStringArray'
})
export class FileNameToStringArray implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    let finalValue: string = "";
    let valueAsArray: string[] = value.split("-");
    valueAsArray.forEach(word => {
      finalValue += word + " ";
    })
    return finalValue;
  }
}
