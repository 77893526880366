export function ipv4Regex() {
  const byteBlock = '([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])';
  return new RegExp(`^(${byteBlock}\.){3}${byteBlock}(/([0-9]|[1-2][0-9]|[1-3][0-2]))?$`);
}

export function alphaNumericWithSpecialCharsRegex() {
  return new RegExp('^[a-zA-Z\\d\\-_.@!#\\s]+$');
}

export function portRegex() {
  return new RegExp('^[0-9]{2,4}$');
}

export function CIDRNotationRegex() {
  return new RegExp('^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))$');
}

export function macAddressRegex() {
  return new RegExp('^[a-fA-F0-9:]{17}|[a-fA-F0-9]{12}$');
}

export function phoneNumberRegex() {
  return new RegExp('^[\\-0-9]*$');
}

export function urlRegex() {
  return new RegExp('^(?:http(s)?:\\/\\/)?[\\w*.-]+(?:\\.[\\w\\.*-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]?$');
}

export function onlyNumbers() {
  return new RegExp('^[0-9]*$');
}
