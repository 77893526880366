import {Component, Input, Output, EventEmitter} from '@angular/core';
import {StoreUserPreferencesService} from '../../../services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {GridTypes, GridUserPreferencesOptions} from "../../../models/client-storage.model";
import {UntypedFormControl} from "@angular/forms";
import {alphaNumericWithSpecialCharsValidator} from "../../../form-validators/form-validators";
import {ERRORS_NAME} from "../../../form-validators/form-model";

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss']
})
export class GridHeaderComponent {
  @Input() totalTitle: string;
  @Input() total: number;
  @Input() gridType: GridTypes;
  @Output() search: EventEmitter<string> = new EventEmitter();
  searchTerm: string;
  searchFormControl = new UntypedFormControl('', [alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)])

  constructor(private storeUserPreferencesService: StoreUserPreferencesService) {}

  ngOnChanges() {
    const currentGridPreferences = this.storeUserPreferencesService.userPreferences?.preferences[this.gridType];
    if (currentGridPreferences) {
      let searchTerm = currentGridPreferences[GridUserPreferencesOptions.searchTerm];
      if (searchTerm) {
        this.searchTerm = searchTerm;
        this.emitChanges(this.searchTerm);
      }
    }
  }

  emitChanges(event: string) {
    if (this.searchFormControl.valid) {
      this.search.emit(event);
    }
  }

  get hasTotal() {
    return this.totalTitle && this.total || this.total == 0;
  }

}
