<div class="row anomaly-setting-row">
    <div class="col col-flex-1 align-items-center">
        <mat-slide-toggle [labelPosition]="'before'" [checked]="setting?.enabled" (change)="onEnableClicked($event)">
            Enable</mat-slide-toggle>
    </div>
    <div class="col align-items-center">
        <app-dynamic-wordings *ngIf="dynamicSentence" [dynamicSentence]="dynamicSentence"
            (wordChanged)="onWordChanged($event)"></app-dynamic-wordings>
    </div>
    <div class="col col-flex-1 align-items-center">
        <pressed-button [buttonText]="'Notify Admin'" [isPressed]="setting?.shouldNotify"
            (buttonSelectedChanged)="onNotifyClicked($event)"></pressed-button>
    </div>
</div>