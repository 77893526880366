import {SingleDevice, SingleDeviceType} from '../../../models/single-device.model';
import {GenericDevice, SingleD3Node, Topology} from '../../../models/topology';
import {ConnectedClientType} from '../../../models/clients.model';
import {BasicLink, D3Link, SingleLink} from '../../../models/single-link.model';
import {TopologyNodeType} from '../models/TopologyTypes.model';

export function isRegularDevice(element: SingleD3Node<GenericDevice<any>>): boolean {
  return element && !isClient(element) && !isMultiClient(element) && !isZipper(element) && Object.values(SingleDeviceType).includes(element.data.type);

}

export function isD3CloudNode(element: SingleD3Node<GenericDevice<any>>): boolean {
  return element && element.data.originalData && element.data.originalData.hasOwnProperty('node');

}

export function isFailedClients(element: SingleD3Node<GenericDevice<any>>): boolean {
  return !!(element && element.data.originalData && element.data.originalData.failedClients);

}

/**
 * @method isNotMultiClient A Type Safe gaurd. Return true if the specifice node is an array of clients,
 * i.g., a grouped of clients.
 * @param element Current node in the topology
 */
export function isMultiClient(element: SingleD3Node<GenericDevice<any>>): boolean {
  return !!(element && element.data.originalData && Array.isArray(element.data.originalData) && element.data.originalData[0] && element.data.originalData[0].originalData && element.data.originalData[0].originalData.connectedClientType);

}

/**
 * @method isNotClient A Type Safe gaurd. Return true if the specifice node a client or multi client,
 * @param element Current node in the topology
 */
export function isClient(element: SingleD3Node<GenericDevice<any>>): boolean {
  return element && Object.values(ConnectedClientType).includes(element.data.type) && !isMultiClient(element);
}

/**
 * @method isNotMultiClient A Type Safe gaurd. Return true if the specifice node is an array of clients,
 * i.g., a grouped of clients.
 * @param element Current node in the topology
 */
export function isGenericIsMultiClient(element: GenericDevice<any>): boolean {
  return !!(element && element.originalData && Array.isArray(element.originalData) && element.originalData[0] && element.originalData[0].originalData && element.originalData[0].originalData.connectedClientType);

}

/**
 * @method isNotClient A Type Safe gaurd. Return true if the specifice node a client or multi client,
 * @param element Current node in the topology
 */
export function isGenericIsClient(element: GenericDevice<any>): boolean {
  return element && Object.values(ConnectedClientType).includes(element.type) && !isGenericIsMultiClient(element);
}

export function isDevice(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return !!(element && !isDisconnected(element) && !isZipper(element) && !isLink(element) &&
    (element as SingleD3Node<GenericDevice<any>>).data &&
    (element as SingleD3Node<GenericDevice<any>>).data.originalData);

}

export function isZipper(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return !!(element && !isDisconnected(element) && !isLink(element) &&
    (element as SingleD3Node<GenericDevice<any>>).data && (element as SingleD3Node<GenericDevice<any>>).data.isZipper);
}

export function isZipperChildrenInZipMode(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return (element as SingleD3Node<GenericDevice<any>>) &&
    (element as SingleD3Node<GenericDevice<any>>).children &&
    (element as SingleD3Node<GenericDevice<any>>).children.filter(child => (!isClient(child) && !isMultiClient(child)) && child.data.isToBeZipped && !child.data.isZipMode).length > 0;
}

export function isToBeZippedNode(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return !!(element && !isDisconnected(element) && !isLink(element) &&
    (element as SingleD3Node<GenericDevice<any>>).data && (element as SingleD3Node<GenericDevice<any>>).data.isToBeZipped);
}

export function isLink(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return element && element.hasOwnProperty('source');

}

export function isDisconnected(element: SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link): boolean {
  return element && !element.hasOwnProperty('data');


}

export function isCloudNode(element: GenericDevice<any>): boolean {
  return element && element.originalData && element.originalData.hasOwnProperty('node');
}

export function isDeviceCloud(type: TopologyNodeType): boolean {
  return type == SingleDeviceType.WWW;
}

export function isDeviceUnrecognized(type: TopologyNodeType): boolean {
  return type === SingleDeviceType.ExternalRouter || type === SingleDeviceType.UnidentifiedDevice;
}

export function isAnyFailedClients(topology: Topology<GenericDevice<SingleDevice>, SingleLink>): boolean {
  let isFailedClients = false;
  topology.nodes.forEach(node => {
    if (node.originalData.failedClients && node.originalData.failedClients.length > 1) {
      isFailedClients = true;
    }
  });
  return isFailedClients;
}

export function isSameDevice(deviceData: SingleDevice, clickedNode: SingleD3Node<GenericDevice<any>>) {
  return deviceData && clickedNode.data.id == deviceData.device.id;
}

export function hasVendorName(element: SingleD3Node<GenericDevice<any>>) {
  return isRegularDevice(element) && (element.data.originalData as SingleDevice) && (element.data.originalData as SingleDevice).device &&
    (element.data.originalData as SingleDevice).device.vendorName;
}

export function isDeviceLink(link: BasicLink | SingleLink) {
  return link.hasOwnProperty('startPort');
}
