import {Injectable} from '@angular/core';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {KpiType, TrafficValueAndSize} from 'src/app/shared/models/kpi.model';
import {BaseDeviationBarService} from 'src/app/shared/pages/reports/models/base-deviation-bar-service';
import {IBaseDeviationBar} from 'src/app/shared/pages/reports/models/Ibase-deviation-bar';
import {
  OutlierType,
  TrafficDeviation,
  TrafficDeviationDisplay,
  TrafficOrigin
} from '../../../../../models/legacy-reports.model';
import {FromToDatesStrings} from "../../../../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class TrafficDeviationService extends BaseDeviationBarService implements IBaseDeviationBar {

  constructor(protected dateConvertor: TimeManagerService,
              private kpiDataService: KpiDataService) {
    super(dateConvertor)
  }

  getBarDatasets(trafficDeviation: TrafficDeviationDisplay[], datesAsString: FromToDatesStrings): VerticalBarData {
    let displayData = this.getDisplayData(trafficDeviation, datesAsString);
    let data: VerticalBarData = {
      datasets: [
        {
          data: displayData.dataDisplay.map(datum => datum.underDeviation),
          backgroundColor: '#f39915',
          label: "Under Mean",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.underDeviation),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.value),
          backgroundColor: '#2ed47a',
          label: "Daily Traffic",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.value),
          tooltipTitle: displayData.tooltipTitle,
          tooltipFooter: this.generateFooterText(displayData.dataDisplay),
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.aboveDeviation),
          backgroundColor: '#f7685b',
          label: "Above Mean",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.aboveDeviation),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        }
      ],
      labels: this.getFormattedDates(datesAsString)
    };
    return data;
  }

  getDisplayData(trafficDeviation: TrafficDeviationDisplay[], datesAsString: FromToDatesStrings) {
    let dataForDisplay: {
      underDeviation: number,
      aboveDeviation: number,
      value: number
    }[] = [];

    let tooltipForDisplay: {
      underDeviation: string,
      aboveDeviation: string,
      value: string
    }[] = [];
    let tooltipTitle: string[] = [];

    trafficDeviation.forEach((deviationData, index) => {
      if (deviationData) {
        dataForDisplay.push({
          underDeviation: this.getUnderDeviation(deviationData),
          aboveDeviation: this.getAboveDeviation(deviationData),
          value: this.getTraffic(deviationData)
        })
        tooltipForDisplay.push({
          underDeviation: this.getUnderDeviationTooltip(deviationData),
          aboveDeviation: this.getAboveDeviationTooltip(deviationData),
          value: this.getNoDeviationTooltip(deviationData)
        })
        tooltipTitle.push(`${this.getFormattedDates(datesAsString)[index]}`);
      }
    })
    return {dataDisplay: dataForDisplay, tooltipDisplay: tooltipForDisplay, tooltipTitle: tooltipTitle};
  }

  getUnderDeviationTooltip(deviationData: TrafficDeviationDisplay) {
    if (this.getUnderDeviation(deviationData) > 0) {
      if (deviationData["outlier-type"] == OutlierType.STRONG) {
        return `${deviationData.value.toFixed(2)} ${deviationData.unit} significantly smaller than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} traffic ${deviationData.dayOfWeekMean.toFixed(2)} ${deviationData.unit}`
      }
      if (deviationData["outlier-type"] == OutlierType.WEAK) {
        return `${deviationData.value.toFixed(2)} ${deviationData.unit} smaller than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} traffic ${deviationData.dayOfWeekMean.toFixed(2)} ${deviationData.unit}`
      }
    }
    return null;
  }

  getAboveDeviationTooltip(deviationData: TrafficDeviationDisplay) {
    if (this.getAboveDeviation(deviationData) > 0) {
      if (deviationData["outlier-type"] == OutlierType.STRONG) {
        return `${deviationData.value.toFixed(2)} ${deviationData.unit} significantly larger than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} traffic ${deviationData.dayOfWeekMean.toFixed(2)} ${deviationData.unit}`
      }
      if (deviationData["outlier-type"] == OutlierType.WEAK) {
        return `${deviationData.value.toFixed(2)} ${deviationData.unit} larger than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} traffic ${deviationData.dayOfWeekMean.toFixed(2)} ${deviationData.unit}`
      }
    }
    return null;
  }

  getNoDeviationTooltip(deviationData: TrafficDeviationDisplay) {
    if (!deviationData["outlier-type"])
      return `Traffic - ${this.getTraffic(deviationData)} ${deviationData.unit}`;
    return null;

  }

  getTrafficBySize(trafficDeviation: TrafficDeviation[]) {
    let valueAndUnit: TrafficValueAndSize;
    let formattedTrafficDeviation: TrafficDeviationDisplay[] = [];
    let highestTraffic: number = 0;
    trafficDeviation.forEach(deviation => {
      if (deviation && deviation.value && deviation.value > highestTraffic)
        highestTraffic = deviation.value;
    });
    valueAndUnit = this.kpiDataService.getBytesSizeAfterCalc(highestTraffic);
    trafficDeviation.forEach(deviation => {
      formattedTrafficDeviation.push({
        date: deviation.date,
        dayOfWeekMean: deviation.dayOfWeekMean ? this.kpiDataService.divideByTrafficUnit(deviation.dayOfWeekMean, valueAndUnit.size, KpiType.Traffic) : deviation.dayOfWeekMean,
        dayOfWeekStddev: deviation.dayOfWeekStddev ? this.kpiDataService.divideByTrafficUnit(deviation.dayOfWeekStddev, valueAndUnit.size, KpiType.Traffic) : deviation.dayOfWeekStddev,
        value: deviation.value ? this.kpiDataService.divideByTrafficUnit(deviation.value, valueAndUnit.size, KpiType.Traffic) : null,
        "outlier-type": deviation["outlier-type"],
        unit: valueAndUnit.size
      })
    });
    return {data: formattedTrafficDeviation, unit: valueAndUnit.size};
  }

  isDeviationByTraffic(origin: TrafficOrigin): boolean {
    switch (origin) {
      case TrafficOrigin.DEVICE:
        return true;
      case TrafficOrigin.VPN_CONN:
        return true;
      case TrafficOrigin.APP:
        return true;
      case TrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return false;
      case TrafficOrigin.DEVICE_BY_CLIENTS:
        return false;
      default:
        break;
    }
  }
}
