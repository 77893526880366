import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {KpiData} from '../../models/kpi.model';

@Component({
  selector: 'app-single-widgets-bar',
  templateUrl: './single-widgets-bar.component.html',
  styleUrls: ['./single-widgets-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleWidgetsBarComponent {
  @Input() widgetData: KpiData;
  @Input() isSelectable: boolean = true;
  /**
   * @param isSelected Recieve boolean value from the parent container.
   * The value indicate if the current element was clicked
   */
  @Input() isSelected: boolean;

  constructor() {
  }


  /**
   * @method changeClassWhenClicked changes the class of the selected widget element
   */
  get clickedElementClass() {
    if (this.isSelected)
      return 'widgets-clicked-header-column';
    else
      return 'widgets-header-column'
  }
}
