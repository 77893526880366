import {Injectable} from '@angular/core';
import {BasicLink, SingleLinkStatus, SingleLinkType} from '../../../models/single-link.model';
import {EditStringsService} from '../../../services/strategies/edit-strings.service';
import {CloudLinkType} from "../../../models/cloud-network.model";
import {TimeManagerService} from "../../../services/time-manager.service";


@Injectable({
  providedIn: 'root'
})
export class InitatePropertiesDataService {
  arrayProps: any[] = [];

  constructor(private dateConversionService: TimeManagerService,
              private editStringsService: EditStringsService
  ) {
  }

  /**
   * @method setPropertiesAsObject Receive properties array of key, value objects and convert it into an object
   * @param data Array of key,value objects
   */
  flattenProperties(data: any) {
    this.arrayProps = [];
    this.turnObjectIntoFlat(data);
    if (this.arrayProps) {
      let propObj: any = [];
      for (let i = 0; i < this.arrayProps.length; i++) {
        propObj[this.arrayProps[i].key] = this.arrayProps[i].value;
      }
      return propObj;
    }
  }

  private turnObjectIntoFlat(obj: any) {
    for (let [key, value] of Object.entries(obj)) {
      if (key.toLowerCase() === "createdat") {
        this.arrayProps.push({
          // key: this.editStringsService.convertKeytoCamelCaseWord(key),
          key: key,
          value: this.dateConversionService.dateByFormat(+value, 'DD-MMM-YYYY HH:mm')
        })
      }
      if (Array.isArray(value)) {
        // this.arrayProps.push({key: this.editStringsService.convertKeytoCamelCaseWord(key), value: value.length});
        this.arrayProps.push({key: key, value: value.length});
      } else if (value && value instanceof Object) {
        this.turnObjectIntoFlat(value)
      } else if (value !== null && value !== undefined && value !== "" && !this.isKeyWithId(key) && key.toLowerCase() !== "createdat")
        // this.arrayProps.push({key: this.editStringsService.convertKeytoCamelCaseWord(key), value: value.toString()})
         this.arrayProps.push({key: key, value: value.toString()})
    }
  }

  /**
   * @method isKeyWithId Check if a key had "id" in his name.
   * If Yes, and if it follow product demands: it return true
   * @param key The current key
   */
  private isKeyWithId(key: string) {
    if (key.includes("Id") && !key.includes("default") && key != "vlanId" || key == "id")
      return true;
    return false;
  }

  getLinkCurrentStatue(link: BasicLink) {
    let currentStatus: CurrentStatus = {
      status: link.status ? link.status : null,
      type: link.type ? link.type : null
    }
    return currentStatus;
  }

    multiPercentageBy100(healthDegradationReasons: { percentage?: number; reason: string; }[]): { percentage?: number; reason: string; }[] {
    if (healthDegradationReasons)
      healthDegradationReasons.forEach(element => {
        if (element.percentage && (element.percentage >= 0 && element.percentage <= 1))
          element.percentage = -element.percentage * 100;
      });
    return healthDegradationReasons;
  }

  multiHealthBy100(health: number) {
    if (health >= 0 && health <= 1)
      return health * 100;
    if (health == -1)
      return health * -100;
    return health;
  }


}

export class CurrentStatus {
  status: SingleLinkStatus;
  type: SingleLinkType | CloudLinkType;
}
