import { Injectable } from '@angular/core';
import { IAppState } from 'src/app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromCurrentEntity from 'src/app/store/selectors/entities.selector';
import { getRouterReducerState } from 'src/app/store/router/reducers/router.selector'
import { MergedRouteReducerState } from 'src/app/store/router/reducers/merged-route';
import { Observable } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Entity } from '../../models/entity.model';
import { EntityType } from '../../models/entity-type.enum';
import { selectHierarchy } from 'src/app/store/selectors/hierarchy.selector';
import { Hierarchy } from '../../models/hierarchy.model';

/**
 * This service is a parent for almsot all REST services.
 * It provide them data ragarding the app Entities. e.g., Organizations, Tenants and Users
 *
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalEntitiesService {

  /**
   * @param organizationsDetails is the selector for the organiztions list, that is stored on ngrx-store
   */
  // organizationsDetails = this.appStore.pipe(select(entitiesGlobalStore.selet));

  /**
   * @param tenantsDetails is the selector for the tenants list, that is stored on ngrx-store
   */
  //tenantsDetails = this.appStore.pipe(select(entitiesGlobalStore.selectOrgTenants));

  /**
  * @param selectedTenantDetails is the selector for the tenant that was chosen, that is stored on ngrx-store
  * In case that the user exit the screens that are relevant only for chosen tenant -
  * this parameter will bu nulled. @see getActiveTenantIdFromRouter
  */
  //selectedTenantDetails = this.appStore.pipe(select(entitiesGlobalStore.selectedTenant));

  /**
  * @param router is the selector for the router data, as is saved on MergedRouteReducerState
  *
  */
  router = this.store.pipe(select(getRouterReducerState));

  /**
  * @param orgId contains the current active org, and is being used by services who need this data
  * Notice: currently there is no mechanism for selecting on org from organizations list.
  * @see getActiveOrgId
  */
  // TODO: this should be converted to observable
  // orgId: number;
  //orgId$: Observable<number>;

  /**
 * @param tenantId contains the current active tenant, and is being used by services who need this data
 */
  highLevelOrg$: Observable<Hierarchy>;
  orgId$: Observable<number>;
  tenantId$: Observable<number>;
  currentEntity$: Observable<Entity>;
  prevEntity$: Observable<Entity>;
  nextEntity$: Observable<Entity>;
  currentVenue$: Observable<Entity>;
  currentFabric$: Observable<Entity>;
  currentDevice$: Observable<Entity>;
  currentEntityType$: Observable<EntityType>;
  closestVenue$: Observable<Entity>;
  currentEntityId$: Observable<number>;
  //tenantId$: Observable<number>;;

  /**
 * @param userId contains the current active user, and is being used by services who need this data
 */
  userId$: Observable<number>;

  // token: string;


  constructor(private store: Store<IAppState | MergedRouteReducerState>
  ) {
    this.highLevelOrg$ = this.store.select(selectHierarchy).pipe(
      filter((org) => org.length > 0),
      distinctUntilChanged((oldOrg: Hierarchy, newOrg: Hierarchy) => oldOrg[0].id == newOrg[0].id),
    );
    this.orgId$ = this.store.select(fromCurrentEntity.selectClosestOrgId).pipe(
      filter((orgId) => !!orgId),
      distinctUntilChanged((oldOrgId: number, newOrgId: number) => oldOrgId == newOrgId),
    );
    this.tenantId$ = this.store.select(fromCurrentEntity.selectClosestTenantId).pipe(
      filter((tenantId) => !!tenantId),
      distinctUntilChanged((oldTenantId: number, newTenantId: number) => oldTenantId == newTenantId),
    );
    this.currentEntity$ = this.store.select(fromCurrentEntity.selectCurrentEntity).pipe(
      // tap(entity => console.log('current entity %o', entity)),
      filter((entity) => !!entity),
      distinctUntilChanged((oldEntity: Entity, newEntity: Entity) =>
        oldEntity.id == newEntity.id && oldEntity.type == newEntity.type),

    );
    this.prevEntity$ = this.store.select(fromCurrentEntity.selectPrevEntity).pipe(
      // tap(entity => console.log('current entity %o', entity)),
      filter((entity) => !!entity),
      distinctUntilChanged((oldEntity: Entity, newEntity: Entity) =>
        oldEntity.id == newEntity.id && oldEntity.type == newEntity.type),

    );
    this.nextEntity$ = this.store.select(fromCurrentEntity.selectNextEntity).pipe(
      // tap(entity => console.log('current entity %o', entity)),
      filter((entity) => !!entity),
      distinctUntilChanged((oldEntity: Entity, newEntity: Entity) =>
        oldEntity.id == newEntity.id && oldEntity.type == newEntity.type),

    );
    this.currentEntityId$ = this.currentEntity$.pipe(
      map(entity => entity.id),
      distinctUntilChanged((oldEntityId: number, newEntityId: number) => oldEntityId == newEntityId),
    );
    this.currentEntityType$ = this.currentEntity$.pipe(
      map(entity => entity.type),
      distinctUntilChanged((oldEntityType: EntityType, newEntityType: EntityType) => oldEntityType == newEntityType),
    );

    this.closestVenue$ = this.store.select(fromCurrentEntity.selectClosestVenue).pipe(
      filter((entity) => !!entity),
      // tap(entity => console.log("closest venue emit %o", entity)),
      distinctUntilChanged(
        null, (venue) => {
          // console.log("Key function called venue %o", venue)
          return venue.id
        }),
      // tap(entity => console.log("closest after venue emit %o", entity)),
    );
    /**
     * All next three observables, are programmed to emit only if the current value of the specific entity id has changed
     */
    this.currentVenue$ = this.store.select(fromCurrentEntity.selectCurrentVenue).pipe(
      filter((entity) => !!entity),
      //distinctUntilChanged should ignore the id type, which might be string or number
      //(that why we are not using "===" but "==")
      distinctUntilChanged((oldVenue: Entity, newVenue: Entity) => oldVenue.id == newVenue.id),
    )
    this.currentDevice$ = this.store.select(fromCurrentEntity.selectCurrentDevice).pipe(
      filter((entity) => !!entity),
      //distinctUntilChanged should ignore the id type, which might be string or number
      //(that why we are not using "===" but "==")
      distinctUntilChanged((oldDevice: Entity, newDevice: Entity) => oldDevice.id == newDevice.id),
    )
    this.currentFabric$ = this.store.select(fromCurrentEntity.selectCurrentFabric).pipe(
      filter((entity) => !!entity),
      //distinctUntilChanged should ignore the id type, which might be string or number
      //(that why we are not using "===" but "==")
      distinctUntilChanged((oldFabric: Entity, newFabric: Entity) => oldFabric.id == newFabric.id),
    )
  }

}
