<div [@hoverTooltip]="animationTrigger">
    <app-topology-tooltip *ngIf="node" [nodeEvent]="node" (clickOnTitle)="goToDevice($event)" [title]="title"
        [tplgRect]="tplgRect" (isHover)="isHoverTooltip($event)" [tooltipType]="tooltipType">
        <ng-container>
            <div class="row">
                <div class="col">
                    <ng-container *ngIf="!isHealthNotCalculated">
                        <div class="tplg-tooltip-content-title">{{'data.COMMON.HEALTH_DEGRADATIONS' | translate}}:</div>
                        <div class="row tplg-tooltip-content pl-4"
                            *ngFor="let item of node.d.data.originalData?.healthDegradationReasons">
                            <i class="col tplg-tooltip-icon"
                                [inlineSVG]="'assets/media/netop/health-issues-icon.svg'"></i>
                            <span *ngIf="item.percentage" class="col tplg-tooltip-text-percentage"
                                [ngStyle]="{color: getPercentageColor(item.percentage)}">{{item.percentage.toFixed(0)}}%</span>
                            <span class="col tplg-tooltip-text-reason">{{item.reason}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isHealthNotCalculated">
                        <div class="row tplg-tooltip-content">
                            <div class="col pl-4 pt-2 tplg-tooltip-text">
                                {{'data.COMMON.HEALTH_NOT_CALCULATED' | translate}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="isFailedConnection" class="col tplg-failed-client-col">
                    <div class="tplg-tooltip-content-title">{{'data.CLIENTS.FAILED_CONNECTIONS' | translate}}:</div>
                    <div class="tplg-tooltip-content-failed-container">
                        <div *ngFor="let connection of failedClients" class="tplg-tooltip-content-failed">
                            {{connection.clientMac}} : {{connection.type}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </app-topology-tooltip>
</div>
