<div class="kt-form kt-form--group kt-margin-10">
    <div class="kt-form__section kt-form__section--first ml-3">
        <div class="form-group kt-form__group row p-0">
            <label class="col-lg-4 mt-3" for="repeatEvery">{{ 'data.TENANTS.BACKUP.REPEAT' | translate }}</label>
            <mat-form-field class="period-selection-form-field mat-form-field-fluid">
                <mat-select [(ngModel)]="selectedCategory" [disabled]="isDisabled"
                    (selectionChange)="onPeriodSelectionChanges($event)">
                    <mat-option value="daily">
                        {{ 'data.TENANTS.BACKUP.DAY' | translate }}
                    </mat-option>
                    <mat-option value="weekly">
                        {{ 'data.TENANTS.BACKUP.WEEK' | translate }} </mat-option>
                    <mat-option value="monthly">
                        {{ 'data.TENANTS.BACKUP.MONTH' | translate }} </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="showDay()">
                <div class="form-group kt-form__group row">
                    <label class="mt-3 ml-4 mr-3">{{ 'data.TENANTS.BACKUP.EVERY' | translate }}</label>
                    <mat-form-field class="col-lg-2 mat-form-field-fluid">
                        <mat-select (selectionChange)="cronInterpreter()" [disabled]="isDisabled"
                            [(ngModel)]="state?.daily.everyDays.days">
                            <mat-option *ngFor="let monthDay of selectOptions.monthDays" [value]="monthDay">
                                {{monthDay}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <label class="col-lg-2 mt-3">{{ 'data.TENANTS.BACKUP.DAYS_AT' | translate }}</label>
                    <mat-form-field class="col-lg-2 mat-form-field-fluid daily-form-field-flex">
                        <input matInput type="time" (change)="cronInterpreter()" [(ngModel)]="chosenTime"
                            [disabled]="isDisabled">
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="showWeek()">
                <label class="mt-3 ml-4 mr-3">{{ 'data.TENANTS.BACKUP.AT' | translate }}</label>
                <mat-form-field class="col-lg-4 week-at-form-dield mat-form-field-fluid">
                    <input matInput type="time" (change)="cronInterpreter()" [(ngModel)]="chosenTime"
                        [disabled]="isDisabled">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="showMonth()">
                <div class="show-month-container">
                    <label class="">{{ 'data.TENANTS.BACKUP.EVERY' | translate }}</label>
                    <mat-form-field class="pl-3 input-field-width-20">
                        <mat-select [(ngModel)]="state?.monthly.specificDay.day" (selectionChange)="cronInterpreter()"
                            [disabled]="isDisabled">
                            <mat-option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                value="{{monthDaysWithLast}}">
                                {{monthDayDisplay(monthDaysWithLast)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <label class="mt-3 mr-3 ml-3">{{ 'data.TENANTS.BACKUP.OF_EVERY' | translate }}</label>
                    <mat-form-field class="input-field-width-20">
                        <mat-select #monthlyMonth [(ngModel)]="state?.monthly.specificDay.months"
                            (selectionChange)="cronInterpreter()" [disabled]="isDisabled">
                            <mat-option *ngFor="let month of selectOptions.months" [value]="month">
                                {{month}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <label class="mt-3">{{ 'data.TENANTS.BACKUP.MONTHS' | translate }}</label>
                    <label class="mt-3 mr-3 ml-3">{{ 'data.TENANTS.BACKUP.AT' | translate }}</label>
                    <mat-form-field class="input-field-width-20">
                        <input matInput type="time" (change)="cronInterpreter()" [(ngModel)]="chosenTime"
                            [disabled]="isDisabled">
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="showWeek()">
        <div class="form-group kt-margin-10">
            <label>{{ 'data.TENANTS.BACKUP.REPEAT_ON' | translate }}</label>
            <div class="kt-checkbox-inline">
                <!--SUN-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.SUN"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.SUNDAY' | translate }}</span>
                </label>
                <!--Mon-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.MON"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.MONDAY' | translate }}</span>
                </label>
                <!--Tue-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.TUE"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.TUESEDAY' | translate }}</span>
                </label>
                <!--Wed-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.WED"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.WENDESDAY' | translate }}</span>
                </label>
                <!--Thu-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.THU"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.TUESEDAY' | translate }}</span>
                </label>
                <!--Fri-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.FRI"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.FRIDAY' | translate }}</span>
                </label>
                <!--Sat-->
                <label class="kt-checkbox">
                    <input type="checkbox" (change)="cronInterpreter()" [(ngModel)]="state?.weekly.SAT"
                        [disabled]="isDisabled">
                    <span>{{ 'data.TENANTS.BACKUP.SUNDAY' | translate }}</span>
                </label>
            </div>
        </div>
    </ng-container>
</div>
