<div>
    <div class="row widget-container" [showSpinner]="isLoading" [diameter]="40">
        <div class="col kpi-bar-col" *ngFor="let prop of widgetsData" [ngClass]="prop.kpiWidgetClass">
            <app-single-widgets-bar (click)="widgetSelected(prop, $event)" [widgetData]="prop"
                [isSelected]="selectedWidgetType == prop.key">
            </app-single-widgets-bar>
        </div>
    </div>

    <div *ngIf="isWidgetSelected && !isEntityDevice" class="row kpi-bar-big-chart-container">
        <div class="col kpi-bar-big-chart-col">
            <app-single-widget-big-chart [kpiType]="selectedWidgetType" class="pt-3" [closeIconClass]="'col-no-flex'"
                [cssGraphClass]="'single-big-chart'" (closeChart)="closeChart()" [graphWidth]="graphWidth"
                [graphHeight]="graphHeight" [allowStacked]="true" [venueUtilizationData]="venueUtilizationData">
            </app-single-widget-big-chart>
        </div>
    </div>
</div>