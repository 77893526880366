import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {KpiType, TrafficUnits} from 'src/app/shared/models/kpi.model';
import {Breakpoints} from '@angular/cdk/layout';
import {GraphTrendSizeService} from 'src/app/shared/services/strategies/graph-trend-size.service';
import {TranslateService} from '@ngx-translate/core';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {BaseSingleDeviceDashboard} from 'src/app/devices/components/devices-single-components/device-health-dashboard/base-single-device-dashboard';
import {LoggerService} from 'src/app/shared/services/logger.service';
import {DevicesService} from 'src/app/shared/services/rest-services/devices.service';
import {BaseSingleEntityService} from 'src/app/shared/services/base-single-entity.service';
import {StoreKpisBarService} from '../kpis-bar/store-kpis-bar.service';

@Component({
  selector: 'app-device-single-chart',
  templateUrl: './device-single-chart.component.html',
  styleUrls: ['./device-single-chart.component.scss']
})
export class DeviceSingleChartComponent extends BaseSingleDeviceDashboard implements OnInit {
  graphWidth: number;
  graphHeight: number;
  trendPeriod = ["Day", "Week", "Month", "6 Months", "Year"];
  selectedTrendPeriod: UntypedFormGroup;
  chartTitle: string;
  selectedWidgetType: KpiType = KpiType.Health;

  constructor(
    protected translate: TranslateService,
    protected loggerFactory: LoggerService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected devicesService: DevicesService,
    private graphTrendSizeService: GraphTrendSizeService,
    private kpiDataService: KpiDataService,
    private storeKpisBarService: StoreKpisBarService,
    private fb: UntypedFormBuilder) {
    super(translate, loggerFactory, devicesService, baseSingleEntityService)
  }

  ngOnInit() {
    this.initTrendPeriodSelection();
    this.getDeviceBigChartSize();
    this.chartTitle = this.translate.instant('data.COMMON.DEVICE') + " " + this.selectedWidgetType.toString() + " (" + this.kpiDataService.getKpiUnit(this.selectedWidgetType) + ")";
    this.subscribeToKpiSelection();
  }

  subscribeToKpiSelection() {
    let kpiSelectionSubcs = this.storeKpisBarService.notifyKpiTypeAsObservable$.subscribe(kpiType => {
      if (kpiType) {
        this.selectedWidgetType = kpiType;
        if (kpiType !== KpiType.Traffic) {
          this.chartTitle = this.translate.instant('data.COMMON.DEVICE') + " " + this.selectedWidgetType.toString() + " (" + this.kpiDataService.getKpiUnit(this.selectedWidgetType) + ")";
        }
      }
    });
    this.subscription.push(kpiSelectionSubcs);
  }

  /**
   * @method calculateBigChartSize Responsible for the responsivness of the chart
   */
  private getDeviceBigChartSize() {
    let mediaWidth = {
      [Breakpoints.XLarge]: {width: 1200, heigh: 260},
      [Breakpoints.Large]: {width: 820, heigh: 260},
      [Breakpoints.Medium]: {width: 800, heigh: 250},
      [Breakpoints.Small]: {width: 300, heigh: 150},
    }
    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    this.graphWidth = width;
    this.graphHeight = height;
  }

  initTrendPeriodSelection() {
    this.selectedTrendPeriod = this.fb.group({
      selectedPeriod: [null, Validators.required]
    });
    const toSelect = this.trendPeriod.find(period => period === "Week");
    this.selectedTrendPeriod.get('selectedPeriod').setValue(toSelect);
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }

  onUnitCalculated(unit: TrafficUnits) {
    this.chartTitle = this.translate.instant('data.COMMON.DEVICE') + " " + this.selectedWidgetType.toString() + " (" + this.kpiDataService.getKpiUnit(this.selectedWidgetType, unit) + ")";

  }
}
