<div class="kt-portlet__head-label" [hidden]="noTitle">
  <span class="kt-portlet__head-icon" #refIcon>
    <!-- The icon can be provided either as a projected content or as a icon class (bootstrap one) -->
    <ng-content *ngIf="!icon" select="[portletIcon]"></ng-content>
    <i *ngIf="icon" [ngClass]="icon"></i>
  </span>
  <!-- The title can be provided either as a projected content or as a title text  -->
  <ng-content *ngIf="!title" select="[portletTitle]"></ng-content>
  <h3 *ngIf="title" class="kt-portlet__head-title" [innerHTML]="title" [ngClass]="titleClass"></h3>
  <ng-content select="[nextToTitle]"></ng-content>
</div>
<div class="kt-portlet__head-toolbar nowrap" #refTools [ngClass]="toolbarClass">
  <!-- default tool set -->
  <!-- We need a way to remove some of the default tools -->
  <ng-content select="[portletTools]"></ng-content>
  <ng-container *ngIf="!hideDefaultTools">
    <a class="btn btn-clean btn-primary-outline btn-pill btn-sm btn-icon btn-icon-md btn-toolbar" (click)="maximize()"
      *ngIf="portletSizeState != PortletSizeState.Maximized">
      <i class="la la-expand"></i>
    </a>
    <a class="btn btn-clean btn-pill btn-sm btn-icon btn-icon-md btn-toolbar" (click)="restoreSize()"
      *ngIf="portletSizeState != PortletSizeState.Normal">
      <i class="la la-2x la-compress"></i>
    </a>
  </ng-container>
</div>
