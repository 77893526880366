import {Injectable} from '@angular/core';
import {getDaysNumberBetweenDates} from "../../../operators/time-operator";
import {Observable} from "rxjs";
import {TrafficKpiDataDisplay} from "../../../models/kpi.model";
import {TimeRangeUnit, TimeUnit} from "../../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class KpiDisplayDataGeneratorService {
  readonly DIVIDE_BY_MILLION = 1000000;
  readonly FIVE_MINUTES_IN_SECONDS = 1000000;

  constructor() {
  }

  calculateFromDateByActionRange(actionDate: Date, currentDate: Date, daysToAdd: number, unit: TimeUnit = TimeUnit.DAYS): number {
    const daysAfterAction = getDaysNumberBetweenDates(currentDate, actionDate, unit);
    return daysAfterAction + daysToAdd;
  }

  convertThroughputToMBps(throughputSplitTrend: TrafficKpiDataDisplay[]) {
    const splitTrend = [...throughputSplitTrend];
    splitTrend.forEach(trend => trend.data.forEach(point => point.y / this.DIVIDE_BY_MILLION / this.FIVE_MINUTES_IN_SECONDS));
    return splitTrend;
  }
}
