import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-netop-dialog-sub-title',
  templateUrl: './netop-dialog-sub-title.component.html',
  styleUrls: ['./netop-dialog-sub-title.component.scss']
})
export class NetopDialogSubTitleComponent implements OnInit {
  @Input() cssClass: string;
  constructor() { }

  ngOnInit() {
  }

}
