import { Component, OnInit, Input } from '@angular/core';
import { rssiClientTable, RssiLabels } from 'src/app/shared/models/clients.model';

@Component({
  selector: 'app-multi-clients-table',
  templateUrl: './multi-clients-table.component.html',
  styleUrls: ['./multi-clients-table.component.scss']
})
export class MultiClientsTableComponent implements OnInit {
  @Input() clientsTable: rssiClientTable[];
  public rssiLabels = RssiLabels.getRssilabelsAsArray();

  constructor() { }

  ngOnInit() {
  }

  getCellKey(clientRow: any, label: string) {
    if (clientRow[label].key)
      return clientRow[label].key;
  }
  getCellValue(clientRow: any, label: string) {
    if (clientRow[label].value)
      return clientRow[label].value;
  }

}
