import {Injectable} from '@angular/core';
import {KpiType} from 'src/app/shared/models/kpi.model';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {BaseDeviationBarService} from 'src/app/shared/pages/reports/models/base-deviation-bar-service';
import {LossAndLatencyReports} from '../../../../../models/legacy-reports.model';
import {FromToDatesStrings} from "../../../../../models/time.model";
import {LossLatencyThreshold} from "../../../../../global-utils/loss-latency-threshold";

@Injectable({
  providedIn: 'root'
})
export class LossLatencyBarGeneratorService extends BaseDeviationBarService {

  constructor(protected dateConvertor: TimeManagerService,
              private kpiDataService: KpiDataService) {
    super(dateConvertor)
  }

  THRESHOLDS: LossLatencyThreshold;

  generateVerticalDatasets(lossAndLatencyDeviation: LossAndLatencyReports[], kpiType: KpiType, datesAsString: FromToDatesStrings): VerticalBarData {
    let displayData = this.getDisplayData(lossAndLatencyDeviation, kpiType);
    let data: VerticalBarData = {
      datasets: [
        {
          data: displayData.dataDisplay.map(datum => datum.normal),
          backgroundColor: '#2ed47a',
          label: this.getNormalLabel(kpiType),
          tooltipText: displayData.tooltipDisplay.map(datum => datum.normal),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.high),
          backgroundColor: '#f39915',
          label: this.getHighLabel(kpiType),
          tooltipText: displayData.tooltipDisplay.map(datum => datum.high),
          tooltipTitle: displayData.tooltipTitle,
          tooltipFooter: this.generateFooterText(displayData.dataDisplay),
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.critical),
          backgroundColor: '#f7685b',
          label: this.getCriticalLabel(kpiType),
          tooltipText: displayData.tooltipDisplay.map(datum => datum.critical),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        }
      ],
      labels: this.getFormattedDates(datesAsString)
    };
    return data;
  }

  getCriticalLabel(kpiType: KpiType): string {
    switch (kpiType) {
      case KpiType.Loss:
        return `Above ${this.THRESHOLDS.loss.critical}${this.kpiDataService.getKpiUnit(kpiType)}`;
      case KpiType.Latency:
        return `Above ${this.THRESHOLDS.latency.critical}${this.kpiDataService.getKpiUnit(kpiType)}`;
      default:
        break;
    }
  }

  getHighLabel(kpiType: KpiType): string {
    switch (kpiType) {
      case KpiType.Loss:
        return `Above ${this.THRESHOLDS.loss.high}${this.kpiDataService.getKpiUnit(kpiType)}`;
      case KpiType.Latency:
        return `Above ${this.THRESHOLDS.latency.high}${this.kpiDataService.getKpiUnit(kpiType)}`;
      default:
        break;
    }
  }

  getNormalLabel(kpiType: KpiType): string {
    switch (kpiType) {
      case KpiType.Loss:
        return `Under ${this.THRESHOLDS.loss.high}${this.kpiDataService.getKpiUnit(kpiType)}`;
      case KpiType.Latency:
        return `Under ${this.THRESHOLDS.latency.high}${this.kpiDataService.getKpiUnit(kpiType)}`;
      default:
        break;
    }
  }

  getDisplayData(lossAndLatencyDeviation: LossAndLatencyReports[], kpiType: KpiType) {
    let dataForDisplay: {
      critical: number,
      high: number,
      normal: number
    }[] = [];

    let tooltipForDisplay: {
      critical: string,
      high: string,
      normal: string
    }[] = [];
    let tooltipTitle: string[] = [];
    lossAndLatencyDeviation.forEach(point => {
      dataForDisplay.push({
        critical: this.getCriticalDeviation(point),
        high: this.getHighDeviation(point),
        normal: this.getNormalData(point)
      })
      tooltipForDisplay.push({
        critical: this.getCriticalDeviationTooltip(point, kpiType),
        high: this.getHighDeviationTooltip(point, kpiType),
        normal: this.getNormalDataTooltip(point, kpiType)
      })
      tooltipTitle.push(
        "% Of Measurements"
      )
    })

    return {dataDisplay: dataForDisplay, tooltipDisplay: tooltipForDisplay, tooltipTitle: tooltipTitle};
  }

  getNormalDataTooltip(point: LossAndLatencyReports, kpiType: KpiType): string {
    if (point.thresholds && point.thresholds.INFO) {
      switch (kpiType) {
        case KpiType.Loss:
          return `${(point.thresholds.INFO * 100 / point.total).toFixed(2)}% Under ${this.THRESHOLDS.loss.high}${this.kpiDataService.getKpiUnit(kpiType)}`
        case KpiType.Latency:
          return `${(point.thresholds.INFO * 100 / point.total).toFixed(2)}% Under ${this.THRESHOLDS.latency.high}${this.kpiDataService.getKpiUnit(kpiType)}`
        default:
          break;
      }
    }
  }

  getHighDeviationTooltip(point: LossAndLatencyReports, kpiType: KpiType): string {
    if (point.thresholds && point.thresholds.HIGH) {
      switch (kpiType) {
        case KpiType.Loss:
          return `${(point.thresholds.HIGH * 100 / point.total).toFixed(2)}% Above ${this.THRESHOLDS.loss.high}${this.kpiDataService.getKpiUnit(kpiType)}`
        case KpiType.Latency:
          return `${(point.thresholds.HIGH * 100 / point.total).toFixed(2)}% Above ${this.THRESHOLDS.latency.high}${this.kpiDataService.getKpiUnit(kpiType)}`
        default:
          break;
      }
    }
  }

  getCriticalDeviationTooltip(point: LossAndLatencyReports, kpiType: KpiType): string {
    if (point.thresholds && point.thresholds.CRITICAL) {
      switch (kpiType) {
        case KpiType.Loss:
          return `${(point.thresholds.CRITICAL * 100 / point.total).toFixed(2)}% Above ${this.THRESHOLDS.loss.critical}${this.kpiDataService.getKpiUnit(kpiType)}`;
        case KpiType.Latency:
          return `${(point.thresholds.CRITICAL * 100 / point.total).toFixed(2)}% Above ${this.THRESHOLDS.latency.critical}${this.kpiDataService.getKpiUnit(kpiType)}`;
        default:
          break;
      }
    }
  }

  getNormalData(point: LossAndLatencyReports): number {
    if (point.thresholds && point.thresholds.INFO) {
      return point.thresholds.INFO * 100 / point.total;
    }
  }

  getHighDeviation(point: LossAndLatencyReports): number {
    if (point.thresholds && point.thresholds.HIGH) {
      return point.thresholds.HIGH * 100 / point.total;
    }
  }

  getCriticalDeviation(point: LossAndLatencyReports): number {
    if (point.thresholds && point.thresholds.CRITICAL) {
      return point.thresholds.CRITICAL * 100 / point.total;
    }
  }

  adujstResultToDates(deviationData: LossAndLatencyReports[], datesAsString: FromToDatesStrings) {
    let formattedDeviation: LossAndLatencyReports[] = [];
    let datesAsArray = this.getFormattedDates(datesAsString, false);
    datesAsArray.forEach(date => {
      let deviationByDate = this.findDeviation(date, deviationData);
      if (deviationByDate && deviationByDate != undefined) {
        formattedDeviation.push(deviationByDate as LossAndLatencyReports);
      } else {
        formattedDeviation.push({
          date: date,
          thresholds: null,
          total: null
        })
      }
    })
    return formattedDeviation;
  }
}
