import { Component, OnInit } from '@angular/core';
import {MisconfigurationCategory} from "../../../../../shared/models/fabrics.model";
import {convertEnumToArray} from "../../../../../shared/operators/enum-operators";

@Component({
  selector: 'app-venue-fabric-legends',
  templateUrl: './venue-fabric-legends.component.html',
  styleUrls: ['./venue-fabric-legends.component.scss']
})
export class VenueFabricLegendsComponent implements OnInit {
  legendNames: string[] = convertEnumToArray(MisconfigurationCategory);
  constructor() { }

  ngOnInit() {
  }

}
