import { Component, OnInit, OnDestroy } from '@angular/core';
import { INoRowsOverlayAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { IssueActionsDialogComponent } from 'src/app/shared/modals/issue-actions-dialog/issue-actions-dialog.component';
import { IssueActionData, IssueActionType, IssueAction } from 'src/app/shared/modals/issue-actions-dialog/issue-action-data';
import { SeverityService } from 'src/app/shared/services/strategies/severity.service';
import { ActionStatus } from 'src/app/shared/models/actions.model';
import { EntityGridActionsMenuService } from './entity-grid-actions-menu.service';
import { ActionsTrend } from 'src/app/shared/models/health.model';
import {EntityType} from "../../../models/entity-type.enum";

@Component({
  selector: 'app-actions-menu-renderer',
  templateUrl: './actions-menu-renderer.component.html',
  styleUrls: ['./actions-menu-renderer.component.scss']
})
export class ActionsMenuRendererComponent implements ICellRendererAngularComp, OnDestroy {
  private logger: Logger; components; columnDefs;
  categoryName: string;
  description: string;
  category: number;
  nextPossibleSteps: Array<ActionStatus>;
  params: ICellRendererParams;
  ActionStatus = ActionStatus;

  constructor(private translate: TranslateService,
    private loggerFactory: LoggerService,
    public dialog: MatDialog,
    private severityService: SeverityService) {
    this.logger = this.loggerFactory.getLogger("ActionsMenuRendererComponent");

  }

  agInit(params: ICellRendererParams): void {
    this.logger.debug("params %o", params);
    this.params = params;
    if (params.data) {
      this.categoryName = params.data.categoryName;
      this.category = params.data.category;
      this.description = params.data.description;
      this.nextPossibleSteps = params.data.nextPossibleSteps;
    }
  }
  refresh(params: any): boolean {
    return false;
  }
  ngOnDestroy(): void {
  }
  onAction(nextAction: ActionStatus) {

    this.logger.debug("onAction called next action %o", nextAction)
    if (!this.params.context ||
      !this.params.context.componentParent ||
      !this.params.context.componentParent.onActionPerformed) {
      this.logger.error("Could not find action or action method to fire");
    }
    this.params.context.componentParent.onActionPerformed(this.params.data.id, nextAction)
  }
  private openDialog(type: IssueActionType) {
    let severity;
    if ((this.params as any).severityStrategy) {
      severity = (this.params as any).severityStrategy(this.params.data)
    } else {
      severity = this.severityService.serverityByHealth(this.params.data.health)
    }
    this.logger.debug(`Found severity ${severity}`)

    const dialogRef = this.dialog.open(IssueActionsDialogComponent, {
      data: new IssueAction(type,
        severity,
        new IssueActionData(this.category,
          this.categoryName,
          this.description
        )
      )
    });

  }
}
