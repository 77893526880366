import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleAppearance } from '@angular/material/button-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';
import { ActionUserSelection } from '../action-configuration-dialog.component';

@Component({
  selector: 'app-user-selection-action-configurationa',
  templateUrl: './user-selection-action-configurationa.component.html',
  styleUrls: ['./user-selection-action-configurationa.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSelectionActionConfigurationaComponent {
  userSelection: ActionUserSelection = { configurationSelection: null, insightSelection: null };
  @Output() userSelectedEvent: EventEmitter<ActionUserSelection> = new EventEmitter();
  userQuestions: { key: string, type: ("toggle" | "pos-radio" | "neut-radio"), options: string[], step?: ("configurationSelection" | "insightSelection") }[] = [];

  constructor(private translate: TranslateService) {
    this.userQuestions.push({
      key: this.translate.instant('data.ACTIONS.DEFINE_CHANGES'),
      type: "toggle",
      options: ["Negative", "Neutral", "Possitive"],
      step: "configurationSelection"
    })
  }

  /**
 * Save user selection at userSelection variable. 
 * In Case of selection from the first toggle group (pssitive, neutral or negative): initate data for the next toggle group
 * @param event The selection event (could be button toggle or radio button)
 */
  storeUserSelection(event: MatButtonToggleChange | MatRadioChange) {
    if (event.source.name == 'configurationSelection') {
      this.userSelection.configurationSelection = event.value;
      //Initiate Default value for mat-radio-selection: "No"
      if (this.userSelection.configurationSelection == "Possitive" || this.userSelection.configurationSelection == "Neutral")
        this.userSelection.insightSelection = "No";
      this.userSelectedEvent.emit(this.userSelection);
      this.initiateNextStepValue();
    }
    if (event.source.name == 'insightSelection') {
      this.userSelection.insightSelection = event.value;
      this.userSelectedEvent.emit(this.userSelection);
    }
  }
  /**
 * Enter the relevent data for the second question the being presentes to the user
 * The text will be set according to the first selection
 */
  private initiateNextStepValue() {
    switch (this.userSelection.configurationSelection) {
      case "Negative":
        this.userQuestions[1] = {
          key: this.translate.instant('data.ACTIONS.SYSTEM_INSIGHT_VALUE_NEGATIVE'),
          type: "toggle",
          options: ["Minor", "Medium", "High"]
        };
        break;
      case "Neutral":
        this.userQuestions[1] = {
          key: this.translate.instant('data.ACTIONS.SYSTEM_INSIGHT_VALUE_NEUTRAL'),
          type: "neut-radio",
          options: ["Yes", "No"]
        };
        break;
      case "Possitive":
        this.userQuestions[1] = {
          key: this.translate.instant('data.ACTIONS.SYSTEM_INSIGHT_VALUE_POSSITIVE'),
          type: "pos-radio",
          options: ["Yes", "No"]
        };
        break;
      default:
        break;
    }
    if (this.userQuestions[1])
      this.userQuestions[1].step = "insightSelection";
  }

  /**
   * Initate the class for the button toggels selections
   * @param toggle The current toggle
   */
  getToggleButtonClass(toggle: string) {
    let lowerToggle = toggle.toLowerCase();
    if (lowerToggle == "possitive")
      lowerToggle = "minor";
    if (lowerToggle == "negative")
      lowerToggle = "high";
    return 'toggle-btn-' + lowerToggle;
  }

  get firstQuestion() {
    if (this.userQuestions[0])
      return this.userQuestions[0];
  }
  get secondQuestion() {
    if (this.userQuestions[1])
      return this.userQuestions[1];
  }
  get isRadioAlreadyChecked() {
    if (this.userSelection.insightSelection)
      return true;
    return false;
  }
  get radioTemplateName() {
    if (this.secondQuestion.type == 'pos-radio')
      return 'posRadioOptions';
    if (this.secondQuestion.type == 'neut-radio')
      return 'neutRadioOptions';
  }
}
