import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeUnit} from "../../../models/time.model";
import {
  DEFAULT_TIME_BACK_BOUNDS,
  TIME_UNIT_BOUNDS,
  TimePeriodInputPayload,
  TimeUnitBounds
} from "../time-range-picker.model";
import {roundToNearest} from "../../../operators/numeric-operator";

@Component({
  selector: 'app-time-period-input',
  templateUrl: './time-period-input.component.html',
  styleUrls: ['./time-period-input.component.scss']
})
export class TimePeriodInputComponent implements OnInit {
  @Input() interval: number = 1;
  @Input() timeUnit: TimeUnit = TimeUnit.HOURS;
  @Input() timeUnitOptions: TimeUnit[] = [TimeUnit.MINUTES, TimeUnit.HOURS, TimeUnit.DAYS, TimeUnit.MONTHS];
  @Input() timeUnitBounds: TimeUnitBounds = DEFAULT_TIME_BACK_BOUNDS;
  @Input() prefix: string;
  @Output() periodChange = new EventEmitter<TimePeriodInputPayload>();

  private previousInterval: number;
  private previousTimeUnit: TimeUnit;

  constructor() {
  }

  ngOnInit(): void {
    this.previousInterval = this.interval;
    this.previousTimeUnit = this.timeUnit;
  }

  onIntervalChange(interval: number) {
    this.interval = interval;
  }

  onTimeUnitSelected(timeUnit: TimeUnit) {
    this.timeUnit = timeUnit;
    const minBound = this.minBound;
    const step = this.step;
    this.interval = roundToNearest(minBound, step);
  }

  onMenuOpened() {
    this.previousInterval = this.interval;
    this.previousTimeUnit = this.timeUnit;
  }

  onMenuClosed() {
    if (TimeUnit.minutes(this.interval, this.timeUnit) != TimeUnit.minutes(this.previousInterval, this.previousTimeUnit)) {
      this.previousInterval = this.interval;
      this.previousTimeUnit = this.timeUnit;
      this.periodChange.emit({
        interval: this.interval,
        timeUnit: this.timeUnit
      });
    }
  }

  get displayLabel(): string {
    let unit = this.timeUnit?.toLowerCase();
    unit = this.interval > 1 ? unit : unit?.slice(0, unit.length - 1);
    return `${this.prefix ? this.prefix + ' ' : ''}${this.interval} ${unit}`;
  }

  get minBound(): number {
    let bound = this.timeUnitBounds[this.timeUnit]?.min;
    const valid_bound = TIME_UNIT_BOUNDS[this.timeUnit]?.min;
    if (!bound || (valid_bound && bound < valid_bound)) {
      bound = valid_bound;
    }
    return bound || 1;
  }

  get maxBound(): number {
    let bound = this.timeUnitBounds[this.timeUnit]?.max;
    const valid_bound = TIME_UNIT_BOUNDS[this.timeUnit]?.max;
    if (!bound || (bound > valid_bound)) {
      bound = valid_bound;
    }
    return bound || 100;
  }

  get step(): number {
    return this.timeUnitBounds[this.timeUnit]?.step || 1;
  }
}
