import { v4 as uuid } from 'uuid';;
import { FormFieldsType } from 'src/app/shared/modals/models/form-actions.model';


export class FiltersBar<T, U>{
  name: string;
  data: FilterData<T, U>[];
  fieldType: FormFieldsType;
  selectAllText: string;
  isDisableSelection: boolean;
  searchable?: boolean;

  constructor(filterName: string, filterFieldType: FormFieldsType, filterData: FilterData<T, U>[], selectAllText: string, searchable: boolean = false) {
    this.data = filterData;
    this.name = filterName;
    this.selectAllText = selectAllText;
    this.fieldType = filterFieldType;
    this.searchable = searchable;
  }

  get isNoItemSelected() {
    return !this.data || this.data && this.data.filter(datum => datum.isSelected).length == 0;
  }

  /**
   * Check if the current data array has selectAll option.
   * If no - Add option as fake item
   */
  addSelectAllOption() {
    if (this.data && this.data.find(datum => datum.isFakeItem) === undefined && this.selectAllText !== undefined) {
      let selectAllItem: FilterData<any, any> = {
        id: uuid(),
        name: `${this.selectAllText}`,
        type: `${this.name}`,
        data: null,
        filterName: this.name,
        isSelected: this.isNoItemSelected ? true : false,
        isFakeItem: true
      };
      this.data ?
        this.data.unshift(selectAllItem) :
        this.data = [selectAllItem];
    }
  }
}

export class FilterData<T, U> {
  id: number;
  name: string;
  type: U;
  data: T;
  filterName: string;
  isSelected?: boolean;
  isFakeItem?: boolean;
}
