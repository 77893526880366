import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { GlobalEntitiesService } from './global-entities.service';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EntityType } from '../../models/entity-type.enum';
import { Entity } from '../../models/entity.model';
import { KpiDataService } from '../strategies/kpi-data.service';
import { TrafficDeviation, TopTrafficTotalDisplay, TopTrafficOrigin, TrafficOrigin, TopTrafficSingleDisplayData } from '../../models/legacy-reports.model';
import { TopTrafficService } from '../../components/dynamic-dashboard/components/traffic-statistics/services/top-traffic.service';

@Injectable({
  providedIn: 'root'
})
export class TrafficService extends GlobalEntitiesService {
  constructor(
    private topTrafficService: TopTrafficService,
    private kpiDataService: KpiDataService,
    private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getTrafficDeviationHistory(currentEntity: Entity, startDate: string, endDate: string, origin: TrafficOrigin): Observable<TrafficDeviation[]> {
    if (currentEntity.type == EntityType.TENANT || currentEntity.type == EntityType.VENUE) {
      return this.http.get<TrafficDeviation[]>(`dashboard/${currentEntity.type}/${currentEntity.id}/traffic/?origin=${origin}&start=${startDate}&end=${endDate}&totalDaysToScan=90`)
    }
  }

  getTopTrafficData(currentEntity: Entity, startDate: string, endDate: string, origin: TopTrafficOrigin): Observable<TopTrafficTotalDisplay> {
    if (currentEntity.type == EntityType.TENANT || currentEntity.type == EntityType.VENUE) {
      return this.http.get<any>(`dashboard/${currentEntity.type}/${currentEntity.id}/traffic/top/?origin=${origin}&start=${startDate}&end=${endDate}&totalDaysToScan=90&limit=5`).pipe(
        take(1),
        map(topData => {
          let isTraffic = this.topTrafficService.checkIsTraffic(origin);
          if (topData.data.length > 0) {
            let sizeAndUnit = this.kpiDataService.getBytesSizeAfterCalc(topData.value);
            let topDeviceAsGeneric: TopTrafficTotalDisplay = {
              calculatedData: isTraffic ? +sizeAndUnit.value.toFixed(2) : topData.value,
              originalData: topData.value,
              unit: isTraffic && sizeAndUnit ? sizeAndUnit.size : null,
              isTraffic: isTraffic,
              name: this.topTrafficService.getDatumEntityName(origin),
              data: topData.data.sort(this.topTrafficService.getTrafficParamByOrigin(origin, 'desc')).map(deviceData => {
                let sizeAndUnit = this.kpiDataService.getBytesSizeAfterCalc(this.topTrafficService.getEntityValue(deviceData, origin));
                return {
                  details: this.topTrafficService.getTrafficDataByOrigin(deviceData, origin),
                  calculatedData: (isTraffic && sizeAndUnit) ? +sizeAndUnit.value.toFixed(2) : null,
                  originalData: this.topTrafficService.getEntityValue(deviceData, origin),
                  unit: (isTraffic && sizeAndUnit) ? sizeAndUnit.size : null,
                  isTraffic: isTraffic,
                  label: this.topTrafficService.getEntityName(this.topTrafficService.getTrafficDataByOrigin(deviceData, origin)),
                  displayLabel: this.topTrafficService.generateDisplayLabel(deviceData, topData.value, origin),
                } as TopTrafficSingleDisplayData
              })
            }
            return topDeviceAsGeneric;
          }
          return null;
        })
      )
    }
  }
}

