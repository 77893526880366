import {Injectable} from "@angular/core";
import {VenueSummary} from "../../models/venues.model";
import {Observable, of} from "rxjs";
import {VenuesService} from "../../services/rest-services/venues.service";
import {tap} from "rxjs/operators";
import {SelectionOption} from "../select-list/select-option.model";

@Injectable()
export class VenuePickerStore {
  private venues: VenueSummary[];
  selections: SelectionOption[] = [];

  constructor(private venuesService: VenuesService) {
  }

  fetchVenues(): Observable<VenueSummary[]> {
    if (!this.venues) {
      return this.venuesService.fetchTenantVenuesSummary().pipe(
        tap(venues => this.venues = venues)
      );
    }
    return of(this.venues);
  }
}
