<ng-container *ngIf="!isHasInsights">
  <ng-container *ngIf="delimitedValues.length > 0">
    <div>{{delimitedValues[0]}}</div>
    <ul #tooltip="matTooltip" [matTooltip]="markerTooltipData" [matTooltipPosition]="'above'" matTooltipClass="marker-tooltip" matTooltipShowDelay="1000">
      <li *ngFor="let value of delimitedValues.slice(1)">{{value}}</li>
    </ul>
  </ng-container>
</ng-container>

<ng-container *ngIf="isHasInsights">
  <div class="analyze-pop-over-menu-wrapper">
    <button mat-button [matMenuTriggerFor]="popOverMenu" [disableRipple]="true" #tooltip="matTooltip"
            [matTooltip]="markerTooltipData" [matTooltipPosition]="'above'"
            matTooltipClass="marker-tooltip" matTooltipShowDelay="1000">{{content}}</button>
    <mat-menu #popOverMenu="matMenu" [class]="'analyze-pop-over-menu'">
      <app-netop-dialog>
        <div (click)="$event.stopPropagation()">
          <app-netop-dialog-title (close)="closeMenu()" [closeIconClass]="'col-no-flex'">
            {{title}}
          </app-netop-dialog-title>
          <app-netop-dialog-content>
            <div class="analyze-pop-over-container">
              <app-insight-resolutions-display [summary]="summary" [rootCauses]="rootCauses" [solutions]="solutions"
                                               [iconsName]="icons" (actionSelected)="onActionSelected($event)">
              </app-insight-resolutions-display>
            </div>
          </app-netop-dialog-content>
        </div>
      </app-netop-dialog>
    </mat-menu>
  </div>
</ng-container>
