<mat-list [ngClass]="cssList">
  <ng-content select="[searchInput]"></ng-content>
  <!--Select All item-->
  <mat-list-item class="checkbox-container" *ngIf="allowSelectAll">
    <mat-checkbox [(ngModel)]="checkAll" (change)="onCheckAllChange($event)" [ngClass]="checkboxCss">
      <div class="row">
        <div class="col">{{listCheckAllText}} </div>
      </div>
    </mat-checkbox>
  </mat-list-item>
  <mat-divider class="divider"></mat-divider>

  <!-- All other items-->
  <div class="checkbox-list-items-container">
    <ng-container *ngFor="let option of filteredOptionsAsObservable$ | async">
      <mat-list-item class="checkbox-container">
        <mat-checkbox [checkboxClass]="option?.checkboxClass?.classContent"
                      [checkboxClassName]="option?.checkboxClass?.className" [isSelected]="isOptionChecked(option)"
                      [checked]="isOptionChecked(option)"
                      [disabled]="!isOptionChecked(option) && selectionLimit && checkedOptions >= selectionLimit"
                      (change)="onCheckboxChange(option,$event)"
                      [ngClass]="checkboxCss">
        </mat-checkbox>
        <div class="row ml-0">
          <div class="col checkbox-list-text" [ngClass]="cssText" *ngFor="let field of displayedFields"
               [title]="option.data[field.fieldData[0].fieldName]">
            <ng-container *ngFor="let data of field.fieldData; let last = last">
                            <span class="span-text checkbox-list-text-label"
                                  [title]="getFieldContent(option.data, data)">
                                <strong>{{data.label ? data.label : null}}</strong>
                              {{getFieldContent(option.data, data)}}
                              {{!last ? ', ' : null}}
                            </span>
            </ng-container>
          </div>
        </div>
      </mat-list-item>
      <mat-divider class="divider"></mat-divider>
    </ng-container>
    <ng-content select="[noOptions]" *ngIf="!_options || _options?.length === 0"></ng-content>
  </div>
</mat-list>
