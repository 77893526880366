import {Injectable} from '@angular/core';
import {GraphTrendConfig, GraphTrendThemeConfig} from '../models/graph-trend-config';
import {KpiGroupedBy, KpiType, StackedKpiData, TrafficKpiDataDisplay, TrafficUnits} from '../../../models/kpi.model';
import {GraphTrendConfigService} from './graph-trend-config.service';
import {StackedBarConfigService} from './stacked-bar-config.service';
import {StackedBarConfig, StackedBarThemeConfig} from '../models/stacked-bar.config';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';

@Injectable({
  providedIn: 'root'
})
export class KpiMultiDataService {

  constructor(private graphTrendConfigService: GraphTrendConfigService,
              private stackedBarConfigService: StackedBarConfigService,
              private kpiDataService: KpiDataService) {
  }

  /**
   * Return GraphTrendConfig Object.
   * Check if there is need in stacked configuration
   * Currently (7.4.20), relevant only for traffic kpi in stacked mode
   * @param data The trend data
   * @param trendLineConfig The regular trend data config
   * @param kpiType The Kpi type
   * @param showStacked Is the user chose to see stacked data
   */
  setTrendLineConfig(data: any, trendLineConfig: GraphTrendConfig, kpiType: KpiType, groupedBy: KpiGroupedBy = undefined): GraphTrendConfig {
    if (kpiType) {
      if (kpiType === KpiType.Traffic || kpiType === KpiType.Throughput || kpiType === KpiType.Connection_Failures) {
        trendLineConfig = this.initializedStackedLineConfigArray(data, trendLineConfig, groupedBy);
      } else {
        const config = this.graphTrendConfigService.getKpiConfig(kpiType);
        trendLineConfig = {
          datasets: [{
            ...config, data
          }],
        }
      }
      return trendLineConfig;
    }
  }

  /**
   * Return GraphTrendConfig object for stacked chart data.
   * Create array with the stacked config data and push it the trendLineConfig.liineConfig array
   * @param stackedData The stacked data, divides by labels
   * @param trendLineConfig The current configuration for this trend
   */
  initializedStackedLineConfigArray(stackedData: TrafficKpiDataDisplay[], trendLineConfig: GraphTrendConfig, groupedBy: KpiGroupedBy = undefined): GraphTrendConfig {
    let stackedLineConfig: any[] = [];
    trendLineConfig = {datasets: []};
    if (stackedData) {
      stackedData.forEach((dataset, index) => {
        stackedLineConfig.push(this.stackedBarConfigService.getStackedOrSplitConfig(dataset.type, index, groupedBy));
      });
      stackedLineConfig.forEach((config, index) => {
        let data = stackedData[index].data;
        trendLineConfig.datasets.push({...config, data})
      });
      trendLineConfig.unit = stackedData && stackedData.length > 0 ? stackedData[0].unit : null;
    }
    return trendLineConfig;
  }

  /**
   * Merge multi datasets into one
   * @param stackedData The entire data array
   */
  mergeMultiDatasets(stackedData: StackedKpiData[]): StackedKpiData[] {
    if (stackedData.length > 0) {
      let stackedAsOne: StackedKpiData[] = [{
        type: "Traffic data",
        data: []
      }];
      let basicDatasetIndex = this.findLongestDataArray(stackedData);
      let sum: { x: string, y: number }[] = stackedData[basicDatasetIndex].data;
      stackedData.forEach((dataset, stackedIndex) => {
        if (stackedIndex !== basicDatasetIndex) {
          sum = stackedData[basicDatasetIndex].data.map((datum, dataIndex) => {
            return {
              x: datum.x,
              y: stackedData[stackedIndex].data[dataIndex] ?
                +(datum.y + stackedData[stackedIndex].data[dataIndex].y).toFixed(2) :
                +datum.y.toFixed(2)
            };
          })
        }
      });
      stackedAsOne[0].data = sum;
      return stackedAsOne;
    }
    return stackedData;
  }

  /**
   * Merge multi line config array with multi datasets to one
   */
  mergeMultiLineConfig(trendLineConfig: GraphTrendConfig): GraphTrendConfig {
    if (trendLineConfig) {
      let mergedConfig: any[] = [];
      let allDatasets: StackedKpiData[] = [];
      trendLineConfig.datasets.forEach(config => allDatasets.push({type: config.label, data: config.data}));
      let config = this.graphTrendConfigService.getKpiConfig(KpiType.Traffic);
      config.label = "Traffic data";
      mergedConfig[0] = {
        ...config,
        data: this.mergeMultiDatasets(allDatasets).length > 0 ? this.mergeMultiDatasets(allDatasets)[0].data : []
      };
      trendLineConfig.datasets = mergedConfig;
    }
    return trendLineConfig;
  }

  /**
   * Find the longest datum array in the stacked data array and return its index
   */
  findLongestDataArray(stackedData: StackedKpiData[]): number {
    let longestIndex: number = 0;
    let dataLongestArrayLength = 0;
    stackedData.forEach((datum, index) => {
      if (datum.data.length > dataLongestArrayLength) {
        dataLongestArrayLength = datum.data.length;
        longestIndex = index;
      }
    })
    return longestIndex;
  }


  /**
   * Prepare the stacked bar data configuraion. and return StackedBarConfig object
   * The preperation include the configuration style and the calculation of each bar percentage
   * @param stackedBarData The current stacked data
   */

  prepareStackedBarConfig(stackedBarData: GraphTrendConfig): StackedBarConfig {
    let formattedStackedBarConfig: StackedBarConfig;
    formattedStackedBarConfig = {datasets: this.generateStackedConfig(stackedBarData), responsive: true, total: 0};
    this.setBarDatasetsTotalAndPercentage(formattedStackedBarConfig);
    return formattedStackedBarConfig;
  }


  /**
   * Generate the stacked bar config
   * The method calculate the total values of each data set and set them as the data set only value
   * @param stackedBarData The current stacked data
   */
  private generateStackedConfig(stackedBarData: GraphTrendConfig): StackedBarThemeConfig[] {
    let stackedBarThemeConfig: StackedBarThemeConfig[] = [];
    stackedBarData.datasets.forEach((dataset, index) => {
      let total: number = 0;
      dataset.data.forEach(datum => {
        total += datum ? datum.y : 0;
      })
      stackedBarThemeConfig.push(this.stackedBarConfigService.getStackedBarConfig(dataset.label, +total.toFixed(2), index));
    })
    return stackedBarThemeConfig;
  }

  /**
   * For Each dataset - calculate the percentage that it takes from the total bar values
   * @param formattedStackedBarConfig The new stacked bar config
   */
  private setBarDatasetsTotalAndPercentage(formattedStackedBarConfig: StackedBarConfig) {
    let total = 0;
    formattedStackedBarConfig.datasets.forEach(config => total += config.data[0]);
    formattedStackedBarConfig.datasets.forEach(config => config.percentage = config.data[0] * 100 / total);
    formattedStackedBarConfig.total = +total.toFixed(2);
  }

  convertStackedToTrafficUnit(unit: TrafficUnits, stackedGraphConfig: GraphTrendConfig): GraphTrendConfig {
    if (stackedGraphConfig && unit !== stackedGraphConfig.unit) {
      stackedGraphConfig.datasets.forEach(config => {
        config.data.forEach(datum => {
          datum.y = this.kpiDataService.adujstTrafficUnits(datum.y, stackedGraphConfig.unit, unit);
        })
      });
    }
    return stackedGraphConfig;
  }

  /**
   * Return total dataset out of two datasets of the same dates
   * @param result
   */
  mergeTwoDatasetsWithSameDates(result: TrafficKpiDataDisplay[]) {
    if (result && result.length > 0) {
      let finalTrend = [];
      const resultWithTrend = result.filter(trend => trend && trend.data && trend.data.length > 0);
      if (resultWithTrend && resultWithTrend.length > 0) {
        const xKey = Object.keys(resultWithTrend[0].data[0])[0];
        const yKey = Object.keys(resultWithTrend[0].data[0])[1];
        for (let i = 0; i < resultWithTrend[0].data.length; i++) {
          const x = resultWithTrend[0].data[i][xKey];
          let totalY = 0;
          resultWithTrend.forEach(trend => totalY += trend.data[i][yKey]);
          finalTrend.push({[xKey]: x, [yKey]: totalY});
        }
      }
      return finalTrend;
    }
  }
}
