import {Injectable} from '@angular/core';
import {
  DNatView,
  GlobalPolicyView,
  OpenPortsAndPolicies, OpenPortsForExport,
  OpenPortsView, PolicyDisplay
} from "../../../../shared/models/open-ports.model";
import {Page} from "../../../../shared/models/page.model";
import {ExportPolicyDTO} from "../../../../shared/models/policies.model";
import {convertStringArray} from "../../../../shared/operators/string-opertators";

@Injectable({
  providedIn: 'root'
})
export class OpenPortsDisplayService {

  constructor() {
  }

  generateOpenPortsView(dNats: Array<DNatView>): Page<OpenPortsView> {
    let openPortsView: Array<OpenPortsView> = [];
    dNats.forEach(dNat => {
      openPortsView.push(
        new OpenPortsView(dNat)
      )
    })
    return {total: openPortsView.length, data: openPortsView};
  }

  generateGlobalPoliciesView(venuePolicies: Array<ExportPolicyDTO>): GlobalPolicyView {
    if (venuePolicies && venuePolicies.length > 0) {
      return new GlobalPolicyView(venuePolicies);
    }
  }

  /**
   * Format The data for export the open ports data to xlsx file
   */
  getFormattedDataForExport(formatted: OpenPortsForExport, data: OpenPortsAndPolicies) {
    let formattedTMP = {};
    //Format openPorts
    if (data && data.openPortsView) {
      data.openPortsView.data.forEach(datum => {
        formattedTMP = {};
        for (const [key, value] of Object.entries(datum)) {
          if (key === 'allow') {
            let policyAsText: string = (value as Array<PolicyDisplay>).map(policy => policy.rules.map(rule => rule.toPlainText)).toString();
            formattedTMP[key] = policyAsText;
          } else {
            formattedTMP[key] = convertStringArray(value);
          }
        }
        formatted["Open Ports"].push(formattedTMP);
      })
    }
    //Format globalPolicies
    if (data && data.policyView) {
      formattedTMP = {};
      data.policyView.policyArray.forEach(policy => {
        formatted["Global Policies"].push({policy: policy.rules.map(rule => rule.toPlainText).toString()});
      })
    }

    //Delete empty datasets
    for (const [key, value] of Object.entries(formatted)) {
      if (Array.isArray(value) && value.length === 0)
        delete formatted[key];
    }
    return formatted;
  }
}
