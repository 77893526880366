<app-netop-dialog>

  <app-netop-dialog-title (close)="closeDialog()">
    {{'data.MAINTENANCE.RESTORE' | translate}}
  </app-netop-dialog-title>
  <app-netop-dialog-content [cssClass]="'restore-dialog'">
    <div class="resotre-header">
      <h5 class="m-3 pl-3"> {{'data.MAINTENANCE.LATEST_RESTORES' | translate}}
      </h5>
      <div class="restore-total">{{'data.MAINTENANCE.TOTAL_BACKUPS' | translate}}: {{options?.length}}</div>
    </div>
    <div class="restore-list-container">
      <div [showSpinner]="isLoading" [diameter]="50">
        <app-checkbox-search-list [options]="options" [displayedFields]="disaplayedFields"
                                  [optionsListControl]="optionsListControl" [searchField]="'serial'"
                                  [allowSelectAll]="false"
                                  [allowMultiSelection]="false" [cssText]="'no-ellipse'"
                                  [cssList]="'restore-list-height'">
        </app-checkbox-search-list>
      </div>
    </div>
    <ng-container *ngIf="restoreForm">
      <div>
        <form [formGroup]="restoreForm">
          <div class="mb-3 mt-3">
            <mat-radio-group class="m-3" formControlName="deploy">
              <mat-radio-button value="now" [checked]="isNow">
                {{'data.MAINTENANCE.RESOTRE_NOW' | translate}}
              </mat-radio-button>
              <mat-radio-button value="later">
                {{'data.MAINTENANCE.RESOTRE_LATER' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <ng-container *ngIf="isLater">
            <div class="date-inputs mb-3">
              <mat-form-field class="mat-form-field-fluid mr-5">
                <input matInput type="date" formControlName="deployDate">
              </mat-form-field>
              <mat-form-field class="mat-form-field-fluid">
                <input matInput type="time" formControlName="deployHour">
              </mat-form-field>
            </div>
          </ng-container>
        </form>
      </div>
    </ng-container>
  </app-netop-dialog-content>

  <app-netop-dialog-actions>
    <!--buttons-->
    <div class="d-flex justify-content-end kt-margin-r-10">
      <mat-dialog-actions>
        <app-buttons (click)="closeDialog()" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
                     [btnText]="'data.COMMON.CANCEL'">
        </app-buttons>
        <app-buttons class="kt-margin-l-10" (click)="closeDialog()" [btnClass]="" [btnText]="'Restore'"
                     [btnDisabled]="true">
        </app-buttons>
      </mat-dialog-actions>
    </div>
  </app-netop-dialog-actions>

</app-netop-dialog>
