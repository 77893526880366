import {Injectable} from '@angular/core';
import {Hierarchy} from '../../../models/hierarchy.model';
import {ThemeService} from '../../theme.service';
import {OrgBranding} from '../../../models/branding.model';
import {Logger, LoggerService} from '../../logger.service';
import {ClientsPreferences} from "../../../models/client-storage.model";

@Injectable({
  providedIn: 'root'
})
export class StoreBrandingService {

  /**
   * Selected UI Object
   */
  selectedOrgUI: OrgBranding;
  /**
   * Default UI Object
   */
  defaultOrgUI: OrgBranding;
  /**
   * The current hierarchy object
   */
  hierarchy: Hierarchy;
  logger: Logger;

  constructor(
    private themeService: ThemeService,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("StoreBrandingService");
  }

  /**
   * Apply Branbing Form Selection
   */
  applyBrandingSelection(brandingSelection: OrgBranding) {
    this.selectedOrgUI = brandingSelection;
    this.setColorsSelection();
    this.logger.debug("storeBrandingService.selectedOrgUI", this.selectedOrgUI);
  }

  saveDefaultBrandingSelection(branding: OrgBranding) {
    this.defaultOrgUI = branding;
  }

  /**
   * Set the color theme selection at selectedOrgUI
   */
  setColorsSelection() {
    this.themeService.applyUserBrandingSelection(this.selectedOrgUI.themeToCssVariables(this.selectedOrgUI.themeColors));
  }

  applyClientStorageValues(firebaseSelection: ClientsPreferences<any>) {
    this.saveDefaultBrandingSelection(
      new OrgBranding(
        {...firebaseSelection.preferences.data.orgBranding.themeColors},
        firebaseSelection.preferences.data.orgBranding.companyName,
        firebaseSelection.preferences.data.orgBranding.buttonPalette,
        {
          file: null, url: firebaseSelection.preferences.data.orgBranding.companyLogoAsUrl,
        },
      )
    )
    this.applyBrandingSelection(
      new OrgBranding(
        {...firebaseSelection.preferences.data.orgBranding.themeColors},
        firebaseSelection.preferences.data.orgBranding.companyName,
        firebaseSelection.preferences.data.orgBranding.buttonPalette,
        {
          file: null, url: firebaseSelection.preferences.data.orgBranding.companyLogoAsUrl,
        },
      )
    )
  }
}
