export enum ActionSeverity {
    High = "High",
    Medium = "Medium",
    Minor = "Minor",
    Critical = "Critical"
}
export namespace ActionSeverity {
    export function toArray() {
        return [
            "Critical",
            "High",
            "Medium",
            "Minor",
        ]
    }
}