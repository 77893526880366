import { Component } from '@angular/core';
import { TrafficOrigin } from '../../../../../../models/legacy-reports.model';


@Component({
  selector: 'app-device-traffic-deviation-bar',
  templateUrl: './device-traffic-deviation-bar.component.html',
  styleUrls: ['./device-traffic-deviation-bar.component.scss']
})
export class DeviceTrafficDeviationBarComponent {
  origin: TrafficOrigin = TrafficOrigin.DEVICE;
}
