import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectionTreeNodeModel, TreeNodeSelectionEvent} from "./selection-tree.model";
import {Subscription} from "rxjs";
import {IconRegistryService} from "../../services/strategies/icon-registry.service";
import {FlatNodeWithIcon, TreeDisplay} from "../../models/utils-classes/base-netop-mat-tree.model";
import {sortArrayByObjectPath} from "../../operators/array.operator";
import {NgChanges} from "../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-selection-tree',
  templateUrl: './selection-tree.component.html',
  styleUrls: ['./selection-tree.component.scss']
})
export class SelectionTreeComponent extends TreeDisplay<any, any, any, SelectionTreeNodeModel> implements OnInit, OnChanges, OnDestroy {
  @Input() tree: SelectionTreeNodeModel[];
  @Input() isLoading: boolean = false;
  @Input() nodeSelected: (node: SelectionTreeNodeModel) => boolean;
  @Input() nodeEnabled: (node: SelectionTreeNodeModel) => boolean;
  @Input() nodeExpanded: (node: SelectionTreeNodeModel) => boolean;
  @Input() nodeLoading: (node: SelectionTreeNodeModel) => boolean;
  @Input() nodeColor: (node: SelectionTreeNodeModel) => string;

  @Output() selection = new EventEmitter<TreeNodeSelectionEvent>();
  @Output() expanded = new EventEmitter<SelectionTreeNodeModel>();
  @Output() collapsed = new EventEmitter<SelectionTreeNodeModel>();

  checklistSubscription: Subscription;

  constructor(private iconRegistryService: IconRegistryService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.loadTree();
  }

  ngOnChanges(changes: NgChanges<SelectionTreeComponent>) {
    if (changes.tree && this.tree) {
      this.loadTree();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromSelections();
  }

  onExpansionClick(node: FlatNodeWithIcon) {
    if (this.treeControl.isExpanded(node)) {
      this.expanded.emit(node.originalNode);
    }
    else {
      this.collapsed.emit(node.originalNode);
    }
  }

  loadTree() {
    if (this.tree) {
      this.filterTree();
      this.setTreeDisplay();
      this.sortNodes();
      this.reselectNodes();
      this.expandNodes();
      this.cdr.markForCheck();
    }
  }

  filterTree() {
    this.tree = this.tree.filter(node => {
      this.filterChildren(node);
      return node.lazyLoading || !this.isEmpty(node);
    });
  }

  private filterChildren(node: SelectionTreeNodeModel) {
    if (node.nodes != null) {
      node.nodes = node.nodes.filter(n => !this.isEmpty(n));
    }
  }

  private isEmpty(node: SelectionTreeNodeModel): boolean {
    if (node.nodes != null) {
      if (node.nodes.length == 0) {
        return true;
      }
      return node.nodes.filter(n => !this.isEmpty(n)).length == 0;
    }

    return false;
  }

  setTreeDisplay() {
    if (this.tree) {
      this.processTree = this.tree;
      this.dataSource.data = this.tree.slice();
    }
  }

  sortNodes() {
    this.treeControl.dataNodes.forEach(node => {
      if (node.originalNode.additionalData?.withSort && node.originalNode.nodes && node.originalNode.nodes.length > 0) {
        node.originalNode.nodes = node.originalNode.nodes.sort(sortArrayByObjectPath('additionalData.valueToSort', 'desc'));
      }
    });
    this.setTreeDisplay();
  }

  reselectNodes() {
    this.unsubscribeFromSelections();
    this.treeControl.dataNodes
      .filter(node => this.isSelected(node.originalNode))
      .forEach(node => {
        if (!node.expandable) {
          this.checklistSelection.select(node);
        }
      });
    this.subscribeToSelections();
  }

  expandNodes() {
    this.treeControl.dataNodes.forEach(node => {
      if (this.isExpanded(node.originalNode) && this.hasExpandedChildren(node.originalNode)) {
        this.treeControl.expand(node);
      }
    });
  }

  private hasExpandedChildren(node: SelectionTreeNodeModel) {
    if (node.nodes && node.nodes.length > 0) {
      if (this.isExpanded(node)) {
        return true;
      }

      return node.nodes.filter(n => this.hasExpandedChildren(n)).length > 0;
    }

    return false;
  }

  hasSearch(node: FlatNodeWithIcon<SelectionTreeNodeModel>) {
    return this.treeControl.isExpanded(node) && node.originalNode?.additionalData?.withSearch;
  }

  isNodeEnabled(node: FlatNodeWithIcon<SelectionTreeNodeModel>) {
    if (this.nodeEnabled) {
      return this.nodeEnabled(node?.originalNode);
    }
    else {
      return true;
    }
  }

  isNodeLoading(node: FlatNodeWithIcon<SelectionTreeNodeModel>) {
    return this.nodeLoading ? this.nodeLoading(node?.originalNode) : false;
  }

  getNodeColor(node: FlatNodeWithIcon<SelectionTreeNodeModel>) {
    if (this.nodeColor) {
      return this.nodeColor(node?.originalNode);
    }
  }

  getExpanderIcon(node: FlatNodeWithIcon) {
    return this.iconRegistryService.getExpanderIcon(this.treeControl.isExpanded(node));
  }

  subscribeToSelections() {
    this.checklistSubscription = this.checklistSelection.changed.subscribe(changes => {
      const action = changes.added && changes.added.length > 0 ? 'added' : 'removed';
      const selection = action == 'added' ? changes.added[0] : changes.removed[0];
      if (!selection.expandable) {
        if (selection.originalNode.additionalData) {
          selection.originalNode.additionalData.selected = action == 'added';
        }
        this.selection.emit({node: selection.originalNode, selected: action == 'added'});
      }
    });
  }

  private isSelected(node: SelectionTreeNodeModel): boolean {
    return this.nodeSelected && this.nodeSelected(node);
  }

  private isExpanded(node: SelectionTreeNodeModel): boolean {
    return this.nodeExpanded && this.nodeExpanded(node);
  }

  unsubscribeFromSelections() {
    if (this.checklistSubscription && !this.checklistSubscription.closed) {
      this.checklistSubscription.unsubscribe();
    }
  }
}
