import {SingleDeviceType} from '../../../models/single-device.model';
import {ConnectedClientType} from '../../../models/clients.model';
import {CloudNodeType} from '../../../models/cloud-network.model';

export type TopologyNodeType = SingleDeviceType | CloudNodeType | ConnectedClientType;

export namespace TopologyNodeType {
  export function getTopologyNodesTypes(): TopologyNodeType[] {
    return [
      CloudNodeType.Vpc,
      CloudNodeType.CloudRouter,
      SingleDeviceType.ClientDevice,
      SingleDeviceType.AccessPoint,
      SingleDeviceType.Camera,
      SingleDeviceType.UnidentifiedDevice,
      SingleDeviceType.Switch,
      SingleDeviceType.SwitchStack,
      SingleDeviceType.Router,
      SingleDeviceType.SDWAN,
      SingleDeviceType.CellularGateway,
      SingleDeviceType.Firewall,
      SingleDeviceType.ExternalRouter,
      SingleDeviceType.Sase,
      SingleDeviceType.WWW,
      SingleDeviceType.FakeRoot
    ];
  }

  export function isHigherType(currentType: TopologyNodeType, nextType: TopologyNodeType) {
    const typesArray = getTopologyNodesTypes();
    const currentTypeIndex = typesArray.indexOf(currentType);
    const nextTypeIndex = typesArray.indexOf(nextType);
    return nextTypeIndex > currentTypeIndex ? true : false;
  }

  export function isLowerType(currentType: TopologyNodeType, nextType: TopologyNodeType): boolean {
    const typesArray = getTopologyNodesTypes();
    const currentTypeIndex = typesArray.indexOf(currentType);
    const nextTypeIndex = typesArray.indexOf(nextType);
    return nextTypeIndex < currentTypeIndex;
  }

  export function isOneHigherType(currentType: TopologyNodeType, nextType: TopologyNodeType) {
    const typesArray = getTopologyNodesTypes();
    const currentTypeIndex = typesArray.indexOf(currentType);
    const nextTypeIndex = typesArray.indexOf(nextType);
    return nextTypeIndex - currentTypeIndex == 1 ? true : false;
  }
}
