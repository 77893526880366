import {AnomaliesSizes} from "../models/anomalies.model";
import {InjectionToken} from "@angular/core";

export class GlobalUtilsSizes {
  public static MULTI_KPIS_CHARTS_HEIGHT = window.innerHeight * .2;
  public static MULTI_KPIS_CHARTS_HEIGHT_WITH_VPN = window.innerHeight * .15;
  public static INSIGHTS_ANOMALIES_ACTIONS_CHART = window.innerHeight * .29;
  public static INSIGHTS_ANOMALIES_GRID_SIZES = window.innerHeight * .365;

}

export const anomalyInsightsGridSizes: AnomaliesSizes = {
  height: `${GlobalUtilsSizes.INSIGHTS_ANOMALIES_GRID_SIZES}px`,
  width: '100%'
};
export const ANOMALY_GRID_SIZES = new InjectionToken<string>('Anomaly Grid sizes');
export const ANOMALY_CHART_HEIGHT = new InjectionToken<string>('Anomaly Grid sizes');
