import {ChartTooltipExtensions} from './chart-tooltip-extensions';
import {SelectedBarDatasets} from "../models/chart-js-events.model";

export class GraphTooltip extends ChartTooltipExtensions {

  constructor() {
    super()
  }

  /**
   * Add custom tooltip to the chart.
   * The method sets the tooltip as an dom element that will be added to the dom.
   * In addition, it will set the tooltip's content, styling and positioning
   * Inspired by: https://stackblitz.com/edit/chart-js-html-tooltip
   */
  customGraphTooltips = (context) => {
    const {chart, tooltip} = context;
    this.chartObj = chart;
    if (this.chartObj) {
      let tooltipEl = this.setCustomTooltipStyling(tooltip, this.chartObj);
      this.setChartCustomTooltipPosition(tooltipEl, tooltip);
    }
  };
}
