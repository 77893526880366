<ng-container *ngIf="areChanges">
    <div class="change-log-container">
        <ng-container *ngFor="let entity of changesEntityArray; let last = last; let i = index">
            <div [ngClass]="'entity-data-container-' + changeType.toLowerCase()">
                <span class="entity-icon-container" *ngIf="changes[entity.key].length > 0">
                    <mat-icon class="entity-icon" [ngClass]="'entity-icon-' + changeType.toLowerCase()" mat-list-icon
                        [svgIcon]="entity.iconName">entity icon</mat-icon>
                </span>
                <span [ngClass]="'change-text-' + changeType.toLowerCase()" *ngIf="changes[entity.key].length > 0"
                    (click)="openDetailedChangesDialog(changes[entity.key])">
                    {{changes[entity.key].length}} {{entity.key}}</span>
                <span
                    *ngIf="!last && changes[changesEntityArray[i+1].key].length > 0 && changes[entity.key].length > 0">,&nbsp;</span>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!areChanges">
    <div class="change-log-container">
        {{'data.COMMON.NO_CHANGES' | translate}}</div>
</ng-container>