import { Component, OnInit, ContentChild, TemplateRef, AfterContentInit } from '@angular/core';
import { NetopDialogTitleComponent } from './netop-dialog-title/netop-dialog-title.component';
import { NetopDialogContentComponent } from './netop-dialog-content/netop-dialog-content.component';
import { NetopDialogActionsComponent } from './netop-dialog-actions/netop-dialog-actions.component';

@Component({
  selector: 'app-netop-dialog',
  templateUrl: './netop-dialog.component.html',
  styleUrls: ['./netop-dialog.component.scss']
})
export class NetopDialogComponent implements OnInit, AfterContentInit {
  // @ContentChild(NetopDialogTitleComponent, { static: false }) title: NetopDialogTitleComponent;
  // @ContentChild(NetopDialogContentComponent, { static: false }) content: NetopDialogContentComponent;
  // @ContentChild(NetopDialogActionsComponent, { static: false }) actions: NetopDialogContentComponent;

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    //debugger
  }
}
