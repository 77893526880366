import { TimeSeries } from '../components/chartjs-components/models/sparkline-config';
import { TimePeriod, FromToDates, TimeUnit } from "../models/time.model";
import moment from 'moment/min/moment-with-locales';
moment.locale(navigator.language);

export function calculateTotalHoursBetweenTwoDates(startDate: Date, endDate: Date): number {
  return Math.abs(startDate.getTime() - endDate.getTime()) / 36e5;
}

export function calculateDaysNumberBetweenTwoDates(startDate: Date, endDate: Date) {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
}

export function sortTimeseriesArray(a: TimeSeries, b: TimeSeries) {
  if (a.date > b.date)
    return 1;
  if (a.date < b.date)
    return -1;
  return 0;
}

/**
 * Return the index of the first closet date in an array of dates, comparing given date
 * For example: 20.1.12, 22.1.12, 23.1.12 VS 21.1.12 will return 0
 */
export function findClosetEarlierDate(timeSeries: Date[], date: Date): number {
  let earlier = timeSeries[0];
  timeSeries.forEach(point => {
    if (point < date && point > earlier) {
      earlier = point;
    }
  })
  return timeSeries.findIndex(item => item == earlier);
}

export function getYesterdayDate(): Date {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
}

export function isLast24Hours(currentDate: number): boolean {
  let dayEarlier = new Date();
  dayEarlier.setDate(dayEarlier.getDate() - 1);
  return currentDate >= dayEarlier.getTime();
}

export function getPeriodDates(period: TimePeriod, fromYesterday: boolean = true): FromToDates {
  switch (period) {
    case TimePeriod.LAST_24_HOURS:
      return {
        start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
        end: fromYesterday ? getYesterdayDate() : new Date(Date.now())
      }
    case TimePeriod.LAST_WEEK:
      return {
        start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        end: fromYesterday ? getYesterdayDate() : new Date(Date.now())
      }
    case TimePeriod.LAST_MONTH:
      return {
        start: new Date(Date.now() - 31 * 24 * 60 * 60 * 1000),
        end: fromYesterday ? getYesterdayDate() : new Date(Date.now())
      }
    default:
      break;
  }
}

export function returnDateObject(year: number, month: number, day: number) {
  return new Date(year, month, day);
}

export function getDatesBetweenDates(startDate: Date, endDate: Date): Date[] {
  let dates = []
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate];
  return dates
}

export function getDatesBetweenDatesByRange(startDate: Date, endDate: Date, timeUnit: TimeUnit, gap: number): Date[] {
  let dates = []
  const theDate = new Date(startDate);
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)];
    switch (timeUnit) {
      case TimeUnit.MONTHS:
        theDate.setDate(theDate.getDate() + gap);
        break;
      case TimeUnit.DAYS:
        theDate.setHours(theDate.getHours() + gap);
        break;
      case TimeUnit.HOURS:
        theDate.setMinutes(theDate.getMinutes() + gap);
        break
    }
  }
  dates = [...dates, endDate];
  return dates
}

export function getDateByRangeAndTimeUnit(date: Date, range: number, timeUnit: TimeUnit, action: ('add' | 'subtract')) {
  const newDate = new Date(date);
  switch (timeUnit) {
    case TimeUnit.MINUTES:
      return getDatesByMinutesRange(range, newDate, action);
    case TimeUnit.HOURS:
      return getDatesByHoursRange(range, newDate, action);
    case TimeUnit.DAYS:
      return getDateByDaysRange(range, newDate, action);
    case TimeUnit.MONTHS:
      return getDateByMonthRange(range, newDate, action);
  }
}

export function getDaysNumberBetweenDates(firstDate: Date, seceondDate: Date, unit: TimeUnit = TimeUnit.DAYS) {
  const divider = unit === "DAYS" ? 1000 * 60 * 60 * 24 : 1000 * 60 * 60;
  const first = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate(), firstDate.getHours(),
    firstDate.getMinutes(), firstDate.getSeconds());
  const second = Date.UTC(seceondDate.getFullYear(), seceondDate.getMonth(), seceondDate.getDate(), seceondDate.getHours(),
    seceondDate.getMinutes(), seceondDate.getSeconds());
  return Math.floor((first - second) / divider);
}

export function getDateByDaysRange(range: number, date: Date, action: ('add' | 'subtract')) {
  if (action === 'add') {
    const nowPlusRange = new Date(date);
    nowPlusRange.setDate(date.getDate() + range);
    if (nowPlusRange.getTime() > date.getTime()) {
      return nowPlusRange;
    } else {
      return date;
    }
  } else {
    const pastDate = new Date(date);
    pastDate.setDate(pastDate.getDate() - range);
    return pastDate;
  }
}

export function getDateByMonthRange(range: number, date: Date, action: ('add' | 'subtract')) {
  if (action === 'add') {
    const nowPlusRange = new Date(date);
    nowPlusRange.setMonth(date.getMonth() + range);
    if (nowPlusRange.getTime() > date.getTime()) {
      return nowPlusRange;
    } else {
      return date;
    }
  } else {
    const pastDate = new Date(date);
    pastDate.setMonth(pastDate.getMonth() - range);
    return pastDate;
  }
}

export function getDatesByHoursRange(range: number, date: Date, action: ('add' | 'subtract')) {
  if (action === 'add') {
    const nowPlusRange = new Date(date);
    nowPlusRange.setHours(nowPlusRange.getHours() + range);
    if (nowPlusRange.getTime() > date.getTime()) {
      return nowPlusRange;
    } else {
      return date;
    }
  } else {
    const pastDate = new Date(date);
    pastDate.setHours(pastDate.getHours() - range);
    return pastDate;
  }
}

export function getDatesByMinutesRange(range: number, date: Date, action: ('add' | 'subtract')) {
  if (action === 'add') {
    const nowPlusRange = new Date(date);
    nowPlusRange.setMinutes(nowPlusRange.getMinutes() + range);
    if (nowPlusRange.getTime() > date.getTime()) {
      return nowPlusRange;
    } else {
      return date;
    }
  } else {
    const pastDate = new Date(date);
    pastDate.setMinutes(pastDate.getMinutes() - range);
    return pastDate;
  }
}

export function convertToMilliseconds(value: number, unit: TimeUnit): number {
  switch (unit) {
    case TimeUnit.MINUTES:
      return value * 1000 * 60;
    case TimeUnit.HOURS:
      return value * 1000 * 60 * 60;
    case TimeUnit.DAYS:
      return value * 1000 * 60 * 60 * 24;
  }
}

//Displaying Date and Time Format Based on the User's Browser
export function formatDateTime(unix: Date | number | string | moment.Moment, format: string = 'YYYY-MM-DD HH:mm'): string {
    const localDateix = moment(unix);

    if (!localDateix.isValid()) return unix.toString();

    moment.locale()
    return moment(localDateix).format('L HH:mm');
}
