export class SelectionListItem<T> {
    id: number;
    data: T;
    mainText: string;
    subText: string;
    isSelected: boolean;
    outOf: OutOfDisplay;
    constructor(id: number, data: T, mainText: string, subText: string, outOf?: OutOfDisplay) {
        this.id = id;
        this.data = data;
        this.mainText = mainText;
        this.subText = subText;
        this.outOf = outOf;
    }
}

export class SelectedWithOriginal<T> {
    selected: T[];
    original: T[];
}
export type OutOfDisplay = { out: number, total: number }
