<div class="action-configuration-data-container">
    <div class="row action-conf-data-row" *ngFor="let data of configurationData">
        <div class="col action-conf-dialog-text-key col-flex-3">{{data.key}}</div>
        <div class="col action-conf-dialog-text">{{data.value}}</div>
    </div>
    <div class="row action-conf-data-row">
        <div class="col action-conf-dialog-text-key col-flex-3">
            {{'data.ACTIONS.AFFECTED_KPI_KEY' | translate}}</div>
    </div>
    <div class="row action-conf-dialog-kpi-row">
        <ng-container *ngFor="let kpi of kpiChanges; let i = index">
            <div class="col align-center-col-padding"
                [ngClass]="i == kpiChanges.length-1? '': 'action-conf-dialog-border'">
                <div class="row">
                    <div class="col action-conf-dialog-text align-center-column col-no-flex">{{kpi.key}}</div>
                    <div class="col"> <span class="arrow-icon"
                            [ngStyle]="{color: getArrowColor(kpi)}">{{getKpiChangeArrow(kpi)}}</span>
                        <span class="col action-conf-dialog-text col-flex-3 pl-0"
                            [ngStyle]="{color: getChangeColor(kpi)}">{{getKpiOperator(kpi)}}{{kpi.change}}%</span>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col">
                        <div class="action-conf-dialog-text-value">{{getKpiValue(kpi)}}
                            {{getKpiUnit(kpi)}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="dialog-row-bottom-border"></div>
</div>