import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-properties-title',
  templateUrl: './properties-title.component.html',
  styleUrls: ['./properties-title.component.scss']
})
export class PropertiesTitleComponent implements OnInit {
  @Input() title: string;
  @Output() closeIconClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  emitCloseIconClicked() {
    this.closeIconClicked.emit(true);
  }
}
