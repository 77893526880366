<div class="list-view row row-no-side-margin">
  <div class="col col-flex-2 list-col">
    <div class="list-view-stack">
      <ng-container *ngIf="items">
        <div *ngFor="let item of items; let i = index"
             (click)="onItemSelected(item)" class="list-view-item"
             [ngClass]="{'list-view-item-selected': isSelected(item.id)}">
          <div class="row category-list-row row-no-side-margin">
            <div class="col col-flex-1 no-side-padding">
              <mat-icon class="category-icon" [ngClass]="'list-view-item-icon-' + i" [svgIcon]="item.icon">
              </mat-icon>
            </div>
            <div class="col align-items-center">
              <div>
                <span class="anomality-text-sub"> {{item.label | formattedCamel}} </span>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col">
    <ng-content></ng-content>
  </div>
</div>
