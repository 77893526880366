import {ValidatorFn, AbstractControl} from '@angular/forms';
import {BrandingImageFile} from '../../models/branding.model';

export const IMAGES_FILE_TYPES: string[] = ['png', 'jpg', 'svg', 'jpeg'];
export const DATA_FILE_TYPES: string[] = ['json'];


export function onlyImageFilesValidators(errorName: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const imageFile: BrandingImageFile = control.value;
    if (imageFile && imageFile.file) {
      const extension = (imageFile as BrandingImageFile).file.name.split('.').pop();
      if (!IMAGES_FILE_TYPES.includes(extension.toLowerCase())) {
        return {[errorName]: true};
      }
    }
    return null;
  }
}

export function onlyDataFilesValidators(errorName: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const imageFile: BrandingImageFile = control.value;
    if (imageFile && imageFile.file) {
      const extension = (imageFile as BrandingImageFile).file.name.split('.').pop();
      if (!DATA_FILE_TYPES.includes(extension.toLowerCase())) {
        return {[errorName]: true};
      }
    }
    return null;
  }
}

export function checkIsFileTypeAllowed(allowedTypes: string[], type: string) {
  return allowedTypes.find(allowed => type.includes(allowed));
}
