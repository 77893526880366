<app-netop-dialog>

  <app-netop-dialog-title (close)="closeDialog()">
    {{titleText}}
  </app-netop-dialog-title>

  <app-netop-dialog-content [cssClass]="'periodic-dialog-content'">
    <ng-container>
      <form class="col branding-col-form-container" [formGroup]="scheduleForm">
        <div class="m-3">
          <mat-slide-toggle [labelPosition]="'before'" class="pr-2 pl-2 backup-toggle"
                            formControlName="scheduleEnabled">
            {{scheduleNowText}}
          </mat-slide-toggle>
        </div>
        <app-cron-generator [isDisabled]="!isScheduleEnabled" (cronSelected)="onCronSelected($event)" [defaultCron]="dialogData?.cron">
        </app-cron-generator>
      </form>
    </ng-container>
  </app-netop-dialog-content>

  <app-netop-dialog-actions>
    <div class="d-flex justify-content-end kt-margin-r-10">
      <mat-dialog-actions>
        <app-buttons (click)="closeDialog()" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
                     [btnText]="'data.COMMON.CANCEL'">
        </app-buttons>

        <app-buttons class="kt-margin-l-10" (click)="submitSchedule()" [btnStyle]="buttonColor" [btnClass]=""
                     [btnText]="scheduleText" [btnDisabled]="!isFormDirty && !isFormValid">
        </app-buttons>
      </mat-dialog-actions>
    </div>
  </app-netop-dialog-actions>

</app-netop-dialog>
