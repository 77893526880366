import { EntityType } from '../../models/entity-type.enum';

export enum GraphType {
    TENANT = 'Tenant',
    DEVICE = 'Device',
    VENUE = 'Venue',
    FABRIC = 'Fabric',
    TRAFFIC = "Traffic",
}

export function enitityTypeToGraphType(entityType: EntityType) {
    let enitityTypeToGraphType = {
        [EntityType.DEVICE]: GraphType.DEVICE,
        [EntityType.FABRIC]: GraphType.FABRIC,
        [EntityType.TENANT]: GraphType.TENANT,
        [EntityType.VENUE]: GraphType.VENUE
    }
    return enitityTypeToGraphType[entityType]
}

export function graphTypeToEntityType(entityType: GraphType) {
    let enitityTypeToGraphType = {
        [GraphType.DEVICE]: EntityType.DEVICE,
        [GraphType.FABRIC]: EntityType.FABRIC,
        [GraphType.TENANT]: EntityType.TENANT,
        [GraphType.VENUE]: EntityType.VENUE
    }
    return enitityTypeToGraphType[entityType]
}