<mat-accordion multi>
    <mat-expansion-panel>
        <mat-expansion-panel-header class="sync-properties-expans-panel-header pl-0" [collapsedHeight]="'100px'"
            [expandedHeight]="'100px'">
            <mat-list class="properties-header-container">
                <mat-list-item>
                    <div class="sync-properties-list-item">
                        <div class="col icon-and-ellipse pr-4 col-flex-4">
                            <mat-icon class="small-ellipse" mat-list-icon svgIcon="small-ellipse">
                            </mat-icon>
                            <i class="icon-position" [inlineSVG]="iconPath"></i>
                        </div>
                        <div class="col align-center-column flex-col-direction pr-4">
                            <span
                                [title]="singleChange?.entityName"><strong>{{singleChange?.entityName}}</strong></span>
                            <span>{{formmatedEntitiyClassName}}</span>
                        </div>
                        <div class="col align-center-column flex-col-direction header-second-part">
                            <span class="pl-5"><strong>{{singleChange?.changes.length}}</strong></span>
                            <span class="pl-5">{{'data.PROPERTIES.HEADER' | translate}}</span>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <mat-list class="properties-list-header-container">
                <mat-list-item>
                    <div class="row full-width">
                        <span class="col"
                            [ngClass]="singleChange?.changes.length > 4 ? 'col-flex-13' : 'col-flex-14'"><strong>{{'data.COMMON.PROPERTY_NAME' | translate}}</strong></span>
                        <span class="col"
                            *ngIf="singleChange?.type.toLowerCase() !== changeType.ADDED.toLowerCase()"><strong>{{'data.COMMON.OLD_VALUE' | translate}}</strong></span>
                        <span class="col"
                            *ngIf="singleChange?.type.toLowerCase() !== changeType.REMOVED.toLowerCase()"><strong>{{'data.COMMON.NEW_VALUE' | translate}}</strong></span>
                    </div>
                </mat-list-item>
            </mat-list>
            <mat-list class="properties-list-content-container">
                <mat-list-item *ngFor="let change of singleChange?.changes">
                    <div class="row full-width">
                        <span class="col col-flex-14" [title]="change?.propertyName">{{change?.propertyName}}</span>
                        <span class="col" *ngIf="singleChange?.type.toLowerCase() !== changeType.ADDED.toLowerCase()"
                            [ngClass]="oldValueClass" [title]="change?.oldValue">{{change?.oldValue}}</span>
                        <span class="col" *ngIf="singleChange?.type.toLowerCase() !== changeType.REMOVED.toLowerCase()"
                            [ngClass]="newValueClass" [title]="change?.newValue">{{change?.newValue}}</span>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>