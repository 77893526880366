import { Pipe, PipeTransform } from '@angular/core';
import { TopTrafficTotalDisplay } from '../../models/legacy-reports.model';

@Pipe({
  name: 'totalFromTop'
})
export class TopTrafficTotalPipe implements PipeTransform {

  transform(topEntities: TopTrafficTotalDisplay): any {
    if (topEntities) {
      return topEntities.isTraffic ? `(Total: ${topEntities.calculatedData} ${topEntities.unit})` : `(Total: ${topEntities.originalData})`;
    }
    return null;
  }

}
