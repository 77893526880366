import { Component } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-icon-cell-renderer',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss']
})
export class IconCellRendererComponent implements ICellRendererAngularComp {
  value: string;
  iconPaths: string[];
  orientation: IconCellRendererOrientation;
  containerClassName: string;
  hideText: boolean;

  agInit(params: IconCellRendererParams): void {
    this.value = params.value;
    this.iconPaths = params.iconPaths && typeof params.iconPaths == 'function' ? params.iconPaths(params.data) : [];
    this.orientation = params.orientation ?? 'left';
    this.containerClassName = params.containerClassName;
    this.hideText = params.hideText;
  }

  refresh(params: any): boolean {
    return false;
  }

  isSvg(iconPath: string): boolean {
    return iconPath?.split('.')?.includes('svg');
  }

  get containerClass() {
    return `${this.containerClassName ? this.containerClassName + ' ' : ''}text-with-icon text-with-icon-${this.orientation}`;
  }
}

export interface IconCellRendererParams extends ICellRendererParams {
  iconPaths: (row: any) => string[];
  orientation?: IconCellRendererOrientation;
  containerClassName?: string;
  hideText?: boolean;
}

export type IconCellRendererOrientation = 'left' | 'right';
