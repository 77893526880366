<app-netop-dialog>
  <app-netop-dialog-title (close)="closeDialog()" [closeIconClass]="'col-no-flex'">
    {{'data.REPORTS.DEEP_DIVE_HEADER' | translate}}
  </app-netop-dialog-title>
  <app-netop-dialog-sub-title [cssClass]="'reports-dev-sub-title-header'">
    <div class="row reports-dev-sub-title-row">
      <div class="col reports-dev-sub-title-entity">
                <span class="reports-dev-sub-title-first">
                    {{breadcrumbs}}
                </span>
      </div>
      <div class="col reports-dev-sub-title-info-col">
        <span> {{'data.REPORTS.CURRENT_DATE' | translate}} {{formattedSelectedDate}}</span>
        <div *ngIf="currentEntity?.type == EntityType.TENANT" class="anomaly-modal-sub-title-explain">
          <div>
            <i [inlineSVG]="'assets/media/netop/information2.svg'" class="anomaly-modal-info-icon"></i>
          </div>
          {{'data.REPORTS.SELECT_VENUE_EXPLAIN_TEXT' | translate}}
        </div>
      </div>
      <div class="col reports-dev-sub-title-date">
        <mat-form-field class="report-dev-date-picker-field" color="accent">
          <mat-label>{{'data.REPORTS.SELECT_DATE' | translate}}</mat-label>
          <input matInput [matDatepicker]="picker"
                 [(ngModel)]="currentDate" [matDatepickerFilter]="filterDates">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker color="primary">
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </app-netop-dialog-sub-title>
  <app-netop-dialog-content class="no-overflow-x" [cssClass]="'dev-rep-content-container'">
    <app-deviation-reports-dialog-main [preSelectedEntity]="dialogData?.selectedEntity"
                                       [selectedDate]="formattedSelectedDate"
                                       (selectedEntity)="onSelectedEntity($event)"
                                       [currentTenant]="dialogData?.currentTenant">
    </app-deviation-reports-dialog-main>
  </app-netop-dialog-content>
  <app-netop-dialog-actions>
    <app-buttons (click)="closeDialog()" class="alerts-button" [btnStyle]="'primary'" [btnText]="'data.COMMON.OK'">
    </app-buttons>
  </app-netop-dialog-actions>
</app-netop-dialog>
