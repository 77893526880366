import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function customEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const values = cleanEmails(control.value);
    const invalids = [], valids = [];
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    values.forEach(value => {
      if(!emailRegex.test(value))
        invalids.push(value);
      else
        valids.push(value);
    });

    return invalids.length == 0 ? null : { valids, invalids };
  };
}

export function cleanEmails(emails: string | string[]): string[] {
  const values = typeof emails == "string" ? emails.split(',') : emails;
  return values.map(email => email.trim()).filter(value => value.trim() !== '');
}

@Directive({
  selector: '[customEmail]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomEmailValidatorDirective),
      multi: true
    }
  ]
})
export class CustomEmailValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return customEmailValidator()(control);
  }
}
