<div class="row global-policy-row" [ngClass]="cssOverride"
     *ngIf="policies && policies.length  > 0">
  <!--Policies-->
  <div class="col global-policy-col"
       *ngFor="let policy of policies; let last =last">
    <div class="cell" *ngIf="policy.order > 0">
      <span>{{policy.order}})</span>
    </div>
    <!--Regular Display-->

    <ng-container>
      <div class="align-items-center" *ngFor="let rule of policy.rules" #tooltip="matTooltip"
           [matTooltip]="rule.toPlainText" [matTooltipPosition]="'above'"
           matTooltipClass="marker-tooltip" matTooltipShowDelay="1000">

        <!--dstData Display-->

        <ng-container [ngTemplateOutlet]="endPointsDisplay" [ngTemplateOutletContext]="{endPoints:rule.dstData}">
        </ng-container>

        <!--Arrow-->

        <div class="col col-flex-2 arrow" [ngClass]="'policy-arrow-' + rule?.action.toLowerCase()"
             *ngIf="rule?.srcData?.length > 0 && rule?.dstData?.length">
          {{rule?.arrow}}
        </div>

        <!--srcData Display-->

        <ng-container [ngTemplateOutlet]="endPointsDisplay" [ngTemplateOutletContext]="{endPoints:rule.srcData}">
        </ng-container>

      </div>
    </ng-container>

  </div>
</div>

<!--No Data Display-->

<ng-content></ng-content>

<!--End points Display-->

<ng-template #endPointsDisplay let-endPoints="endPoints">
  <ng-container *ngFor="let point of endPoints; let last = last">
    <div class="cell" *ngIf="point?.iconName">
      <mat-icon class="global-policy-icon" [svgIcon]="point.iconName"></mat-icon>
    </div>
    <div class="cell">
      <span style="font-weight: bold" *ngIf="!point?.iconName">{{point.key}}: </span>
      <span>{{point.value}}</span>
      <span class="mr-2" *ngIf="!last">, </span>
    </div>
  </ng-container>
</ng-template>
