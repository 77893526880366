import { Component } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {SeverityUtils} from "../../../global-utils/severity-utils";

@Component({
  selector: 'app-bar-cell-renderer',
  templateUrl: './bar-cell-renderer.component.html',
  styleUrls: ['./bar-cell-renderer.component.scss']
})
export class BarCellRendererComponent implements ICellRendererAngularComp {
  value: number;
  color: string;

  agInit(params: IBarCellRendererParams): void {
    this.value = params.value;
    this.color = params.color && typeof params.color == 'function' ? params.color(params.data) : SeverityUtils.getHealthColor(params.value);
  }

  refresh(params: any): boolean {
    return false;
  }

  get percentage() {
    return Math.floor((this.value / 100) * 100);
  }

  get displayValue() {
    return Math.floor(this.value)
  }

  get hasValue() {
    return this.value != null && this.value != -1;
  }
}

export interface IBarCellRendererParams extends ICellRendererParams {
  color: (row: any) => string;
}
