import {Component, HostBinding, HostListener, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import {TopologyTooltipsTypes} from '../topology-tooltips-types.enum';
import {TopologyTooltipService} from '../topology-tooltip.service';
import {NodeEventData} from '../../../../models/topology';


@Component({
  selector: 'app-topology-tooltip',
  templateUrl: './topology-tooltip.component.html',
  styleUrls: ['./topology-tooltip.component.scss']
})
export class TopologyTooltipComponent {
  /**
   * @param nodeEvent Contain the event data from the svg.
   * If node was hovered, this object will receive the node data and the event data
   */
  @Input() nodeEvent: NodeEventData<any>;
  @Input() title: string;
  @Input() tplgRect: DOMRect;
  @Input() tooltipType: TopologyTooltipsTypes;
  /**
   * @param displayNodeEvent The node that will be displayed on the the tooltip
   * Will get null only if the user is not hovering over the tooltip and the node
   */
  displayNodeEvent: NodeEventData<any>;
  nodeAsElement: HTMLElement;
  onHostHover = false;
  @Output() clickOnTitle: EventEmitter<NodeEventData<any>> = new EventEmitter();
  @Output() isHover: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('style.top') get nodeX() {
    if (this.displayNodeEvent) {
      let rect = <DOMRect>this.nodeAsElement.getBoundingClientRect();
      /**
       * 66 is the height of the tooltip header + 14 px of padding from the bottom of the screen
       */
      if ((window.innerHeight - rect.y) < (this.tooltipHeight + 66)) {
        return (rect.y - (this.tooltipHeight + 66) + 'px');
      }
      if (rect.y === 0) {
        return null;
      }
      return rect.y + 'px';
    }
    return null;
  }

  @HostBinding('style.left') get nodeY() {
    if (this.displayNodeEvent) {
      let rect = <DOMRect>this.nodeAsElement.getBoundingClientRect();
      //Imry: 11.2.20: 265 is the width of the tooltip element
      //In order to disaply the tooltip left to the node, we need to subtract tooltip element width
      //If width for the tooltip is smaller then 229 (the minimum height for displaying the tooltip properly) -
      //return the x position of the node + its width in order to display the tooltip to its right side
      //229 - the tplgRect.x is the container of the svg. Between it and the side nav there are 14px + 25px of padding
      //subtract them from 265 (the tooltip width) gives: 229 (subtract few more px's for better L&F)
      if ((rect.x - this.tplgRect.x) < (this.tooltipWidth - 39)) {
        return rect.x + rect.width + 'px';
      }
      return +rect.x - this.tooltipWidth + 'px';
    }
    return 0;
  }

  @HostBinding('class') class = 'tplg-tooltip';

  @HostListener('mouseenter') onMouseEnter() {
    this.onHostHover = true;
    this.isHover.emit(this.onHostHover);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.onHostHover = false;
    this.isHover.emit(this.onHostHover);
    if (!this.nodeEvent) {
      this.displayNodeEvent = null;
    }
  }

  constructor(public topologyTooltipService: TopologyTooltipService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.nodeEvent) {
      this.displayNodeEvent = this.nodeEvent;
      this.nodeAsElement = <HTMLElement>this.displayNodeEvent.event.target;
    }
    if (!this.nodeEvent && !this.onHostHover) {
      this.displayNodeEvent = this.nodeEvent;
    }
  }

  /**
   * @param displayTooltip Return true if there is node to display and false otherwise
   */
  get displayTooltip() {
    if (this.displayNodeEvent) {
      return true;
    }
    return false;
  }

  get tooltipWidth() {
    switch (this.tooltipType) {
      case TopologyTooltipsTypes.SingleDeviceTooltip:
        return 265;
      case TopologyTooltipsTypes.SingleClientTooltip:
        return 350;
      case TopologyTooltipsTypes.MultiClientTooltip:
        return 450;
      case TopologyTooltipsTypes.FailedClientsTooltip:
        return 650;
      default:
        break;
    }
  }

  get tooltipHeight() {
    switch (this.tooltipType) {
      case TopologyTooltipsTypes.SingleDeviceTooltip:
        return 190;
      case TopologyTooltipsTypes.SingleClientTooltip:
        return 190;
      case TopologyTooltipsTypes.MultiClientTooltip:
        return 250;
      case TopologyTooltipsTypes.FailedClientsTooltip:
        return 250;
      default:
        break;
    }
  }

  emitClickedTooltipTitle() {
    this.clickOnTitle.emit(this.displayNodeEvent);
  }
}
