import {Injectable} from '@angular/core';
import {GenericDevice} from 'src/app/shared/models/topology';
import {BasicLink} from 'src/app/shared/models/single-link.model';
import arrayToTree from 'array-to-tree';
import {distinctArray} from 'src/app/shared/operators/array.operator';
import {Logger, LoggerService} from '../../../services/logger.service';
import {BaseTreeCreation} from './base-tree-creation';
import {TopologyNodeType} from '../models/TopologyTypes.model';
import {SingleDeviceType} from "../../../models/single-device.model";


@Injectable({
  providedIn: 'root'
})
export class TopologyBuilderService extends BaseTreeCreation {
  originalNodes: GenericDevice<any>[];
  originalLinks: BasicLink[];
  logger: Logger;

  constructor(private loggerFactory: LoggerService) {
    super();
    this.logger = this.loggerFactory.getLogger('TopologyBuilderService');
  }

  /**
   * Return tree out of nodes and links array
   * The method does 3 things:
   * 1. Save the original nodes and links array globally
   * 2. Assign each node parent Id by set of rules
   */
  generateTree(nodes: GenericDevice<any>[], links: BasicLink[]): GenericDevice<any> {
    this.assignGlobalNodesLinks(nodes, links);
    this.assignParentId();
    return this.convertNodesArrayToTree() ?? {} as any;
  }

  /**
   * Assign global params
   * @param nodes the original nodes array
   * @param links the original links array
   */
  assignGlobalNodesLinks(nodes: GenericDevice<any>[], links: BasicLink[]) {
    this.logger.debug('nodes', nodes);
    this.logger.debug('links', links);
    this.originalNodes = [...nodes];
    this.originalLinks = [...links];
  }

  /**
   * Assign each node with parent_id
   */
  assignParentId() {
    this.originalNodes.forEach(node => {
      const linkedNodes = this.findLinkedNodes(node);
      const potentialParents = this.filterPotentialParents(linkedNodes, node);
      node.parent_id = this.selectOneParent(potentialParents, node);
    });
  }

  /**
   * User array-to-tree library and convert nodes array into array of trees
   * Create fake root node if needs be
   */
  convertNodesArrayToTree() {
    let treesArray = arrayToTree(this.originalNodes);
    if (treesArray.length > 1) {
      treesArray = this.createFakeRootNode(treesArray, this.originalNodes, this.originalLinks);
      treesArray = this.sortRootNodeArray(treesArray);
    }
    this.logger.debug('Root Node', this.findBestTopology(treesArray));
    return this.findBestTopology(treesArray);
  }

  /**
   * Select one parent out of the parents array by a set of rules:
   *
   * 1. Prefer higher types devices
   * 2. In case no higher type parent was found - assign one of the same level parents
   *
   * Same level parent will be selected by the following rules:
   *
   * 1. If the parent has higher level parent of its own
   * 2. If no parent has it own higher type parent, select the first parent out of the parents array
   */
  selectOneParent(parents: GenericDevice<any>[], node: GenericDevice<any>): string | number {
    if (parents.length == 1) {
      return parents[0].id;
    }
    if (node.type == SingleDeviceType.Switch) {
      const switchStack = parents.find(parent => parent.type == SingleDeviceType.SwitchStack);
      if (switchStack) {
        return switchStack.id;
      }
      const sdwanParent = parents.find(parent => parent.type == SingleDeviceType.SDWAN);
      if (sdwanParent) {
        return sdwanParent.id;
      }
      const switchParent = parents.find(parent => parent.type == SingleDeviceType.Switch);
      if (switchParent) {
        return switchParent.id;
      }
    }
    if (node.type === SingleDeviceType.UnidentifiedDevice) {
      const switchParent = parents.find(parent => parent.type === SingleDeviceType.Switch);
      if (switchParent) {
        return switchParent.id;
      }
    }

    const sameLevelParents: GenericDevice<any>[] = [];

    parents.sort((parentA, parentB) => {
      if (TopologyNodeType.isHigherType(parentA.type, parentB.type)) {
        return 1;
      }
      if (TopologyNodeType.isHigherType(parentB.type, parentA.type)) {
        return -1;
      }
      return 0;
    });


    /**
     * If higher type parent was found - return it immediately
     * If same type parent was found - push it into sameLevelParents array
     */
    for (let index = 0; index < parents.length; index++) {
      const parent = parents[index];
      if (TopologyNodeType.isHigherType(node.type, parent.type)) {
        return parent.id;
      }
      if (parent.type === node.type) {
        sameLevelParents.push(parent);
      }
    }
    /**
     * If only same level (by type) parent was found -
     * Return the one parent with its own higher type parents.
     * If no such node was found, return the first in the array.
     */
    if (sameLevelParents && sameLevelParents.length > 0) {
      for (let index = 0; index < sameLevelParents.length; index++) {
        const parent = sameLevelParents[index];
        if (this.hasHigherLevelParent(parent)) {
          return parent.id;
        }
      }
      return sameLevelParents[0].id;
    }
  }

  /**
   * Check if given node as parents, that fits to the following rules:
   * 1. Parent must be of hiehger type
   */
  hasHigherLevelParent(node: GenericDevice<any>): boolean {
    let parents = this.findLinkedNodes(node);
    parents = this.filterPotentialParents(parents, node);
    return parents.filter(grandParent => TopologyNodeType.isHigherType(node.type, grandParent.type)).length > 0;
  }

  /**
   * Find & return all the nodes that are linked to the current node
   *
   */
  findLinkedNodes(node: GenericDevice<any>): GenericDevice<any>[] {
    const nodes: GenericDevice<any>[] = [];
    this.originalLinks.forEach(link => {
      const otherNodeLocation: ('startDeviceId' | 'endDeviceId') = this.findLinkDirectionForNodeOtherHalf(node, link);
      if (otherNodeLocation) {
        const linkedNode = this.originalNodes.find(node => node.id == link[otherNodeLocation]);
        if (linkedNode !== undefined && linkedNode.id !== node.id) {
          nodes.push(linkedNode);
        }
      }
    });
    return distinctArray(nodes, ['id']);
  }

  /**
   * Filter irrelevant nodes, by the following rules:
   * 1. Parents cannot be of lower type
   * 2. Parents cannot be current nodes children
   */
  filterPotentialParents(parents: GenericDevice<any>[], node: GenericDevice<any>): GenericDevice<any>[] {
    const filteredParents: GenericDevice<any>[] = [];
    parents.forEach(parent => {
      if (this.isNodeAndParentAreUnidentifiedAndSwitch(node, parent)) {
        if (node.type === SingleDeviceType.Switch) {
          if (parent.type == SingleDeviceType.SwitchStack) {
            filteredParents.push(parent);
          }
          else if (!this.isSwitchCanBeParentOfUnidentified(node)) {
            filteredParents.push(parent);
          }
        }
        if (node.type === SingleDeviceType.UnidentifiedDevice) {
          if (this.isSwitchCanBeParentOfUnidentified(parent)) {
            filteredParents.push(parent);
          }
          if (parent.type == SingleDeviceType.Switch) {
            filteredParents.push(parent);
          }
        }
        if (this.isSwitchCanBeParentOfUnidentified(parent)) {
          filteredParents.push(parent);
        }
      } else if (!TopologyNodeType.isLowerType(node.type, parent.type) && !this.isNodeParentOfOtherNode(node, parent)) {
        filteredParents.push(parent);
      }
    });
    return distinctArray(filteredParents, ['id']);
  }

  /**
   * Return true if other node parent_id is identical to the current node
   */
  isNodeParentOfOtherNode(currentNode: GenericDevice<any>, otherNode: GenericDevice<any>): boolean {
    return otherNode.parent_id && otherNode.parent_id == currentNode.id;
  }

  /**
   * return true if switch has more than one unidentified children or that its only unidentified child is zipper
   * @param parent
   * @private
   */
  private isSwitchCanBeParentOfUnidentified(parent: GenericDevice<any>) {
    let unidentifiedLinkedDevices: GenericDevice<any>[] = [];
    this.originalLinks.forEach(link => {
      let otherNodeLocation: ('startDeviceId' | 'endDeviceId') = this.findLinkDirectionForNodeOtherHalf(parent, link);
      ;
      if (otherNodeLocation) {
        const potentialChild = this.originalNodes.find(node => node.id === link[otherNodeLocation]);
        if (potentialChild !== undefined && potentialChild.type === SingleDeviceType.UnidentifiedDevice) {
          unidentifiedLinkedDevices.push(potentialChild);
        }
      }
    })
    return unidentifiedLinkedDevices && unidentifiedLinkedDevices.length > 1 || unidentifiedLinkedDevices.length === 1 && unidentifiedLinkedDevices[0].isZipper || this.isSwitchHasParentOfHigherType(parent);
  }

  /**
   * Return true if both nodes are of UnidentifiedDevice and Switch
   * @private
   */
  private isNodeAndParentAreUnidentifiedAndSwitch(nodeA: GenericDevice<any>, nodeB: GenericDevice<any>) {
    return nodeA.type === SingleDeviceType.UnidentifiedDevice && nodeB.type === SingleDeviceType.Switch || nodeB.type === SingleDeviceType.UnidentifiedDevice && nodeA.type === SingleDeviceType.Switch;
  }

  /**
   * return true if switch has higher parent that is not unidentified node
   * @private
   */
  private isSwitchHasParentOfHigherType(parent: GenericDevice<any>) {
    for (let i = 0; i < this.originalLinks.length; i++) {
      const link = this.originalLinks[i];
      let otherNodeLocation: ('startDeviceId' | 'endDeviceId') = this.findLinkDirectionForNodeOtherHalf(parent, link);
      if (otherNodeLocation) {
        const otherNode = this.originalNodes.find(node => node.id === link[otherNodeLocation]);
        if (otherNode !== undefined && otherNode.type !== SingleDeviceType.UnidentifiedDevice && TopologyNodeType.isHigherType(parent.type, otherNode.type)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Return the direction for current node and link.
   * If one of the link "sides" fit: the method return its id pointer
   * @private
   */
  private findLinkDirectionForNodeOtherHalf(parent: GenericDevice<any>, link: BasicLink) {
    let otherNodeLocation: ('startDeviceId' | 'endDeviceId');
    if (link.startDeviceId == parent.id) {
      otherNodeLocation = 'endDeviceId';
    }
    if (link.endDeviceId == parent.id) {
      otherNodeLocation = 'startDeviceId';
    }
    return otherNodeLocation;
  }
}
