import {Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import {TreeConfiguration, TopologyConfiguration} from '../../models/topology-configuration';
import {TopologyInformationService} from '../../services/topology-information.service';
import {Topology} from 'src/app/shared/models/topology';
import {SingleDevice} from 'src/app/shared/models/single-device.model';
import {SingleLink} from '../../../../models/single-link.model';
import {NgChanges} from "../../../../extend-angular-classes/on-changes";
import {deepEqual} from "../../../../operators/object-operators/object-comparison";
import {D3TreeGraphComponent} from "../../d3-topology/display/d3-tree-graph/d3-tree-graph.component";

@Component({
  selector: 'app-topology-main',
  templateUrl: './topology-main.component.html',
  styleUrls: ['./topology-main.component.scss']
})
export class TopologyMainComponent implements OnInit {
  @Input() treeConfiguration: TreeConfiguration;
  @Input() topologyConfiguration: TopologyConfiguration;
  @Input() isDevice: boolean = false;
  @Input() isFabricsVenue: boolean = false;
  @Input() isTopologyGenerated: boolean = undefined;
  @Input() selectedFabricTopology: Topology<SingleDevice, SingleLink>;
  @Input() tplgRect: DOMRect;
  @Input() isPropertiesOpen: boolean = false;
  isShowTooltips: boolean = true;

  constructor(private topologyInformationService: TopologyInformationService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: NgChanges<TopologyMainComponent>) {
    if (!this.isDevice && changes.treeConfiguration && changes.treeConfiguration.currentValue && changes.treeConfiguration.previousValue && !deepEqual(changes.treeConfiguration.previousValue, changes.treeConfiguration.currentValue)) {
      this.destroyAndReviveTopologyTooltips();
    }
  }

  get topologyRowClass() {
    return this.topologyInformationService.getDeviceTopologyRowClass(this.isDevice, this.selectedFabricTopology);
  }

  get isInitiateTopology() {
    return this.treeConfiguration && this.topologyConfiguration;
  }

  /**
   * Manually destroy tooltip component by assigning isShowTooltips with false and true values
   * The set timeout create the gap the allows the component to activate its life cycles
   * @private
   */
  private destroyAndReviveTopologyTooltips() {
    this.isShowTooltips = false;
    setTimeout(() => {
      this.isShowTooltips = true;
    });
  }
}
