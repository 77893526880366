import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class OpenDialogService {

  constructor(public dialog: MatDialog) { }

  openDialog(height: (number | 'auto'), width: (number | 'auto'), dialogComponent: any, data?: any, minWidth?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = height === "auto" ? height : `${height}px`;
    dialogConfig.width = width === "auto" ? width : `${width}px`;
    dialogConfig.minWidth = `${minWidth}px`;
    data ?
      dialogConfig.data = data : null;
    dialogConfig.panelClass = "no-dialog-overflow"
    this.dialog.open(dialogComponent, dialogConfig);
  }

}
