import {Injectable} from '@angular/core';
import {
  EndPointDisplay,
  EndPointValueTypes,
  ExportPolicyDTO,
  FirewallRullDisplay, getEndPointIconName,
  KeyValueIcon,
  PolicyRuleDTO
} from 'src/app/shared/models/policies.model';
import {distinctArray, sortArrayByParam} from 'src/app/shared/operators/array.operator';
import {Fabric} from "../../../shared/models/fabrics.model";

@Injectable({
  providedIn: 'root'
})
export class PolicyDisplayGeneratorService {

  constructor() {
  }

  /**
   * Create EndPointDisplay array
   * 1. Create ruleId
   * 2. Create markerId (per rule)
   * 3. Remove irrelevant fields
   * 4. Check data direction
   */
  formatNetworkRules(policiesData: ExportPolicyDTO[], fabric: Fabric): EndPointDisplay[] {
    let firewallRules: FirewallRullDisplay[] = [];
    let endPoints: EndPointDisplay[] = [];
    let highestRuleIndex: number = 0;
    policiesData.forEach((policy, policyIndex) => {
      if (policy && policy.networkRules && policy.networkRules.length > 0) {
        policy.networkRules.forEach((rule, ruleIndex) => {
          let isRemoveAnyFileds: boolean = this.isRemoveAnyFields(rule);
          highestRuleIndex++;
          rule.dstEndPoints.forEach(point => {
            //Prepare data for fabric display
            if (point.fabrics.length > 0) {
              point.fabrics.forEach(endPointFabric => {
                endPoints.push(new EndPointDisplay(
                  highestRuleIndex,
                  endPointFabric.fabricName,
                  this.getFormmatedEndPointValue(point.value),
                  getEndPointIconName('fabric'),
                  rule.action,
                  endPointFabric.fabricId != fabric.id,
                  rule.comment
                ))
              })
            }

            //Prepare data for value display
            else {
              for (let [key, value] of Object.entries(point.value)) {
                if (key !== "section" && !isRemoveAnyFileds || (isRemoveAnyFileds && value.toLowerCase() !== "any"))
                  endPoints.push(
                    new EndPointDisplay(
                      highestRuleIndex,
                      value,
                      null,
                      getEndPointIconName(point.endPointType),
                      rule.action,
                      true,
                      rule.comment
                    ))
              }
            }
          })
        })
      }
    })
    let distinctedArray = distinctArray(endPoints, ["value", "action", "isSrcIsFabric"]).sort(sortArrayByParam("ruleId", "asc"));
    return this.addEvenOddIndex(distinctedArray);
  }

  /**
   * If a rule has value on one of its dstEndPoints - return true
   */
  isRemoveAnyFields(rule: PolicyRuleDTO): boolean {
    let isRemoveAnyFields = false;
    for (let index = 0; index < rule.dstEndPoints.length; index++) {
      const point = rule.dstEndPoints[index];
      if (Object.values(point.value).find(val => val && val.toLowerCase() !== "any" && val.toLowerCase() !== "unknown") != undefined) {
        isRemoveAnyFields = true;
        break;
      }
    }
    return isRemoveAnyFields;
  }

  /**
   * Add markerIndex per rule.
   * The marker will allow to color each rule with different (Zebra styte)
   */
  addEvenOddIndex(endPointDisplay: EndPointDisplay[]) {
    let index = 0;
    endPointDisplay.forEach((rule, ruleIndex) => {
      if (endPointDisplay[ruleIndex - 1] && rule.ruleId != endPointDisplay[ruleIndex - 1].ruleId) {
        index++;
      }
      rule.markerIndex = index;
    })
    return endPointDisplay;
  }

  /**
   * Parse endPoint value field and inject it to KeyValueIcon object
   *
   */
  getFormmatedEndPointValue(endPoint: EndPointValueTypes): KeyValueIcon[] {
    let endPointData: KeyValueIcon[] = [];
    for (let [key, value] of Object.entries(endPoint)) {
      endPointData.push({
        key: key,
        value: value,
        iconName: getEndPointIconName(key)
      })
    }
    return endPointData;
  }

  formatTrafficShapingRules(policiesData: ExportPolicyDTO[]): any {
    let trafficShapingRules = {};
    policiesData.forEach((policy, policyIndex) => {
      if (policy && policy.trafficShapingRules && policy.trafficShapingRules.length > 0) {
        policy.trafficShapingRules.forEach((rule, ruleIndex) => {
          let ruleLength = Object.keys(rule).length;
          let index = 0;
          for (let [key, value] of Object.entries(rule)) {
            if (key == "dstEndPoints") {
              let endPoints: string = '';
              value.forEach(endPoint => {
                endPoints += endPoint
              });
              trafficShapingRules[`Destinations.${policyIndex}.${ruleIndex}`] = endPoints.length > 0 ? endPoints : 'None';
            } else {
              trafficShapingRules[`${key}.${policyIndex}.${ruleIndex}`] = value;
            }
            if (key == "maxInbound" || key == "maxOutbound") {
              trafficShapingRules[`${key}.${policyIndex}.${ruleIndex}`] = value + "KB";
            }
            if (index == ruleLength - 1) {
              trafficShapingRules[`zzz${policyIndex}.${ruleIndex + 1}`] = -1;
            }
            index++;
          }
        });
      }
    });
    return trafficShapingRules;
  }

  formatContentFilteringRules(policiesData: ExportPolicyDTO[]) {
    let filteringRules = {};
    policiesData.forEach((policy, index) => {
      if (index == 0) {
        filteringRules["allowedUrlPatterns"] = null
      }
      policy.contentFilteringRules.allowedUrlPatterns.patterns.forEach(pattern => {
        if (pattern)
          filteringRules[pattern] = policy.contentFilteringRules.allowedUrlPatterns.settings;
      });

      if (index == 0) {
        filteringRules["blockedUrlPatterns"] = null
      }
      policy.contentFilteringRules.blockedUrlPatterns.patterns.forEach(pattern => {
        if (pattern)
          filteringRules[pattern] = policy.contentFilteringRules.blockedUrlPatterns.settings;

      });

      if (index == 0) {
        filteringRules["blockedUrlCategories"] = null
      }
      policy.contentFilteringRules.blockedUrlCategories.categories.forEach(category => {
        if (category)
          filteringRules[category] = policy.contentFilteringRules.blockedUrlCategories.settings;
      })
    });
    return filteringRules;
  }
}
