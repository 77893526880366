<mat-form-field>
  <mat-label>Enter a dates range</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onInputDateChanged($event)">
    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onInputDateChanged($event)">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker (opened)="isPickerOpen = true" (closed)="onPickerClosed()"></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
