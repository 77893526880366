import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {StackedBarConfig, StackedBarThemeConfig} from '../models/stacked-bar.config';
import {KpiMultiDataService} from '../services/kpi-stacked-split-data.service';
import {GraphTrendConfig} from '../models/graph-trend-config';
import {ThemePalette} from '@angular/material/core';
import {KpiGroupedBy} from "../../../models/kpi.model";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {NgChanges} from "../../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-stacked-bar-with-graph',
  templateUrl: './stacked-bar-with-graph.component.html',
  styleUrls: ['./stacked-bar-with-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedBarWithGraphComponent {
  @Input() stackedAndSplitData: { splitGraphConfig: GraphTrendConfig, stackedGraphConfig: GraphTrendConfig };
  @Input() height: number = 40;
  @Input() width: number = 400;
  @Input() stackedBarClass: string;
  @Input() toggleButtons = [KpiGroupedBy.Traffic, KpiGroupedBy.Wired, KpiGroupedBy.Application];
  @Output() stackedDatasetSelected: EventEmitter<GraphTrendConfig> = new EventEmitter();
  @Output() toggleSelectionChanges: EventEmitter<KpiGroupedBy> = new EventEmitter();
  @Output() isAppSelected: EventEmitter<boolean> = new EventEmitter();
  formattedStackedBarConfig: StackedBarConfig;
  color: ThemePalette = "primary";
  showStackedData: boolean = false;
  kpiGroupedBy = KpiGroupedBy;

  constructor(
    private kpiStackedAndSplitDataService: KpiMultiDataService) {
  }


  ngOnChanges(changes: NgChanges<StackedBarWithGraphComponent>) {
    if (changes.stackedAndSplitData && this.stackedAndSplitData && this.stackedAndSplitData.stackedGraphConfig) {
      this.formattedStackedBarConfig = this.kpiStackedAndSplitDataService.prepareStackedBarConfig(this.stackedAndSplitData.stackedGraphConfig);
    }
  }

  /**
   * Add the current clicked data set to the original one (split data).
   * Emit the new line config with the original + new data selection
   */
  addSelectedDataSetToSplitData(event: StackedBarThemeConfig) {
    if (this.stackedAndSplitData) {
      if (event) {
        let selectedGraphDataset = this.stackedAndSplitData.stackedGraphConfig.datasets.find(config => config.label == event.label);
        const splitConfig = [...this.stackedAndSplitData.splitGraphConfig.datasets];
        const splitUnit = this.stackedAndSplitData.splitGraphConfig.unit;
        const splitLabels = this.stackedAndSplitData.splitGraphConfig.labels;
        let newGraphData: GraphTrendConfig = {
          unit: splitUnit,
          datasets: splitConfig,
          labels: splitLabels
        }
        newGraphData.datasets.push(selectedGraphDataset);
        this.stackedDatasetSelected.emit(newGraphData);
      } else {
        this.stackedDatasetSelected.emit(this.stackedAndSplitData.splitGraphConfig);
      }
    }
  }

  /**
   * If selected toggle is one of the stacked data buttons:
   * 1. Changes showStackedData to true
   * @param event
   */
  onToggleButtonClick(event: MatButtonToggleChange) {
    const toggleSelection = event.value as KpiGroupedBy;
    this.showStackedData = toggleSelection === KpiGroupedBy.Application;
    this.toggleSelectionChanges.emit(toggleSelection);
  }
}
