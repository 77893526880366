import {Injectable} from '@angular/core';
import {LegacySeverity} from '../../models/severity.model';
import {HealthStatus} from '../../models/health.model';
import {SingleLinkStatus} from '../../models/single-link.model';
import {DeviceState} from "../../models/devices.model";

@Injectable({
  providedIn: 'root'
})
export class SeverityService {

  serverityByHealth(healthPercent: number): LegacySeverity {
    if (0 <= healthPercent && healthPercent < 60) {
      return LegacySeverity.High;
    }
    if (60 <= healthPercent && healthPercent < 90) {
      return LegacySeverity.Medium;
    }
    if (healthPercent >= 90) {
      return LegacySeverity.Minor;
    }
    return -1;
  }

  severityByWorstHealthStatus(healthStatus: HealthStatus) {
    if (healthStatus.high) {
      return LegacySeverity.High;
    }
    if (healthStatus.medium) {
      return LegacySeverity.Medium;
    }
    return LegacySeverity.Minor;
  }

  severityByLinkStatus(linkStatus: any) {
    if (linkStatus == SingleLinkStatus.Error.toString()) {
      return "severity_high";
    }
    if (linkStatus == SingleLinkStatus.Warning.toString()) {
      return "severity_medium";
    }
    return "severity_low";

  }

  severityByFabricsAndDevicesHealth(fabricsHealth: number, devicesHealth: number): LegacySeverity {
    let health;
    if (fabricsHealth == -1) {
      health = devicesHealth
    } else if (devicesHealth == -1) {
      health = fabricsHealth
    } else {
      health = Math.min(fabricsHealth, devicesHealth);
    }

    return this.serverityByHealth(health)
  }

  severityByDeviceStatus(deviceStatus: DeviceState): LegacySeverity {
    switch (deviceStatus) {
      case DeviceState.Online:
        return LegacySeverity.Minor;
      case DeviceState.Alerting:
        return LegacySeverity.Medium;
      case DeviceState.Offline:
        return LegacySeverity.High;
      case DeviceState.Unknown:
        return LegacySeverity.Zero;
      default:
        break;
    }
  }
}
