import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetopDialogComponent } from './netop-dialog.component';
import { NetopDialogTitleComponent } from './netop-dialog-title/netop-dialog-title.component';
import { NetopDialogContentComponent } from './netop-dialog-content/netop-dialog-content.component';
import { NetopDialogActionsComponent } from './netop-dialog-actions/netop-dialog-actions.component';
import { NetopDialogSubTitleComponent } from './netop-dialog-sub-title/netop-dialog-sub-title.component';
import { MaterialModule } from '../../material.module';
import { SubHeaderBorderComponent } from './sub-header-border/sub-header-border.component';



@NgModule({
  declarations: [
    NetopDialogComponent,
    NetopDialogTitleComponent,
    NetopDialogContentComponent,
    NetopDialogActionsComponent,
    NetopDialogSubTitleComponent,
    SubHeaderBorderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    NetopDialogComponent,
    NetopDialogTitleComponent,
    NetopDialogContentComponent,
    NetopDialogActionsComponent,
    NetopDialogSubTitleComponent,
    SubHeaderBorderComponent
  ],

})
export class NetopDialogModule { }
