<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog()" [closeIconClass]="'col-no-flex'">
        {{dialogTitle}}
    </app-netop-dialog-title>
    <app-netop-dialog-content class="no-overflow-x">
        <ng-container *ngIf="changes?.length > 0">
            <div class="row synced-properties-container">
                <div *ngFor="let change of changes" class="col synced-properties-col">
                    <app-synced-entity-properties [singleChange]="change"></app-synced-entity-properties>
                </div>
            </div>
        </ng-container>
    </app-netop-dialog-content>
</app-netop-dialog>