import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { GraphTypeSelectorData } from '../graph-type-selector/graph-type-selector-data.model';
import { DashboardService } from '../../services/rest-services/dashboard.service';
import { EntityType } from '../../models/entity-type.enum';
import { KpiService } from '../../services/rest-services/kpi.service';
import { KpiType } from '../../models/kpi.model';
import { map } from 'rxjs/operators';
import { GraphTypeSelectorsConfigService } from '../graph-type-selectors/graph-type-selectors-config.service';
import { GraphType } from '../graph-type-selector/graph-type.enum';
import { LoggerService, Logger } from '../../services/logger.service';
import { TrendGraphSelectorsConfigService } from './trend-graph-selectors-config.service';

/**
 * Service that fetches data for most of the graph type selectors and creates a configuration 
 * for the selectors display.
 */
@Injectable({
    providedIn: 'root'
})
export class BaseTrendGraphSelectorsConfigService implements TrendGraphSelectorsConfigService {

    protected logger: Logger;

    constructor(protected loggerFactory: LoggerService,
        protected dashboardService: DashboardService,
        protected graphTypeSelectorsConfigService: GraphTypeSelectorsConfigService,
        protected kpiService: KpiService) {
        this.logger = this.loggerFactory.getLogger("TrendGraphSelectorsConfigService");
    }
    getTrendGraphSelectorsConfig$(entityType: (EntityType.ORGANIZATION | EntityType.TENANT)): Observable<GraphTypeSelectorData[]> {
        switch (entityType) {
            case EntityType.ORGANIZATION:
                return zip(this.dashboardService.fetchHealthStatus(EntityType.TENANT),
                    this.dashboardService.fetchHealthStatus(EntityType.VENUE),
                    this.dashboardService.fetchHealthStatus(EntityType.FABRIC),
                    this.dashboardService.fetchHealthStatus(EntityType.DEVICE)).pipe(map(
                        ([tenantsHealth, venuesHealth, fabricsHealth, devicesHealth]) => {
                            this.logger.debug('TENANTS health status %o', tenantsHealth)
                            return this.graphTypeSelectorsConfigService.getOrgActionsConfig({
                                [GraphType.TENANT]: tenantsHealth,
                                [GraphType.VENUE]: venuesHealth,
                                [GraphType.FABRIC]: fabricsHealth,
                                [GraphType.DEVICE]: devicesHealth
                            })
                        }
                    ))
            case EntityType.TENANT:
                return zip(this.dashboardService.fetchHealthStatus(EntityType.VENUE),
                    this.dashboardService.fetchHealthStatus(EntityType.FABRIC),
                    this.dashboardService.fetchHealthStatus(EntityType.DEVICE),
                    this.kpiService.getKpiCurrentValue(KpiType.Traffic, true)).pipe(map(
                        ([venuesHealth, fabricsHealth, devicesHealth, traffic]) => {
                            this.logger.debug('VENUES health status %o', venuesHealth)
                            return this.graphTypeSelectorsConfigService.getTenantActionsConfig({
                                [GraphType.DEVICE]: devicesHealth,
                                [GraphType.VENUE]: venuesHealth,
                                [GraphType.FABRIC]: fabricsHealth,
                                [GraphType.TRAFFIC]: traffic
                            })
                        }
                    ));
            default:
                break;
        }


    }

    getHealthKpiTrendConfig$(entityType: EntityType.TENANT | EntityType.ORGANIZATION, time: string): Observable<GraphTypeSelectorData[]> {
        switch (entityType) {
            case EntityType.ORGANIZATION:
                return zip(this.dashboardService.fetchHealthStatusByTime(EntityType.TENANT, time),
                    this.dashboardService.fetchHealthStatusByTime(EntityType.VENUE, time),
                    this.dashboardService.fetchHealthStatusByTime(EntityType.FABRIC, time),
                    this.dashboardService.fetchHealthStatusByTime(EntityType.DEVICE, time)).pipe(map(
                        ([tenantsHealth, venuesHealth, fabricsHealth, devicesHealth]) => {
                            this.logger.debug('TENANTS health status %o', tenantsHealth)
                            return this.graphTypeSelectorsConfigService.getOrgActionsConfig({
                                [GraphType.TENANT]: tenantsHealth,
                                [GraphType.VENUE]: venuesHealth,
                                [GraphType.FABRIC]: fabricsHealth,
                                [GraphType.DEVICE]: devicesHealth
                            })
                        }
                    ))
            case EntityType.TENANT:
                return zip(this.dashboardService.fetchHealthStatusByTime(EntityType.VENUE, time),
                    this.dashboardService.fetchHealthStatusByTime(EntityType.FABRIC, time),
                    this.dashboardService.fetchHealthStatusByTime(EntityType.DEVICE, time),
                    this.kpiService.getKpiCurrentValueByTime(KpiType.Traffic, time)).pipe(map(
                        ([venuesHealth, fabricsHealth, devicesHealth, traffic]) => {
                            this.logger.debug('VENUES health status %o', venuesHealth)
                            return this.graphTypeSelectorsConfigService.getTenantActionsConfig({
                                [GraphType.DEVICE]: devicesHealth,
                                [GraphType.VENUE]: venuesHealth,
                                [GraphType.FABRIC]: fabricsHealth,
                                [GraphType.TRAFFIC]: traffic
                            })
                        }
                    ));
            default:
                break;
        }
    }
}