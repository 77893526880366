import {Injectable} from "@angular/core";
import {ViewFilterStates} from "../models/actions-list-query-params.model";
import {FilterData} from "../../../../../shared/components/filters-bar/models/filters-bar.model";

@Injectable({providedIn: 'root'})
export class ActionsFiltersStateStore {
  readonly LOCAL_STORAGE_KEY = 'actionsFilterState';
  state: ViewFilterStates = {};

  addViewState(viewName: string) {
    const storedState = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (storedState) {
      this.state = JSON.parse(storedState);
    }
    if (!Object.keys(this.state).includes(viewName)) {
      this.state[viewName] = {
        singleFilters: {},
        multiFilters: {}
      };
    }
  }

  addFilterState(viewName: string, filterData: FilterData<any, any>) {
    this.addFilterValue(viewName, filterData.filterName, filterData.data);
  }

  addFilterValue(viewName: string, filterName: string, value: any) {
    if (value) {
      if (!this.state[viewName]) {
        this.clearState(viewName);
      }
      if (value.constructor.name == 'Array') {
        if (!this.state[viewName].multiFilters) {
          this.state[viewName].multiFilters = {};
        }
        this.state[viewName].multiFilters[filterName] = value;
      }
      else {
        if (!this.state[viewName].singleFilters) {
          this.state[viewName].singleFilters = {};
        }
        this.state[viewName].singleFilters[filterName] = value;
      }
    }
  }

  updateState() {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.state));
  }

  clearState(viewName: string) {
    this.state[viewName] = {
      singleFilters: {},
      multiFilters: {}
    };
    this.updateState();
  }

  stateEqualsValue(viewName: string, filterName: string, value: string) {
    return Object.keys(this.state).includes(viewName)
      && Object.keys(this.state[viewName].singleFilters).includes(filterName)
      && this.state[viewName].singleFilters[filterName] == value
  }

  stateContainsValue(viewName: string, filterName: string, value: string) {
    return Object.keys(this.state).includes(viewName)
      && Object.keys(this.state[viewName].multiFilters).includes(filterName)
      && this.state[viewName].multiFilters[filterName]?.includes(value);
  }
}
