<app-netop-dialog>
  <app-netop-dialog-title (close)="closeDialog()" [closeIconClass]="'col-no-flex'">
    <span style="text-transform: capitalize">{{(entity | async)?.name}} {{selectedWidgetType}} Data</span>
  </app-netop-dialog-title>
  <app-netop-dialog-content [cssClass]="'no-overflow-x'">
    <div class="m-4">
      <app-single-widget-big-chart [kpiType]="selectedWidgetType" class="pt-3" [closeIconClass]="'col-no-flex'"
                                   [cssGraphClass]="'single-big-chart'" [graphWidth]="graphWidth"
                                   [graphHeight]="graphHeight" [allowStacked]="true" [isShowCloseIcon]="false"
                                   [showPeriodForm]="false">
      </app-single-widget-big-chart>
    </div>
  </app-netop-dialog-content>
</app-netop-dialog>
