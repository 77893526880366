import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { EditableKey } from './models/editable-text.model';

@Directive({
  selector: '[appSetFocus]'
})
export class SetFocusDirective {
  @Input('appSetFocus') editableKey: EditableKey;
  @Input() key: string;

  constructor(private el: ElementRef) { }


  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.el && this.editableKey && this.editableKey.key === this.key && this.editableKey.isEditable) {
      this.el.nativeElement.focus();
    }
  }

}
