import { Action, createReducer, on } from '@ngrx/store';
import { Hierarchy } from 'src/app/shared/models/hierarchy.model';
import * as fromHierarchy from '../actions/hierarchy.actions';


export const hierarchyFeatureKey = 'hierarchy';

export interface State {
  hierarchy: Hierarchy
}

export const initialState: State = {
  hierarchy: []
};

const hierarchyReducer = createReducer(
  initialState,
  on(fromHierarchy.loadHierarchy, (state, { hierarchy }) => {
    return {
      ...state,
      hierarchy
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return hierarchyReducer(state, action);
}
