<div class="time-period-container" [ngStyle]="selectedTimePeriod == timePeriod.CUSTOM_DATE ?
{'justify-content': 'space-around'}:
{'justify-content': 'space-between', 'margin-left': '1rem'}">
    <mat-form-field appearance="outline" class="time-period-form-field" [ngClass]="formFieldCss">
        <mat-label>Time period</mat-label>
        <mat-select class="dashboard-selection-container" (selectionChange)="onTimeSelectionChanged($event.value)"
            [(ngModel)]="selectedTimePeriod">
            <mat-option *ngFor="let period of timePeriodArray" [value]="period">
                {{period}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <ng-container *ngIf="selectedTimePeriod == timePeriod.CUSTOM_DATE">
        <app-from-to-date-picker class="date-picker-container" [daysBetweenDatesLimits]="30"
            [maxDateLimit]="maxDateLimit" [fromDate]="fromDate" [toDate]="toDate"
            (selectedTime)="onCustomTimeChanges($event)">
        </app-from-to-date-picker>
    </ng-container>
</div>
