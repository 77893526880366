<app-netop-dialog>

    <app-netop-dialog-title (close)="closeDialog(false)">
        {{'data.MAINTENANCE.BACKUP' | translate}}

    </app-netop-dialog-title>
    <app-netop-dialog-content [cssClass]="'backup-now-content'">

        <form class="col branding-col-form-container" [formGroup]="backupForm">
            <div class="mb-3 mt-3">
                <mat-radio-group class="m-3" formControlName="deploy">
                    <mat-radio-button value="now" [checked]="isNow">
                        {{'data.MAINTENANCE.BACKUP_NOW' | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="later">
                        {{'data.MAINTENANCE.BACKUP_LATER' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="isLater">
                <div class="date-inputs mb-3">
                    <mat-form-field class="mat-form-field-fluid mr-5">
                        <input matInput type="date" formControlName="deployDate">
                    </mat-form-field>
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput type="time" formControlName="deployHour">
                    </mat-form-field>
                </div>
            </ng-container>
        </form>

    </app-netop-dialog-content>
    <app-netop-dialog-actions>

        <!--buttons-->
        <div class="d-flex justify-content-end kt-margin-r-10">
            <mat-dialog-actions>
                <app-buttons (click)="closeDialog(false)" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
                    [btnText]="'data.COMMON.CANCEL'">
                </app-buttons>
                <app-buttons class="kt-margin-l-10" (click)="setBackup()" [btnClass]="" [btnText]="'Backup'">
                </app-buttons>
            </mat-dialog-actions>
        </div>
    </app-netop-dialog-actions>

</app-netop-dialog>