import {Injectable} from '@angular/core';
import {EntityWithPolicies} from "../../../models/policies.model";
import {Topology} from "../../../models/topology";
import {SingleDevice} from "../../../models/single-device.model";
import {BasicLink} from "../../../models/single-link.model";
import {PropertiesContent, PropertiesElementType} from "../models/properties-content";
import {PolicyDisplayGeneratorService} from "../../../../fabrics/components/policy-display/policy-display-generator.service";
import {InitatePropertiesDataService} from "./initate-properties-data.service";
import {SingleEntityService} from "../../single-entities/single-entity.service";
import {BaseEntityPropertiesGenerator} from "./base-entity-properties-generator";
import {FabricKpiData} from "../../../models/fabrics.model";
import {convertCamelcaseToKebabCased} from "../../../operators/string-opertators";

@Injectable({
  providedIn: 'root'
})
export class GenerateFabricPropertiesService extends BaseEntityPropertiesGenerator {

  constructor(protected prepareProps: InitatePropertiesDataService,
              protected singleEntityService: SingleEntityService,
              private policyDisplayGenerator: PolicyDisplayGeneratorService) {
    super(prepareProps, singleEntityService)
  }

  prepareFabricData(fabricWithPolicies: EntityWithPolicies<FabricKpiData>, fabricTopology: Topology<SingleDevice, BasicLink>) {
    let propData: PropertiesContent;
    if (fabricWithPolicies) {
      let fabric = fabricWithPolicies.entityData.fabric;
      let properties = Object.assign({}, fabric);
      delete properties.ssids;
      delete properties.vlans;

      propData = {
        headerData: {
          id: fabric.id,
          elementType: PropertiesElementType.FABRIC,
          firstColumn: {main: fabric.name, sub: fabric.fabricStatus.status ? fabric.fabricStatus.status : null},
          iconPath: '/assets/media/netop/topology/fabric-icon.svg',
          health: this.prepareProps.multiHealthBy100(+fabricWithPolicies.entityData.health),
          healthDegradationReasons: fabricWithPolicies.entityData.healthDegradationReasons,
          issues: fabricTopology ? this.singleEntityService.calculateTotalIssues(fabricTopology) : 0
        },
        mainData: {
          panelElements: [
            // {key: 'staticInfo', value: this.prepareProps.flattenProperties(properties)},
            {key: 'ssids', value: fabric.ssids[0] ? this.prepareProps.flattenProperties(fabric.ssids[0]) : null},
            {key: 'vlans', value: fabric.vlans[0] ? this.prepareProps.flattenProperties(fabric.vlans[0]) : null},
            {
              key: 'contentFilteringPolicies',
              value: this.policyDisplayGenerator.formatContentFilteringRules(fabricWithPolicies.policiesData),
              isValueAsTooltip: true
            },
            {
              key: 'trafficShapingPolicies',
              value: this.policyDisplayGenerator.formatTrafficShapingRules(fabricWithPolicies.policiesData),
              withCrudIcons: true
            },
            {
              key: 'FirewallPolicies',
              value: this.policyDisplayGenerator.formatNetworkRules(fabricWithPolicies.policiesData, fabric),
              isTeleported: true

            },
          ],
          elementType: PropertiesElementType.FABRIC
        }
      }
    }
    return propData;
  }
}
