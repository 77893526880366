import {TranslateService} from "@ngx-translate/core";
import {ChangeDetectorRef} from "@angular/core";
import {GridTypes} from "../../../models/client-storage.model";

export class BaseHealthGrid {
  total: number;
  title: string;
  totalTitle: string;
  searchTerm: string;
  public gridType: GridTypes;

  constructor(protected translate: TranslateService, gridType: GridTypes, title: string, totalTitle: string, protected cdr: ChangeDetectorRef) {
    this.gridType = gridType;
    this.totalTitle = totalTitle;
    this.title = title;
  }

  onSearchChanged($event: string) {
    this.searchTerm = $event;
    this.cdr.detectChanges();
  }
}
