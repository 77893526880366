import { Component, OnInit } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-device-clients-connection',
  templateUrl: './top-device-clients-connection.component.html',
  styleUrls: ['./top-device-clients-connection.component.scss']
})
export class TopDeviceClientsConnectionComponent implements OnInit {
  origin: TopTrafficOrigin = TopTrafficOrigin.DEVICE_BY_CLIENTS;

  constructor() { }

  ngOnInit() {
  }

}
