<div class="action-selection-container">
    <div class="row action-conf-data-row pt-5">
        <div class="col action-conf-dialog-text-key align-center-column col-flex-6">
            {{firstQuestion.key}}
        </div>
        <div class="col col-flex-3 align-center-column">
            <mat-button-toggle-group [name]="firstQuestion.step" (change)="storeUserSelection($event)"
                class="toggle-btn-group">
                <mat-button-toggle *ngFor="let toggle of firstQuestion.options" [value]="toggle" aria-label="{{toggle}}"
                    [ngClass]="getToggleButtonClass(toggle)">
                    {{toggle}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <ng-container *ngIf="secondQuestion">
        <div class="row action-conf-data-row pt-5">
            <div class="col action-conf-dialog-text-key align-center-column col-flex-6">
                {{secondQuestion.key}}
            </div>
            <div class="col col-flex-3 align-center-column">
                <ng-container *ngIf="secondQuestion.type == 'toggle'" [ngTemplateOutlet]="toggleOptions"></ng-container>
                <ng-container *ngIf="secondQuestion.type == 'neut-radio'" [ngTemplateOutlet]="neutRadioOptions">
                </ng-container>
                <ng-container *ngIf="secondQuestion.type == 'pos-radio'" [ngTemplateOutlet]="posRadioOptions">
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #toggleOptions>
    <mat-button-toggle-group (change)="storeUserSelection($event)" class="toggle-btn-group"
        [name]="secondQuestion.step">
        <mat-button-toggle *ngFor="let toggle of secondQuestion.options" [value]="toggle" aria-label="{{toggle}}"
            [ngClass]="getToggleButtonClass(toggle)">
            {{toggle}}
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>

<ng-template #neutRadioOptions>
    <ng-container [ngTemplateOutlet]="radioGroupSelection"></ng-container>
</ng-template>

<ng-template #posRadioOptions>
    <ng-container [ngTemplateOutlet]="radioGroupSelection"></ng-container>
</ng-template>

<ng-template #radioGroupSelection>
    <mat-radio-group class="action-conf-dialog-radio-group" aria-label="Select an option" [name]="secondQuestion.step"
        (change)="storeUserSelection($event)">
        <mat-radio-button *ngFor="let radio of secondQuestion.options" [checked]="radio == 'No'" [value]="radio"
            class="action-conf-dialog-text">
            {{radio}}</mat-radio-button>
    </mat-radio-group>
</ng-template>