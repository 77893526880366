import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GraphTrendConfig} from 'src/app/shared/components/chartjs-components/models/graph-trend-config';
import {KpiGroupedBy, KpiType, TrafficUnits} from 'src/app/shared/models/kpi.model';
import {catchError, take} from 'rxjs/operators';
import {KpiService} from 'src/app/shared/services/rest-services/kpi.service';
import moment from 'moment';
import {isArrayEmpty, isObjectEmpty} from 'src/app/shared/operators/object-operators/null-checks';
import {KpiMultiDataService} from 'src/app/shared/components/chartjs-components/services/kpi-stacked-split-data.service';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {NgxGaugeConfiguration} from 'src/app/shared/components/gauge/gauge.model';
import {BandwidthUtilizationData} from 'src/app/shared/models/bandwidth.model';
import {NgChanges} from 'src/app/shared/extend-angular-classes/on-changes';
import {of} from 'rxjs';
import {StackedBarWithGraphComponent} from "../../chartjs-components/stacked-bar-with-graph/stacked-bar-with-graph.component";
import {BaseKpiChartDisplay} from "../../single-entities/multi-kpi-action-graph-trend/base-kpi-chart-display";
import {calculateDaysNumberBetweenTwoDates} from "../../../operators/time-operator";
import {FromToDatesStrings, TimeUnit} from "../../../models/time.model";


@Component({
  selector: 'app-single-widget-big-chart',
  templateUrl: './single-widget-big-chart.component.html',
  styleUrls: ['./single-widget-big-chart.component.scss']
})
export class SingleWidgetBigChartComponent extends BaseKpiChartDisplay {
  @Input() cssGraphClass;
  @Input() headerRowCss: string;
  @Input() closeIconClass: string;
  @Input() isDevice: boolean;
  @Input() allowStacked: boolean = false;
  @Input() showPeriodForm: boolean = true;
  @Input() venueUtilizationData: BandwidthUtilizationData;
  //TODO: Replace with real dates picker when we will have one
  @Input() dates: FromToDatesStrings = {
    start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    end: new Date().toISOString()
  }
  @Input() isShowCloseIcon: boolean = true;
  @Output() kpiUnitCalculated: EventEmitter<TrafficUnits> = new EventEmitter<TrafficUnits>();
  @ViewChild('stackedBar') stackedBar: StackedBarWithGraphComponent;

  gaugeConfiguration: NgxGaugeConfiguration[] = [];
  // trendPeriod = ['Day', 'Week', 'Month', '6 Months', 'Year'];
  // selectedTrendPeriod: FormGroup;


  /**
   * @param trendLineConfig The canvas configuration
   */
  trendLineConfig: GraphTrendConfig;

  /**
   * @param closeChart Emit event when the closing icon is clicked
   */
  @Output() closeChart: EventEmitter<boolean> = new EventEmitter();

  constructor(
    protected kpiStackedAndSplitDataService: KpiMultiDataService,
    protected kpiService: KpiService,
    protected kpiDataService: KpiDataService,
    protected cdr: ChangeDetectorRef) {
    super(kpiStackedAndSplitDataService, kpiService, kpiDataService, cdr)
  }

  ngOnInit() {
    // this.initTrendPeriodSelection();
  }

  ngOnChanges(changes: NgChanges<SingleWidgetBigChartComponent>) {
    if (changes.venueUtilizationData && this.venueUtilizationData) {
      this.initNgxGaugeConfiguration();
    }
    if ((changes.kpiType || changes.dates) && this.dates) {
      this.toggleSelection = KpiGroupedBy.Traffic;
      this.getChartTrend();
    }
  }

  private initNgxGaugeConfiguration() {
    if (this.venueUtilizationData.utilization && this.venueUtilizationData.bandwidth) {
      this.gaugeConfiguration = [
        new NgxGaugeConfiguration(this.venueUtilizationData.utilization.recvTotal, 'Downlink', this.venueUtilizationData.bandwidth.acquiredDownlink),
        new NgxGaugeConfiguration(this.venueUtilizationData.utilization.sentTotal, 'Uplink', this.venueUtilizationData.bandwidth.acquiredUplink)
      ];
      this.cdr.markForCheck();
    }
  }

  /**
   * @method getChartTrend Receive the trend by kpiType
   */
  getChartTrend() {
    if (this.kpiType == KpiType.Traffic) {
      this.getTrafficTrend(KpiGroupedBy.Traffic);
    }
    if (this.kpiType !== KpiType.Traffic) {
      this.getOtherKpiTrend();
    }

  }

  /**
   * @method setChartConfig receive the kpi configuration and initiate the trendLineConfig param
   */
  setChartConfig(kpiTrend) {
    let data: GraphTrendConfig;
    let trendLineConfig: GraphTrendConfig;

    if (!this.isTraffic) {
      const formattedKpiTrend: { x: any, y: any }[] = kpiTrend.map((datum: any) => {
        return {x: moment(datum.datetime || datum.date), y: datum.value};
      });
      data = this.kpiStackedAndSplitDataService.setTrendLineConfig(formattedKpiTrend, trendLineConfig, this.kpiType, this.toggleSelection);
    }
    if (this.isTraffic) {
      this.unit = kpiTrend && kpiTrend.length > 0 && !this.isAppDisplay ? kpiTrend[0].unit : this.unit;
      this.kpiUnitCalculated.emit(this.unit);
      data = this.kpiStackedAndSplitDataService.setTrendLineConfig(kpiTrend, trendLineConfig, this.kpiType, this.toggleSelection);
    }

    if (!isArrayEmpty(data) || !isObjectEmpty(data)) {
      this.trendLineConfig = !this.isAppDisplay ? data : {...this.kpiStackedAndSplitDataService.mergeMultiLineConfig(this.trendLineConfig)};
      this.stackedAndSplitData = this.isAppDisplay ? {
        splitGraphConfig: {...this.trendLineConfig},
        stackedGraphConfig: this.kpiStackedAndSplitDataService.convertStackedToTrafficUnit(this.unit, data)
      } : null;
    }
    this.cdr.markForCheck();
  }

  getOtherKpiTrend() {
    this.isLoading = true;
    this.kpiService.getCurrentKpiTrend(this.kpiType).pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(null);
      })
    ).subscribe(
      (kpiTrend) => {
        this.isLoading = false;
        if (kpiTrend) {
          this.setChartConfig(kpiTrend);
        }
      });
  }

  getTrafficTrend(groupedBy: KpiGroupedBy) {
    this.isLoading = true;
    const timeBack = calculateDaysNumberBetweenTwoDates(new Date(this.dates.start), new Date(this.dates.end));
    this.kpiService.getMultiKpiTrends(timeBack, this.dates.end, TimeUnit.DAYS, groupedBy).pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(null);
      })
    ).subscribe(
      (kpiTrend) => {
        this.isLoading = false;
        this.setChartConfig(kpiTrend);
      });
  }

  /**
   * If selected toggle is one of the stacked data buttons:
   * 1. Changes showStackedData to true
   * @param event
   */
  onToggleChange(kpiGrouped: KpiGroupedBy) {
    this.toggleSelection = kpiGrouped;
    this.getTrafficTrend(kpiGrouped);
  }

  /**
   * @method closeBigChart emit event that tells the parent component to hide the chart with ngIf
   */
  closeBigChart() {
    this.closeChart.emit(true);
  }

  // private initTrendPeriodSelection() {
  //   this.selectedTrendPeriod = this.fb.group({
  //     selectedTrendPeriod: [null, Validators.required]
  //   });
  //   const toSelect = this.trendPeriod.find(period => period === 'Week');
  //   this.selectedTrendPeriod.get('selectedTrendPeriod').setValue(toSelect);
  // }

  get isOnlyInteger() {
    if (this.kpiType === KpiType.Clients) {
      return 1;
    }
    return undefined;
  }

  get maxTrendValue() {
    if (this.kpiType === KpiType.Health) {
      return 100;
    }
    return undefined;
  }

  get isTrafficUtilization() {
    return this.kpiType == KpiType.Traffic && this.gaugeConfiguration.length > 0;
  }


  get formattedKpiHeader() {
    let title = this.kpiType.toString();
    if (this.kpiType !== KpiType.Clients) {
      const unit = this.kpiDataService.getKpiUnit(this.kpiType, this.unit);
      if (unit !== 'undefined') {
        title += ` (${unit}) `;
      }
    }
    return `${title}`;
  }
}
