<app-netop-dialog>

    <app-netop-dialog-title (close)="closeDialog()">
        <span class="issue-actions-dialog-title">
            {{'data.DASHBOARD.ACTION_CATEGORY'| translate:issueAction.actionData}} -
            {{issueAction.actionData.description}}
        </span>
    </app-netop-dialog-title>

    <!--Dialog Sub Header-->
    <app-netop-dialog-sub-title>
        <div class="issues-actions-dialog-title-item issues-health-icon-container">
            <app-severity-cell-renderer [severity]="issueAction.severity"></app-severity-cell-renderer>
        </div>
        <!-- severity color/style -->
        <div class="issues-actions-dialog-title-item col-content issues-health-entity-name"
            [ngClass]="severityCssClass">{{severityText}}</div>
        <div class="issues-actions-dialog-vertical-sep"></div>

        <div class="issues-actions-dialog-title-item col-content issues-health-percentage">
            {{issueAction.actionData.categoryName}}</div>
        <div class="issues-actions-dialog-title-item issues-category-{{issueAction.actionData.category}}"></div>
    </app-netop-dialog-sub-title>

    <app-netop-dialog-content>
        <mat-list>
            <mat-list-item *ngFor="let dataItem of ContentDataList">
                <div class="issue-action-dialog-item-name">{{dataItem.label | translate}}</div>
                <div class="issue-action-dialog-item-value">{{issueAction.actionData[dataItem.field]}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
        <!-- <mat-divider></mat-divider> -->
        <mat-list>
            <mat-radio-group class="deploy-time-radio-group" (change)="deployTimeChange()">
                <mat-list-item>
                    <mat-radio-button checked="true" class="deploy-time-radio-button" [value]="deployTimeNow">
                        {{'data.ACTIONS.DEPLOY_NOW'|translate}}
                    </mat-radio-button>
                </mat-list-item>
                <mat-list-item>
                    <mat-radio-button class="deploy-time-radio-button" #deployTimeLater [value]="deployTimeLater">
                        {{'data.ACTIONS.DEPLOY_LATER'|translate}}
                    </mat-radio-button>
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <!-- <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon> -->
                    </mat-datepicker-toggle>
                    <input matInput [matDatepicker]="picker" [disabled]="!deployTimeLater.checked">
                    <mat-datepicker #picker></mat-datepicker>
                </mat-list-item>
            </mat-radio-group>
        </mat-list>
        <!-- <mat-divider></mat-divider> -->
    </app-netop-dialog-content>

    <app-netop-dialog-actions>
        <app-buttons (click)="closeDialog()" [btnVariant]="'stroked'" [btnStyle]="'primary'"
            [btnText]="'data.COMMON.OK'">
        </app-buttons>
        <app-buttons (click)="closeDialog()" [btnStyle]="'primary'" [btnText]="'data.COMMON.DEPLOY'">
        </app-buttons>
    </app-netop-dialog-actions>
</app-netop-dialog>