<div class="row topology-header-row" [ngClass]="headerRowHeight">
  <div class="col tplg-title-col" [cssClasses]="['col-flex-2']"
       [classByCondition]="isFabricsVenue">
    <div class="row title-row" [ngStyle]="isVenueDisplay? {'cursor': 'pointer'} : null"
         (click)="isVenueDisplay? onTopologyHeaderClicked() : null">
      <div class="col col-flex-1" *ngIf="titleIconPath">
        <mat-icon [svgIcon]="titleIconPath"></mat-icon>
      </div>
      <div class="col align-center-column">
        <span class="tplg-title">{{title}}</span>
        <mat-icon *ngIf="isVenueDisplay" class="entity-name-selector"
                  [svgIcon]="'click-with-arrow'"></mat-icon>
      </div>
    </div>
    <div class="tplg-checkbox-container" *ngIf="isShowClient && isInitiateTopology">
      <mat-checkbox *ngIf="isClients" [(ngModel)]="showClientsChecked" (change)="onShowClientsClick()">
        {{'data.TOPOLOGY.SHOW_CLIENTS' | translate}} {{totalClient ? '(' + totalClient + ')' : ''}}</mat-checkbox>
      <span *ngIf="showNoClientError"
            class="fade-in-out-message">{{'data.TOPOLOGY.NO_CLIENTS_ERROR' | translate}}</span>
    </div>
  </div>
  <div #headerCenterDisplayContainer class="col tplg-center-header-container" [cssClasses]="['col-flex-6']"
       [classByCondition]="isFabricsVenue">
    <div class="tplg-header-center-panel"></div>
    <app-header-clients-display *ngIf="showClientsChecked" [height]="tplgHeaderMiddleColRect?.height / 1.5"
                                [width]="tplgHeaderMiddleColRect?.width /1.5" [ngStyle]="{width: '100%'}">
    </app-header-clients-display>
  </div>
  <div class="col disconnected-devices-container" [ngClass]="classForVenueFabricScreen">
    <app-disconnected-devices [isFabricsVenue]="isFabricsVenue" [isDevice]="isDevice"
                              [disConnectedDevices]="disConnectedDevices">
    </app-disconnected-devices>
  </div>
</div>
