import { CrudActionType } from 'src/app/organizations/accounts/org-manage-tenant/crud-action';

export class OrganizationType {
    id?: number
    dashboardType: OrgDashboardType;
    name: string;
    organizationId: number;
    properties: OrgTypeProperty[];
    settingsId?: number;
}
export class OrganizationSetting {
    dashboardType: OrgDashboardType;
    name: string;
    properties: OrgTypeProperty[];
    settingsId: number;
}
export class OrgTypeProperty {
    id?: number;
    name: string;
    type: PropType;
    mandatory: boolean;
    value: string | number;
}

export enum OrgDashboardType {
    ORGANIZATION_LEVEL = "ORGANIZATION_LEVEL",
    TENANT_LEVEL = "TENANT_LEVEL"
}

export enum PropType {
    STRING = "STRING",
    INTEGER = "INTEGER",
    FLOAT = "FLOAT"
}
export namespace PropType {
    export function getPropTypesAsEnum() {
        return [
            PropType.STRING,
            PropType.INTEGER,
            PropType.FLOAT
        ]
    }
}
export namespace OrgDashboardType {
    export function getDashboardTypesAsArray() {
        return [
            OrgDashboardType.ORGANIZATION_LEVEL,
            OrgDashboardType.TENANT_LEVEL
        ]
    }

}

export type OrgTypeWithAction = { type: OrganizationType, crudAction: CrudActionType };

