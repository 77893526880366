import {Subnet} from './vlan.model';
import {BasicDevice} from './single-device.model';
import {VenueRawData, VenueType} from "./venues.model";
import {DeviceState} from "./devices.model";
import {TrafficUnits} from "./kpi.model";
import {Moment} from "moment/moment";

export class VenueVPN {
  id: number;
  firstVpnSite: VPNSite;
  secondVpnSite: VPNSite;
  vpnPhaseSettings: VPNSettings;
}

export class DisplayVenueVPN {
  data: VenueVPN;
  status?: VPNConnectionStatus;
  traffic?: VPNTraffic;
}

export class VPNSettings {
  ikeVersion: string;
  secret: string;
  ipsecPolicies: { key: string, value: {} };
}

export class VPNSite {
  id: number;
  name: string;
  venueData: VenueRawData;
  deviceData: BasicDevice;
  venueVpnMode: VenueVpnMode;
  exportedVlans: Subnet[];
  publicIps: string[];
  vpnSettings: VPNSettings;
  vpnConnectionId: number;
  thirdPartyVpnPeers: VPNSite[];
  status?: DeviceState;
  venueType: VenueType;
  vendorName: string;
}

export enum VPNType {
  VENDOR_VPN,
  THIRD_PARTY_VPN
}

export enum VenueVpnMode {
  HUB = "hub",
  SPOKE = "spoke",
  SiteToSite ="site_to_site",
  NONE = "None"
}

export enum VPNConnectionStatus {
  REACHABLE = "reachable",
  UNREACHABLE = "unreachable",
  UNKNOWN = "unknown"
}

export class VPNTraffic {
  connectionId?: number;
  sent?: number;
  received?: number;
  firstSiteId: number;
  firstSiteTraffic: BasicTraffic;
  secondSiteId: number;
  secondSiteTraffic: BasicTraffic;
}

export class VPNStatus {
  vpnSitesStatus: {
    [id: number]: DeviceState;
  }
  vpnConnectionsStatus: {
    [id: number]: VPNConnectionStatus;
  };
}

export class VPNdata {
  vpnConnections: DisplayVenueVPN[];
  vpnTraffic: VPNTraffic[];
  vpnStatuses: VPNStatus
}

export class VPNSummary {
  vpnConnections: DisplayVenueVPN[];
  vpnStatuses: VPNStatus;
}

export class VpnStatisticsDTO extends VPNTraffic {
  connectionId: number;
}

export class BasicTraffic {
  sent: number;
  recv: number;
}

export class VPNStatusTrendDTO<T> {
  startDate: number;
  endDate: number;
  status: T;
}

export class VPNConnectionTrendDTO {
  sent: VPNTrend;
  recv: VPNTrend;
}

export class VPNConnectionStatusTrendDTO {
  startTime: number;
  endTime: number;
  vpnConnectionStatus: VPNConnectionStatus;
}

export type VPNTrend = Array<{ date: number, value: number }>;

export type VPNConnectionMetricData = {[key: string]: {date: Date, value: number}[]};

export class VPNConnectionMetricTrend {
  key: string;
  metricName: VPNConnectionMetric;
  deviceConnection: string;
  data: {date: Moment, value: number}[];
}

export enum VPNConnectionMetric {
  Traffic = 'traffic',
  Sent = 'sent',
  Recv = 'recv',
  Latency = 'latency',
  Loss = 'loss',
  Jitter = 'jitter',
  Mos = 'mos'
}
export namespace VPNConnectionMetric {
  export function toDisplayList(): VPNConnectionMetric[] {
    return [
      VPNConnectionMetric.Traffic,
      VPNConnectionMetric.Latency,
      VPNConnectionMetric.Loss,
      VPNConnectionMetric.Jitter,
      VPNConnectionMetric.Mos
    ];
  }
  export function toUnit(metric: VPNConnectionMetric): string {
    switch (metric) {
      case VPNConnectionMetric.Traffic:
      case VPNConnectionMetric.Sent:
      case VPNConnectionMetric.Recv:
        return TrafficUnits.KB;
      case VPNConnectionMetric.Latency:
      case VPNConnectionMetric.Jitter:
        return 'ms';
      case VPNConnectionMetric.Loss:
        return '%';
      case VPNConnectionMetric.Mos:
        return 'MOS';
    }
  }
}
