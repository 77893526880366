import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KpiType } from 'src/app/shared/models/kpi.model';


@Injectable({
  providedIn: 'root'
})
export class StoreKpisBarService {
  private notifyKpiType: BehaviorSubject<KpiType> = new BehaviorSubject(null);
  notifyKpiTypeAsObservable$: Observable<KpiType> = this.notifyKpiType.asObservable()

  constructor() { }

  storeKpiTypeSelection(kpiType: KpiType) {
    this.notifyKpiType.next(kpiType);
  }
  deleteKpiTypeSelection() {
    this.notifyKpiType.next(null);
  }
}
