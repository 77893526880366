import {BehaviorSubject, Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {untilDestroyed} from "@ngneat/until-destroy";
import {dirtyCheck} from "../../form-validators/form-changes";
import {tap} from "rxjs/operators";

export class BaseNetopForm {
  store$: Observable<{}>;
  store: BehaviorSubject<{}>;
  formGroup: UntypedFormGroup;
  isDirty$: Observable<boolean>;
  isFormChangedByUser: boolean = false;

  constructor(protected fb: UntypedFormBuilder) {
  }

  /**
   * Generate both store objects.
   * Subscribe to form Changes
   * Follow is sirty changes
   */
  initializeFormChangeDetection() {
    let storeObj = {};

    for (const [key, value] of Object.entries(this.formGroup.getRawValue())) {
      storeObj[key] = value;
    }
    this.store = new BehaviorSubject(storeObj);
    this.store$ = this.store.asObservable();
    this.subscribeToStoreChangeDetection();
    this.initializeIsDirty();
  }

  protected subscribeToStoreChangeDetection() {
    this.store$.pipe(
      untilDestroyed(this)
    ).subscribe(state => {
      this.formGroup.patchValue(state);
    });
  }

  protected initializeIsDirty() {
    this.isDirty$ = this.formGroup.valueChanges.pipe(
      tap(() => this.isFormChangedByUser = true),
      dirtyCheck(this.store$),
    );
  }

}
