<div class="graph-type-selectors column grow space-between" *ngIf="layout == 'column'">
  <ng-container *ngTemplateOutlet="selectorsTemplate">
  </ng-container>
</div>
<div class="graph-type-selectors-row" *ngIf="layout == 'row'"
  [ngClass]="graphTypeSelectorsRowCss">
  <ng-container *ngTemplateOutlet="selectorsTemplate">
  </ng-container>
</div>

<ng-template #selectorsTemplate>
  <ng-container *ngFor="let selectorData of graphTypeSelectorsData;let i=index, let last = last">

    <app-graph-type-selector #graphTypeSelector (selectorClicked)="onSelectorClicked($event)" [layout]="layout"
      [inBarDisplay]="inBarDisplay" (click)="onClick(selectorData)" [selectorData]="selectorData"
      [selectable]="selectable" [selected]="selectable && i == 0" [selectorDataCss]="selectorDataCss"
      [addMargin]="!last ? addMargin: ''"
      [ngClass]="{ 'mr-5': layout == 'row' && !last, 'graph-type-as-clickable': selectorData.graphType == graphType.TRAFFIC && layout == 'row'}">
    </app-graph-type-selector>
    <mat-divider *ngIf="i < graphTypeSelectorsData.length-1 && !graphTypeSelector.selected && layout !== 'row'"
      class="graph-type-selectors-separator">
    </mat-divider>
  </ng-container>
</ng-template>
