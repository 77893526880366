import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {Chart} from 'chart.js';
import {LoggerService, Logger} from 'src/app/shared/services/logger.service';
import {GraphTrendConfig, GraphTrendThemeConfig} from '../chartjs-components/models/graph-trend-config';
import {KpiService} from '../../services/rest-services/kpi.service';
import {KpiType, KpiTrend} from '../../models/kpi.model';
import {GraphTrendConfigService} from '../chartjs-components/services/graph-trend-config.service';
import {take} from 'rxjs/operators';
import {Breakpoints, BreakpointObserver} from '@angular/cdk/layout';
import moment from 'moment';
import {NgChanges} from "../../extend-angular-classes/on-changes";
import {BaseTrendChartJs} from "../chartjs-components/models/base-trend-chart-js";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-small-graph-trend',
  templateUrl: './small-graph-trend.component.html',
  styleUrls: ['./small-graph-trend.component.scss']
})
export class SmallGraphTrendComponent extends BaseTrendChartJs {

  // TODO: ge the canvas size from input
  kpiType: KpiType = KpiType.Health;
  kpiTrend: KpiTrend;
  // @Input() dataSet;
  // An array of two graph configurations
  trendLineConfig: GraphTrendConfig;
  @Output() selectedBar: EventEmitter<any> = new EventEmitter();
  @Input() graphWidth: number = 800;
  @Input() graphHeight: number = 250;
  @Input() maxTicksX: number = 6;
  @Input() maxTicksY: number = 6;
  @Input() maxRotation: number = 0;
  @Input() hideLegend: boolean = false;

  issuesChart: any;
  private logger: Logger; components; columnDefs;
  private readonly Y_AXES_COLOR = "#D3D8DD";
  /**
   * The number of the steps on y axes
   */
  private readonly Y_STEP_NUM = 4;
  chart: any;
  mediaWidth = {
    [Breakpoints.XLarge]: {width: 180, heigh: 50},
    [Breakpoints.Large]: {width: 150, heigh: 45},
    [Breakpoints.Medium]: {width: 70, heigh: 40},
    [Breakpoints.Small]: {width: 40, heigh: 15},
  }

  constructor(
    protected translate: TranslateService,
    protected loggerFactory: LoggerService,
    private kpiService: KpiService,
    private breakpointObserver: BreakpointObserver,
    private graphTrendConfigService: GraphTrendConfigService) {
    super(translate, loggerFactory)
    this.logger = this.loggerFactory.getLogger("GraphTrendComponent");
  }

  ngOnInit() {
    if (!this.graphWidth || !this.graphHeight) {
      let mediaQueries = Object.keys(this.mediaWidth);
      for (let i = 0; i < mediaQueries.length; i++) {
        if (this.breakpointObserver.isMatched(mediaQueries[i])) {
          let size = this.mediaWidth[mediaQueries[i]];
          console.log("matched query width size %o", size)
          this.graphWidth = this.graphWidth || size.width;
          this.graphHeight = this.graphHeight || size.heigh;
          break;
        }
      }
    }
  }

  getChartTrend() {
    this.kpiService.getCurrentKpiTrend(this.kpiType).pipe(take(1)).subscribe(
      (kpiTrend) => {
        this.kpiTrend = kpiTrend;
        if (kpiTrend) {
          this.setChartConfig(this.kpiTrend)
        }
      })
  }

  /**
   * @method setChartConfig receive the kpi configuration and initiate the trendLineConfig param
   * @param kpiTrend
   */
  setChartConfig(kpiTrend) {
    let data = kpiTrend.map((datum) => {
      return {
        x: moment(datum.datetime || datum.date), y: datum.value
      }
    })
    let config = this.graphTrendConfigService.getKpiConfig(this.kpiType);
    this.trendLineConfig = {
      datasets: [{
        ...config, data
      }]
    }
    if (this.trendLineConfig) {
      this.drawGraph()
    }
  }

  ngOnChanges(changes: NgChanges<SmallGraphTrendComponent>) {
    if (!this.kpiTrend) {
      this.getChartTrend();
    }
  }


  drawGraph() {
    if (this.chart) {
      this.chart.destroy()
    }

    // this.logger.debug(`maxY ${maxY} , ticks options %o`, yTicks)
    let grapData = this.trendLineConfig.datasets.map((config) => this.buildLineConfig(config, this.graphElm, this.graphHeight));
    this.logger.debug("graph data %o", grapData)
    this.logger.debug(`max ticks ${this.maxTicksX} max rotation ${this.maxRotation}`)
    this.chart = new Chart(this.graphElm.nativeElement, {
      // The custom chart type that shows a ruler line if there is a point under cursor
      type: 'line',
      data: {
        datasets: grapData//[illEntitiesDataSet, totalEntitiesDataSet]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
              enabled: false
              // mode: 'x',
              // intersect: false
            }
        },
        // Note that if we will need the graph inside a resizable portlet we will need
        // to set the responsive option to false.
        responsive: true,
        maintainAspectRatio: false,
        // adding hover with 'x' mode shows the points under cursor (same x coordinate)
        // the sensitivity is configured by pointHitRadius
        hover: {
          mode: 'x',
          intersect: false,
        },

        scales: {
          x: {
            type: 'time',
            display: false, // mandatory
            border: {
              display: false
            },
            grid: {
              // hide x axes & grid lines, the display property on xAxes will leave the ticks shown
              display: false,
            },
            ticks: {
              display: false
            },
          },
          y: {
            display: false, // mandatory
            max: 100,
            min: 0,
            border: {
              display: false
            },
            grid: {
              // hide x axes & grid lines, the display property on xAxes will leave the ticks shown
              display: false,
            },
            ticks: {
              display: false,
            }
          }
        },
      }
    });
  }

  ngAfterViewInit() {
    this.logger.debug("ngAfterViewInit called ")
    if (this.trendLineConfig) {
      this.drawGraph()
    }
  }

}
