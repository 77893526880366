import {Injectable} from '@angular/core';
import {BasicUserDetails} from "./IBaseAuthService";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  private notifyUserProfile: BehaviorSubject<BasicUserDetails> = new BehaviorSubject(null);
  notifyUserProfileObservable$ = this.notifyUserProfile.asObservable();

  constructor() {
  }

  saveUserProfile(profile: BasicUserDetails) {
    this.notifyUserProfile.next((profile));
  }

}
