<div class="row pl-3 pb-3">
  <div class="col col-lg-2 prop-element-icon-container" *ngIf="!headerData?.firstColumn?.errorMessage">
    <app-icon-with-ellipse [iconPath]="headerData?.iconPath" [secondaryIconPath]="headerData?.secondaryIconPath" *ngIf="!headerData?.type || !headerData?.noDataMessage">
    </app-icon-with-ellipse>
    <div *ngIf="headerData?.type == deviceType.WWW">
      <i class="prop-svg-root-icon" [inlineSVG]="headerData?.iconPath"></i>
    </div>
  </div>

  <ng-container *ngIf="!headerData?.firstColumn?.errorMessage && !headerData?.noDataMessage">
    <div class="col col-md-4 prop-first-column-container" [ngClass]="widthForLinkData">
      <div class="row">
        <div class="col pt-3 prop-first-column-main" title="{{headerData?.firstColumn.main}}">
          {{headerData?.firstColumn.main}}
        </div>
      </div>
      <div class="row">
        <span class="col prop-first-column-sub" title="{{headerData?.firstColumn.sub}}" appIsValueInComplete>
          {{headerData?.firstColumn.sub}}
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.noDataMessage">
    <div class="col pt-3 prop-no-data-message">
      {{headerData?.noDataMessage}}
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.firstColumn?.errorMessage">
    <div class="col pt-3 ">
      <p class="row prop-error-message">
        {{headerData?.firstColumn.errorMessage.errorLabel}}
      </p>
      <p class="row prop-error-message">
        {{headerData?.firstColumn.errorMessage.errorContent}}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.elementType === elementType?.LINK">
    <div class="col col-sm-2 link-warning-icon-container">
      <mat-icon class="link-warning-icon severity-icon" mat-list-icon [svgIcon]="severityIconPath">
      </mat-icon>
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.elementType !== elementType?.LINK">
    <app-sub-header-border [cssClass]="'pr-0'">
    </app-sub-header-border>
  </ng-container>


  <ng-container *ngIf="headerData?.health >=0">
    <div class="col col-md-3 health-colomn-container">
      <div class="col prop-health-value-container">
        <div class="row">
          <div *ngIf="headerData" class="col">
            <div class="pt-3 prop-value" [ngStyle]="{color: healthColor}">
              {{headerData?.health.toFixed(0)}}%
            </div>
          </div>
        </div>
        <div class="row health-polygon-container" (click)="openHealthIssuesDialog('health')">
          <span class="col prop-key prop-key-1">{{'data.COMMON.HEALTH' | translate}}
          </span>
          <i class="col prop-polygon-1" [inlineSVG]="'/assets/media/netop/topology/click-with-arrow.svg'"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.health ==-1">
    <div class="col col-md-3 health-colomn-container">
      <div class="col prop-undefined-health-value-container">
        <div class="row">
          <span class="prop-health-undefined-error">{{'data.COMMON.HEALTH_NOT_CALCULATED' | translate}}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="headerData?.issues >= 0">
    <div class="col col-md-3 actions-colomn-container">
      <div class="col prop-health-value-container">
        <div class="row">
          <div *ngIf="headerData" class="col">
            <div class="pt-3 prop-value">
              {{headerData.issues}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col prop-actions-container" (click)="openHealthIssuesDialog('issues')">
            <div class="col prop-key prop-key-2">{{'data.DASHBOARD.ACTIONS' | translate}}</div>
            <i class="col prop-polygon-2"
              [inlineSVG]="'/assets/media/netop/topology/click-with-arrow.svg'"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
