import {Injectable} from '@angular/core';
import {HoriznotalTimeSpan, HorizontalTimelineMarker} from '../models/horizontal-time-span.model';
import {calculateTotalHoursBetweenTwoDates} from 'src/app/shared/operators/time-operator';

@Injectable({
  providedIn: 'root'
})
export class HorizontalTimelineService {

  constructor() {
  }

  /**
   * Calculate the time span width and add tooltip data
   * 1. The width is being calculate in relation to the parent width.
   * The calculation get the percentage from the hours differences, and apply it on the width
   * 2. Insert marker data into one of the span
   * The method checks if the marker date (i.e., action update at field) is
   * in between one of the markers
   */
  addSpansDisplayData(timeSpans: HoriznotalTimeSpan[], width: number, marker: HorizontalTimelineMarker): HoriznotalTimeSpan[] {
    const totalTimelineHours: number = this.getTrendTotalTimeSpan(timeSpans);
    const filteredTimeSpans: HoriznotalTimeSpan[] = this.getFilteredItems(timeSpans, totalTimelineHours);
    if (filteredTimeSpans && filteredTimeSpans.length > 0) {
      filteredTimeSpans.forEach((span, index) => {
        let filteredTotalTimeSpan = this.getTrendTotalTimeSpan(filteredTimeSpans);
        let totalSpanHours = calculateTotalHoursBetweenTwoDates(span.start, span.end);
        let finalWidth = width - filteredTimeSpans.length;
        span.width = finalWidth * (((totalSpanHours / filteredTotalTimeSpan) * 100) / 100);
        if (span.width > 1) {
          span.width = span.width - 1;
        }
        if (marker && span.start < marker.date && span.end > marker.date) {
          span.marker = marker;
          span.marker.markerWidthPercentage = this.findMarkerWidthPercentage(span, marker);
        }
      })
    }
    return filteredTimeSpans;
  }

  /**
   * Calculate the total time span of the filtered spans.
   */
  getTrendTotalTimeSpan(timeSpans: HoriznotalTimeSpan[]) {
    if (timeSpans.length > 2) {
      const endDate: Date = timeSpans[timeSpans.length - 1].end ? timeSpans[timeSpans.length - 1].end : timeSpans[timeSpans.length - 2].end;
      return calculateTotalHoursBetweenTwoDates(timeSpans[0].start, endDate);
    }
    if (timeSpans.length == 2) {
      return calculateTotalHoursBetweenTwoDates(timeSpans[0].start, timeSpans[1].end)
    }
    if (timeSpans.length == 1) {
      return calculateTotalHoursBetweenTwoDates(timeSpans[0].start, timeSpans[0].end)
    }
  }

  /**
   * Filter total time span array.
   * Allow only above 1% items or the last item in the array
   * @param timeSpans The original time span array
   * @param totalTimelineHours Total original time span hours
   */
  getFilteredItems(timeSpans: HoriznotalTimeSpan[], totalTimelineHours: number): HoriznotalTimeSpan[] {
    return timeSpans.filter((span, index) => {
      let totalSpanHours = calculateTotalHoursBetweenTwoDates(span.start, span.end);
      return index == timeSpans.length - 1 || this.isAboveThreshold(totalSpanHours, totalTimelineHours);
    });
  }

  /**
   * Check if the span is at least 1 percentage from the entire time span range
   */
  isAboveThreshold(totalSpanHours: number, totalTimelineHours: number) {
    if (isNaN(totalTimelineHours))
      return true;
    return (totalSpanHours / totalTimelineHours) * 100 > 0.1;
  }

  /**
   * Calculate the marker width in percentage, in relation to the span that wrap it
   */
  findMarkerWidthPercentage(span: HoriznotalTimeSpan, marker: HorizontalTimelineMarker): number {
    let totalHours = calculateTotalHoursBetweenTwoDates(span.start, span.end);
    let totalSpanHours = calculateTotalHoursBetweenTwoDates(span.start, marker.date);
    return (totalSpanHours / totalHours) * 100;
  }

  /**
   * Generette tooltip data for the marker
   */
  generateMarkerTooltipData(timeSpans: HoriznotalTimeSpan[]): string {
    if (timeSpans && timeSpans.length > 0) {
      let markerSpan = timeSpans.find(span => {
        if (span.marker)
          return true;
      });
      if (markerSpan !== undefined) {
        return markerSpan.marker.markerTooltipData;
      }
    }
    return null;
  }
}
