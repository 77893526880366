<div class="menu-cell-renderer">
  <div *ngIf="!isEmpty" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="menu-cell-icon-container">
    <mat-icon>more_vert</mat-icon>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of options">
    <button *ngIf="visible(option)" mat-menu-item (click)="option.onClick(data)">{{option.name}}</button>
  </ng-container>
</mat-menu>
