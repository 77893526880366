import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

export type FromToDisplay = {
  from: number;
  to: number;
  fromBackgroundColor: string;
  toBackgroundColor: string;
  textColor: string;
}

@Component({
  selector: 'app-from-to-display',
  templateUrl: './from-to-display.component.html',
  styleUrls: ['./from-to-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FromToDisplayComponent implements OnInit {
  @Input() fromToDisplay: FromToDisplay;

  constructor() {
  }

  ngOnInit() {
  }

}
