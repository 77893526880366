import { Injectable } from '@angular/core';
import { Auth0Service } from '../../auth0.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Logger, LoggerService } from '../../logger.service';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  private http: HttpClient;
  private readonly functionsBaseUrl =
    `https://${environment.firebase.region}-${environment.firebase.projectId}.cloudfunctions.net`;
  private readonly localFunctionsBaseUrl =
    `http://localhost:5000/${environment.firebase.projectId}/${environment.firebase.region}`;
  token: firebase.auth.UserCredential;
  private logger: Logger;
  userProfileSubscr: Subscription;

  private notifyUserDetails: BehaviorSubject<string> = new BehaviorSubject(null);
  notifyUserDetailsObservable$ = this.notifyUserDetails.asObservable()
  currentUserEmail: string;


  constructor(public afAuth: AngularFireAuth,
    handler: HttpBackend,
    private authService: Auth0Service,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("FirebaseAuthService");
    // Note that we are injecting and using HttpBackend for creating http client
    // which will skip interceptors.
    this.http = new HttpClient(handler);
    this.userProfileSubscr = this.authService.profile$.subscribe((profile) => {
      if (profile && profile.email) {
        this.currentUserEmail = profile.email;
        this.notifyUserDetails.next(this.currentUserEmail);
      }
      if (profile && environment.useServerless) {
        // TODO: this should be probably done on first firebase request
        this.authService.getToken().then((token) => {
          this.logger.debug("authenticating with firebase using token : %o profile %o", token, profile)
          this.loginToFirebase(token)
        }, (reason) => {
          this.logger.error(`Couldn't authenticate against firebase for a reason ${reason}`, reason)
        })
      }
    })
  }

  loginToFirebase(authToken: string) {
    // First call a function which will get us a firebase jwt token for auth0 one.
    const baseUrl = environment.firebase.localInvocation ? this.localFunctionsBaseUrl : this.functionsBaseUrl;
    const functionUrl = baseUrl + '/http/token'
    this.http.get(functionUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).pipe(take(1)).subscribe((firebaseToken: any) => {
      console.log("got firebase token : %o", firebaseToken);
      // Now call sign in firebase with firebase jwt token.
      this.afAuth.signInWithCustomToken(firebaseToken.firebaseToken).then(
        (firebaseToken) => {
          // this.notifyUserDetails.next(this.currentUserEmail);
          this.token = firebaseToken;
        }
      ).catch(reason => console.error("Couldn't log user to firebase %o", reason))
    })
  }

  destroy() {
    this.userProfileSubscr.unsubscribe()
  }
}
