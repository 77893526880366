import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Entity } from 'src/app/shared/models/entity.model';
import { EntityType } from 'src/app/shared/models/entity-type.enum';

@Component({
  selector: 'app-deviation-reports-dialog-main',
  templateUrl: './deviation-reports-dialog-main.component.html',
  styleUrls: ['./deviation-reports-dialog-main.component.scss']
})
export class DeviationReportsDialogMainComponent implements OnInit {
  @Input() preSelectedEntity: Entity;
  @Input() currentTenant: Entity;
  @Input() selectedDate: string;
  @Output() selectedEntity: EventEmitter<Entity> = new EventEmitter();
  currentEntity: Entity;
  EntityType = EntityType;

  constructor() { }

  ngOnInit() {
    this.currentEntity = { ...this.preSelectedEntity };
  }
  backToTenantView() {
    this.onEntitySelected(this.preSelectedEntity);
  }

  onEntitySelected(entity: Entity) {
    this.currentEntity = entity;
    this.selectedEntity.emit(entity)
  }

  get isBackToTenant(): boolean {
    return this.preSelectedEntity &&
      this.preSelectedEntity.type == EntityType.TENANT &&
      this.currentEntity &&
      this.currentEntity.type === EntityType.VENUE;
  }

}
