import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CronExp, SchedulingState } from '../../models/journal.model';
import { HttpClient } from '@angular/common/http';
import { GlobalEntitiesService } from './global-entities.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { ActivatedRoute } from '@angular/router';
import { concatMap, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScheduleBackupService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }
  changeBackupState(state: boolean): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      concatMap((tenantId) =>
        this.http.put(`tenants/${tenantId}/scheduleBackup/${state}`, {})
      ));
  }

  getScheduleExpression(): Observable<CronExp> {
    return this.tenantId$.pipe(
      take(1),
      switchMap((tenantId) =>
        this.http.get<CronExp>(`tenants/${tenantId}/scheduleBackup/`)
      )
    )
  }
  schedulingState(): Observable<SchedulingState> {
    return this.tenantId$.pipe(
      take(1),
      switchMap((tenantId) =>
        this.http.get<SchedulingState>(`tenants/${tenantId}/schedulingState`)
      )
    )
  }

  postScheduleExpression(expression: string): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      concatMap((tenantId) =>
        this.http.post(`tenants/${tenantId}/scheduleBackup/`, expression.toString())
      )
    )
  }
}
