import {StackedBarThemeConfig} from '../models/stacked-bar.config';
import {StackedBarComponent} from '../stacked-bar/stacked-bar.component';
import {ActiveElement, ChartEvent} from "chart.js";

/**
 * Handle click horizontal stacked bar click event.
 * The method find the clicked dataset and change is properties to it will be marked as clicked
 * The method also emit the selected dataset do it the selection will be displayed on the graph chart to
 * @param event The current click event
 * @param elements The current clicked elements (i.e, the entire bar)
 * @param chart The Chart object
 * @param component The this object of the stacked bar component
 */
export function onClickPlugin(event: ChartEvent, elements: ActiveElement[], chart: any, component: StackedBarComponent) {
  if (elements[0]) {
    let currentDatasetIndex = elements[0].datasetIndex;
    let currentDataset = chart.config.data.datasets[currentDatasetIndex] as StackedBarThemeConfig;

    chart.config.data.datasets.forEach((dataset: StackedBarThemeConfig, index) => {
      if (index !== currentDatasetIndex || dataset.isSelected) {
        dataset.isSelected = false;
        dataset.backgroundColor = dataset.hoverColor;
        dataset.borderColor = "#ffffff";
        dataset.borderSkipped = 'left';
      } else {
        dataset.isSelected = true;
        dataset.backgroundColor = dataset.defaultColor;
        dataset.borderColor = dataset.defaultColor;
        dataset.borderSkipped = null;
      }
    });
    currentDataset.isSelected ?
      component.clickedDataset.emit(currentDataset) :
      component.clickedDataset.emit(null);
    chart.update();
  }
}
