import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BaseHealthGrid} from "../../../entity-dashboard/health-grid/base-health-grid";
import {GridTypes} from "../../../../models/client-storage.model";

@Component({
  selector: 'app-fabrics-health-grid',
  templateUrl: './fabrics-health-grid.component.html',
  styleUrls: ['./fabrics-health-grid.component.scss']
})
export class FabricsHealthGridComponent extends BaseHealthGrid {
  constructor(protected translate: TranslateService, protected cdr: ChangeDetectorRef) {
    super(translate, GridTypes.FABRICS_HEALTH, translate.instant('data.DASHBOARD.FABRICS_HEALTH'), translate.instant('data.DASHBOARD.TOTAL_FABRICS'), cdr);
  }
}
