import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, ViewEncapsulation } from '@angular/core';
import { PortletSizeState } from '../portlet-size-state.enum'

@Component({
  selector: 'app-portlet-header',
  templateUrl: './portlet-header.component.html',
  styleUrls: ['./portlet-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PortletHeaderComponent implements OnInit {
  PortletSizeState = PortletSizeState;
  @Input() toolbarClass: string;
  @Input() titleClass: string;
  //toolbarClass
  // Public properties
  // append html class to the portlet header
  @Input() class: string;
  /**
   * The title can be provided either as a projected content (portletTitle) or as a title text 
   * a simple title text
   */
  @Input() title: string;
  /**  
   * The icon can be provided either as a projected content (portletIcon) or as a icon class (bootstrap one) 
   * icon name to be added to the i tag
   */
  @Input() icon: string;
  /**  remove title container entirly */
  @Input() noTitle: boolean;
  /** Is the portlet minimized */
  @Input() minimized: boolean;
  /**  Is the portlet of normal size */
  @Input() normalSize: boolean;
  @Input() hideDefaultTools: boolean = false;
  portletSizeState: PortletSizeState = PortletSizeState.Normal;


  @HostBinding('class') classes: string = 'kt-portlet__head';

  @ViewChild('refIcon', { static: true }) refIcon: ElementRef;
  hideIcon: boolean;

  @ViewChild('refTools', { static: true }) refTools: ElementRef;
  hideTools: boolean;

  /**
   * Fired when a maximize button was clicked
   */
  maximizeEvent: EventEmitter<any> = new EventEmitter();
  /**
   * Fire when a restoreSize button was clicked
   */
  restoreSizeEvent: EventEmitter<any> = new EventEmitter();
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }
  ngAfterContentInit(): void {
    // append custom class
    this.classes += this.class ? ' ' + this.class : '';

    // hide icon's parent node if no icon provided
    this.hideIcon = this.refIcon.nativeElement.children.length === 0;

    // hide tools' parent node if no tools template is provided
    this.hideTools = this.refTools.nativeElement.children.length === 0;
    this.cdr.detectChanges();

  }
  setPortletSizeStateToolBar(portletSizeState: PortletSizeState) {
    this.portletSizeState = portletSizeState;
  }
  maximize() {
    this.maximizeEvent.emit();
  }
  restoreSize() {
    this.restoreSizeEvent.emit();
  }

}
