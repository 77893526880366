import {Injectable} from '@angular/core';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {DataGranularity} from 'src/app/shared/models/actions.model';
import {FromToDates, FromToDatesStrings, TimePeriod, TimePeriodWithDates} from "../../../../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class InsightsAnomaliesTimeGeneratorService {

  constructor(private dateConvertor: TimeManagerService) {
  }

  timeConvertor(timeWithDates: TimePeriodWithDates): FromToDatesStrings {
    let formattedDates: FromToDates;
    switch (timeWithDates.timePeriod) {
      case TimePeriod.LAST_24_HOURS:
        formattedDates = this.formatLast24(timeWithDates);
        break;
      case TimePeriod.LAST_WEEK:
        formattedDates = this.formatRangeDates(timeWithDates);
        break;
      case TimePeriod.CUSTOM_DATE:
        formattedDates = this.formatCustomDates(timeWithDates);
        break;
      default:
        break;
    }
    return {
      start: this.dateConvertor.convertJSIsoToSpring(formattedDates.start),
      end: this.dateConvertor.convertJSIsoToSpring(formattedDates.end),
    }
  }

  formatCustomDates(timeWithDates: TimePeriodWithDates): FromToDates {
    let isoStartDate = this.dateConvertor.getMidnightOfDate(timeWithDates.dates.start);
    let isoEndDate = this.dateConvertor.getLastSecondOfdate(timeWithDates.dates.end);
    return {start: isoStartDate, end: isoEndDate};
  }

  formatRangeDates(timeWithDates: TimePeriodWithDates): FromToDates {
    let isoStartDate = this.dateConvertor.getMidnightOfDate(timeWithDates.dates.start);
    let isoEndDate = timeWithDates.dates.end;
    return {start: isoStartDate, end: isoEndDate};
  }

  formatLast24(timeWithDates: TimePeriodWithDates): FromToDates {
    let isoStartDate = this.dateConvertor.getDateWithRoundHour(timeWithDates.dates.start);
    let isoEndDate = timeWithDates.dates.end;
    return {start: isoStartDate, end: isoEndDate};
  }

  /**
   * Return dates range by granularity
   *
   */
  generateRangeByGranularity(unix: number, dataGranularity: DataGranularity): FromToDatesStrings {
    let isoStartDate: Date;
    let isoEndDate: Date;
    switch (dataGranularity) {
      case DataGranularity.Daily:
        isoStartDate = this.dateConvertor.getMidnightOfDate(new Date(unix));
        isoEndDate = this.dateConvertor.getLastSecondOfdate(new Date(unix));
        break;
      case DataGranularity.HourlySix:
        isoStartDate = new Date(unix);
        isoEndDate = this.dateConvertor.getHoursAfterCurrentDate(new Date(unix), 6);
        break;
      case DataGranularity.HourlyTwelve:
        isoStartDate = new Date(unix);
        isoEndDate = this.dateConvertor.getHoursAfterCurrentDate(new Date(unix), 12);
        break;
      case DataGranularity.Hourly:
        isoStartDate = new Date(unix);
        isoEndDate = this.dateConvertor.getHoursAfterCurrentDate(new Date(unix), 1);
        break;
      default:
        break;
    }
    return {
      start: this.dateConvertor.convertJSIsoToSpring(isoStartDate),
      end: this.dateConvertor.convertJSIsoToSpring(isoEndDate),
    }
  }

  /**
   * Return the displayed date for Anomalies grid, by Granularity
   */
  getDisplateDate(start: string, dataGranularity: DataGranularity): string {
    switch (dataGranularity) {
      case DataGranularity.Daily:
        return `Current Date: ${this.dateConvertor.dateByFormat(start, 'DD-MMM')}`;
      case DataGranularity.Hourly:
      case DataGranularity.HourlySix:
      case DataGranularity.HourlyTwelve: {
        return `Current Date: ${this.dateConvertor.dateByFormat(start, 'HH:mm')}`
      }
      default:
        break;
    }
  }


}
