import {Chart, TooltipLabelStyle, TooltipModel} from 'chart.js';
import {ChartJsTooltip} from './chartjs-tooltip.model';
import {GraphTrendComponent} from "../graph-trend/graph-trend.component";

export class ChartTooltipExtensions {
  /**
   * Add arrow class to the tooltip in order to add pseudo element to the tooltip box
   */
  protected tooltipElArrowClass = 'with-arrow';
  /**
   * Set the tooltip default direction
   */
  protected tooltipDefaultXDirection: ("right" | "left" | "center") = 'right';
  protected tooltipDefaultYDirection: ("above" | "under") = 'under';
  /**
   * The tooltip actual direction. Set to default direction, and changes by the hover events
   */
  protected tooltipActualDirection: ("right" | "left" | "center" | "above" | "under");
  private readonly TOOLTIP_LINE_COLOR = "#5d6267";
  protected readonly X_ARROW_WIDTH = 25; // Arrow width  + some space for good L&F
  protected readonly Y_ARROW_WIDTH = 17; // Arrow width  + some space for good L&F
  protected defaultTooltipId = 'chartjs-tooltip';
  protected tooltipClasss: string;
  chartObj: Chart<any>;
  minWidth: number;


  constructor() {
  }

  setCustomTooltipStyling = (tooltip: TooltipModel<any>, chart: Chart<any>, minWidth: number = 250): HTMLElement => {
    // Tooltip Element
    let tooltipDefaultId = this.defaultTooltipId;
    //The query has to be to the specific element in case of multi charts
    let tooltipEl: HTMLDivElement = chart.canvas.parentNode.querySelector("#" + tooltipDefaultId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = tooltipDefaultId;
      tooltipEl.innerHTML = '<table></table>';
      tooltipEl.classList.add('chart-tooltip');
      tooltipEl.style.minWidth = `${String(minWidth)}px`;
      this.tooltipClasss ? tooltipEl.classList.add(this.tooltipClasss) : null;
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0 as any;
      return;
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltip.body) {
      /** Enter empty string if tooltip has no title */
      const titleLines = tooltip.title[0] !== "undefined" ? tooltip.title : [""];
      const bodyLines = tooltip.body.map(getBody);
      const footerLine = tooltip.footer[0] !== "undefined" ? tooltip.footer : [""];
      let innerHtml = '<thead>';
      titleLines.forEach(function (title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';
      bodyLines.forEach(function (body, i) {
        if (body.length > 0) {
          let label: string = body;
          let subContent: string[];
          let subContentLabel: string[];
          /**
           * @ special charachter add the ability to add multi line template Dynamiclly
           * Currently, 13.1.21, only usage is in InsightsAnomaliesBarGeneratorService
           */
          if ((body[0] as string).includes('@')) {
            let formmatedBody: string[] = body[0].split('@');
            label = formmatedBody[0];
            formmatedBody.forEach((content, index) => {
              if (index > 0) {
                subContentLabel ? subContentLabel.push(content.split(':')[0]) : subContentLabel = [content.split(':')[0]];
                subContent ? subContent.push(content.split(':')[1]) : subContent = [content.split(':')[1]];
              }

            });
          }
          const colors = tooltip.labelColors[i];
          let style = 'background:' + colors.backgroundColor;
          style += '; border-color:' + colors.backgroundColor;
          style += '; border-width: 2px';
          const squareSpan = '<span class="chartjs-tooltip-key" style="' +
            style +
            '"></span>';
          /**
           * This next line sould be read only if Special charachter was used
           */
          if (subContentLabel && subContentLabel.length > 0 && subContent && subContent.length > 0) {
            innerHtml += `<tr><td> ${squareSpan} <strong>${label}:</strong> </td></tr>`;
            subContentLabel.forEach((conteneLabel, index) => {
              innerHtml += `<tr><td> <span><strong>${conteneLabel}</strong></span>
                </td><td><span style="opacity: .8">${subContent[index]}</span> </td></tr>`;

            })
          } else {
            innerHtml += '<tr><td>' + squareSpan + label + '</td></tr>';
          }
        }
      });
      footerLine.forEach(line => {
        innerHtml += '<tr><th>' + line + '</th></tr>';
      })
      innerHtml += '</tbody>';
      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1 as any;
    const {offsetLeft: positionX, offsetHeight: positionY} = chart.canvas;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY - (positionY - tooltip.caretY / 2.5) + 'px';
    tooltipEl.style.padding = tooltip.options.padding +
      'px ' +
      tooltip.options.padding +
      'px';

    return tooltipEl;
  }

  /**
   * Open default tooltip pointer, if givven. Uses the animation => onComplete, chart js life cycle.
   * @param index The index of the dataset that will be marked by the tooltip (selected by date)
   * @param currentObj The "this" object
   */
  openDefaultTooltip(index: number, currentObj: GraphTrendComponent) {
    if (currentObj && currentObj.chartConfig.datasets.length > 0) {
      setTimeout(() => {
        let mouseMoveEvent, meta, point, rectangle;
        meta = currentObj.chart.getDatasetMeta(0);
        rectangle = currentObj.chart.canvas.getBoundingClientRect();
        if (meta.data[index]) {
          point = meta.data[index].getCenterPoint();
          mouseMoveEvent = new MouseEvent('mousemove', {
            clientX: rectangle.left + point.x,
            clientY: rectangle.top + point.y
          });
          mouseMoveEvent['isArtificial'] = true;
          currentObj.chart.canvas.dispatchEvent(mouseMoveEvent);
        }
      }, 150);
    }
  }

  /**
   * Open default tooltip pointer, if givven. Uses the animation => onComplete, chart js life cycle.
   * @param index The index of the dataset that will be marked by the tooltip (selected by date)
   * @param currentObj The "this" object
   */
  public removeDefaultTooltip(currentObj: GraphTrendComponent) {
    let tooltipDefaultId = this.defaultTooltipId;
    let tooltipEl = currentObj.chart.canvas.parentNode.querySelector("#" + tooltipDefaultId);
    if (tooltipEl)
      tooltipEl.remove();
  }

  /**
   * Set the background color for the chart labels
   * @param tooltipItem The curren Tooltip Object
   * @param chart The Chart object
   */
  public fillLabelColors(context, colorProperty: string): TooltipLabelStyle {
    const color = context.dataset[colorProperty];
    return {
      backgroundColor: color,
      borderColor: color
    };
  }

  protected setChartCustomTooltipPosition(tooltipEl: HTMLElement, tooltip: ChartJsTooltip) {
    if (tooltipEl) {
      //Remove classed that was added at setCustomTooltipStyling method
      tooltipEl.classList.remove(this.tooltipElArrowClass + "-" + this.tooltipActualDirection);
      //Set the tooltip x and y position
      const positionCorrectX = this.setTooltipXPosition(tooltip, tooltipEl);
      tooltipEl.style.left = positionCorrectX + tooltip.caretX + 'px';
      // tooltipEl.style.top = `${this.setTooltipYposition(tooltip)}px`;
      tooltipEl.classList.add(this.tooltipElArrowClass + "-" + this.tooltipActualDirection);
    }
  }

  /**
   * Returning the value that will be added to the current tooltip caretX position, in order to set the style.left value
   * Could return 3 options:
   * 1. Right to the hover event
   * 2. Left to the hover event
   * 3. Center to the hover event (when returning 0)
   * @param chartObj The current Chart object
   * @param tooltip The current tooltip object
   */
  protected setTooltipXPosition(tooltip: any, tooltipEl: any): number {
    if (this.tooltipDefaultXDirection == 'left')
      return this.setTooltipToTheLeft(tooltip, tooltipEl, this.X_ARROW_WIDTH);
    if (this.tooltipDefaultXDirection == 'right')
      return this.setTooltipToTheRight(tooltip, tooltipEl, this.X_ARROW_WIDTH);
    if (this.tooltipDefaultXDirection == 'center')
      return 0;
  }

  /**
   * For Justifying the tooltip to the right, the method is calculting the center of the tooltip and its padding,
   * and subtract it from the tooltip.caretX
   * If Calculation suggests that the tooltip will be off scale, it changes the calculation for possitive values
   * @param chartObj The current Chart object
   * @param tooltip The current tooltip object
   */
  protected setTooltipToTheLeft(tooltip: any, tooltipEl: any, arrowWidth: number): number {
    this.tooltipActualDirection = 'left';
    let tooltipWidth = tooltipEl.offsetWidth + arrowWidth
    let positionCorrectX = -tooltipWidth / 2.5 - tooltip.xPadding;
    let tooltipRealXposition = tooltip.caretX - this.chartObj.scales["y-axis-0"].right;
    if (tooltipRealXposition < tooltipWidth) {
      positionCorrectX = tooltipWidth / 2.5 + tooltip.xPadding;
      this.tooltipActualDirection = 'right';
    }
    return positionCorrectX;
  }

  /**
   * Return the fix for setting the tooltip on its right position
   * If the default x Directiopn is center (and not right or left),
   * than, and only than, the tooltip can be placed under or above the hover event.
   * If not, the tooltip will be "align-center" with the hover event.
   * @param chartObj The current Chart object
   * @param tooltip The current tooltip object
   */
  protected setTooltipYposition(tooltip: any) {
    if (this.tooltipDefaultXDirection == "center") {
      this.tooltipActualDirection = this.tooltipDefaultYDirection;
      if (this.tooltipDefaultYDirection == "under")
        return this.setTooltipUnderPoint(tooltip);
      if (this.tooltipDefaultYDirection == "above")
        return this.setTooltipAbovePoint(tooltip);
    }
    return this.setTooltipCenterPoint(tooltip);
  }

  /**
   * For Justifying the tooltip to the left, the method is calculting the center of the tooltip and its padding,
   * and add it from the tooltip.caretX
   * If Calculation suggests that the tooltip will be off scale, it changes the calculation for negative values
   * @param chartObj The current Chart object
   * @param tooltip The current tooltip object
   */
  protected setTooltipToTheRight(tooltip: TooltipModel<any>, tooltipEl: any, arrowWidth: number): number {
    this.tooltipActualDirection = 'right';
    const tooltipWidth = tooltipEl.offsetWidth + arrowWidth;
    let positionCorrectX = tooltipWidth / 1.5 + +tooltip.options.padding;
    if (this.chartObj.width - tooltip.caretX < tooltipWidth) {
      positionCorrectX = -tooltipWidth / 1.5 - +tooltip.options.padding;
      this.tooltipActualDirection = 'left';
    }
    return positionCorrectX;
  }


  protected setTooltipUnderPoint(tooltip: any) {
    let positionCorrectY = this.Y_ARROW_WIDTH;
    /**
     * The tooltip realistic caretY value.
     * The tooltip caretY is based on the absulote chart position. i.e., including the xAxis height
     * In order to calculate The tooltip realistic caretY value, we need to sutstruct this value (i.e., this.chart.scales["x-axis-0"].top)
     */
    let tooltipRealYposition = this.chartObj.scales["x-axis-0"].top - tooltip.caretY;
    if (tooltipRealYposition < tooltip.height) {
      this.tooltipActualDirection = "above";
      //Calculation: Negative value because the correction is done with the 'caretY' tooltip value,
      //which is scaled from the top down. In order to set the tooltip higher than its original heigh,
      //We need to subtract from its original caretY.
      //We subtract the tooltip heigh + its padding and the border pixels (as its sets on the chart-tooltip.scss)
      positionCorrectY = -tooltip.height - tooltip.yPadding * 2 - 4;
    }

    return positionCorrectY;
  }

  protected setTooltipAbovePoint(tooltip: TooltipModel<any>) {
    let positionCorrectY = -tooltip.height - +tooltip.options.padding * 2 - this.Y_ARROW_WIDTH;
    if (tooltip.caretY < tooltip.height) {
      this.tooltipActualDirection = "under";
      positionCorrectY = this.Y_ARROW_WIDTH;
    }
    return positionCorrectY;
  }

  protected setTooltipCenterPoint(tooltip: TooltipModel<any>) {
    const IN_THE_MIDDLE_OF_Y = this.chartObj.scales.x.top / 2 - tooltip.height * 2;
    return IN_THE_MIDDLE_OF_Y;
    let positionCorrectY = -tooltip.height / 2 - +tooltip.options.padding - 4;
    /**
     * The tooltip realistic caretY value.
     * The tooltip caretY is based on the absulote chart position. i.e., including the xAxis height
     * In order to calculate The tooltip realistic caretY value, we need to sutstruct this value (i.e., this.chart.scales["x-axis-0"].top)
     */
    if (this.chartObj.scales["x"]) {
      let tooltipRealYposition = this.chartObj.scales["x"].top - tooltip.caretY;
      let tooltipOfficialYposition = this.chartObj.scales["x"].top - tooltip.y;
      // //Calculation: Negative value because the correction is done with the 'caretY' tooltip value,
      // //which is scaled from the top down. In order to set the tooltip higher than its original heigh,
      // //We need to subtract from its original caretY.
      // //We subtract the tooltip height + its padding and the border pixels (as its sets on the chart-tooltip.scss)

      if (tooltipRealYposition - tooltipOfficialYposition < tooltip.height) {
        positionCorrectY = -tooltip.height - +tooltip.options.padding * 2 - this.Y_ARROW_WIDTH - (tooltip.caretY - tooltip.y);
        positionCorrectY = this.chartObj.scales["x-axis-0"].top / 2 - tooltip.height * 2;
      }
    }

    return positionCorrectY;
  }
}
