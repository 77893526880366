import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {StackedBarConfig} from "../../models/stacked-bar.config";

@Component({
  selector: 'app-stacked-bars-row',
  templateUrl: './stacked-bars-row.component.html',
  styleUrls: ['./stacked-bars-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedBarsRowComponent implements OnInit {
  @Input() stackedBarConfig: StackedBarConfig[];
  @Input() height: number;
  @Input() width: number;

  constructor() {
  }

  ngOnInit() {
  }

}
