import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-grid-rows',
  templateUrl: './total-grid-rows.component.html',
  styleUrls: ['./total-grid-rows.component.scss']
})
export class TotalGridRowsComponent implements OnInit {
  public total: number;
  constructor() { }

  ngOnInit() {
  }

}
