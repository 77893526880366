export class ForceDirectedGraphNode<T> {
    // optional - defining optional implementation properties - required for relevant typing assistance
    index?: number;
    x?: number;
    y?: number;
    vx?: number;
    vy?: number;
    fx?: number | null;
    fy?: number | null;
    isSelectedElement: boolean = false;
    isOnlyNode: boolean = false;
    id: number;
    label: string;
    name: string;
    type: any;
    data: T;


    constructor(id: number, name: string, type: string, label: string, data: T) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.label = label;
        this.data = data;
    }


    get r(): number {
        return this.isOnlyNode ? 50 : 10;
    }

    get fontSize(): number {
        return this.r / 4;
    }

    get color(): string {
        return '#2ED47A';
    }

    get stroke(): string {
        return;
        // return this.isSelectedElement ?
        //     '#6bb0fd' : '';
    }
  get topRightIconPath() {
      return null;
  }
}
