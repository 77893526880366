import { Injectable } from '@angular/core';
import { HealthChangeStatus } from '../../models/health.model';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme.service';
import { LegacySeverity } from '../../models/severity.model';
import { KpiType, KpiValue, KpiValueChange } from '../../models/kpi.model';
import { GraphTypeSelectorData, GraphTypeSelectorTotalData } from '../graph-type-selector/graph-type-selector-data.model';
import { GraphType } from '../graph-type-selector/graph-type.enum';
import { KpiDataService } from '../../services/strategies/kpi-data.service';

@Injectable({
    providedIn: 'root'
})
export class GraphTypeSelectorsConfigService {
    severityTheme: { css: {}; icons: {}; colors: {}; };

    constructor(private themeService: ThemeService, private translate: TranslateService,
        private kpiDataService: KpiDataService) {
        this.severityTheme = this.themeService.getSeverityTheme()
    }
    getTenantActionsConfig(data: {
        [graphType: string]: HealthChangeStatus | KpiValueChange
    }) {
        return this._getTenantConfig(data);
    }
    getTenantHealthConfig(data: {
        [graphType: string]: HealthChangeStatus | KpiValueChange
    }) {
        return this._getTenantConfig(data);
    }
    getOrgActionsConfig(data: {
        [graphType: string]: HealthChangeStatus
    }): GraphTypeSelectorData[] {
        return this._getOrgConfig(data);
    }
    getOrgHealthConfig(data: {
        [graphType: string]: HealthChangeStatus
    }) {
        return this._getOrgConfig(data);
    }
    private _getTenantConfig(data: {
        [graphType: string]: HealthChangeStatus | KpiValueChange
    }): GraphTypeSelectorData[] {
        let trafficTotalAndUnits: { finalValue: number, countOfDevition: number } = this.kpiDataService.calculateTrafficValue((data[GraphType.TRAFFIC] as KpiValueChange).value, 0);
        return [this.builSelectorConfig("data.DASHBOARD.VENUES",
            "venues-graph-selector.svg",
            GraphType.VENUE,
            data[GraphType.VENUE] as HealthChangeStatus),
        this.builSelectorConfig("data.DASHBOARD.FABRICS",
            "fabrics-graph-selector.svg",
            GraphType.FABRIC,
            data[GraphType.FABRIC] as HealthChangeStatus),
        this.builSelectorConfig("data.DASHBOARD.DEVICES",
            "devices-graph-selector.svg",
            GraphType.DEVICE,
            data[GraphType.DEVICE] as HealthChangeStatus),
        // Traffic config
        {
            title: this.translate.instant("data.COMMON.TRAFFIC"),
            change: (data[GraphType.TRAFFIC] as KpiValueChange).change,
            total: trafficTotalAndUnits.finalValue,
            icon: "traffic-graph-selector.svg",
            graphType: GraphType.TRAFFIC,//"traffic",
            units: this.kpiDataService.getBytesSizeByValue(trafficTotalAndUnits.countOfDevition),
            invertSignColor: false,
            hideIll: true
        }
        ]
    }
    private _getOrgConfig(data: {
        [graphType: string]: HealthChangeStatus
    }): GraphTypeSelectorData[] {
        return [
            this.builSelectorConfig("data.DASHBOARD.TENANTS",
                "tenants-graph-selector.svg",
                GraphType.TENANT,
                data[GraphType.TENANT]),
            this.builSelectorConfig("data.DASHBOARD.VENUES",
                "venues-graph-selector.svg",
                GraphType.VENUE,
                data[GraphType.VENUE]),
            this.builSelectorConfig("data.DASHBOARD.FABRICS",
                "fabrics-graph-selector.svg",
                GraphType.FABRIC,
                data[GraphType.FABRIC]),
            this.builSelectorConfig("data.DASHBOARD.DEVICES",
                "devices-graph-selector.svg",
                GraphType.DEVICE,
                data[GraphType.DEVICE])
        ]
    }
    private builSelectorConfig(title: string, icon: string, graphType: GraphType, status: HealthChangeStatus, hideIll: boolean = false) {
        return {
            change: status.change,
            invertSignColor: true,
            ill: this.maxSeverity(status),
            total: status.total,
            title: this.translate.instant(title),
            ill_style: this.severityCSS(status),
            icon: icon,
            graphType: graphType,
            hideIll
        };
    }
    private maxSeverity(illness: HealthChangeStatus) {
        return illness.high > 0 ? illness.high : illness.medium
    }
    private severityCSS(health: HealthChangeStatus) {
        /**
         * Imry: 15.1.20:
         * Added the next condition in order to apply different styling to the "zero" ill items case
         */
        if (health.high + health.medium == 0)
            return this.severityTheme.css[LegacySeverity.Zero];
        return health.high > 0 ? this.severityTheme.css[LegacySeverity.High] : this.severityTheme.css[LegacySeverity.Medium]
    }

}
