import {MatTreeNodeModelWithIcon,} from "../../../../shared/models/utils-classes/base-netop-mat-tree.model";
import {convertWordsToKebabCased} from "../../../../shared/operators/string-opertators";
import {STACKED_HALF_COLORS} from "../../../../shared/components/chartjs-components/utils/chartjs-colors";
import {EntityType} from "../../../../shared/models/entity-type.enum";
import {ColorsUtils} from "../../../../shared/global-utils/colors-utils";
import {KpiType} from "../../../../shared/models/kpi.model";

export enum VenueKpis {
  Latency = 'Latency',
  Loss = 'Loss',
  Traffic = 'Traffic',
  Throughput = 'Throughput',
  Clients = 'Clients Count',
  ConnectionFailures = 'Connection Failures',
  VPN_Traffic = 'VPN',
  Predictions = 'Predictions',
  Fabric_traffic = 'Fabric Traffic',
  Application = "Application",
  Resources = "Resources",
  Cpu = "Cpu",
  Memory = "Memory",
  Sessions = "Sessions"
}

export namespace VenueKpis {
  export function toArray() {
    return [
      VenueKpis.Latency,
      VenueKpis.Loss,
      VenueKpis.Sessions,
      VenueKpis.Cpu,
      VenueKpis.Memory,
      VenueKpis.Traffic,
      VenueKpis.Throughput,
      VenueKpis.Clients,
      VenueKpis.ConnectionFailures,
      VenueKpis.Predictions,
      VenueKpis.Fabric_traffic,
      VenueKpis.VPN_Traffic,
      VenueKpis.Application,
    ]
  }
}

export enum TrafficKeys {
  Traffic_Up = 'Traffic Up',
  Traffic_Down = 'Traffic Down',
  Throughput_Up = 'Throughput Up',
  Throughput_Down = 'Throughput Down'
}

export namespace TrafficKeys {
  export function keysToArray() {
    return [
      TrafficKeys.Traffic_Up,
      TrafficKeys.Traffic_Down,
      TrafficKeys.Throughput_Up,
      TrafficKeys.Throughput_Down
    ]
  }

  export function trafficArray() {
    return [
      TrafficKeys.Traffic_Up,
      TrafficKeys.Traffic_Down,
    ]
  }

  export function throughputArray() {
    return [
      TrafficKeys.Throughput_Up,
      TrafficKeys.Throughput_Down
    ]
  }

  export function toNodesArray(kpi: VenueKpis): Array<MatTreeNodeModelWithIcon<string | number, VenueKpis | ConnectionFailuresKeys | TrafficKeys>> {
    if (kpi === VenueKpis.Traffic) {
      return [{
        value: TrafficKeys.Traffic_Down,
        type: TrafficKeys.Traffic_Down,
        nodes: [],
        additionalData: {
          class: convertWordsToKebabCased(TrafficKeys.Traffic_Down),
          fromColor: KPI_BACKGROUND_HALF_OPACITY_COLORS[TrafficKeys.Traffic_Down],
          toColor: KPI_BACKGROUND_ZERO_OPACITY_COLORS[TrafficKeys.Traffic_Down]
        }
      },
        {
          value: TrafficKeys.Traffic_Up,
          type: TrafficKeys.Traffic_Up,
          nodes: [],
          additionalData: {
            class: convertWordsToKebabCased(TrafficKeys.Traffic_Up),
            fromColor: KPI_BACKGROUND_HALF_OPACITY_COLORS[TrafficKeys.Traffic_Up],
            toColor: KPI_BACKGROUND_ZERO_OPACITY_COLORS[TrafficKeys.Traffic_Up]
          }
        },
      ]
    }
    if (kpi === VenueKpis.Throughput) {
      return [{
        value: TrafficKeys.Throughput_Down,
        type: TrafficKeys.Throughput_Down,
        nodes: [],
        additionalData: {
          class: convertWordsToKebabCased(TrafficKeys.Throughput_Down),
          fromColor: KPI_BACKGROUND_HALF_OPACITY_COLORS[TrafficKeys.Throughput_Down],
          toColor: KPI_BACKGROUND_ZERO_OPACITY_COLORS[TrafficKeys.Throughput_Down]
        }
      },
        {
          value: TrafficKeys.Throughput_Up,
          type: TrafficKeys.Throughput_Up,
          nodes: [],
          additionalData: {
            class: convertWordsToKebabCased(TrafficKeys.Throughput_Up),
            fromColor: KPI_BACKGROUND_HALF_OPACITY_COLORS[TrafficKeys.Throughput_Up],
            toColor: KPI_BACKGROUND_ZERO_OPACITY_COLORS[TrafficKeys.Throughput_Up]
          }
        }
      ]
    }

  }
}

export enum PredictionKeys {
  Predictions = 'Predictions',
  Traffic_Predictions = 'Traffic Predictions'
}


export namespace PredictionKeys {
  export function toArray() {
    return [
      PredictionKeys.Predictions,
      PredictionKeys.Traffic_Predictions
    ]
  }
}

export enum PredictionDatasetsName {
  Actual = 'Actual',
  Prediction = 'Prediction',
  Lower = 'Lower',
  Higher = 'Higher'
}

export namespace PredictionDatasetsName {
  export function toArray() {
    return [
      PredictionDatasetsName.Actual,
      PredictionDatasetsName.Prediction,
      PredictionDatasetsName.Lower,
      PredictionDatasetsName.Higher,
    ]
  }
}


export enum ConnectionFailuresKeys {
  assoc = 'assoc',
  auth = 'auth',
  DNS = 'DNS',
  DHCP = 'DHCP'
}

export namespace ConnectionFailuresKeys {
  export function toArray(): Array<MatTreeNodeModelWithIcon<string | number, VenueKpis | ConnectionFailuresKeys | TrafficKeys>> {
    return Object.values(ConnectionFailuresKeys).filter(val => typeof val === 'string').map(val => {
        return {
          value: val,
          type: val,
          nodes: [],
          additionalData: {
            class: convertWordsToKebabCased(val.toString()),
            fromColor: KPI_BACKGROUND_HALF_OPACITY_COLORS[val.toString()],
            toColor: KPI_BACKGROUND_ZERO_OPACITY_COLORS[val.toString()]
          }
        }
      },
    ) as Array<MatTreeNodeModelWithIcon<string | number, VenueKpis | ConnectionFailuresKeys | TrafficKeys>>
  }

  export function toStringArray() {
    return [
      ConnectionFailuresKeys.auth.toString(),
      ConnectionFailuresKeys.assoc.toString(),
      ConnectionFailuresKeys.DNS.toString(),
      ConnectionFailuresKeys.DHCP.toString(),
    ]
  }
}

export type VenueKpisTree = { [key in VenueKpis]?: MatTreeNodeModelWithIcon<string | number, VenueKpis | ConnectionFailuresKeys | TrafficKeys | PredictionKeys | EntityType> };
export const KPIS_WITHOUT_MONTHLY_SUPPORT = [
  ConnectionFailuresKeys.DHCP.toString(),
  ConnectionFailuresKeys.DNS.toString(),
  ConnectionFailuresKeys.auth.toString(),
  ConnectionFailuresKeys.assoc.toString(),
  VenueKpis.Loss.toString(),
  VenueKpis.Latency.toString(),
  VenueKpis.Cpu.toString(),
  VenueKpis.Memory.toString(),
  VenueKpis.Sessions,
  VenueKpis.Throughput.toString(),
  TrafficKeys.Throughput_Up.toString(),
  TrafficKeys.Throughput_Down.toString(),
]

export const METRICS_WITH_TRAFFIC_AND_THROUGHPUT = [
  VenueKpis.Fabric_traffic,
  EntityType.CLIENT
]
export const KPIS_WITHOUT_HOURLY_SUPPORT = [
  VenueKpis.Application.toString()
]
export const KPIS_LOAD_BY_TREE_ONLY = [
  VenueKpis.Latency,
  VenueKpis.Loss,
  VenueKpis.Cpu,
  VenueKpis.Memory,
  VenueKpis.Sessions,
  VenueKpis.Application.toString(),
  VenueKpis.Fabric_traffic,
  VenueKpis.VPN_Traffic
]

export const SORTED_TRAFFIC_KPI = [
  VenueKpis.Latency,
  VenueKpis.Loss,
  VenueKpis.Cpu,
  VenueKpis.Memory,
  VenueKpis.Sessions,
  VenueKpis.Application,
  VenueKpis.Fabric_traffic
]

export const KPI_BACKGROUND_ZERO_OPACITY_COLORS: { [key in (VenueKpis | ConnectionFailuresKeys | TrafficKeys | string)]: string } = {
  [TrafficKeys.Traffic_Down]: ColorsUtils.hexToRgb('#8B42D6', 0),
  [TrafficKeys.Traffic_Up]: ColorsUtils.hexToRgb('#ebbef3', 0),
  [TrafficKeys.Throughput_Down]: ColorsUtils.hexToRgb('#246b2b', 0),
  [TrafficKeys.Throughput_Up]: ColorsUtils.hexToRgb('#47bc3b', 0),
  [EntityType.CLIENT]: ColorsUtils.hexToRgb('#9da3e0', 0),
  [ConnectionFailuresKeys.auth]: ColorsUtils.hexToRgb('#ffa300', 0),
  [ConnectionFailuresKeys.DNS]: ColorsUtils.hexToRgb('#65EED7', 0),
  [ConnectionFailuresKeys.DHCP]: ColorsUtils.hexToRgb('#023039', 0),
  [ConnectionFailuresKeys.assoc]: ColorsUtils.hexToRgb('#FFD86C', 0),
  [VenueKpis.Traffic]: ColorsUtils.hexToRgb('#00A876', 0),
  [VenueKpis.Throughput]: ColorsUtils.hexToRgb('#00A876', 0),
  [VenueKpis.ConnectionFailures]: ColorsUtils.hexToRgb('#00A876', 0),
  [VenueKpis.Predictions]: ColorsUtils.hexToRgb('#33bec4', 0),
  [PredictionDatasetsName.Higher]: ColorsUtils.hexToRgb('#0055ff', 0),
  [PredictionDatasetsName.Lower]: ColorsUtils.hexToRgb('#0055ff', 0),
  [PredictionDatasetsName.Actual]: ColorsUtils.hexToRgb('#00ff49', 0),
  [PredictionDatasetsName.Prediction]: ColorsUtils.hexToRgb('#0055ff', 0),
};

export const KPI_BACKGROUND_HALF_OPACITY_COLORS: { [key in VenueKpis | ConnectionFailuresKeys | TrafficKeys | PredictionDatasetsName | string]: string } = {
  [VenueKpis.VPN_Traffic]: STACKED_HALF_COLORS[0],
  [VenueKpis.Latency]: STACKED_HALF_COLORS[1],
  [VenueKpis.Loss]: STACKED_HALF_COLORS[2],
  [VenueKpis.Cpu]: STACKED_HALF_COLORS[3],
  [VenueKpis.Memory]: STACKED_HALF_COLORS[4],
  [VenueKpis.Sessions]: STACKED_HALF_COLORS[5],
  [TrafficKeys.Traffic_Down]: ColorsUtils.hexToRgb('#8B42D6'),
  [TrafficKeys.Traffic_Up]: ColorsUtils.hexToRgb('#ebbef3'),
  [KpiType.Traffic]: ColorsUtils.hexToRgb('#9b79be'),
  [TrafficKeys.Throughput_Down]: ColorsUtils.hexToRgb('#246b2b'),
  [TrafficKeys.Throughput_Up]: ColorsUtils.hexToRgb('#47bc3b'),
  [KpiType.Throughput]: ColorsUtils.hexToRgb('#75a76b'),
  [EntityType.CLIENT]: ColorsUtils.hexToRgb('#9da3e0'),
  [ConnectionFailuresKeys.auth]: ColorsUtils.hexToRgb('#ffa300'),
  [ConnectionFailuresKeys.DNS]: ColorsUtils.hexToRgb('#65EED7'),
  [ConnectionFailuresKeys.DHCP]: ColorsUtils.hexToRgb('#023039'),
  [ConnectionFailuresKeys.assoc]: ColorsUtils.hexToRgb('#FFD86C'),
  [VenueKpis.ConnectionFailures]: ColorsUtils.hexToRgb('#00A876'),
  [VenueKpis.Predictions]: ColorsUtils.hexToRgb('#33bec4'),
  [PredictionDatasetsName.Higher]: ColorsUtils.hexToRgb('#0055ff', .1), //#f80202
  [PredictionDatasetsName.Lower]: ColorsUtils.hexToRgb('#0055ff', .1), //#000000
  [PredictionDatasetsName.Actual]: ColorsUtils.hexToRgb('#00ff49'),
  [PredictionDatasetsName.Prediction]: ColorsUtils.hexToRgb('#0055ff'),
};

export type TotalWithId = { id: number, total: number };
