import {Injectable} from '@angular/core';
import {ClientDevice} from "../../../../../models/clients.model";
import {NumericHash} from "../../../../../models/utils-classes/hash.model";

@Injectable({
  providedIn: 'root'
})
export class ClientsToDisplayConvertorService {

  constructor() {
  }

  convertConnectedClientsToHash(clients: ClientDevice[]): NumericHash {
    let aggregatedClients: NumericHash;
    clients.forEach(client => {
      const keys = ["os", "manufacturer"];
      keys.forEach(key => {
        if (client.hasOwnProperty(key) && client[key]) {
          if (!aggregatedClients) {
            aggregatedClients = {[key]: {[client[key]]: 1}};
          }
          if (aggregatedClients && aggregatedClients[key]) {
            if (aggregatedClients[key][client[key]]) {
              aggregatedClients[key][client[key]]++;
            } else {
              aggregatedClients[key][client[key]] = 1;
            }
          } else {
            aggregatedClients[key] = {[client[key]]: 1}
          }
        }
      })
    });
    return aggregatedClients;
  }
}
