import { Action, createAction, props } from '@ngrx/store';
import { Entity } from 'src/app/shared/models/entity.model';
import { EntityType } from 'src/app/shared/models/entity-type.enum';

/**
 * Neither fires as of 27.11.2019 Evgeny
 */
export const currentEntityLoadRequest = createAction('[Entity] Load Request',
    props<{ entityId: number, entityType: EntityType }>())
/**
 * Fired when the navigation starts
 */
export const currentEntityNavigationStarted = createAction('[Entity] Navigation Started',
    props<{ entityId: number, entityType: EntityType }>())
/**
 * Fired when the navigation finishes
 */
export const currentEntityNavigated = createAction('[Entity] Navigated',
    props<{ entityId: number, entityType: EntityType }>())
/**
 * Fire when the current entity is loaded via hierarchy 
 */
export const currentEntityLoaded = createAction('[Entity] Load Success',
    props<{ entity: Entity }>())
/**
 * Fires if there was an error on navigation
 */
export const currentEntityNavigationError = createAction('[Entity] Navigation Error')
export const currentEntityNavigationCancel = createAction('[Entity] Navigation Canceled',
    // the previouse (to canceled navigation) entity
    props<{ entity: Entity }>())