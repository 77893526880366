import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { PortalSizeService } from './services/portal-size.service';
import { Rect } from './services/rect.class'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-portal',
  templateUrl: './dashboard-portal.component.html',
  styleUrls: ['./dashboard-portal.component.scss'],
  providers: [PortalSizeService]
})
export class DashboardPortalComponent implements OnInit {
  // portal root classes
  @Input() class: string;

  constructor(private elementRef: ElementRef, private portalSizeService: PortalSizeService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let container = this.elementRef.nativeElement.querySelector('.dashboard-portal')
    this.portalSizeService.setPortalElement(container)
    // this.fireRectChange()
  }
  ngOnDestroy() {
    this.portalSizeService.disconnect();
  }
  // @HostListener('window:resize', ['$event'])
  // @HostListener('document:scroll', ['$event'])
  // onWindowResize(event: any) {
  //   this.fireRectChange()
  // }
  // private fireRectChange() {
  //   let rect = (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect();
  //   console.log('got portal size of %o', rect)
  //   this.portalSizeService.setPortalElement(new Rect(rect.left, rect.top, rect.width, rect.height));
  // }
}
