import {Directive, HostListener, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AllDirections} from "../operators/encoded-arrow";
import {isOverflowing} from "../operators/dom-operator";

@Directive({
  selector: '[arrowScroll]'
})
export class horizontalScrollDirective {

  constructor(private viewContainer: ViewContainerRef) {
  }

  @Input('scrollRange') scrollRange: number;
  @Input('arrowScroll') direction: AllDirections;
  @Input('scrollElement') scrollElement: HTMLElement;

  @HostListener('click', ['$event']) onClick($event) {
    if (this.direction === 'right') {
      this.scrollRight();
    }
    if (this.direction === 'left') {
      this.scrollLeft();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isShowArrows();
  }

  ngAfterViewInit() {
    this.listenToScrollEvent();
    this.isShowArrows();
  }

  scrollRight(): void {
    this.scrollElement.scrollTo({
      left: (this.scrollElement.scrollLeft + +this.scrollRange),
      behavior: 'smooth'
    });
  }


  scrollLeft(): void {
    this.scrollElement.scrollTo({
      left: (this.scrollElement.scrollLeft - +this.scrollRange),
      behavior: 'smooth'
    });
  }

  private isShowArrows() {
    if (!isOverflowing(this.scrollElement) || this.isShowLeftScroll() || this.isShowRightScroll()) {
      //this.viewContainer.element.nativeElement.hidden = true;
      this.viewContainer.element.nativeElement.innerText = '';
    } else {
      //this.viewContainer.element.nativeElement.hidden = false;
      this.viewContainer.element.nativeElement.innerText = this.direction === 'left' ? '<<' : '>>';

    }
  }

  private listenToScrollEvent() {
    this.scrollElement.addEventListener('scroll', (event) => {
      this.isShowArrows();
    })
  }

  private isShowLeftScroll() {
    return this.scrollElement.scrollLeft === 0 && this.direction === 'left';
  }

  private isShowRightScroll() {
    return this.scrollElement.offsetWidth === this.scrollElement.scrollWidth - this.scrollElement.scrollLeft && this.direction === 'right';
  }
}
