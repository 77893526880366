export class NavItem {
  name: LeftSideItemName;
  class: string;
  routerLink: any[] | string;
  disabled: boolean;
  routerLinkActive: string;
  enterAnimation: string;
  svgIcon: string;
  isDisplayed: boolean;
  canBeDeleted: boolean;
  iconStyle?: any;
  textStyle?: any;
  lastBeforeDivider?: boolean;
  isSelected?: boolean
}

export enum LeftSideItemName {
  DASHBOARD = 'dashboard',
  FABRICS = 'fabrics',
  VENUES = 'venues',
  DEVICES = 'devices',
  ALARMS = 'alarms',
  INTEGRATIONS = 'integrations',
  SETTINGS = 'settings',
  OPERATIONS = 'operations',
  SECURITY = 'security',
  ACCOUNTS = 'accounts',
  SERVICES = 'services',
  REPORTS = 'reports'
}
