import {ActiveElement, Chart, TitleOptions} from 'chart.js';
import {Directive, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ChartJsAnnotation} from "./annotations.model";
import {MultiAxisDatasetConfig, MultiAxisGraphConfig} from "./multi-axis-graph-config";
import {LoggerService} from "../../../services/logger.service";
import {GraphTooltip} from "../chartjs-tooltips/graph-tooltip";
import {VerticalBarThemeConfig} from "./vertical-bar-config";
import {VerticalBarTooltip} from "../chartjs-tooltips/vertical-bar-tooltip";
import {GraphTrendConfig, GraphTrendThemeConfig} from "./graph-trend-config";
import {FromToDates, TimeUnit} from "../../../models/time.model";
import {AllDirections} from "../../../operators/encoded-arrow";
import {
  calculateDaysNumberBetweenTwoDates,
  getDateByDaysRange, getDatesByHoursRange, getDatesByMinutesRange
} from "../../../operators/time-operator";
import {TimeUtils} from "../../../global-utils/time-utils";


export const MINUTES_LABELS_GAP: number = 30;
export const HOURS_LABELS_GAP: number = 12;
export const DAYS_LABELS_GAP: number = 5;

export const MINUTES_TICKS_GAP: number = 5;
export const HOURS_TICKS_GAP: number = 1;
export const DAYS_TICKS_GAP: number = 1;
export const MINUTES_DISTANCE_FROM_FUTURE: number = 5;

export const LEGEND_TEXT_SIZE = 14;
export const LEGEND_BOX_WIDTH = 6;


@Directive()
export class BaseTrendChartJs {
  @Input() chartConfig: GraphTrendConfig | MultiAxisGraphConfig;
  @Input() graphWidth: number;
  @Input() graphHeight: number;
  @Input() hideLegend: boolean = false;
  @Input() isResponsive: boolean = true;
  @Input() noDataError: string = this.translate.instant('data.COMMON.NO_GRAPH_DATA_TO_DISPLAY');
  @Input() annotations: ChartJsAnnotation[];
  @Input() maxTicksX: number = 6;
  @Input() maxTicksY: number = 6;
  @Input() maxRotation: number = 0;
  @Input() beginAtZero: boolean = undefined;
  @Input() stacked: boolean = false;
  @Input() minY: number = undefined;
  @Input() maxY: number = undefined;
  @Input() minX: number = undefined;
  @Input() maxX: number = undefined;
  @Input() suggestedMaxY: number = undefined;
  @Input() suggestedMinY: number = undefined;
  @Output() datesChanged: EventEmitter<FromToDates> = new EventEmitter<FromToDates>()
  @ViewChild('graph', {static: true}) graphElm: any;

  graphTooltip: GraphTooltip = new GraphTooltip();
  verticalBarTooltip = new VerticalBarTooltip('above', false, 250);
  topY: number;
  bottomY: number;
  x: number;
  datasets: MultiAxisDatasetConfig[] | any[];
  legend = {
    display: !this.hideLegend,
    labels: {
      // fontColor: this.isShowLegendToggleMode == false ? '#ffffff' : '#6a707e',
      // show the legend 'icon' in the same style as the point under cursor
      usePointStyle: true,
      // the size of the legend
      boxWidth: LEGEND_BOX_WIDTH,
      font: {
        size: LEGEND_TEXT_SIZE
      },
    },
    position: "top",
    align: "end"
  } as any;

  titlePlugin: TitleOptions = {
    color: '#000000',
    fullSize: true,
    padding: undefined,
    display: true,
    text: this.noDataError,
    font: {
      size: 16,
      family: "'Rubik','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      style: 'normal',
      weight: 'normal',
      lineHeight: 1.2
    },
    align: 'center',
    position: 'top'
  }

  zoomPluginConfiguration = {
    /**
     * https://github.com/chartjs/chartjs-plugin-zoom/blob/v0.7.7/README.md
     * ======Notice==========
     * 19.7.21: Imry: Current zoom plugin is 0.7.7 which is compatible with Chart.js 2.x.x versions
     * When the time come and we are going to upgrade Chart.js version we will need to it to all of its libraries to
     */
    pan: {
      enabled: true,
      drag: {
        enabled: true,
      },
      wheel: {
        enabled: false,
      },
      mode: 'x',
      threshold: 10,
      onPanComplete: function (chart) {
        this.logger.debug(`chart while panning ${chart.chart}`);
        this.logger.debug((`min ${new Date(chart.chart.scales['x'].min)}`));
        this.logger.debug((`max ${new Date(chart.chart.scales['x'].max)}`));
        if (chart && chart.chart && chart.chart.scales['x']) {
          const newRange: FromToDates = {
            start: new Date(chart.chart.scales['x'].min),
            end: TimeUtils.roundDateMinutes(new Date(chart.chart.scales['x'].max))
          }

          this.datesChanged.emit(newRange);
        }
      }.bind(this)
    },
    zoom: {
      drag: {
        enabled: false,
      },
      wheel: {
        enabled: false,
      },
      mode: 'x',
      speed: 0.1,
      threshold: 2,
      sensitivity: 3,
      onZoom: function ({chart}) {
        console.log(`I'm zooming!!!`);
      },
      // Function called once zooming is completed
      onZoomComplete: function ({chart}) {
        this.logger.debug(`chart while panning ${chart.chart}`);
        this.logger.debug((`min ${new Date(chart.chart.scales['x'].min)}`));
        this.logger.debug((`max ${new Date(chart.chart.scales['x'].max)}`));
        if (chart && chart.chart && chart.chart.scales['x']) {
          const newRange: FromToDates = {
            start: new Date(chart.chart.scales['x'].min),
            end: new Date(chart.chart.scales['x'].max)
          }
          this.datesChanged.emit(newRange);
        }
      }.bind(this)
    }

  }
  hoverItemsNumber: number = 0;
  addDateToTooltipItems: boolean = false;
  chart: Chart;


  constructor(protected translate: TranslateService, protected loggerFactory: LoggerService) {
  }

  protected drawEmptyGraph() {
    const currentThis = this;
    this.chart = new Chart(this.graphElm.nativeElement, {
      type: 'line',
      data: {
        datasets: []
      },
      options: {
        plugins: {
          title: this.titlePlugin
        },
        // Note that if we will need the graph inside a resizable portlet we will need
        // to set the responsive option to false.
        responsive: currentThis.isResponsive,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true, // mandatory
            title: {
              display: true, // mandatory
            },
            border: {
              display: true
            },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: 0
            },
          },
          y: {
            display: true,
            border: { display: false },
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: 0
            }
          },
        }
      }
    });
  }

  /**
   * Return boolean if there is at least one dataset with data
   * @param config
   * @protected
   */
  protected hasNoData(config: MultiAxisGraphConfig | GraphTrendConfig) {
    if (config.hasOwnProperty('datasetsConfig')) {
      return (config as MultiAxisGraphConfig).datasets.length == 0 ||
        (config as MultiAxisGraphConfig).datasets.find((lineConfig) =>
          lineConfig && lineConfig.data && lineConfig.data.length > 0) === undefined;
    }
    if (config.hasOwnProperty('datasets')) {
      return (config as GraphTrendConfig).datasets.length == 0 ||
        (config as GraphTrendConfig).datasets.find((lineConfig) =>
          lineConfig && lineConfig.data && lineConfig.data.length > 0) === undefined;
    }
  }

  /**
   * Find item current index and return tooltip text
   * @param tooltipItem
   * @param chart
   */
  protected getTooltipText = (context) => {
    const itemData: VerticalBarThemeConfig = (context.dataset as VerticalBarThemeConfig);
    if (itemData.tooltipText[context.dataIndex]) {
      return this.hoverItemsNumber as number > 1 && this.addDateToTooltipItems ?
        `${itemData.tooltipText[context.dataIndex].toString()} ${(itemData.data[context.dataIndex] as any).x.format('HH:mm')}` :
        `${itemData.tooltipText[context.dataIndex].toString()}`
    }
  }

  protected buildLineConfig?(lineConfig: GraphTrendThemeConfig, graphElm, graphHeight: number) {
    let gradient: any;
    let graphConfig: any = {
      label: lineConfig.label,
      fill: lineConfig.fill,
      /**
       * colors of the points on the graph, those are hidden using
       * pointRadius = 0, but are shown on hover using the 'hover'
       * config.
       */
      pointBackgroundColor: "white",
      // The data to show on the graph
      data: lineConfig.data,
      pointHoverBackgroundColor: "white",
      borderWidth: lineConfig.borderWidth,
      // hide the point by default using radius 0
      pointRadius: 0,
      // the cursor sensitivity
      pointHitRadius: 4,
    };
//graphConfig["lineTension"] = 0;
    if (lineConfig["gradient"]) {
      gradient = graphElm.nativeElement.getContext("2d").createLinearGradient(0, 0, 0, graphHeight);
      gradient.addColorStop(0, lineConfig.gradient.fromColor);
      gradient.addColorStop(1, lineConfig.gradient.toColor);
      graphConfig = {
        ...graphConfig,
        backgroundColor: gradient,
        borderColor: lineConfig.gradient.fromColor,
        strokeColor: lineConfig.gradient.toColor,
        pointBorderColor: lineConfig.gradient.fromColor,
        pointHighlightStroke: lineConfig.gradient.toColor,
      }
    } else {
      graphConfig = {
        ...graphConfig,
        pointBorderColor: lineConfig.strokeColor,
        strokeColor: lineConfig.strokeColor
      }
    }
    ["lineTension", "pointRadius", "borderColor", "pointBorderColor", "pointBorderWidth"].forEach((configName) => this.setIfDef(graphConfig, configName, lineConfig))
    return graphConfig;
  }

  setIfDef ?(obj, configName, configObj) {
    if (configObj[configName] != undefined
    ) {
      obj[configName] = configObj[configName];
    }
    return obj;
  }

  /**
   * Emit new dates after interpreting Range and direction
   * @param panDirection
   */
  setPan(panDirection: AllDirections) {
    if (this.chart.config.data.labels) {
      const timeAxisLength = this.chart.config.data.labels.length;
      const lastItemX = this.chart.config.data.labels[timeAxisLength - 1] as number;
      const oneBeforeLastItemX = this.chart.config.data.labels[timeAxisLength - 2] as number;
      let currentRange: TimeUnit;
      currentRange = TimeUnit.HOURS;

      if (TimeUtils.calculateTotalMinutesBetweenTwoDates(new Date(lastItemX), new Date(oneBeforeLastItemX)) < 60) {
        currentRange = TimeUnit.MINUTES;
      }

      if (calculateDaysNumberBetweenTwoDates(new Date(lastItemX), new Date(oneBeforeLastItemX)) >= 1) {
        currentRange = TimeUnit.DAYS;
      }

      let emittedRange: FromToDates;
      let action: ('add' | 'subtract') = panDirection === 'right' ? 'add' : 'subtract';
      switch (currentRange as TimeUnit) {
        case TimeUnit.HOURS: {
          emittedRange = {
            start: null,
            end: getDatesByHoursRange(HOURS_LABELS_GAP / 6, new Date(lastItemX), action)
          }
          break;
        }
        case TimeUnit.DAYS: {
          emittedRange = {
            start: null,
            end: getDateByDaysRange(DAYS_LABELS_GAP, new Date(lastItemX), action)
          }
          break;
        }
        case TimeUnit.MINUTES: {
          emittedRange = {
            start: null,
            end: getDatesByMinutesRange(MINUTES_LABELS_GAP, new Date(lastItemX), action)
          }
          break;
        }
        default:
          break;
      }
      emittedRange = {
        start: emittedRange.start,
        end: TimeUtils.roundDateMinutes(emittedRange.end)
      }
      this.datesChanged.emit(emittedRange);
    }
  }

  getHighestYScale(chart: any) {
    if (Object.keys(chart.scales).includes('y')) {
      return 'y';
    }

    let highestYScaleValue = 0;
    let highestYScaleKey: string;
    Object.entries(chart.scales).forEach(scale => {
      const key: any = scale[0];
      const value: any = scale[1];
      if (value.type !== 'time') {
        if (value.max > highestYScaleValue) {
          highestYScaleValue = value.max;
          highestYScaleKey = key;
        }
      }
    })
    return highestYScaleKey;
  }

  setVerticalMarkerLineSizes(elements: ActiveElement[], chart: Chart) {
    this.x = elements[0].element.x;
    this.topY = chart.scales[this.getHighestYScale(chart)].top;
    this.bottomY = chart.scales[this.getHighestYScale(chart)].bottom;
  }

  drawVerticalMarkerLine(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(this.x, this.topY, 3, 3);
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(this.x, this.topY);
    ctx.lineTo(this.x, this.bottomY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#5d6267';
    ctx.stroke();
    ctx.restore();
  }

  /**
   * Return true if new potoential date is later then now, or of the gap between them is lower the MINUTES_DISTANCE_FROM_FUTURE
   * @param newPotentialDate
   * @private
   */
  private isPanIntoTheFuture(newPotentialDate: Date) {
    return newPotentialDate >= new Date() ||
      TimeUtils.calculateTotalMinutesBetweenTwoDates(newPotentialDate, new Date()) <= MINUTES_DISTANCE_FROM_FUTURE;
  }
}
