import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviationReportsDialogComponent } from './deviation-reports-dialog.component';
import { NetopDialogModule } from '../netop-dialog/netop-dialog.module';
import { DeviationReportsDialogMainComponent } from './components/deviation-reports-dialog-main/deviation-reports-dialog-main.component';
import { TenantDeviationReportsDialogComponent } from './components/tenant-deviation-reports-dialog/tenant-deviation-reports-dialog.component';
import { VenueDeviationReportsDialogComponent } from './components/venue-deviation-reports-dialog/venue-deviation-reports-dialog.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '../../shared-components.module';
import { BasicProgressBarComponent } from '../../components/basic-progress-bar/basic-progress-bar.component';
import { SharedDirectivesModule } from '../../shared-directives.module';
import { FULL_COLORS_LIST, fullColorsList } from '../../global-utils/colors-utils';
import { InlineSVGModule } from 'ng-inline-svg';
import { LOSS_LATENCY_THRESHOLDS, lossLatencyThreshold } from '../../global-utils/loss-latency-threshold';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import {SharedPipesModule} from "../../shared-pipes.module";
import {
    HORIZONTAL_ANNOTATION,
    HORIZONTAL_CHART_JS_ANNOTATION
} from "../../components/chartjs-components/models/chart-js-injection-token";



@NgModule({
  declarations: [
    DeviationReportsDialogComponent,
    DeviationReportsDialogMainComponent,
    TenantDeviationReportsDialogComponent,
    VenueDeviationReportsDialogComponent,
  ],
  imports: [
    CommonModule,
    NetopDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    InlineSVGModule.forRoot(),
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule
  ],
  exports: [BasicProgressBarComponent],
  providers: [
    {
      provide: FULL_COLORS_LIST,
      useValue: fullColorsList
    },
    {
      provide: LOSS_LATENCY_THRESHOLDS,
      useValue: lossLatencyThreshold
    },
    {
      provide: HORIZONTAL_CHART_JS_ANNOTATION,
      useValue: HORIZONTAL_ANNOTATION
    },
  ]
})
export class DeviationReportsDialogModule { }
