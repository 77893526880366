import { Injectable, ElementRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ViewPortService {
    getWindowSize() {
        return {
            height: (window.innerHeight || document.documentElement.clientHeight),
            width: (window.innerWidth || document.documentElement.clientWidth)
        }
    }
}  