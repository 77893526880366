import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {SearchMenuBoxDisplay} from "./search-menu-box.model";
import {isNotNullandUndefined} from "../../operators/object-operators/null-checks";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import {ICONS_PATH} from "../../../config/path";

@Component({
  selector: 'app-search-menu-box',
  templateUrl: './search-menu-box.component.html',
  styleUrls: ['./search-menu-box.component.scss']
})
export class SearchMenuBoxComponent<T> implements OnInit {

  @Input() display: SearchMenuBoxDisplay<T>[];
  @Input() multipleSelect: boolean = false;
  @Input() selectedOption: SearchMenuBoxDisplay<T>;
  @Input() selectedOptions: SearchMenuBoxDisplay<T>[] = [];
  @Input() dragAndDrop: boolean = false;
  @Input() dropListId: string;
  @Input() dropListConnectedTo: string[];
  @Input() cssClass: string;
  @Input() height: string;
  @Output() selectedOptionChange = new EventEmitter<SearchMenuBoxDisplay<T>>();
  @Output() selectedOptionsChange = new EventEmitter<SearchMenuBoxDisplay<T>[]>();

  searchInput: string;
  searchIcon: string = 'search.svg';
  searchIconPath: string = `${ICONS_PATH}${this.searchIcon}`;

  constructor() { }

  ngOnInit(): void {
  }

  onOptionSelected(selectedOption: SearchMenuBoxDisplay<T>) {
    if (!this.multipleSelect) {
      if (selectedOption) {
        if (this.selectedOption && this.selectedOption.id == selectedOption.id)
          this.selectedOption = undefined;
        else
          this.selectedOption = selectedOption;
        this.selectedOptionChange.emit(this.selectedOption);
      }
    }
    else {
      if (this.isOptionSelected(selectedOption))
        this.selectedOptions = this.selectedOptions.filter(option => option.id != selectedOption.id);
      else
        this.selectedOptions.push(selectedOption);
      this.selectedOptionsChange.emit(this.selectedOptions);
    }
  }

  onOptionDragged(dragged: SearchMenuBoxDisplay<T>) {
    this.selectedOption = undefined;
    this.selectedOptions = [];
    this.selectedOptionChange.emit(this.selectedOption);
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  onOptionDropped(dropped: CdkDragDrop<SearchMenuBoxDisplay<T>[]>) {
    if (dropped.previousContainer == dropped.container)
      moveItemInArray(dropped.container.data, dropped.previousIndex, dropped.currentIndex);
    else
      transferArrayItem(dropped.previousContainer.data, dropped.container.data, dropped.previousIndex, dropped.currentIndex);
  }

  getMenuClass(option: SearchMenuBoxDisplay<T>): string {
    return (!this.multipleSelect && option.id == this.selectedOption?.id) || (this.multipleSelect && this.isOptionSelected(option))
      ? 'search-menu-box-option search-menu-box-option-selected'
      : 'search-menu-box-option';
  }

  isOptionSelected(option: SearchMenuBoxDisplay<T>) {
    return this.selectedOptionIds.includes(option.id);
  }

  get filteredDisplay() {
    if (!isNotNullandUndefined(this.searchInput))
      return this.display;
    return this.display.filter(option =>
      option.label?.toLowerCase()
        .includes(this.searchInput.toLowerCase()));
  }

  get selectedOptionIds(): number[] {
    return this.selectedOptions.map(option => option.id);
  }

}
