import { Directive, Input, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[classByCondition]'
})
export class ClassByConditionDirective {
  @Input() classByCondition: boolean;
  @Input() cssClasses: string[];
  constructor(private _ren: Renderer2, private _el: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnChanges() {
    this.classByCondition ?
      this.cssClasses.forEach(cssClass => this._ren.addClass(this._el.nativeElement, cssClass)) :
      this.cssClasses.forEach(cssClass => this._ren.removeClass(this._el.nativeElement, cssClass))
    this.cdr.detectChanges();
  }
}
