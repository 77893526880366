<ng-container *ngIf="!isNoNodes">
    <div class="diagram-container-row">
        <ng-container *ngFor="let node of diagramNodes; let last = last; index as i">
            <div class="diagram-container-col">
                <div [ngbTooltip]="node.tooltipTemplate" [tooltipClass]="tooltipClass" [placement]="tooltipDirection">
                    <app-icon-with-ellipse [iconPath]="node.iconPath"></app-icon-with-ellipse>
                    <span class="diagram-text-label">{{node.label}}</span>
                </div>
                <div class="diagram-arrow-container" *ngIf="!last">
                    <span *ngIf="node.linkData" class="diagram-text-link">{{node.linkData}}</span>
                    <mat-icon mat-list-icon svgIcon="left-arrow" [ngClass]="[node.linkColor ? 'diagram-arrow diagram-arrow-' +
                            node.linkColor : 'diagram-arrow', node.linkData ? 'diagram-arrow-icon-with-data' : '']">
                    </mat-icon>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<div class="digram-default-message" *ngIf="isNoNodes">{{defaultMessge}}
</div>
