import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { BaseSingleDeviceDashboard } from '../../device-health-dashboard/base-single-device-dashboard';
import { TranslateService } from '@ngx-translate/core';
import { DeviceLocation } from '../../../../../shared/models/map.model';
import { LoggerService } from '../../../../../shared/services/logger.service';
import { DevicesService } from '../../../../../shared/services/rest-services/devices.service';
import { SingleEntityService } from '../../../../../shared/components/single-entities/single-entity.service';
import { BaseSingleEntityService } from '../../../../../shared/services/base-single-entity.service';
import {SingleDevice} from "../../../../../shared/models/single-device.model";
import {clearSubscriptions} from "../../../../../shared/operators/rxjs";

@Component({
  selector: 'app-device-single-map',
  templateUrl: './device-single-map.component.html'
})
export class DeviceSingleMapComponent extends BaseSingleDeviceDashboard implements OnInit, OnDestroy {
  @Input() device: SingleDevice;
  mapTitle: string;
  deviceLocation: DeviceLocation[];

  constructor(
    protected translate: TranslateService,
    protected devicesService: DevicesService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected singleEntityService: SingleEntityService,
    protected loggerFactory: LoggerService
  ) {
    super(translate, loggerFactory, devicesService, baseSingleEntityService)
  }

  ngOnInit() {
    this.mapTitle = this.translate.instant('data.COMMON.DEVICE') + " " + this.translate.instant('data.DEVICE.LOCATION');
    this.setDeviceLocation();
  }

  ngOnDestroy() {
    clearSubscriptions(this.subscription);
  }

  private setDeviceLocation() {
    if (this.device) {
      this.deviceLocation = [{
        lat: (this.device as SingleDevice).device.lat,
        lng: (this.device as SingleDevice).device.lon,
        markerEntityId: (this.device as SingleDevice).device.id,
        health: +(this.device as SingleDevice).health,
        name: (this.device as SingleDevice).device.name
      }];
    }
  }
}
