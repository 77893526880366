import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import {SearchMenuBoxDisplay} from "../../components/search-menu-box/search-menu-box.model";
import {ICONS_PATH} from "../../../config/path";

@Component({
  selector: 'app-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss']
})
export class AssignmentDialogComponent<T> implements OnInit {

  @Input() assigned: SearchMenuBoxDisplay<T>[];
  @Input() unassigned: SearchMenuBoxDisplay<T>[];
  @Input() dialogTitle: string;
  @Input() assignedHeading: string;
  @Input() unassignedHeading: string;
  @Output() assignedChange= new EventEmitter<SearchMenuBoxDisplay<T>[]>();
  @Output() unassignedChange = new EventEmitter<SearchMenuBoxDisplay<T>[]>();
  @Output() dialogClosed = new EventEmitter();

  assignedSelected: SearchMenuBoxDisplay<T>;
  unassignedSelected: SearchMenuBoxDisplay<T>;
  leftArrowIcon = `${ICONS_PATH}double_arrow_left.svg`;
  rightArrowIcon = `${ICONS_PATH}double_arrow_right.svg`;

  constructor() { }

  ngOnInit(): void {
  }

  onLeftArrow() {
    if (this.unassignedSelected) {
      this.unassigned = this.unassigned.filter(option => option.id != this.unassignedSelected.id);
      this.assigned.push(this.unassignedSelected);
      this.unassignedSelected = undefined;
      this.unassignedChange.emit(this.unassigned);
      this.assignedChange.emit(this.assigned);
    }
  }

  onRightArrow() {
    if (this.assignedSelected) {
      this.assigned = this.assigned.filter(option => option.id != this.assignedSelected.id);
      this.unassigned.push(this.assignedSelected);
      this.assignedSelected = undefined;
        this.assignedChange.emit(this.assigned);
      this.unassignedChange.emit(this.unassigned);
    }
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

}
