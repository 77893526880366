import {Colors} from "../../config/colors";

export class SeverityUtils {
  static getHealthColor(health: number): string {
    if (health == null || health == -1) return 'white';
    if (health >= 90) {
      return Colors.SEVERITY_MINOR;
    }
    if (health >= 70) {
      return Colors.SEVERITY_MEDIUM;
    }
    if (health >= 50) {
      return Colors.SEVERITY_HIGH;
    }
    return Colors.SEVERITY_CRITICAL;
  }
}
