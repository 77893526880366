import {Injectable} from '@angular/core';
import {ThemeService} from '../../../services/theme.service';
import {TranslateService} from '@ngx-translate/core';
import {Theme} from '../../../models/theme.model';
import {EntityType} from '../../../models/entity-type.enum';
import {KpiType} from '../../../models/kpi.model';

@Injectable({
  providedIn: 'root'
})
export class GraphTrendConfigService {
  categoryTheme: Theme;

  constructor(private themeService: ThemeService,
              private translate: TranslateService) {

  }

  getActionsConfig() {
    this.categoryTheme = this.themeService.getCategoryTheme()

    // let actionsFromColor = 'rgba(58, 150, 253, 1)'//'rgba(16, 156, 241, 0.7)';
    // let actionsToColor = 'rgba(46, 91, 255, 0.001)'//'rgba(16, 156, 241, 0)'
    // let actionsClosedFromColor = 'rgba(46, 91, 255, 0.5)'//'rgba(16, 156, 241, 0.7)';
    // let actionsClosedToColor = 'rgba(46, 91, 255, 0.0001)'//'rgba(16, 156, 241, 0)'
    let actionsFromColor = 'rgba(58, 150, 253, 0.2)';//#3a96fd
    let actionsToColor = 'rgba(46, 91, 255,0.01)';//#2e5bff
    let actionsClosedFromColor = 'rgba(157, 96, 251, 0.2)';// #9d60fb
    let actionsClosedToColor = 'rgba(46, 91, 255, 0.01)';// #2e5bff

    return {
      closedActions: {
        label: this.translate.instant("data.DASHBOARD.ACTIONS_CLOSED"),
        gradient: {
          fromColor: actionsFromColor,
          toColor: actionsToColor
        },
        //data: dataIll,
        borderColor: "rgba(58, 150, 253)",
        borderWidth: 2,
        lineTension: 0,
        pointRadius: 3,
        pointBorderWidth: 2,
        pointBorderColor: "rgba(58, 150, 253)"
      },
      allActions: {
        label: this.translate.instant("data.DASHBOARD.ACTIONS_OPENED"),
        gradient: {
          fromColor: actionsClosedFromColor,
          toColor: actionsClosedToColor
        },
        //data: dataTotal,
        borderColor: "rgba(157, 96, 251)",
        borderWidth: 2,
        lineTension: 0,
        pointRadius: 3,
        pointBorderWidth: 2,
        pointBorderColor: "rgba(157, 96, 251)"
      }
    }
  }

  getKpiConfig(graphType: KpiType) {
    let borderColor = '#109CF1';
    let fromColor = 'rgba(16, 156, 241, 0.7)';
    let toColor = 'rgba(16, 156, 241, 0)'

    return {
      // illTrend: {
      gradient: {
        fromColor,
        toColor
      },
      label: this.getKpiLabel(graphType),
      strokeColor: toColor,
      //
      borderColor: borderColor,
      borderWidth: 2,
      fill: true
      // }
    }
  }

  getHealthConfig(graphType: EntityType) {
    let borderIllColor = '#109CF1';
    let borderTotalColor = 'rgba(16, 156, 241, 1)';
    let fromColor = 'rgba(16, 156, 241, 0.4)';
    let toColor = 'rgba(16, 156, 241, 0)';

    return {
      illTrend: {
        gradient: {
          fromColor,
          toColor
        },
        label: this.getIllLabel(graphType),
        strokeColor: toColor,
        borderWidth: 2,
        borderColor: borderIllColor
      },
      totalTrend: {
        fill: false,
        label: this.getTotalLabel(graphType),
        strokeColor: "#B7BCBE",
        borderWidth: 1,
        borderColor: "#B7BCBE"
      }
    }
  }

  getKpiLabel(graphType: KpiType) {
    let label = graphType.toUpperCase();
    return this.translate.instant("data.COMMON." + label);
  }

  private getTotalLabel(graphType: EntityType) {
    let labels = {
      [EntityType.TENANT]: 'TOTAL_TENANTS',
      [EntityType.VENUE]: 'TOTAL_VENUES',
      [EntityType.FABRIC]: 'TOTAL_FABRICS',
      [EntityType.DEVICE]: 'TOTAL_DEVICES',
    }
    return this.translate.instant("data.DASHBOARD." + labels[graphType]);
  }

  private getIllLabel(graphType: EntityType) {
    let labels = {
      [EntityType.TENANT]: 'ILL_TENANTS',
      [EntityType.VENUE]: 'ILL_VENUES',
      [EntityType.FABRIC]: 'ILL_FABRICS',
      [EntityType.DEVICE]: 'ILL_DEVICES',
    }
    return this.translate.instant("data.DASHBOARD." + labels[graphType]);
  }
}
