import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FabricToggleStatus} from "../../../../../shared/models/fabrics.model";

@Component({
  selector: 'app-venue-fabric-toggle',
  templateUrl: './venue-fabric-toggle.component.html',
  styleUrls: ['./venue-fabric-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VenueFabricToggleComponent implements OnInit {

  constructor() {}

  @Input() itemCounts: {all: number, complete: number, incomplete: number};
  @Input() isLoading: boolean = false;

  fabricToggles = FabricToggleStatus;
  @Output() toggleChange = new EventEmitter<FabricToggleStatus>();

  filterFabrics(status: FabricToggleStatus) {
    this.toggleChange.emit(status);
  }

  ngOnInit(): void {
  }

}
