import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {InsightsService} from 'src/app/shared/services/rest-services/insights.service';
import {take} from 'rxjs/operators';
import {NgChanges} from 'src/app/shared/extend-angular-classes/on-changes';
import {InsightsAnomaliesBarGeneratorService} from './services/insights-anomalies-bar-generator.service';
import {AnomaliesSizes} from 'src/app/shared/models/anomalies.model';
import {
  IndexedLabel,
  VerticalBarComponent
} from 'src/app/shared/components/chartjs-components/vertical-bar/vertical-bar.component';
import {
  AnomalyInsightsTrend,
  FromToDatesWithAnomalyTrend,
  TimeSeriesWrapper
} from 'src/app/shared/models/actions.model';
import {InsightsAnomaliesTimeGeneratorService} from '../insights-anomalies/services/insights-anomalies-time-generator.service';
import {FromToDatesStrings} from "../../../../models/time.model";

@Component({
  selector: 'app-insights-anomalies-bar',
  templateUrl: './insights-anomalies-bar.component.html',
  styleUrls: ['./insights-anomalies-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsAnomaliesBarComponent {
  @Input() dates: FromToDatesStrings;
  @Input() height: number;
  @Input() isObscurityMode: boolean = false;
  @Output() barSelected: EventEmitter<FromToDatesWithAnomalyTrend> = new EventEmitter();
  @Output() trendGenerated: EventEmitter<FromToDatesWithAnomalyTrend> = new EventEmitter();
  @Output() totalActionsCalculated: EventEmitter<number> = new EventEmitter();
  @ViewChild(VerticalBarComponent, {static: true}) verticalBarComponent: VerticalBarComponent;
  totalActionsNumber: number;
  highestActionsNumPerDay: number;
  verticalBarHeight: number;
  title: string = 'Anomalies Count';
  isLoading: boolean = false;
  verticalBarDatasets: VerticalBarData;
  trend: TimeSeriesWrapper<AnomalyInsightsTrend>;

  constructor(
    private cdr: ChangeDetectorRef,
    private insightsService: InsightsService,
    private datesGenerator: InsightsAnomaliesTimeGeneratorService,
    private barGenerator: InsightsAnomaliesBarGeneratorService) {
  }

  /**
   * 1. Change bar height when sizes param changes only
   * 2. Re generate bar datum if dates has changed
   * 2. Re generate bar datum if isObscurityMode has changed
   */
  ngOnChanges(changes: NgChanges<InsightsAnomaliesBarComponent>) {
    if (changes.height && changes.height.currentValue && !changes.height.previousValue && this.height) {
      this.verticalBarHeight = this.height;
    }
    if (this.dates && changes.dates) {
      this.insightsService.getInsightsAnomaliesTrend(this.dates).pipe(take(1)).subscribe(trend => {
        if (trend) {
          this.totalActionsNumber = this.barGenerator.getTotalActionsNumber(trend);
          this.highestActionsNumPerDay = this.barGenerator.getHighestActionsNumPerDay(trend);
          this.totalActionsCalculated.emit(this.totalActionsNumber);
          delete trend.data.Compliance;
          delete trend.data.Security;
          this.trend = trend;
          this.verticalBarDatasets = this.barGenerator.generateBarDataset(trend, this.totalActionsNumber);
          this.trendGenerated.emit({trend: this.trend, fromToDates: this.dates});
        }
        this.cdr.markForCheck();
      })
    }
    if (changes.isObscurityMode && changes.isObscurityMode.previousValue !== undefined) {
      if (!this.isObscurityMode && this.verticalBarComponent) {
        this.verticalBarDatasets = this.barGenerator.generateBarDataset(this.trend, this.totalActionsNumber);
        this.cdr.markForCheck();
      }
    }
  }

  /**
   * Emit the relevant date be current Granularity
   */
  onBarClicked(event: IndexedLabel) {
    let datesAsString = this.datesGenerator.generateRangeByGranularity(this.trend.data.Performance[event.index].date, this.trend.dataGranularity);
    this.barSelected.emit({trend: this.trend, fromToDates: datesAsString});
    this.cdr.markForCheck();
  }
}
