import { HealthStatus, HealthIndicator } from '../../models/health.model';

export const oneOfHealthRenderer = (healthStatus: HealthStatus) => {
    let illStatus = {
        cssClass: 'severity-low',
        value: 0
    };
    /**
     * Imry - 15.1.20:
     * By produce request: when there are no venues with medium or high health status - 
     * The number 0 should appear with the same color as the total number of venues
     */
    if (!healthStatus) {
        let illStatus = {
            cssClass: 'severity-zero',
            value: 0
        };
        return getCellHealthHTML(illStatus, { total: 0 })
    }

    if (healthStatus.medium + healthStatus.high == 0) {
        illStatus = {
            cssClass: 'severity-zero',
            value: 0
        }
    }

    if (healthStatus.high) {
        illStatus = {
            cssClass: 'severity-high',
            value: healthStatus.high
        }
    } else if (healthStatus.medium) {
        illStatus = {
            cssClass: 'severity-medium',
            value: healthStatus.medium
        };
    }
    return getCellHealthHTML(illStatus, healthStatus)
}
function getCellHealthHTML(illStatus: { cssClass: string, value: number }, healthStatus: { total: number }) {
    return `<span class="severity"><span class="${illStatus.cssClass}" >${illStatus.value}</span>/${healthStatus.total}</span>`;
}
function isSevereRow(rowParams) {
    if (!rowParams.data) {
        return false;
    }
    var severeIndicator: HealthIndicator = rowParams.data.statusIndicator;
    return severeIndicator == HealthIndicator.MOST_SEVERE;
}

export const dashboardRowClassRules = {
    "ag-grid-dashboard-row": () => true,
    "ag-grid-severe-row": (params) => {
        //debugger
        return isSevereRow(params)
    },
    "ag-grid-non-severe-row": (params) => !isSevereRow(params),
    "ag-dashboard-grid-row-with-hover": (params) => {
        // no hover for row if there is either cell hover enabled or row hover explicitly disabled
        return !(params.context && params.context.enableCellHover) &&
            (!params.context || !params.context.disableRowHover)
    },
    "ag-dashboard-grid-cell-hover-row": (params) => {
        // 
        return params.context && params.context.enableCellHover
    },
};