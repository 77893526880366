import { InjectionToken } from '@angular/core';

export type ThresholdSeverity = { high: number, critical: number };
export type LossLatencyThreshold = { loss: ThresholdSeverity, latency: ThresholdSeverity };
export const lossLatencyThreshold: LossLatencyThreshold = {
    loss: {
        high: 20,
        critical: 60
    },
    latency: {
        high: 150,
        critical: 300
    }
}

export const LOSS_LATENCY_THRESHOLDS = new InjectionToken<string>('Loss and Latency threshold');