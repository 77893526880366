<div [@hoverTooltip]="animationTrigger">
    <app-topology-tooltip *ngIf="node" [nodeEvent]="node" (clickOnTitle)="goToClients($event)" [title]="title"
        [tplgRect]="tplgRect" (isHover)="isHoverTooltip($event)" [tooltipType]="tooltipType">
        <ng-container>
            <div class="multi-clients-rows-container">
                <app-rssi-bar [iconsData]="iconsData"></app-rssi-bar>
                <app-multi-clients-table [clientsTable]="clientsTable"></app-multi-clients-table>
            </div>
        </ng-container>
    </app-topology-tooltip>
</div>