import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {mergeMap, take, switchMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {Store} from '@ngrx/store';
import {IAppState} from 'src/app/store/state/app.state';
import {GlobalEntitiesService} from './global-entities.service';
import {Topology} from '../../models/topology';
import {BasicDevice, SingleDevice} from '../../models/single-device.model';
import {
  DeviceBackupDTO,
  DeviceBackupPeriodicDTO,
  DeviceBackupOnceDTO,
  DeviceBackupNowDTO,
  BackupSupport,
  DeviceBackupHistoryDTO
} from '../../models/device-backup.model';
import {Page} from '../../models/page.model';
import {BaseSortFilter} from '../../models/sort-filter/base-sort-filter.model';
import {SingleLink} from '../../models/single-link.model';

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store);
  }

  getDeviceMetaData(venueId: number, deviceId: number): Observable<SingleDevice> {
    return this.http.get<SingleDevice>(`venues/${venueId}/devices/${deviceId}`);
  }

  getCurrentDeviceMetaData(): Observable<SingleDevice> {
    return combineLatest([this.currentDevice$, this.closestVenue$]).pipe(
      mergeMap(([currentDevice, closestVenue]) => this.getDeviceMetaData(closestVenue.id, currentDevice.id))
    );
  }

  getDeviceVenueTopology(): Observable<Topology<SingleDevice, SingleLink>> {
    return this.closestVenue$.pipe(
      // Note that since closestVenue$ is using distinctUntilChanged it will be unique
      // per subscription. This to change UI only when closestVenue$ changes we need
      // to use the same subscription and take(1) will break it - commenting it out
      //take(1),
      switchMap((venue) => {
        // console.log("venue %o ", venue)
        return this.http.get<Topology<SingleDevice, SingleLink>>(`venues/${venue.id}/topology/`);
      })
    );
  }

  getDeviceById(deviceId: number): Observable<SingleDevice> {
    return this.closestVenue$.pipe(
      take(1),
      mergeMap((closestVenue) => this.http.get<SingleDevice>(`venues/${closestVenue.id}/devices/${deviceId}`)));
  }

  getVenueDevices(): Observable<BasicDevice[]> {
    return this.closestVenue$.pipe(
      take(1),
      mergeMap((closestVenue) => this.http.get<BasicDevice[]>(`venues/${closestVenue.id}/devices`)));
  }

  /**
   * Get the data for the maintenance grid
   */
  getAllDevicesBackupHistory(sortAndFilter: BaseSortFilter, backupSupport: BackupSupport): Observable<Page<DeviceBackupDTO>> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<Page<DeviceBackupDTO>>(sortAndFilter.appendToURL(`devices/deviceBackup/tenant/${tenantID}?backupSupported=${backupSupport}`))));
  }

  getDeviceBackupHistory(deviceID: number): Observable<DeviceBackupHistoryDTO[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<DeviceBackupHistoryDTO[]>(`devices/deviceBackup/tenant/${tenantID}/device/${deviceID}/deviceBackupHistory`)));
  }

  setDeviceBackupPeriodic(selectedDevices: DeviceBackupPeriodicDTO): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<any>(`devices/deviceBackup/tenant/${tenantID}/backupPeriodic`, selectedDevices)));
  }


  setDeviceBackupLater(selectedDevices: DeviceBackupOnceDTO): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<any>(`devices/deviceBackup/tenant/${tenantID}/backupOnce`, selectedDevices)));
  }

  setDeviceBackupNow(selectedDevices: DeviceBackupNowDTO): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<any>(`devices/deviceBackup/tenant/${tenantID}/backupNow`, selectedDevices)));
  }
}
