import { Component, OnInit, ChangeDetectorRef, ViewContainerRef, Input, ComponentFactoryResolver, Type, ComponentRef, ChangeDetectionStrategy, ViewChild, ContentChild, HostBinding, ElementRef, Renderer2, HostListener, QueryList, ContentChildren, Optional } from '@angular/core';
import { Rect } from '../services/rect.class';
import { PortletHeaderComponent } from '../portlet-header/portlet-header.component';
import { PortalSizeService } from '../services/portal-size.service';
import { PortletSizeState } from '../portlet-size-state.enum';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { PortletResizeService } from '../services/portlet-resize.service';
import { Subscription } from 'rxjs';
import { PortletSidebarComponent } from '../portlet-sidebar/portlet-sidebar.component';

@Component({
  selector: 'app-portlet',
  templateUrl: './portlet.component.html',
  styleUrls: ['./portlet.component.scss'],
  animations: [
    trigger('fullSize', [
      state('false', style({
        display: "*",
        width: "{{normalWidth}}px",
        height: "{{normalHeight}}px",
        top: "{{normalTop}}px",
        left: "{{normalLeft}}px",
        overflow: "*"
      }), {
          params: {
            normalWidth: '*',
            normalHeight: '*',
            normalTop: '*',
            normalLeft: '*'
          }
        }),
      state('true', style({
        display: "block",
        width: "{{fullWidth}}px",
        height: "{{fullHeight}}px",
        top: "{{fullTop}}px",
        left: "{{fullLeft}}px",
        overflow: "auto"
      }), {
          params: {
            fullWidth: '100%',
            fullHeight: '100%',
            fullTop: '0',
            fullLeft: '0'
          }
        }),
      transition('false <=> true', animate(200))
    ])
  ],
  providers: [PortletResizeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortletComponent implements OnInit {
  // portlet root classes
  @Input() class: string;
  classes: string = 'kt-portlet dashboard-portlet';

  private portletSizeState: PortletSizeState;

  // portlet header component template
  @ContentChild(PortletHeaderComponent, { static: true }) header: PortletHeaderComponent;
  @ContentChildren(PortletSidebarComponent) sidebar: QueryList<PortletHeaderComponent>;
  normalSize: Rect = new Rect(0, 0, 0, 0);
  maximizedSize: Rect = new Rect(0, 0, 0, 0);
  resizeSubscription: Subscription;
  haveSideBar: boolean;
  // navigationState = this.appStore.pipe(select(selectNavigationState));
  // private storeSubscription: Subscription;


  constructor(
    // TODO: this probably should not be optional
    private portalSizeService: PortalSizeService,
    private portletResizeService: PortletResizeService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // TODO: Consider subscribing to the service only if the portlet is in full size
    if (this.portalSizeService) {
      this.resizeSubscription = this.portalSizeService.portalResize$.subscribe(() => {
        //console.log('resized observable triggered in service')
        this.resizeToPortal();
      });
    }
  }
  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    // append custom class
    this.classes += this.class ? ' ' + this.class : '';

    let rect = (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect();
    this.normalSize = new Rect(rect.left, rect.top, rect.width, rect.height);
    this.haveSideBar = this.sidebar && this.sidebar.length > 0;
    if (this.haveSideBar) {
      this.changeDetectorRef.detectChanges()
    }
  }
  get fullSize() {
    return this.portletSizeState == PortletSizeState.Maximized;
  }
  /**
   * Preset the positioning and size of the portlet before animation
   * @private
   * @param $event 
   */
  fullSizeStart($event) {
    let rect = this.getPortletElement().getBoundingClientRect();
    let cssStyle = ['width', 'top', 'height', 'left'];
    //let portletElement = this.getPortletElement();
    //console.log('portlet element %o', portletElement)
    this.setStyleFromObject(cssStyle, rect)
    this.setStyle({ position: "fixed", "z-index": "90" });
  }
  private setInitialStyle(styles: string[]) {
    // console.log(`setting initial styles %o `, styles)
    styles.forEach((style) => {
      this.renderer.setStyle(
        this.getPortletElement(),
        style,
        'initial'
      );
    })
  }
  private setStyle(cssStyles: { [key: string]: string }) {
    for (let style in cssStyles) {
      // console.log(`setting style ${style} to ${cssStyles[style]} `)
      this.renderer.setStyle(
        this.getPortletElement(),
        style,
        cssStyles[style]
      );
    }
  }
  private setStyleFromObject(styles: string[], cssStyles: any) {
    styles.forEach((style) => {
      // console.log(`setting style from object ${style} to ${cssStyles[style]} `)
      // console.log('portlet element %o', this.getPortletElement())
      this.renderer.setStyle(
        this.getPortletElement(),
        style,
        cssStyles[style]
      );
    })
  }
  /**
   * Reset portlet styles size was reset 
   * Fire event on resize
   * @private
   * @param $event 
   */
  fullSizeDone($event) {
    // unmaximize the
    // console.log('in fullSizeDone')
    if (!$event.toState) {
      // going to false maximize i.e. normal size state
      //console.log('setting initial styles')
      //let portletElement = this.getPortletElement();
      //console.log('portlet element %o', portletElement)
      setTimeout(() => {
        this.fireResize();
        this.setStyle({ height: "100%" });
        this.setInitialStyle(["width", "top", 'left', 'z-index'])
      });
      this.setStyle({ position: "static" });
    } else {
      this.fireResize();
    }
  }
  private fireResize() {
    let rect = this.getPortletElement().getBoundingClientRect();
    // TODO: change to fire with the rect of the body only
    this.portletResizeService.portletResized(new Rect(rect.left, rect.top, rect.width, rect.height));
  }
  private getPortletElement() {
    return (<HTMLElement>this.elementRef.nativeElement).querySelector('.kt-portlet');
  }

  ngAfterContentInit() {
    if (this.header && this.portletSizeState != PortletSizeState.Maximized) {
      this.header.maximizeEvent.subscribe(() => {
        this.maximize()
      })
    }
    if (this.header && this.portletSizeState != PortletSizeState.Normal) {
      this.header.restoreSizeEvent.subscribe(() => {
        this.restoreSize()
      })
    }
  }
  /**
   * Maximize the portlet
   * @private
   */
  maximize() {
    let rect = this.getPortletElement().getBoundingClientRect();
    this.normalSize = new Rect(rect.left, rect.top, rect.width, rect.height);
    // getBoundingClientRect();
    this.maximizedSize = this.portalSizeService.getPortalSize();
    //console.log('maximize fired to rect %o from rect %o', this.maximizedSize, this.normalSize);
    this.setPortletSizeState(PortletSizeState.Maximized);
  }
  /**
   * Restore portlet size
   * @private
   */
  restoreSize() {
    this.setPortletSizeState(PortletSizeState.Normal);
  }
  @HostListener('window:resize', ['$event'])
  @HostListener('document:scroll', ['$event'])
  onResize() {
    this.resizeToPortal();
  }

  private resizeToPortal() {
    if (!this.fullSize) {
      return;
    }
    let cssStyle = ['width', 'top', 'height', 'left'];
    //let portletElement = this.getPortletElement();
    this.maximizedSize = this.portalSizeService.getPortalSize();
    if (!this.maximizedSize) {
      // Can be called even before portal initialization due to the 
      // DOM listeners
      return;
    }
    this.setStyleFromObject(cssStyle, this.maximizedSize.formatPX())
    this.fireResize();
  }
  private setPortletSizeState(portletSizeState: PortletSizeState) {
    this.portletSizeState = portletSizeState;
    if (this.header)
      this.header.setPortletSizeStateToolBar(portletSizeState)
    this.changeDetectorRef.markForCheck()
  }
}
