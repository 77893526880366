<mat-accordion>
  <ng-container *ngFor="let property of accordionObj | keyvalue; let i = index">
    <div class="accordion-expans-panel" [classByCondition]="panelsOpenState[i]"
         [cssClasses]="['accordion-expans-panel-open']">
      <mat-expansion-panel [expanded]="i==0? true: false" (opened)="panelsOpenState[i] = true"
                           (afterCollapse)="panelsOpenState[i] = false">
        <mat-expansion-panel-header [collapsedHeight]="'26px'" [expandedHeight]="'26px'">
          <mat-panel-title>
            {{property.key | camelToUpper |removeObjectMarkers}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let item of property.value | keyvalue; let i = index">
          <div *ngIf="item?.value && item?.value !== ' ' && item?.value !== -1" class="accordion-row"
               [classByCondition]="item?.value == -1 && i < property.value.length -1"
               [cssClasses]="['accordion-row-marker']">
            <div class="col accordion-text-key">
              <p>{{item.key| camelToUpper |removeIndex}}</p>
            </div>
            <div class="col justify-end-column">
              <p class="accordion-text-value" [title]="item.value">{{item.value ? item.value : 'null'}}
              </p>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </div>
  </ng-container>
</mat-accordion>
