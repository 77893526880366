import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrgTypeWithAction } from '../../models/organizations-type.model';
import { OrganizationTypesManagerComponent } from 'src/app/organizations/settings/components/organization-types-manager/organization-types-manager.component';
import { OrganizationDetails } from '../../models/organizations.model';

@Component({
  selector: 'app-add-org-type-dialog',
  templateUrl: './add-org-type-dialog.component.html',
  styleUrls: ['./add-org-type-dialog.component.scss']
})
export class AddOrgTypeDialogComponent implements OnInit {
  @ViewChild(OrganizationTypesManagerComponent, { static: true }) typesManager: OrganizationTypesManagerComponent;

  constructor(
    private matDialogRef: MatDialogRef<AddOrgTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { typeData: OrgTypeWithAction, orgData: any }) {
  }

  ngOnInit() {
    if (this.typesManager)
      this.typesManager.onCrudSelection(this.dialogData.typeData);
  }
  closeDialog(isAdded: boolean) {
    this.matDialogRef.close(isAdded)
  }
}
