import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-spinner',
  template: `
  <mat-spinner></mat-spinner>
  `
})
export class PageSpinnerComponent implements OnInit {
  ngOnInit() {}
}
