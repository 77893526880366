import { Component, OnInit, Input } from '@angular/core';
import { GenericDevice } from 'src/app/shared/models/topology';
import { SingleDevice, SingleDeviceType } from 'src/app/shared/models/single-device.model';
import { D3TreeEventsService } from '../../../services/d3-tree-events.service';
import { D3NodeService } from '../../../services/d3-node.service';
import { HealthColorsService } from 'src/app/shared/services/strategies/health-colors.service';
import { DisconnectedDevicesStoreService } from '../../../services/disconnected-devices-store.service';
import { isDisconnected } from '../../../operators/topology-operators';

@Component({
  selector: 'app-single-disconnected-device',
  templateUrl: './single-disconnected-device.component.html',
  styleUrls: ['./single-disconnected-device.component.scss']
})
export class SingleDisconnectedDeviceComponent implements OnInit {
  @Input() device: GenericDevice<SingleDevice>;
  @Input() isFabricsVenue: boolean;
  @Input() isDevice: boolean;
  deviceClicked: boolean = false;

  constructor(
    private d3TreeEventsService: D3TreeEventsService,
    private d3NodeService: D3NodeService,
    private healthColorsService: HealthColorsService,
    private disconnectedDeviceStoreService: DisconnectedDevicesStoreService) { }

  ngOnInit() {
    this.subscribeToTreeSelectionMode();
  }
  /**
   * @method subscribeToTreeSelectionMode subscribe to both node and link selections, and report if ther is element
   * selection, in order to canel the disconnected device selection
   */
  subscribeToTreeSelectionMode() {
    this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(clickedNode => {
      if (!isDisconnected(clickedNode) || (clickedNode as GenericDevice<SingleDevice>) && (clickedNode as GenericDevice<SingleDevice>).id !== this.device.id)
        this.deviceClicked = false;
      else
        this.deviceClicked = true;
    });
  }
  onDisconnectedClicked() {
    if (!this.isFabricsVenue && !this.isDevice) {
      this.device.iconPath = this.d3NodeService.getClickedIconPath(this.device.type);
      this.d3TreeEventsService.onDisconnectedDeviceClick(this.device);
    }
  }

  get iconPath() {
    if (this.device)
      return this.deviceClicked ?
        this.d3NodeService.getClickedIconPath(this.device.type) :
        this.d3NodeService.getInitialIconPath(this.device.type)
  }

  get ellipsePath() {
    if (this.deviceClicked)
      return '/assets/media/netop/topology/clicked-small-ellipse.svg';
    return '/assets/media/netop/topology/small-ellipse.svg';
  }

  get iconClass() {
    if (this.isCloud)
      return 'dis-www-icon';
  }

  get isCloud() {
    if (this.device)
      return this.device.type == SingleDeviceType.WWW;
  }

  get dotPath() {
    if (this.device)
      return this.healthColorsService.getDotColor(+this.device.originalData.health);
  }

  get labelColor() {
    if (this.deviceClicked)
      return '#3794FC';
  }

  ngOnDestroy() {
    this.disconnectedDeviceStoreService.resetSelectedDisconnectedDevice();
  }
}
