export const CRON_SELECTION_OPTIONS = {
    months: getCronUtils(1, 12),
    monthWeeks: ['#1', '#2', '#3', '#4', '#5', 'L'],
    days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
    monthDays: getCronUtils(1, 31),
    monthDaysWithLasts: [...getCronUtils(1, 31).map(String), 'L']
};

export function getCronUtils(startFrom: number, until: number) {
    return Array.from({ length: (until + 1 - startFrom) }, (_, k) => k + startFrom);

}