import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastChars'
})
export class LastCharsPipe implements PipeTransform {

  transform(value: string, numberOfChars: number): any {
    if (value && value.length >= 2) {
      // 2. Start with a blank str ing
      let newString = `${value[0]}${value[1]}***`;

      // 3. Add in the last n characters from original string.
      newString += value.substring(value.length - numberOfChars);
      return newString;
    }
    return '';
  }

}
