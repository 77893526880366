<app-list-view *ngIf="listItems"
               [items]="listItems"
               (itemSelect)="onCategorySelected($event)">
  <ng-container *ngIf="currentAnomalySettings$">
    <ng-container *ngFor="let setting of currentAnomalySettings$  | async">
      <app-anomaly-setting [setting]="setting" (anomalySettingChanged)="onSettingChanged($event)">
      </app-anomaly-setting>
    </ng-container>
  </ng-container>
</app-list-view>
