import { Component, ContentChild, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { EditableKey } from './models/editable-text.model';

@Component({
  selector: 'app-legacy-editable-text',
  templateUrl: './legacy-editable-text.component.html',
  styleUrls: ['./legacy-editable-text.component.scss']
})
export class LegacyEditableTextComponent {
  @Input() editableKey: EditableKey;
  @Input() isEditMode: boolean;
  @Input() key: string;
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective, { static: true }) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective, { static: true }) editModeTpl: EditModeDirective;

  mode: 'view' | 'edit' = 'view';

  constructor() {
  }

  get currentView() {
    if (this.viewModeTpl && this.editModeTpl)
      return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }


  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.isEditMode || (this.editableKey && this.editableKey.key === this.key && this.editableKey.isEditable))
      this.mode = 'edit';
    else
      this.mode = 'view';

  }
}
