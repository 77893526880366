import {Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import interact from "interactjs";
import {NgChanges} from "../extend-angular-classes/on-changes";

@Directive({
  selector: '[resizable]'
})
export class ResizableDirective implements OnInit, OnChanges {
  @Input() resizableClass: string = 'resizable';
  @Input() resizableEdges: ResizableEdge[] = ['left', 'right', 'top', 'bottom'];
  @Input() resizableDisabled: boolean = false;
  @Output() resized = new EventEmitter<ResizedEvent>();

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.classList.add(this.resizableClass);
    this.interact();
  }

  ngOnChanges(changes: NgChanges<ResizableDirective>) {
    if (changes.resizableDisabled) {
      this.interact();
    }
  }

  private interact() {
    if (!this.resizableDisabled) {
      this.setInteraction();
    }
    else {
      this.unsetInteraction();
    }
  }

  private setInteraction() {
    const self = this;
    interact(`.${this.resizableClass}`).resizable({
      edges: {
        left: this.resizableEdges.includes('left'),
        right: this.resizableEdges.includes('right'),
        top: this.resizableEdges.includes('top'),
        bottom: this.resizableEdges.includes('bottom')
      },
      listeners: {
        move: function (event) {
          let {x, y} = event.target.dataset;
          x = (parseFloat(x) || 0) + event.deltaRect.left;
          y = (parseFloat(y) || 0) + event.deltaRect.top;

          const style = {};
          if (self.resizableEdges.includes('left') || self.resizableEdges.includes('right')) {
            style['width'] = `${event.rect.width}px`;
          }
          if (self.resizableEdges.includes('top') || self.resizableEdges.includes('bottom')) {
            style['height'] = `${event.rect.height}px`;
          }

          Object.assign(event.target.style, style);
          Object.assign(event.target.dataset, {x, y});
        },
        end: function (event) {
          self.resized.emit({
            width: event.rect.width,
            height: event.rect.height
          });
        }
      }
    });
  }

  private unsetInteraction() {
    interact(`.${this.resizableClass}`).unset();
  }
}

export interface ResizedEvent {
  width: number;
  height: number;
}

export type ResizableEdge = 'left' | 'right' | 'top' | 'bottom';
