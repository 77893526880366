import {InjectionToken} from "@angular/core";
import {ChartJsAnnotation} from "./annotations.model";
import {TimeUtils} from "../../../global-utils/time-utils";
import {TimeApi, TimeUnit, UserTimeRangesSelection} from "../../../models/time.model";
import {DefaultChartDates} from "./chart-js-time-unit";

export const HORIZONTAL_ANNOTATION: ChartJsAnnotation = {
  drawTime: 'afterDraw', // overrides annotation.drawTime if set
  display: true,
  type: 'line',
  mode: 'horizontal',
  scaleID: 'y',
  value: 125,
  borderColor: 'red',
  borderWidth: 0.75,
  label: {
    // Background color of label, default below
    backgroundColor: 'red',

    font: {
      size: 10,
      family: 'rubik, arial, sans-serif',
      style: 'bold'
    },

    // Font color of text, default below
    color: "#fff",

    // Padding of label to add left/right, default below
    xPadding: 3,

    // Padding of label to add top/bottom, default below
    yPadding: 3,

    // Radius of label rectangle, default below
    cornerRadius: 3,

    // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
    position: "center",

    // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
    // For horizontal lines positioned left or right, negative values move
    // the label toward the edge, and positive values toward the center.
    xAdjust: 0,

    // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
    // For vertical lines positioned top or bottom, negative values move
    // the label toward the edge, and positive values toward the center.
    yAdjust: 0,

    // Whether the label is enabled and should be displayed
    enabled: true,

    // Text to display in label - default is null
    content: null,

    height: 1,
    width: '100%'
  },
}
export const HORIZONTAL_CHART_JS_ANNOTATION = new InjectionToken<string>('Horizontal chart-js annotation');

export const TODAY_TEXT = `Now-${new Date().getHours()}:${new Date().getMinutes()}`;
export const VERTICAL_TODAY_ANNOTATION = {
  drawTime: 'afterDatasetsDraw',
  type: 'line',
  scaleID: 'x',
  mode: "vertical",
  value: new Date(),
  // yMin: 0,
  // yMax: 0,
  // xMin: new Date(),
  // xMax: new Date(),
  borderColor: '#000000',
  borderWidth: 0.75,
  label: {
    // Background color of label, default below
    backgroundColor: '#000000',

    font: {
      size: 16,
      family: 'rubik, arial, sans-serif',
      style: 'bold'
    },

    // Font color of text, default below
    color: "#fff",
    content: TODAY_TEXT,

    // Padding of label to add left/right, default below
    xPadding: 5,

    // Padding of label to add top/bottom, default below
    yPadding: 5,

    // Radius of label rectangle, default below
    cornerRadius: 5,

    // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
    position: "start",

    // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
    // For horizontal lines positioned left or right, negative values move
    // the label toward the edge, and positive values toward the center.
    xAdjust: 0,

    // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
    // For vertical lines positioned top or bottom, negative values move
    // the label toward the edge, and positive values toward the center.
    yAdjust: 0,

    // Whether the label is enabled and should be displayed
    enabled: true,

    height: 1,
    width: '100%'
  }
}
export const TODAY_VERTICAL_JS_ANNOTATION = new InjectionToken<string>('Today chart-js annotation');

export const DEFAULT_TODAY_DATES: DefaultChartDates = {
  defaultDay: TimeUtils.roundDateMinutes(new Date()),
  defaultHour: `${TimeUtils.roundDateMinutes(new Date()).toTimeString().substring(0, 5)}`,
  timeApi: {
    specificDate: TimeUtils.roundDateMinutes(new Date()).toISOString(),
    timeBack: 4,
    timeUnit: TimeUnit.HOURS
  },
  kpiTimeRanges: [{
    range: UserTimeRangesSelection.FOUR_HOURS,
    value: 4
  },
    {
      range: UserTimeRangesSelection.DAY,
      value: 2
    },
    {
      range: UserTimeRangesSelection.MONTH,
      value: 1
    }
  ]
}
export const CHART_JS_DEFAULT_TODAY_DATES = new InjectionToken<DefaultChartDates>('Default Today dates');

