<app-netop-dialog>
  <app-netop-dialog-title [cssClass]="'single-entity-action-dialog-title-text'" (close)="closeDialog()">
    {{dialogTitle}}
  </app-netop-dialog-title>
  <app-netop-dialog-content class="no-overflow">
    <div class="assignment-dialog-container-boxes row">
      <div class="assignment-dialog-container-box col-5">
        <div class="container">
          <h5 class="assignment-dialog-container-box-heading security-rule-dashboard-header-text">{{assignedHeading}}</h5>
          <app-search-menu-box [display]="assigned" [(selectedOption)]="assignedSelected" [dragAndDrop]="true"
                               dropListId="assigned-drop-list" [dropListConnectedTo]="['unassigned-drop-list']"
                               height="44vh" [style.width.%]="100"></app-search-menu-box>
        </div>
      </div>
      <div class="assignment-dialog-container-arrows col-2">
        <div class="container">
          <mat-icon [inlineSVG]="leftArrowIcon" class="assignment-dialog-container-arrow arrow-hover-blue mat-icon mb-3"
                    (click)="onLeftArrow()"></mat-icon>
          <mat-icon [inlineSVG]="rightArrowIcon" class="assignment-dialog-container-arrow arrow-hover-blue mat-icon"
                    (click)="onRightArrow()"></mat-icon>
        </div>
      </div>
      <div class="assignment-dialog-container-box col-5">
        <div class="container">
          <h5 class="assignment-dialog-container-box-heading security-rule-dashboard-header-text">{{unassignedHeading}}</h5>
          <app-search-menu-box [display]="unassigned" [(selectedOption)]="unassignedSelected" [dragAndDrop]="true"
                               dropListId="unassigned-drop-list" [dropListConnectedTo]="['assigned-drop-list']"
                               height="44vh" [style.width.%]="100"></app-search-menu-box>
        </div>
      </div>
    </div>
  </app-netop-dialog-content>
</app-netop-dialog>
