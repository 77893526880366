import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumUpperToLower'
})
export class EnumUpperToLowerPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      let formatted: string[] = value.split('_');
      let final: string = "";
      formatted.forEach(word => {
        if (word)
          final += word[0].toUpperCase() + word.slice(1, word.length).toLowerCase() + " ";
      })
      return final;
    }
    return null;
  }

}
