<div class="row prop-title-row">
  <div class="col">
    <span class="">{{title}}</span>
  </div>
  <div class="col justify-end-column">
    <mat-icon (click)="emitCloseIconClicked()">close</mat-icon>
  </div>
</div>
<mat-divider></mat-divider>



