import {Pipe, PipeTransform} from '@angular/core';
import {AnomalyCategory} from "../../../../models/anomalies.model";

@Pipe({
  name: 'usersToClients'
})
export class UsersToClientsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value === AnomalyCategory.UsersNumber) {
      return 'Clients Number'
    }
    return value;
  }

}
