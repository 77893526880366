import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {VerticalMenuOptionsModel} from "./vertical-menu-options.model";

@Component({
  selector: 'app-vertical-menu-options',
  templateUrl: './vertical-menu-options.component.html',
  styleUrls: ['./vertical-menu-options.component.scss']
})
export class VerticalMenuOptionsComponent<T> implements OnInit {

  @Input() items: Array<VerticalMenuOptionsModel<T>>;
  @Input() ariaLabel: string;
  @Output() itemClicked = new EventEmitter<T>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(item: T) {
    this.itemClicked.emit(item);
  }

}
