<div class="dev-rep-bars-container">
    <div class="dev-rep-top-container">
        <h5 class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_BY_TRAFFIC' | translate}}
            {{topTrafficVenues$ | async | totalFromTop}}</h5>
        <div class="dev-rep-progress-list">
            <ng-container
                *ngFor="let progressBar of topTrafficVenues$ | async | progressBarConvertor : fullColorsList[2]">
                <div class="just-flex dev-rep-dialog-progress-bar-container">
                    <app-basic-progress-bar [progressBar]="progressBar"
                        (click)="onBarSelected(progressBar.originalData)">
                    </app-basic-progress-bar>
                </div>
            </ng-container>
            <ng-container *ngIf="!(topTrafficVenues$ | async | progressBarConvertor)">
                <div class="dev-rep-error-message-text">
                    {{'data.ERRORS.NO_DATA' | translate}}
                </div>
            </ng-container>
        </div>
    </div>
    <div class="dev-rep-top-container">
        <h5 class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_BY_USERS' | translate}}
            {{topUsersVenues$ | async | totalFromTop}}</h5>
        <div class="dev-rep-progress-list">
            <ng-container
                *ngFor="let progressBar of topUsersVenues$ | async | progressBarConvertor : fullColorsList[3]">
                <div class="just-flex dev-rep-dialog-progress-bar-container">
                    <app-basic-progress-bar [progressBar]="progressBar"
                        (click)="onBarSelected(progressBar.originalData)">
                    </app-basic-progress-bar>
                </div>
            </ng-container>
            <ng-container *ngIf="!(topUsersVenues$ | async | progressBarConvertor)">
                <div class="dev-rep-error-message-text">
                    {{'data.ERRORS.NO_DATA' | translate}}
                </div>
            </ng-container>
        </div>
    </div>
</div>