import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IDatasource, IGetRowsParams } from "ag-grid-community";
import { take, catchError, of, Subscription } from "rxjs";
import { ActionsMenuRendererComponent } from "src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/actions-menu-renderer.component";
import { dashboardRowClassRules } from "src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers";
import fabricsStatusRenderer from "src/app/shared/ag-grid/cell-renderers/fabric-status-renderer/fabric-status-renderer";
import { HealthCellRendererComponent } from "src/app/shared/ag-grid/cell-renderers/health-cell-renderer/health-cell-renderer.component";
import { SeverityCellRendererComponent } from "src/app/shared/ag-grid/cell-renderers/severity-cell-renderer/severity-cell-renderer.component";
import { BaseDashboardEntityList } from "src/app/shared/components/entity-dashboard/entity-list/base-dashboard-entity-list";
import { GridTypes } from "src/app/shared/models/client-storage.model";
import { LegacySeverity } from "src/app/shared/models/severity.model";
import { GridSortFilter } from "src/app/shared/models/sort-filter/grid-sort-filter.model";
import { StoreUserPreferencesService } from "src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service";
import { StoreWidgetPreferencesService } from "src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service";
import { Logger, LoggerService } from "src/app/shared/services/logger.service";
import { GlobalEntitiesService } from "src/app/shared/services/rest-services/global-entities.service";
import { TenantsService } from "src/app/shared/services/rest-services/tenants.service";
import { SeverityService } from "src/app/shared/services/strategies/severity.service";

@Component({
  selector: 'app-fabrics-health-list',
  templateUrl: './fabrics-health-list.component.html',
  styleUrls: ['./fabrics-health-list.component.scss']
})
export class FabricsHealthListComponent extends BaseDashboardEntityList implements OnInit {
  private logger: Logger; components; columnDefs;
  dataSource: IDatasource = {
    //rowCount: null,
    getRows: (params: IGetRowsParams) => {
      // TODO;
      // Use startRow and endRow for sending pagination to Backend
      // params.startRow : Start Page
      // params.endRow : End Page
      // use this.searchText to filter the data source
      this.tenantsService.fetchFabricsHealth(new GridSortFilter(params, this.searchText, this.numberOfRawsInPage)).pipe(
        take(1),
        catchError(error => {
          this.isLoading = false;
          return of(null);
        })
      ).subscribe(data => {
        this.isLoading = false;
        this.loadData(this.logger, params, data)
      }, () => params.failCallback());
    }
  };
  tenantIdSubscr: Subscription;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantsService: TenantsService,
    private loggerFactory: LoggerService,
    private severityService: SeverityService,
    private translate: TranslateService,
    private globalEntitiesService: GlobalEntitiesService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
  ) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, GridTypes.FABRICS_HEALTH);
    this.logger = this.loggerFactory.getLogger('FabricsHealthListComponent');
    // Query server on search changed
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`);
      this.searchText = searchText;
      // Call your function which calls API or do anything you would like do after a lag of 1 sec
      this.gridApi.setDatasource(this.dataSource)
    });
    this.tenantIdSubscr = this.globalEntitiesService.tenantId$.subscribe((entity) => {
      this.logger.debug("FabricsHealthListComponent current entity changed")
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource)
      }
    })
    this.context = { componentParent: this, enableCellHover: true };
    this.components = {
      severityCellRenderer: SeverityCellRendererComponent,
      actionsMenuRenderer: ActionsMenuRendererComponent,
      healthCellRenderer: HealthCellRendererComponent
    };

  }
  /**
   * Click on tenant will take us to the tenant page
   */
  gotToFabric(event: any) {
    if (event.type === 'rowClicked') {
      // TODO: do something
      this.logger.debug(`fabric row clicked`)
      this.tenantsService.tenantId$.pipe(take(1)).subscribe(
        (tenantId) => {
          this.router.navigate(['/tenant', tenantId, 'fabric', event?.data?.id ? event.data.id : ''], { relativeTo: this.activatedRoute }).catch();
        }
      )
    }
  }

  ngOnInit() {

    let columnDefs = [
      {
        /**
         * Severity column
         * The severity column header is needed to show the sort arrow
         * the text is hidden by transparent color.
         */
        headerName: 'Severity',
        //headerClass: 'ag-grid-hidden-header',
        width: 60,
        // Keep the field name as it is usefull in sorting i.e. it sets
        // in the datasource -> sortModel object the colId property which
        // usefull to be passed to the server side.
        field: "severity",
        // Show severity icon using renderer
        cellRenderer: "severityCellRenderer",
        sortable: true,
        sort: "asc",
        cellRendererParams: {
          severityStrategy: (rowData: any) => {
            if (!rowData) {
              return LegacySeverity.Minor
            }
            return this.severityService.serverityByHealth(rowData.fabricHealth)
          }
        },
      },
      {
        headerName: this.translate.instant('data.COMMON.FABRIC'),
        field: 'fabricName',
        width: 130,
        // valueFormatter: data => {
        //   return data.value ? this.momentDatePipe.transform(data.value) : '';
        // },
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.HEALTH'),
        field: 'fabricHealth',
        width: 70,
        cellRenderer: "healthCellRenderer",
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.FABRIC_STATE'),
        field: 'fabricState',
        width: 50,
        sortable: true,
        cellRenderer: (row) => fabricsStatusRenderer(row, this.translate)
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.PARENT_VENUE'),
        field: 'parentVenueName',
        width: 100,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.NUM_DEVICES'),
        field: 'numDevices',
        width: 40,
        sortable: true,
      }
    ];
    this.columnDefs = columnDefs;
    this.rowClassRules = dashboardRowClassRules;


    this.logger.debug("in ngOnInit of TenantVenuesListComponent")
  }
  ngOnDestroy(): void {
    this.tenantIdSubscr.unsubscribe()
    this.cancelSubscription();
  }

}
