import {Component, Input, OnInit} from '@angular/core';
import {LegacySeverity} from "../../models/severity.model";

@Component({
  selector: 'app-health-bar',
  templateUrl: './health-bar.component.html',
  styleUrls: ['./health-bar.component.scss']
})
export class HealthBarComponent implements OnInit {

  @Input() health: number;
  @Input() completeHealth: number;
  @Input() healthPercent: number;
  @Input() severity: LegacySeverity;
  @Input() warningTooltipText: string;
  @Input() display: 'bar' | 'percent' | 'fraction' = 'percent';
  Severity = LegacySeverity;

  constructor() { }

  ngOnInit(): void {
  }

  get severityBgColor() {
    switch (this.severity) {
      case LegacySeverity.High:
        return 'bg-health-bad';
      case LegacySeverity.Medium:
        return 'bg-health-medium';
      case LegacySeverity.Minor:
        return 'bg-health-good';
    }
  }

  get severityColor() {
    switch (this.severity) {
      case LegacySeverity.High:
        return 'health-bad';
      case LegacySeverity.Medium:
        return 'health-medium';
      case LegacySeverity.Minor:
        return 'health-good';
    }
  }

}
