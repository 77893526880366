<div *ngIf="typeSelected" class="org-type-form-container">

    <!--Org Type Form-->
    <form [formGroup]="orgTypeForm">
        <div>
            <div class="row">
                <div class="col">
                    <h4 class="mb-4">{{crudTitle}} {{ 'data.ORG_TYPES.TYPE_METADATA' | translate }}</h4>
                </div>
                <div *ngIf="!isFormEnabled && isTypeNotParentType" class="col justify-end-column">
                    <button mat-icon-button class="setting-edit-icon edit-icon-button" (click)="changeEditMode()">
                        <mat-icon mat-list-icon svgIcon="edit">
                        </mat-icon>
                    </button>
                </div>
            </div>

            <!--Type Name-->

            <mat-form-field class="mat-form-field-fluid prop-fields">
                <mat-label>{{ 'data.ORG_TYPES.TYPE_NAME' | translate }}</mat-label>
                <input matInput formControlName="name" [formGroup]="orgTypeForm" maxlength="100">
            </mat-form-field>

            <!--Dashboard Type-->

            <mat-form-field class="mat-form-field-fluid prop-fields">
                <mat-label>{{ 'data.ORG_TYPES.DASHBOARD_TYPE' | translate }}</mat-label>
                <mat-select class="dashboard-selection-container" formControlName="dashboardType">
                    <mat-option *ngFor="let type of orgDashboardType" [value]="type">
                        {{type | enumUpperToLower}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- properties list-->
        <ng-container formArrayName="properties">
            <div class="mt-2">
                <div class="row m-0">
                    <h4 class="type-props">{{ 'data.ORG_TYPES.TYPE_PROPERTIES' | translate }}</h4>
                    <mat-icon *ngIf="properties.controls.length == 0" class="org-crud-icon type-prop-add-icon"
                        [ngClass]="isFormEnabled? '': 'org-crud-icon-disabled'" mat-list-icon
                        (click)="isFormEnabled? addProperty(): null">
                        add </mat-icon>
                </div>
                <div class="org-types-prop-section" [ngClass]="propsCss">
                    <ng-container *ngFor="let prop of properties.controls; index as i">
                        <ng-container [formGroupName]="i">
                            <div class="prop-fields-row">
                                <div class="col prop-details-text">Property Details: </div>
                                <!--Prop Name-->
                                <mat-form-field class="mat-form-field-fluid prop-fields col">
                                    <mat-label>{{ 'data.ORG_TYPES.PROP_NAME' | translate }}</mat-label>
                                    <input matInput formControlName="name" [formGroup]="orgTypeForm" maxlength="100">
                                </mat-form-field>

                                <!--Prop Type-->

                                <mat-form-field class="mat-form-field-fluid prop-fields col">
                                    <mat-label>{{ 'data.ORG_TYPES.PROP_TYPE' | translate }}</mat-label>
                                    <mat-select class="dashboard-selection-container" formControlName="type">
                                        <mat-option *ngFor="let type of propType" [value]="type">
                                            {{type | titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <!--Prop Madnatory-->

                                <mat-checkbox class="prop-fields col" formControlName="mandatory">
                                    {{ 'data.ORG_TYPES.PROP_IS_MANDATORY' | translate }}</mat-checkbox>

                                <div class="prop-actions prop-fields col">
                                    <mat-icon *ngIf="isFormEnabled" class="org-crud-icon" mat-list-icon
                                        (click)="removeProperty(i)">delete
                                    </mat-icon>
                                    <mat-icon *ngIf="isFormEnabled" class="org-crud-icon" mat-list-icon
                                        (click)="addProperty()">add
                                    </mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div id="scroll-anchor">&nbsp;</div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="justify-end-column org-type-buttons">
        <div>
            <app-buttons class="chat-robot-summary-btn-cancel" [btnVariant]="'stroked'" [btnStyle]="'primary'"
                [btnClass]="" [btnText]="'data.COMMON.CANCEL'" (click)="cancelChanges()" [btnDisabled]="!isFormEnabled">
            </app-buttons>
            <app-buttons *ngIf="isShowEditButton" class="chat-robot-summary-btn-copy pt-0" [btnStyle]="'primary'"
                [btnClass]="" [btnText]="'data.COMMON.SAVE'" (click)="saveChanges()"
                [btnDisabled]="!isFormEnabledAndValid">
            </app-buttons>
            <app-buttons *ngIf="currentAction == crudActionType.Create" class="chat-robot-summary-btn-copy pt-0"
                [btnStyle]="'primary'" [btnClass]="" [btnText]="'data.COMMON.ADD_BUTTON'" (click)="addType()"
                [btnDisabled]="!isFormEnabledAndValid">
            </app-buttons>
        </div>
    </div>
</div>
