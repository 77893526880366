<app-portlet>
    <app-portlet-main-content>
        <app-portlet-header [title]="topologyTitle" class="portlet-header" [hideDefaultTools]="true">
        </app-portlet-header>
        <app-portlet-body class="portlet-body device-portlet-body-container">
            <!--
                For Single Device screen, the selected device id is set as input,
                so the topology componenets will prevent any behvior that could undo this selection
                (such as clicked on other svg element)
            -->
            <div class="device-tplg-container">
                <app-network-topology *ngIf="venueTopology && deviceData" [treeData]="venueTopology"
                    [title]="deviceData?.device.name" [isDevice]="true" [tplgWidth]="tplgWidth"
                    [tplgHeight]="tplgHeight" [selectedDeviceID]="deviceData?.device.id">
                </app-network-topology>
            </div>
        </app-portlet-body>
    </app-portlet-main-content>
</app-portlet>
