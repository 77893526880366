import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {GenericDevice, NodeEntities, NodeEventData} from 'src/app/shared/models/topology';
import { ConnectedClientType, ClientQueriesParam, ClientDevice, rssiIconData, rssiClientTable, rssiClientsData } from 'src/app/shared/models/clients.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/shared/services/rest-services/clients.service';
import { ClientsTooltipDataService } from 'src/app/clients/clients-tooltip-data.service';
import { TopologyTooltipsTypes } from '../topology-tooltips-types.enum';
import { BaseTopologyToolTip } from '../topology-device-tooltip/base-topology-tooltip';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-multi-clients-tooltip',
  templateUrl: './multi-clients-tooltip.component.html',
  styleUrls: ['./multi-clients-tooltip.component.scss'],
  animations: [
    //Source: https://fireship.io/lessons/angular-animations-examples/
    trigger('hoverTooltip', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('0ms ease-out')),
      transition('hide => show', animate('1500ms ease-in'))
    ])
  ]
})
export class MultiClientsTooltipComponent extends BaseTopologyToolTip {
  iconsData: rssiIconData[] = [];
  clientsTable: rssiClientTable[];
  private _rssiBarData: rssiClientsData;


  constructor(protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private clientsTooltipDataService: ClientsTooltipDataService) {
    super(TopologyTooltipsTypes.MultiClientTooltip, router, activatedRoute)
  }


  initiateNodeEventData() {
    if (this.nodeEvent) {
      this.node = this.nodeEvent;
      if (this.node) {
        this.nodeEvent = this.node;
        this.initiateDataForView(this.nodeEvent);
      }
    }
    if (!this.nodeEvent && !this.isHoverTooltipElement) {
      this.node = null;
    }
  }

  initiateDataForView(nodeEvent: NodeEventData<GenericDevice<NodeEntities>>) {
    this.title = `Connection ${this.nodeEvent.d.data.name}`;
    this._rssiBarData = this.clientsTooltipDataService.countClientByRssi(Array.isArray(nodeEvent.d.data.originalData) ? nodeEvent.d.data.originalData : null);
    this.iconsData = this.clientsTooltipDataService.initiateIconPaths(this.nodeEvent.d.data.type as ConnectedClientType, this._rssiBarData);
    this.clientsTable = this.clientsTooltipDataService.prepareMultiClientsTable(this._rssiBarData);
  }

  /**
  * @method goToDevice Navigate to the selected device
  * @param deviceId The selected device id that was selected by clicking the tooltip title
  */
  goToClients() {
    let onlineMode: boolean;
    let parentNodeNumber: string;
    onlineMode = this.node.d.data.originalData[0].originalData.online;
    parentNodeNumber = this.node.d.data.originalData[0].originalData.device.serialNumber;
    const searchClientQueries: ClientQueriesParam = { recentDeviceSerial: parentNodeNumber, online: onlineMode };
    this.clientsService.setQueriesForClientGrid(searchClientQueries);
    this.router.navigate(['../clients'], { relativeTo: this.activatedRoute }).catch();
  }

  isHoverTooltip(isHover: boolean) {
    this.isHoverTooltipElement = isHover;
    if (!this.isHoverTooltipElement && !this.nodeEvent)
      this.node = null;
  }
  get animationTrigger(): ('show' | 'hide') {
    return this.node ? 'show' : 'hide';
  }

  ngOnDestroy() {
    this.subsc.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
