import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {VenueSummary} from "../../models/venues.model";
import {Subscription} from "rxjs";
import {clearSubscriptions} from "../../operators/rxjs";
import {VenuePickerStore} from "./venue-picker-store.service";
import {SelectionOption} from "../select-list/select-option.model";
import {NgxSelectOption} from "../../ngx-select/ngx-select.classes";
import {NgxSelectComponent} from "../../ngx-select/ngx-select.component";

@Component({
  selector: 'app-venue-picker',
  templateUrl: './venue-picker.component.html',
  styleUrls: ['./venue-picker.component.scss']
})
export class VenuePickerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selections: SelectionOption[];
  @Output() selected = new EventEmitter<SelectionOption[]>();
  @Output() removed = new EventEmitter<SelectionOption[]>();
  @Output() closed = new EventEmitter<SelectionOption[]>();

  @ViewChild('venuePicker', {static: true}) venuePicker: NgxSelectComponent;

  set venues(venues: VenueSummary[]) {
    this.venueOptions = venues.map(venue => ({
      id: venue.id,
      name: venue.name,
      description: venue.name
    }));
  }
  venueOptions: SelectionOption[];
  subscriptions: Subscription[] = [];

  constructor(private venuePickerStore: VenuePickerStore,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fetchVenues();
  }

  ngAfterViewInit() {
    if (this.selections && this.venuePicker) {
      this.selections?.forEach(selection => {
        this.venuePicker.selectOptionValue(selection.id);
      });
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    clearSubscriptions(this.subscriptions);
  }

  fetchVenues() {
    const subsc = this.venuePickerStore.fetchVenues().subscribe(venues => {
      this.venues = venues;
    });
    this.subscriptions.push(subsc);
  }

  onSelect(options: NgxSelectOption[]) {
    const selections = this.extractPayload(options)
    this.selected.emit(selections);
    this.venuePickerStore.selections = selections;
  }

  onRemove(options: NgxSelectOption[]) {
    const selections = this.extractPayload(options);
    this.removed.emit(selections);
    this.venuePickerStore.selections = selections;
  }

  onClose(options: NgxSelectOption[]) {
    const selections = this.extractPayload(options);
    this.closed.emit(selections);
    this.venuePickerStore.selections = selections;
  }

  private extractPayload(selections: NgxSelectOption[]): SelectionOption[] {
    return selections.map(selection => ({
      id: selection.data?.id,
      name: selection.data?.name,
      description: selection.data?.description
    }));
  }
}
