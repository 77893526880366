import { Component, OnInit, Input } from '@angular/core';
import { rssiIconData, RssiLabels } from 'src/app/shared/models/clients.model';

@Component({
  selector: 'app-rssi-bar',
  templateUrl: './rssi-bar.component.html',
  styleUrls: ['./rssi-bar.component.scss']
})
export class RssiBarComponent implements OnInit {
  @Input() iconsData: rssiIconData[];
  public rssiLegend = RssiLabels.getRssiLegendText();
  public rssiLegendInfinity = RssiLabels.getInfinityAsString();
  constructor() { }

  ngOnInit() {
  }

}
