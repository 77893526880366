import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SingleDevice } from '../../../models/single-device.model';
import { GenericDevice } from '../../../models/topology';

@Injectable({
  providedIn: 'root'
})
export class DisconnectedDevicesStoreService {

  /**
  * @param notifyNode recieve the clicked element data
  */
  private notifyNode: BehaviorSubject<GenericDevice<SingleDevice>> = new BehaviorSubject(null);

  /**
   * @param notifyNodeObservable$ makes notify data available to outside users
   * In our case: to the parent param
   */
  notifyNodeObservable$ = this.notifyNode.asObservable();

  constructor() {
  }

  storeSelectedDisconnectedDevice(device: GenericDevice<SingleDevice>) {
    if (device) {
      this.notifyNode.next(device);
    }
  }
  resetSelectedDisconnectedDevice() {
    this.notifyNode.next(null);
  }
}
