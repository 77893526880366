<div class="row icon-with-text-row">
    <div class="col icon-with-ellipse-col" *ngIf="iconPath">
        <app-icon-with-ellipse [iconPath]="iconPath" [ellipseClass]="ellipseClass"
            [iconClass]="'ellipse-cell-renderer'">
        </app-icon-with-ellipse>
    </div>
    <div class="col pl-0">
        <p class="renderer-icon-text">{{text}}</p>
    </div>
</div>
