import {Component, Input, ViewEncapsulation} from '@angular/core';
import {HeaderData, PropertiesDataType, PropertiesElementType} from '../models/properties-content';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {SingleDeviceType} from 'src/app/shared/models/single-device.model';
import {IssuesHealthDialogComponent} from '../../../modals/issues-health-dialog/issues-health-dialog.component';
import {SeverityService} from '../../../services/strategies/severity.service'
import {PropActionsHealthDataService} from '../services/prop-actions-health-data.service';
import { MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-properties-header',
  templateUrl: './properties-header.component.html',
  styleUrls: ['./properties-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertiesHeaderComponent {
  @Input() headerData: HeaderData;
  @Input() isDevice: false;
  propertiesDataType = PropertiesDataType.HEALTH;
  deviceType = SingleDeviceType;
  elementType = PropertiesElementType;
  severityIconPath: string;
  entityActionsList: string[] = [];

  constructor(
    private healthColorsService: HealthColorsService,
    private severityService: SeverityService,
    private propActionsHealthDataService: PropActionsHealthDataService,
    public dialog: MatDialog,
  ) {
  }

  ngOnChanges() {
    this.propActionsHealthDataService.getActionsData(this.entityActionsList, this.headerData);
    this.getLinkIcon();
  }

  get healthColor() {
    return this.healthColorsService.getHealthColors(this.headerData.health);
  }

  get widthForLinkData() {
    if (this.headerData && this.headerData.elementType == this.elementType.LINK)
      return 'prop-link-first-column-container'
    return;
  }

  private getLinkIcon() {
    if (this.headerData && this.headerData.status && this.headerData.elementType == this.elementType.LINK)
      this.severityIconPath = this.severityService.severityByLinkStatus(this.headerData.status);
  }

  openHealthIssuesDialog(dataType: string) {
    if (PropertiesDataType.HEALTH.toLowerCase() !== dataType)
      this.propertiesDataType = PropertiesDataType.ACTIONS;
    else
      this.propertiesDataType = PropertiesDataType.HEALTH;
    this.dialog.open(IssuesHealthDialogComponent, {
      width: '648px',
      height: '357',
      data: {
        dataContent: this.headerData,
        dataType: this.propertiesDataType,
        titleAddition: this.propertiesDataType == PropertiesDataType.ACTIONS ?
          ' - Last 24 hours'
          :
          ''
      }
    })
  }
}
