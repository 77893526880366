<ng-container portletTools>
  <div class="row grid-toolbar-row">
    <div class="search-container">
      <div class="search-input">
        <ng-content select="[checkboxFilter]"></ng-content>
        <input type="text" class="search" (keyup)="0" [(ngModel)]="searchTerm" pattern="[a-zA-Z0-9]+"
               (ngModelChange)="emitChanges($event)">
        <span class="search-icon" [inlineSVG]="'assets/media/netop/search.svg'" *ngIf="!searchTerm"></span>
      </div>
      <ng-content select="[typeFlter]"></ng-content>
    </div>
    <div>
      <app-total-grid-rows *ngIf="hasTotal">
        {{totalTitle}}: {{total}}
      </app-total-grid-rows>
      <ng-content select="[headerCog]"></ng-content>
      <ng-content select="[headerButton]"></ng-content>
    </div>
  </div>
</ng-container>
