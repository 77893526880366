import { Injectable } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { GraphTrendSizeService } from 'src/app/shared/services/strategies/graph-trend-size.service';

@Injectable({
  providedIn: 'root'
})
export class SingleSizeCalculationService {

  constructor(private graphTrendSizeService: GraphTrendSizeService
  ) { }

  calculateBigChartSize(): { width: number; height: number; } {
    let chartSizes: { width: number, height: number }
    let mediaWidth = {
      [Breakpoints.XLarge]: { width: 665, heigh: 900 },
      [Breakpoints.Large]: { width: 665, heigh: 375 },
      [Breakpoints.Medium]: { width: 800, heigh: 250 },
      [Breakpoints.Small]: { width: 300, heigh: 150 },
    }
    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    chartSizes = { width: width, height: height };
    return chartSizes;
  }

  calculateTopologySize(): { width: number; height: number; } {
    let topologySizes: { width: number, height: number }
    let mediaWidth = {
      [Breakpoints.XLarge]: { width: 940, heigh: 525 },
      [Breakpoints.Large]: { width: 920, heigh: 525 },
      [Breakpoints.Medium]: { width: 1200, heigh: 445 },
      [Breakpoints.Small]: { width: 1200, heigh: 400 },
    };

    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    topologySizes = { width: width, height: height };
    return topologySizes;
  }
  calculateDeviceTopologySize(): { width: number; height: number; } {
    let topologySizes: { width: number, height: number }
    let mediaWidth = {
      [Breakpoints.XLarge]: { width: 940, heigh: 375 },
      [Breakpoints.Large]: { width: 920, heigh: 375 },
      [Breakpoints.Medium]: { width: 1200, heigh: 445 },
      [Breakpoints.Small]: { width: 1200, heigh: 400 },
    };

    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    topologySizes = { width: width, height: height };
    return topologySizes;
  }
  calculateVenueFabricsTopologySize(): { width: number; height: number; } {
    let topologySizes: { width: number, height: number }
    let mediaWidth = {
      [Breakpoints.XLarge]: { width: 940, heigh: 625 },
      [Breakpoints.Large]: { width: 920, heigh: 625 },
      [Breakpoints.Medium]: { width: 1200, heigh: 445 },
      [Breakpoints.Small]: { width: 1200, heigh: 400 },
    };

    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    topologySizes = { width: width, height: height };
    return topologySizes;
  }
}