<ng-container *ngIf="isLeanMode">
  <div class="list-view-stack lean">
    <div *ngFor="let anomaly of anomaliesSelection; let i= index" (click)="onItemSelected(anomaly)"
         class="list-view-item lean" [ngClass]="{'anomaly-list-item-selected lean': anomaly.isSelected}">
      <div class="row m-0">
        <div class="col col-flex-1">
          <mat-icon class="anomaly-icon" [ngClass]="'anomaly-icon-' + i" svgIcon="anomaly-icon">
          </mat-icon>
        </div>
        <div class="col anomaly-list-text-col">
          <div class="anomaly-text">{{anomaly.mainText | formattedCamel}}</div>
          <div class="anomaly-text">
            <span class="anomaly-text-out">{{anomaly?.outOf?.out}}</span>
            <span>/{{anomaly?.outOf?.total}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isLeanMode">
  <div class="list-view-stack detailed">
    <div class="prefix" style="font-weight: 600">Filter By:</div>
    <div class="list-view-filters">
      <app-anomalies-grid-filters [leanGridData]="leanGridData" [currentEntity]="currentEntity"
                                  [selectedEntity]="selectedEntity"
                                  (entitySelected)="onEntitySelected($event)"></app-anomalies-grid-filters>
    </div>
    <div class="items-container">
      <div class="arrow" arrowScroll="left" scrollRange="50" [scrollElement]="itemsList">
        <<
      </div>
      <div class="list" #itemsList>
        <div class="item" *ngFor="let anomaly of anomaliesSelection; let i= index">
          <mat-radio-button value="Custom Url" (click)="onItemSelected(anomaly)"
                            [checked]="anomaly.data.anomalyCategory === currentAnomalyCategory || i === 0"
                            [disableRipple]="true">
            <div class="just-flex">
              <div class="anomaly-text detailed">{{anomaly.mainText  | usersToClients| formattedCamel}}</div>
              <div class="anomaly-text">
                <span class="anomaly-text-out">{{anomaly?.outOf?.out}}</span>
                <span>/{{anomaly?.outOf?.total}}</span>
              </div>
            </div>
          </mat-radio-button>
        </div>
      </div>
      <div class="arrow" arrowScroll="right" scrollRange="50" [scrollElement]="itemsList">
        >>
      </div>
    </div>
  </div>
</ng-container>
