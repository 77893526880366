import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {GenericDevice, NodeEventData, SingleD3Node, Topology} from 'src/app/shared/models/topology';
import {SingleDevice, SingleDeviceType} from 'src/app/shared/models/single-device.model';
import {D3TreeEventsService} from '../../../../services/d3-tree-events.service';
import {D3NodeService} from '../../../../services/d3-node.service';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {
  hasVendorName,
  isClient,
  isDevice,
  isDeviceCloud, isDeviceUnrecognized,
  isFailedClients, isGenericIsClient, isGenericIsMultiClient,
  isMultiClient, isZipper
} from '../../../../operators/topology-operators';
import {TopologyConfiguration, TreeConfiguration} from '../../../../models/topology-configuration';
import {Subscription} from 'rxjs';
import {NgChanges} from 'src/app/shared/extend-angular-classes/on-changes';
import {SingleLink} from "../../../../../../models/single-link.model";
import {ClientsTopologyService} from "../../../../services/clients-topology.service";

@Component({
  selector: '[nodeVisual]',
  templateUrl: './node-visual.component.html',
  styleUrls: ['./node-visual.component.scss']
})
export class NodeVisualComponent {
  @Input('nodeVisual') node: SingleD3Node<GenericDevice<any>>
  @Input('topologyConfiguration') topologyConfiguration: TopologyConfiguration;
  @Input('treeConfiguration') treeConfiguration: TreeConfiguration;
  @Input() selectedFabricTopology: Topology<SingleDevice, SingleLink>;
  @Input('maxChildren') maxChildren: number;
  @Input('isSqueezable') isSqueezable: boolean = false;
  width: number;
  nodeClicked: boolean = false;
  maxChildrenNumber: number = 0;
  subscription: Subscription[] = [];

  @ViewChild('nodeIconElement') nodeIconElement: ElementRef;

  constructor(
    private d3TreeEventsService: D3TreeEventsService,
    private d3NodeService: D3NodeService,
    private clientTopologyService: ClientsTopologyService,
    private healthColorsService: HealthColorsService) {
  }

  ngOnInit() {
    this.subscribed3TreeSelection();
  }

  ngOnChanges(changes: NgChanges<NodeVisualComponent>) {
    if (this.node && this.topologyConfiguration && this.treeConfiguration && this.maxChildren) {
      this.width = this.treeConfiguration.width;
      this.maxChildrenNumber = this.maxChildren;
      if (this.topologyConfiguration.selectedDeviceID || this.topologyConfiguration.selectedFabricTopology) {
        this.markNodeAsClickedBasedOnInputData();
      }
    }
  }

  /**
   * Subscribe to node selection observable.
   * Initiate ths nodeClicked property
   * The method won't work on venues fabrics screen, because this screen does allow the user to select node by click,
   * only mark nodes as selected when fabric is selected
   */
  subscribed3TreeSelection() {
    if (this.topologyConfiguration && !this.topologyConfiguration.isFabricsVenue) {
      let notifyClickedSubscription = this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(element => {
        if (element && (isDevice(element) && (element as SingleD3Node<GenericDevice<any>>).data.id === this.node.data.id) ||
          (isZipper(element) && (element as SingleD3Node<GenericDevice<any>>).data.parent_id === this.node.data.id)) {
          this.applyClickedElementChanges();
        } else {
          this.cancelClickedElementChanges();
        }
      })
      this.subscription.push(notifyClickedSubscription);
    }
  }

  /**
   * Invoke the onClickedNode method with the current clicked node
   * @param event Current click event
   */
  onNodeClick(event: MouseEvent) {
    this.node.data.iconPath = this.d3NodeService.getClickedIconPath(this.node.data.type);
    this.node.data.secondaryIcon = this.d3NodeService.getSecondaryIconPath(this.node.data.type);
    this.d3NodeService.onNodeClick(this.node, this.topologyConfiguration);
  }

  onMouseEnter($event: MouseEvent) {
    let nodeData: NodeEventData<GenericDevice<any>> = {d: this.node, event: $event};
    if (!isZipper(nodeData.d))
      this.d3TreeEventsService.onNodeEnter(nodeData);
  }

  onMouseLeave() {
    this.d3TreeEventsService.onNodeLeave();
  }

  /**
   * Responsible For mark nodes as clicked
   * Use cases:
   * [1] On single device screen, where the device was selected before routing to the topology screen
   * [2] On the single venue fabric screen where device is marked as clicked after fabric selection
   */
  private markNodeAsClickedBasedOnInputData() {
    if (this.topologyConfiguration.selectedDeviceID == this.node.data.id)
      this.d3TreeEventsService.onNodeClick(this.node);
    if (this.topologyConfiguration.selectedFabricTopology)
      this.markFabricNodesAsClicked();
  }

  /**
   * Mark devices that are of the selected fabric as clicked
   */
  private markFabricNodesAsClicked() {
    this.nodeClicked = this.topologyConfiguration.selectedFabricTopology.nodes.find(fabricNode => fabricNode.device.id == this.node.data.id) !== undefined;
  }

  applyClickedElementChanges() {
    this.nodeClicked = true;
  }

  cancelClickedElementChanges() {
    this.nodeClicked = false;
  }

  get nodePosition() {
    let positionX = this.width - this.node.y;
    return `translate(${positionX}, ${this.node.x})`
  }

  get shadowClass() {
    return this.nodeClicked ?
      "clicked-node-circle" :
      "node-circle";
  }

  get textClass() {
    return this.nodeClicked ?
      'clicked-node-label' :
      'node-label';
  }

  get iconPath() {
    if (this.nodeClicked)
      this.node.data.iconPath = this.d3NodeService.getClickedIconPath(this.node.data.type);
    else
      this.node.data.iconPath = this.d3NodeService.getInitialIconPath(this.node.data.type);
    return this.node.data.iconPath;
  }

  get dotFill() {
    if (isMultiClient(this.node) || this.node.data.isZipper)
      return '#ffffff';
    return this.healthColorsService.getNodeDotColor(this.node.data.originalData?.health);
  }


  get dotStroke() {
    if (isMultiClient(this.node) || this.node.data.isZipper)
      return {stroke: "#4C5862", 'stroke-width': 0.8}
  }

  /**
   * Key param: Other attributes are calculated by it.
   */
  get gSize() {
    return this.d3NodeService.getNodeSize(this.maxChildrenNumber, this.isSqueezable);
  }

  get nodeRadius() {
    return this.gSize;
  }

  get withDot() {
    return !this.isCloud && !isClient(this.node) && !this.isUnrecognizedDevice && this.node.data?.type != SingleDeviceType.SwitchStack;
  }

  get withEndLinkDot() {
    return !this.isCloud;
  }

  get isShowChildrenNumber() {
    if (isMultiClient(this.node) || isZipper(this.node))
      return this.node.data.originalData?.length;
    return null;
  }

  get showNumberInTheMiddle() {
    return isZipper(this.node);
  }

  get isShowAboveLinkIcon() {
    return this.node && this.node.children && this.node.children.length > 0 && this.node.children.find(child => child.data.isToBeZipped && !child.data.isZipMode) !== undefined;
  }

  get textInTheMiddleY() {
    if (this.isShowChildrenNumber > 9) {
      return this.gSize * .35;
    }
    return this.gSize * .5
  }

  get warningIconPath() {
    if (isFailedClients(this.node)) {
      return 'assets/media/icons/svg/Code/Warning-2.svg';
    }
    return null;
  }

  get rightBottomIcon() {
    return this.d3NodeService.getRightBottomIcon(this.node, this.nodeClicked);
  }

  get aboveLinkIcon() {
    return this.d3NodeService.getTopLeftIcon(this.node, this.nodeClicked);
  }

  get isDevice() {
    return this.topologyConfiguration.isDevice;
  }

  get isCloud() {
    return isDeviceCloud(this.node.data.type);
  }

  get isUnrecognizedDevice() {
    return isDeviceUnrecognized(this.node.data.type);
  }


  get topRightIcon() {
    return isZipper(this.node);
  }

  get vendorName() {
    return hasVendorName(this.node) && this.node.data.type !== SingleDeviceType.UnidentifiedDevice ?
      " - " + (this.node.data.originalData as SingleDevice)?.device?.vendorName : null
  }

  get isZipperChildrenHasClients() {
    if (isZipper(this.node)) {
      const clients = this.treeConfiguration.nodes.filter(treeNode => isGenericIsClient(treeNode) || isGenericIsMultiClient(treeNode));
      if (clients && clients.length > 0) {
        for (let i = 0; i < this.node.data.originalData?.length; i++) {
          const child = this.node.data.originalData[i];
          if (this.clientTopologyService.isDeviceHasClients(clients, child)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  get numberInTheMiddleStyle() {
    let style;
    if (this.isShowChildrenNumber > 9) {
      style = {'font-size': this.gSize}
    } else {
      style = {'font-size': this.gSize * 1.5};
    }
    return style;
  }

  get topRightDotR() {
    if (isZipper(this.node)) {
      return this.gSize / 1.6;
    }
    return this.gSize / 3.75;
  }

  get topRightDotY() {
    if (this.topRightIcon) {
      return -this.gSize * 1.2;
    }
    return -this.gSize * .75;
  }

  get bottomLeftIcon() {
    if (this.isZipperChildrenHasClients) {
      return 'assets/media/netop/topology/computer.svg';
    }
  }


  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}
