import {Injectable} from '@angular/core';
import {TimeManagerService} from '../../../services/time-manager.service';
import {KpiDataset, KpiTrend, KpiTrendDisplay, KpiType, StackedKpiData, TrafficUnits} from '../../../models/kpi.model';
import {isKpiStacked} from '../../../operators/kpi-oprerators';
import {EditStringsService} from '../../../services/strategies/edit-strings.service';
import {GraphTrendMarker} from '../models/chart-js-events.model';
import {findClosetEarlierDate} from '../../../operators/time-operator';
import {
  TrendOptionsData,
  TrendOptionsDto
} from "../../kpi-display-components/multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.model";

@Injectable({
  providedIn: 'root'
})

export class MultiKpiChartsService {
  constructor(
    private dateConvertor: TimeManagerService,
    private editStringsService: EditStringsService,
  ) {
  }

  /**
   * Return New data with new cell that represent the action last updated date
   * The method checks if the trend data is stacked or not.
   * @param trendData The current trend data
   * @param marker
   */
  enterTooltipPosition(trendData: KpiTrend | StackedKpiData[], marker: GraphTrendMarker) {
    if (isKpiStacked(trendData))
      (trendData as StackedKpiData[]).forEach(trend => this.findIsTooltip(trend.data, marker));
    else
      this.findIsTooltip(trendData, marker);
  }

  /**
   * The function enter new cell to the trend array.
   * The cell represent the date of the selected action.
   * This cell will be used as a tooltip that display the same date on all charts.
   * In order to do so, the function look for the right place to put the new cell,
   * By comparing the action date and the trend date.
   *
   * @param data The curren kpi trend Object.
   * @param lastUpdate The curren kpi trend Object.
   */
  private findIsTooltip(trendData, marker) {
    if (marker) {
      let tooltipEnterIndex = 0;
      trendData.forEach((datum, index) => {
        if (
          (index > 0 && index < trendData.length - 1)
          &&
          marker.formatted > this.dateConvertor.dateByFormat(trendData[index - 1].x, 'DD-MMM-YYYY HH:mm') &&
          marker.formatted < this.dateConvertor.dateByFormat(trendData[index].x, 'DD-MMM-YYYY HH:mm')) {
          tooltipEnterIndex = index;
        }
      });
      if (tooltipEnterIndex) {
        let tooltipCell = {x: marker.unix, y: trendData[tooltipEnterIndex].y};
        trendData.splice(tooltipEnterIndex, 0, tooltipCell);
      }
    }
  }

  /**
   * Find the max and min values for the x axis.
   * Relevant for multi kpi display only
   * @param kpiTrends All the kpi trends
   */
  findChartMinXAndMaxX(kpiTrends: { type: KpiType; trend: KpiTrend | StackedKpiData[]; stacked?: StackedKpiData[]; }[]): any[] {
    let counter = 0;
    let min;
    let max;
    while (!(kpiTrends[counter].trend.length != 0) && counter <= kpiTrends.length - 1) {
      counter++
    }
    if (!isKpiStacked(kpiTrends[counter].trend) && (kpiTrends[counter].trend as KpiTrend)[0]) {
      min = (kpiTrends[counter].trend as KpiTrend)[0].datetime;
      max = (kpiTrends[counter].trend as KpiTrend)[0].datetime;
    } else if ((kpiTrends[counter].trend as StackedKpiData[])[0].data[0]) {
      min = (kpiTrends[counter].trend as StackedKpiData[])[0].data[0].x;
      max = (kpiTrends[counter].trend as StackedKpiData[])[0].data[0].x;
    }
    kpiTrends.forEach(kpi => {
      if (!isKpiStacked(kpi.trend)) {
        kpi.trend.forEach(trend => {
          if (trend.datetime > max)
            max = trend.datetime;
          if (trend.datetime < min)
            min = trend.datetime;
        })
      } else {
        if (kpi.trend)
          kpi.trend.forEach(trend => {
            trend.data.forEach(point => {
              if (point.x > max)
                max = point.x;
              if (point.x < min)
                min = point.x;
            })
          })
        if (kpi.stacked)
          kpi.stacked.forEach(trend => {
            trend.data.forEach(point => {
              if (point.x > max)
                max = point.x;
              if (point.x < min)
                min = point.x;
            })
          })
      }
    })
    return [max, min];
  }

  /**
   * Return the kpi title with the relevant unit
   * @param kpiType The current kpi type
   */
  setKpiTitleWithUnits(kpiType: KpiType, unit: TrafficUnits): string {
    let key = this.editStringsService.convertKeytoCamelCaseWord(kpiType);
    switch (kpiType) {
      case KpiType.Latency:
        return key + " (ms)"
      case KpiType.Loss:
        return key + " (%)"
      case KpiType.Traffic:
        return `${kpiType} (${unit})`
      case KpiType.Throughput:
        return `${kpiType} (${TrafficUnits.MBps})`
      case KpiType.Clients:
        return key;
      default:
        break;
    }
  }

  getCurrentOptionTrend(optionsTrend: TrendOptionsDto): TrendOptionsData {
    let options = Object.values(optionsTrend);
    if (options.length == 0) return undefined;

    let currentOption: TrendOptionsData;
    options.forEach(option => {
      const dataKeys = Object.keys(option.data);
      if (option.data.length > 0) {
        if (dataKeys.includes('datetime')) {
          currentOption = currentOption === undefined ? option : currentOption;
        } else {
          let empty: boolean = true;
          (option.data as StackedKpiData[]).forEach(dataset => {
            if (dataset.data.length > 1) {
              currentOption = currentOption === undefined ? option : currentOption;
              empty = false;
            }
          });
          option.empty = empty;
        }
      } else {
        if (dataKeys.includes('datetime'))
          option.empty = true;
      }
    });

    return currentOption !== undefined ? currentOption : options[0];
  }

  /**
   * Build GraphTrendMarker by timestamp
   */
  generateMarker(trend: KpiTrend | StackedKpiData[], timestamp: number | string): GraphTrendMarker {
    if (trend.length > 0 && timestamp) {
      let markerIndex: number;
      if (trend[0].hasOwnProperty("datetime")) {
        markerIndex = (trend as KpiTrend).findIndex(point => (point.datetime as any)._i === timestamp);
        if (markerIndex === -1) {
          markerIndex = findClosetEarlierDate((trend as KpiTrend).map(point => point.datetime.toDate()), this.dateConvertor.convertUnixToDateObject(+timestamp));
        }
        if (markerIndex && markerIndex >= 0) {
          return {
            index: markerIndex,
            formatted: this.dateConvertor.dateByFormat((trend as KpiTrend)[markerIndex].datetime.toDate(), 'DD-MMM-YYYY HH:mm'),
            moment: (trend as KpiTrend)[markerIndex].datetime
          }
        }
      }
      if (trend[0].hasOwnProperty("type")) {
        markerIndex = (trend as StackedKpiData[])[0].data.findIndex(point => (point.x as any)._i === timestamp);
        if (markerIndex === -1) {
          markerIndex = findClosetEarlierDate((trend as StackedKpiData[])[0].data.map(point => point.x.toDate()), this.dateConvertor.convertUnixToDateObject(+timestamp));
        }
        if (markerIndex && markerIndex >= 0) {
          return {
            index: markerIndex,
            formatted: this.dateConvertor.dateByFormat((trend[0] as any).data[markerIndex].x.toDate(), 'DD-MMM-YYYY HH:mm'),
            moment: (trend[0] as any).data[markerIndex].x
          }
        }
      }
    }
  }

  /**
   * Return GraphTrendMarker After finding the current date in the trend array by index
   */
  generateMarkerByIndex(trend: KpiTrend | StackedKpiData[], markerIndex): GraphTrendMarker {
    if (markerIndex && markerIndex > -1) {
      if (trend[0].hasOwnProperty("datetime")) {
        return {
          index: markerIndex,
          formatted: this.dateConvertor.dateByFormat((trend as KpiTrend)[markerIndex].datetime.toDate(), 'DD-MMM-YYYY HH:mm'),
          moment: (trend as KpiTrend)[markerIndex].datetime
        }
      }
      if (trend[0].hasOwnProperty("type")) {
        return {
          index: markerIndex,
          formatted: this.dateConvertor.dateByFormat((trend[0] as any).data[markerIndex].x.toDate(), 'DD-MMM-YYYY HH:mm'),
          moment: (trend[0] as any).data[markerIndex].x
        }
      }
    }
  }

  regenerateKpiMarkerDisplayDataSingle(kpi: KpiTrendDisplay) {
    if (kpi.marker) {
      const timestamp = kpi.marker.moment;
      const index = this.getKpiMarkerDisplayIndex(kpi, timestamp);
      if (index !== kpi.marker.index || index === kpi.marker.index) {
        kpi.marker = this.generateMarkerByIndex(kpi.trend, index);
      }
    }
  }

  /**
   * 1. Regenerate Marker if the current kpi trend is not the one in the event
   * The method find the current index in the trend array and create the marker with it
   */
  regenerateKpiMarkerDisplayData(kpiTrends: KpiTrendDisplay[], event: KpiDataset) {
    const timestamp: number = Object.values(event.selectedDataset.datasets)[0].xValue;
    kpiTrends.forEach(kpi => {
      const index = this.getKpiMarkerDisplayIndex(kpi, timestamp);
      //Regenerate marker only for different calculated index
      // Except: if its the hovered kpi, so when the user is hovered out, the marker won't be gone
      if (!kpi.marker || index !== kpi.marker.index || index === kpi.marker.index && event.kpiType === kpi.type) {
        kpi.marker = this.generateMarkerByIndex(kpi.trend, index);
      }
    });
  }

  private getKpiMarkerDisplayIndex(kpi: KpiTrendDisplay, timestamp: number): any {
    let index;
    if (kpi.type === KpiType.Traffic || kpi.type === KpiType.Throughput) {
      let inIndexes = (kpi.trend[0] as StackedKpiData).data.findIndex(point => point.x._i === timestamp);
      let outIndexes = (kpi.trend[1] as StackedKpiData).data.findIndex(point => point.x._i === timestamp);
      if (inIndexes === -1 && outIndexes === -1) {
        inIndexes = findClosetEarlierDate((kpi.trend[0] as StackedKpiData).data.map(point => point.x.toDate()), this.dateConvertor.convertUnixToDateObject(timestamp));
        outIndexes = findClosetEarlierDate((kpi.trend[1] as StackedKpiData).data.map(point => point.x.toDate()), this.dateConvertor.convertUnixToDateObject(timestamp));
      }
      if (inIndexes > -1 && outIndexes > -1) {
        index = inIndexes;
      }
    } else {
      index = (kpi.trend as KpiTrend).findIndex(point => (point.datetime as any)._i === timestamp);
    }
    return index;
  }
}
