export enum EntityType {
  TENANT = 'Tenant',
  ORGANIZATION = 'Organization',
  DEVICE = 'Device',
  VENUE = 'Venue',
  NETWORK_INTERFACE = 'Network Interface',
  SLA = 'SLA',
  SNMP = 'SNMP',
  LINK = 'LINK',
  FABRIC = 'Fabric',
  CLIENT = 'Client',
  ALERT = 'Alert',
  ACCOUNTS = 'Accounts',
  SSID = 'Ssid',
  VLAN = 'Vlan'
}

export namespace EntityType {
  export function isEntityTypeHigher(entityType1: EntityType, entityType2: EntityType) {
    if (isGeographicEntity(entityType1) || isGeographicEntity(entityType2)) {
      let typesArray: EntityType[] = [
        EntityType.VENUE,
        EntityType.TENANT,
        EntityType.ORGANIZATION
      ];

      let entityType1Index = typesArray.findIndex(type => type == entityType1);
      let entityType2Index = typesArray.findIndex(type => type == entityType2);
      return entityType2Index > entityType1Index;
    }
  }

  export function isGeographicEntity(entityType: EntityType) {
    return entityType == EntityType.ORGANIZATION || entityType == EntityType.TENANT ||
      entityType == EntityType.VENUE
  }
}
