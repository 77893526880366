import {Component, OnInit, ViewChildren, Input, QueryList, Output, EventEmitter} from '@angular/core';
import {LegacySeverity} from '../../models/severity.model';
import {GraphTypeSelectorData} from '../graph-type-selector/graph-type-selector-data.model';
import {GraphTypeSelectorComponent} from '../graph-type-selector/graph-type-selector.component';
import {Logger, LoggerService} from '../../services/logger.service';
import {GraphType} from '../graph-type-selector/graph-type.enum';

@Component({
  selector: 'app-graph-type-selectors',
  templateUrl: './graph-type-selectors.component.html',
  styleUrls: ['./graph-type-selectors.component.scss']
})
export class GraphTypeSelectorsComponent implements OnInit {
  Severity = LegacySeverity;
  @Input() graphTypeSelectorsData: GraphTypeSelectorData[];
  @Input() selectable: boolean = true;
  @ViewChildren(GraphTypeSelectorComponent) graphTypeSelectors: QueryList<GraphTypeSelectorComponent>;
  @Output() typeSelected: EventEmitter<string> = new EventEmitter();
  @Input() layout: "column" | "row" = "column";
  @Input() selectorDataCss: string;
  @Input() graphTypeSelectorsRowCss: string;
  @Input() addMargin: boolean;
  @Input() inBarDisplay: boolean = false;
  private logger: Logger; components; columnDefs;
  graphType = GraphType;

  constructor(private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("GraphTypeSelectorsComponent");
  }

  ngOnInit() {
    this.logger.debug(`selectable ${this.selectable}`)
  }

  onSelectorClicked(clickedGraphType: string) {
    if (!this.selectable) {
      return;
    }
    this.initiateEmission(clickedGraphType);
  }

  onClick(graphData: GraphTypeSelectorData) {
    this.initiateEmission(graphData.graphType);
  }

  private initiateEmission(clickedGraphType: string) {
    this.graphTypeSelectors.forEach((graphTypeSelector) => {
      if (graphTypeSelector.selectorData.graphType != clickedGraphType) {
        graphTypeSelector.selected = false;
      } else {
        this.logger.debug(`emitted selected graph type ${graphTypeSelector.selectorData.graphType}`)
        this.typeSelected.emit(graphTypeSelector.selectorData.graphType)
      }
    })
  }
}
