import {getObjectProperty} from './object-operators/object-access';
import {randomIntBetween} from "./numeric-operator";

export function findIndexById(array: any[], id: number): number {
  return array.findIndex(item => item.id == id);
}

export function findIndexByFieldName(array: any[], fieldName: string, value: any): number {
  return array.findIndex(item => item[fieldName] == value);
}

export function switchItemsInArray(array: any[], index1: number, index2: number) {
  let tempRow = array[index1];
  array[index1] = array[index2]
  array[index2] = tempRow;
  return array;
}

export function switchItemsInNestedArray(array: any[], indexRow1: number, indexItem1, indexRow2: number, indexItem2: number) {
  let tempRow = array[indexRow1][indexItem1];
  array[indexRow1][indexItem1] = array[indexRow2][indexItem2]
  array[indexRow2][indexItem2] = tempRow;
  return array;
}

export function isAllItemsSame(arr: any[]): boolean {
  return arr.every((val, i, arr) => val === arr[0])
}

export function generateNumbersArray(lastIndex: number, startIndex: number = 0) {
  let numbersArray: number[] = [];
  for (let index = startIndex; index < lastIndex; index++) {
    numbersArray.push(index);
  }
  return numbersArray;
}

export function generateNumbersArrayWithTicks(tick: number, lastIndex: number, startIndex: number = 0) {
  let numbersArray: number[] = [];
  for (let index = startIndex; index <= lastIndex;) {
    numbersArray.push(index);
    if ((index + tick) <= lastIndex) {
      index = index + tick;
    } else {
      break;
    }

  }
  return numbersArray;
}

export function generateDigitsAndTicksNumbersArray(tick: number, singleDigitsMax: number, lastIndex: number, startIndex: number = 0) {
  let numbersArray: number[] = [];
  for (let index = startIndex; index <= lastIndex;) {
    numbersArray.push(index);
    if (index < singleDigitsMax) {
      index++;
    } else if ((index + tick) <= lastIndex) {
      index = index + tick;
    }
    if ((index + tick) > lastIndex) {
      break;
    }
  }
  return numbersArray;
}

export function sortArrayByParam(prop: string, direction: ("asc" | "desc")) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return direction == "asc" ?
        -1 : 1
    }
    if (a[prop] > b[prop]) {
      return direction == "asc" ?
        1 : -1
    }
    return 0;
  }
}

export function sortArrayByObjectPath(path: string, direction: ("asc" | "desc")) {
  return function (a, b) {
    const aValue = getObjectProperty(a, path);
    const bValue = getObjectProperty(b, path);
    if (aValue < bValue) {
      return direction == "asc" ?
        -1 : 1
    }
    if (aValue > bValue) {
      return direction == "asc" ?
        1 : -1
    }
    return 0;
  }
}

export function isArraySame(array1: any[], array2: any[], compareParam: string) {
  let isSame: boolean = true;
  if (array2 && array1.length != array2.length || array1.length && !array2)
    isSame = false;
  else if (array1.length == array2.length) {
    for (let index = 0; index < array1.length; index++) {
      const elementFrom1 = array1[index];
      const elementFrom2 = array2[index];
      if (elementFrom1.hasOwnProperty(compareParam) && elementFrom2.hasOwnProperty(compareParam)) {
        if (elementFrom1[compareParam] != elementFrom2[compareParam]) {
          isSame = false;
          break;

        }
      }
    }
  }
  return isSame;
}

export function distinctArray(arr: any[], fields: string[]): any[] {
  return arr.filter(
    (currentItem, i, arr) =>
      arr.findIndex(item => {
        if (item !== undefined) {
          let isTheSame: boolean = true;
          fields.forEach(field => {
            if (item[field] !== currentItem[field]) {
              isTheSame = false;
            }
          })
          return isTheSame;
        }
      }) === i);
}

export function distinctArrayWithSplitChar(arr: any[], fields: string[], char: string): any[] {
  return arr.filter(
    (currentItem, i, arr) =>
      arr.findIndex(item => {
        if (item !== undefined) {
          let isTheSame: boolean = true;
          fields.forEach(field => {
            if (item[field].split(`${char}`)[0] !== currentItem[field].split(`${char}`)[0]) {
              isTheSame = false;
            }
          })
          return isTheSame;
        }
      }) === i);
}

export function distinctDatesArray(arr: Date[]): Date[] {
  return arr.filter(
    (currentItem, i, arr) =>
      arr.findIndex(item => {
        if (item !== undefined) {
          let isTheSame: boolean = true;
          if (item.getTime() !== currentItem.getTime()) {
            isTheSame = false;
          }
          return isTheSame;
        }
      }) === i);
}

export function numericRange(from = 0, to = 0, length = 4, maxOffset = true): number[] {
  const values: number[] = [];
  let offsetTo: number;
  if (maxOffset) {
    offsetTo = to + Math.floor(Math.ceil((to - from) / length))
  }
  let step = Math.ceil((offsetTo - from) / length);
  let i = 0;
  while (i < length) {
    values.push(Math.ceil(from + i * step));
    i++;
  }
  if (values.length > 0 && maxOffset) {
    values.push(values[values.length - 1] + 1);
  }
  return values;
}

export function chooseRandom<E = any>(array: E[]): E {
  return array[randomIntBetween(0, array.length - 1)];
}
