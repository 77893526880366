<ng-container *ngIf="propertiesData">
  <div class="row align-items-center pt-3">
    <div class="col properties-mat-accordion-container">
      <mat-accordion>
        <ng-container *ngFor="let item of panelElements; let i = index">
          <mat-expansion-panel class="prop-expans-panel" [expanded]="i==0? true: false"
                               (closed)="item.isTeleported ? onSpecialChange(item?.key): null"
                               (opened)="item?.isTeleported ? onSpecialChange(item?.key): null">
            <mat-expansion-panel-header class="prop-expans-panel-header" [collapsedHeight]="'26px'"
                                        [expandedHeight]="'26px'">
              <mat-panel-title>
                {{item.key | camelToUpper}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>

              <ng-container *ngIf="item?.isTeleported">
                <div class="teleported-panel"></div>
              </ng-container>

              <ng-container *ngIf="!item?.isTeleported">

                <ng-container *ngIf="item?.withCrudIcons">
                  <div class="row properties-content-edit-icon prop-toolbar-row">
                    <div class="col">
                      <mat-icon mat-list-icon svgIcon="edit" class="entities-tree-view-icon">
                      </mat-icon>
                    </div>
                  </div>

                  <mat-toolbar-row class="prop-toolbar-row properties-content-add-icon">
                    <div class="col prop-key-col">
                      <mat-icon class="org-crud-icon type-prop-add-icon"
                                mat-list-icon>
                        add
                      </mat-icon>
                    </div>
                  </mat-toolbar-row>
                </ng-container>

                <mat-toolbar class="prop-toolbar" [ngClass]="toolBarClass" *ngIf="(item?.value| keyvalue)?.length > 0">
                  <mat-toolbar-row class="prop-toolbar-row"
                                   *ngFor="let prop of item.value | keyvalue: returnZero; let i = index; last as isLast">
                    <div class="row prop-content-row" [classByCondition]="prop?.value == -1 && !isLast"
                         [cssClasses]="['accordion-row-marker']">
                      <ng-container *ngIf="prop?.value !== -1">
                        <div class="col prop-key-col"
                             ngbTooltip="{{item.isValueAsTooltip ? getPropValue(item.key, prop) : null}}"
                             [placement]="'right'" [closeDelay]="200" [openDelay]="100">
                          <span class="prop-content-key" [title]="item.isValueAsTooltip ? '' :  prop.key"
                                [styleProperties]="getPropValue(item.key, prop)" [key]="prop.key">
                            {{prop.key | camelToUpper | removeIndexAfterName}}
                          </span>
                        </div>
                        <div class="col prop-value-col">

                          <!--
                    Form control name and editable element key was based on the combination of the item.key name and the specific property name,
                    in order to make each of them unique
                  -->
                          <app-legacy-editable-text class="just-flex" [editableKey]="editableKey"
                                             [key]="item.key + (prop.key | lowerNoSpaces)">
                            <ng-template viewMode>

                              <span *ngIf="!item.isValueAsTooltip" class="prop-value-field"
                                    [styleProperties]="getPropValue(item.key, prop)"
                                    [title]="getPropValue(item.key, prop)">{{getPropValue(item.key, prop)}}</span>
                            </ng-template>
                            <ng-template editMode>
                              <div class="prop-form-container">
                                <form [formGroup]="editPropertiesForm" class="">
                                  <mat-form-field class="prop-value-field prop-edit-field"
                                                  *ngIf="!isEnum(prop, item.key)">
                                    <input matInput [appSetFocus]="editableKey"
                                           [key]="item.key + (prop.key | lowerNoSpaces)"
                                           value="{{getPropValue(item.key, prop)}}" appIsValueInComplete
                                           formControlName="{{item.key}}{{prop.key | lowerNoSpaces}}">
                                  </mat-form-field>
                                  <mat-form-field class="prop-value-field prop-edit-field"
                                                  *ngIf="isEnum(prop, item.key)">
                                    <mat-select formControlName="{{item.key}}{{prop.key | lowerNoSpaces}}">
                                      <mat-option *ngFor="let value of getEnumValues(prop)" [value]="value">
                                        {{value}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </form>
                              </div>
                            </ng-template>
                            <mat-icon *ngIf="editPropertiesService.isFabricPropertyIsEditable(prop.key, item.key)"
                                      mat-list-icon class="mat-list-icon edit-icon" svgIcon="edit"
                                      (click)="changeValueMode(item.key, prop.key, $event)">
                            </mat-icon>
                          </app-legacy-editable-text>
                        </div>
                      </ng-container>
                    </div>
                  </mat-toolbar-row>
                </mat-toolbar>
              </ng-container>
            </ng-template>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
</ng-container>
