import {getDatesBetweenDates} from 'src/app/shared/operators/time-operator';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {BaseDeviation, TrafficDeviation, TrafficOrigin} from '../../../models/legacy-reports.model';
import {FromToDatesStrings} from "../../../models/time.model";

export class BaseDeviationBarService {

  constructor(protected dateConvertor: TimeManagerService) {
  }

  generateFooterText(dataDisplay: any[]): string[] {
    let tooltipsArray: string[] = [];
    dataDisplay.forEach(datum => tooltipsArray.push(`<div class='justify-end-column' style='color: #0000ee; cursor: pointer; text-decoration: underline'>Analyze</div>`));
    return tooltipsArray;
  }

  findDeviation(date: string, trafficDeviation: BaseDeviation[]): BaseDeviation {
    if (trafficDeviation) {
      return trafficDeviation.find(deviation => {
        if (deviation)
          return deviation && deviation.date === date
      });
    }
  }

  getFormattedDates(datesAsString: FromToDatesStrings, withWeekDay: boolean = true): string[] {
    let labels: string[];
    withWeekDay ?
      labels = getDatesBetweenDates(new Date(datesAsString.start), new Date(datesAsString.end)).map(date => `${this.dateConvertor.dateByFormat(date, 'YYYY-MM-DD')} - ${this.dateConvertor.getWeekDayNameByDate(date)}`) :
      labels = getDatesBetweenDates(new Date(datesAsString.start), new Date(datesAsString.end)).map(date => `${this.dateConvertor.dateByFormat(date, 'YYYY-MM-DD')}`);
    return labels;
  }

  insideDeviation(deviationData: TrafficDeviation) {
    return this.getAboveDeviation(deviationData) == 0 && this.getUnderDeviation(deviationData) == 0;
  }

  getTraffic(deviationData: TrafficDeviation): number {
    if (deviationData.value) {
      if (deviationData["outlier-type"] && deviationData) {
        return +(deviationData.value - this.getAboveDeviation(deviationData)).toFixed(2);
      }
      return +deviationData.value.toFixed(2);
    }
    return null;
  }

  getUnderDeviation(deviationData: TrafficDeviation): number {
    if (deviationData["outlier-type"] && deviationData && deviationData.value && deviationData.value < deviationData.dayOfWeekMean && ((deviationData.dayOfWeekMean - deviationData.value) > deviationData.dayOfWeekStddev * 2)) {
      return +deviationData.value.toFixed(2);
    }
    return 0;
  }

  getAboveDeviation(deviationData: TrafficDeviation): number {
    if (deviationData["outlier-type"] && deviationData && deviationData.value && deviationData.value > deviationData.dayOfWeekMean && (deviationData.value - deviationData.dayOfWeekMean > deviationData.dayOfWeekStddev * 2)) {
      return +(deviationData.value - (deviationData.dayOfWeekMean + deviationData.dayOfWeekStddev * 2)).toFixed(2);
    }
    return 0;
  }

  getBarTitle(origin: TrafficOrigin) {
    switch (origin) {
      case TrafficOrigin.DEVICE:
        return "Devices";
      case TrafficOrigin.VPN_CONN:
        return "VPN Connections";
      case TrafficOrigin.APP:
        return "Apps";
      case TrafficOrigin.DEVICE_BY_CLIENTS:
        return "Connected Clients";
      case TrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return "Connected Failures";
      default:
        break;
    }
  }

  adujstResultToDates(deviationData: BaseDeviation[], datesAsString: FromToDatesStrings): BaseDeviation[] {
    let formattedTrafficDeviation: TrafficDeviation[] = [];
    let datesAsArray = this.getFormattedDates(datesAsString, false);
    datesAsArray.forEach(date => {
      let deviationByDate = this.findDeviation(date, deviationData);
      if (deviationByDate && deviationByDate != undefined) {
        formattedTrafficDeviation.push(deviationByDate as TrafficDeviation);
      } else {
        formattedTrafficDeviation.push({
          date: date,
          dayOfWeekMean: null,
          dayOfWeekStddev: null,
          value: null,
          "outlier-type": null
        })
      }
    })
    return formattedTrafficDeviation;
  }

}
