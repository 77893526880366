import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnlyGridScreensService {

  constructor() { }

  /**
   * @method getGridHeight Set the grid height, in relation to the viwo port heigh
   * the number 234 is the result sum of: 
   * The header and tab section height (88px combined) 
   * the portlet header (48)
   * the grid header(56) 
   * 2px for L&F to look better (regarding the total items div)
   * 
   */
  getGridHeight() {
    let vh = window.innerHeight - 174;
    let gridHeight = vh + "px"
    return gridHeight;
  }
}
