import {TranslateService} from '@ngx-translate/core';
import {GraphType} from '../graph-type-selector/graph-type.enum';
import {ThemeService} from '../../services/theme.service';
import {GraphTrendConfig} from '../chartjs-components/models/graph-trend-config';
import {GraphDonutConfig} from '../chartjs-components/models/graph-donut-config';
import {GraphTypeSelectorData} from '../graph-type-selector/graph-type-selector-data.model';
import {LoggerService} from '../../services/logger.service';
import {Directive} from "@angular/core";


/**
 * Helper class that implement common functionality for dashboard template components.
 * i.e. components that holds common templates for both health and action dashboard.
 * Those "template" componnents are design to hold templates and their implementation
 * and being included using template elements as opposite to being subclassed.
 */
@Directive()
export class BaseEntityDashboard {
  // The title in the dashboard - binded to the header portlet component
  trendGraphTitle: string;
  donutTitle: string;
  trendLineConfig: GraphTrendConfig;
  donutConfig: GraphDonutConfig;
  stackedAndSplitData: { splitGraphConfig: GraphTrendConfig, stackedGraphConfig: GraphTrendConfig };
  isLoading: boolean = false;
  /**
   * The data & configuration for the graph type selectors
   */
  graphTypeSelectorsData: GraphTypeSelectorData[]

  // Themes configurations
  severityTheme: { css: {}; icons: {}; colors: {}; };
  categoryTheme: { css: {}; icons: {}; colors: {}; };

  graphType: GraphType;

  readonly titleByGraphType = {
    [GraphType.DEVICE]: 'data.DASHBOARD.DEVICES',
    [GraphType.FABRIC]: 'data.DASHBOARD.FABRICS',
    [GraphType.TENANT]: 'data.DASHBOARD.TENANTS',
    [GraphType.TRAFFIC]: 'data.COMMON.TRAFFIC',
    [GraphType.VENUE]: 'data.DASHBOARD.VENUES',
  }

  constructor(
    protected translate: TranslateService,
    protected loggerFactory: LoggerService,
    private themeService: ThemeService) {
    this.severityTheme = this.themeService.getSeverityTheme()
    this.categoryTheme = this.themeService.getCategoryTheme()
  }

  /**
   * Set trend the portlet title according to the graph type selected
   * @param graphType the graph type selected
   */
  setGraphTrendTitle(graphType: GraphType) {
    this.trendGraphTitle = this.translate.instant(this.titleByGraphType[graphType])
    if (graphType == GraphType.TRAFFIC)
      this.trendGraphTitle = this.trendGraphTitle + " (MB)";
  }
}
