import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import {GenericDevice, NodeEntities, NodeEventData} from 'src/app/shared/models/topology';
import {ClientQueriesParam, ClientDevice} from 'src/app/shared/models/clients.model';
import {Router, ActivatedRoute} from '@angular/router';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {ClientsService} from 'src/app/shared/services/rest-services/clients.service';
import {ClientsTooltipDataService} from 'src/app/clients/clients-tooltip-data.service';
import {TopologyTooltipsTypes} from '../topology-tooltips-types.enum';
import {BaseTopologyToolTip} from '../topology-device-tooltip/base-topology-tooltip';
import {trigger, state, style, transition, animate} from '@angular/animations';


@Component({
  selector: 'app-single-client-device-tooltip',
  templateUrl: './single-client-device-tooltip.component.html',
  styleUrls: ['./single-client-device-tooltip.component.scss'],
  animations: [
    //Source: https://fireship.io/lessons/angular-animations-examples/
    trigger('hoverTooltip', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('0ms ease-out')),
      transition('hide => show', animate('1500ms ease-in'))
    ])
  ]
})
export class SingleClientDeviceTooltipComponent extends BaseTopologyToolTip {
  constructor(protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private clientsService: ClientsService,
              private clientsTooltipDataService: ClientsTooltipDataService,
              public healthColorsService: HealthColorsService,
  ) {
    super(TopologyTooltipsTypes.SingleClientTooltip, router, activatedRoute)
  }


  initiateNodeEventData() {
    if (this.nodeEvent) {
      this.node = this.nodeEvent;
      if (this.node) {
        this.title = this.node.d.data.name;
        this.initiateDataForView(this.node);
      }
    }
    if (!this.nodeEvent && !this.isHoverTooltipElement) {
      this.node = null;
    }
  }

  initiateDataForView(nodeEvent: NodeEventData<GenericDevice<NodeEntities>>) {
    this.tooltipContent = this.clientsTooltipDataService.prepareDataForSingelClient(nodeEvent.d.data.originalData as ClientDevice);
  }

  ngOnDestroy() {
    this.subsc.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
}
