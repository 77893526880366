import {ForceDirectedGraphNode} from 'src/app/shared/components/force-directed-graph/models/force-directed-graph-node';
import {VenueVpnMode, VPNSite} from 'src/app/shared/models/vpn.model';
import {DeviceState} from "../../../../shared/models/devices.model";
import {VenueType} from "../../../../shared/models/venues.model";

export class VPNForceNode extends ForceDirectedGraphNode<VPNSite> {

  constructor(id: number, name: string, type: string, label: string, data: VPNSite) {
    super(id, name, type, label, data)
  }

  get r(): number {
    if (this.data.venueVpnMode && this.data.venueVpnMode.toLowerCase() === VenueVpnMode.HUB.toLowerCase()) {
      return this.topRightIconPath ? 55 : 50;
    } else {
      return this.topRightIconPath ? 35 : 30;
    }
  }

  get fontSize(): number {
    return this.r / 2.5;
  }

  get color(): string {
    switch (this.data.status) {
      case DeviceState.Online:
        return '#7dd090';
      case DeviceState.Offline:
        return '#f7685b';
      case DeviceState.Alerting:
        return '#ffb946';
      case DeviceState.Unknown:
        return '#C4C4C4';
      default:
        break;
    }
  }

  get topRightIconPath() {
    switch (this.data.venueType) {
      case VenueType.CAMPUS:
        return 'assets/media/netop/organization2.svg';
      case VenueType.CLOUD:
        if (this.data.vendorName === 'gcp') {
          return 'assets/media/netop/topology/google-cloud-icon.svg';
        }
        if (this.data.vendorName === 'aws') {
          return 'assets/media/netop/topology/aws-cloud-icon.svg';
        }
      case VenueType.SASE:
        return 'assets/media/netop/topology/cloud-firewall.svg';
    }
  }
}
