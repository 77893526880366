import {Entity} from 'src/app/shared/models/entity.model';
import {ReportsEntities, ReportsStoreService} from 'src/app/shared/pages/reports/services/reports-store.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ChangeDetectorRef, Input, Directive} from '@angular/core';
import {TrafficOrigin, TopTrafficOrigin} from '../../../models/legacy-reports.model';
import {KpiType} from 'src/app/shared/models/kpi.model';
import {FromToDatesStrings} from "../../../models/time.model";


@Directive()
export class BaseReportsWidgets {
  @Input() origin: TrafficOrigin | TopTrafficOrigin;
  @Input() kpiType: KpiType;
  currentEntity: Entity;
  currentTenant: Entity;
  currentOrg: Entity;
  datesAsString: FromToDatesStrings;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];


  constructor(protected reportsStoreService: ReportsStoreService,
              protected dateConvertor: TimeManagerService,
              protected translate: TranslateService,
              protected cdr: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.loadFiltersData();
  }

  loadFiltersData() {
    let allFiltersSubsc = this.reportsStoreService.notifyAllFiltersSelectionObservable$.subscribe(allFilters => {
      if (allFilters && this.isEntitiesChanged(allFilters.allEntities.value)) {
        this.currentEntity = allFilters.allEntities.value.selectedEntity;
        this.currentTenant = allFilters.allEntities.value.selectedTenant;
        this.currentOrg = allFilters.allEntities.value.selectedOrg;
        let datesAsString: FromToDatesStrings = {
          start: this.dateConvertor.dateByFormat(allFilters.timeSelection.value.start, 'YYYY-MM-DD'),
          end: this.dateConvertor.dateByFormat(allFilters.timeSelection.value.end, 'YYYY-MM-DD'),
        }
        this.datesAsString = datesAsString;
        this.loadData();
      }
    })
    this.subscriptions.push(allFiltersSubsc);
  }

  loadData() {
  }

  destroyProcedure() {
    this.subscriptions.forEach(subsc => subsc.unsubscribe());
  }

  private isEntitiesChanged(value: ReportsEntities) {
    return (!this.currentEntity || this.currentEntity && value.selectedEntity.id !== this.currentEntity.id) ||
      (!this.currentOrg || this.currentOrg && value.selectedOrg.id !== this.currentOrg.id) ||
      (!this.currentTenant || this.currentTenant && value.selectedTenant.id !== this.currentTenant.id);
  }
}
