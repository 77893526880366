import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SingleChangeLog, ChangeType, AggregatedChangedEntitites } from '../../models/change-log.model';
import { SingleDeviceType } from '../../models/single-device.model';
import { D3NodeService } from '../network-topology/services/d3-node.service';
import { Subscription } from 'rxjs';
import { isChangeDevice } from './sync-entity-operators';
import { EditStringsService } from '../../services/strategies/edit-strings.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-synced-entity-properties',
  templateUrl: './synced-entity-properties.component.html',
  styleUrls: ['./synced-entity-properties.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SyncedEntityPropertiesComponent implements OnInit {
  @Input() singleChange: SingleChangeLog;
  subsc: Subscription;
  changeType = ChangeType;
  constructor(
    private d3NodeService: D3NodeService,
    private editStrings: EditStringsService
  ) { }

  ngOnInit() {
  }

  get iconPath() {
    if (this.singleChange) {
      if (isChangeDevice(this.singleChange.entityClassName))
        return this.d3NodeService.getInitialIconPath(this.singleChange.entityClassName as SingleDeviceType);
      else {
        if (this.singleChange.entityClassName.toLowerCase() == AggregatedChangedEntitites.SSID.toLowerCase())
          return this.d3NodeService.getInitialIconPath(SingleDeviceType.AccessPoint);
        if (this.singleChange.entityClassName.toLowerCase() == AggregatedChangedEntitites.VLAN.toLowerCase())
          return 'assets/media/netop/fabric.svg';
        if (this.singleChange.entityClassName.toLowerCase() == AggregatedChangedEntitites.SwitchStack.toLowerCase())
          return 'assets/media/netop/switch-stack.svg';
      }
    }
  }

  get formmatedEntitiyClassName() {
    if (this.singleChange) {
      if (isChangeDevice(this.singleChange.entityClassName))
        return this.editStrings.convertKeytoCamelCaseWord(this.singleChange.entityClassName);
      return this.singleChange.entityClassName;
    }
  }

  get newValueClass() {
    if (this.singleChange.type.toLowerCase() == this.changeType.ADDED.toLowerCase())
      return 'changed-text-green';
    if (this.singleChange.type.toLowerCase() == this.changeType.MODIFIED.toLowerCase())
      return 'changed-text-blue';
  }
  get oldValueClass() {
    return this.singleChange.type.toLowerCase() == this.changeType.REMOVED.toLowerCase() ? 'changed-text-red' : '';
  }
}
