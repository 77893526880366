import {Component, OnInit, Input} from '@angular/core';
import {TopologyTooltipsTypes} from '../topology-tooltips-types.enum';
import {isClient, isD3CloudNode, isMultiClient, isRegularDevice} from '../../operators/topology-operators';
import {Subscription} from 'rxjs';
import {SingleDevice} from 'src/app/shared/models/single-device.model';
import {GenericDevice, NodeEventData} from 'src/app/shared/models/topology';
import {D3TreeEventsService} from '../../services/d3-tree-events.service';

@Component({
  selector: 'app-topology-tooltips-list',
  templateUrl: './topology-tooltips-list.component.html',
  styleUrls: ['./topology-tooltips-list.component.scss']
})

export class TopologyTooltipsListComponent implements OnInit {
  @Input() tplgRect: DOMRect;
  currentTooltipType: TopologyTooltipsTypes;
  subcs: Subscription;
  nodeEvent: NodeEventData<GenericDevice<SingleDevice>>;
  public tooltipTypes = TopologyTooltipsTypes;

  constructor(private d3TreeEventsService: D3TreeEventsService) {
  }

  ngOnInit() {
    this.subscribeToNodeEvent();
  }

  subscribeToNodeEvent() {
    this.subcs = this.d3TreeEventsService.notifyToolTipObservable$.subscribe(nodeEvent => {
      if (nodeEvent) {
        this.nodeEvent = nodeEvent;
        switch (true) {
          case isClient(nodeEvent.d):
            this.currentTooltipType = TopologyTooltipsTypes.SingleClientTooltip;
            break;
          case isMultiClient(nodeEvent.d):
            this.currentTooltipType = TopologyTooltipsTypes.MultiClientTooltip;
            break;
          case (isRegularDevice(nodeEvent.d)):
            this.currentTooltipType = TopologyTooltipsTypes.SingleDeviceTooltip;
            break;
          case (isD3CloudNode(nodeEvent.d)):
            this.currentTooltipType = TopologyTooltipsTypes.CloudeNodeTooltip;
            break;
          default:
            break;
        }
      } else
        this.nodeEvent = null;
    });
  }

  ngOnDestroy() {
    this.subcs.unsubscribe();
  }

}
