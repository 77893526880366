import {TimeApi, TimeUnit} from "../models/time.model";

export class TimeUtils {
  static readonly second = 1000;
  static readonly minute = TimeUtils.second * 60;
  static readonly hour = TimeUtils.minute * 60;
  static readonly day = TimeUtils.hour * 24;
  static readonly month = Math.floor((TimeUtils.day * 365) / 12);

  public static interoperateTimeApiByRange(timeApi: TimeApi): TimeApi {
    if (timeApi) {
      switch (timeApi.timeUnit) {
        case TimeUnit.MONTHS: {
          return {
            timeUnit: TimeUnit.DAYS,
            timeBack: 30,
            specificDate: timeApi.specificDate
          }
        }
        case TimeUnit.DAYS: {
          return {
            timeUnit: TimeUnit.HOURS,
            timeBack: 48,
            specificDate: timeApi.specificDate
          }
        }
        default:
          break;
      }
    }
    return timeApi;
  }

  /**
   * Return The last second  23:59:59 of the given day of given date
   * @param date
   */
  public static getTheEndOfTheDay(date: Date): Date {
    const actualDate = new Date(date);
    const endOfDayDate = new Date(actualDate.getFullYear(),
      actualDate.getMonth(),
      actualDate.getDate(), 23, 59, 59);
    return endOfDayDate;
  }

  /**
   * Return The midnight hour 00:00 of the next day of given date
   * @param date
   */
  public static getMidNightOfTheNextDay(date: Date): Date {
    const currentDate = new Date(date);
    const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
    const tomorrowMidnight = new Date(tomorrow.setHours(0, 0, 0, 0));
    return tomorrowMidnight;
  }

  public static calculateTotalMinutesBetweenTwoDates(startDate: Date, endDate: Date): number {
    let diffMs = Math.abs(startDate.getTime() - endDate.getTime());
    return Math.round((diffMs / 1000) / 60);
  }

  /**
   * Round date to the nearest upper 5 minutes point
   * @param date
   */
  public static roundDateMinutes(date: Date) {
    if (date.getMinutes() !== 0 && date.getMinutes() !== 5) {
      const newDate = new Date(date);
      const round = 1000 * 60 * 5;
      const rounded = new Date(Math.ceil(newDate.getTime() / round) * round);
      return rounded;
    }
    return date;
  }

  public static getDates(timeBack: number, timeUnit: TimeUnit, date: Date) {
    date ??= new Date();
    return {
      start: new Date(date.getTime() - timeBack * TimeUnit.milliseconds(timeUnit)),
      end: date
    };
  }

  public static getPeriod(milliseconds: number): {interval: number, timeUnit: TimeUnit} {
    let interval: number;
    let timeUnit: TimeUnit;

    if (milliseconds < this.second) {
      interval = milliseconds / 1000;
      timeUnit = TimeUnit.MILLIS;
    }

    else if (milliseconds < this.minute) {
      interval = milliseconds / this.second;
      timeUnit = TimeUnit.SECONDS;
    }

    else if (milliseconds < this.hour) {
      interval = milliseconds / this.minute;
      timeUnit = TimeUnit.MINUTES;
    }

    else if (milliseconds < this.day) {
      interval = milliseconds / this.hour;
      timeUnit = TimeUnit.HOURS;
    }
    else {
      interval = milliseconds / this.day;
      timeUnit = TimeUnit.DAYS;
    }

    interval = Math.ceil(interval);
    return {interval, timeUnit};
  }

  public static getTimeUnitMilliseconds(timeUnit: TimeUnit): number {
    switch (timeUnit) {
      case TimeUnit.NANOS:
      case TimeUnit.MICROS:
      case TimeUnit.MILLIS:
        return 1;
      case TimeUnit.SECONDS:
        return this.second;
      case TimeUnit.MINUTES:
        return this.minute;
      case TimeUnit.HOURS:
        return this.hour;
      case TimeUnit.DAYS:
        return this.day;
      case TimeUnit.MONTHS:
        return this.month;
    }
  }

  public static toGranularity(interval: number, timeUnit: TimeUnit, numIntervals: number): {interval: number, timeUnit: TimeUnit} {
    const milliseconds = (this.getTimeUnitMilliseconds(timeUnit) * interval) / numIntervals;
    return this.getPeriod(milliseconds);
  }

  public static toTimeBack(interval: number, timeUnit: TimeUnit, numIntervals: number): {interval: number, timeUnit: TimeUnit} {
    const milliseconds = this.getTimeUnitMilliseconds(timeUnit) * interval * numIntervals;
    return this.getPeriod(milliseconds);
  }

  public static getTimeBack(fromDate: Date, toDate: Date): {interval: number, timeUnit: TimeUnit}  {
    return this.getPeriod(toDate.getTime() - fromDate.getTime());
  }
}
