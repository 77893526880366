import { BaseDashboardEntityList } from './base-dashboard-entity-list';
import { StoreUserPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import { ActionStatus } from 'src/app/shared/models/actions.model';
import { BaseEntityActionsService } from 'src/app/shared/services/rest-services/base-entity-actions.service';
import { take } from 'rxjs/operators';
import { ActionsFilterState } from 'src/app/shared/models/actions-filter-state.model';
import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { EntityGridActionsMenuService } from 'src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/entity-grid-actions-menu.service';
import { StoreWidgetPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {GridTypes, GridUserPreferencesOptions} from "../../../models/client-storage.model";
import {EntityType} from "../../../models/entity-type.enum";
import {Entity} from "../../../models/entity.model";

@Directive()
export class ActionsDashboardEntityList extends BaseDashboardEntityList {
  @Input() currentEntity: Entity;

    get selectedActionType() {
        return this._selectedActionType;
    }
    // @Output() selectedActionTypeChange: EventEmitter<string> = new EventEmitter()
    @Input()
    set selectedActionType(actionType: string) {
        let actionTypeChanged = false;
        if (this._selectedActionType != actionType) {
            actionTypeChanged = true;
        }
        this._selectedActionType = actionType;
        if (actionTypeChanged && this.gridApi) {
            // If the action type was change reload the actions list
            this.gridApi.setDatasource(this.dataSource);
        }
    }
    getSelectedActionType(): string {
        return this._selectedActionType;
    }
    _selectedActionType: string;

    constructor(storeUserPreferencesService: StoreUserPreferencesService,
        protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
        protected entityActionsService: BaseEntityActionsService,
        gridType: GridTypes,
        private entityGridActionsMenuService: EntityGridActionsMenuService) {
        super(storeUserPreferencesService, storeWidgetPreferencesService, gridType);

    }
    onActionPerformed(actionId: number, nextAction: ActionStatus, entityType?: EntityType, entityId?: number) {
        console.log(`action id ${actionId} of type ${nextAction} performed`);
        this.entityActionsService.performAction(actionId, nextAction, entityType ?? this.currentEntity?.type, entityId ?? this.currentEntity?.id).pipe(
            take(1),
        ).subscribe(() => {
            this.gridApi.refreshInfiniteCache();
            this.entityGridActionsMenuService.actionPerformed.next(nextAction);
        });
    }
    /**
     * Helper method to set action type filter
     * @param filter action type filter to set
     */
    protected setGridActionTypeFilter(filter: ActionsFilterState) {
        this.storeUserPreferencesService.setCurrentGridPreference(GridUserPreferencesOptions.actionFilter, filter);
    }
}
