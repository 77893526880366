import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, zip} from 'rxjs';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {Store} from '@ngrx/store';
import {IAppState} from 'src/app/store/state/app.state';
import {Topology} from 'src/app/shared/models/topology';
import {FabricsService} from './fabrics.service';
import {KpiService} from './kpi.service';
import {SingleDeviceType, SingleDevice} from '../../models/single-device.model';
import {Fabric, FabricKpiData, FabricTotalData} from '../../models/fabrics.model';
import {PolicyService} from './policy.service';
import {EntityType} from '../../models/entity-type.enum';
import {SingleLink} from '../../models/single-link.model';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VenueSingleStoreService extends GlobalEntitiesService {
  private notifyFabric: BehaviorSubject<FabricTotalData> = new BehaviorSubject(null);
  notifyFabricObservable$ = this.notifyFabric.asObservable();
  fabricTotalData: FabricTotalData;
  fabricData: FabricKpiData;
  topology: Topology<SingleDevice, SingleLink>;
  health: number;

  constructor(
    store: Store<IAppState>,
    private fabricService: FabricsService,
    private kpiService: KpiService,
    private policyService: PolicyService
  ) {
    super(store)
  }

  getFabricData(fabric: Fabric) {
    return zip(
      this.policyService.getPoliciesByEntity({id: fabric.id, name: fabric.name, type: EntityType.FABRIC}),
      this.fabricService.getFabricMetaDataById(fabric.id),
      this.fabricService.getFabricTopologyById(fabric.id),
      this.kpiService.getKpiSelectedFabricCurrentValue(fabric.id))
  }

  getFabricTotalData(fabric: Fabric): Observable<FabricTotalData> {
    return this.getFabricData(fabric).pipe(
      map(data => ({
        fabricData: {entityData: data[1], policiesData: data[0]},
        fabricTopology: data[2],
        health: +data[1]?.health
      }))
    );
  }

  changeTopologyWWWId(fabricTopology: Topology<SingleDevice, SingleLink>): Topology<SingleDevice, SingleLink> {
    fabricTopology.nodes.forEach(node => {
      if (node.device.type == SingleDeviceType.WWW)
        node.device.id = "666";
    })
    return fabricTopology;
  }

  emptyFabricSelection() {
    this.notifyFabric.next(null);
  }
}
