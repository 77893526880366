import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'limitStringLength'
})
export class LimitStringLengthPipe implements PipeTransform {

  transform(str: string, maxStringLength: number): string {
    if (str) {
      if (str.length <= maxStringLength) {
        return str;
      }
      return `${str.substring(0, maxStringLength)}...`;
    }
  }
}
