import {BaseTopologyToolTip} from "./base-topology-tooltip";
import {ActivatedRoute, Router} from "@angular/router";
import {HealthColorsService} from "../../../../services/strategies/health-colors.service";
import {TopologyTooltipsTypes} from "../topology-tooltips-types.enum";
import {GenericDevice, NodeEventData} from "../../../../models/topology";
import {SingleDevice, SingleDeviceType} from "../../../../models/single-device.model";
import {FailedConnection} from "../../../../models/clients.model";
import {Directive} from "@angular/core";

@Directive()
export class BaseHealthTopologyTooltip extends BaseTopologyToolTip {

  isHealthNotCalculated: boolean = false;
  isFailedConnection: boolean = false;
  failedClients: FailedConnection[] = [];

  constructor(tooltipType: TopologyTooltipsTypes,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected healthColorsService: HealthColorsService) {
    super(tooltipType, router, activatedRoute);
  }

  initiateNodeEventData() {
    if (this.nodeEvent) {
      this.node = this.nodeEvent;
      this.isDeviceHasFailedConnection(this.node as NodeEventData<GenericDevice<SingleDevice>>);
      if (this.node) {
        this.title = this.node.d.data.name;
        if (this.node.d.data.type == SingleDeviceType.WWW)
          this.title = "Internet Connectivity"
        if (!(this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData?.healthDegradationReasons?.length) {
          if ((this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData?.health !== -1 && (this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData?.healthDegradationReasons)
            (this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData.healthDegradationReasons[0] = {
              percentage: +(this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData?.health,
              reason: "Health"
            }
          if ((this.node as NodeEventData<GenericDevice<SingleDevice>>).d.data.originalData?.health == -1)
            this.isHealthNotCalculated = true;
        }
      }
    }
    if (!this.nodeEvent && !this.isHoverTooltipElement) {
      this.node = null;
    }
  }

  isDeviceHasFailedConnection(node: NodeEventData<GenericDevice<SingleDevice>>) {
    if (node.d.data.originalData) {
      if (node.d.data.originalData?.failedClients) {
        this.failedClients = node.d.data.originalData?.failedClients;
        this.isFailedConnection = true;
        this.tooltipType = TopologyTooltipsTypes.FailedClientsTooltip;
      }
      if (!node.d.data.originalData?.failedClients) {
        this.isFailedConnection = false;
        this.tooltipType = TopologyTooltipsTypes.SingleDeviceTooltip;
      }
    }
  }

  /**
   * @method getPercentageColor return colors for the fabric value in the properties header
   * the methode is activated by the template, and inject the restult with ngStyle
   * @param health represent the current element health
   */
  getPercentageColor(health: number) {
    return this.healthColorsService.getHealthColors(health);
  }
}
