import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef, QueryList, ViewChildren
} from '@angular/core';
import {HealthColorsService} from '../../services/strategies/health-colors.service';
import {NgxGaugeConfiguration} from './gauge.model';
import { v4 as uuid } from 'uuid';;
import {NgChanges} from '../../extend-angular-classes/on-changes';


@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeComponent implements OnInit {
  @Input() ngxGaugeConfiguration: NgxGaugeConfiguration;
  labelId = uuid();
  @ViewChildren('reading-label') labelList: QueryList<ElementRef>
  @ViewChildren('reading-block') bloackList: QueryList<ElementRef>

  constructor(private healthColorService: HealthColorsService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: NgChanges<GaugeComponent>) {
    if (changes.ngxGaugeConfiguration.currentValue)
      this.initiateGaugeConfiguration();
  }

  /**
   * The initilizer for the ngxGaugeConfiguration variables.
   * When creating the ngxGaugeConfiguration on paren component, the user is ought to set only to
   * parameters: value, and label.
   */
  private initiateGaugeConfiguration() {
    if (this.ngxGaugeConfiguration) {
      this.ngxGaugeConfiguration.type = "arch";
      this.ngxGaugeConfiguration.min = 0;
      this.ngxGaugeConfiguration.size = this.gaugeSize;
      this.ngxGaugeConfiguration.thick = 10;
      this.ngxGaugeConfiguration.appendText = "Mbps";
      let thresholdKeyValues = this.calculateThreshold();
      this.ngxGaugeConfiguration.thresholdConfig = {
        [thresholdKeyValues[0]]: {color: this.healthColorService.SEVERITY_COLORS[2]},
        [thresholdKeyValues[1]]: {color: this.healthColorService.SEVERITY_COLORS[1]},
        [thresholdKeyValues[2]]: {color: this.healthColorService.SEVERITY_COLORS[0]}
      }
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    const labelElement: HTMLElement = document.getElementById(this.labelId);
    labelElement.style.fontSize = `${this.gaugeSize / 8.9}px`;
    const readingBlockCount = document.getElementsByClassName('reading-block').length;
    for (let i = 0; i <= readingBlockCount - 1; i++) {
      const element: HTMLElement = document.getElementsByClassName('reading-block')[i] as HTMLElement;
      element.style.fontSize = `${this.gaugeSize / 5.8}px`;
    }
  }

  private calculateThreshold() {
    return [0, this.ngxGaugeConfiguration.max * 55 / 100, this.ngxGaugeConfiguration.max * 85 / 100];
  }

  get gaugeSize() {
    return 80;
  }

}
