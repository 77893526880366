import {Directive, HostListener, Input} from '@angular/core';
import {ExportFileService} from "../services/export-file.service";

@Directive({
  selector: '[exportToExcel]'
})
export class ExportToExcelDirective {

  constructor(private exportFileService: ExportFileService) { }
  @Input('exportToExcel') data: any[];
  @Input() fileName: string;

  @HostListener('click', ['$event']) onClick($event) {
    this.exportFileService.exportExcel(this.data, this.fileName);
  }

}
