import { IAppState } from "../state/app.state";
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromCurrentEntity from '../reducers/current-entities.reducer';
import { Entity } from 'src/app/shared/models/entity.model';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { Hierarchy } from 'src/app/shared/models/hierarchy.model';
import { selectHierarchy } from './hierarchy.selector';


export const selectCurrentEntitySate = createFeatureSelector<IAppState, fromCurrentEntity.State>(
    fromCurrentEntity.currentEntityFeatureKey
);//(state: IAppState) => state[fromCurrentEntity.currentEntityFeatureKey];

export const selectCurrentEntity = createSelector(
    selectCurrentEntitySate,
    (state: fromCurrentEntity.State) => state.currentEntity
);

export const selectPrevEntity = createSelector(
    selectCurrentEntitySate,
    (state: fromCurrentEntity.State) => state.prevEntity
);

export const selectNextEntity = createSelector(
    selectCurrentEntitySate,
    (state: fromCurrentEntity.State) => state.nextEntity
);

export const selectCurrentTenant = createSelector(
    selectCurrentEntity,
    (state: Entity) => {
        if (state && state.type == EntityType.TENANT) {
            return state;
        }
        return null;
    }
);
export const selectCurrentVenue = createSelector(
    selectCurrentEntity,
    (state: Entity) => {
        if (state && state.type == EntityType.VENUE) {
            return state;
        }
        return null;
    }
);
export const selectCurrentDevice = createSelector(
    selectCurrentEntity,
    (state: Entity) => {
        if (state && state.type == EntityType.DEVICE) {
            return state;
        }
        return null;
    }
);
export const selectCurrentFabric = createSelector(
    selectCurrentEntity,
    (state: Entity) => {
        if (state && state.type == EntityType.FABRIC) {
            return state;
        }
        return null;
    }
);

export const selectCurrentOrg = createSelector(
    selectCurrentEntity,
    (state: Entity) => {
        if (state && state.type == EntityType.ORGANIZATION) {
            return state;
        }
        return null;
    }
);

export const selectClosestTenantId = createSelector(
    selectCurrentEntity,
    selectHierarchy,
    (state: Entity, hierarchy: Hierarchy) => {
        // If the current entity is a tenant return it's id
        if (state && state.type == EntityType.TENANT) {
            return state.id;
        }
        // Go over the hierarchy backward till reached the first organization
        const org = hierarchy.slice(0).reverse().find(step => step.type == EntityType.TENANT)
        return org ? org.id : undefined;
    }
);

export const selectClosestTenant = createSelector(
    selectCurrentEntity,
    selectHierarchy,
    (state: Entity, hierarchy: Hierarchy) => {
        // If the current entity is a tenant return it's id
        if (state && state.type == EntityType.TENANT) {
            return state;
        }
        // Go over the hierarchy backward till reached the first organization
        const org = hierarchy.slice(0).reverse().find(step => step.type == EntityType.TENANT)
        console.log("closest tenat found %o", org)
        return org;
    }
);
export const selectClosestVenue = createSelector(
    selectCurrentEntity,
    selectHierarchy,
    (state: Entity, hierarchy: Hierarchy) => {
        // If the current entity is a tenant return it's id
        if (state && state.type == EntityType.VENUE) {
            return state;
        }
        // Go over the hierarchy backward till reached the first Venue
        const venue = hierarchy.slice(0).reverse().find(step => step.type == EntityType.VENUE)
        console.log("closest venue found %o", venue);
        return venue;
    }
);

export const selectClosestOrgId = createSelector(
    selectCurrentEntity,
    selectHierarchy,
    (state: Entity, hierarchy: Hierarchy) => {
        // If the current entity is an organization return it's id
        if (state && state.type == EntityType.ORGANIZATION) {
            return state.id;
        }
        // Go over the hierarchy backward till reached the first organization
        const org = hierarchy.slice(0).reverse().find(step => step.type == EntityType.ORGANIZATION)
        return org ? org.id : undefined;
    }
);
