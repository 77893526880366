import { Injectable } from '@angular/core';
import { SingleChangeLog, SingleChangesLogDetails, ChangeType } from 'src/app/shared/models/change-log.model';
import { sortArrayByParam } from 'src/app/shared/operators/array.operator';

@Injectable({
  providedIn: 'root'
})
export class ChangesFlatService {

  constructor() { }

  /**
   * Search for objects and convert them into flat change
   */
  flatChangesArray(changes: SingleChangeLog[]) {
    changes.forEach(allChanges => {
      allChanges.changes = this.createNewChangesArray(allChanges.changes);

    });
    return changes.sort(sortArrayByParam('entityName', "asc"));
  }
  /**
   * Return flat change for object values
   * @param change Single Change Log change
   */
  createNewChangesArray(changes: SingleChangesLogDetails[]): SingleChangesLogDetails[] {
    let flattenChanges: SingleChangesLogDetails[] = [];
    changes.forEach(change => {
      if (change.type.toLowerCase() == ChangeType.ADDED.toLowerCase())
        flattenChanges = flattenChanges.concat(this.flatOneChangeValues(change, "newValue"));
      if (change.type.toLowerCase() == ChangeType.REMOVED.toLowerCase())
        flattenChanges = flattenChanges.concat(this.flatOneChangeValues(change, "oldValue"));
      if (change.type.toLowerCase() == ChangeType.MODIFIED.toLowerCase())
        flattenChanges = flattenChanges.concat(this.flatModifiedValues(change));
    })
    return flattenChanges.sort(sortArrayByParam('propertyName', "asc"));
  }
  /**
   * Flatten Modified values
   * The method take in consideration the fact that each objects can have different properties
   */
  private flatModifiedValues(change: SingleChangesLogDetails): SingleChangesLogDetails[] {
    let newSingleChangesLogDetails: SingleChangesLogDetails[] = [];
    if (change.oldValue && change.oldValue) {
      if (typeof change.oldValue === "object" && typeof change.newValue === "object") {
        let oldValuesAsArray = change.oldValue ? Object.entries(change.oldValue).sort() : [];
        let newValuesAsArray = change.newValue ? Object.entries(change.newValue).sort() : [];
        let longerArrayLength = oldValuesAsArray.length;
        if (newValuesAsArray.length > oldValuesAsArray.length)
          longerArrayLength = newValuesAsArray.length;
        for (let i = 0; i < longerArrayLength; i++) {
          if (this.sameKeyName(oldValuesAsArray[i], newValuesAsArray[i]))
            newSingleChangesLogDetails.push(this.getSameModifiedPropertyAsChange(change, oldValuesAsArray[i], newValuesAsArray[i]))
          else
            newSingleChangesLogDetails = newSingleChangesLogDetails.concat(this.getDifferentModifiedPropertyAsChange(change, oldValuesAsArray[i], newValuesAsArray[i]))
        }
      }
      else newSingleChangesLogDetails.push(change);
    }
    return newSingleChangesLogDetails;
  }
  /**
   * If new and old values objects has different properties, return one change object for each 
   * of them
   */
  private getDifferentModifiedPropertyAsChange(change: SingleChangesLogDetails, oldValues: [string, unknown], newValues: [string, unknown]): SingleChangesLogDetails[] {
    let newSingleChangesLogDetails: SingleChangesLogDetails[] = [];
    newSingleChangesLogDetails.push({
      propertyName: oldValues ? change.propertyName + "." + oldValues[0] : change.propertyName,
      type: change.type,
      oldValue: oldValues ? oldValues[1] : null,
      newValue: 0
    })
    newSingleChangesLogDetails.push({
      propertyName: newValues ? change.propertyName + "." + newValues[0] : change.propertyName,
      type: change.type,
      newValue: newValues ? newValues[1] : null,
      oldValue: 0
    })
    return newSingleChangesLogDetails;
  }
  /**
   * return boolean of to properties of modified change has the same key (i.e., Same property)
   */
  private sameKeyName(oldValues: [string, any], newValues: [string, any]): boolean {
    return oldValues && newValues && oldValues[0] == newValues[0];
  }
  /**
   * If the old and new values objectes has the same property, the method will return new change 
   * object with relevant values
   */
  private getSameModifiedPropertyAsChange(change: SingleChangesLogDetails, oldValues: [string, any], newValues: [string, any]): SingleChangesLogDetails {
    return {
      propertyName: change.propertyName + "." + oldValues[0],
      type: change.type,
      oldValue: oldValues ? oldValues[1] : null,
      newValue: newValues ? newValues[1] : null,
    }
  }
  /**
   * Return flat change object for changes with object values
   * If the change is already flat - return the chagne
   */
  private flatOneChangeValues(change: SingleChangesLogDetails, valueType: ("oldValue" | "newValue")): SingleChangesLogDetails[] {
    let newSingleChangesLogDetails: SingleChangesLogDetails[] = [];
    if (change[valueType]) {
      if (typeof change[valueType] === "object") {
        for (let [key, value] of Object.entries(change[valueType])) {
          if (value)
            newSingleChangesLogDetails.push({
              propertyName: change.propertyName + "." + key,
              type: change.type,
              [valueType]: value
            })
        }
      }
      else newSingleChangesLogDetails.push(change);
    }
    return newSingleChangesLogDetails;
  }
}
