import {Injectable} from '@angular/core';
import {BasicTopologyNode, Topology} from '../../models/topology';
import {KpiData, KpiType} from '../../models/kpi.model';
import { MatDialogConfig, MatDialog} from '@angular/material/dialog';
import {ActionKpiDialogComponent} from '../../modals/action-kpi-dialog/action-kpi-dialog.component';
import {Logger} from '../../services/logger.service';
import {RowClickedEvent} from 'ag-grid-community';
import {ActionConfigurationDialogComponent} from '../../modals/action-configuration-dialog/action-configuration-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SingleEntityService {

  constructor() {
  }


  calculateTotalIssues(topology: Topology<any, any>): number {
    let issues: number = 0;
    if (topology) {
      topology.nodes.forEach(node => {
        if (node.issues) {
          issues = +node.issues.length;
        }
      });
      return issues;
    }
  }

  /**
   * @method getWidgetWidth Change the class of the first health widget
   * @param prop The Widget data object
   */
  getWidgetWidth(prop: KpiData) {
    if (prop.key == KpiType.Health) {
      return "health-widget-bar-container";
    }
  }

  openActionListModal(logger: Logger, dialog: MatDialog, event: RowClickedEvent) {
    logger.debug("Row clicked data is: %o", event);
    if (event.data.type === "ConfigurationChange") {
      return this.openActionConfigurationDialog(dialog, event);
    } else {
      return this.openActionKpiDialog(dialog, event);
    }
  }

  openActionConfigurationDialog(dialog: MatDialog, event: RowClickedEvent) {
    const height = 570;
    const width = 900;
    if (event) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        rowData: event.data,
      };
      dialogConfig.height = height + 'px';
      dialogConfig.width = width + 'px';
      return dialog.open(ActionConfigurationDialogComponent, dialogConfig);
    }
  }

  openActionKpiDialog(dialog: MatDialog, event: RowClickedEvent) {
    const height = 800;
    const width = 1500;
    if (event) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        rowData: event.data,
        graphTrendHeight: height / 4,
        graphTrendWidth: width / 2.5
      };
      dialogConfig.height = height + 'px';
      dialogConfig.width = width + 'px';
      return dialog.open(ActionKpiDialogComponent, dialogConfig);
    }
  }

  calculateNodeAndLinkIssues(clickedElement: BasicTopologyNode) {
    let issues = 0;
    if (clickedElement.issues && clickedElement.issues.length > 0) {
      issues = clickedElement.issues.length;
    }
    return issues;
  }
}
