import { NgxGaugeType, NgxGaugeCap } from 'ngx-gauge/gauge/gauge';

export class NgxGaugeConfiguration {
    //: Source: https://github.com/ashish-chopra/ngx-gauge#readme
    /**
     * Specifies the current value of the Gauge in the range specified by min and max. It is a required attribute.
     */
    value: number;
    /**
     * Specifies the text to display below the Gauge's reading.	
     */
    label: string;
    /**
     * Specifies the gauge's type ("full", "semi", "arch")
     */
    type?: NgxGaugeType;
    /**
     * Specified the thickness of the gauge's bar.	
     */
    thick?: number;
    /**
     * Specified the maximum numeric value for gauge's scale.	
     */
    max?: number;
    /**
     * Specifies the minimum numeric value for gauge's scale.	
     */
    min?: number;
    /**
     * Specifies the size of the canvas in which Gauge will be drawn. It is used as width and height both.	
     */
    size?: number;
    /**
     * Specifies an object of threshold values at which the gauge's color changes. Checkout an example here.
     */
    thresholdConfig?: {};
    /**
     * Specifies the duration (in milliseconds) of the Gauge's animation	
     */
    duration?: number;
    /**
     * The style of line ending at the gauge's end	"round", "butt").
     */
    cap?: NgxGaugeCap;
    /**
     * Specifies the foreground color of the Gauge's scale.	
     */
    foregroundColor?: string;
    /**
     * Specifies the background color of the Gauge's scale.	
     */
    backgroundColor?: string;
    /**
     * Specifies a string appended to the Gauge's reading. For example "%" most commonly used.	
     */
    appendText?: string;

    /**
     * toggles the gauge animation.	
     */
    animate?: boolean;

    constructor(gaugeValue: number, gaugeLabel: string, max: number) {
        this.value = gaugeValue;
        this.label = gaugeLabel;
        this.max = max;
    }

}