import { Component, OnInit } from '@angular/core';
import { BaseSingleDeviceDashboard } from 'src/app/devices/components/devices-single-components/device-health-dashboard/base-single-device-dashboard';
import { TranslateService } from '@ngx-translate/core';
import { PropertiesElementType } from 'src/app/shared/components/properties/models/properties-content';
import { D3NodeService } from 'src/app/shared/components/network-topology/services/d3-node.service';
import { InitatePropertiesDataService } from 'src/app/shared/components/properties/services/initate-properties-data.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { DevicesService } from 'src/app/shared/services/rest-services/devices.service';
import { BaseSingleEntityService } from 'src/app/shared/services/base-single-entity.service';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { isLast24Hours } from 'src/app/shared/operators/time-operator';
import {SingleDevice} from "../../../../models/single-device.model";

@Component({
  selector: 'app-device-single-properties',
  templateUrl: './device-single-properties.component.html',
  styleUrls: ['./device-single-properties.component.scss']
})
export class DeviceSinglePropertiesComponent extends BaseSingleDeviceDashboard implements OnInit {
  propertiesTitle: string;


  constructor(
    private d3NodeService: D3NodeService,
    protected preparePropsDataService: InitatePropertiesDataService,
    protected translate: TranslateService,
    protected loggerFactory: LoggerService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected devicesService: DevicesService,
    protected dateConvertor: TimeManagerService,
  ) {
    super(translate, loggerFactory, devicesService, baseSingleEntityService)
  }

  ngOnInit() {
    this.propertiesTitle = this.translate.instant('data.COMMON.DEVICE') + " " + this.translate.instant('data.COMMON.INFO');
    this.subscribeToDeviceChanges();

  }
  subscribeToDeviceChanges() {
    let deviceSubsc = this.baseSingleEntityService.notifyEntityDataAsObservable$.subscribe(device => {
      if (device) {
        this.deviceData = device as SingleDevice;
        this.initPropData();
      }
    })
    this.subscription.push(deviceSubsc);
  }
  /**
 * @method initDeviceData convert the Device data for the properties data object
 */
  private initPropData() {
    if (this.deviceData) {
      this.propData = {
        headerData: {
          elementType: PropertiesElementType.NODE,
          firstColumn: { main: this.deviceData.device.name, sub: this.deviceData.device.type },
          iconPath: this.d3NodeService.getClickedIconPath(this.deviceData.device.type),
          health: +this.deviceData.health,
          issues: this.deviceData.issues ? +this.deviceData.issues : 0,
          healthDegradationReasons: this.preparePropsDataService.multiPercentageBy100(this.deviceData.healthDegradationReasons)
        },
        mainData: {
          panelElements: [
            { key: 'staticInfo', value: this.preparePropsDataService.flattenProperties(this.deviceData.device) }
          ]
        }
      }
    }
  }

  get lastKpiUpdate(): string {
    return this.deviceData && this.deviceData.lastKpiUpdate && !isLast24Hours(this.deviceData.lastKpiUpdate) ?
      `Last KPI time: ${this.dateConvertor.dateByFormat(this.deviceData.lastKpiUpdate, 'DD-MMM-YYYY HH:mm')}` :
      null
  }
  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}
