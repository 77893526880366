import {
  Component,
  OnInit,
  EventEmitter,
  ContentChildren,
  QueryList,
  Output,
  Input,
  Host,
  ChangeDetectorRef,
  AfterContentInit,
  AfterViewInit
} from '@angular/core';
import {FlowStepChangedEvent, FlowStepBeforeChangeEvent} from './event/flow-step-changed-event';
import {FlowStepDirective} from './directive/flow-step.directive';
import {FlowService} from './service/flow.service';
import {Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LoggerService, Logger} from '../../services/logger.service';


@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss'],
  providers: [FlowService]
})
export class FlowComponent implements AfterContentInit, OnInit {
  /**
   * Current flow data state state
   */
  flowState: { [step: string]: any } = {};
  /**
   * The initially shown steps
   */
  @Input() initialSteps: string[] = [];
  /**
   * The collection of all avalaible steps (TODO: should not be needed - read it from the directives)
   */
  //@Input() steps: string[];
  /**
   * Steps currently shown
   */
  shownSteps: string[];

  private stepFinishedSubscription: Subscription;
  private directiveChnageSubscription: Subscription;

  // Returns the next shown steps
  @Input() nextStepStrategy: (firedStep: string, payload: any, flowState: { [step: string]: any }, isLastStep: boolean) => string[];
  @Input() backStepStrategy: (firedStep: string, flowState: { [step: string]: any }, isFirstStep: boolean) => string[];
  /**
   * Emitted by the flow component when a step is fired
   */
  @Output() stepChanged: EventEmitter<FlowStepChangedEvent> = new EventEmitter();
  /**
   * Step directives
   */
  @ContentChildren(FlowStepDirective, {descendants: true}) stepsDirectives !: QueryList<FlowStepDirective>;
  @Input() preserveState: boolean = false;
  /**
   * The order of the steps that where fired, except for the steps that were removed by back function
   */
  firedStepsOrder: string[];
  private logger: Logger; components; columnDefs;

  constructor(private flowService: FlowService, private loggerFactory: LoggerService, private cdr: ChangeDetectorRef) {
    this.logger = this.loggerFactory.getLogger("FlowComponent");

    this.flowState = {}
    this.firedStepsOrder = [];
    this.shownSteps = [];
  }

  ngOnInit() {
    if (!this.nextStepStrategy) {
      this.nextStepStrategy = this.defaultStepStrategy;
    }
    if (!this.backStepStrategy) {
      this.backStepStrategy = this.defaultBackStrategy;
    }
  }

  /**
   * Clean step on going forward i.e. the state of hidden steps & the state of newlly shown states
   * @param step the step we finish
   * @param lastShownSteps the shown steps before we going forward
   */
  private cleanStateOnFinish(step: string, lastShownSteps: string[]) {
    if (this.preserveState || this.firedStepsOrder.length == 0) {
      return;
    }
    // First clean all the hidden states
    this.stepsDirectives.map((directive) => {
      if (directive.hidden) {
        this.logger.debug(`cleaning hidden ${directive.step} `)
        this.cleanStepState(directive.step);
      } else {
        // Clean the state of newlly shown states
        if (!lastShownSteps.find((step) => step.toString() == directive.step.toString())) {
          this.logger.debug(`cleaning new state ${directive.step} `)
          this.cleanStepState(directive.step);
        }
      }
    })
  }

  public cleanStepState(step) {
    step = step.toString();
    delete this.flowState[step];
    let idx = this.firedStepsOrder.indexOf(step);
    if (idx != -1) {
      this.firedStepsOrder.splice(idx, 1);
    }
  }

  /**
   * Clean state on going back i.e. state of hidden state & the state we are going to
   * @param backToStep the back step to go
   */
  private cleanStateOnBack(backToStep: string) {
    if (this.preserveState || this.firedStepsOrder.length == 0) {
      return;
    }
    // First clean all the hidden states
    this.cleanHiddenState();
    // Clean the state of the step we moved to
    this.logger.debug(`cleaning back to step ${backToStep} `)
    this.cleanStepState(backToStep);
  }

  private cleanHiddenState() {
    this.stepsDirectives.map((directive) => {
      if (directive.hidden) {
        this.logger.debug(`cleaning ${directive.step} `);
        this.cleanStepState(directive.step);
      }
    });
  }

  private lastShownIndex() {
    let directives = this.directivesToArray();
    let lastShownIndex = -1;

    for (let i = directives.length - 1; i >= 0; i--) {
      if (directives[i].step == this.lastShownStep) {
        lastShownIndex = i;
      }
    }
    return lastShownIndex
  }

  private getStepIndex(step: string) {
    let directives = this.directivesToArray();
    if (this.firedStepsOrder.length == 0) {
      return -1;
    }
    let lastFiredIndex = -1;
    for (let i = directives.length - 1; i >= 0; i--) {
      if (directives[i].step == step) {
        lastFiredIndex = i;
      }
    }
    return lastFiredIndex
  }

  private setup() {
    // TODO: this is buggy as the after change in this case will change the
    // steps view.
    if (this.initialSteps.length == 0) {
      this.stepsDirectives.map((directive) => this.initialSteps.push(directive.step))
    }
    this.shownSteps = this.initialSteps;
    this.validateDirectives();
    //console.log('shown steps in setup %o', this.shownSteps)
    this._showSteps();
    // Emitted by the child step signaling it's end
    this.stepFinishedSubscription = this.flowService.stepFired.subscribe(
      (flowStep: FlowStepBeforeChangeEvent) => this.finishStep(flowStep.step, flowStep.payload)
    )
    // this.cdr.detectChanges()
  }

  ngAfterContentInit() {
    // suppress life cycle errors
    // i.e. resolve ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    this.directiveChnageSubscription = this.stepsDirectives.changes.subscribe(
      (directive) => {
        setTimeout(() => {
          this.validateDirectives();
          //console.log('shown steps in callback %o', this.shownSteps)
          this._showSteps();
        });
        //this.cdr.detectChanges()
        // console.log('changed a directive %o', directive)
      }
    );
    setTimeout(() => {
      this.setup();
    });
  }

  private validateDirectives() {
    this.stepsDirectives.map((directive) => {
      if (!directive.step) {
        console.log('Step not defined for a appFlowStep directive, directive %o', directive)
      }
    })
  }

  /**
   * Are at least one of the steps shown
   * @param steps
   */
  isShownOneOf(steps: string[]): boolean {
    if (!this.shownSteps || !steps) {
      return false;
    }
    return !!this.shownSteps.find(shownStep => steps.includes(shownStep));
  }

  /**
   * Is a step shown
   * @param step
   */
  isStepShown(step: string) {
    step = step.toString()
    if (this.shownSteps.length == 0) {
      return false;
    }
    // Note the use of find and not includes to avoid nesty js casting issues
    let includes = this.shownSteps.find(shownStep => step.toString() == shownStep.toString());
    return !!includes;
  }

  /**
   * Is the last shown step the first one in the flow
   */
  atFirstStep() {
    if (this.shownSteps.length == 0) {
      return false;
    }
    return this.shownSteps[this.shownSteps.length - 1] == this.stepsDirectives.first.step;
  }

  /**
   * Is the last shown step the last one in the flow
   */
  atLastStep() {
    if (this.shownSteps.length == 0 && this.stepsDirectives.length > 0) {
      return false;
    }
    return this.shownSteps[this.shownSteps.length - 1] == this.stepsDirectives.last.step;
  }

  /**
   * Last shown step
   */
  get lastShownStep() {
    if (!this.shownSteps || this.shownSteps.length < 1) {
      return null;
    }
    return this.shownSteps[this.shownSteps.length - 1];
  }

  /**
   * Finish a step with certain payload selected
   * @param step fired step
   * @param payload payload for the finished step
   * @param ignoreIfFired ignore the step if it was already fired
   */
  finishStep(step: string, payload: any, ignoreIfFired = false) {
    this.logger.debug('finishing step %s payload %o', step, payload);
    step = step.toString();
    // store the steps state
    this.flowState[step] = payload;
    if (ignoreIfFired && this.firedStepsOrder.indexOf(step) != -1) {
      this.logger.debug("Ignoring step %s as required", step)
      return;
    }
    this.firedStepsOrder.push(step);
    const isLastStep = this.isLastStep(step);
    let lastShownSteps = this.shownSteps.slice(0);
    this.shownSteps = this.nextStepStrategy(step, payload, this.flowState, isLastStep);
    this._showSteps();
    if (!this.preserveState) {
      this.cleanStateOnFinish(step, lastShownSteps)
    }
    this.stepChanged.emit(new FlowStepChangedEvent(step, payload, this.flowState, 'Forward', isLastStep));
  }

  /**
   * Go one step back
   * @param backToStep the step to got to or the last fired one
   * @param preserveState do not clear the prev step state,
   * @param payload optional step payload to update
   */
  backStep(backToStep: string, preserveState = false, payload?: any) {
    if (!backToStep) {
      if (this.firedStepsOrder.length > 0) {
        backToStep = this.firedStepsOrder[this.firedStepsOrder.length - 1];
      } else {
        console.log('No fired states yet and no back specified in backStep [flow cmponent]');
        throw Error('No fired states yet and no back specified in backStep [flow cmponent]')
        return;
      }
    }
    backToStep = backToStep.toString()
    //
    // check that the ste we are going from was shown
    // if (!this.shownSteps || this.shownSteps.length == 0) {
    //   return;
    // }
    // if (!this.shownSteps.find((shownStep) => shownStep.toString() == backToStep.toString())) {
    //   return;
    // }
    const isFirstStep = this.isFirstStep(backToStep);
    let shownSteps = this.backStepStrategy(backToStep, this.flowState, isFirstStep)
    if (!shownSteps) {
      console.error('A back to step of %s strategy returned undefined steps array', backToStep)
      throw Error(`A back to step of ${backToStep} strategy returned undefined steps array`)
    }
    if (payload) {
      this.flowState[backToStep] = payload;
    }
    this.shownSteps = this.backStepStrategy(backToStep, this.flowState, isFirstStep)
    this._showSteps();
    if (!preserveState) {
      this.cleanStateOnBack(backToStep);
    }
    // pop the fired steps till we get to the last fired state
    while (this.firedStepsOrder.length > 0) {
      let prevState: string = this.firedStepsOrder.pop();
      if (prevState == backToStep.toString()) {
        break;
      }
    }

    this.stepChanged.emit(new FlowStepChangedEvent(backToStep, null, this.flowState, 'Backward', isFirstStep));
  }

  /**
   * Is the step the first in the flow
   * @param step
   */
  isFirstStep(step: string): boolean {
    return step.toString() == this.stepsDirectives.first.step;
  }

  /**
   * Reset the flow data state
   */
  resetState() {
    this.flowState = {}
  }

  private _showSteps() {
    this.stepsDirectives.map(directive => {
      //console.log('step in _showSteps %s', step)
      directive.hidden = !this.shownSteps.find(shownStep => {
        // console.log('shown directive %o', directive)
        // if (!directive || !directive.step) {
        //   return false;
        // }
        return shownStep.toString() == directive.step.toString()
      });
    })
  }

  /**
   * Check if a step is the last one in flow
   * @param step
   */
  isLastStep(step: string) {
    return step.toString() == this.stepsDirectives.last.step;
  }

  /**
   * Default strategy implementation just hides previouse steps and shows
   * the next one.
   * @param firedStep
   * @param payload
   */
  private defaultStepStrategy(firedStep: string, payload: any, flowState: { [step: string]: any }, isLastStep: boolean): string[] {
    let i = 0;
    this.stepsDirectives.find((directive, idx) => {
      if (firedStep == directive.step) {
        i = idx + 1;
        return true;
      }
    })
    if (i < this.stepsDirectives.length) {
      return [this.stepsDirectives.last.step];
    }
    // Don't show anything if at the end of the flow
    return [];
  }

  private directivesToArray(): FlowStepDirective[] {
    let directives = [];
    this.stepsDirectives.map(directive => {
      directives.push(directive);
    })
    return directives;
  }

  private defaultBackStrategy(firedStep: string, flowState: { [step: string]: any }): string[] {
    let directives = this.directivesToArray();
    let shownSteps = [];
    let showStep = false;
    for (let i = directives.length - 1; i >= 0; i--) {
      if (!showStep) {
        this.cleanStepState(i);
      } else {
        shownSteps.push(directives[i].step)
      }

      if (firedStep == directives[i].step) {
        showStep = true;
      }
    }
    return shownSteps;
  }

  // defaultBackStrategy
  ngOnDestroy() {
    if (this.stepFinishedSubscription)
      this.stepFinishedSubscription.unsubscribe();
    if (this.directiveChnageSubscription)
      this.directiveChnageSubscription.unsubscribe();
  }
}
