import { Component, EventEmitter, Output, Input, SimpleChanges, ChangeDetectorRef, Inject } from '@angular/core';
import { TopTrafficOrigin, TopTrafficTotalDisplay, TopTrafficSingleDisplayData } from '../../../../models/legacy-reports.model';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/shared/models/entity.model';
import { TrafficService } from 'src/app/shared/services/rest-services/traffic.service';
import { share } from 'rxjs/operators';
import { FULL_COLORS_LIST } from 'src/app/shared/global-utils/colors-utils';

@Component({
  selector: 'app-tenant-deviation-reports-dialog',
  templateUrl: './tenant-deviation-reports-dialog.component.html',
  styleUrls: ['./tenant-deviation-reports-dialog.component.scss']
})
export class TenantDeviationReportsDialogComponent {
  @Input() currentEntity: Entity;
  @Input() currentDate: string;
  topTrafficVenues$: Observable<TopTrafficTotalDisplay>;
  topUsersVenues$: Observable<TopTrafficTotalDisplay>;
  @Output() selectedEntity: EventEmitter<Entity> = new EventEmitter();
  constructor(private trafficService: TrafficService, private cdr: ChangeDetectorRef, @Inject(FULL_COLORS_LIST) readonly fullColorsList: string) { }

  ngOnChanges(changes: SimpleChanges) {
    this.topTrafficVenues$ = this.trafficService.getTopTrafficData(this.currentEntity, this.currentDate, this.currentDate, TopTrafficOrigin.VENUES_BY_TRAFFIC).pipe(share());
    this.topUsersVenues$ = this.trafficService.getTopTrafficData(this.currentEntity, this.currentDate, this.currentDate, TopTrafficOrigin.VENUES_BY_CLIENTS).pipe(share());
    this.cdr.detectChanges();
  }
  onBarSelected(entity: TopTrafficSingleDisplayData) {
    this.selectedEntity.emit({ id: entity.details.first, name: entity.details.second, type: EntityType.VENUE });
  }

}
