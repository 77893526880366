import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgxGaugeModule } from "ngx-gauge";
import { PolicyDisplayComponent } from "../fabrics/components/policy-display/policy-display.component";
import { FabricStatusDisplayComponent } from "../venues/venues-single-components/venue-single-fabrics/components/fabric-status-display/fabric-status-display.component";
import { AutocompleteListComponent } from "./components/autocomplete-list/autocomplete-list.component";
import { BasicProgressBarComponent } from "./components/basic-progress-bar/basic-progress-bar.component";
import { BotContainerComponent } from "./components/bots/bot-container/bot-container.component";
import { BotStepComponent } from "./components/bots/bot-step/bot-step.component";
import { BotSummaryContainerComponent } from "./components/bots/bot-summary-container/bot-summary-container.component";
import { BotSummaryItemComponent } from "./components/bots/bot-summary-item/bot-summary-item.component";
import { ButtonsComponent } from "./components/buttons/buttons.component";
import { GraphDonutComponent } from "./components/chartjs-components/graph-donut/graph-donut.component";
import { GraphTrendComponent } from "./components/chartjs-components/graph-trend/graph-trend.component";
import { TODAY_VERTICAL_JS_ANNOTATION, VERTICAL_TODAY_ANNOTATION, CHART_JS_DEFAULT_TODAY_DATES, DEFAULT_TODAY_DATES } from "./components/chartjs-components/models/chart-js-injection-token";
import { MultiAxisTrendComponent } from "./components/chartjs-components/multi-axis-trend/multi-axis-trend.component";
import { SparklineComponent } from "./components/chartjs-components/sparkline/sparkline.component";
import { StackedBarWithGraphComponent } from "./components/chartjs-components/stacked-bar-with-graph/stacked-bar-with-graph.component";
import { StackedBarComponent } from "./components/chartjs-components/stacked-bar/stacked-bar.component";
import { StackedBarsRowComponent } from "./components/chartjs-components/stacked-bar/stacked-bars-row/stacked-bars-row.component";
import { VerticalBarComponent } from "./components/chartjs-components/vertical-bar/vertical-bar.component";
import { CheckboxSearchListComponent } from "./components/checkbox-search-list/checkbox-search-list.component";
import { ControlErrorComponent } from "./components/control-error/control-error.component";
import { CronGeneratorComponent } from "./components/cron-generator/cron-generator.component";
import { DashboardPortalModule } from "./components/dashboard-portal/dashboard-portal.module";
import { DonutRowComponent } from "./components/donut-row/donut-row.component";
import { DynamicWordingsModule } from "./components/dynamic-wordings/dynamic-wordings.module";
import { AnomaliesCategoryListComponent } from "./components/entity-dashboard/anomalies/anomalies-category-list/anomalies-category-list.component";
import { AnomalySettingTenantsSelectionComponent } from "./components/entity-dashboard/anomalies/anomaly-setting-tenants-selection/anomaly-setting-tenants-selection.component";
import { AnomalySettingComponent } from "./components/entity-dashboard/anomalies/anomaly-setting/anomaly-setting.component";
import { AnomalySettingsContainerComponent } from "./components/entity-dashboard/anomalies/anomaly-settings-container/anomaly-settings-container.component";
import { FiltersBarComponent } from "./components/filters-bar/filters-bar.component";
import { FromToDatePickerComponent } from "./components/from-to-date-picker/from-to-date-picker.component";
import { FromToDisplayComponent } from "./components/from-to-display/from-to-display.component";
import { GaugeComponent } from "./components/gauge/gauge.component";
import { GraphDonutPlainComponent } from "./components/graph-donut-plain/graph-donut-plain.component";
import { GraphDonutPortletComponent } from "./components/graph-donut-portlet/graph-donut-portlet.component";
import { HealthBarComponent } from "./components/health-bar/health-bar.component";
import { TimelineMarkerDirective } from "./components/horizontal-timeline/directives/timeline-marker.directive";
import { HorizontalTimelineComponent } from "./components/horizontal-timeline/horizontal-timeline.component";
import { IconWithEllipseComponent } from "./components/icon-with-ellipse/icon-with-ellipse.component";
import { InsightResolutionsDisplayComponent } from "./components/kpi-display-components/insight-resolutions-display/insight-resolutions-display.component";
import { MultiKpiActionGraphTrendComponent } from "./components/kpi-display-components/multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.component";
import { LegacyEditableTextComponent } from "./components/legacy-editable-text/legacy-editable-text.component";
import { ListViewModule } from "./components/list-view/list-view.module";
import { MenuCheckboxListComponent } from "./components/menu-checkbox-list/menu-checkbox-list.component";
import { NetopTabGroupComponent } from "./components/netop-tab-group/netop-tab-group.component";
import { NetopTabComponent } from "./components/netop-tab/netop-tab.component";
import { NetopToggleComponent } from "./components/netop-toggle/netop-toggle.component";
import { PagingArrowsComponent } from "./components/paging-arrows/paging-arrows.component";
import { AllowDenyViewComponent } from "./components/policies-display/allow-deny-view/allow-deny-view.component";
import { PressedButtonComponent } from "./components/pressed-button/pressed-button.component";
import { SearchMenuBoxComponent } from "./components/search-menu-box/search-menu-box.component";
import { SearchModule } from "./components/search/search.module";
import { VenuesMultiSelectListComponent } from "./components/select-list/venues-multi-select-list/venues-multi-select-list.component";
import { SelectionTreeModule } from "./components/selection-tree/selection-tree.module";
import { SingleWidgetBigChartComponent } from "./components/single-widgets-bar/single-widget-big-chart/single-widget-big-chart.component";
import { TimeRangeManagerComponent } from "./components/time-range-manager/time-range-manager.component";
import { TimeRangePickerModule } from "./components/time-range-picker/time-range-picker.module";
import { VenuePickerComponent } from "./components/venue-picker/venue-picker.component";
import { VerticalMenuOptionsComponent } from "./components/vertical-menu-options/vertical-menu-options.component";
import { AssignmentDialogComponent } from "./modals/assignment-dialog/assignment-dialog.component";
import { BackupNowComponent } from "./modals/backup-now/backup-now.component";
import { CloneDialogComponent } from "./modals/clone-dialog/clone-dialog.component";
import { InputDialogComponent } from "./modals/input-dialog/input-dialog.component";
import { KpiDialogComponent } from "./modals/kpi-dialog/kpi-dialog.component";
import { KpisTrendsComponent } from "./modals/kpis-trends/kpis-trends.component";
import { SingleKpiDisplayComponent } from "./modals/kpis-trends/single-kpi-display/single-kpi-display.component";
import { NetopDialogModule } from "./modals/netop-dialog/netop-dialog.module";
import { PeriodicBackupComponent } from "./modals/periodic-backup/periodic-backup.component";
import { RestoreComponent } from "./modals/restore/restore.component";
import { ScheduleDialogComponent } from "./modals/schedule-dialog/schedule-dialog.component";
import { TenantPeriodicBackupComponent } from "./modals/tenant-periodic-backup/tenant-periodic-backup.component";
import { NgxSelectModule } from "./ngx-select/ngx-select.module";
import { SharedDirectivesModule } from "./shared-directives.module";
import { SharedPipesModule } from "./shared-pipes.module";


const components = [
  HorizontalTimelineComponent,
  SparklineComponent,
  VerticalBarComponent,
  KpisTrendsComponent,
  MultiKpiActionGraphTrendComponent,
  StackedBarWithGraphComponent,
  GraphTrendComponent,
  StackedBarComponent,
  CronGeneratorComponent,
  ButtonsComponent,
  CheckboxSearchListComponent,
  IconWithEllipseComponent,
  LegacyEditableTextComponent,
  PolicyDisplayComponent,
  AnomalySettingComponent,
  PressedButtonComponent,
  AnomalySettingTenantsSelectionComponent,
  MenuCheckboxListComponent,
  AnomaliesCategoryListComponent,
  BasicProgressBarComponent,
  GraphDonutComponent,
  DonutRowComponent,
  StackedBarsRowComponent,
  NetopToggleComponent,
  FiltersBarComponent,
  FabricStatusDisplayComponent,
  FromToDisplayComponent,
  SingleWidgetBigChartComponent,
  GaugeComponent,
  GraphDonutPortletComponent,
  GraphDonutPlainComponent,
  AutocompleteListComponent,
  AllowDenyViewComponent,
  PeriodicBackupComponent,
  BackupNowComponent,
  RestoreComponent,
  CloneDialogComponent,
  TenantPeriodicBackupComponent,
  InputDialogComponent,
  KpiDialogComponent,
  AutocompleteListComponent,
  InsightResolutionsDisplayComponent,
  NetopTabGroupComponent,
  NetopTabComponent,
  SingleKpiDisplayComponent,
  MultiAxisTrendComponent,
  TimeRangeManagerComponent,
  FromToDatePickerComponent,
  PagingArrowsComponent,
  VenuesMultiSelectListComponent,
  BotSummaryContainerComponent,
  BotSummaryItemComponent,
  ScheduleDialogComponent,
  HealthBarComponent,
  VerticalMenuOptionsComponent,
  SearchMenuBoxComponent,
  AssignmentDialogComponent,
  ControlErrorComponent,
  AnomalySettingsContainerComponent,
  VenuePickerComponent
];

const modules = [
  MatIconModule,
  InlineSVGModule,
  SharedPipesModule,
  SharedDirectivesModule,
  MatTooltipModule,
  MatSlideToggleModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatInputModule,
  NetopDialogModule,
  MatButtonModule,
  MatDialogModule,
  MatRadioModule,
  MatCheckboxModule,
  MatListModule,
  MatOptionModule,
  MatButtonToggleModule,
  MatToolbarModule,
  NgbTooltipModule,
  SharedDirectivesModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  NgxGaugeModule,
  MatAutocompleteModule,
  DashboardPortalModule,
  MatTabsModule,
  MatDatepickerModule,
  MatFormFieldModule,
  NgxSelectModule,
  DragDropModule,
  MatTreeModule,
  ListViewModule,
  DynamicWordingsModule
];

@NgModule({
  declarations: [
    components,
    BotContainerComponent,
    BotStepComponent,
    TimelineMarkerDirective,
  ],
  imports: [
    CommonModule,
    TimeRangePickerModule,
    modules
  ],
  exports: [
    components,
    DonutRowComponent,
    BotContainerComponent,
    BotStepComponent,
    SelectionTreeModule,
    SearchModule,
    TimeRangePickerModule,
    DynamicWordingsModule
  ],
  providers: [
    {
      provide: TODAY_VERTICAL_JS_ANNOTATION,
      useValue: VERTICAL_TODAY_ANNOTATION
    },
    {
      provide: CHART_JS_DEFAULT_TODAY_DATES,
      useValue: DEFAULT_TODAY_DATES
    },
  ]
})
export class SharedComponentsModule {
}
