import { InjectionToken } from '@angular/core';

export enum DynamicWordingRuleType {
    Normal,
    Bold,
    Dropdown,
	InputPercents,
    Input
}

export class DynamicWordingRule {
    type: DynamicWordingRuleType;
    startChar: string;
    endChar: string;
}
export class DynamicWordingCharacters {
    startChar: string;
    endChar: string;
}

export const dynamicWordingRules: DynamicWordingRule[] = [
    {
        type: DynamicWordingRuleType.Bold,
        startChar: "@",
        endChar: "@"

    },
    {
        type: DynamicWordingRuleType.Dropdown,
        startChar: "{",
        endChar: "}"
    }
]
export const specialCharacters: DynamicWordingCharacters[] = [
    {
        startChar: "@",
        endChar: "@"

    },
    {
        startChar: "{",
        endChar: "}"
    }
]


export const DYNAMIC_WORDING_RULES = new InjectionToken<DynamicWordingRule[]>('Dynamic Wording Rulls');
export const DYNAMIC_WORDING_CHARACTERS = new InjectionToken<DynamicWordingRule[]>('Dynamic Wording Characters');

export class WordsWithRules<T>{
    id?: number;
    specialWordId?: number;
    value: string;
    rule: DynamicWordingRule;
    options: T[];
    pattern: string;
    errorMessage: string;

    constructor(id: number, value: string, rule: DynamicWordingRule, options: T[], specialWordId?: number, pattern?: string, errorMessage?: string) {
        this.id = id;
        this.value = value;
        this.rule = rule;
        this.options = options;
        this.specialWordId = specialWordId;
        this.pattern = pattern;
        this.errorMessage = errorMessage;
    }
}
