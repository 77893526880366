import { DashboardType } from './dashboards.model';

export class SingleWidget {
    type: WidgetType;
    size: WidgetSize;
    possibleSizes: {
        sizeDisaply: string;
        actualSize: WidgetSize;
    }[];

    constructor(type: WidgetType) {
        this.type = type;
        this.getWidgetSizes();
    }

    getWidgetSizes() {
        switch (this.type) {
            case WidgetType.ACTIONS_PIE:
                this.setSizeToOneCol();
                break;
            case WidgetType.DEVICE_SINGLE_PROPERTIES:
                this.setSizeToOneCol();
                break;
            case WidgetType.DEVICE_SINGLE_MAP:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_DEVICES:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_DEVICE_BY_CONN_FAILURES:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_CLIENTS:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_APPS:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_VPN:
                this.setSizeToOneCol();
                break;
            case WidgetType.TOP_DEVICE_CLIENTS:
                this.setSizeToOneCol();
                break;
            case WidgetType.ORG_CHILDREN:
                this.setSizeToOneCol();
                break;
            case WidgetType.CHART_NO_SELECTORS:
                this.setSizeToTwoCol();
                break;
            case WidgetType.HEALTH_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.TRAFFIC_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.LOSS_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.USERS_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.DEVICE_TRAFFIC_DEVIATION:
                this.setSizeToTwoCol();
                break;
            case WidgetType.APP_TRAFFIC_DEVIATION:
                this.setSizeToTwoCol();
                break;
            case WidgetType.DEVICE_BY_CONN_FAILURES:
                this.setSizeToTwoCol();
                break;
            case WidgetType.VPN_TRAFFIC_DEVIATION:
                this.setSizeToTwoCol();
                break;
            case WidgetType.LOSS_BAR:
                this.setSizeToTwoCol();
                break;
            case WidgetType.LATENCY_BAR:
                this.setSizeToTwoCol();
                break;
            case WidgetType.DEVICE_CLIENTS_CONNECTION:
                this.setSizeToTwoCol();
                break;
            case WidgetType.HIERARCHY_MAP:
                this.setSizeToTwoCol();
                break;
            case WidgetType.LATENCY_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.DEVICE_SINGLE_TOPOLOGY:
                this.setSizeToTwoCol();
                break;
            case WidgetType.CHART_WITH_SELECTORS:
                this.setSizeToTwoCol();
                break;
            case WidgetType.DEVICE_SINGLE_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.TENANTS_HEALTH_CHART:
                this.setSizeToTwoCol();
                break;
            case WidgetType.CLIENTS_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.FABRICS_HEALTH_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.EVENTS_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.DEVICES_HEALTH_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.VENUES_HEALTH_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.TENANTS_HEALTH_GRID:
                this.setSizeToThreeCol();
                break;
            case WidgetType.KPIS_BAR:
                this.setSizeToThreeCol();
            case WidgetType.VENUE_TOPOLOGY_WITH_PROPERTIES:
                this.setSizeToThreeCol();
                break;
            case WidgetType.ANOMALIES:
                this.setSizeToThreeCol();
                break;
            case WidgetType.INSIGHTS_ANOMALIES:
                this.setSizeToThreeCol();
                break;
            case WidgetType.INSIGHTS_INCIDENTS:
              this.setSizeToThreeCol();
              break;
            default:
                break;
        }
    }

    setSizeToOneCol() {
        this.size = WidgetSize.COL1;
        this.possibleSizes = [{ sizeDisaply: WidgetSize.getWidgetSizeDisplayText(WidgetSize.COL1), actualSize: WidgetSize.COL1 }];
    }
    setSizeToTwoCol() {
        this.size = WidgetSize.COL2;
        this.possibleSizes = [
            { sizeDisaply: WidgetSize.getWidgetSizeDisplayText(WidgetSize.COL2), actualSize: WidgetSize.COL2 },
            { sizeDisaply: WidgetSize.getWidgetSizeDisplayText(WidgetSize.COL3), actualSize: WidgetSize.COL3 },
        ];
    }
    setSizeToThreeCol() {
        this.size = WidgetSize.COL3
        this.possibleSizes = [
            { sizeDisaply: WidgetSize.getWidgetSizeDisplayText(WidgetSize.COL3), actualSize: WidgetSize.COL3 }
        ];
    }
}

export enum WidgetType {
    ACTIONS_PIE = "actions-pie",
    CHART_WITH_SELECTORS = "multi-kpis-chart",
    TENANTS_HEALTH_GRID = "tenants-health-grid",
    VENUES_HEALTH_GRID = "venues-health-grid",
    FABRICS_HEALTH_GRID = "fabrics-health-grid",
    DEVICES_HEALTH_GRID = "devices-health-grid",
    CLIENTS_GRID = "clients-grid",
    EVENTS_GRID = "events-grid",
    SINGLE_KPI_CHART = "single-kpis-chart",
    PROPERTIES = "properties",
    TOPOLOGY = "topology",
    CHART_NO_SELECTORS = "single-kpi-chart",
    HEALTH_CHART = "health-chart",
    TRAFFIC_CHART = "traffic-chart",
    LOSS_CHART = "loss-chart",
    USERS_CHART = "users-chart",
    LATENCY_CHART = "latency-chart",
    KPIS_BAR = "kpis-bar",
    TOPOLOGY_WITH_PROPERTIES = "topology-with-properties",
    DEVICE_SINGLE_MAP = "device-single-map",
    DEVICE_SINGLE_CHART = "device-single-chart",
    DEVICE_SINGLE_PROPERTIES = "device-single-properties",
    DEVICE_SINGLE_TOPOLOGY = "device-single-topology",
    VENUE_TOPOLOGY_WITH_PROPERTIES = "venue-topology-with-properties",
    HIERARCHY_MAP = "hierarchy-map",
    ORG_CHILDREN = "org-children",
    TENANTS_HEALTH_CHART = "tenant-health-chart",
    DEVICE_TRAFFIC_DEVIATION = "device-traffic-deviation",
    VPN_TRAFFIC_DEVIATION = "vpn-traffic-deviation",
    APP_TRAFFIC_DEVIATION = "app-traffic-deviation",
    ANOMALIES = "anomalies",
    TOP_DEVICES = "top-devices",
    TOP_CLIENTS = "top-clients",
    TOP_VPN = "top-vpn",
    TOP_APPS = "top-apps",
    TOP_DEVICE_CLIENTS = "top-device-clients",
    TOP_DEVICE_BY_CONN_FAILURES = "top-device-connection-failures",
    LATENCY_BAR = "latency-bar",
    LOSS_BAR = "loss-bar",
    DEVICE_CLIENTS_CONNECTION = "device-client-connection",
    DEVICE_BY_CONN_FAILURES = "device-connection-failures",
    INSIGHTS_ANOMALIES = "insights-anomalies",
    INSIGHTS_INCIDENTS = "insights-incidents"
}
export enum WidgetSize {
    COL1,
    COL2,
    COL3,
    COL_HALF = 1.5
}

export namespace WidgetSize {
    export function getWidgetSizeDisplayText(size: WidgetSize) {
        switch (size) {
            case WidgetSize.COL1:
                return "1/3 from current view";
            case WidgetSize.COL2:
                return "2/3 from current view";
            case WidgetSize.COL3:
                return "3/3 from current view";
            case WidgetSize.COL_HALF:
                return "half current view";
            default:
                break;
        }

    }
}
export class WidgetsTemplate {
    templateName: string;
    widgetsArray: SingleWidget[];
    isEditable: boolean;
    isEditMode?: boolean = false;
    isSelected?: boolean;
    isUtils?: boolean = false;
    isDefaultSelection?: boolean;
}
