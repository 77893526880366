import {Injectable} from '@angular/core';
import {Topology} from "../../../models/topology";
import {SingleDevice} from "../../../models/single-device.model";
import {BasicLink} from "../../../models/single-link.model";
import {PropertiesContent, PropertiesElementType} from "../models/properties-content";
import {SingleEntityService} from "../../single-entities/single-entity.service";
import {InitatePropertiesDataService} from "./initate-properties-data.service";
import {BaseEntityPropertiesGenerator} from "./base-entity-properties-generator";
import {VenueData} from "../../../models/venues.model";

@Injectable({
  providedIn: 'root'
})
export class GenerateVenuePropertiesService extends BaseEntityPropertiesGenerator {

  constructor(protected prepareProps: InitatePropertiesDataService,
              protected singleEntityService: SingleEntityService) {
    super(prepareProps, singleEntityService)
  }

  prepareVenueData(venueData: VenueData, venueTopology: Topology<SingleDevice, BasicLink>) {
    let propData: PropertiesContent;
    if (venueData) {
      let venue = venueData.venue;
      propData = {
        headerData: {
          elementType: PropertiesElementType.VENUE,
          firstColumn: {main: venue.name, sub: venue.type},
          iconPath: '/assets/media/netop/topology/fabric-icon.svg',
          issues: venueTopology ? this.singleEntityService.calculateTotalIssues(venueTopology) : 0,
          health: venueData.health ? this.propsGenerator.multiHealthBy100(venueData.health) : 0,
        },
        mainData: {
          panelElements: [
            {
              key: 'staticInfo', value: this.propsGenerator.flattenProperties(venue)
            }
          ]
        }
      }
    }
    return propData;
  }
}
