import { Component, OnInit } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-vpn-connections',
  templateUrl: './top-vpn-connections.component.html',
  styleUrls: ['./top-vpn-connections.component.scss']
})
export class TopVpnConnectionsComponent implements OnInit {
  origin: TopTrafficOrigin = TopTrafficOrigin.VPN_CONNS

  constructor() { }

  ngOnInit() {
  }

}
