import { Component, OnInit } from '@angular/core';
import { KpiType } from 'src/app/shared/models/kpi.model';

@Component({
  selector: 'app-loss-bar',
  templateUrl: './loss-bar.component.html',
  styleUrls: ['./loss-bar.component.scss']
})
export class LossBarComponent implements OnInit {
  kpiType = KpiType.Loss;
  constructor() { }

  ngOnInit() {
  }

}
