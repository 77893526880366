import {Component, OnInit, Inject, ViewChild, Input, ChangeDetectorRef} from '@angular/core';
import {AnomaliesSizes, AnomalySummaryByCategory} from 'src/app/shared/models/anomalies.model';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {InsightsAnomaliesTimeGeneratorService} from './services/insights-anomalies-time-generator.service';
import {SingleSizeCalculationService} from 'src/app/venues/services/single-size-calculation.service';
import {InsightsAnomaliesBarComponent} from '../insights-anomalies-bar/insights-anomalies-bar.component';
import {OpenDialogService} from "../../../../services/open-dialog.service";
import {FromToDatesWithAnomalyTrend} from "../../../../models/actions.model";
import {ANOMALY_CHART_HEIGHT, ANOMALY_GRID_SIZES} from "../../../../global-utils/sizes";
import {FromToDates, FromToDatesStrings, TimePeriod, TimePeriodWithDates} from "../../../../models/time.model";

@Component({
  selector: 'app-insights-anomalies',
  templateUrl: './insights-anomalies.component.html',
  styleUrls: ['./insights-anomalies.component.scss']
})
export class InsightsAnomaliesComponent implements OnInit {
  logger: Logger;
  isShowTraffic: boolean = false;
  dates: FromToDatesStrings;
  barDates: FromToDatesStrings;
  isObscurityMode: boolean = false;
  trafficGraphHeight: number;
  displayDate: string;
  totalOpenActions: number;
  selectedTimeDates: FromToDates = {
    start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    end: new Date(Date.now())
  }
  timePeriodArray = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_WEEK, TimePeriod.CUSTOM_DATE];
  maxDateLimit = new Date(Date.now());
  selectedTimePeriod: TimePeriod = TimePeriod.LAST_24_HOURS;
  anomalies: AnomalySummaryByCategory[];
  datesWithTrend: FromToDatesWithAnomalyTrend;
  @Input() isLeanMode: boolean = false;
  @ViewChild(InsightsAnomaliesBarComponent) insightsAnomaliesBar: InsightsAnomaliesBarComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private datesGenerator: InsightsAnomaliesTimeGeneratorService,
    private singleSizeCalculationService: SingleSizeCalculationService,
    private openDialogService: OpenDialogService,
    private loggerFactory: LoggerService,
    @Inject(ANOMALY_GRID_SIZES) readonly anomaliesInsightsSizes: AnomaliesSizes,
    @Inject(ANOMALY_CHART_HEIGHT) readonly anomalyChartHeight: number,
  ) {
    this.logger = this.loggerFactory.getLogger('InsightsAnomaliesComponent');
  }

  ngOnInit() {
  }

  onTimeSelectionChanged(datesWithPeriod: TimePeriodWithDates) {
    this.dates = this.datesGenerator.timeConvertor(datesWithPeriod);
    this.logger.debug('dates', this.dates);
    this.cdr.detectChanges();
  }

  onBarDateClicked(event: FromToDatesWithAnomalyTrend) {
    this.datesWithTrend = event;
    this.barDates = event.fromToDates;
    this.isObscurityMode = true;
    this.displayDate = this.datesGenerator.getDisplateDate(this.barDates.start, this.insightsAnomaliesBar.trend.dataGranularity);

  }

  onTrendGenerated(event: FromToDatesWithAnomalyTrend) {
    this.datesWithTrend = event;
  }

  /**
   * 1. Change isObscurityMode
   * 2. Change display date to null
   * 3. Change the datesWithTrend to original values and re-assign in order to activate change detection
   */
  onObscurityIconChanges() {
    this.isObscurityMode = false;
    this.displayDate = null;
    this.datesWithTrend = {fromToDates: this.dates, trend: this.datesWithTrend.trend};
  }

  get graphHeight(): number {
    if (this.anomaliesInsightsSizes) {
      return +this.anomaliesInsightsSizes.height.substring(0, this.anomaliesInsightsSizes.height.length - 2)
    }
  }

  get anomaliesTitle() {
    if (this.totalOpenActions > 0) {
      return `Open Anomalies: ${this.totalOpenActions}`;
    }
  }
}
