import {Pipe, PipeTransform} from '@angular/core';

export enum ObjectMarkers {
  AAA = '111'
}

export namespace ObjectMarkers {
  export function toArray() {
    return [
      ObjectMarkers.AAA
    ]
  }
}

@Pipe({
  name: 'removeObjectMarkers'
})
export class RemoveObjectMarkersPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    ObjectMarkers.toArray().forEach(marker => {
      value = value.replace(marker, '');
    });
    return value;
  }

}
