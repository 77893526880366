import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {TimeRangeInterpreterService} from "./time-range-interpreter.service";
import {TimeApi, TimeRangeWithValue, TimeUnit} from "../../models/time.model";
import {Logger} from "loglevel";
import {LoggerService} from "../../services/logger.service";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {NgChanges} from "../../extend-angular-classes/on-changes";
import {BaseNetopForm} from "../../models/forms/base-netop-form";
import {UntilDestroy} from "@ngneat/until-destroy";
import {TimeUtils} from "../../global-utils/time-utils";

@UntilDestroy()
@Component({
  selector: 'app-time-range-manager',
  templateUrl: './time-range-manager.component.html',
  styleUrls: ['./time-range-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeRangeManagerComponent extends BaseNetopForm implements OnInit {
  @Input() toggleButtons: Array<TimeRangeWithValue>;
  @Input() defaultSelection: TimeRangeWithValue;
  @Input() hoursSelection: TimeRangeWithValue;
  @Input() defaultDay: TimeRangeWithValue;
  @Input() defaultHour: TimeRangeWithValue;
  @Input() isDisableMode: boolean;
  @Input() isRoundMinutes: boolean;
  @Output() timeForApiSelected: EventEmitter<TimeApi> = new EventEmitter<TimeApi>();

  timeForApi: TimeApi = null;
  selectedToggle: TimeRangeWithValue;
  private logger: Logger; components; columnDefs;

  constructor(private timeRangeInterpreter: TimeRangeInterpreterService, private loggerFactory: LoggerService, protected fb: UntypedFormBuilder) {
    super(fb)
    this.logger = this.loggerFactory.getLogger('TimeRangeManagerComponent');
  }

  ngOnInit(): void {
    this.selectedToggle = {...this.defaultSelection};
    this.loadDefaultValues();
    this.submitTimeSelection();
  }

  ngOnChanges(changes: NgChanges<TimeRangeManagerComponent>) {
    if (changes.defaultDay || changes.defaultHour) {
      this.loadDefaultValues();
    }
    if (changes.defaultSelection) {
      this.selectedToggle = {...this.defaultSelection};
    }
  }

  onTimeToggleChanged(event: MatButtonToggleChange) {
    this.selectedToggle = event.value;
    this.formGroup.controls['range'].setValue(this.selectedToggle);
  }

  /**
   * Generate timeForApi object with relevent values
   */
  submitTimeSelection() {
    if (this.selectedToggle) {
      const dataGranularity: TimeUnit = this.timeRangeInterpreter.getDataGranularity(this.selectedToggle);
      let date: Date = this.timeRangeInterpreter.getDateByGranularity(this.formGroup.controls['day'].value, dataGranularity);
      let hour = this.formGroup.controls['hour'].value;
      if (hour) {
        date.setHours(hour.split(':')[0], hour.split(':')[1])
      }
      if (date && this.isRoundMinutes) {
        date = TimeUtils.roundDateMinutes(date);
      }
      this.timeForApi = {
        specificDate: date.toISOString(),
        timeUnit: dataGranularity,
        timeBack: this.selectedToggle.value
      }
      this.logger.debug('timeForApi', this.timeForApi);
      this.timeForApiSelected.emit(this.timeForApi);
      this.store.next(this.formGroup.value);
      this.isFormChangedByUser = false;
    }
  }

  /**
   * Load default values
   */
  private loadDefaultValues(withRange: boolean = true) {
    this.formGroup = this.fb.group({
      day: new UntypedFormControl(this.defaultDay),
      hour: new UntypedFormControl(this.defaultHour),
      range: new UntypedFormControl(withRange ? this.defaultSelection : this.formGroup.get('range').value)
    })
    this.initializeFormChangeDetection();
    if (!this.isRangeChanges)
      this.isFormChangedByUser = false;
  }

  get currentSelection() {
    return this.defaultSelection;
  }

  resetTimeSelectionToNow() {
    this.loadDefaultValues(false);
    this.submitTimeSelection();
  }

  get isTimeDifferentThanDefault() {
    return this.formGroup.get('day').value !== this.defaultDay ||
      this.formGroup.get('hour').value !== this.defaultHour;
  }

  get isRangeChanges() {
    return this.defaultSelection && this.selectedToggle && this.defaultSelection.range !== this.selectedToggle.range;
  }
}
