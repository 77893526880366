<app-portlet>
  <app-portlet-header [title]="'data.SITES.PLURAL' | translate" [hideDefaultTools]="true">
    <ng-container nextToTitle>
      <app-search [searchText]="searchText" class="ml-4" (searchTextChange)="onSearch($event)">
        <ng-container *ngIf="total != null" nextToSearch>{{total}} sites</ng-container>
      </app-search>
    </ng-container>
    <ng-container portletTools>
      <app-time-period-picker [options]="timePeriodOptions" [currentSelectionIndex]="selectedTimePeriodIndex"
        class="ml-5" (optionSelected)="onTimePeriodSelected($event)">
      </app-time-period-picker>
    </ng-container>
  </app-portlet-header>
  <app-portlet-body class="portlet-body-entity-list full-screen-widget-portlet-no-margin">
    <app-venues-grid [searchText]="searchText" [fetchPaginatedRows]="fetchVenuesCb" [(refresh)]="refresh" [timeBack]="timeBack" [searchText]="searchText"
      [timeUnit]="timeUnit" [style.height.%]="100" (total)="total = $event">
    </app-venues-grid>
  </app-portlet-body>
</app-portlet>
