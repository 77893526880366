import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class GraphTrendSizeService {

  constructor(public breakpointObserver: BreakpointObserver) { }

  findGraphSize(mediaWidth: any) {
    let graphWidth: number;
    let graphHeight: number;
    let mediaQueries = Object.keys(mediaWidth);
    for (let i = 0; i < mediaQueries.length; i++) {
      if (this.breakpointObserver.isMatched(mediaQueries[i])) {
        let size = mediaWidth[mediaQueries[i]];
        graphWidth = size.width;
        graphHeight = size.heigh;
      }
    }
    return [graphWidth, graphHeight]
  }
}
