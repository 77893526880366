import {ChangeDetectorRef, Injectable} from '@angular/core';
import {EntityAction} from "../../models/actions.model";
import {AnomalyCategory} from "../../models/anomalies.model";
import {KpiService} from "../../services/rest-services/kpi.service";
import {take} from "rxjs/operators";
import {DeviceIssuesGridService} from "../../components/dynamic-dashboard/components/insights-anomalies/anomalies-grids/components/device-issues-grid/device-issues-grid.service";
import {
  KpiGroupedBy,
  KpiTrend,
  KpiTrendDisplay,
  KpiType,
  StackedKpiData,
  TrafficKpiDataDisplay, TrafficUnits
} from "../../models/kpi.model";
import {EntityType} from "../../models/entity-type.enum";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActionKpiAnalyzeService {

  constructor(private kpiService: KpiService, private deviceIssuesGridService: DeviceIssuesGridService) {
  }

  /**
   * Generate time visualization prefix
   * @param action
   */
  generatePrefix(action: EntityAction, visualizationTrafficUnit: { unit: TrafficUnits }) {
    let prefix: string = '';
    switch (action.anomalyCategory) {
      case AnomalyCategory.VpnStatus:
      case AnomalyCategory.VpnTraffic:
        prefix += `VPN `;
        break;
      case AnomalyCategory.Devices:
        prefix += `Device `;
        break;
      default:
        break;
    }
    prefix += action.additionalData.name ? `${action.additionalData.name} ` : ' ';

    switch (action.anomalyCategory) {
      case AnomalyCategory.Devices:
      case AnomalyCategory.VpnStatus:
        prefix += `Status:`;
        break;
      case AnomalyCategory.VpnTraffic:
        prefix += `- Traffic (${visualizationTrafficUnit.unit}):`;
        break;
      default:
        break;
    }

    return prefix;
  }

  /**
   * Generate Device Traffic Time span
   */
  generateDeviceTrafficTimeSpan(entityId: number, timeSpans: any[], cdr: ChangeDetectorRef, specificDate: string = undefined, fromDate: number = 7) {
    this.kpiService.getKpiDeviceStatus(entityId, fromDate, specificDate).pipe(take(1)).subscribe(deviceStatues => {
      this.deviceIssuesGridService.initiateTimeSpanWithData(timeSpans, deviceStatues);
      cdr.markForCheck();
    })
  }

  getVisualizationView(anomalyCategory: AnomalyCategory) {
    return anomalyCategory === AnomalyCategory.Devices ||
      anomalyCategory === AnomalyCategory.VpnStatus ||
      anomalyCategory === AnomalyCategory.VpnTraffic ||
      anomalyCategory === AnomalyCategory.ConnectionFailures;
  }

  generateConnectionFailureTrend(entityId: number, entityType: EntityType, tenantId: number, groupedBy: KpiGroupedBy, currentDate: string, fromDate: number, cdr: ChangeDetectorRef) {
    return this.kpiService.getKpiGroupedTrendByIds(KpiType.Connection_Failures, entityType, entityId, tenantId, groupedBy, fromDate, currentDate);
  }
}
