import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AggregateLogChangesEntities, SingleChangeLog, ChangeLog } from '../../models/change-log.model';
import { SingleDeviceType } from '../../models/single-device.model';
import { TranslateService } from '@ngx-translate/core';
import { TimeManagerService } from '../../services/time-manager.service';

@Component({
  selector: 'app-sync-changes-modal',
  templateUrl: './sync-changes-modal.component.html',
  styleUrls: ['./sync-changes-modal.component.scss']
})
export class SyncChangesModalComponent implements OnInit {
  changes: SingleChangeLog[];
  entityType: string;
  constructor(private translate: TranslateService,
    private dateConversion: TimeManagerService,
    private modal: MatDialogRef<SyncChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rowData: ChangeLog, changes: SingleChangeLog[] }) {
    this.changes = data.changes;
    if (this.changes && this.changes[0]) {
      this.entityType = this.changes[0].entityClassName.toString();
      if (Object.values(SingleDeviceType).includes(this.changes[0].entityClassName as any))
        this.entityType = "Device";
    }
  }

  ngOnInit() {
  }

  get dialogTitle() {
    if (this.changes)
      return this.changes.length + " " +
        this.entityType + "s " +
        this.translate.instant('data.COMMON.UPDATED').toLowerCase() + " " +
        this.translate.instant('data.COMMON.TO').toLowerCase() + " " +
        this.data.rowData.venueName + " " +
        this.translate.instant('data.COMMON.IN').toLowerCase() + " " +
        this.dateConversion.dateByFormat(this.data.rowData.time, 'DD-MMM-YYYY HH:mm');
  }

  closeDialog() {
    this.modal.close();

  }

}
