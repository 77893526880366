import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separateUnderscore'
})
export class SeparateUnderscorePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let formattedWord: string = ''
    if (value) {
      let slicedValue = value.split('_');
      if (slicedValue && slicedValue.length > 0) {
          slicedValue.forEach(word => {
            formattedWord += `${word} `;
          });
          return formattedWord;
      }
    }
    return value;
  }

}
