import { FabricStatus } from 'src/app/shared/models/fabrics.model';

export default function fabricsStatusRenderer(row: { data: { fabricState: string } }, translateService) {
    // const data: TenantsHealth = row.data;
    // console.log("fabric state renderer %o", row.data.fabricState)
    let fabricsData = {
        fabricState: FabricStatus.COMPLETE as string
    }
    if (row && row.data) {
        fabricsData = row.data;
    }
    return `<span class="fabrics-state-${fabricsData.fabricState.replace(/\s/g, '').toLowerCase()}">
    ${translateService.instant('data.COMMON.' + fabricsData.fabricState.replace(/\s/g, '').toUpperCase())}</span>`;
}