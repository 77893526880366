<div class="open-ports-pop-over-menu-wrapper" *ngIf="policies && policies.length > 0">
  <button mat-button [matMenuTriggerFor]="menu" [disableRipple]="true" #tooltip="matTooltip"
          [matTooltip]="policyRulesAsText" [matTooltipPosition]="'above'"
          matTooltipClass="marker-tooltip" matTooltipShowDelay="1000">
    <app-allow-deny-view [policies]="policies" [cssOverride]="gridOverride"></app-allow-deny-view>
  </button>

  <mat-menu #menu="matMenu" [class]="'analyze-pop-over-menu'">
    <app-netop-dialog>
      <div (click)="$event.stopPropagation()">
        <app-netop-dialog-title [closeIconClass]="'col-no-flex'" (close)="closeMenu()">
          Policies
        </app-netop-dialog-title>
        <app-netop-dialog-content>
          <div class="open-ports-pop-over-container">
            <app-allow-deny-view [policies]="policies" [cssOverride]="tooltipOverride"></app-allow-deny-view>
          </div>
        </app-netop-dialog-content>
      </div>
    </app-netop-dialog>
  </mat-menu>
</div>
