import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ReportsStoreService } from 'src/app/shared/pages/reports/services/reports-store.service';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { VerticalBarData } from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import { take } from 'rxjs/operators';
import { ClientsBarGeneratorService } from './services/clients-bar-generator.service';
import { TrafficService } from 'src/app/shared/services/rest-services/traffic.service';
import { TrafficOrigin, TrafficDeviation } from '../../../../models/legacy-reports.model';
import { OpenDialogService } from 'src/app/shared/services/open-dialog.service';
import { BaseReportsWidgets } from 'src/app/shared/pages/reports/models/base-reports-widgets';
import { BaseReportsBarWidgets } from 'src/app/shared/pages/reports/models/base-reports-bar-widgets';

@Component({
  selector: 'app-clients-bar',
  templateUrl: './clients-bar.component.html',
  styleUrls: ['./clients-bar.component.scss']
})
export class ClientsBarComponent extends BaseReportsBarWidgets {
  barTitle: string;
  verticalBarDatasets: VerticalBarData;
  verticalBarHeight: number = 300;
  verticalBarWidth: number = 130;
  private logger: Logger; components; columnDefs;

  constructor(
    protected reportsStoreService: ReportsStoreService,
    protected dateConvertor: TimeManagerService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef,
    protected openDialogService: OpenDialogService,
    protected barGenerator: ClientsBarGeneratorService,
    private trafficService: TrafficService,
    private loggerFactory: LoggerService) {
    super(reportsStoreService, dateConvertor, barGenerator, translate, openDialogService, cdr)
    this.logger = this.loggerFactory.getLogger("LossLatencyBarComponent");
  }
  loadData() {
    if (this.datesAsString && this.currentEntity && (this.currentEntity.type == EntityType.VENUE || this.currentEntity.type == EntityType.TENANT)) {
      this.trafficService.getTrafficDeviationHistory(this.currentEntity, this.datesAsString.start, this.datesAsString.end, this.origin as TrafficOrigin).pipe(take(1)).subscribe(deviationData => {
        let deviationByDates = this.barGenerator.adujstResultToDates(deviationData, this.datesAsString);
        this.verticalBarDatasets = this.barGenerator.getBarDatasets(deviationByDates as TrafficDeviation[], this.datesAsString, this.origin as TrafficOrigin);
      })
    }
  }

  get title() {
    return this.translate.instant('data.REPORTS.NON_TRAFFIC_DEVIATION', {
      origin: this.barGenerator.getBarTitle(this.origin as TrafficOrigin)
    })
  }



  ngOnDestroy() {
    this.destroyProcedure();
  }

}
