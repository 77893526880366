import {AbstractControl, ValidatorFn} from '@angular/forms';
import {
  alphaNumericWithSpecialCharsRegex,
  CIDRNotationRegex,
  ipv4Regex,
  macAddressRegex, onlyNumbers,
  phoneNumberRegex,
  portRegex, urlRegex
} from "../operators/regex-operators";
import {isNotNullandUndefined} from "../operators/object-operators/null-checks";

export function tooShortValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value || control.value.length < 3) {
      return {[errorName]: true};
    }
    return null;
  }
}

export type ActualValue = { actualValue: string };

export function alphaNumericWithSpecialCharsValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: ActualValue } | null => {
    const regex = alphaNumericWithSpecialCharsRegex();
    const isValid = !control.value || regex.test(control.value);
    if (!isValid) {
      return {[errorName]: {actualValue: control.value}};
    }
    return null;
  }
}

export function phoneNumberValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = phoneNumberRegex();
    const isValid = !control.value || regex.test(control.value);
    if (!isValid) {
      return {[errorName]: true};
    }
    return null;
  }
}

/**
 * Check if form control value fit to IPv4 address pattern
 * SOURCE:https://www.regextester.com/104851
 */
export function ipv4Validator(errorName: string, allowEmpty: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = ipv4Regex();
    const valid = !allowEmpty
      ? (!control.value || !regex.test(control.value))
      : (!!control.value && !regex.test(control.value));
    if (valid) {
      return {[errorName]: true};
    }
    return null;
  }
}

/**
 * Check if form control value fit to port Pattern. i.e.: 2-4 digits
 */
export function portValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = portRegex();
    if (!control.value || !regex.test(control.value)) {
      return {[errorName]: true};
    }
    return null;
  }
}

export function portsRangeValidator(errorName: string) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!isNotNullandUndefined(control.value)) return {['isEmpty']: true};
    let valid = control.value >= 1 && control.value <= 36000;
    return valid ? null : {['notInRange']: true};
  }
}

/**
 * Check if form control value fit to CIDR Notation Pattern
 * SOURCE: https://www.regexpal.com/93987
 */
export function CIDRNotationValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = CIDRNotationRegex();
    if (!control.value || !regex.test(control.value)) {
      return {[errorName]: true};
    }
    return null;
  }
}

/**
 * Check if form control value fit to CIDR Notation Pattern
 * SOURCE: https://www.regextester.com/93796
 */
export function macAddressValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = macAddressRegex();
    if (!control.value || !regex.test(control.value)) {
      return {[errorName]: true};
    }
    return null;
  }
}

export function emailsListValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    // Get value on emails input as a string
    const emails = control.value.split(",");
    let valid = true;
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (let i = 0; i < emails.length; i++) {
      // Trim whitespaces from email address
      const trimmedEmail = emails[i].trim();

      // Check email against our regex to determine if email is valid
      if (trimmedEmail != "" && !regex.test(trimmedEmail)) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      return {[errorName]: true};
    }
    return null;
  };
}

export function isNumberValidator(errorName: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = onlyNumbers();
    if (control.value && !regex.test(control.value)) {
      return {[errorName]: true};
    }
    return null;
  }
}

export function isStringValidator(errorName: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && !isNaN(parseInt(control.value))) {
      return {[errorName]: true};
    }
    return null;
  }
}

/**
 * Check if form control value fit to CIDR Notation Pattern
 * SOURCE: https://www.regextester.com/93796
 */
export function commaSeparatedUrlsValidator(errorName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = urlRegex();
    let isInputValid: boolean = control.value;
    if (control.value) {
      const urls = control.value.toString().split(',');
      isInputValid = urls.find(url => url.trim() && !regex.test(url.trim())) === undefined;
    }
    if (!isInputValid) {
      return {[errorName]: true};
    }
    return null;
  }
}
