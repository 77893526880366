import { Injectable } from '@angular/core';
import { BaseAnomalySpanService } from '../../services/base-anomaly-span.service';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { HealthColorsService } from 'src/app/shared/services/strategies/health-colors.service';
import { IBaseAnomalyHorizontalGrid } from '../../models/IBase-anomaly-horizontal-span';
import { VPNAnomaly } from 'src/app/shared/models/anomalies.model';
import { VPNConnectionStatus, VPNStatusTrendDTO, VPNConnectionStatusTrendDTO } from 'src/app/shared/models/vpn.model';
import { calculateDaysNumberBetweenTwoDates, calculateTotalHoursBetweenTwoDates } from 'src/app/shared/operators/time-operator';
import { HoriznotalTimeSpan } from '../../../../../../horizontal-timeline/models/horizontal-time-span.model';

@Injectable({
  providedIn: 'root'
})
export class VpnAnomaliesService extends BaseAnomalySpanService implements IBaseAnomalyHorizontalGrid {
  constructor(protected dateConversion: TimeManagerService,
    protected healthService: HealthColorsService,
  ) {
    super(healthService, dateConversion);
  }

  getLastDateAsString(VPNAnomaly: VPNAnomaly[], textAddition?: any) {
    if (VPNAnomaly &&
      VPNAnomaly.length > 0 &&
      VPNAnomaly.filter(issue => issue.vpnConnectionStatusHistory.length > 0).length > 0) {
      let earliestDate: Date = this.findEarliestDate(VPNAnomaly[0].vpnConnectionStatusHistory);
      VPNAnomaly.forEach(issue => {
        if (this.findEarliestDate(issue.vpnConnectionStatusHistory) < earliestDate)
          earliestDate = this.findEarliestDate(issue.vpnConnectionStatusHistory);
      });
      if (calculateDaysNumberBetweenTwoDates(earliestDate, new Date()) < 1) {
        return `Last ${calculateTotalHoursBetweenTwoDates(earliestDate, new Date()).toFixed(0)} hours device status`;
      }
      return `Last ${calculateDaysNumberBetweenTwoDates(earliestDate, new Date()).toFixed(0)} days device status`;
    }
    return "Time";
  }

  findEarliestDate(vpnConnectionStatusHistory: VPNConnectionStatusTrendDTO[]): Date {
    if (vpnConnectionStatusHistory.length > 0) {
      let earliestDate: Date = this.dateConversion.convertUnixToDateObject(vpnConnectionStatusHistory[0].startTime);
      vpnConnectionStatusHistory.forEach(status => {
        if (this.dateConversion.convertUnixToDateObject(status.endTime) < earliestDate)
          earliestDate = this.dateConversion.convertUnixToDateObject(status.startTime);
      });
      return earliestDate;
    }
    return new Date();
  }

  getTimeSpans(statuses: VPNConnectionStatusTrendDTO[]) {
    let timeSpans: HoriznotalTimeSpan[] = [];
    statuses.forEach(status => {
      timeSpans.push(
        new HoriznotalTimeSpan(
          this.dateConversion.convertUnixToDateObject(status.startTime),
          this.dateConversion.convertUnixToDateObject(status.endTime),
          status.vpnConnectionStatus.toString(),
          this.healthColorsService.getColorByConnectionStatus(status.vpnConnectionStatus),
          `Start: ${this.dateConversion.dateByFormat(status.startTime, 'DD-MMM-YYYY HH:mm')},

          End: ${this.dateConversion.dateByFormat(status.endTime, 'DD-MMM-YYYY HH:mm')}`
        ))
    });
    return timeSpans;
  }

}
