import { Component, OnInit, Input } from '@angular/core';

/**
 * Basic container component that is meant to replace (over time) the column box one.
 */
@Component({
  selector: 'app-basic-container',
  templateUrl: './basic-container.component.html',
  styleUrls: ['./basic-container.component.scss']
})
export class BasicContainerComponent implements OnInit {
  @Input() containerCss;
  constructor() { }

  ngOnInit() {
  }

}
