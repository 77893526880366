import { Inject, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ActionStatus } from 'src/app/shared/models/actions.model';

@Injectable({
    providedIn: 'root'
})
export class EntityGridActionsMenuService {
    actionPerformed: Subject<ActionStatus> = new Subject();
    actionPerformed$: Observable<ActionStatus> = this.actionPerformed.asObservable();

}