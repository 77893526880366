import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CloudSubnetDTO} from "../../../../../models/cloud-network.model";

@Component({
  selector: 'app-subnet-properties-display',
  templateUrl: './subnet-properties-display.component.html',
  styleUrls: ['./subnet-properties-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubnetPropertiesDisplayComponent implements OnInit {
  @Input() showTeleport: boolean = false;
  @Input() subnets: CloudSubnetDTO[];

  constructor() {
  }

  ngOnInit() {

  }

}
