import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {TimePayload} from "../time-range-picker.model";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {getRange} from "../../../operators/numeric-operator";
import {Subscription} from "rxjs";
import {NgChanges} from "../../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hour: number = 0;
  @Input() minute: number = 0;
  @Output() timeSelected = new EventEmitter<TimePayload>();

  hours: number[] = getRange(0, 23);
  minutes: number[] = getRange(0, 59);
  formGroup: UntypedFormGroup;
  formGroupSubscription: Subscription;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.subscribeToFormGroup();
  }

  ngOnChanges(changes: NgChanges<TimePickerComponent>) {
    if ((changes.hour || changes.minute) && this.formGroup?.controls) {
      this.formGroup.controls['hour'].patchValue(this.hour, {emitEvent: false});
      this.formGroup.controls['minute'].patchValue(this.minute, {emitEvent: false});
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromFormGroup();
  }

  createFormGroup() {
    this.formGroup = this.fb.group({
      hour: this.fb.control(this.hour),
      minute: this.fb.control(this.minute)
    });
  }

  emitSelection() {
    this.timeSelected.emit({
      hour: this.hour,
      minute: this.minute
    });
  }

  subscribeToFormGroup() {
    this.unsubscribeFromFormGroup();
    this.formGroupSubscription = this.formGroup.valueChanges.subscribe(() => {
      this.setTime();
      this.emitSelection();
    });
  }

  unsubscribeFromFormGroup() {
    if (this.formGroupSubscription && !this.formGroupSubscription.closed) {
      this.formGroupSubscription.unsubscribe();
    }
  }

  setTime() {
    this.hour = this.formHour;
    this.minute = this.formMinute;
  }

  get formHour(): number {
    if (this.formGroup?.controls && this.formGroup.controls['hour']?.value) {
      return this.formGroup.controls['hour'].value;
    }
    return 12;
  }

  get formMinute(): number {
    if (this.formGroup?.controls && this.formGroup.controls['minute']?.value) {
      return this.formGroup.controls['minute'].value;
    }
    return 0;
  }
}
