import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CrudActionType} from '../../../accounts/org-manage-tenant/crud-action';
import {
  OrganizationType,
  OrgDashboardType,
  PropType,
  OrgTypeWithAction
} from 'src/app/shared/models/organizations-type.model';
import {UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {OrganizationsService} from 'src/app/shared/services/rest-services/organizations.service';
import {HierarchyLevel} from 'src/app/shared/models/hierarchy.model';
import {alphaNumericWithSpecialCharsValidator} from "../../../../shared/form-validators/form-validators";
import {ERRORS_NAME} from "../../../../shared/form-validators/form-model";

@Component({
  selector: 'app-organization-types-manager',
  templateUrl: './organization-types-manager.component.html',
  styleUrls: ['./organization-types-manager.component.scss']
})
export class OrganizationTypesManagerComponent implements OnInit {
  @Input() currentOrg: HierarchyLevel;
  @Input() propsCss: string;
  @Output() typesChanged: EventEmitter<boolean> = new EventEmitter();
  orgTypeForm: UntypedFormGroup;
  typeSelected: boolean = false;
  orgDashboardType = OrgDashboardType.getDashboardTypesAsArray();
  currentAction: CrudActionType;
  currentType: OrganizationType;
  crudActionType = CrudActionType;
  propType = PropType.getPropTypesAsEnum();
  subscription: Subscription[] = [];

  constructor(private organizationsService: OrganizationsService) {
    this.loadEmptyForm();
  }

  ngOnInit() {

  }

  /**
   * The method invoked whenever type was selected
   * The method switch resolvation for different crudAction
   * The method set typeSelected to true, and set the current action and type properties
   * The method re-load the form with empty values
   */
  onCrudSelection(actionData: OrgTypeWithAction) {
    this.typeSelected = true;
    if (this.currentType && actionData.type && actionData.type.id !== this.currentType.id ||
      actionData.crudAction == CrudActionType.Create ||
      !this.currentType) {
      this.orgTypeForm.reset();
      if (actionData.crudAction == this.crudActionType.Edit || actionData.crudAction == this.crudActionType.View) {
        this.loadEditForm(actionData.type);
      }
    }

    switch (actionData.crudAction) {
      case CrudActionType.Create:
        this.orgTypeForm.enable();
        this.loadEmptyForm();
        break;
      case CrudActionType.View:
        this.orgTypeForm.disable();
        break;
      case CrudActionType.Edit:
        this.orgTypeForm.enable();
        break;
      default:
        break;
    }
    this.currentAction = actionData.crudAction;
    this.currentType = actionData.type;
  }

  onOrgTypesListChanges() {
    this.typeSelected = false;
    this.currentAction = null;
    this.currentType = null;
    this.orgTypeForm.reset();
  }

  /**
   * Load the Form with null values and set subscription to it
   */
  loadEmptyForm() {
    this.orgTypeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
      dashboardType: new UntypedFormControl(this.orgDashboardType[0]),
      properties: new UntypedFormArray([
        new UntypedFormGroup({
          name: new UntypedFormControl('', [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
          type: new UntypedFormControl('', [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
          mandatory: new UntypedFormControl('')
        })
      ])
    })
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    let formSubsc = this.orgTypeForm.valueChanges.subscribe(changes => {
      // console.log("changes", changes);
    })
    this.subscription.push(formSubsc);
  }

  /**
   * Load form for current type.
   * Set form default values by current type
   * @param type Current selected type
   */
  loadEditForm(type: OrganizationType) {
    this.orgTypeForm.reset();
    this.properties.clear();
    this.orgTypeForm.get('name').setValue(type.name);
    this.orgTypeForm.get('dashboardType').setValue(type.dashboardType);
    if (type.properties.length > 0) {
      type.properties.forEach((prop, index) => {
        if (this.properties.controls[index])
          this.properties.controls[index].setValue({name: prop.name, type: prop.type, mandatory: prop.mandatory});
        else
          this.properties.controls.push(new UntypedFormGroup({
            name: new UntypedFormControl(prop.name, [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
            type: new UntypedFormControl(prop.type, [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
            mandatory: new UntypedFormControl(prop.mandatory)
          }));
      })
    }
  }

  /**
   * Add property to properties array
   */
  addProperty() {
    const property = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
      type: new UntypedFormControl('', [Validators.required, alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)]),
      mandatory: new UntypedFormControl('')
    });
    this.properties.push(property);
    this.scroll();
  }

  /**
   * Remove property from Properties array
   * @param index Current prop index
   */
  removeProperty(index: number) {
    this.properties.removeAt(index);
    this.scroll();
  }

  cancelChanges() {
    this.onCrudSelection({type: this.currentType, crudAction: this.currentAction});
  }

  /**
   * Save changes for edited type
   */
  saveChanges() {
    let finalValue = this.orgTypeForm.value as OrganizationType;
    let updatedType: OrganizationType = {
      id: this.currentType.id,
      name: finalValue.name,
      organizationId: this.currentOrg.id,
      dashboardType: finalValue.dashboardType,
      properties: finalValue.properties
    }
    this.organizationsService.updateOrgType(updatedType);
    this.emit();

  }

  /**
   * Add new type
   */
  addType() {
    let finalValue = this.orgTypeForm.value as OrganizationType;
    let newType: OrganizationType = {
      name: finalValue.name,
      organizationId: this.currentOrg.id,
      dashboardType: finalValue.dashboardType,
      properties: finalValue.properties
    }
    this.organizationsService.addOrgType(newType);
    this.emit();
  }

  emit() {
    this.orgTypeForm.disable();
    this.typesChanged.emit(true);
  }

  changeEditMode() {
    this.orgTypeForm.enabled ?
      this.orgTypeForm.disable() :
      this.orgTypeForm.enable();
  }

  /**
   * Current form properties array
   */
  get properties(): UntypedFormArray {
    return this.orgTypeForm.get('properties') as UntypedFormArray;
  }

  get isShowEditButton() {
    return this.currentAction == this.crudActionType.Edit || this.currentAction == this.crudActionType.View;
  }

  get isFormEnabled() {
    return this.orgTypeForm.enabled;
  }

  get isFormEnabledAndValid() {
    return this.orgTypeForm.enabled && this.orgTypeForm.valid;
  }

  get isTypeNotParentType() {
    if (this.currentType && this.currentOrg)
      return this.currentType.organizationId == this.currentOrg.id;
  }

  get crudTitle() {
    switch (this.currentAction) {
      case CrudActionType.Create:
        return 'New';
      case CrudActionType.Edit:
        return 'Edit';
      case CrudActionType.View:
        return '';
      default:
        break;
    }
  }

  private scroll() {
    setTimeout(() => {
      let scrollAnchor: HTMLElement = document.getElementById("scroll-anchor");
      if (scrollAnchor) {
        scrollAnchor.scrollIntoView({behavior: "smooth"})
      }
    }, 100)
  }

  ngOnDestroy() {
    this.subscription.forEach(subcs => subcs.unsubscribe());
  }

}
