<div [@hoverTooltip]="animationTrigger">
    <app-topology-tooltip *ngIf="node" [nodeEvent]="node" [title]="title"
        [tplgRect]="tplgRect" (isHover)="isHoverTooltip($event)" [tooltipType]="tooltipType">
        <ng-container>
            <div class="row row-client-container" *ngFor="let property of tooltipContent">
                <span class="col single-client-key">{{property.key}}</span>
                <span class="col single-client-value"
                    [ngStyle]="{color: healthColorsService.getRSSIColors(property)}">{{property.value}}</span>
            </div>
        </ng-container>
    </app-topology-tooltip>
</div>
