import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreFirebaseStateService {
  isEditMode: boolean = false;
  private notifyIsEditMode: BehaviorSubject<boolean> = new BehaviorSubject(null);
  notifyIsEditModeAsObservable$ = this.notifyIsEditMode.asObservable();

  constructor() { }

  updateEditMode() {
    this.notifyIsEditMode.next(this.isEditMode);
  }
}
