<ag-grid-angular [showSpinner]="isLoading" class="entity-list ag-theme-material" [ngStyle]="gridSizes"
                 rowSelection="single"
                 suppressCellFocus="true" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                 (gridReady)="onGridReady($event)" suppressHorizontalScroll="true"
                 (modelUpdated)="onGridReady($event)" [components]="components"
                 [overlayLoadingTemplate]="'&nbsp;'"
                 [sortingOrder]="['asc','desc']" [rowClassRules]="rowClassRules" [cacheBlockSize]="numberOfRawsInPage"
                 [context]="context" [icons]="icons" rowModelType="infinite" [debug]="debugGrid" [rowHeight]="rowHeight"
                 [headerHeight]="headerHeight" (sortChanged)="onSortChange($event)" (dragStopped)="onColDragEnd($event)"
                 (viewportChanged)="onViewPortChanges($event)" (bodyScroll)="bodyScroll($event)"
                 [enableBrowserTooltips]="true" [rowClass]="rowClass" (columnResized)="onColumnResized($event)">
</ag-grid-angular>
