import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bot-container',
  templateUrl: './bot-container.component.html',
  styleUrls: ['./bot-container.component.scss']
})
export class BotContainerComponent implements OnInit {

  @Input() cssClass: string = undefined;
  @Input() overflowY: boolean = false;
  @Input() maxHeight: string = '100vh';
  @Input() paddingBottom: string = 'unset';

  constructor() { }

  ngOnInit(): void {
  }



}
