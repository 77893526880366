import { GraphTypeSelectorData, GraphTypeSelectorTotalData } from 'src/app/shared/components/graph-type-selector/graph-type-selector-data.model';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { zip, Observable } from 'rxjs';
import { GraphType } from '../graph-type-selector/graph-type.enum';
import { EntityDashboardConfig } from './entity-dashboard-config.service';
import { TrendGraphSelectorsConfigService } from './trend-graph-selectors-config.service';
import { EntityType } from '../../models/entity-type.enum';

/**
 * A convenience class that implements parts of the EntityDashboardConfig interface.
 */
export abstract class BaseEntityConfigService implements EntityDashboardConfig {

    protected logger: Logger;

    constructor(protected loggerFactory: LoggerService,
        protected trendGraphSelectorsConfigService: TrendGraphSelectorsConfigService) {
        this.logger = this.loggerFactory.getLogger("BaseEntityConfigService");
    }
    getTrendGraphSelectorsConfig$(entityType: (EntityType.ORGANIZATION | EntityType.TENANT)): Observable<GraphTypeSelectorData[]> {
        return this.trendGraphSelectorsConfigService.getTrendGraphSelectorsConfig$(entityType)
    }
    getHealthKpiConfig$(entityType: (EntityType.ORGANIZATION | EntityType.TENANT), time: string): Observable<GraphTypeSelectorData[]> {
        return this.trendGraphSelectorsConfigService.getHealthKpiTrendConfig$(entityType, time)
    }

    abstract getIssuesDonutConfig$(): Observable<{ title: string, datasets: any[] }>;
    abstract getTrendGraphConfig$(graphType: GraphType): Observable<{ datasets: any[] }>;
}
