import {Injectable} from '@angular/core';
import {KpiGroupedBy} from 'src/app/shared/models/kpi.model';
import {StackedBarConfig, StackedBarThemeConfig} from '../models/stacked-bar.config';
import {STACKED_FULL_COLORS, STACKED_HALF_COLORS, STACKED_ZERO_COLORS} from '../utils/chartjs-colors';
import {NumericHash} from "../../../models/utils-classes/hash.model";

@Injectable({
  providedIn: 'root'
})
export class StackedBarConfigService {

  constructor() {
  }

  zeroOpacityColors = STACKED_ZERO_COLORS;
  halfOpacityColors = STACKED_HALF_COLORS;
  fullOpacityColor = STACKED_FULL_COLORS;

  getStackedOrSplitConfig(label: string, index: number, groupedBy: KpiGroupedBy = undefined) {
    return groupedBy === KpiGroupedBy.Application || groupedBy === KpiGroupedBy.Failure_Step ?
      this.getStackedChartConfig(label, index) :
      this.getSplitChartConfig(label, index);
  }

  getStackedBarConfig(label: string, total: number, index: number): StackedBarThemeConfig {
    let defaultColors = this.fullOpacityColor;
    let hoverColors = this.halfOpacityColors;
    return {
      data: [total],
      label: label,
      defaultColor: defaultColors[index],
      hoverColor: hoverColors[index],
      backgroundColor: defaultColors[index],
      borderColor: "#ffffff",
      borderWidth: 1,
      hoverBackgroundColor: hoverColors[index],
      borderSkipped: "left",
      // minBarLength: 5,
    } as StackedBarThemeConfig;
  }

  getStackedChartConfig(label: string, index: number) {
    let fromColor = this.halfOpacityColors[index];
    let toColor = this.zeroOpacityColors[index];
    return {
      gradient: {
        fromColor,
        toColor
      },
      label: label,
      strokeColor: toColor,
      fill: true,
      borderColor: fromColor,
      borderWidth: 2,
      //lineTension: 0,
      pointBorderColor: fromColor
    }
  }

  getSplitChartConfig(label: string, index: number) {
    let fromColors = [
      'rgba(58, 150, 253, 0.7)',
      'rgba(157, 96, 251, 0.7)'
    ];
    let toColors = [
      'rgba(58, 150, 253, 0)',
      'rgba(157, 96, 251, 0)',
    ]
    const isPinkColor: boolean = label === '↓' || label === 'In' || label === 'Wired';
    let fromColor = isPinkColor ? fromColors[1] : fromColors[0];
    let toColor = isPinkColor ? toColors[1] : toColors[0];
    return {
      // illTrend: {
      gradient: {
        fromColor,
        toColor
      },
      label: label,
      strokeColor: toColor,
      fill: true,
      borderColor: fromColor,
      borderWidth: 2,
      //lineTension: 0
    }
  }

  /**
   * Convert NumericHash objects into StackedBarConfig array
   * @param data The NumericHash Object
   */
  convertHashToStackedBarConfig(data: NumericHash) {
    let stackedBarConfig: StackedBarConfig[] = [];
    for (const [key, value] of Object.entries(data)) {
      let total: number = 0;
      Object.values(value).forEach(datum => total += datum);
      stackedBarConfig.push({
        datasets: this.generateStackedLineForNumericConfig(value),
        responsive: false,
        total: total,
        title: `By ${key.toUpperCase()}`
      })
    }
    return stackedBarConfig;
  }

  private generateStackedLineForNumericConfig(data: { [p: string]: number }) {
    let lineConfig: StackedBarThemeConfig[] = [];
    let index = 0;
    let defaultColors = this.fullOpacityColor;
    let hoverColors = this.halfOpacityColors;
    let total: number = 0;
    Object.values(data).forEach(datum => total += datum);
    for (const [key, value] of Object.entries(data)) {
      if (value > 0) {
        lineConfig.push({
          data: [value],
          label: key,
          defaultColor: defaultColors[index],
          hoverColor: hoverColors[index],
          backgroundColor: defaultColors[index],
          borderColor: "#ffffff",
          borderWidth: 1,
          hoverBackgroundColor: hoverColors[index],
          borderSkipped: "left",
          percentage: value / total * 100
          // minBarLength: 5,
        } as StackedBarThemeConfig)
      }
      index++
    }
    ;
    return lineConfig;
  }
}
