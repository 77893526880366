import {SingleDeviceType} from 'src/app/shared/models/single-device.model';
import {SSID} from 'src/app/shared/models/ssid.model';
import {VLAN} from 'src/app/shared/models/vlan.model';
import {TopologyNodeType} from '../../network-topology/models/TopologyTypes.model';

export class PropertiesContent {
  headerData: HeaderData;
  mainData: MainData;
}

export class HeaderData {
  id?: number;
  elementType: PropertiesElementType
  firstColumn?: { main: string, sub: any, errorMessage?: { errorLabel: string, errorContent: string } }
  noDataMessage?: string;
  health?: number;
  healthDegradationReasons?: { percentage?: number, reason: string }[]
  issues?: number;
  actionsList?: string[];
  status?: any;
  iconPath: string;
  secondaryIconPath?: string;
  type?: TopologyNodeType;
}

export class MainData {
  panelElements: PanelElement[];
  elementType?: PropertiesElementType;
}

export class PanelElement {
  key: string;
  value: any;
  isTeleported?: boolean;
  withCrudIcons?: boolean;
  isValueAsTooltip?: boolean;
}

export enum PropertiesElementType {
  FABRIC = "Fabric",
  VENUE = "Venue",
  NODE = "Node",
  LINK = "Link",
  CloudNode = "CloudNode"
}

export enum PropertiesDataType {
  ACTIONS = "ACTIONS",
  HEALTH = "HEALTH"
}
