import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sub-header-border',
  templateUrl: './sub-header-border.component.html',
  styleUrls: ['./sub-header-border.component.scss']
})
export class SubHeaderBorderComponent implements OnInit {
  @Input() cssClass: string;
  constructor() { }

  ngOnInit() {
  }

}
