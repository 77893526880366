<ng-container *ngFor="let word of wording; let isFirst = first">

  <!-- Regular text-->
  <ng-container *ngIf="word.rule.type == dynamicWordingRuleType.Normal">
        <span>
            {{isFirst ? word.value : word.value.toString().toLowerCase()}}
        </span>
  </ng-container>

  <!-- Bold text-->
  <ng-container *ngIf="word.rule.type == dynamicWordingRuleType.Bold">
        <span class="d-wording-bold-text">
            {{word.value.toString().toLowerCase()}}
        </span>
  </ng-container>

  <!-- Input text-->
  <ng-container *ngIf="word.rule.type == dynamicWordingRuleType.Input">
    <div class="d-wording-text-input">
      <mat-form-field>
        <input matInput (keyup)="onKeyUp($event, word)" [pattern]="word?.pattern"
               [value]="word.value" [formControl]="formControls[word.id]"/>
      </mat-form-field>
    </div>
  </ng-container>

	<!-- Input Percents -->
  <ng-container *ngIf="word.rule.type == dynamicWordingRuleType.InputPercents" >
	<mat-form-field *ngIf="edidInputPercents.id == word.id" appearance="outline">
		<span class="input-percents">
			<input matInput [(ngModel)]="word.value" (keyup)="onInputChanged($event, word)"   />
			<div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups"> 
				<button type="button" class="btn btn-outline-danger" color="warn" (click)="onCancele(word)">X</button>
				<button type="button" class="btn  btn-outline-success" [disabled]="!edidInputPercentsValid"  (click)="onSave(word)">V</button>
			</div>
			<!-- <button mat-icon-button color="primary" [disabled]="!edidInputPercentsValid"  (click)="onSave(word)">V</button>
			<button mat-icon-button color="warn" [disabled]="!edidInputPercentsValid"  (click)="onCancele(word)">X</button> -->
		</span>
	</mat-form-field>
	<button  mat-button  *ngIf="edidInputPercents.id != word.id"
		(click)="editInput(word)">
		{{word.value}}
	</button>
  </ng-container>

  <!-- Dropdown text-->
  <ng-container *ngIf="word.rule.type == dynamicWordingRuleType.Dropdown">
    <button mat-button [matMenuTriggerFor]="menu" class="d-wording-text-underline"> {{word?.value}}
    </button>
    <mat-menu #menu="matMenu" class="d-wording-mat-menu">
      <button mat-menu-item *ngFor="let option of word.options" (click)="onDropdownClicked(option, word)"
              class="d-wording-text">{{option}}</button>
    </mat-menu>
  </ng-container>
</ng-container>
