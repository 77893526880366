<app-portlet>
    <app-portlet-main-content>
        <app-portlet-header [title]="donutTitle" class="portlet-header" [hideDefaultTools]="true">
            <ng-container nextToTitle>
                <span class="reports-next-to-text">
                    {{byText}}</span>
            </ng-container>
            <ng-container portletTools>
                <div>{{total}}</div>
            </ng-container>
        </app-portlet-header>
        <app-portlet-body class="portlet-body trend-graph">
            <app-graph-donut [showSpinner]="isLoading" class="graph-donut-container" [graphConfig]="donutConfig"
                [isLabelsDisplay]="true" [height]="height" [width]="width" [noDataText]="noDataMessage">
            </app-graph-donut>
            <div *ngIf="lowResultsNumber" class="top-traffic-explain-text">
                {{'data.REPORTS.NO_TRAFFIC_EXPLAIN' | translate}}</div>
        </app-portlet-body>
    </app-portlet-main-content>
</app-portlet>