import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalTextContent } from '../models/modal-content.model';
import { FormAction } from '../models/form-actions.model';

@Component({
  selector: 'app-save-edited-properties',
  templateUrl: './save-edited-properties.component.html',
  styleUrls: ['./save-edited-properties.component.scss']
})
export class SaveEditedPropertiesComponent {
  modalText: ModalTextContent;
  formAction = FormAction;
  btnDisabled: boolean = false;

  constructor(private matDialogRef: MatDialogRef<SaveEditedPropertiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: FormAction, data: ModalTextContent }) {
    this.modalText = this.data.data;
  }

  closeDialog(buttonType: FormAction) {
    this.matDialogRef.close(buttonType)
  }
}
