import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {ClientsTopologyService} from "../../../../services/clients-topology.service";
import {Subscription} from "rxjs";
import {ClientsToDisplayConvertorService} from "../../services/clients-to-display-convertor.service";
import {NumericHash} from "../../../../../../models/utils-classes/hash.model";
import {StackedBarConfig} from "../../../../../chartjs-components/models/stacked-bar.config";
import {StackedBarConfigService} from "../../../../../chartjs-components/services/stacked-bar-config.service";

@Component({
  selector: 'app-header-clients-display',
  templateUrl: './header-clients-display.component.html',
  styleUrls: ['./header-clients-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderClientsDisplayComponent {
  clientsDisplayData: NumericHash;
  @Input() height: number;
  @Input() width: number;
  stackedBarConfig: StackedBarConfig[];
  subscriptions: Subscription[] = [];

  constructor(
    private clientsTopologyService: ClientsTopologyService,
    private convertorService: ClientsToDisplayConvertorService,
    private stackedBarConfigService: StackedBarConfigService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadClientsData();
  }

  private loadClientsData() {
    const clientsSubsc = this.clientsTopologyService.notifyClientSelectionAsObservable$.subscribe(clients => {
      if (clients && clients.length > 0) {
        this.clientsDisplayData = this.convertorService.convertConnectedClientsToHash(clients);
        this.stackedBarConfig = [...this.stackedBarConfigService.convertHashToStackedBarConfig(this.clientsDisplayData)];
      }
      this.cdr.markForCheck();
    });
    this.subscriptions.push(clientsSubsc);
  }

  ngOnDestroy() {
    this.clientsTopologyService.emptyStoreTopologyClients();
    this.subscriptions.forEach(subsc => subsc.unsubscribe());
  }
}
