<div class="row pb-3 header-container" [ngClass]="headerRowCss">
  <div *ngIf="!isDevice" class="col big-chart-title">
    <span>{{formattedKpiHeader}}</span>
    <span>(Last Week)</span>
  </div>
  <ng-container *ngIf="!isDevice" [ngTemplateOutlet]="bigChartHeader"></ng-container>
</div>

<div [ngClass]="cssGraphClass">
  <div class="row">
    <div class="col" [showSpinner]="isLoading">
      <app-graph-trend [maxTicksX]="6" [chartConfig]="trendLineConfig" [graphHeight]="graphHeight"
                       [graphWidth]="graphWidth" [maxY]="maxTrendValue" [beginAtZero]="true" [stepSize]="isOnlyInteger"
                       [stacked]="isStacked">
      </app-graph-trend>
    </div>
  </div>
  <ng-container *ngIf="!isDevice">
    <div class="row big-chart-footer-row">
      <ng-container *ngIf="isTrafficUtilization">
        <div class="col justify-end-column align-center-column">
        </div>
        <div class="col gauge-col justify-end-column align-baseline">
          <span class="gauge-label">{{'data.TENANTS.BANDWIDTH.BANDWIDTH_UTILIZATION' | translate}}</span>
          <app-gauge *ngFor="let gauge of gaugeConfiguration; let first = first"
                     [ngxGaugeConfiguration]="gauge" [ngClass]="first ? 'pr-5': null"></app-gauge>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #bigChartHeader>
  <ng-container *ngIf="isShowToggle">
    <div class="col stacked-container">
      <app-stacked-bar-with-graph #stackedBar [width]="showPeriodForm? 550 : 750" [stackedAndSplitData]="stackedAndSplitData"
                                  (stackedDatasetSelected)="trendLineConfig = $event"
                                  (toggleSelectionChanges)="onToggleChange($event)">
      </app-stacked-bar-with-graph>
    </div>
  </ng-container>
  <!--
      The empty div is replacing the slide toggle element,
      in order to keep the column position for all kpi charts.
  -->
  <div *ngIf="!isShowToggle" class="col"></div>
  <div *ngIf="isShowCloseIcon" class="col big-chart-close-icon-container" [ngClass]="closeIconClass"
       (click)=" closeBigChart()">
    <mat-icon class="big-chart-close-icon" mat-list-icon>close</mat-icon>
  </div>
  <ng-container *ngIf="showPeriodForm">
    <div class="col col-no-flex big-chart-form-label">
      <!--            <p class="time-span-label">Show:</p>-->
    </div>
    <div class="col col-no-flex big-chart-time-selection">
      <!--            <form [formGroup]="selectedTrendPeriod" class="big-chart-form-container">-->
      <!--                <mat-form-field class="big-chart-selelction-container">-->
      <!--                    <mat-select formControlName="selectedTrendPeriod">-->
      <!--                        <mat-option [disabled]="period !== 'Week'" *ngFor="let period of trendPeriod" [value]="period">-->
      <!--                            {{period}}-->
      <!--                        </mat-option>-->
      <!--                    </mat-select>-->
      <!--                </mat-form-field>-->
      <!--            </form>-->
    </div>
  </ng-container>
</ng-template>
