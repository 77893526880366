export class ProgressBarDTO<T>{
    total: number;
    value: number;
    name: string;
    color: string;
    originalData: T;
    tooltipText: string;

    constructor(
        originalData: T,
        total: number,
        value: number,
        name: string,
        color: string,
        tooltipText: string) {
        this.originalData = originalData;
        this.total = total;
        this.value = value;
        this.name = name;
        this.color = `${color}`;
        this.tooltipText = tooltipText;
    }

    get displayPercentage() {
        return this.percentage ?
            this.percentage.toFixed(2) :
            0;
    }
    get percentage(): number {
        return this.value * 100 / this.total;
    }

}