import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp, ITooltipAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, ITooltipComp, ITooltipParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-text-tooltip-cell-renderer',
  templateUrl: './text-tooltip-cell-renderer.component.html',
  styleUrls: ['./text-tooltip-cell-renderer.component.scss']
})
export class TextTooltipCellRendererComponent implements ITooltipAngularComp, OnDestroy {
  private params: ITooltipParams;
  private data: any;
  message: string;

  agInit(params: ITooltipParams): void {
    this.params = params;
    if (params?.data) {
      this.message = params.value.replace(/&#39;/g, "'");
    }
  }

  constructor() { }
  ngOnDestroy(): void {

  }



}
