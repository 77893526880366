import {ElementRef, Injectable} from '@angular/core';
import {TopologyConfiguration, TreeConfiguration} from '../models/topology-configuration';
import {D3Tree} from '../models/d3-tree';
import * as d3 from 'd3';
import {TopologyBuilderService} from './topology-builder.service';
import {SingleD3Node} from '../../../models/topology';
import {WidthHeight} from "../../../models/utils-classes/sizes.model";


@Injectable({
  providedIn: 'root'
})
export class D3Service {
  svgElement: ElementRef<SVGElement>;

  constructor(private topologyBuilder: TopologyBuilderService) {
  }

  /**
   * Create Tree object and return it
   */
  createTreeObject(treeConfiguration: TreeConfiguration, topologyConfiguration: TopologyConfiguration, treeSizes: WidthHeight, isSqueezable: boolean = false): D3Tree {
    if (treeConfiguration && topologyConfiguration) {
      return new D3Tree(treeConfiguration, topologyConfiguration, treeSizes, this.topologyBuilder, isSqueezable);
    }
  }

  /**
   * Apply zoom and drag behaviour on the s3 graph.
   * The method handle both wheel events and drag events.
   * @param svgElement The main svg element of the graph
   * @param containerElement The container element as received from the directive
   */
  applyZoomableDraggableBehaviour(svgElement: ElementRef<SVGElement>, containerElement: any) {
    let svg, container, zoomed, zoom;

    svg = d3.select(svgElement);
    container = d3.select(containerElement);

    zoomed = () => {
      const transform = d3.event.transform;
      /**Check if the  mouse event is scroll (aka: wheel) or not*/
      if (d3.event.sourceEvent.type && d3.event.sourceEvent.type === 'wheel') {
        container
          .attr('transform', `translate(${transform.x},${transform.y}) scale(${transform.k})`)
          .attr('style', 'transition-duration: 300ms');
      } else {
        /**Important to set the  transition-duration to 0, Otherwise - After wheel event it will remain 300ms*/
        container
          .attr('transform', `translate(${transform.x},${transform.y}) scale(${transform.k})`)
          .attr('style', 'transition-duration: 0ms');
      }
    };
    zoom = d3.zoom().on('zoom', zoomed);
    svg.call(zoom);
  }

  /**
   * Apply zoom behviour as a result of click event on one of the zoom buttons
   */
  applyZoomButtonsEvent(buttonValue: ('out' | 'in')) {
    let svgGraph = d3.select(this.svgElement.nativeElement);
    let graph = svgGraph.select('g');
    let graphZoomEvent;
    let initGraphZoom = () => {
      if (d3.event) {
        graph.attr('transform', d3.event.transform);
      }
    };
    if (buttonValue == 'in') {
      graphZoomEvent = d3.zoom().on('zoom', initGraphZoom).scaleBy(svgGraph.transition().duration(350), 1.2);
    }
    if (buttonValue == 'out') {
      graphZoomEvent = d3.zoom().on('zoom', initGraphZoom).scaleBy(svgGraph.transition().duration(350), 0.9);
    }
  }

  /**
   * Return the deepest depth level of the tree
   */
  findDeepestLevel(nodes: SingleD3Node<any>[]): number {
    let deepestLevel = 0;
    nodes.forEach(node => {
      if (node.depth > deepestLevel) {
        deepestLevel = node.depth;
      }
    });
    return deepestLevel;
  }

  /**
   * Return the view box property.
   * The viewport goal in determine the svg x,y, width, height
   * The method returns different calculation for devices,
   * because on single device screen the svg should focus each time on the selected device
   */
  calculateD3ViewBox(treeConfiguration: TreeConfiguration, treeObject: D3Tree, topologyConfiguration: TopologyConfiguration, isPropertiesOpen: boolean) {
    if (topologyConfiguration.isDevice) {
      /**
       * The WWW node
       */
      const rootNode = treeObject.nodesAfterD3.find(node => !node.parent);
      /**
       * The Y value for the view box. Calculated as follow:
       * subtract half of the topology container height from the wwwDevice.x position
       */
      let YViewBox = rootNode.x - treeConfiguration.height / 2;
      if (topologyConfiguration.selectedDevice) {
        const selectedX = topologyConfiguration.selectedDevice.x;
        if ((selectedX) > rootNode.x) {
          YViewBox = YViewBox + (selectedX - rootNode.x);
        }
        if (rootNode.x > selectedX) {
          YViewBox = YViewBox - (rootNode.x - selectedX);
        }
      }
      return `${topologyConfiguration.isDisconnectedDevices ? 50 : 50}, ${YViewBox}, ${treeConfiguration.width}, ${treeConfiguration.height}`;
    }
    return `${this.calculateXViewBox(treeObject)}, ${this.calculateYViewbox(treeObject.maxChildrenNumber, treeObject)}, ${treeConfiguration.width}, ${this.calculateHeight(treeConfiguration.height, isPropertiesOpen)}`;
  }

  /**
   * Return x value for topologies with fake root (move them to the left)
   * @param treeObject the D3Tree object, contain the sized of the svg
   * @private
   */
  private calculateXViewBox(treeObject: D3Tree) {
    return treeObject.treeData.data.type === "FakeRoot" ?
      50 : 50;
  }

  /**
   * Calculate the y value for the viewbox attribute
   * For large topologies return calculation as result of height and number of nodes that allows normal display
   * @param maxChildrenNumber Total max children number per level at the tree
   * @param treeObject the D3Tree object, contain the sized of the svg
   * @private
   */
  private calculateYViewbox(maxChildrenNumber: number, treeObject: D3Tree) {
    if (maxChildrenNumber > this.topologyBuilder.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY) {
      const height = treeObject.svgSizes.height;
      return height / this.topologyBuilder.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY * 2 - 150;
    }
    return -10;
  }

  /**
   * Add height when the properties pan is open.
   * Reason: Opening the pan changes the svg sizes.
   * When happened - Adding hardcoded 150 seems to solve the problem
   * In the future, it is probably best to find more dynamic calculation
   * @param height height of the svg container
   * @param isPropertiesOpen Current status of the properties pan
   * @private
   */
  private calculateHeight(height: number, isPropertiesOpen: boolean) {
    if (isPropertiesOpen) {
      return height + height * .265;
    }
    return height;
  }
}
