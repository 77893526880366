import { Component, OnInit } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-device-connection-failures',
  templateUrl: './top-device-connection-failures.component.html',
  styleUrls: ['./top-device-connection-failures.component.scss']
})
export class TopDeviceConnectionFailuresComponent implements OnInit {
  origin: TopTrafficOrigin = TopTrafficOrigin.DEVICE_BY_CONN_FAILURES;

  constructor() { }

  ngOnInit() {
  }

}
