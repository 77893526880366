<div class="row">
    <div class="col tplg-footer-left-container" [ngClass]="isWidget? 'footer-for-widget' : 'footer-regular'">
        <ng-content select="[leftFooter]"></ng-content>
    </div>
    <div class="col col-no-flex tplg-footer-right-container" [ngClass]="deviceZoomContainerClass">
        <div class="zoom-container">
            <div appZoomInButton class="tplg-zoom-in-container"
                [inlineSVG]="'/assets/media/netop/topology/zoom-in-button.svg'">
            </div>
            <div appZoomOutButton class="tplg-zoom-out-container"
                [inlineSVG]="'/assets/media/netop/topology/zoom-out-button.svg'">
            </div>
        </div>
    </div>
</div>