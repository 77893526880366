import {Component, OnInit} from '@angular/core';
import {IGetRowsParams} from "ag-grid-community";
import {TimePeriodOption, TimePeriodPayload, TimePeriods, TimeUnit} from "../../../shared/models/time.model";
import {DEFAULT_TIME_PERIOD_OPTIONS} from "../../../shared/components/time-range-picker/time-range-picker.model";
import {Observable} from "rxjs";
import {Page} from "../../../shared/models/page.model";
import {VenuesHealth} from "../../../shared/models/venues.model";
import {ActivatedRoute} from "@angular/router";
import {TenantsService} from "../../../shared/services/rest-services/tenants.service";
import {GridSortFilter} from "../../../shared/models/sort-filter/grid-sort-filter.model";
import {ComposableGridRow} from "../../../features/tables/core/composable-grid";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-tenant-venues',
  templateUrl: './tenant-venues.component.html',
  styleUrls: ['./tenant-venues.component.scss']
})
export class TenantVenuesComponent implements OnInit {
  total: number;
  params: IGetRowsParams;
  rowCount: number;
  searchText: string;
  timeBack: number = 4;
  timeUnit: TimeUnit = TimeUnit.HOURS;
  timePeriodOptions: TimePeriodOption[] = DEFAULT_TIME_PERIOD_OPTIONS;
  selectedTimePeriodIndex: number = null;
  refresh: boolean;

  fetchVenuesCb: (params: IGetRowsParams) => Observable<Page<ComposableGridRow<VenuesHealth>>> = params => {
    this.params = params;
    return this.fetchVenues().pipe(
      map(data => ({...data, data: data.data.map(venue => ({data: venue}))}))
    );
  }

  constructor(private activatedRoute: ActivatedRoute,
              private tenantsService: TenantsService) {
  }

  ngOnInit(): void {
    const queryParams = this.activatedRoute?.snapshot?.queryParams;
    if (queryParams && queryParams['timeBack'] && queryParams['timeUnit']) {
      this.timeBack = queryParams['timeBack'];
      this.timeUnit = queryParams['timeUnit'];
      this.selectedTimePeriodIndex = TimePeriods.getIndex(this.timePeriodOptions, this.timeBack, this.timeUnit);
    }
    else {
      this.selectedTimePeriodIndex = 0;
    }
  }

  onSearch(searchText: string) {
    this.searchText = searchText;
    this.refresh = true;
  }

  onTimePeriodSelected(payload: TimePeriodPayload) {
    if (payload?.option) {
      this.timeBack = payload.option.interval;
      this.timeUnit = payload.option.timeUnit;
      this.refresh = true;
    }
  }

  fetchVenues() {
    const params = new GridSortFilter(this.params, this.searchText, this.rowCount);
    return this.tenantsService.fetchCurrentVenuesHealth(params, this.timeBack, this.timeUnit);
  }
}
