import {ClientUserPreferencesData} from "../../models/client-storage.model";
import {Logger} from "loglevel";
import {Auth0Service} from "../auth0.service";
import {LoggerService} from "../logger.service";
import {FirebaseAuthService} from "./firebase-services/firebase-auth.service";
import {LoadClientStorageService} from "./load-client-storage.service";

export class BaseUserClientStorage {
  clientSelection: ClientUserPreferencesData;
  protected logger: Logger;

  constructor(
    protected loggerFactory: LoggerService,
    protected loadClientStorageService: LoadClientStorageService,
    serviceName: string) {
    this.logger = this.loggerFactory.getLogger(serviceName);
  }

}
