import {Inject, Injectable} from '@angular/core';
import {BaseUserClientStorage} from "../base-user-client-storage";
import {LoggerService} from "../../logger.service";
import {encodedEmailAddress} from "../../../operators/encoded-mail";
import {BROWSER_STORAGE} from "./base-local-storage-organization";
import jp from 'jsonpath';
import {
  ClientsPreferences,
  ClientStorageEntities,
  ClientUserPreferencesData
} from "../../../models/client-storage.model";
import {environment} from "../../../../../environments/environment";
import {LoadClientStorageService} from "../load-client-storage.service";
import {AuthStorageService} from "../../../../auth/auth-storage.service";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageUserPreferencesService extends BaseUserClientStorage {
  private readonly CURRENT_STORAGE_ENTITY: ClientStorageEntities = ClientStorageEntities.USER_PREFERENCES;
  localStoragePath: string;


  constructor(
    protected loggerFactory: LoggerService,
    protected loadClientStorageService: LoadClientStorageService,
    private authStorageService: AuthStorageService,
    @Inject(BROWSER_STORAGE) public storage: Storage,
  ) {
    super(loggerFactory, loadClientStorageService, 'LocalStorageUserPreferencesService')
  }

  loadUserDetails() {
    this.authStorageService.notifyUserProfileObservable$.subscribe(userDetails => {
      if (userDetails && userDetails.email) {
        this.clientSelection = {
          userEmail: encodedEmailAddress(userDetails.email),
          preferences: {}
        };
        this.localStoragePath = `['${this.CURRENT_STORAGE_ENTITY}']['${environment.environmentType}']['${encodedEmailAddress(userDetails.email)}']`;
        this.logger.debug("clientUserPreferencesData", this.clientSelection);
        this.isCurrentUserExist();
      }
    })

  }

  private isCurrentUserExist() {
    let preferencesObject = JSON.parse(this.storage.getItem('preferences'));
    //if preferencesObject are exist on local storage
    if (preferencesObject) {
      this.createOrUpdateCurrentEntityPreferences(preferencesObject);
    }
    //if preferencesObject are does exist on local storage
    else {
      this.createAndStorePreferencesObject(preferencesObject);
    }
  }

  private createOrUpdateCurrentEntityPreferences(preferencesObject: any) {
    const entityPreferences = jp.query(preferencesObject, `$..['${this.CURRENT_STORAGE_ENTITY}']`);
    //if current storage entity exist
    if (entityPreferences && entityPreferences.length > 0) {
      const entityEnvironmentPreferences = jp.query(preferencesObject, `$..${this.localStoragePath}`)[0];
      if (entityEnvironmentPreferences) {
        this.clientSelection = entityEnvironmentPreferences;
        this.applyDefaultAndSelectedPreferences(this.clientSelection ? this.clientSelection : false);
        return;
      }
    }
    //if current storage entity does not exist
    const newEntityPreferencesObject = {
      [environment["environmentType"]]: {
        [`${this.clientSelection.userEmail}`]: null
      }
    }
    preferencesObject.preferences[this.CURRENT_STORAGE_ENTITY] = newEntityPreferencesObject;
    this.storage.setItem('preferences', JSON.stringify(preferencesObject));
    this.applyDefaultAndSelectedPreferences(this.clientSelection);
    return;
  }

  private createAndStorePreferencesObject(preferencesObject: any) {
    if (!preferencesObject) {
      const newPreferencesObject = {
        [this.CURRENT_STORAGE_ENTITY]: {
          [environment["environmentType"]]: {
            [`${this.clientSelection.userEmail}`]: this.clientSelection
          }
        }
      };
      preferencesObject = {
        preferences: newPreferencesObject
      };
    }
    this.storage.setItem('preferences', JSON.stringify(preferencesObject));
    this.applyDefaultAndSelectedPreferences(this.clientSelection);
  }

  updateUserPreferencesSelection() {
    this.savePreferencesInLocalStorage();
  }

  private savePreferencesInLocalStorage() {
    const preferences = JSON.parse(this.storage.getItem('preferences'));
    jp.apply(preferences, `$..${this.localStoragePath}`, (value) => this.clientSelection);
    this.storage.setItem('preferences', JSON.stringify(preferences));
  }

  /**
   * Apply and store current storage preferences on loadClientStorageService
   * @param clientStorage
   */
  applyDefaultAndSelectedPreferences(clientStorage: ClientUserPreferencesData | ClientsPreferences<any> | boolean) {
    this.loadClientStorageService.storeUserPreferencesLoaded(clientStorage as ClientUserPreferencesData | boolean);
  }
}
