import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {INetopMatTreeModel} from "./INetop-mat-tree.model";
import {SelectionModel} from "@angular/cdk/collections";

export abstract class MatTreeFlatNode {
  expandable: boolean;
  numChildren?: number;
  name: string;
  level: number;
}

export class TreeNodeModel<T, S, D = any, N extends TreeNodeModel<T, S, D> = any> {
  value: T;
  type: S;
  label?: string;
  lazyLoading?: boolean;
  hasLazyLoaded?: boolean;
  nodes: Array<N>;
  additionalData?: D;
}

/** Flat node with expandable and level information */
export class FlatNodeWithIcon<T = any> extends MatTreeFlatNode {
  iconName: string;
  originalNode: T;
}

export class MatTreeNodeModelWithIcon<T, S, D = any> extends TreeNodeModel<T, S, D> {
  iconName?: string;
}

export class TreeDisplay<T, S, D = any, N = any> extends MatTreeNodeModelWithIcon<T, S, D> implements INetopMatTreeModel<T> {
  protected _transformer = (node: MatTreeNodeModelWithIcon<T, S, D>, level: number) => {
    return {
      expandable: !!node.nodes || node.lazyLoading,
      numChildren: node?.nodes?.length,
      name: node.label || this.getName(node.value),
      level: level,
      iconName: node.iconName,
      originalNode: node
    } as FlatNodeWithIcon;
  }
  processTree: any;
  treeControl = this.createTreeControl();
  treeFlattener = this.createTreeFlattener();
  dataSource = this.createDatasource();

  checklistSelection = new SelectionModel<FlatNodeWithIcon>(true /* multiple */);

  limitSelection: number = 0;

  constructor() {
    super();
  }

  reset() {
    this.treeControl = this.createTreeControl();
    this.treeFlattener = this.createTreeFlattener();
    this.dataSource = this.createDatasource();
  }

  createTreeControl() {
    return new FlatTreeControl<FlatNodeWithIcon<N>>(node => node.level, node => node.expandable);
  }

  createTreeFlattener() {
    return new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.nodes);
  }

  createDatasource() {
    return new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  hasChild = (_: number, node: FlatNodeWithIcon<N>) => node.expandable;

  getLevel = (node: FlatNodeWithIcon<N>) => node.level;

  isExpandable = (node: FlatNodeWithIcon<N>) => node.expandable;


  getName(value: T): string {
    return value?.toString() || '';
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  itemLeafItemSelectionToggle(node: FlatNodeWithIcon<N>): void {
    if (!node.expandable) {
      this.checklistSelection.toggle(node);
      this.checkAllParentsSelection(node);
    }
  }

  preDefinedBranchesNames: Array<string> = [];
  preDefinedLeavesNames: Array<string> = [];

  /**
   * Mark all pre-defined nodes as selected
   * Expand if need be
   * SOURCE: https://stackblitz.com/edit/angular-j2nf2r?embed=1&file=app/tree-checklist-example.html
   */
  markAndExpandNodesAsSelected(preDefinedLeavesNames, preDefinedBranchesNames) {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (preDefinedLeavesNames.map(leave => leave.split('|')[0]).includes(this.treeControl.dataNodes[i].name.split('|')[0])) {
        this.markNodeAsSelected(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
      if (preDefinedBranchesNames.includes((this.treeControl.dataNodes[i].name))) {
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }
  }

  deselectAllNodes() {
    if (this.treeControl && this.treeControl.dataNodes) {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        const node = this.treeControl.dataNodes[i];
        this.checklistSelection.deselect(node);
      }
    }
  }

  markNodeAsSelected(node: FlatNodeWithIcon<N>) {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  deselectNode(nodesName: string[]) {
    this.checklistSelection.selected.forEach(selectedNode => {
      if (nodesName.includes(selectedNode.name)) {
        this.checklistSelection.deselect(selectedNode);
      }
    })
  }

  get isAboveSelectionLimit() {
    return this.checklistSelection.selected.filter(node => !node.expandable).length >= this.limitSelection;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNodeWithIcon<N>): void {
    let parent: FlatNodeWithIcon | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNodeWithIcon<N>): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNodeWithIcon<N>): FlatNodeWithIcon<N> | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNodeWithIcon<N>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  descendantsPartiallySelected(node: FlatNodeWithIcon<N>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: FlatNodeWithIcon<N>): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => {
      if (!this.isAboveSelectionLimit) {
        this.checklistSelection.isSelected(child)
      }
    });
    this.checkAllParentsSelection(node);
  }

  hasChildren(node: FlatNodeWithIcon): boolean {
    return node.numChildren > 0;
  }
}
