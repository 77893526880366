<app-netop-dialog>

    <app-netop-dialog-title (close)="closeDialog()">
        {{dataType}} {{ entityTitle }} {{titleAddition}}
    </app-netop-dialog-title>

    <app-netop-dialog-sub-title>
        <app-icon-with-ellipse [iconPath]="headerData?.iconPath"></app-icon-with-ellipse>
        <app-sub-header-border></app-sub-header-border>
        <ng-container *ngIf="headerData?.health !== -1">
            <div class="col sub-header-text-pad issues-health-percentage" [ngStyle]="{color: percentageColor}">
                {{ headerData?.health.toFixed(2)  }}%
            </div>
            <div class="col sub-header-text-pad issues-health-text-health">
                {{'data.COMMON.HEALTH' | translate}}</div>
        </ng-container>
        <!-- Health Not calculated messge-->
        <span *ngIf="headerData?.health === -1"
            class="issues-health-text-not-calculated-error sub-header-text-pad">{{'data.COMMON.HEALTH_NOT_CALCULATED' | translate}}</span>

    </app-netop-dialog-sub-title>

    <app-netop-dialog-content>

        <div class="issues-health-content-container">
            <ng-container *ngIf="isDialogHealth" [ngTemplateOutlet]="healthContent"></ng-container>
            <ng-container *ngIf="!isDialogHealth" [ngTemplateOutlet]="actionContent"></ng-container>
        </div>

        <ng-template #healthContent>
            <div class="row issues-health-content" *ngFor="let item of dialogData">
                <ng-container *ngIf="item?.percentage !== 100">
                    <mat-icon class="col issues-health-icon" mat-list-icon svgIcon="health-issues-icon"></mat-icon>
                    <span *ngIf="item.percentage" class="col issues-health-text-percentage"
                        [ngStyle]="{color: percentageColor}">{{item.percentage.toFixed(2)}}%</span>
                    <span class="col issues-health-text-reason">{{item.reason}}</span>
                </ng-container>
                <ng-container *ngIf="item?.percentage == 100">
                    <span class="col issues-health-text-reason-100">{{item.reason}}</span>
                </ng-container>
            </div>
        </ng-template>

        <ng-template #actionContent>
            <div class="issues-health-text-reason issues-health-action" *ngFor="let action of entityActionsList">
                <div class="row issues-health-row-content-container">
                    <span class="col action-icon">
                        <mat-icon mat-list-icon svgIcon="actions-description">actions-description</mat-icon>
                    </span>
                    <span class="col align-center-column">{{action}}</span>
                </div>
            </div>
            <div class="issues-health-text issues-health-action" *ngIf="entityActionsList?.length == 0">
                {{'data.ACTIONS.NO_OPEN_ACTIONS' | translate}}
            </div>
        </ng-template>

    </app-netop-dialog-content>

    <app-netop-dialog-actions>
        <app-buttons (click)="closeDialog()" class="issues-health-button" [btnStyle]="'primary'"
            [btnText]="'data.COMMON.OK'">
        </app-buttons>
    </app-netop-dialog-actions>
</app-netop-dialog>
