import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BaseHealthGrid} from "../../../entity-dashboard/health-grid/base-health-grid";
import {GridTypes} from "../../../../models/client-storage.model";

@Component({
  selector: 'app-devices-health-grid',
  templateUrl: './devices-health-grid.component.html',
  styleUrls: ['./devices-health-grid.component.scss']
})
export class DevicesHealthGridComponent extends BaseHealthGrid {
  constructor(protected translate: TranslateService, protected cdr: ChangeDetectorRef) {
    super(translate, GridTypes.DEVICES_HEALTH, translate.instant('data.DASHBOARD.DEVICES_HEALTH'), translate.instant('data.DASHBOARD.TOTAL_DEVICES'), cdr);
  }
}

