<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog()" [cssClass]="'single-entity-action-dialog-title-text'">
        <span class="action-conf-dialog-pl-20">{{title}} </span>
        <span class="action-conf-dialog-text-explain">{{titleExplain}}</span>
    </app-netop-dialog-title>

    <app-netop-dialog-sub-title>
        <div class="row action-conf-dialog-sub-title">
            <div class="col action-conf-dialog-text-sub col-flex-3">{{subTitle.key}}</div>
            <div class="col action-conf-dialog-text">{{subTitle.value}}</div>
        </div>
    </app-netop-dialog-sub-title>

    <app-netop-dialog-content [cssClass]="'no-overflow-x'">
        <app-action-configuration-data [configurationData]="configurationData" [kpiChanges]="kpiChanges">
        </app-action-configuration-data>
        <app-user-selection-action-configurationa (userSelectedEvent)="setUserSelection($event)">
        </app-user-selection-action-configurationa>
    </app-netop-dialog-content>

    <app-netop-dialog-actions [cssClass]="'padding-buttons'">
        <span *ngIf="isUserSelect"
            class="action-conf-dialog-text">{{'data.ACTIONS.SAVE_DECISION_BUTTON' | translate}}</span>
        <app-buttons (click)="closeDialog()" class="alerts-button" [btnStyle]="'primary'" [btnVariant]="'cancel'"
            [btnText]="'data.COMMON.NO'" [btnDisabled]="!isUserSelect">
        </app-buttons>
        <app-buttons (click)="saveSelection()" class="alerts-button" [btnStyle]="'primary'"
            [btnText]="'data.COMMON.YES'" [btnDisabled]="!isUserSelect">
        </app-buttons>
    </app-netop-dialog-actions>
</app-netop-dialog>