import { Component, OnInit, Input } from '@angular/core';
import { KpiChanges } from '../action-configuration-dialog.component';
import { KpiType } from 'src/app/shared/models/kpi.model';
import { KpiDataService } from 'src/app/shared/services/strategies/kpi-data.service';
import { getArrowByValue } from 'src/app/shared/operators/encoded-arrow';
import { HealthColorsService } from 'src/app/shared/services/strategies/health-colors.service';

@Component({
  selector: 'app-action-configuration-data',
  templateUrl: './action-configuration-data.component.html',
  styleUrls: ['./action-configuration-data.component.scss']
})
export class ActionConfigurationDataComponent implements OnInit {
  @Input() configurationData: { key: string, value: string }[] = [];
  @Input() kpiChanges: KpiChanges[];
  constructor(private kpiDataService: KpiDataService,
    private healthColorsService: HealthColorsService) { }

  ngOnInit() {
  }

  getKpiUnit(kpi: KpiChanges) {
    if (kpi.key !== KpiType.Traffic)
      return this.kpiDataService.getKpiUnit(kpi.key);
    return this.kpiDataService.getBytesSizeAfterCalc(kpi.value).size + "/day";
  }
  getKpiOperator(kpi: KpiChanges) {
    if ((kpi.key == KpiType.Loss || kpi.key == KpiType.Latency) && kpi.change > 0)
      return "+";
  }
  getChangeColor(kpi: KpiChanges) {
    return this.healthColorsService.getKpiChangeColor(kpi);
  }
  getKpiChangeArrow(kpi: KpiChanges) {
    if (kpi.key == KpiType.Loss || kpi.key == KpiType.Latency)
      return getArrowByValue(kpi.change);
  }
  getArrowColor(kpi: KpiChanges) {
    if (kpi.change > 0)
      return '#F7685B';
    if (kpi.change < 0)
      return '#2ED47A';
  }
  getKpiValue(kpi: KpiChanges) {
    if (kpi.key == KpiType.Traffic)
      return this.kpiDataService.calculateTrafficValue(kpi.value, 0).finalValue.toFixed(2);
    return kpi.value;
  }

}
