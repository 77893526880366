import { ForceDirectedGraphLink } from 'src/app/shared/components/force-directed-graph/models/force-directed-graph-link';
import { VPNConnectionStatus, DisplayVenueVPN } from 'src/app/shared/models/vpn.model';
import { drawArrow } from 'src/app/shared/operators/encoded-arrow';
import { KpiDataService } from 'src/app/shared/services/strategies/kpi-data.service';

export class VPNForceLink extends ForceDirectedGraphLink<DisplayVenueVPN> {
    constructor(source: number, target: number, id: number, data: DisplayVenueVPN, private kpiDataService: KpiDataService) {
        super(source, target, id, data)
    }
    get strokeColor(): string {
        return this.data.status == VPNConnectionStatus.REACHABLE ?
            '#7dd090' : '#f7685b';
    }

    get firstText(): string {
        if (this.data.traffic) {
            let sentTraffic = this.kpiDataService.getBytesSizeAfterCalc(this.data.traffic.firstSiteTraffic.sent);
            if (sentTraffic.value > 0) {
                return `${sentTraffic.value.toFixed(2)}  
            ${sentTraffic.size} 
            ${drawArrow("right")}`
            };
        }
    }
    get secondText(): string {
        if (this.data.traffic) {
            let recvTraffic = this.kpiDataService.getBytesSizeAfterCalc(this.data.traffic.firstSiteTraffic.recv);
            if (recvTraffic.value > 0) {
                return `${drawArrow("left")}  
            ${recvTraffic.value.toFixed(2)}
            ${recvTraffic.size}`
            };
        }
    }
}
