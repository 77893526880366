export class ForceDirectedGraphLink<T>{
    source: any;
    target: any;
    id: number;
    data: T;
    isSelectedElement: boolean = false;


    constructor(source, target, id, data: T) {
        this.source = source;
        this.target = target;
        this.data = data;
        this.id = id;
    }

    get strokeColor(): string {
        return '';
    }

    get firstText(): string {
        return null;
    }
    get secondText(): string {
        return null;
    }

    get storkeWidth(): number {
        return this.isSelectedElement ?
            4 : 2;
    }

    get path() {
        if (this.target && this.source) {
            return `M ${this.source.x} ${this.source.y} L ${this.target.x} ${this.target.y}`
        }
    }

    get textPosition() {
        if (this.target && this.source) {
            let x;
            let y;
            let [biggerX, lowerX] = this.source.x > this.target.x ? ["source", "target"] : ["target", "source"];
            x = this[biggerX].x - (this[biggerX].x - this[lowerX].x) / 2;
            let [biggerY, lowerY] = this.source.y > this.target.y ? ["source", "target"] : ["target", "source"];
            y = this[biggerY].y - (this[biggerY].y - this[lowerY].y) / 2;
            return { x: x, y: y };
        }
    }
    get textRotation() {
        let cordinates = this.textPosition;
        let rotate: string;
        if (this.target && this.source) {
            // let translate: string;
            this.target.x < this.source.x ?
                rotate = `rotate(180 ${cordinates.x} ${cordinates.y})` :
                rotate = `rotate(0)`;
            // ((link.source.x - link.target.x) > (link.source.y - link.target.y)) ?
            //   translate = 'translate(0, 20)' :
            //   translate = 'translate(0, -20)'

            return `${rotate}`;
        }
    }
}