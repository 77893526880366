import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueAsStrings } from './models/pipes-model';
import { stringToCamelcase } from '../operators/string-opertators';

@Pipe({
  name: 'removeSimiliarKey'
})
export class RemoveSimiliarKeyPipe implements PipeTransform {

  transform(key: any, list: KeyValueAsStrings[], index): any {
    if (list &&
      list.length > 0 && list[index - 1] &&
      stringToCamelcase(list[index - 1].key.split('.')[0]).trim() == key.split('.')[0].trim()) {
      return key.split('.')[1];
    }
    return key;
  }

}
