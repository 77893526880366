import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'pressed-button',
  templateUrl: './pressed-button.component.html',
  styleUrls: ['./pressed-button.component.scss']
})
export class PressedButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() isPressed: boolean = false;
  @Output() buttonSelectedChanged: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onButtonClick(event: MatButtonToggleChange) {
    this.isPressed = !this.isPressed;
    this.buttonSelectedChanged.emit(this.isPressed);
  }

}
