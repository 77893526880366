import {ChartTooltipExtensions} from './chart-tooltip-extensions';
import {ChartJsTooltip} from './chartjs-tooltip.model';

export class DonutTooltip extends ChartTooltipExtensions {
  /**
   * The donut object. will be generated at the end of the new chart object creation at graph-donut
   */
  donut: any;

  constructor(minWidth: number = 250) {
    super();
    this.minWidth = minWidth;
  }

  /**
   * Create custom Donut tooltip
   * The method received the donut tooltip object and then initiate the creating of tooltip element and settins its position
   */
  customDonutTooltips = (context) => {
    const {chart, tooltip} = context;
    this.donut = chart;
    if (this.donut) {
      let tooltipEl = this.setCustomTooltipStyling(tooltip, this.donut, this.minWidth);
      this.setDonutCustomTooltipPosition(tooltipEl, tooltip, this.donut);
    }
  };

  /**
   * Set the donut custom tooltip position
   */
  private setDonutCustomTooltipPosition(tooltipEl: HTMLElement, tooltip: ChartJsTooltip, tooltipThis: any) {
    if (tooltipEl) {
      this.tooltipActualDirection = "left";
      let xPosition: number = tooltip.caretX + tooltip.caretSize + tooltip.caretPadding;
      let canvasRect: DOMRect = tooltipThis.canvas.getBoundingClientRect();
      if (canvasRect.x > tooltip.width && (canvasRect.x - tooltip.width) < 250) {
        xPosition += 150 - (canvasRect.x - tooltip.width);
      }
      tooltipEl.style.left = xPosition + 'px';
      tooltipEl.style.top = tooltip.y + tooltip.height / 2 + 'px';
      tooltipEl.classList.add(this.tooltipElArrowClass + "-" + this.tooltipActualDirection);
    }
  }
}
