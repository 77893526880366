<app-portlet>
    <app-portlet-header [title]="propertiesTitle" class="portlet-header" [hideDefaultTools]="true">
        <ng-container portletTools *ngIf="lastKpiUpdate">
            <div>
                <span> <i class="device-single-prop-warning"
                        [inlineSVG]="'assets/media/icons/svg/Code/Warning-2.svg'"></i></span>
                <span class="device-single-prop-warning-text"> {{lastKpiUpdate}}
                </span>
            </div>
        </ng-container>
    </app-portlet-header>
    <app-portlet-body class="portlet-body device-portlet-body-container">
        <app-legacy-properties *ngIf="propData" [title]="propertiesTitle" [isDevice]="true" [propertiesData]="propData">
        </app-legacy-properties>
    </app-portlet-body>
</app-portlet>
