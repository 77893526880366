import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-portlet-sidebar',
  templateUrl: './portlet-sidebar.component.html',
  styleUrls: ['./portlet-sidebar.component.scss']
})
export class PortletSidebarComponent implements OnInit {
  @HostBinding('class') classList = '';
  @Input() class: string;

  constructor() { }

  ngOnInit() {
    if (this.class) {
      this.classList += ' ' + this.class;
    }
  }

}
