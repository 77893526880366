import {Subscription} from 'rxjs';
import {TopologyTooltipsTypes} from '../topology-tooltips-types.enum';
import {Router, ActivatedRoute} from '@angular/router';
import {ClientDevice, RssiLabels} from 'src/app/shared/models/clients.model';
import {Directive, Input, SimpleChanges} from "@angular/core";
import {GenericDevice, NodeEntities, NodeEventData} from "../../../../models/topology";
import {IBaseTopologyTooltip} from "./IBaseTopologyTooltip";
import {SingleDevice} from "../../../../models/single-device.model";
import {CloudNode} from "../../../../models/cloud-network.model";

@Directive()
export class BaseTopologyToolTip implements IBaseTopologyTooltip{
  @Input() tplgRect: DOMRect;
  /**
   * @param nodeEvent Contain the event data from the svg.
   * If node was hovered, this object will receive the node data and the event data
   */
  @Input() nodeEvent: NodeEventData<GenericDevice<NodeEntities>>;
  /**
   * @param displayNodeEvent The node that will be displayed on the the tooltip
   * Will get null only if the user is not hovering over the tooltip and the node
   */
  node: NodeEventData<GenericDevice<NodeEntities>>;
  protected title: string;
  protected isHoverTooltipElement: boolean = false;
  protected subsc: Subscription[] = [];
  protected tooltipType: TopologyTooltipsTypes;
  protected tooltipContent: ClientTooltipContent[] = [];

  constructor(tooltipType: TopologyTooltipsTypes,
              protected router: Router,
              protected activatedRoute: ActivatedRoute) {
    this.tooltipType = tooltipType;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initiateNodeEventData();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.nodeEvent && !changes.nodeEvent.firstChange)
      this.initiateNodeEventData();
  }
  initiateNodeEventData() {}

  isHoverTooltip(isHover: boolean) {
    this.isHoverTooltipElement = isHover;
    if (!this.isHoverTooltipElement && !this.nodeEvent)
      this.node = null;
  }

  get animationTrigger(): ('show' | 'hide') {
    return this.node ? 'show' : 'hide';
  }
  /**
   * @method goToDevice Navigate to the selected device
   * @param deviceId The selected device id that was selected by clicking the tooltip title
   */
  goToDevice(device: NodeEventData<GenericDevice<SingleDevice>>) {
    this.router.navigate(['/device', device.d.data.id], { relativeTo: this.activatedRoute }).catch();
  }

}

export class ClientTooltipContent {
  key: string;
  value: string | number | boolean;
  color?: RssiLabels;
}
