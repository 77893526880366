import {combineLatest, Observable} from "rxjs";
import {debounceTime, map, shareReplay} from "rxjs/operators";
import {deepEqual} from "../operators/object-operators/object-comparison";

/**
 * Return boolean Observable for Object comparison
 * SOURCE: https://netbasal.com/detect-unsaved-changes-in-angular-forms-75fd8f5f1fa6
 * @param source
 */
export function dirtyCheck<U>(source: Observable<U>) {
  return function <T>(valueChanges: Observable<T>): Observable<boolean> {
    const isDirty$ = combineLatest([
      source,
      valueChanges],
    ).pipe(
      debounceTime(300),
      map(([a, b]) => deepEqual(a, b) === false),
      shareReplay({bufferSize: 1, refCount: true}),
    );

    return isDirty$;
  };
}
