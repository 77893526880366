import {Component, OnInit} from '@angular/core';
import {BaseSingleDeviceDashboard} from '../../../../../devices/components/devices-single-components/device-health-dashboard/base-single-device-dashboard';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../../../services/logger.service';
import {Topology, GenericDevice, SingleD3Node} from '../../../../models/topology';
import {SingleDevice} from '../../../../models/single-device.model';
import {Subscription} from 'rxjs';
import {DevicesService} from '../../../../services/rest-services/devices.service';
import {SingleSizeCalculationService} from '../../../../../venues/services/single-size-calculation.service';
import {isDevice, isSameDevice, isDeviceCloud} from '../../../network-topology/operators/topology-operators';
import {Router, ActivatedRoute} from '@angular/router';
import {D3TreeEventsService} from '../../../network-topology/services/d3-tree-events.service';
import {BaseSingleEntityService} from '../../../../services/base-single-entity.service';
import {SingleLink} from "../../../../models/single-link.model";

@Component({
  selector: 'app-device-single-topology',
  templateUrl: './legacy-device-single-topology.component.html',
  styleUrls: ['./legacy-device-single-topology.component.scss']
})
export class LegacyDeviceSingleTopologyComponent extends BaseSingleDeviceDashboard implements OnInit {
  topologyTitle: string;
  venueTopology: Topology<SingleDevice, SingleLink>;
  deviceTopologySubsrc: Subscription;
  tplgWidth: number;
  tplgHeight: number;

  constructor(
    protected translate: TranslateService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected devicesService: DevicesService,
    protected loggerFactory: LoggerService,
    private singleSizeCalculationService: SingleSizeCalculationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private d3TreeEventsService: D3TreeEventsService,
  ) {
    super(translate, loggerFactory, devicesService, baseSingleEntityService);
  }

  ngOnInit() {
    this.topologyTitle = this.translate.instant('data.COMMON.DEVICE') + ' ' + this.translate.instant('data.DEVICE.NEIGHBORS');
    this.calculateDeviceTopologySize();
    this.subscribeToDeviceChanges();
    this.subscribeToD3TreeClickEvents();
  }

  subscribeToDeviceChanges() {
    const deviceSubsc = this.baseSingleEntityService.notifyEntityDataAsObservable$.subscribe(device => {
      if (device) {
        this.deviceData = device as SingleDevice;
        this.initTopology();
      }
    });
    this.subscription.push(deviceSubsc);
  }


  /**
   * @method subscribeToNodeClickedEvents Responisble to the re-navigate to the single device screen, when device is selected.
   * by subscribing to the clicked element observable it tell whether to display the current selected device data,
   * or the entire venue data (in case that no device is chosen)
   */
  subscribeToD3TreeClickEvents() {
    const clickedNodeSubsc = this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(clickedNode => {
      if (clickedNode && isDevice(clickedNode) && !isSameDevice(this.deviceData, clickedNode as SingleD3Node<GenericDevice<any>>) &&
        !isDeviceCloud((clickedNode as SingleD3Node<GenericDevice<any>>).data.type)) {
        // Empty device data for the next flow:
        // When navigate between two devices via click on topology in this device single screen -
        // the binding for deviceData param, send the previous and current device data id.
        // The topology only takes the previous and not the current.
        // If emptied, because of the ngIf, the topology will only get the current device data id
        this.deviceData = null;
        this.router.navigate(['/device', (clickedNode as SingleD3Node<GenericDevice<any>>).data.id], {relativeTo: this.activatedRoute}).catch();
      }
    });
    this.subscription.push(clickedNodeSubsc);
  }

  /**
   * @method initTopology Receive the graph data from ,f,chthe snapshot data property
   */
  initTopology() {
    !this.deviceTopologySubsrc ? this.subscribeToNewTopology() : this.initiateExistTopolgy();
  }

  /**
   * If the current topology subscription does not exist -
   * The method subscribe to it, and initiate new topology
   */
  private subscribeToNewTopology() {
    this.deviceTopologySubsrc = this.devicesService.getDeviceVenueTopology().subscribe(topology => {
      if (topology) {
        this.venueTopology = topology;
      }
    });
  }

  /**
   * @method calculateTopologySize
   */
  private calculateDeviceTopologySize() {
    let topologySize: { width: number, height: number };
    topologySize = this.singleSizeCalculationService.calculateDeviceTopologySize();
    this.tplgWidth = topologySize.width;
    this.tplgHeight = topologySize.height;
  }

  /**
   * If the current topology subscription exist, the topology is re-created as an new object.
   * Before that, that nodes gets value that will match the link start/end id that point to the cloud node.
   * The reason for it is that after the topology created, the links are initilize with new start/end id for
   * the cloud node.
   * 18.5.20: Check - why the nodes stays the same and the link changes
   */
  private initiateExistTopolgy() {
    if (this.venueTopology) {
      this.venueTopology.nodes.forEach(node => node.device.id == 0 ? node.device.id = '666' : node.device.id = node.device.id);
      this.venueTopology = {...this.venueTopology};
    } else {
      this.subscribeToNewTopology();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}
