<div class="date-time-picker">
  <div class="date-picker-col">
    <app-date-picker [date]="date" (dateChange)="onDateSelected($event)">
    </app-date-picker>
  </div>
  <div class="time-picker-col">
    <app-time-picker [hour]="date?.getHours()" [minute]="date?.getMinutes()" (timeSelected)="onTimeSelected($event)">
    </app-time-picker>
  </div>
</div>
