import { Injectable } from '@angular/core';
import { AnomalyCategory, TrendAnomaly } from 'src/app/shared/models/anomalies.model';
import { calculateDaysNumberBetweenTwoDates, calculateTotalHoursBetweenTwoDates } from 'src/app/shared/operators/time-operator';
import { TimeSeries } from '../../../../../chartjs-components/models/sparkline-config';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import {IBaseAnomalyGrid} from "../models/IBaseAnomalyGrid";

@Injectable({
  providedIn: 'root'
})
export class AnomalyTrendService implements IBaseAnomalyGrid {

  constructor(private dateConversion: TimeManagerService) {
  }

  /**
 * Return string with the data of the time span for all LossLatenctAnomaly (in days)
 */
  getLastDateAsString(lossLatenctAnomaly: TrendAnomaly[], category: AnomalyCategory): string {
    if (lossLatenctAnomaly &&
      lossLatenctAnomaly.length > 0 &&
      lossLatenctAnomaly.filter(aonmaly => aonmaly.timeSeries.length > 0).length > 0) {
      let formattedCategory = this.getTitleCategory(category);
      let earliestDate: Date = this.findEarliestDate(lossLatenctAnomaly[0].timeSeries);
      lossLatenctAnomaly.forEach(aonmaly => {
        if (this.findEarliestDate(aonmaly.timeSeries) < earliestDate)
          earliestDate = this.findEarliestDate(aonmaly.timeSeries);
      });
      if (calculateDaysNumberBetweenTwoDates(earliestDate, new Date()) < 1) {
        return `${formattedCategory} for last ${calculateTotalHoursBetweenTwoDates(earliestDate, new Date()).toFixed(0)} hours`;
      }
      return `${formattedCategory} for last ${calculateDaysNumberBetweenTwoDates(earliestDate, new Date()).toFixed(0)} days`;
    }
    return "Time";
  }
  getTitleCategory(category: AnomalyCategory) {
    switch (category) {
      case AnomalyCategory.Latency:
        return "Latency";
      case AnomalyCategory.PacketLoss:
        return "Loss";
      case AnomalyCategory.Traffic:
        return "Traffic";
      case AnomalyCategory.VpnTraffic:
        return "VPN Traffic";
      default:
        break;
    }
  }
  /**
   * Find the eraliest date for each status history
   */
  findEarliestDate(timeSeries: TimeSeries[]) {
    if (timeSeries.length > 0) {
      let earliestDate: Date = this.dateConversion.convertUnixToDateObject(timeSeries[0].date);
      timeSeries.forEach(point => {
        if (this.dateConversion.convertUnixToDateObject(point.date) < earliestDate)
          earliestDate = this.dateConversion.convertUnixToDateObject(point.date);
      });
      return earliestDate;
    }
    return new Date();
  }
}
