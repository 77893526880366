import { createReducer, on, Action } from '@ngrx/store';
import { Entity } from 'src/app/shared/models/entity.model';
import * as CurrentEntityActions from '../actions/entities.action-types';

export const currentEntityFeatureKey = 'current-entity';

export interface State {
    /**
     * Current entity value, the one we navigated to.
     * On navigation start
     * [1] nextEntity is set with id and type only using event payload.
     * [2] prevEntity is set to what the currentEntity was.
     * [3] currentEntity is not changed.
     * On navigation end (currentEntityNavigated)
     * [1] nextEntity is set to null.
     * [2] prevEntity is set to null.
     * [3] currentEntity is set using event payload.
     * On currentEntityLoaded
     * [1] nextEntity is leaved as null.
     * [2] prevEntity is leaved as null.
     * [3] currentEntity is changed to have the data (name) loaded from BE.
     * On currentEntityNavigationError.
     * [1] nextEntity is leaved as null.
     * [2] prevEntity is leaved as null.
     * [3] currentEntity is set to what the prevEntity was.
     */
    currentEntity: Entity,
    /**
     * Previouse entity value, set when navigation starts and unset when it finishes
     * In case of an error aat navigation we reset the current entity value using this one.
     */
    prevEntity: Entity,
    /**
     * The next entity value if the navigation will succeed.
     */
    nextEntity: Entity
}

export const initialState: State = {
    currentEntity: null,
    prevEntity: null,
    nextEntity: null
};

const currentEntityReducer = createReducer(
    initialState,
    /**
     * Once the hierarchy loaded set the current entity properties,
     * i.e. the entity id & type should be same as already set by currentEntityNavigated
     * except for the entity name.
     */
    on(CurrentEntityActions.currentEntityLoaded, (state, { entity }) => {
        return {
            ...state,
            currentEntity: {
                ...entity,
                type: entity.type//.toLowerCase() as EntityType
            },
        }
    }),
    /**
     * Once the navigation was finished set the current entity as it was set by ngrx router merge
     * i.e. set the current entity id & type (name will be loaded using hierarchy service)
     */
    on(CurrentEntityActions.currentEntityNavigated, (state, { entityId, entityType }) => ({
        currentEntity: {
            ...state.currentEntity,
            name: "",
            id: entityId,
            type: entityType
        },
        prevEntity: null,
        nextEntity: null
    })),
    /**
     * Once the navoigation is started set the nextEntity value.
     */
    on(CurrentEntityActions.currentEntityNavigationStarted, (state, { entityId, entityType }) => ({
        ...state,
        prevEntity: Object.assign({}, state.currentEntity),
        nextEntity: {
            name: "",
            id: entityId,
            type: entityType
        }
    })),
    on(CurrentEntityActions.currentEntityNavigationError,
        CurrentEntityActions.currentEntityNavigationCancel,
        (state) => ({
            prevEntity: null,
            currentEntity: Object.assign({}, state.prevEntity),
            nextEntity: null
        }))
);

export function reducer(state: State | undefined, action: Action) {
    return currentEntityReducer(state, action);
}
