import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortletHeaderComponent } from './portlet-header/portlet-header.component';
import { PortletComponent } from './portlet/portlet.component';
import { DashboardPortalComponent } from './dashboard-portal.component';
import { PortletFooterComponent } from './portlet-footer/portlet-footer.component';
import { PortletBodyComponent } from './portlet-body/portlet-body.component';
import { PortletSidebarComponent } from './portlet-sidebar/portlet-sidebar.component';
import { PortletMainContentComponent } from './portlet-main-content/portlet-main-content.component';
import { InlineSVGModule } from 'ng-inline-svg';


@NgModule({
  declarations: [
    PortletHeaderComponent,
    PortletComponent,
    DashboardPortalComponent,
    PortletFooterComponent,
    PortletBodyComponent,
    PortletSidebarComponent,
    PortletMainContentComponent,
  ],
  imports: [
    CommonModule,
    InlineSVGModule
  ],
  exports: [
    PortletHeaderComponent,
    PortletComponent,
    DashboardPortalComponent,
    PortletFooterComponent,
    PortletBodyComponent,
    PortletSidebarComponent,
    PortletMainContentComponent,
  ]
})
export class DashboardPortalModule { }
