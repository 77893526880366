import { Injectable } from '@angular/core';
import { GraphTrendConfig } from 'src/app/shared/components/chartjs-components/models/graph-trend-config';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { DashboardService } from 'src/app/shared/services/rest-services/dashboard.service';
import { GraphTrendConfigService } from 'src/app/shared/components/chartjs-components/services/graph-trend-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KpiService } from 'src/app/shared/services/rest-services/kpi.service';
import {KpiGroupedBy, KpiType, StackedOrSplit} from 'src/app/shared/models/kpi.model';
import { HealthTrend } from 'src/app/shared/models/health.model';
import { GraphType, graphTypeToEntityType } from '../graph-type-selector/graph-type.enum';
import { EntityDashboardConfig } from './entity-dashboard-config.service';
import { BaseEntityConfigService } from './base-entity-config.service';
import { BaseTrendGraphSelectorsConfigService } from './base-trend-graph-selectors-config.service';
import { KpiMultiDataService } from 'src/app/shared/components/chartjs-components/services/kpi-stacked-split-data.service';

@Injectable({
  providedIn: 'root'
})
export class HealthDashboardConfigService extends BaseEntityConfigService implements EntityDashboardConfig {
  protected logger: Logger;

  constructor(protected loggerFactory: LoggerService,
    protected dashboardService: DashboardService,
    protected kpiStackedAndSplitDataService: KpiMultiDataService,
    protected translate: TranslateService,
    protected themeService: ThemeService,
    protected graphTrendConfigService: GraphTrendConfigService,
    protected kpiService: KpiService,
    protected trendGraphSelectorsConfigService: BaseTrendGraphSelectorsConfigService) {
    super(loggerFactory, trendGraphSelectorsConfigService)
    this.logger = this.loggerFactory.getLogger("HealthDashboardConfigService");
  }
  /**
   * Get the configuration for the donut issues chart
   */
  getIssuesDonutConfig$(): Observable<{ title: string, datasets: any[] }> {
    let severityTheme = this.themeService.getSeverityTheme()

    let donutConfig = {
      title: this.translate.instant("data.DASHBOARD.ACTIONS"),
      datasets: []
    }

    return this.dashboardService.fetchActionsCount('Severity').pipe(map((issuesData: Array<{ category, label, value }>) => {
      issuesData.forEach(({ category, label, value }) => {
        let lineConfig = {
          data: value,
          label,
          backgroundColor: severityTheme.colors[category],
          iconCSSClass: severityTheme.icons[category]
        };
        donutConfig.datasets.push(lineConfig)
      })
      return donutConfig;
    }))
  }
  /**
   * Get the configuration for trend graph chart
   * @param graphType the graph type to get the configuration for
   */
  getTrendGraphConfig$(graphType: GraphType, groupedBy: KpiGroupedBy = undefined): Observable<{ datasets: any[] }> {
    if (GraphType.TRAFFIC == graphType) {
      let trendLineConfig: GraphTrendConfig;
      return this.kpiService.getMultiKpiTrends(undefined, groupedBy).pipe(map(
        (kpiTrend) => {
          return this.kpiStackedAndSplitDataService.setTrendLineConfig(kpiTrend, trendLineConfig, KpiType.Traffic, groupedBy);
        }
      ));
    }
    return this.dashboardService.fetchHealthTrend(graphTypeToEntityType(graphType)).pipe(map(
      (healthTrend) => {
        return this.getTrendData(graphTypeToEntityType(graphType), healthTrend)
      }
    ))
  }
  // presentationType, label, value
  /**
   * Get the trend graph config including it's data
   * @param graphType graph type to get the data for
   * @param healthTrend the trend graph data
   */
  private getTrendData(graphType: EntityType, healthTrend: HealthTrend) {
    let dataIll = healthTrend.unhealthy.map((datum) => { return { x: datum.datetime, y: datum.value } })
    let dataTotal = healthTrend.total.map((datum) => { return { x: datum.datetime, y: datum.value } });
    this.logger.debug(`graph type selected ${graphType} in setTrendData`)

    let config = this.graphTrendConfigService.getHealthConfig(graphType)
    return {
      datasets: [{
        ...config.illTrend, data: dataIll
      }, {
        ...config.totalTrend, data: dataTotal
      }]
    }
  }
  // private getKpiTrendData(kpiType: KpiType, healthTrend: KpiTrend) {
  //     let dataTotal = healthTrend.map((datum) => { return { x: datum.datetime, y: datum.value } });
  //     let config = this.graphTrendConfigService.getKpiConfig(kpiType)
  //     return { lineConfig: [{ ...config, data: dataTotal }] };
  // }

}
