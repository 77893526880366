<div class="time-period-input-container">
  <div [matMenuTriggerFor]="menu"
       class="time-period-input-display"
       (menuOpened)="onMenuOpened()"
       (menuClosed)="onMenuClosed()">
    {{displayLabel}}
  </div>
</div>

<mat-menu #menu="matMenu" classList="time-period-input-menu">
  <div class="time-period-input" (click)="$event?.stopPropagation()">
    <mat-slider [min]="minBound"
                [max]="maxBound"
                [step]="step">
      <input matSliderThumb [value]="interval" (input)="onIntervalChange($event.currentTarget.value)" (change)="onIntervalChange($event.currentTarget.value)"/>
    </mat-slider>
    <mat-form-field>
      <div class="netop-select-list">
        <mat-select [(value)]="timeUnit" (selectionChange)="onTimeUnitSelected($event.value)">
          <mat-option *ngFor="let option of timeUnitOptions" [value]="option">{{option | titlecase}}</mat-option>
        </mat-select>
      </div>
    </mat-form-field>
  </div>
</mat-menu>
