<div class="fabric-status-display-container">
  <div class="row fabric-status-title-container">
    <div class="fabric-status-row" [ngClass]="colorClass"><strong>{{fabricStatusTitle}}</strong></div>
    <mat-icon *ngIf="!isFabricComplete" svgIcon="minimize-icon" (click)="changeCollapseMode()"></mat-icon>
  </div>
  <div *ngIf="!isCollapse && !isFabricComplete" [@inOutAnimation]>
    <div class="fabric-status-row" *ngIf="fabricStatusReason"><strong>Reason: </strong>{{fabricStatusReason}}</div>
    <div class="fabric-status-row" *ngIf="fabricStatusSolution"><strong>Solution: </strong>{{fabricStatusSolution}}</div>
    <div class="row fabric-status-icons-container">
      <div class="row fabric-status-single-icon-container" *ngFor="let icon of iconsName">
        <mat-icon svgIcon="{{icon}}-icon"></mat-icon>
        <div>{{icon}}</div>
      </div>
    </div>
  </div>
</div>
