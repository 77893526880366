import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActiveElement, Chart, ChartEvent, ChartOptions, PointElement } from 'chart.js';
import { Logger, LoggerService } from 'src/app/shared/services/logger.service';
import { GraphTrendConfig } from '../models/graph-trend-config';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { TimeManagerService } from '../../../services/time-manager.service';
import { GraphTooltip } from '../chartjs-tooltips/graph-tooltip';
import { NgChanges } from 'src/app/shared/extend-angular-classes/on-changes';
import annotationPlugin from 'chartjs-plugin-annotation';


import { ChartJsAnnotation } from '../models/annotations.model';
import { GraphTrendMarker, SelectedBarDatasets } from "../models/chart-js-events.model";
import { BaseTrendChartJs } from "../models/base-trend-chart-js";
import 'chartjs-adapter-moment';


@Component({
  selector: 'app-graph-trend',
  templateUrl: './graph-trend.component.html',
  styleUrls: ['./graph-trend.component.scss']
})
export class GraphTrendComponent extends BaseTrendChartJs {
  @Output() selectedBar: EventEmitter<any> = new EventEmitter();
  @Input() stepSize: number = undefined;
  @Input() noDataError: string = this.translate.instant('data.COMMON.NO_GRAPH_DATA_TO_DISPLAY');
  @Input() annotations: ChartJsAnnotation[] = [];
  @Input() timeDisplayFormat: string = 'DD-MMM-YYYY HH:mm';

  /**
   * Tooltip to be rendered with the first drawing of the chart
   */
  @Input() marker: GraphTrendMarker = undefined;
  @Input() hideTooltip: boolean = false
  @Output() onBarHovered: EventEmitter<SelectedBarDatasets> = new EventEmitter<SelectedBarDatasets>();

  selectedDatasets: SelectedBarDatasets;


  /**
   * 24.11.2019
   * To make the graph responsive :
   * [1] Set the graph/chartjs property responsive to true
   * [2] For the initial size use media query/observable
   * [3] Set margin of the graph to some negative number : the less margin is, faster
   *  the graph will respond to the resizing. The same trick would work for width
   *   set to something less then 100% (if we could afford such a width)
   * [4] To compensate for the margin use padding on the parent/host element
   */

  // 24-11-2019 Evgeny
  // This was moved into css files but I will not remove it till coordinated with
  // Imry.
  // @Input() marginLeft: number = -20;
  // @Input() marginRight: number = -20;
  mediaWidth = {
    [Breakpoints.XLarge]: { width: 1000, heigh: 320 },
    [Breakpoints.Large]: { width: 820, heigh: 320 },
    [Breakpoints.Medium]: { width: 800, heigh: 250 },
    [Breakpoints.Small]: { width: 300, heigh: 150 },
  }
  private logger: Logger; components; columnDefs;
  private readonly Y_AXES_COLOR = "#D3D8DD";
  /**
   * Allow to set the chart type to "line", which is the only way to draw stacked chart
   * There are more types available. But for now (1.4.20), Only two allowed.
   */
  /**
   * The graph tooltip instance that will be used for generating a custom tooltip
   */
  graphTooltip: GraphTooltip = new GraphTooltip();

  constructor(protected loggerFactory: LoggerService,
    public breakpointObserver: BreakpointObserver,
    public dateConversionService: TimeManagerService,
    protected translate: TranslateService) {
    super(translate, loggerFactory)
    this.logger = this.loggerFactory.getLogger("GraphTrendComponent");
  }

  ngOnInit() {
    this.generateGraphSizes();
  }

  generateGraphSizes() {
    if (!this.graphWidth || !this.graphHeight) {
      let mediaQueries = Object.keys(this.mediaWidth);
      for (let i = 0; i < mediaQueries.length; i++) {
        if (this.breakpointObserver.isMatched(mediaQueries[i])) {
          let size = this.mediaWidth[mediaQueries[i]];
          console.log("matched query width size %o", size)
          this.graphWidth = this.graphWidth || size.width;
          this.graphHeight = this.graphHeight || size.heigh;
          break;
        }
      }
    }
  }

  ngOnChanges(changes: NgChanges<GraphTrendComponent>) {
    if (changes.marker && this.marker) {
      this.logger.debug("ngOnChanges Marker", this.marker.formatted);
      this.drawTooltip();
    }
    this.logger.debug("changes found %o", changes);
    if (changes.chartConfig && this.chartConfig) {
      this.drawGraph();
    }
  }

  ngAfterViewInit() {
    this.logger.debug("ngAfterViewInit called ");
    if (this.chartConfig) {
      this.drawGraph();
    }
    if (this.marker) {
      this.drawTooltip();
    }
  }

  drawGraph() {
    if (this.chart) {
      this.chart.destroy()
    }

    // If there is no line that have non empty dataset - draw empty graph
    if (this.hasNoData(this.chartConfig)) {
      this.drawEmptyGraph();
      this.graphTooltip.removeDefaultTooltip(this);
      return;
    }
    // if (this.stacked)
    //   this.chartType = "line";

    let yTicks = {
      autoSkip: true,
      maxTicksLimit: this.maxTicksY,
      padding: 12,
    };
    if (this.minY !== undefined) {
      yTicks["min"] = this.minY;
    }
    if (this.maxY !== undefined) {
      yTicks["max"] = this.maxY;
    }

    //Set the step size between ticks. E.g, In case there is no logic in decimals
    if (this.stepSize !== undefined) {
      yTicks["stepSize"] = this.stepSize;
    }
    if (this.beginAtZero !== undefined) {
      yTicks["beginAtZero"] = this.beginAtZero;
    }
    if (this.stacked !== undefined) {
      yTicks["stacked"] = this.stacked;
    }
    if (this.annotations && this.annotations.length > 0) {
      yTicks["suggestedMaxY"] = this.annotations.filter(annotation => annotation.yMax)[0].yMax;
    }

    if (this.suggestedMaxY !== undefined) {
      yTicks["suggestedMaxY"] = this.suggestedMaxY;
    }
    if (this.suggestedMinY !== undefined) {
      yTicks["suggestedMinY"] = this.suggestedMinY;
    }

    let xTicks = {
      autoSkip: true,
      maxTicksLimit: this.maxTicksX,
      maxRotation: this.maxRotation,
    }
    if (this.minX !== undefined) {
      xTicks["min"] = this.minX;
    }
    if (this.maxX !== undefined) {
      xTicks["max"] = this.maxX;
    }

    let defaultTooltipValue: GraphTrendMarker;
    if (this.marker !== undefined) {
      defaultTooltipValue = this.marker;
    }
    const thisClass = this;

    // this.logger.debug(`maxY ${maxY} , ticks options %o`, yTicks)
    this.datasets = this.chartConfig.datasets.map((config) => this.buildLineConfig(config, this.graphElm, this.graphHeight));
    if (this.annotations && this.annotations.length > 0) {
      Chart.register(annotationPlugin);
    }
    this.logger.debug("graph data %o", this.datasets);
    this.logger.debug(`max ticks ${this.maxTicksX} max rotation ${this.maxRotation}`)
    this.chart = new Chart(this.graphElm.nativeElement, {
      type: 'line',
      data: {
        datasets: this.datasets
      },
      plugins: [{
        id: 'afterDatasetDraw',
        afterDatasetDraw(chart: Chart, args, options): boolean | void {
          thisClass.drawVerticalMarkerLine(chart.ctx);
        }
      }],
      options: {
        plugins: {
          annotation: thisClass.annotations && thisClass.annotations.length > 0 ? {
            annotations: thisClass.annotations as any[]
          } : null,
          legend: this.legend,
          tooltip: {
            mode: 'index',
            enabled: thisClass.hideTooltip ? true : false,
            position: 'nearest',
            intersect: false,
            external: thisClass.hideTooltip
              ? null
              : this.graphTooltip.customGraphTooltips,
            callbacks: {
              //Fill the squares color (AKA: labelColor) with the same color of the border
              labelColor: (context) =>
                this.graphTooltip.fillLabelColors(context, "borderColor")
            },
            filter: function (tooltipItem, data) {
              if (thisClass.hideTooltip) {
                return false;
              }
              tooltipItem.label = thisClass.dateConversionService.dateByFormat((tooltipItem.element as PointElement).parsed.x, thisClass.timeDisplayFormat);
              // tooltipItem. yLabel = dataset?.yValue;
              // tooltipItem.value = dataset?.yValue?.toString();
              return true;
            }
          },
        },
        responsive: thisClass.isResponsive,
        maintainAspectRatio: false,
        hover: {
          mode: thisClass.stacked ? 'index' : 'index',
          axis: 'x',
          intersect: false,
        },
        onHover: function (event: ChartEvent, elements: ActiveElement[], chart: Chart) {
          if (elements && elements.length > 0) {
            thisClass.setVerticalMarkerLineSizes(elements, chart);
          }
          if (event.native['isArtificial']) {
            return;
          }
          let currentDatasetIndex: number;
          let currentBarIndex: number;
          if (elements && elements.length > 0) {
            currentDatasetIndex = elements[0].index;
            if (thisClass.marker) {
              currentBarIndex = thisClass.chart.data.datasets[0].data.findIndex(point => (point as any).x._i === thisClass.marker.moment._i);
            } else {
              currentBarIndex = elements[0].datasetIndex;
            }
          }
          const selectedBarDatasets = {
            stacked: thisClass.stacked,
            barIndex: currentBarIndex,
            datasetIndex: currentDatasetIndex,
            datasets: {}
          }
          elements.forEach(element => {
            if (chart.data.datasets && chart.data.datasets.length > 0) {
              const dataset = chart.data.datasets[element.datasetIndex];
              //const item = dataset.data[element.index]
              if (element.element.y >= 0) {
                selectedBarDatasets.datasets[element.datasetIndex] = {
                  name: dataset.label,
                  xValue: (element.element as PointElement).parsed.x,
                  yValue: (element.element as PointElement).parsed.y,
                  index: element.index,
                  datasetIndex: element.datasetIndex,
                  x: event.x,
                  y: event.y
                };
              }
              thisClass.selectedDatasets = selectedBarDatasets;
            }
          });
          if (selectedBarDatasets.barIndex !== undefined
            && Object.keys(selectedBarDatasets.datasets).length > 0) {
            thisClass.logger.debug("selectedBarDatasets", selectedBarDatasets);
            thisClass.onBarHovered.emit(selectedBarDatasets);
          }
        },
        scales: {
          x: {
            type: 'time',
            max: xTicks["max"],
            min: xTicks["min"],
            time: {
              unit: 'hour',
              displayFormats: {
                hour: thisClass.timeDisplayFormat
              }
            },
            display: true,
            title: {
              display: true,
            },
            border: {
              display: false
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: xTicks.autoSkip,
              maxRotation: xTicks.maxRotation,
              maxTicksLimit: xTicks.maxTicksLimit,


              // callback: function (value, index, values: any[]) {
              //   if (thisClass.annotations && thisClass.annotations.length) {
              //     if (value && index == 0 || index == (values.length - 4)) {
              //       return value;
              //     }
              //     return '';
              //   }
              //   return value;
              // }
            } as any,
          },
          y: {
            // hide the axes line
            max: yTicks["max"],
            min: yTicks["min"],
            suggestedMax: yTicks["suggestedMaxY"],
            beginAtZero: yTicks["beginAtZero"],
            title: {
              display: true,
              //labelString: 'Count'
            },
            border: {
              display: false
            },
            grid: {
              display: !(thisClass.annotations && thisClass.annotations.length > 0),
              // show grid lines as dashs
              // remove the little lines aside of the ticks
              tickLength: 0,
              color: this.Y_AXES_COLOR,
            },
            //Responsible to display stacked chart data
            stacked: yTicks["stacked"],
            // Computed number of ticks & max/min values
            ticks: {
              stepSize: yTicks["stepSize"],
              maxTicksLimit: yTicks.maxTicksLimit,
              autoSkip: yTicks.autoSkip,
              padding: yTicks.padding,
            }
          },
        },
      },
    }
    );
  }

  private drawTooltip() {
    if (this.marker && this.chart) {
      const barIndex = this.chart.data.datasets[0].data.findIndex(point => (point as any).x._i === this.marker.moment._i);
      if (barIndex >= 0) {
        this.logger.debug("index", barIndex);
        this.graphTooltip.openDefaultTooltip(barIndex, this);
      }
    }
  }
}
