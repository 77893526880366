import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, filter, switchMap, tap} from 'rxjs/operators';
import {BreadCrumb} from '../../models/breadcrumbs.model';
import {Hierarchy, HierarchyLevel} from '../../models/hierarchy.model';
import {LoggerService, Logger} from '../../services/logger.service';
import {selectHierarchy} from 'src/app/store/selectors/hierarchy.selector';
import {IAppState} from 'src/app/store/state/app.state';
import {Store} from '@ngrx/store';
import {StoreBrandingService} from '../../services/client-storage-services/internal-storage-services/store-branding.service';
import {getObjectProperty} from "../../operators/object-operators/object-access";
import {clearSubscriptions} from "../../operators/rxjs";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  logger: Logger;
  routerSubscr: Subscription;
  hierarchySubscr: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<IAppState>,
    private loggerFactory: LoggerService,
    private storeBrandingService: StoreBrandingService
  ) {
    this.logger = this.loggerFactory.getLogger("BreadcrumbsComponent")
  }

  breadcrumbs: BreadCrumb[];

  ngOnInit() {
    // subscribe to router events
    this.routerSubscr = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      switchMap(() => this.store.select(selectHierarchy)
        .pipe(tap((hierarchy: Hierarchy) => this.buildBreadcrumbs(hierarchy)))
      )).subscribe();
    // build initial breadcrumbs
    this.hierarchySubscr = this.store.select(selectHierarchy)
      .pipe(tap((hierarchy: Hierarchy) => this.buildBreadcrumbs(hierarchy)))
      .subscribe();
  }

  ngOnDestroy() {
    clearSubscriptions([this.routerSubscr, this.hierarchySubscr]);
  }

  buildBreadcrumbs(hierarchy: Hierarchy) {
    const breadcrumbs = this.buildHierarchyBredcrumbs(hierarchy);
    this.logger.debug("created hierarchhy breadcrumbs %o", breadcrumbs);
    let routingBreadcrumbs = this.buildRoutingBredcrumbs();
    this.breadcrumbs = breadcrumbs.concat(routingBreadcrumbs);
    this.logger.debug("Full Breadcrumbs array %o", this.breadcrumbs);
  }

  /**
   * Build breadcrumbs of the current entity using hierarchy as fetched from server
   *  and stored in ngrx store.
   * @param hierarchy the hierarchy of current entity
   */
  private buildHierarchyBredcrumbs(hierarchy: Hierarchy):
    Array<BreadCrumb> {
    return hierarchy.map((hieLevel: HierarchyLevel) => {
      let url = hieLevel.type.toLowerCase() + "/" + hieLevel.id;
      // if (this.activatedRouteSnapshot.data.breadcrumbsEntry) {
      //   url += "/" + this.activatedRouteSnapshot.data.breadcrumbsEntry
      // }
      return {
        queryParams: {},
        url,
        label: hieLevel.name
      }
    })
  }

  /**
   * Buld a breadcrumb corresponding to active route.
   * The breadcrumb will consist from any route with 'breadcrum' key in it's data section and the
   * full path starting from root to it.
   */
  private buildRoutingBredcrumbs(): Array<BreadCrumb> {
    let route = this.activatedRoute;
    let breadcrumbs = [];
    do {
      this.logger.debug(`building breadcrums from route %o`, route)
      this.logger.debug(`building breadcrums from pathFromRoot %o`, route.pathFromRoot)
      let breadcrumbDetails = this.getBreadcrumbDetails(route)
      if (breadcrumbDetails) {
        const breadcrumb = {
          label: breadcrumbDetails.label,
          url: breadcrumbDetails.path
        };
        breadcrumbs.push(breadcrumb)
      }
      route = route.firstChild;
    } while (route);
    this.logger.debug("created routing breadcrumbs %o", breadcrumbs)
    return breadcrumbs;
  }

  /**
   * Get the path and the label of the breadcrumb.
   * Only routes that have a 'breadcrumbs' key in it's route will be taken into account
   * any other routes will be skipped
   * @param route active route
   */
  private getBreadcrumbDetails(route: ActivatedRoute): { path: string, label: string } {
    const label: any = getObjectProperty(route, 'routeConfig.data.breadcrumbs');
    this.logger.debug(`breadcrumb label ${label}`)
    if (label) {
      const path = this.getRoutePath(route);//route.routeConfig ? route.routeConfig.path : '';
      return {path, label};
    }
    return null;
  }

  private getRoutePath(route: ActivatedRoute) {
    // The path can be reconstructed by going over active routes returned by
    // pathFromRoot, taking segments from snapshot and a path out of each segment
    return route.pathFromRoot.map((path) => path.snapshot.url.map((url) => url.path).join("/")).join("/");
  }

  get highestOrgName() {
    if (this.storeBrandingService && this.storeBrandingService.selectedOrgUI)
      return this.storeBrandingService.selectedOrgUI.companyName;
  }
}
