import { Component } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-devices',
  templateUrl: './top-devices.component.html',
  styleUrls: ['./top-devices.component.scss']
})
export class TopDevicesComponent {
  origin: TopTrafficOrigin = TopTrafficOrigin.DEVICES
}
