import {SingleDeviceType} from './single-device.model';

export class DeviceBackupDTO {
  enabled: boolean;
  deviceId: number;
  deviceType: SingleDeviceType;
  name: string;
  serialNo: string;
  vendorName: string;
  model: string;
  lastBackupDate: Date;
  cronExpression: string;
  firmWareVersion: string;
  keepFor: number;
  keepForChronoUnit: ChronoUnit;
  supportsBackup: boolean;
  backupStatus: BackupStatus;

}

export class DeviceBackupPeriodicDTO {
  enabled: boolean;
  deviceIds: number[];
  cronExpression: string;
  keepFor: string;
  keepForChronoUnit: ChronoUnit;
}

export class DeviceBackupOnceDTO {
  deviceIds: number[];
  runAt: string;
}

export class DeviceBackupNowDTO {
  deviceIds: number[];
}

export class DeviceBackupHistoryDTO {
  firmWareVersion: string;
  backupStatus: BackupStatus;
  ranAt: Date;
}

export class RestoreBackupHistory {
  date: string;
  unFormatted: Date;
  fileName: string;
}

export enum BackupStatus {
  Executing = "Executing",
  Success = "Success",
  Failed = "Failed"
}

export enum ChronoUnit {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS"
}

export namespace ChronoUnit {
  export function getChronoUnitASArray() {
    return {
      [ChronoUnit.DAYS]: "data.COMMON.DAY",
      [ChronoUnit.WEEKS]: "data.COMMON.WEEK",
      [ChronoUnit.MONTHS]: "data.COMMON.MONTH"
    }
  }
}

export enum BackupSupport {
  ALL = "ALL",
  SUPPORTED = "SUPPORTED",
  UN_SUPPORTED = "UN_SUPPORTED"
}
