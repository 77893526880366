import {Injectable} from '@angular/core';
import {VerticalBarThemeConfig} from "../models/vertical-bar-config";

@Injectable({
  providedIn: 'root'
})
export class VerticalBarConfigService {
  constructor() {
  }

  /**
   * Return the highest value.
   * Take in consideration the hidden state of the datasets
   */
  calculateSuggestedMaxY(datasets: VerticalBarThemeConfig[]) {
    let heightBarValue: number = 0;
    datasets.forEach(dataset => {
      dataset.data.forEach((point, pointIndex) => {
        let totalYPerPoint = 0;
        datasets.forEach(dataset => {
          const data = dataset.data[pointIndex];
          if (data >= 0) {
            totalYPerPoint += data;
          }
        })
        if (totalYPerPoint > heightBarValue) {
          heightBarValue = totalYPerPoint;
        }
      })
    })
    return heightBarValue + 2;
  }
}
