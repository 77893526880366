import {Injectable} from '@angular/core';
import {DeviceIssuesAnomaly} from 'src/app/shared/models/anomalies.model';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {
  calculateDaysNumberBetweenTwoDates,
  calculateTotalHoursBetweenTwoDates
} from 'src/app/shared/operators/time-operator';
import {DeviceStatus} from 'src/app/shared/models/devices.model';
import {HoriznotalTimeSpan} from '../../../../../../horizontal-timeline/models/horizontal-time-span.model';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {BaseAnomalySpanService} from '../../services/base-anomaly-span.service';
import {IBaseAnomalyHorizontalGrid} from '../../models/IBase-anomaly-horizontal-span';

@Injectable({
  providedIn: 'root'
})
export class DeviceIssuesGridService extends BaseAnomalySpanService implements IBaseAnomalyHorizontalGrid {

  constructor(protected dateConversion: TimeManagerService,
              protected healthService: HealthColorsService,
  ) {
    super(healthService, dateConversion);
  }

  /**
   * Return string with the data of the time span for all devics issues (in days)
   */
  getLastDateAsString(deviceIssuesData: DeviceIssuesAnomaly[]): string {
    if (deviceIssuesData &&
      deviceIssuesData.length > 0 &&
      deviceIssuesData.filter(issue => issue.deviceStatusHistory.length > 0).length > 0) {
      let earliestDate: Date = this.findEarliestDate(deviceIssuesData[0].deviceStatusHistory);
      deviceIssuesData.forEach(issue => {
        if (this.findEarliestDate(issue.deviceStatusHistory) < earliestDate)
          earliestDate = this.findEarliestDate(issue.deviceStatusHistory);
      });
      if (calculateDaysNumberBetweenTwoDates(earliestDate, new Date()) < 1) {
        return `Last ${calculateTotalHoursBetweenTwoDates(earliestDate, new Date()).toFixed(0)} hours device status`;
      }
      return `Last ${calculateDaysNumberBetweenTwoDates(earliestDate, new Date()).toFixed(0)} days device status`;
    }
    return "Time";
  }

  /**
   * Find the eraliest date for each status history
   */
  findEarliestDate(deviceStatusHistory: DeviceStatus[]) {
    if (deviceStatusHistory.length > 0) {
      let earliestDate: Date = this.dateConversion.convertUnixToDateObject(deviceStatusHistory[0].startTime);
      deviceStatusHistory.forEach(status => {
        if (this.dateConversion.convertUnixToDateObject(status.startTime) < earliestDate)
          earliestDate = this.dateConversion.convertUnixToDateObject(status.startTime);
      });
      return earliestDate;
    }
    return new Date();
  }

  getTimeSpans(statuses: DeviceStatus[]) {
    if (statuses && statuses.length > 0) {
      let timeSpans: HoriznotalTimeSpan[] = [];
      this.initiateTimeSpanWithData(timeSpans, statuses);
      return timeSpans;
    }
  }

  initiateTimeSpanWithData(timeSpans: HoriznotalTimeSpan[], statuses: DeviceStatus[]) {
    statuses.forEach(status => {
      timeSpans.push(
        new HoriznotalTimeSpan(
          this.dateConversion.convertUnixToDateObject(status.startTime),
          status.endTime ? this.dateConversion.convertUnixToDateObject(status.endTime) : new Date(),
          status.currentStatus,
          this.healthColorsService.getColorByDeviceStatus(status.currentStatus),
          `Start: ${this.dateConversion.dateByFormat(status.startTime, 'DD-MMM-YYYY HH:mm')},
          End: ${this.dateConversion.dateByFormat(status.endTime ? status.endTime : new Date(), 'DD-MMM-YYYY HH:mm')}`
        ))
    });
  }
}
