import {Component, OnInit, Input} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

export type TextAndPath = { text: string, iconPath: string, showEllipse?: boolean };

@Component({
  selector: '[icon-with-text-renderer]',
  templateUrl: './icon-with-text-renderer.component.html',
  styleUrls: ['./icon-with-text-renderer.component.scss']
})
export class IconWithTextRendererComponent implements ICellRendererAngularComp {
  iconPath: string;
  text: string;
  showEllipse: boolean = true;

  constructor() {
  }

  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    let textAndPath: TextAndPath[] = params.context.componentParent.textWithIconPath;
    if (textAndPath && textAndPath.length > 0) {
      let rowIndex = params.rowIndex;
      this.iconPath = textAndPath[rowIndex]?.iconPath
      this.showEllipse = textAndPath[rowIndex]?.showEllipse;
      this.text = params.value;
    }
  }

  refresh(params: any): boolean {
    throw new Error("Method not implemented.");
  }

  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  get ellipseClass(): string {
    return this.showEllipse || this.showEllipse === undefined ? 'icon-cell-renderer' : 'icon-cell-renderer hide';
  }


}
