import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GraphTypeSelectorData } from './graph-type-selector-data.model';
import { ICONS_PATH } from 'src/app/config/path';
import { LoggerService, Logger } from '../../services/logger.service';
import { StoreUserPreferencesService } from '../../services/client-storage-services/internal-storage-services/store-user-preferences.service';
import { GraphType } from './graph-type.enum';

@Component({
  selector: 'app-graph-type-selector',
  templateUrl: './graph-type-selector.component.html',
  styleUrls: ['./graph-type-selector.component.scss']
})
export class GraphTypeSelectorComponent implements OnInit {
  @Input() selectorData: GraphTypeSelectorData;
  @Input() selectable: boolean;
  @Input() selectorDataCss: string;
  @Input() addMargin: boolean = false;
  @Input() inBarDisplay: boolean = false;
  @Input() layout: "column" | "row" = "column";
  graphType = GraphType;


  @Input()
  set selected(value) {
    let changed = this._selected != value;
    this._selected = value;
    if (changed) {
      this.logger.debug("selected value changed %s", this._selected)
      this.cdr.detectChanges()
    }
  }
  get total() {
    // if the toatal have more then 2 pint numbers, cut it
    return this.selectorData.total.toFixed(2)
  }
  get selected() {
    return this._selected;
  }

  get change(): number {
    return +this.selectorData.change
  }
  _selected: boolean;

  @Output() selectorClicked = new EventEmitter<string>();
  private logger: Logger; components; columnDefs;

  ICONS_PATH = ICONS_PATH;
  constructor(private loggerFactory: LoggerService, private cdr: ChangeDetectorRef, private storeUserPreferencesService: StoreUserPreferencesService) {
    this.logger = this.loggerFactory.getLogger("GraphTypeSelectorComponent");
  }

  ngOnInit() {
    this.logger.debug(`icon ${this.selectorData.icon}`)
    this.checkIfUserHasSelectorPreference()
  }
  /**
   * @method checkIfUserHasSelectorPreference Check if the user has any preference regarding the default selector type
   * for the trend graph.
   * If yes: It simulate click event for the relevant selector
   * The time out is necessary because the graph is being rendered before the grid.
   * Only after the grid update the current grid identity at the storeUserPreferencesService, and then this current function
   * can run the test.
   */
  checkIfUserHasSelectorPreference() {
    setTimeout(() => {
      for (let [key, value] of Object.entries(this.storeUserPreferencesService.userPreferences)) {
        if (key == this.storeUserPreferencesService.currentGrid) {
          if (value && value.hasOwnProperty('graphTypeSelector') && value["graphTypeSelector"] == this.selectorData.graphType) {
            this.onSelectorClick(true);
          }
        }
      }
    });
  }

  onSelectorClick($event) {
    if (!this.selectable)
      return;
    this._selected = true;
    this.selectorClicked.emit(this.selectorData.graphType);
    this.storeUserPreferencesService.setNonGridPreferences("graphTypeSelector", this.selectorData.graphType);
    this.logger.debug("clicked selector %s", this.selectorData.graphType)
  }
}
