import {Component, OnInit, Inject} from '@angular/core';
import {TimeManagerService} from '../../services/time-manager.service';
import {Entity} from '../../models/entity.model';
import {EntityType} from '../../models/entity-type.enum';
import {ReportsDialogData} from "../../pages/reports/models/reports-filter.model";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-deviation-reports-dialog',
  templateUrl: './deviation-reports-dialog.component.html',
  styleUrls: ['./deviation-reports-dialog.component.scss']
})
export class DeviationReportsDialogComponent implements OnInit {
  currentDate: Date;
  currentEntity: Entity;
  preSelectedEntity: Entity;
  EntityType = EntityType;

  constructor(private matDialogRef: MatDialogRef<DeviationReportsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: ReportsDialogData,
              private dateConversion: TimeManagerService
  ) {
  }

  ngOnInit() {
    this.currentDate = new Date(this.dialogData.selectedDate);
    this.currentEntity = this.preSelectedEntity = this.dialogData.selectedEntity;
  }

  /**
   * Compare function that return true if the from date is smaller than the max date limit
   * The purpose is to prevent selection for the fuure.
   */
  filterDates(toDate: Date | null) {
    return toDate <= new Date();
  }

  onSelectedEntity(entity: Entity) {
    this.currentEntity = entity;
  }

  get formattedSelectedDate(): string {
    return this.currentDate? this.dateConversion.dateByFormat(this.currentDate, 'YYYY-MM-DD'): null;
  }

  get breadcrumbs(): string {
    if (this.preSelectedEntity && this.currentEntity) {
      if (this.preSelectedEntity.id != this.currentEntity.id) {
        return `${this.preSelectedEntity.type}: ${this.preSelectedEntity.name} > ${this.currentEntity.type}: ${this.currentEntity.name}`
      }
      if (this.preSelectedEntity.id == this.currentEntity.id && this.currentEntity.type == EntityType.TENANT) {
        return `${this.preSelectedEntity.type}: ${this.preSelectedEntity.name}`
      }
      if (this.preSelectedEntity.id == this.currentEntity.id && this.currentEntity.type == EntityType.VENUE) {
        if (this.dialogData && this.dialogData.currentTenant) {
          return `${this.dialogData.currentTenant.type}: ${this.dialogData.currentTenant.name} > ${this.currentEntity.type}: ${this.currentEntity.name}`
        }
      }
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}
