import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import {ChangeDetectionStrategy} from '@angular/core';
import {Chart, ChartEvent, ChartOptions, LegendElement, LegendItem, TooltipItem} from 'chart.js';
import {VerticalBarTooltip} from '../chartjs-tooltips/vertical-bar-tooltip';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {ChartJStooltipDirection} from '../chartjs-tooltips/chartjs-tooltip.model';
import {NgChanges} from "../../../extend-angular-classes/on-changes";
import {drawAboveBarLabel} from "../plugins/vertical-bar.plugins";
import {VerticalBarData, VerticalBarThemeConfig} from "../models/vertical-bar-config";
import {VerticalBarConfigService} from "../services/vertical-bar-config.service";
import 'chartjs-adapter-moment';


export type IndexedLabel = { index: number, label: string };

@Component({
  selector: 'app-vertical-bar',
  templateUrl: './vertical-bar.component.html',
  styleUrls: ['./vertical-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalBarComponent {
  @Input() verticalBarDatasets: VerticalBarData;
  @Input() height: number;
  @Input() width: number;
  @Input() stepSize: number;
  @Input() tooltipDirection: ChartJStooltipDirection = "above";
  @Input() maxTicksY: number = undefined;
  @Input() suggestedMaxY: number = undefined;
  @Input() displayYGridLines: boolean = true;
  @Input() displayXGridLines: boolean = true;
  @Input() drawAxisBorderOnly: boolean = false;
  @Input() isObscurityChart: boolean = false;
  @Input() hideLabels: boolean = false;
  @Input() isWithArrowDisplay: boolean = false;
  @Input() tooltipMinWidth: number = 250;
  private logger: Logger; components; columnDefs;


  @ViewChild('verticalBar', {static: true}) verticalBarElm: any;
  verticalBar: any
  verticalBarTooltip = new VerticalBarTooltip(this.tooltipDirection, this.isWithArrowDisplay, this.tooltipMinWidth);
  @Output() barClicked: EventEmitter<IndexedLabel> = new EventEmitter();

  constructor(
    private verticalBarConfigService: VerticalBarConfigService,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("VerticalBarComponent");
  }

  ngOnChanges(changes: NgChanges<VerticalBarComponent>) {
    if (changes.isWithArrowDisplay) {
      this.verticalBarTooltip.isWithArrowDisplay = this.isWithArrowDisplay;
    }
    if (this.verticalBarDatasets && this.verticalBarElm) {
      this.drawBar();
    }
    if (changes.tooltipMinWidth && this.verticalBarTooltip) {
      this.verticalBarTooltip.minWidth = this.tooltipMinWidth;
    }
  }

  drawBar() {
    if (this.verticalBar) {
      this.verticalBar.destroy();
    }
    const thisClass = this;
    this.verticalBar = new Chart(this.verticalBarElm.nativeElement, {
      type: 'bar',
      data: this.verticalBarDatasets,
      plugins: [{
        id: 'afterDraw',
        afterDraw: drawAboveBarLabel,
      }],
      options: {
        plugins: {
          tooltip: {
            enabled: false,
            mode: 'index',
            position: 'nearest',
            external: this.verticalBarTooltip.customVerticalTooltips,
            callbacks: {
              title: (tooltipItems: TooltipItem<"bar">[]) => {
                if (tooltipItems && tooltipItems.length > 0) {
                  return (tooltipItems[0].dataset as VerticalBarThemeConfig).tooltipTitle[tooltipItems[0].dataIndex];
                }
                return null;
              },
              label: (context) => {
                const itemData: string = (context.dataset as VerticalBarThemeConfig).tooltipText[context.dataIndex] as string;
                if (itemData) {
                  thisClass.logger.debug("tooltipItem", context);
                  thisClass.logger.debug("itemData", itemData);
                  return `${itemData.toString()}`;
                }
                return null;
              },
              footer: (tooltipItems: TooltipItem<"bar">[]) => {
                if (tooltipItems && tooltipItems.length > 0) {
                  let currentFooter = tooltipItems.find(dataset => (dataset.dataset as VerticalBarThemeConfig).tooltipFooter);
                  if (currentFooter !== undefined) {
                    return (currentFooter.dataset as VerticalBarThemeConfig).tooltipFooter[tooltipItems[0].dataIndex] as string | string[];
                  }
                }
              },
            }
          },
          legend: {
            display: true,
            labels: {
              /**
               * Source: https://github.com/chartjs/Chart.js/blob/master/src/controllers/controller.doughnut.js#L42-L69
               */
              generateLabels(chart) {
                const data = chart.data;
                if (data.datasets.length > 0) {
                  return data.datasets.map((dataset, i) => {
                    return {
                      text: (dataset as VerticalBarThemeConfig).label,
                      fillStyle: (dataset as VerticalBarThemeConfig).backgroundColor,
                      pointStyle: (dataset as VerticalBarThemeConfig).isMockedDataset ? 'circle' : 'rect',
                      lineWidth: 0,
                      datasetIndex: i,
                      hidden: chart.getDatasetMeta(i).hidden
                    } as LegendItem;
                  });
                }
                return [];
              },
              boxWidth: 10,
              font: {
                size: 14
              },
              usePointStyle: true,
            },
            position: "top",
            align: "end",
            onClick: function (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<any>) {
              const index = legendItem.datasetIndex;
              const currentDataset = thisClass.verticalBar.data.datasets[index] as VerticalBarThemeConfig;
              const chart = thisClass.verticalBar;
              if (currentDataset.isMockedDataset) {
                chart.update();
                return;
              }
              const meta = chart.getDatasetMeta(index);
              meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
              if (thisClass.suggestedMaxY) {
                chart.scales.y.suggestedMax = thisClass.verticalBarConfigService.calculateSuggestedMaxY(thisClass.verticalBar.data.datasets as VerticalBarThemeConfig[]);
              }
              chart.update();
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: thisClass.displayXGridLines,
              drawOnChartArea: !this.drawAxisBorderOnly
            },
            ticks: {
              maxRotation: thisClass.hideLabels ? 0 : 50,
              callback: function (value, index, values) {
                const labelForValue = this.getLabelForValue(parseInt(value.toString()));
                if (thisClass.hideLabels) {
                  const arrayLastItem = values.length;
                  const skipLength: number = arrayLastItem > 25 ? 6 : 3;
                  const thresholdToSkip: number = 7;
                  if (values.length < thresholdToSkip || index === (arrayLastItem - 1) || index === 0) {
                    return `${labelForValue}`;
                  }
                  if (values.length >= thresholdToSkip &&
                    (index % skipLength === 0) && (index < (arrayLastItem - skipLength))) {
                    return `${labelForValue}`;
                  }
                } else {
                  return `${labelForValue}`;
                }
              }
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
            suggestedMax: thisClass.suggestedMaxY,
            ticks: {
              stepSize: thisClass.stepSize,
              maxTicksLimit: thisClass.maxTicksY,
              precision: 0,
            },
            grid: {
              display: thisClass.displayYGridLines,
              drawOnChartArea: !this.drawAxisBorderOnly
            },
            type: 'linear'
          }
        },

        onClick: (event, datasets) => {
          if (datasets && datasets.length > 0) {
            let currentDatasetIndex = datasets[0].datasetIndex;
            let currentLabel = datasets[0].index;
            if (currentLabel) {
              thisClass.barClicked.emit({index: currentDatasetIndex, label: currentLabel.toString()});
            }
            /**
             * Change the background if obscurity activated
             */
            if (thisClass.isObscurityChart) {
              thisClass.verticalBar.getDatasetAtEvent(event)[0]._chart.config.data.datasets.forEach(legend => {
                legend.backgroundColor.forEach((color, index) => {
                  if (index != currentDatasetIndex && legend.backgroundColor[index]) {
                    legend.backgroundColor[index] = legend.backgroundColor[index].replace(/[\d.]+\)$/g, '0.3)');
                  }
                })
              })
              this.verticalBar.update();
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      } as ChartOptions,
    })
  }
}
