import { Injectable, ChangeDetectorRef } from "@angular/core";
import { of, Observable } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { SparklineConfig, StackedSparklineConfig, TimeAxis } from "src/app/shared/components/chartjs-components/models/sparkline-config";
import { SparklineConfigService } from "src/app/shared/components/chartjs-components/services/sparkline-config.service";
import { ForceDirectedGraphNode } from "src/app/shared/components/force-directed-graph/models/force-directed-graph-node";
import { isForceLink } from "src/app/shared/components/force-directed-graph/operators/force-types-checks.operator";
import { HoriznotalTimeSpan } from "src/app/shared/components/horizontal-timeline/models/horizontal-time-span.model";
import { DeviceState } from "src/app/shared/models/devices.model";
import { Entity } from "src/app/shared/models/entity.model";
import { TrafficUnits, TrafficKpiDataDisplay } from "src/app/shared/models/kpi.model";
import { VPNdata, VPNConnectionStatus, DisplayVenueVPN, VPNTraffic, VPNSite, VPNStatusTrendDTO } from "src/app/shared/models/vpn.model";
import { openObject, reorderObjFirstGenProps, removeAllIdFields } from "src/app/shared/operators/object-operators/object-formatter.operator";
import { ObjectMarkers } from "src/app/shared/pipes/remove-object-markers.pipe";
import { TenantsService } from "src/app/shared/services/rest-services/tenants.service";
import { HealthColorsService } from "src/app/shared/services/strategies/health-colors.service";
import { KpiDataService } from "src/app/shared/services/strategies/kpi-data.service";
import { TimeManagerService } from "src/app/shared/services/time-manager.service";
import { VPNForceLink } from "../../models/vpn-force-link.model";
import { VPNForceNode } from "../../models/vpn-force-node.model";
import { VenuesService } from "src/app/shared/services/rest-services/venues.service";

@Injectable({
  providedIn: 'root'
})
export class VpnConnectionsService {
  isSparklineLoading: boolean = false;
  isTimeSpansLoading: boolean = false;

  constructor(private dateConversion: TimeManagerService,
    private tenantsService: TenantsService,
    private venuesService: VenuesService,
    private healthColorsService: HealthColorsService,
    private sparklineConfigService: SparklineConfigService,
    private kpiDataService: KpiDataService) {
  }

  /**
   * Return nodes and links array with all vpn data (include traffic and statuses)
   */
  getVpnDataAsNodesLinksArray(vpnData: VPNdata, entity: Entity): { nodes: VPNForceNode[], links: VPNForceLink[] } {
    let nodes: VPNForceNode[];
    let links: VPNForceLink[];
    vpnData = this.addDataToVpnConnections(vpnData);
    let nodesAndLinks = this.createVpnNodesAndLinks(vpnData.vpnConnections);
    nodesAndLinks = this.insertTrafficDataToLinks(nodesAndLinks, vpnData.vpnTraffic);
    nodesAndLinks = this.changeUnixFieldsToDate(nodesAndLinks);
    nodes = nodesAndLinks.nodes;
    links = nodesAndLinks.links;
    return this.setCurrentVenueAsDefaultSeleciton({ nodes: nodes, links: links }, entity);
  }

  changeUnixFieldsToDate(nodesAndLinks: { nodes: VPNForceNode[]; links: VPNForceLink[]; }): { nodes: VPNForceNode[]; links: VPNForceLink[]; } {
    nodesAndLinks.nodes.forEach(node => {
      node.data.deviceData.createdAt = this.dateConversion.dateByFormat(node.data.deviceData.createdAt, 'YYYY-MMM-DD, HH:mm');
      node.data.venueData.createdAt = this.dateConversion.dateByFormat(node.data.venueData.createdAt, 'YYYY-MMM-DD, HH:mm');
    })
    return nodesAndLinks;
  }

  /**
   * Add all vpn data to the vpn object
   * @param vpnData The entire vpn data
   */
  addDataToVpnConnections(vpnData: VPNdata) {
    if (vpnData) {
      vpnData.vpnConnections.forEach(connection => {
        connection.status = this.findConnectionStatus(connection, vpnData.vpnStatuses.vpnConnectionsStatus) || VPNConnectionStatus.UNKNOWN;
        connection.traffic = this.findConnectionTraffic(connection, vpnData.vpnTraffic);
        connection = this.findSitesStatuses(connection, vpnData.vpnStatuses.vpnSitesStatus);
      });
    }
    return vpnData;
  }

  /**
   * Add status data for each site
   */
  findSitesStatuses(connection: DisplayVenueVPN, vpnSitesStatus: { [id: number]: DeviceState; }): DisplayVenueVPN {
    if (connection.data && connection.data.firstVpnSite) {
      connection.data.firstVpnSite.status = vpnSitesStatus[connection.data.firstVpnSite.id] || DeviceState.Unknown;
    }
    if (connection.data && connection.data.secondVpnSite) {
      connection.data.secondVpnSite.status = vpnSitesStatus[connection.data.secondVpnSite.id] || DeviceState.Unknown;
    }
    return connection;
  }

  /**
   * Add Traffic data for each connection
   */
  findConnectionTraffic(connection: DisplayVenueVPN, vpnTraffic: VPNTraffic[]): VPNTraffic {
    let foundVpnTraffic = vpnTraffic.find(vpn =>
      vpn.firstSiteId == connection.data.firstVpnSite.id ||
      vpn.firstSiteId == connection.data.secondVpnSite.id &&
      vpn.secondSiteId == connection.data.firstVpnSite.id ||
      vpn.firstSiteId == connection.data.secondVpnSite.id
    );
    if (foundVpnTraffic) {
      connection.traffic = foundVpnTraffic;
    }
    return connection.traffic;
  }

  /**
   * Add status data for each connection
   */
  findConnectionStatus(connection: DisplayVenueVPN, vpnConnectionsStatuses: { [id: number]: VPNConnectionStatus }): VPNConnectionStatus {
    return vpnConnectionsStatuses[connection.data.id];
  }

  /**
   * Return nodes and links array, as retrieved from the vpn connections array
   */
  createVpnNodesAndLinks(venueVPN: DisplayVenueVPN[]): { nodes: VPNForceNode[], links: VPNForceLink[] } {
    let links: VPNForceLink[] = [];
    let nodes: VPNForceNode[] = [];
    venueVPN.forEach(vpnConnection => {
      nodes = this.addNodes(nodes, vpnConnection);
      links.push(this.addLink(vpnConnection));
    })
    return { nodes: nodes, links: links };
  }

  /**
   * Create node for each site in the vpn array.
   */
  addNodes(nodes: VPNForceNode[], vpnConnection: DisplayVenueVPN): VPNForceNode[] {
    let sites = [vpnConnection.data.firstVpnSite, vpnConnection.data.secondVpnSite];
    sites.forEach(site => {
      if (site) {
        if (nodes.find(node => node.id == site.id) == undefined) {
          nodes.push(
            new VPNForceNode(
              site.id,
              site.venueData.name,
              site.venueVpnMode ? site.venueVpnMode.toString() : '',
              site.venueVpnMode ? site.venueVpnMode.toString() : '',
              site,
            )
          )
        }
      }
    });
    return nodes;
  }

  /**
   * Create link for each connection on the vpn connections array
   */
  addLink(vpnConnection: DisplayVenueVPN): VPNForceLink {
    if (vpnConnection.data && vpnConnection.data.firstVpnSite && vpnConnection.data.secondVpnSite)
      return new VPNForceLink(
        vpnConnection.data.firstVpnSite.id,
        vpnConnection.data.secondVpnSite.id,
        vpnConnection.data.id,
        vpnConnection,
        this.kpiDataService
      )
  }

  /**
   * Combine the vpn traffic api with the vpn connection api in order
   * to add the traffic dta to the links array
   */
  insertTrafficDataToLinks(vpnConnections: { nodes: VPNForceNode[]; links: VPNForceLink[]; }, vpnTrafficData: VPNTraffic[]): { nodes: VPNForceNode[]; links: VPNForceLink[]; } {
    vpnTrafficData.forEach(traffic => {
      let matchingLink: VPNForceLink = vpnConnections.links.find(link => {
        return (link.source == traffic.firstSiteId ||
          link.source == traffic.secondSiteId) &&
          (link.target == traffic.firstSiteId ||
            link.target == traffic.secondSiteId)
      });
      if (matchingLink) {
        {
          matchingLink.data.traffic = traffic;
        }
      }
    });
    return { nodes: vpnConnections.nodes, links: vpnConnections.links };
  }

  /**
   * Return the current accoridion title, i.e., current selected entity from force directed graph
   */
  getAccordionTitle(clickedGraphElement: VPNSite | DisplayVenueVPN) {
    if (clickedGraphElement && clickedGraphElement.hasOwnProperty("venueData")) {
      return `${(clickedGraphElement as VPNSite).name}  -
      ${(clickedGraphElement as VPNSite).venueVpnMode.toString()}`;
    }
    if (clickedGraphElement && (clickedGraphElement as DisplayVenueVPN).hasOwnProperty("data")) {
      return `${(clickedGraphElement as DisplayVenueVPN).data.firstVpnSite.name} <->
      ${(clickedGraphElement as DisplayVenueVPN).data.secondVpnSite.name}`;
    }
  }

  /**
   * Return the formmated data for current selected entity at the force directed graph.
   * The data will be passed to the accordion widget
   */
  getFormattedVPNData(element: any, deleteGeneralData: boolean = false): {} {
    if (element.hasOwnProperty("thirdPartyVpnPeers")) {
      element = this.openThirdPartyVpnPeers(element);
    }
    if (element.hasOwnProperty("exportedVlans")) {
      element = this.openExportedVlans(element);
    }

    let openElement = openObject(reorderObjFirstGenProps(element));

    if (deleteGeneralData)
      delete openElement["generalData"];
    if (openElement.hasOwnProperty("publicIps")) {
      openElement[`${ObjectMarkers.AAA} Public IP`] = openElement["publicIps"];
      delete openElement["publicIps"];
    }

    return removeAllIdFields(openElement);
  }

  openExportedVlans(element: any): any {
    let displayExportedVlans: any[] = [];
    (<Array<any>>element.exportedVlans).forEach((item, index) => {
      displayExportedVlans.push(item);
      if (index < element.exportedVlans.length - 1)
        displayExportedVlans.push({ [index + 1]: -1 });
    })
    element.exportedVlans = displayExportedVlans;
    return element;
  }

  /**
   * Open and format thirdPartyVpnPeers object by bussines logic
   */
  openThirdPartyVpnPeers(element: any): any {
    let elementWithThirdParty = { ...element };
    if (elementWithThirdParty.thirdPartyVpnPeers.length == 0) {
      delete elementWithThirdParty.thirdPartyVpnPeers
    } else {
      elementWithThirdParty = this.getFormmatedThirdVPNPartyData(elementWithThirdParty);
    }
    return elementWithThirdParty;
  }

  getFormmatedThirdVPNPartyData(elementWithThirdParty: any): any {
    elementWithThirdParty.thirdPartyVpnPeers.forEach((peer, peerIndex) => {
      let formatted = {};
      peer.publicIps.forEach((ip, index) => {
        formatted[`Public IP. ${index + 1}`] = ip;
      });
      if (Array.isArray(peer.exportedVlans)) {
        peer.exportedVlans.forEach((vlan, index) => {
          formatted[`Exported Vlan IP. ${index + 1}`] = vlan.ip;
        });
      }
      //Add fake property after each item in the array for display needs
      if (peerIndex < elementWithThirdParty.thirdPartyVpnPeers.length - 1)
        formatted[`${peer.name}zzzz`] = -1;
      elementWithThirdParty[`${peer.name} - Third Party VPN`] = formatted;
    })
    delete elementWithThirdParty.thirdPartyVpnPeers;

    return elementWithThirdParty
  }

  /**
   * When no user selection was made, or When user click on the Svg element and not
   * on one of its inner elements =>
   * Set default selection on the force, with current venue entity
   */
  setCurrentVenueAsDefaultSeleciton(vpnIntegratedData: { nodes: VPNForceNode[]; links: VPNForceLink[]; }, entity: Entity): { nodes: VPNForceNode[]; links: VPNForceLink[]; } {
    if (vpnIntegratedData.nodes && vpnIntegratedData.nodes.length > 0) {
      let defaultVenueIndex = vpnIntegratedData.nodes.findIndex(node => node.data && node.data.venueData && node.data.venueData.id == entity.id);
      if (defaultVenueIndex >= 0) {
        vpnIntegratedData.nodes[defaultVenueIndex].isSelectedElement = true;
      } else {
        vpnIntegratedData.nodes[0].isSelectedElement = true;
      }
    }
    return vpnIntegratedData;
  }

  isOnlyOneSite(vpnData: VPNdata): boolean {
    return vpnData.vpnConnections.length == 0 && vpnData.vpnConnections[0]
      && (!vpnData.vpnConnections[0].data.firstVpnSite ||
        !vpnData.vpnConnections[0].data.secondVpnSite);
  }

  convertSiteToForceNode(site: VPNSite): any {
    let singletSiteNode = new ForceDirectedGraphNode(
      site.id,
      site.name,
      site.venueVpnMode.toString(),
      site.venueVpnMode.toString(),
      site
    );
    singletSiteNode.isOnlyNode = true;
    singletSiteNode.isSelectedElement = true;
    return singletSiteNode;
  }

  /**
   * Build the object that will be formatted for accordion display
   */
  buildElementForDisplay(element: VPNForceNode | VPNForceLink): {} {
    return this.getFormattedVPNData(element.data, true);
  }

  /**
   * Generate the time spans for horizontal bar
   */
  generateTimeSpans(element: any, timeSpans: HoriznotalTimeSpan[], cdr?: ChangeDetectorRef): void {
    this.isTimeSpansLoading = true;

    this.venuesService.getVPNSiteStatusTrend(element.vpnConnectionId || element.data.vpnConnectionId).pipe(take(1), catchError(e => {
      this.isTimeSpansLoading = false;
      return of(null);
    })).subscribe(siteStatusTrend => {
      this.isTimeSpansLoading = false;
      if (siteStatusTrend.length > 0) {
        siteStatusTrend.forEach(point => {
          timeSpans.push(
            new HoriznotalTimeSpan(
              this.dateConversion.convertUnixToDateObject(point.startDate),
              this.dateConversion.convertUnixToDateObject(point.endDate),
              point.status,
              this.healthColorsService.getColorByDeviceStatus(point.status),
              `Start: ${this.dateConversion.dateByFormat(point.startDate, 'DD-MMM-YYYY HH:mm')},
                End: ${this.dateConversion.dateByFormat(point.endDate, 'DD-MMM-YYYY HH:mm')}`
            )
          )
        })
      }

      if (cdr)
        cdr.markForCheck();
    })

  }

  generateSparkline(element: VPNForceNode | VPNForceLink, sparkline: SparklineConfig, cdr?: ChangeDetectorRef): void {
    if (isForceLink(element)) {
      this.isSparklineLoading = true;
      this.getConnectionStatusTraffic(element.id, sparkline, cdr, undefined, undefined, 7);
    }
  }

  getConnectionStatusTrend(connectionId: number, timeSpans: HoriznotalTimeSpan[], cdr: ChangeDetectorRef, tenantId?: number, specificDate: string = undefined, fromDate: number = 7) {
    let apiRequest: Observable<VPNStatusTrendDTO<VPNConnectionStatus>[]>;
    if (tenantId) {
      apiRequest = this.tenantsService.getVPNConnectionStatusTrendById(connectionId, tenantId, fromDate, specificDate);
    } else {
      apiRequest = this.tenantsService.getVPNConnectionStatusTrend(connectionId, fromDate, specificDate);
    }
    apiRequest.pipe(take(1)).subscribe(connectionStatusTrend => {
      if (connectionStatusTrend.length > 0) {
        connectionStatusTrend.forEach(point => {
          timeSpans.push(
            new HoriznotalTimeSpan(
              this.dateConversion.convertUnixToDateObject(point.startDate),
              this.dateConversion.convertUnixToDateObject(point.endDate),
              point.status,
              this.healthColorsService.getColorByConnectionStatus(point.status),
              `Start: ${this.dateConversion.dateByFormat(point.startDate, 'DD-MMM-YYYY HH:mm')},
                End: ${this.dateConversion.dateByFormat(point.endDate, 'DD-MMM-YYYY HH:mm')}`
            )
          )
        })
      }
      cdr.markForCheck();
    })
  }

  getConnectionStatusTraffic(connectionId: number, sparkline: SparklineConfig, cdr: ChangeDetectorRef, tenantId?: number, specificDate: string = undefined, fromDate: number = 7, trafficUnit?: { unit: TrafficUnits }) {
    let apiRequest: Observable<TrafficKpiDataDisplay[]>;
    if (tenantId) {
      apiRequest = this.tenantsService.getVPNConnectionTrafficTrendById(connectionId, tenantId, fromDate, specificDate);
    } else {
      apiRequest = this.tenantsService.getVPNConnectionTrafficTrend(connectionId);
    }
    apiRequest.pipe(take(1), catchError(e => {
      this.isSparklineLoading = false;
      return of(null);
    })).subscribe(trafficTrend => {
      this.isSparklineLoading = false;
      let dataAsTimeAxis: StackedSparklineConfig = [];
      if (trafficTrend && trafficTrend.length > 0) {
        trafficTrend.forEach((trend, index) => {
          if (trend.data.length > 0) {
            dataAsTimeAxis.push({
              label: trend.type,
              trend: trend.data.map(point => ({
                x: point.x,
                y: point.y
              } as TimeAxis))
            });
            if (index === 0) {
              dataAsTimeAxis[0].unit = trend.unit;
            }
          }
        })
        if (dataAsTimeAxis.length > 0) {
          trafficUnit ?
            trafficUnit.unit = dataAsTimeAxis[0].unit :
            trafficUnit = { unit: dataAsTimeAxis[0].unit };
          sparkline = this.sparklineConfigService.getStackedTimeseriesConfig(dataAsTimeAxis, sparkline);
        }
        cdr.markForCheck();
      }
    })
  }
}

