<ng-container *ngIf="node">
  <svg:g class="node" [attr.transform]="nodePosition" (click)="onNodeClick($event)"
         (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave()">
    <!--The nodes circle (the outer element-->
    <svg:circle *ngIf="!isCloud" class="node-circle" [ngClass]="shadowClass" [attr.r]="nodeRadius">
    </svg:circle>

    <!--Top right Circle-->
    <svg:circle *ngIf="withDot" [attr.fill]="dotFill" [ngStyle]="dotStroke" [attr.cx]="gSize*0.75"
                [attr.cy]="topRightDotY" [attr.r]="topRightDotR">
    </svg:circle>

    <!--Top Right Icon-->
    <svg:image *ngIf="topRightIcon" [attr.href]="iconPath" [attr.height]="gSize"
               [attr.width]="gSize" [attr.x]="gSize*.25"
               [attr.y]="-gSize * 1.7"/>


    <!--The link Start dot-->
    <!-- <svg:circle *ngIf="withStartLinkDot" [attr.fill]="startLinkDotFill" [ngStyle]="dotStroke"
        [attr.cx]="-gSize * 1.05" [attr.cy]="gSize * 0.01" [attr.r]="gSize/6">
    </svg:circle> -->
    <!--The link end dot-->
    <!-- <svg:circle *ngIf="withEndLinkDot" [attr.fill]="startLinkDotFill" [ngStyle]="dotStroke" [attr.cx]="gSize * 1.05"
        [attr.cy]="gSize * 0.01" [attr.r]="gSize/6">
    </svg:circle> -->

    <!--The multi clients number text-->
    <svg:text *ngIf="isShowChildrenNumber && !topRightIcon" class="node-label" [attr.x]="gSize * 0.75"
              [attr.y]="-gSize * 0.6"
              [ngStyle]="{'font-size': gSize * 0.4}">
      {{isShowChildrenNumber}}
    </svg:text>

    <!--The Device icon svg-->
    <svg:image *ngIf="!topRightIcon" [attr.href]="iconPath" [attr.height]="isCloud? gSize * 2.25: gSize * 1.25"
               [attr.width]="isCloud? gSize * 2.25: gSize * 1.25" [attr.x]="isCloud? -gSize * 0.1: -gSize * 0.6"
               [attr.y]="isCloud? -gSize * 1.1: -gSize * 0.6"/>

    <!-- Text in the middle of the node -->
    <svg:text *ngIf="showNumberInTheMiddle" class="node-label obscure" [attr.x]="-gSize * .06"
              [attr.y]="textInTheMiddleY"
              [ngStyle]="numberInTheMiddleStyle">
      {{isShowChildrenNumber}}
    </svg:text>


    <!--Above left link-->
    <svg:image *ngIf="isShowAboveLinkIcon" [attr.href]="aboveLinkIcon" [attr.height]="gSize *.8"
               [attr.width]="gSize *.8" [attr.x]="isCloud? -gSize * 1.2: -gSize * 2"
               [attr.y]="-gSize"/>

    <!--Right bottom icon -->
    <svg:image *ngIf="rightBottomIcon" [attr.href]="rightBottomIcon" [attr.height]="gSize * 0.8"
               [attr.width]="gSize * 0.8" [attr.x]="gSize * 0.45" [attr.y]="gSize * 0.35"/>

    <!--warning icon svg-->
    <svg:image *ngIf="warningIconPath" [attr.href]="warningIconPath" [attr.height]="gSize * 0.9"
               [attr.width]="gSize * 0.9" [attr.x]="-gSize * 1.1" [attr.y]="gSize * 0.35"/>

    <svg:circle *ngIf="bottomLeftIcon" [attr.fill]="dotFill" [ngStyle]="dotStroke" [attr.cx]="-gSize * 1.1"
                [attr.cy]="gSize * 0.9" [attr.r]="topRightDotR">
    </svg:circle>

    <!--Bottom Left icon Icon-->
    <svg:image *ngIf="bottomLeftIcon" [attr.href]="bottomLeftIcon" [attr.height]="gSize * 0.9"
               [attr.width]="gSize * 0.9" [attr.x]="-gSize * 1.55" [attr.y]="gSize * 0.4"/>

    <!--The nodes label-->
    <svg:text class="node-label" [ngClass]="textClass" [attr.y]="gSize * 2" [ngStyle]="{'font-size': gSize * 0.65}">
      <tspan> {{node?.data.name}}</tspan>
      <tspan class="vendor-name">{{vendorName}}</tspan>
    </svg:text>
    <!--The device vendor vendor-->
    <!-- <svg:text *ngIf="vendorName" class="node-label" [ngClass]="textClass" [attr.y]="gSize * 3"
        [ngStyle]="{'font-size': gSize * 0.65}">
        ({{vendorName}})
    </svg:text> -->


    <!--
    Imry: 16.4.20:
    The Two next filters are requiered for two statuses: when a node is clicked and un-clicked.
    The only different between them (except the id of course) is the flood-color attribute.
    I couldn't found a way to bind this attribute or change it dynamiclly
    The solution was to create to elements and inject them with the filter property (Look the scss file)
    -->
    <defs>
      <svg:filter id="shadowFilter">
        <svg:feDropShadow flood-opacity="0.7" dx="0" dy="0" [attr.stdDeviation]="gSize / 8"
                          flood-color="#000000"/>
          
      </svg:filter>
    </defs>
    <defs>
      <svg:filter id="clickedShadowFilter">
        <svg:feDropShadow flood-opacity="0.8" dx="0" dy="0" [attr.stdDeviation]="gSize / 8"
                          flood-color="#3794fc"/>
          
      </svg:filter>
    </defs>

  </svg:g>
</ng-container>
