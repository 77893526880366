import {EditPropertiesService} from 'src/app/shared/components/properties/services/edit-properties.service';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {SingleSizeCalculationService} from 'src/app/venues/services/single-size-calculation.service';
import {InitatePropertiesDataService} from 'src/app/shared/components/properties/services/initate-properties-data.service';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {SingleDevice} from 'src/app/shared/models/single-device.model';
import {Subscription} from 'rxjs';
import {ModalTextContent} from 'src/app/shared/modals/models/modal-content.model';
import {FormAction} from 'src/app/shared/modals/models/form-actions.model';
import {TranslateService} from '@ngx-translate/core';
import {D3TreeEventsService} from 'src/app/shared/components/network-topology/services/d3-tree-events.service';
import {ClickedElementService} from 'src/app/shared/services/strategies/clicked-element.service';
import {BaseSingleEntityService} from 'src/app/shared/services/base-single-entity.service';
import {LoggerService} from 'src/app/shared/services/logger.service';
import {StoreKpisBarService} from '../components/kpis-bar/store-kpis-bar.service';
import {GenerateFabricPropertiesService} from "../../properties/services/generate-fabric-properties.service";
import {GenerateVenuePropertiesService} from "../../properties/services/generate-venue-properties.service";
import {VenueData} from "../../../models/venues.model";
import {FabricKpiData} from "../../../models/fabrics.model";
import { MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BaseSingleEntitiesHealth} from "../../single-entities/base-single-entities-health";
import {RestKpiGeneratorService} from "../../kpi-display-components/services/rest-kpi-generator.service";

export class TopologyWithProperties extends BaseSingleEntitiesHealth {
  entityData: FabricKpiData | VenueData | SingleDevice;
  entityType: EntityType;
  singleEntityHealth: number;
  tplgHeight: number;
  tplgWidth: number;
  propTitle: string;
  showTeleport: boolean = false;
  subscription: Subscription[] = [];

  dialogConfig = new MatDialogConfig();
  type = FormAction.LEAVE;
  modalText: ModalTextContent = {
    title: this.translate.instant('data.PROPERTIES.LEAVING_FORM_TITLE'),
    message: this.translate.instant('data.PROPERTIES.LEAVING_FORM_MESSAGE')
  };

  constructor(
    public dialog: MatDialog,
    protected storeKpisBarService: StoreKpisBarService,
    protected editPropertiesService: EditPropertiesService,
    protected globalEntitiesService: GlobalEntitiesService,
    protected singleSizeCalculationService: SingleSizeCalculationService,
    protected restKpiGeneratorService: RestKpiGeneratorService,
    protected preparePropsDataService: InitatePropertiesDataService,
    protected translate: TranslateService,
    protected d3TreeEventsService: D3TreeEventsService,
    protected loggerFactory: LoggerService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected clickedElementService: ClickedElementService,
    protected fabricPropsGenerator: GenerateFabricPropertiesService,
    protected venuePropsGenerator: GenerateVenuePropertiesService
  ) {
    super(dialog, d3TreeEventsService, singleSizeCalculationService, restKpiGeneratorService, editPropertiesService, translate, preparePropsDataService, fabricPropsGenerator, venuePropsGenerator, clickedElementService);
  }

  /**
   * @method calculateTopologySize Calculate topology size in order to make it responsive
   */
  protected calculateTopologySize() {
    let topologySize: { width: number, height: number };
    topologySize = this.singleSizeCalculationService.calculateTopologySize();
    this.tplgWidth = topologySize.width;
    this.tplgHeight = topologySize.height;
  }

  /**
   * @method initEntityData convert the fabric data for the properties data object
   */
  protected initEntityData(data: any) {
    switch (true) {
      case this.entityType == EntityType.FABRIC:
        this.propData = this.fabricPropsGenerator.prepareFabricData(data, this.topology);
        break;
      case this.entityType == EntityType.VENUE:
        this.propData = this.venuePropsGenerator.prepareVenueData(data, this.topology);
      default:
        break;
    }
  }


  get isEditMode() {
    let isEditMode = false;
    const editModeSubsc = this.editPropertiesService.notifyIsEditModeObservable$.subscribe(mode => {
      isEditMode = mode;
    });
    this.subscription.push(editModeSubsc)
    return isEditMode;
  }

}
