<ng-container *ngIf="displayTooltip">
    <div class="tplg-tooltip-container" [ngStyle]="{width: tooltipWidth + 'px' }">
        <app-netop-dialog>
            <app-netop-dialog-title [cssClass]="'tplg-tooltip-title'" [closeIcon]="false"
                (titleClicked)="emitClickedTooltipTitle()">
                {{title}}
            </app-netop-dialog-title>
            <app-netop-dialog-content>
                <div class="tplg-tooltip-content-container" [ngStyle]="{height: tooltipHeight + 'px'}">
                    <ng-content></ng-content>
                </div>
            </app-netop-dialog-content>
        </app-netop-dialog>
    </div>
</ng-container>