export namespace Colors {
  export const SEVERITY_MINOR = '#7dd090';
  export const SEVERITY_MEDIUM = '#f39915';
  export const SEVERITY_HIGH = '#ff8780';
  export const SEVERITY_CRITICAL = '#000000';

  export namespace Highcharts {
    export const SKY_BLUE = '#2caffe';
    export const PURPLE = '#544fc5';
    export const LIGHT_GREEN = '#00e272';
    export const ORANGE = '#fe6a35';
    export const BLUE_GREY = '#6b8abc';
    export const PINK = '#d568fb';
    export const LIGHT_BLUE = '#2ee0ca';
    export const LIGHT_BLUE_2 = '#91e8e1';
    export const RED = '#fa4b42';
    export const TAN = '#feb56a';
    export const BLACK = '#000000';
    export const GREY = '#8C8C8C';

    export const ALL = [
      SKY_BLUE,
      PURPLE,
      LIGHT_GREEN,
      ORANGE,
      BLUE_GREY,
      PINK,
      LIGHT_BLUE,
      LIGHT_BLUE_2,
      RED,
      TAN,
      BLACK,
      GREY
    ];
  }
}
