import {Injectable} from '@angular/core';
import {BasicDevice, SingleDevice, SingleDeviceType} from '../../models/single-device.model';
import {PropertiesContent, PropertiesElementType} from '../../components/properties/models/properties-content';
import {InitatePropertiesDataService} from '../../components/properties/services/initate-properties-data.service';
import {SingleLink, D3Link, BasicLink} from '../../models/single-link.model';
import {BasicTopologyNode, GenericDevice, SingleD3Node} from '../../models/topology';
import {isCloudNode, isDisconnected} from '../../components/network-topology/operators/topology-operators';
import {CloudNode} from '../../models/cloud-network.model';
import {GenerateNodePanelPropsService} from "../../components/properties/services/generate-node-panel-props.service";
import {SingleEntityService} from "../../components/single-entities/single-entity.service";

@Injectable({
  providedIn: 'root'
})
export class ClickedElementService {

  constructor(
    private preparePropData: InitatePropertiesDataService,
    private nodePropsGenerator: GenerateNodePanelPropsService,
    private singleEntityService: SingleEntityService) {
  }

  /**
   * Prepare node data for display at the properties components
   * The method first checks if the clicked node is topology device or disconnected device
   * After the check, it initiate the propData for the properties
   */
  prepareNodeData(clickedNode: SingleD3Node<GenericDevice<any>> | GenericDevice<any>) {
    let propData: PropertiesContent;
    let node: GenericDevice<any>;
    if (!isDisconnected(clickedNode)) {
      node = (clickedNode as SingleD3Node<GenericDevice<any>>).data;
    } else {
      node = (clickedNode as GenericDevice<SingleDevice>);
    }
    let originalDataEntity: BasicDevice | CloudNode;
    isCloudNode(node) ? originalDataEntity = node.originalData.node as CloudNode : originalDataEntity = node.originalData.device as BasicDevice;
    if (originalDataEntity.type !== SingleDeviceType.WWW) {
      propData = {
        headerData: {
          id: +originalDataEntity.id,
          firstColumn: {main: node.name, sub: originalDataEntity.type},
          health: +node.originalData.health,
          issues: this.singleEntityService.calculateNodeAndLinkIssues(node.originalData),
          elementType: isCloudNode(node) ? PropertiesElementType.CloudNode : PropertiesElementType.NODE,
          iconPath: node.iconPath,
          secondaryIconPath: node.secondaryIcon,
          type: originalDataEntity.type,
          healthDegradationReasons: node.originalData.healthDegradationReasons
        },
        mainData: {
          panelElements: this.nodePropsGenerator.generateNodePanelElements(node, originalDataEntity)
        }
      };
      return propData;
    } else {
      propData = {
        headerData: {
          elementType: PropertiesElementType.NODE,
          noDataMessage: 'Internet Connectivity',
          iconPath: node.iconPath,
          type: SingleDeviceType.WWW,

        },
        mainData: null
      };
    }
    return propData;
  }

  prepareLinkData(clickedLink: D3Link) {
    let propData: PropertiesContent;
    const link: SingleLink | BasicLink = clickedLink.data;
    if (link.startDeviceId && link.startDeviceId !== 0 && link.startDeviceId !== '666' &&
      link.endDeviceId && link.endDeviceId !== 0 && link.endDeviceId !== '666'
    ) {
      propData = {
        headerData: {
          elementType: PropertiesElementType.LINK,
          firstColumn: {
            main: link.hasOwnProperty('startPort') ? (link as SingleLink).startPort + '  <-->  ' + (link as SingleLink).endPort : link.description,
            sub: link.type
          },
          iconPath: '/assets/media/netop/link-icon.svg',
          status: link.status
        },
        mainData: {
          panelElements: [
            {key: 'staticInfo', value: this.preparePropData.flattenProperties(link)},
            {key: 'currentStatus', value: this.preparePropData.getLinkCurrentStatue(link)}
          ]
        }
      };
    } else {
      propData = {
        headerData: {
          elementType: PropertiesElementType.LINK,
          noDataMessage: 'Internet Connectivity',
          iconPath: '/assets/media/netop/link-icon.svg',
        },
        mainData: null
      };
    }
    return propData;
  }

}
