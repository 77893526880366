<div class="filter-toggle" [style.width]="'100%'">
  <mat-button-toggle-group
    name="fabrics-toggle"
    aria-label="all fabrics"
    [value]="itemCounts?.all > 0 ? fabricToggles.ALL : null"
    (change)="filterFabrics($event.value)">
    <mat-button-toggle [value]="fabricToggles.ALL" [disabled]="!isLoading && itemCounts && itemCounts.all === 0">
      All <span *ngIf="itemCounts && itemCounts.all">({{itemCounts.all}})</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="fabricToggles.COMPLETE" [disabled]="!isLoading && itemCounts && itemCounts.complete === 0">
      Complete <span *ngIf="itemCounts && itemCounts.complete">({{itemCounts.complete}})</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="fabricToggles.INCOMPLETE" [disabled]="!isLoading && itemCounts && itemCounts.incomplete === 0">
      Incomplete <span *ngIf="itemCounts && itemCounts.incomplete">({{itemCounts.incomplete}})</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
