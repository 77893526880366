import {Injectable} from '@angular/core';
import {GenericTopologyService} from './generic-topology.service';
import {ClientsService} from '../../../services/rest-services/clients.service';
import {GenericDevice, Topology} from '../../../models/topology';
import {TreeConfiguration} from '../models/topology-configuration';
import {ClientDevice, ConnectedClientType, FailedConnection} from '../../../models/clients.model';
import {SingleDevice} from '../../../models/single-device.model';
import {LinkVendorStatus, SingleLink, SingleLinkStatus} from '../../../models/single-link.model';
import { v4 as uuid } from 'uuid';;
import {Logger, LoggerService} from '../../../services/logger.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {VenuesService} from '../../../services/rest-services/venues.service';
import {Page} from '../../../models/page.model';
import {EditStringsService} from '../../../services/strategies/edit-strings.service';

@Injectable({
  providedIn: 'root'
})

export class ClientsTopologyService {
  logger: Logger;
  private notifyClientSelection: BehaviorSubject<ClientDevice[]> = new BehaviorSubject(null);
  notifyClientSelectionAsObservable$ = this.notifyClientSelection.asObservable();

  constructor(private genericTopologyService: GenericTopologyService,
              private clientsService: ClientsService,
              private venuesService: VenuesService,
              private loggerFactory: LoggerService,
              private editStringsService: EditStringsService,
  ) {
    this.logger = this.loggerFactory.getLogger('ClientsTopologyService');
  }

  /**
   * @method addClientsToTopology Initlized the nodes and links array at the configuration object.
   * The function create a new configuration variable in order to activate the ngOnChanges changde detection
   *
   * @param configurationData The Topology configurationData object that would be initlized with the new clients topology
   * @param d3Topologydata The Current d3Topologydata of single devices
   */
  addClientsToTopology(d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>, configurationData: TreeConfiguration) {
    const clientsObservables = forkJoin({
      connectedClient: this.clientsService.getCurrentConnectedVenueClients(),
      failedClients: this.venuesService.getVenueFailedClientsConnection()
    });
    clientsObservables.subscribe({
      next: clientsConnectionsDetails => {
        const isFailed = clientsConnectionsDetails.failedClients.data.length > 0;
        const isConnected = clientsConnectionsDetails.connectedClient.length > 0;
        if (isFailed) {
          this.addFailedClientsToDevices(d3Topologydata, clientsConnectionsDetails.failedClients, configurationData);
        }
        if (isConnected) {
          this.storeTopologyClients(clientsConnectionsDetails.connectedClient);
          this.createNewTopologyDataWithClients(d3Topologydata, configurationData, clientsConnectionsDetails.connectedClient);
        }
        if (!isConnected && !isFailed) {
          this.genericTopologyService.deleteNewTopologyData();
        }
      }
    });
  }

  /**
   * Update new configuration object.
   * Used in case there are failed clients and no new Clients,
   * @param d3Topologydata Updated nodes and links arrays
   * @param configurationData Previous configuration data
   */
  createNewConfigurationDataWithFailed(d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>, configurationData: TreeConfiguration) {
    const confgData = {...configurationData};
    confgData.nodes = d3Topologydata.nodes;
    this.genericTopologyService.saveNewTopologyData(confgData);
  }

  /**
   * pdate new configuration object.
   * Used in case there are failed clients and also new Clients,
   * @param d3Topologydata Updated nodes and links arrays
   * @param configurationData Previous configuration data
   */
  createNewTopologyDataWithClients(d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>, configurationData: TreeConfiguration, connectedClient: ClientDevice[]) {
    let confgData: TreeConfiguration;
    let clientsNewTopology: Topology<GenericDevice<ClientDevice>, SingleLink>;
    let connectedClients: GenericDevice<ClientDevice>[];
    let combinedTopology: Topology<any, any> = null;
    this.logger.debug('Connected Clients: ', connectedClient);
    if (connectedClient && connectedClient.length > 0) {
      connectedClients = this.convertClientsToGenericDevices(connectedClient);
      clientsNewTopology = this.addLinksToClientsDevices(connectedClients, d3Topologydata);
      if (clientsNewTopology) {
        combinedTopology = this.genericTopologyService.addAnotherTopology(d3Topologydata, clientsNewTopology, ConnectedClientType);
        confgData = {...configurationData};
        confgData.nodes = combinedTopology.nodes;
        confgData.links = combinedTopology.links;
        this.genericTopologyService.saveNewTopologyData(combinedTopology);
      } else {
        this.genericTopologyService.deleteNewTopologyData();
      }
    }
  }

  /**
   * @method convertClientsToGenericDevices Convert the clients array into Generic device of clients type array
   * @param  clients The clients array
   */
  private convertClientsToGenericDevices(clients: ClientDevice[]): GenericDevice<ClientDevice> [] {
    const genericDevices: GenericDevice<ClientDevice>[] = clients.map(client => {
      return new GenericDevice<ClientDevice>(
        uuid(),
        this.editStringsService.convertUppercasetoCamelCaseWord(client.connectedClientType),
        client.clientName,
        client
      );
    });
    return genericDevices;
  }

  /**
   * @method addLinksToClientsDevices Return Full topology objects with clients devices and links
   * The method create link for each client and return the full topology
   * @param clients The Client device array
   * @param d3Topologydata The original Single devices topology
   */
  private addLinksToClientsDevices(clients: GenericDevice<ClientDevice> [], d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>) {
    const clientsLinks: SingleLink[] = [];
    let clientsWithLinks: Topology<GenericDevice<ClientDevice>, SingleLink>;
    clients.forEach(client => {
      const newClientLink: SingleLink = {
        id: uuid(),
        type: client.originalData.connectionProperties.connectionType,
        logicalConnections: null,
        origin: LinkVendorStatus.VENDOR,
        status: SingleLinkStatus.OK,
        description: 'Client Link',
        startDeviceId: client.id,
        startPort: null,
        startPortDetails: null,
        endDeviceId: this.findClientsParent(client, d3Topologydata),
        endPort: null,
        endPortDetails: null,
        isOutsideBasicDataSource: true
      };
      if (newClientLink.endDeviceId !== undefined) {
        clientsLinks.push(newClientLink);
      }
    });
    clientsWithLinks = {nodes: clients, links: clientsLinks};
    if (clientsWithLinks.links[0]) {
      return clientsWithLinks;
    }
    return null;
  }

  /**
   * @method findClientsParent Return the Client parent ID, by comparing the serial number on the client and on the nodes array
   * @param client The devices clients array
   * @param d3Topologydata The original topology
   */
  private findClientsParent(client: GenericDevice<ClientDevice>, d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>): string | number {
    let clientParentId;
    d3Topologydata.nodes.forEach(node => {
      if (client.originalData.device && node.originalData.device && node.originalData.device.id == client.originalData.device.id) {
        clientParentId = node.id;
      }
    });
    return clientParentId;
  }

  addFailedClientsToDevices(d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>, failedClients: Page<FailedConnection>, configurationData: TreeConfiguration) {
    if (failedClients) {
      this.logger.debug('Failed Clients: ', failedClients);
      if (d3Topologydata) {
        d3Topologydata.nodes.forEach(node => {
          failedClients.data.forEach(connection => {
            if (node.originalData.device && node.originalData.device.id == connection.deviceId) {
              node.originalData.failedClients ?
                node.originalData.failedClients.push(connection) :
                node.originalData.failedClients = [connection];
            }
          });
        });
        this.createNewConfigurationDataWithFailed(d3Topologydata, configurationData);
      }
    }
  }

  removeFailedClientsFromDevices(d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>, configurationData: TreeConfiguration) {
    if (d3Topologydata) {
      d3Topologydata.nodes.forEach(node => {
        node.originalData.failedClients ? node.originalData.failedClients = null : null;
      });
      this.createNewConfigurationDataWithFailed(d3Topologydata, configurationData);
    }
  }

  storeTopologyClients(connectedClient: ClientDevice[]) {
    this.notifyClientSelection.next(connectedClient);
  }

  emptyStoreTopologyClients() {
    this.notifyClientSelection.next(null);
  }

  /**
   * Return true if one of the current zipper node children has clients.
   */
  isDeviceHasClients(clients: GenericDevice<any>[], device: any) {
    clients.forEach(client => {
      if (ConnectedClientType.isClientMulti(client.type)) {
        client.originalData.forEach(singleClient => {
          if (singleClient.originalData.device.id === device.id) {
            return true;
          }
        })
      } else {
        if (client.originalData.device.id === device.id) {
          return true;
        }
      }
    })
    return false;
  }
}

