import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {GraphTrendConfig} from "../../../components/chartjs-components/models/graph-trend-config";
import {TranslateService} from "@ngx-translate/core";
import {SelectedBarDatasets} from "../../../components/chartjs-components/models/chart-js-events.model";
import {BaseKpiChartDisplay} from "../../../components/single-entities/multi-kpi-action-graph-trend/base-kpi-chart-display";
import {KpiMultiDataService} from "../../../components/chartjs-components/services/kpi-stacked-split-data.service";
import {KpiService} from "../../../services/rest-services/kpi.service";
import {KpiDataService} from "../../../services/strategies/kpi-data.service";
import {KpiGroupedBy, KpiTrend, StackedKpiData} from "../../../models/kpi.model";
import {NgChanges} from "../../../extend-angular-classes/on-changes";
import {VerticalBarData} from "../../../components/chartjs-components/models/vertical-bar-config";
import {TimeSeriesWrapper} from "../../../models/actions.model";
import {KpiBarGeneratorService} from "./kpi-bar-generator.service";

export enum VerticalTrend {
  Vertical,
  Trend
}

@Component({
  selector: 'app-single-kpi-display',
  templateUrl: './single-kpi-display.component.html',
  styleUrls: ['./single-kpi-display.component.scss']
})
export class SingleKpiDisplayComponent extends BaseKpiChartDisplay {
  trendLineConfig: GraphTrendConfig = {datasets: []};
  @Input() kpiTrend: KpiTrend | StackedKpiData[];
  @Input() verticalOrTrend: VerticalTrend;
  @Input() tooltipMinWidth: number;
  VerticalTrend = VerticalTrend;
  noDataMessage: string;
  verticalBarDatasets: VerticalBarData;

  constructor(protected kpiStackedAndSplitDataService: KpiMultiDataService,
              protected kpiService: KpiService,
              protected kpiDataService: KpiDataService,
              protected cdr: ChangeDetectorRef,
              private translate: TranslateService,
              private barGenerator: KpiBarGeneratorService) {
    super(kpiStackedAndSplitDataService, kpiService, kpiDataService, cdr)
    this.noDataMessage = this.translate.instant('data.COMMON.NO_GRAPH_DATA_TO_DISPLAY')
  }

  ngOnChanges(changes: NgChanges<SingleKpiDisplayComponent>): void {
    if (changes.kpiTrend && this.kpiTrend) {
      this.creatDisplayDataset();
    }
  }

  /**
   * CReate the config for relevant disaply
   * @private
   */
  private creatDisplayDataset() {
    if (this.verticalOrTrend === VerticalTrend.Trend) {
      this.createTrendLineConfig();
    }
    if (this.verticalOrTrend === VerticalTrend.Vertical) {
      this.createVerticalBarDataset();
    }
  }

  onBarHovered(event: SelectedBarDatasets) {
    this.barHovered.emit({kpiType: this.kpiType, selectedDataset: event});
  }

  /**
   * Create config for GraphTrend component
   * @private
   */
  private createTrendLineConfig() {
    this.toggleSelection = KpiGroupedBy.Failure_Step;
    this.trendLineConfig = this.kpiStackedAndSplitDataService.setTrendLineConfig(this.kpiTrend, this.trendLineConfig, this.kpiType, this.toggleSelection);
  }

  /**
   * Create Config for Vertical Bar dataset
   * @private
   */
  private createVerticalBarDataset() {
    this.verticalBarDatasets = this.barGenerator.generateBarDataset(this.kpiTrend);
  }
}
