import { Directive, HostListener } from '@angular/core';
import { D3Service } from '../services/d3.service';

@Directive({
  selector: '[appZoomOutButton]'
})
export class ZoomOutButtonDirective {

  constructor(private d3Service: D3Service) { }

  @HostListener('click') onZoomOutClick() {
    this.d3Service.applyZoomButtonsEvent("out");
  }
}
