import {SingleDeviceType} from './single-device.model';
import {SingleLinkType, SingleLinkStatus} from './single-link.model';
import {GenericDevice} from './topology';

// DEPRECATED
export class LegacyClient {
  id: string;
  integrationPointId: number;
  clientName: string;
  connectedClientType: ConnectedClientType;
  count: number;
  firstSeen: string;
  lastSeen: string;
  mac: string;
  ip: string;
  ip6: string;
  isOnline: boolean;
  os: string;
  recentDeviceMac: string;
  recentDeviceName: string;
  recentDeviceSerial: string;
  recv: number;
  sent: number;
  ssid: string;
  status: ClientStatusType;
  vlan: string;
  clientType: ConnectedClientType;
  manufacturer: string;
  description: string;
}

export class Client {
  vendor: string;
  mac: string;
  hostname: string;
  ip: string;
  osName: string;
  manufacturer: string;
  firstSeen: Date;
  lastSeen?: Date;
  networkDeviceId: string;
  networkDeviceName: string;
  networkId: string;
  integrationId: string;
  ssid: string;
  totalReceivedBytes: number;
  totalSentBytes: number;
  totalTrafficUsage: number;
  uniqueId: string;
  vlanId: string;
}

export class ClientTrafficSummary {
  id: number;
  description: string;
  ip: string;
  mac: string;
  totalSentBytes: number;
  totalReceivedBytes: number;
}

export type ClientStatusType = 'offline' | 'online';

export enum ConnectedClientType {
  MOBILE = "MOBILE",
  MultiMOBILE = "MultiMOBILE",
  LAPTOP = "LAPTOP",
  MultiLAPTOP = "MultiLAPTOP",
  UNKNOWN = "UNKNOWN",
  MultiUNKNOWN = "MultiUNKNOWN",
  DESKTOP = "DESKTOP"
}

export namespace ConnectedClientType {
  export function isClientMulti(type: ConnectedClientType) {
    return type === ConnectedClientType.MultiLAPTOP || type === ConnectedClientType.MultiMOBILE || type === ConnectedClientType.MultiUNKNOWN;
  }

  export function getLowerCaseSingleClientDeviceType(clientDeviceType: ConnectedClientType) {
    if (clientDeviceType.toLowerCase().includes('unknown')) {
      return 'unknown';
    }
    let formattedType: string;
    for (let key in ConnectedClientType) {
      if (ConnectedClientType[key] == clientDeviceType) {
        let typeKey = key;
        let clientTypeAsString = clientDeviceType.toString();
        formattedType = clientTypeAsString.toLowerCase();
        if (typeKey.toLowerCase().includes("multi"))
          formattedType = formattedType.substring(0, formattedType.length - 1);
      }
    }
    return formattedType;
  }
}

export class ClientQueriesParam {
  recentDeviceSerial: string;
  online: boolean;
}

export class DeviceInstance {
  id: string | number;
  description: string;
  name: string;
  vendorName: string;
  networkId?: string;
  model: string;
  venueId?: number;
  lat: number;
  lon: number;
  createdAt: string | number;
  serialNumber: string;
  type: SingleDeviceType;
  properties?: {}
}

export class ClientData {
  connectionProperties: {
    connectionStatus: SingleLinkStatus,
    connectionType: SingleLinkType,
    received: number,
    rssi: number,
    sent: number,
    ssid: string,
    vlan: string
  };

  deviceId: string | number;
  mac: string;
  os: string;
  manufacturer: string;
  unknown: boolean;
  online: boolean;
  firstSeen: string;
  lastSeen: string;
  connectedClientType: ConnectedClientType;
  clientName: string;
}

export class ClientDevice extends ClientData {
  device: DeviceInstance;
}

export class DeviceClientGroup {
  device: DeviceInstance;
  clients: ClientData[];
}

export enum RssiLabels {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red"
}

export namespace RssiLabels {
  export function getRssilabelsAsArray() {
    return [
      RssiLabels.GREEN,
      RssiLabels.YELLOW,
      RssiLabels.RED,
    ]
  }

  export function getRssiLegendText() {
    return [
      "0 <> 80db",
      "-81 <> 100db",
      "-101db <> "
    ]
  }

  export function getInfinityAsString() {
    return String.fromCharCode(0x221E);
  }
}

export class rssiClientTable {
  [RssiLabels.GREEN]: { key: string, value: string };
  [RssiLabels.YELLOW]: { key: string, value: string };
  [RssiLabels.RED]: { key: string, value: string };
}

export class rssiIconData {
  iconColor: RssiLabels;
  iconPath: string;
  numberOfClients?: number;
  dotColorPath?: string;
}

export class rssiClientsData {
  [RssiLabels.GREEN]: { count: number, clients: GenericDevice<ClientDevice>[] };
  [RssiLabels.YELLOW]: { count: number, clients: GenericDevice<ClientDevice>[] };
  [RssiLabels.RED]: { count: number, clients: GenericDevice<ClientDevice>[] };
}

export class FailedConnection {
  clientMac: string;
  device: string;
  deviceId: number;
  failureStep: string;
  id: string;
  kpiName: string;
  networkId: string;
  organizationId: string;
  serial: string;
  ssidNumber: number;
  tenantId: number;
  time: string;
  ts: number;
  type: string;
  vlan: number;
}
