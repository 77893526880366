export function getRange(from: number, to: number): number[] {
  const numbers: number[] = [];
  for (let i = from; i <= to; i++) {
    numbers.push(i);
  }
  return numbers;
}

export function arrayMax(values: number[]): number {
   return values.reduce((a, b) => a > b ? a : b);
}

export function arrayMin(values: number[]): number {
  return values.reduce((a, b) => a < b ? a : b);
}

export function arrayMean(values: number[]): number {
  return values.reduce((a, b) => a + b) / values.length;
}

export function randomIntBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function roundToNearest(value: number, nearest: number = 1): number {
  return Math.ceil(value / nearest) * nearest
}
