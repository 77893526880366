<ngx-gauge [type]="ngxGaugeConfiguration?.type"
[value]="ngxGaugeConfiguration?.value"
[max]="ngxGaugeConfiguration?.max"
[min]="ngxGaugeConfiguration?.min"
[append]="ngxGaugeConfiguration?.appendText"
[thick]="ngxGaugeConfiguration?.thick"
[size]="ngxGaugeConfiguration?.size"
[thresholds]="ngxGaugeConfiguration?.thresholdConfig"
[cap]="ngxGaugeConfiguration?.cap"
>
<ngx-gauge-label>
<div [id]="labelId">{{ngxGaugeConfiguration?.label}}</div>
</ngx-gauge-label>
</ngx-gauge>

<span class="gauge-scale-min">{{ngxGaugeConfiguration?.min}}</span>
<span class="gauge-scale-max">{{ngxGaugeConfiguration?.max}}</span>
