import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import {
  FabricSummary,
  FabricAdvancedParameters,
  CreatFabricParameters,
  FabricKpiData
} from '../../models/fabrics.model';
import { HttpClient } from '@angular/common/http';
import { GlobalEntitiesService } from './global-entities.service';
import { IAppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { concatMap, switchMap, mergeMap, take } from 'rxjs/operators';
import { Topology } from 'src/app/shared/models/topology';
import { ChangedProperties } from '../../components/properties/models/changed-properties';
import { SingleDevice } from '../../models/single-device.model';
import { FabricEditableKeys } from '../../components/properties/models/editableFabricKeys';
import { SingleLink } from '../../models/single-link.model';

@Injectable({
  providedIn: 'root'
})
export class FabricsService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getFabricMetaData(): Observable<FabricKpiData> {
    return combineLatest(this.currentEntity$, this.tenantId$).pipe(
      take(1),
      mergeMap(([currentEntitiy, tenantID]) => this.http.get<FabricKpiData>(`tenants/${tenantID}/fabrics/${currentEntitiy.id}/kpis/`)))
  }

  /**
   * @method getFabricTopology send the api request for the single fabric topology.
   * Return: observable of FabricTopology class
   */
  getFabricTopology(): Observable<Topology<SingleDevice, SingleLink>> {
    return combineLatest(this.currentEntity$, this.tenantId$).pipe(
      take(1),
      mergeMap(([entity, tenantID]) => this.http.get<Topology<SingleDevice, SingleLink>>(`tenants/${tenantID}/fabrics/${entity.id}/topology/`))
    )
  }

  fetchTenantFabrics(): Observable<Array<FabricSummary>> {
    return this.tenantId$.pipe(
      take(1),
      switchMap((tenantId) =>
        this.http.get<Array<FabricSummary>>(`tenants/${tenantId}/fabrics/summary`)
      )
    )
  }

  fetchFabricsByTenantId(tenantId: number): Observable<Array<FabricSummary>> {
    return this.http.get<Array<FabricSummary>>(`tenants/${tenantId}/fabrics/summary`)
  }

  updateFabricConfigurationEntities(changedProperties: ChangedProperties[]): Observable<any> {
    return this.currentEntity$.pipe(
      take(1),
      switchMap((entity) =>
        this.http.put(`fabric/${entity.id}/configurationEntities`, changedProperties)
      ))
  }

  updateFabricConfigurationEntitiesByFabricId(changedProperties: ChangedProperties[], fabricId: number): Observable<any> {
    return this.http.put(`fabric/${fabricId}/configurationEntities`, changedProperties)
  }

  //There is no other functions that uses backupNow resource,
  //Therefore, Ffgured that this method sould be here be, with other fabrics httprequests
  postFabricSyncNow(): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      concatMap((tenantId) =>
        this.http.post(`tenants/${tenantId}/backupNow/`, {})
      )
    )
  }

  /**
   * Get advanced fabric parameters
   */
  fetchFabricAdvancedParameters(fabricId: number): Observable<FabricAdvancedParameters> {
    return this.tenantId$.pipe(
      take(1),
      concatMap((tenantId) =>
        this.http.get<FabricAdvancedParameters>(`tenants/${tenantId}/fabrics/${fabricId}/advancedParameters`)
      )
    )
  }

  // The API for this function does not exist
  // Keeping it for future use

  // createFabricIntent(tenantId: number | string, name: string, password: string = ''): Observable<any> {
  //   const body = {
  //     name: 'create_guest_network',
  //     properties: {
  //       name,
  //     }
  //   };
  //   if (password && password !== '') {
  //     body['psk'] = password;
  //   }
  //   return this.http.post(`businessIntents/intent/${tenantId}`, body);
  // }
  getFabricMetaDataById(fabricId): Observable<FabricKpiData> {
    return this.tenantId$.pipe(
      take(1),
      switchMap((tenantID) => this.http.get<FabricKpiData>(`tenants/${tenantID}/fabrics/${fabricId}/kpis/`)))
  }

  /**
   * @method getFabricTopology send the api request for the single fabric topology.
   * Return: observable of FabricTopology class
   */
  getFabricTopologyById(fabricId): Observable<Topology<SingleDevice, SingleLink>> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<Topology<SingleDevice, SingleLink>>(`tenants/${tenantID}/fabrics/${fabricId}/topology/`)))
  }

  createFabric(fabricParameters: CreatFabricParameters): Observable<any> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<Observable<any>>(`businessIntents/intent/${tenantID}/create-fabric`,
        fabricParameters
      )));
  }

  /**
   * @method getFabricTopology send the api request for the single fabric topology.
   * Return: observable of FabricTopology class
   */
  getFabricEditableKeys(): Observable<FabricEditableKeys> {
    return combineLatest(this.currentEntity$, this.tenantId$).pipe(
      take(1),
      mergeMap(([entity, tenantID]) => this.http.get<FabricEditableKeys>(`tenants/${tenantID}/fabrics/${entity.id}/editableFields`))
    )
  }

  /**
   * @method getFabricTopology send the api request for the single fabric topology.
   * Return: observable of FabricTopology class
   */
  getSelectedFabricEditableKeys(fabricId: number): Observable<FabricEditableKeys> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<FabricEditableKeys>(`tenants/${tenantID}/fabrics/${fabricId}/editableFields`)))
  }

  createNewRule(lastParameters: any) {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<Observable<any>>(`businessIntents/intent/${tenantID}/create-policy`,
        lastParameters
      )));
  }
}


