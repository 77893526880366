import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringAsTemplateRefPipe } from './pipes/string-as-template-ref/string-as-template-ref.pipe';
import { BypassSecurityPipePipe } from './pipes/bypass-security.pipe';
import { FileNameToStringArray } from './pipes/file-name-to-camel.pipe';
import { EnumUpperToLowerPipe } from './pipes/enum-upper-to-lower.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { FormattedCamelPipe } from './pipes/formatted-camel.pipe';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { RemoveIndexPipe } from './pipes/remove-index.pipe';
import { CamelToUpperPipe } from './pipes/camel-to-upper.pipe';
import { LowerNoSpacesPipe } from './components/properties/properties-content/lower-no-spaces.pipe';
import { RemoveSimiliarKeyPipe } from './pipes/remove-similiar-key.pipe';
import { RemoveIndexAfterNamePipe } from './pipes/remove-index-after-name.pipe';
import { LimitStringLengthPipe } from './pipes/limit-string-length.pipe';
import {ProgressBarConvertorPipe} from "./pipes/top-traffic-data-pipes/progress-bar-convertor.pipe";
import {TopTrafficTotalPipe} from "./pipes/top-traffic-data-pipes/top-traffic-total.pipe";
import {SeparateUnderscorePipe} from "./pipes/separate-underscore.pipe";
import { RemoveObjectMarkersPipe } from './pipes/remove-object-markers.pipe';
import { UsersToClientsPipe } from './components/entity-dashboard/anomalies/anomalies-category-list/users-to-clients.pipe';
import {LastCharsPipe} from "./pipes/last-chars.pipe";
import { CustomEmailValidatorDirective } from './pipes/email-validator.pipe';

const pipes = [
  StringAsTemplateRefPipe,
  BypassSecurityPipePipe,
  FileNameToStringArray,
  EnumUpperToLowerPipe,
  CapitalizePipe,
  FormattedCamelPipe,
  MomentDatePipe,
  RemoveIndexPipe,
  CamelToUpperPipe,
  LowerNoSpacesPipe,
  RemoveSimiliarKeyPipe,
  RemoveIndexAfterNamePipe,
  LimitStringLengthPipe,
  ProgressBarConvertorPipe,
  TopTrafficTotalPipe,
  SeparateUnderscorePipe,
  RemoveObjectMarkersPipe,
  UsersToClientsPipe,
  LastCharsPipe,
  CustomEmailValidatorDirective
]
@NgModule({
  declarations: [
    pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [pipes],
  providers: [pipes]
})
export class SharedPipesModule { }
