import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import {SingleLink, D3Link, SingleLinkType} from 'src/app/shared/models/single-link.model';
import * as d3 from 'd3';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {D3TreeEventsService} from '../../../../services/d3-tree-events.service';
import {isLink} from '../../../../operators/topology-operators';
import {TreeConfiguration, TopologyConfiguration} from '../../../../models/topology-configuration';
import {D3LinkService} from '../../../../services/d3-link.service';
import {Topology} from "../../../../../../models/topology";
import {SingleDevice} from "../../../../../../models/single-device.model";


@Component({
  selector: '[linkVisual]',
  templateUrl: './link-visual.component.html',
  styleUrls: ['./link-visual.component.scss']
})
export class LinkVisualComponent {
  @Input('linkVisual') link: D3Link;
  @Input('topologyConfigruation') topologyConfigruation: TopologyConfiguration;
  @Input('treeConfiguration') treeConfiguration: TreeConfiguration;
  @Input() selectedFabricTopology: Topology<SingleDevice, SingleLink>;

  width: number;
  linkClicked: boolean = false;

  constructor(
    private healthColorsService: HealthColorsService,
    private d3TreeEventsService: D3TreeEventsService,
    private d3LinkService: D3LinkService) {
  }

  ngOnInit() {
    this.subscribed3TreeSelection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.link && this.topologyConfigruation && this.treeConfiguration) {
      this.width = this.treeConfiguration.width;
      this.markLinksAsClickedBasedOnInput();
    }
  }

  private markLinksAsClickedBasedOnInput() {
    if (this.topologyConfigruation.selectedFabricTopology) {
      if (this.topologyConfigruation.selectedFabricTopology.links.find(fabricLink => this.d3LinkService.isLinkConnectSameNodes(fabricLink, this.link)) != undefined) {
        this.linkClicked = true;
      } else {
        this.linkClicked = false;
      }
    }
  }

  /**
   * Subsribe to node selection observable.
   * Initiate ths nodeClicked property
   */
  subscribed3TreeSelection() {
    if (this.topologyConfigruation && !this.topologyConfigruation.isFabricsVenue) {
      this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(element => {
        if (element && isLink(element) && this.d3LinkService.d3SelectedLinkSamsAstopologyLink((element as D3Link), this.link))
          this.applyClickedElementChanges();
        else
          this.cancelClickedElementChanges();
      })
    }
  }

  applyClickedElementChanges() {
    this.linkClicked = true;
  }

  cancelClickedElementChanges() {
    this.linkClicked = false;
  }

  onLinkClick() {
    this.d3LinkService.onLinkClick(this.link, this.topologyConfigruation);
  }

  get linkPath() {
    return this.d3LinkService.getLinkPath(this.link, this.width, this.treeConfiguration.pathStyle)
  }

  get linkColor() {
    return this.healthColorsService.getLinkStatusColors(this.link.data.status);
  }

  get linkDash() {
    if (this.link.data.type == SingleLinkType.Wireless)
      return 'dashed-link';
  }

  get linkWidth() {
    if (this.linkClicked)
      return '2px';
  }
}
