import {KpiDataset, KpiGroupedBy, KpiType, TrafficUnits} from "../../../models/kpi.model";
import {GraphTrendConfig} from "../../chartjs-components/models/graph-trend-config";
import {KpiMultiDataService} from "../../chartjs-components/services/kpi-stacked-split-data.service";
import {KpiService} from "../../../services/rest-services/kpi.service";
import {KpiDataService} from "../../../services/strategies/kpi-data.service";
import {ChangeDetectorRef, Directive, EventEmitter, Input, Output} from "@angular/core";
import {SelectedBarDatasets} from "../../chartjs-components/models/chart-js-events.model";

@Directive()
export class BaseKpiChartDisplay {
  @Input() kpiType: KpiType;
  @Input() graphWidth;
  @Input() graphHeight;
  @Output() toggleSelected: EventEmitter<KpiGroupedBy> = new EventEmitter<KpiGroupedBy>();
  isLoading: boolean;
  toggleButtons = [KpiGroupedBy.Traffic, KpiGroupedBy.Wired, KpiGroupedBy.Application];
  kpiGroupedBy = KpiGroupedBy;
  isAppSelected: boolean = false;
  @Input() unit: TrafficUnits;
  @Input() minX = undefined;
  @Input() maxX = undefined;
  @Input() maxTicksX: number = 4;
  @Input() maxTicksY: number = 5;
  @Input() isResponsive: boolean = true;
  @Input() hideTooltip: boolean = false;
  @Input() timeDisplayFormat: string = 'DD-MMM-YYYY HH:mm';
  stackedAndSplitData: { splitGraphConfig: GraphTrendConfig, stackedGraphConfig: GraphTrendConfig };
  protected toggleSelection: KpiGroupedBy = KpiGroupedBy.Traffic;
  @Output() barHovered: EventEmitter<KpiDataset> = new EventEmitter<KpiDataset>();


  constructor(
    protected kpiStackedAndSplitDataService: KpiMultiDataService,
    protected kpiService: KpiService,
    protected kpiDataService: KpiDataService,
    protected cdr: ChangeDetectorRef) {
  }

  onBarHovered(event: SelectedBarDatasets) {
    this.barHovered.emit({kpiType: this.kpiType, selectedDataset: event});
  }
  get isShowToggle() {
    return this.isTraffic;
  }

  get isAppDisplay() {
    return this.toggleSelection === KpiGroupedBy.Application;
  }

  get isStacked() {
    return (this.kpiType === KpiType.Connection_Failures || this.kpiType == KpiType.Traffic || this.kpiType === KpiType.Throughput) && this.toggleSelection === KpiGroupedBy.Application;
  }

  get isTraffic() {
    return this.kpiType == KpiType.Traffic;
  }
  get graphStepSize() {
    if (this.kpiType === KpiType.Clients || this.kpiType === KpiType.Connection_Failures)
      return 1;
  }

  get hideLegend() {
    if (this.kpiType === KpiType.Traffic || this.kpiType === KpiType.Throughput || this.kpiType === KpiType.Connection_Failures) {
      return false;
    }
    return true;
  }
}
