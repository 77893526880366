import { Directive, Input, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HoriznotalTimeSpan } from '../models/horizontal-time-span.model';

@Directive({
  selector: '[timelineMarker]'
})
export class TimelineMarkerDirective {
  @Input() timelineMarker: HoriznotalTimeSpan;
  @Input() barHeight: number;


  constructor(private _ren: Renderer2, private _el: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnChanges() {
    this._ren.setStyle(this._el.nativeElement, 'left', `${this.timelineMarker.marker.markerWidthPercentage}%`);
    this._ren.setStyle(this._el.nativeElement, 'height', `${this.barHeight * 3.75}px`);
    this.cdr.detectChanges();
  }
}
