import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MomentDatePipe} from 'src/app/shared/pipes/moment-date.pipe';
import {StateManagerService} from 'src/app/core/services/state-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {IGetRowsParams, IDatasource, ColDef} from 'ag-grid-community';
import {LoggerService, Logger} from 'src/app/shared/services/logger.service';
import {SeverityCellRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/severity-cell-renderer/severity-cell-renderer.component';
import {ActionsMenuRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/actions-menu-renderer.component';
import {of, Subscription} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {SeverityService} from 'src/app/shared/services/strategies/severity.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {dashboardRowClassRules} from 'src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers';
import {GridSortFilter} from 'src/app/shared/models/sort-filter/grid-sort-filter.model';
import {TenantsService} from 'src/app/shared/services/rest-services/tenants.service';
import {BaseDashboardEntityList} from 'src/app/shared/components/entity-dashboard/entity-list/base-dashboard-entity-list';
import {HealthCellRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/health-cell-renderer/health-cell-renderer.component';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {StoreUserPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {StoreWidgetPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {D3NodeService} from 'src/app/shared/components/network-topology/services/d3-node.service';
import {DevicesHealth} from 'src/app/shared/models/devices.model';
import {
  IconWithTextRendererComponent,
  TextAndPath
} from 'src/app/shared/ag-grid/cell-renderers/icon-with-text-renderer/icon-with-text-renderer.component';
import {GridTypes} from "../../../../shared/models/client-storage.model";

@Component({
  selector: 'app-devices-health-list',
  templateUrl: './devices-health-list.component.html',
  styleUrls: ['./devices-health-list.component.scss']
})
export class DevicesHealthListComponent extends BaseDashboardEntityList implements OnInit {
  private logger: Logger; components; columnDefs;
  devicesHealth: DevicesHealth[];

  dataSource: IDatasource = {
    //rowCount: null,
    getRows: (params: IGetRowsParams) => {
      // TODO;
      // Use startRow and endRow for sending pagination to Backend
      // params.startRow : Start Page
      // params.endRow : End Page
      // use this.searchText to filter the data source
      this.tenantsService.fetchDevicesHealth(new GridSortFilter(params, this.searchText, this.numberOfRawsInPage)).pipe(
        take(1),
        catchError(error => {
          this.isLoading = false;
          return of(null);
        })
      ).subscribe(data => {
        this.isLoading = false;
        this.devicesHealth = data.data;
        this.loadData(this.logger, params, data);
      }, () => params.failCallback());
    }
  };
  currentEntitySubscr: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private momentDatePipe: MomentDatePipe,
    // TODO: replace by global resize service
    private stateManagerService: StateManagerService,
    private tenantsService: TenantsService,
    private loggerFactory: LoggerService,
    private d3NodeService: D3NodeService,
    private severityService: SeverityService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private globalEntitiesService: GlobalEntitiesService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService
  ) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, GridTypes.DEVICES_HEALTH);
    this.logger = this.loggerFactory.getLogger("DevicesHealthListComponent");
    // Query server on search changed
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`)
      this.searchText = searchText;
      // Call your function which calls API or do anything you would like do after a lag of 1 sec
      this.gridApi.setDatasource(this.dataSource)
    });
    this.currentEntitySubscr = this.globalEntitiesService.currentEntity$.subscribe((entity) => {
      this.logger.debug("DevicesHealthListComponent current entity changed")
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource)
      }
    })
    this.context = {componentParent: this, enableCellHover: true};
    this.components = {
      severityCellRenderer: SeverityCellRendererComponent,
      actionsMenuRenderer: ActionsMenuRendererComponent,
      healthCellRenderer: HealthCellRendererComponent,
      iconWithTextRenderer: IconWithTextRendererComponent

    };
  }

  /**
   * Click on tenant will take us to the tenant page
   */
  gotToDevice(event: any) {
    if (event.type === 'rowClicked') {
      // TODO: do something
      this.logger.debug(`device row clicked`)
      this.tenantsService.tenantId$.pipe(take(1)).subscribe(
        (tenantId) => {
          this.router.navigate(['/device', event.data.id], {relativeTo: this.activatedRoute}).catch();
        }
      )
    }
  }

  ngOnInit() {
    let columnDefs = [
      {
        /**
         * Severity column
         * The severity column header is needed to show the sort arrow
         * the text is hidden by transparent color.
         */
        headerName: 'Severity',
        //headerClass: 'ag-grid-hidden-header',
        width: 50,
        // Keep the field name as it is usefull in sorting i.e. it sets
        // in the datasource -> sortModel object the colId property which
        // usefull to be passed to the server side.
        field: "severity",
        // Show severity icon using renderer
        cellRenderer: "severityCellRenderer",
        sortable: true,
        sort: "asc",
      },
      {
        headerName: this.translate.instant('data.COMMON.SERIAL_NUMBER'),
        field: 'serialNumber',
        width: 100,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.DEVICE'),
        field: 'deviceName',
        width: 100,
        sortable: true,
        cellRenderer: "iconWithTextRenderer",
      },
      {
        headerName: this.translate.instant('data.COMMON.HEALTH'),
        field: 'health',
        cellRenderer: "healthCellRenderer",
        width: 70,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.VENDOR_NAME'),
        field: 'vendorName',
        width: 70,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.DEVICE_MODEL'),
        field: 'model',
        width: 70,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.AFFFECTED_FABRICS'),
        field: 'affectedFabrics',
        width: 70,
        sortable: true,
      }
    ];
    this.columnDefs = columnDefs;
    this.rowClassRules = dashboardRowClassRules;


    this.logger.debug("in ngOnInit of TenantVenuesListComponent")
  }

  get textWithIconPath(): TextAndPath[] {
    if (this.devicesHealth && this.devicesHealth.length > 0) {
      return this.devicesHealth.map(device => {
        return {text: device.deviceName, iconPath: this.d3NodeService.getInitialIconPath(device.type)}
      });
    }
  }

  ngOnDestroy(): void {
    this.currentEntitySubscr.unsubscribe()
    this.cancelSubscription();
  }
}
