<!--Dialog Title-->
<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog(false)" [closeIconClass]="'col-no-flex'">
        {{'data.ORG_TYPES.ADD_ORG_TYPE'|translate}}
    </app-netop-dialog-title>
    <app-netop-dialog-content class="org-type-dialog-content" [cssClass]="'org-type-dialog-content'">
        <app-organization-types-manager [propsCss]="'prop-section-dialog'" [currentOrg]="dialogData.orgData"
            (typesChanged)="closeDialog(true)">
        </app-organization-types-manager>
    </app-netop-dialog-content>
</app-netop-dialog>