import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NetworkTopologyComponent} from './network-topology.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {InlineSVGModule} from 'ng-inline-svg';
import {FormsModule} from '@angular/forms';
import {DisconnectedDevicesComponent} from './components/disconnected-devices/disconnected-devices.component';
import {MaterialModule} from '../../material.module';
import {TranslateModule} from '@ngx-translate/core';
import {TopologyTooltipComponent} from './tooltips/topology-device-tooltip/topology-tooltip.component';
import {NetopDialogModule} from '../../modals/netop-dialog/netop-dialog.module';
import {SingleDeviceTooltipComponent} from './tooltips/single-device-tooltip/single-device-tooltip.component';
import {SingleClientDeviceTooltipComponent} from './tooltips/single-client-device-tooltip/single-client-device-tooltip.component';
import {MultiClientsTooltipComponent} from './tooltips/multi-clients-tooltip/multi-clients-tooltip.component';
import {RssiBarComponent} from './tooltips/rssi-bar/rssi-bar.component';
import {MultiClientsTableComponent} from './tooltips/multi-clients-table/multi-clients-table.component';
import {TopologyTooltipsListComponent} from './tooltips/topology-tooltips-list/topology-tooltips-list.component';
import {D3TreeGraphComponent} from './d3-topology/display/d3-tree-graph/d3-tree-graph.component';
import {NodeVisualComponent} from './d3-topology/display/shared/node-visual/node-visual.component';
import {LinkVisualComponent} from './d3-topology/display/shared/link-visual/link-visual.component';
import {D3ZoomableDirective} from './directives/d3-zoomable.directive';
import {ZoomInButtonDirective} from './directives/zoom-in-button.directive';
import {ZoomOutButtonDirective} from './directives/zoom-out-button.directive';
import {TopologyHeaderComponent} from './components/topology-header/topology-header.component';
import {TopologyMainComponent} from './components/topology-main/topology-main.component';
import {TopologyFooterComponent} from './components/topology-footer/topology-footer.component';
import {SingleDisconnectedDeviceComponent} from './components/disconnected-devices/single-disconnected-device/single-disconnected-device.component';
import {RssiIconStylerDirective} from './tooltips/directives/rssi-icon-styler.directive';
import {CloudNodeTooltipComponent} from "./tooltips/cloud-node-tooltip/cloud-node-tooltip.component";
import {HeaderClientsDisplayComponent} from "./components/topology-header/components/header-clients-display/header-clients-display.component";
import {SharedDirectivesModule} from "../../shared-directives.module";
import {SharedComponentsModule} from "../../shared-components.module";


@NgModule({ declarations: [
        NetworkTopologyComponent,
        DisconnectedDevicesComponent,
        TopologyTooltipComponent,
        SingleDeviceTooltipComponent,
        SingleClientDeviceTooltipComponent,
        MultiClientsTooltipComponent,
        RssiBarComponent,
        MultiClientsTableComponent,
        TopologyTooltipsListComponent,
        D3TreeGraphComponent,
        NodeVisualComponent,
        LinkVisualComponent,
        D3ZoomableDirective,
        ZoomInButtonDirective,
        ZoomOutButtonDirective,
        TopologyHeaderComponent,
        TopologyMainComponent,
        TopologyFooterComponent,
        SingleDisconnectedDeviceComponent,
        RssiIconStylerDirective,
        CloudNodeTooltipComponent,
        HeaderClientsDisplayComponent,
    ],
    exports: [NetworkTopologyComponent],
    bootstrap: [NetworkTopologyComponent], imports: [FormsModule,
        MaterialModule,
        AngularSvgIconModule,
        CommonModule,
        TranslateModule,
        InlineSVGModule,
        NetopDialogModule,
        SharedDirectivesModule,
        SharedComponentsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class NetworkTopologyModule {
}
