<app-portlet>
  <app-portlet-body class="portlet-body-entity-list with-kpi-bar">
    <div class="row row-container">
      <div class="col tplg-col">
        <app-network-topology [treeData]="topology" [title]="topologyTitle" [tplgWidth]="tplgWidth"
                      [isPropertiesOpen]="isPropertiesOpen"
                      [tplgHeight]="tplgHeight" [isClients]="isShowClients" [isWidget]="true">
          <app-single-sync-status topologyLeftFotter [venueData]="venueData">
          </app-single-sync-status>
        </app-network-topology>
      </div>
      <div class="col properties-col col-flex-3" *ngIf="isPropertiesOpen">
        <app-legacy-properties *ngIf="venueData" [title]="propTitle" [propertiesData]="propData"
                        (specialItemStatusChanged)="showTeleport = $event" (closeIconClicked)="isPropertiesOpen=false">
        </app-legacy-properties>
        <app-subnet-properties-display [subnets]="subnets"
                                       [showTeleport]="showTeleport"></app-subnet-properties-display>
      </div>
    </div>
  </app-portlet-body>
</app-portlet>
