<div class="row row-no-side-margin">
  <div class="col">
    <div [showSpinner]="isLoading">
      <app-kpis-trends *ngIf="kpiTrends" [kpiTrends]="kpiTrends" [height]="145" [width]="450"
                       [isResponsive]="true" [isShowStacked]="false" [annotations]="annotations"
                       [timeDisplayFormat]="timeDisplayFormat" [maxTicksY]="3" [maxX]="undefined" [minX]="undefined"
                       [hideTooltip]="true">
      </app-kpis-trends>
    </div>
  </div>
  <div class="col dev-rep-bars-container-col-direction">
    <div class="dev-rep-top-container">
      <div class="dev-rep-bars-container-with-back">
        <h5 class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_DEVICES' | translate}}
          {{topClients$ | async | totalFromTop}}</h5>
        <div class="teleported-back-to-venue"></div>
      </div>
      <div class="dev-rep-progress-list">
        <ng-container
          *ngFor="let progressBar of topClients$ | async | progressBarConvertor : fullColorsList[5]">
          <div class="just-flex dev-rep-dialog-progress-bar-container">
            <app-basic-progress-bar [progressBar]="progressBar">
            </app-basic-progress-bar>
          </div>
        </ng-container>
        <ng-container *ngIf="!(topClients$ | async | progressBarConvertor)">
          <div class="dev-rep-error-message-text">
            {{'data.ERRORS.NO_DATA' | translate}}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="dev-rep-top-container">
      <h5 class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_APPS' | translate}}
        {{topApps$ | async | totalFromTop}}</h5>
      <div class="dev-rep-progress-list">
        <ng-container *ngFor="let progressBar of topApps$ | async | progressBarConvertor : fullColorsList[6]">
          <div class="just-flex dev-rep-dialog-progress-bar-container">
            <app-basic-progress-bar [progressBar]="progressBar">
            </app-basic-progress-bar>
          </div>
        </ng-container>
        <ng-container *ngIf="!(topApps$ | async | progressBarConvertor)">
          <div class="dev-rep-error-message-text">
            {{'data.ERRORS.NO_DATA' | translate}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
