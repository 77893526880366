import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Auth0Client, Auth0ClientOptions, createAuth0Client } from "@auth0/auth0-spa-js";
import { BehaviorSubject, Observable } from "rxjs";
import { Auth0Profile } from "src/app/auth/IBaseAuthService";
import { environment } from "src/environments/environment";
import { EnvironmentType } from "src/environments/environment.model";
import { DisplayErrorService } from "./display-error.service";

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private profileSubject = new BehaviorSubject<any>(null);
  private readonly auth0Client: Promise<Auth0Client>;

  constructor(private readonly router: Router,
    private displayErrorService: DisplayErrorService,
  ) {
    const config: Auth0ClientOptions = {
      domain: environment.domain,
      clientId: environment.client_id,
      authorizationParams: {
        redirect_uri: environment.redirect_uri,
        defaultScope: 'openid email'
      },
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    };

    if (!EnvironmentType.isOnPrem(environment.environmentType)) {
      this.auth0Client = createAuth0Client(config);
      this.auth0Client.then(() => this.resetProfile());
    }
  }

  private async resetProfile() {
    const client = await this.auth0Client;
    const isAuthenticated = await client.isAuthenticated();
    const profile = isAuthenticated ? await client.getUser() : null;
    this.profileSubject.next(profile);
    return profile;
  }

  async handleLoginCallback(): Promise<any> {
    const client = await this.auth0Client;
    return client.handleRedirectCallback().then(result => {
      const targetRoute = result.appState && result.appState.target ? result.appState.target : '';
      this.resetProfile();

      return targetRoute;

    }).catch(result => {
      this.displayErrorService.openMessage(result);
      setTimeout(() => {
        this.logout();
      }, 2000);
    })
  }

  get profile$(): Observable<Auth0Profile> {
    return this.profileSubject.asObservable();
  }

  get currentProfile(): Auth0Profile {
    return this.profileSubject.value;
  }

  async isAuthenticated(): Promise<boolean> {
    const client = await this.auth0Client;
    return client.isAuthenticated();
  }

  async getToken(): Promise<string> {
    try {
      const client: any = await this.auth0Client,
        raw = await client.getTokenSilently(),
        token = await client.getIdTokenClaims(),
        access_token = token?.__raw;
      return access_token;
    }
    catch (e) {
      return null;
    }
  }

  async showLogin(nextUrl = '/') {
    const client = await this.auth0Client,
      user = await client.loginWithRedirect({
        authorizationParams: { redirect_uri: environment.redirect_uri }
      });
    this.router.navigateByUrl(nextUrl);
  }

  async reLogin() {
    return this.resetProfile();
  }

  async logout() {
    const client = await this.auth0Client;
    return client.logout({
      clientId: environment.client_id
    });
  }
}
