<ag-grid-angular [ngStyle]="{height: '100%'}" style="width: 100%" class="entity-list ag-theme-material"
                 rowSelection="single" suppressCellFocus="false" [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 (gridReady)="onGridReady($event)"
                 suppressHorizontalScroll="true"
                 (modelUpdated)="onGridReady($event)" [components]="components"
                 [overlayLoadingTemplate]="'&nbsp;'" [sortingOrder]="['asc', 'desc']" [rowClassRules]="rowClassRules"
                 [icons]="icons"
                 rowModelType="infinite" [rowHeight]="rowHeight" [headerHeight]="headerHeight" [debug]="debugGrid"
                 (sortChanged)="onSortChange($event)" (dragStopped)="onColDragEnd($event)"
                 (viewportChanged)="onViewPortChanges($event)" (bodyScroll)="bodyScroll($event)" [context]="context"
                 [noRowsOverlayComponent]="noRowsOverlayComponent"
                 [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
                 (columnResized)="onColumnResized($event)" [tooltipShowDelay]="0">
</ag-grid-angular>
