import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalEntitiesService } from './global-entities.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { Observable } from 'rxjs';
import { CreatePolicyIntent, ExportPolicyDTO } from '../../models/policies.model';
import { take, mergeMap } from 'rxjs/operators';
import { Entity } from '../../models/entity.model';



@Injectable({
  providedIn: 'root'
})
export class PolicyService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getPoliciesByEntity(entity: Entity): Observable<ExportPolicyDTO[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<ExportPolicyDTO[]>(`policies/tenant/${tenantId}/${entity.type}/${entity.id}`)
      )
    )
  }

  createPolicy(lastParameters: CreatePolicyIntent) {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.post<Observable<any>>(`businessIntents/intent/${tenantID}/create-policy`,
        lastParameters
      )));
  }

}
