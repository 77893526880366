import { IncidentStatus } from './../../models/incidents.model';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {User, UserDetails, UserRole} from '../../models/users.model';
import {IAppState} from 'src/app/store/state/app.state';
import {Store} from '@ngrx/store';
import {GlobalEntitiesService} from './global-entities.service';
import {map, mergeMap, take} from 'rxjs/operators';
import {VenuesHealth} from '../../models/venues.model';
import {DevicesHealth, DeviceState} from '../../models/devices.model';
import {FabricsHealth} from '../../models/fabrics.model';
import {NewTenant, Tenant, TenantDetails} from '../../models/tenants.model';
import {EntityType} from '../../models/entity-type.enum';
import {BaseSortFilter} from '../../models/sort-filter/base-sort-filter.model';
import {Page} from '../../models/page.model';
import {ChangeLog} from '../../models/change-log.model';
import {
  DisplayVenueVPN,
  VenueVPN,
  VPNConnectionMetric,
  VPNConnectionMetricData,
  VPNConnectionMetricTrend,
  VPNConnectionStatus,
  VPNdata,
  VpnStatisticsDTO,
  VPNStatus,
  VPNStatusTrendDTO,
  VPNSummary,
  VPNTraffic
} from '../../models/vpn.model';
import {KpiType, TrafficKpiDataDisplay, TrafficValueAndSize} from "../../models/kpi.model";
import {KpiDataService} from "../strategies/kpi-data.service";
import moment from 'moment';
import {TimeManagerService} from "../time-manager.service";
import {TimeUnit} from "../../models/time.model";
import { IncidentSummaryGrouping, IncidentSummary, IncidentSeverity } from '../../models/incidents.model';

function convertDtoToTenant(dto: any): Tenant {
  // Add type to the tenant entity
  dto["type"] = EntityType.TENANT
  return dto
}

@Injectable({
  providedIn: 'root'
})
export class TenantsService extends GlobalEntitiesService {
  constructor(
    private http: HttpClient, store: Store<IAppState>,
    private dateConvertor: TimeManagerService,
    private kpiDataService: KpiDataService) {
    super(store)
  }

  createNewTenant(tenantData: NewTenant): Observable<TenantDetails> {
    return this.http.post<TenantDetails>(`tenants/`, tenantData).pipe(
      map((tenant) => convertDtoToTenant(tenant) as TenantDetails)
    )
  }

  updateTenant(tenantData: TenantDetails) {
    let data = {...tenantData}
    // remove the entity type which is a part of the Entity class
    delete data["type"]
    return this.http.put<TenantDetails>(`tenants/`, tenantData)
  }

  fetchTenant(tenantId: number): Observable<TenantDetails> {
    return this.http.get<TenantDetails>(`tenants/${tenantId}`).pipe(
      map((tenant) => convertDtoToTenant(tenant) as TenantDetails)
    )
  }

  fetchTenantsUsersByTenantId(tenantId: number): Observable<Array<User>> {
    return this.http.get<Array<User>>(`tenants/${tenantId}/users`)
  }

  fetchTenantsUsers(): Observable<Array<User>> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<Array<User>>(`tenants/${tenantId}/users`)));
  }

  inviteUserToTenant(tenantId: number, userDetails: UserDetails): Observable<any> {
    return this.http.post(`tenants/${tenantId}/invite`, userDetails).pipe(take(1))
  }

  fetchTenantsUserRoles(): Observable<Array<UserRole>> {
    // For now just return just the admin role instead of quering the server
    // to make the
    return this.http.get<Array<UserRole>>(`tenants/roles`);
  }

  /**
   * The prefix for the service url with dashboar/health is a server side bug
   * The actual place of the tenant data is here, in tenants service
   * @param sortAndFilter
   */
  fetchFabricsHealth(sortAndFilter: BaseSortFilter): Observable<Page<FabricsHealth>> {
    //debugger
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<Page<FabricsHealth>>(sortAndFilter.appendToURL(`dashboard/health/tenants/${tenantId}/fabrics-health`))
      )
    )
  }

  fetchDevicesHealth(sortAndFilter: BaseSortFilter): Observable<Page<DevicesHealth>> {
    sortAndFilter.sortBy ||= 'criticalIncidentSeverity';
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<Page<DevicesHealth>>(sortAndFilter.appendToURL(`dashboard/health/tenants/${tenantId}/devices-health`))
      )
    )
  }

  fetchVenuesHealth(tenantId: number,
                    sortAndFilter: BaseSortFilter,
                    timeBack: number = 1,
                    timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<VenuesHealth>> {
    sortAndFilter.sortBy ||= 'criticalIncidentSeverity';
    return this.http.get<Page<VenuesHealth>>(sortAndFilter.appendToURL(`dashboard/health/tenants/${tenantId}/venues-health?timeBack=${timeBack}&timeUnit=${timeUnit}`));
  }

  fetchCurrentVenuesHealth(sortAndFilter: BaseSortFilter,
                           timeBack: number = 1,
                           timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<VenuesHealth>> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) => this.fetchVenuesHealth(tenantId, sortAndFilter, timeBack, timeUnit))
    );
  }

  getTenantStatus(tenantId): Observable<any> {
    return this.http.get(`tenants/${tenantId}/sync-statuses`)
  }

  getTenantsSyncChanges(sortAndFilter: BaseSortFilter): Observable<Page<ChangeLog>> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<Page<ChangeLog>>(sortAndFilter.appendToURL(`tenants/${tenantId}/changeLog`))
      ),
      map(changes => {
        changes.data = changes.data.map(datum => new ChangeLog(datum.time, datum.venueId, datum.venueName, datum.changes));
        return changes;
      })
    )
  }

  /**
   * Return Combined data from 3 vpn Api:
   * 1. VPN Connections
   * 2. VPN Traffic
   * 3. VPN statuses
   */
  getVpnData(): Observable<VPNdata> {
    return forkJoin({
      vpnConnections: this.getCurrentVenueVpnConnections(),
      vpnTraffic: this.getVPNTrafficData(),
      vpnStatuses: this.getVPNConnectionStatuses()
    })
  }

  getVpnSummary(): Observable<VPNSummary> {
    return forkJoin({
      vpnConnections: this.getVPNTenantConnections(),
      vpnStatuses: this.getVPNConnectionStatuses()
    });
  }

  getVenueVpnConnections(venueId: number): Observable<DisplayVenueVPN[]> {
    return this.http.get<VenueVPN[]>(`venues/${venueId}/vpn`).pipe(
      map(connections => {
        let displayConnections: DisplayVenueVPN[] = [];
        connections.forEach(connection => displayConnections.push({data: connection}));
        return displayConnections;
      })
    );
  }

  getCurrentVenueVpnConnections(): Observable<DisplayVenueVPN[]> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap((currentVenue) => this.getVenueVpnConnections(currentVenue.id))
    );
  }

  getVPNTenantConnections(): Observable<DisplayVenueVPN[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<VenueVPN[]>(`tenants/${tenantId}/vpn`)),
      map(conncetions => {
        let displayConnections: DisplayVenueVPN[] = [];
        conncetions.forEach(connection => {
          displayConnections.push({data: connection})
        })
        return displayConnections;
      })
    )
  }

  getVPNTrafficData(): Observable<VPNTraffic[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<VPNTraffic[]>(`tenants/${tenantId}/vpn/traffic`)))
  }

  getVPNConnectionStatuses(): Observable<VPNStatus> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<VPNStatus>(`tenants/${tenantId}/vpn/status`))
    )
  }

  getVPNStatistics(): Observable<VpnStatisticsDTO[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<VpnStatisticsDTO[]>(`vpn/tenant/${tenantId}/statistics`))
    )
  }

  getVPNConnectionStatusTrend(connectionId: number, fromDate: number = 7, specificDate: string = undefined): Observable<VPNStatusTrendDTO<VPNConnectionStatus>[]> {
    let timeUnit = "DAYS";
    let isoDate: string;
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date());
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<VPNStatusTrendDTO<VPNConnectionStatus>[]>(`vpn/tenant/${tenantId}/connection/${connectionId}/status/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}`))
    )
  }

  getVPNConnectionStatusTrendById(connectionId: number, tenantId: number, fromDate: number = 7, specificDate: string = undefined): Observable<VPNStatusTrendDTO<VPNConnectionStatus>[]> {
    let timeUnit = "DAYS";
    let isoDate: string;
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date());
    return this.http.get<VPNStatusTrendDTO<VPNConnectionStatus>[]>(`vpn/tenant/${tenantId}/connection/${connectionId}/status/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}`);
  }

  getVPNConnectionTrafficTrend(connectionId: number, fromDate: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS, metricName: VPNConnectionMetric = VPNConnectionMetric.Traffic): Observable<TrafficKpiDataDisplay[]> {
    let isoDate: string;
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date());
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantId) =>
        this.http.get<TrafficKpiDataDisplay[]>(`vpn/tenant/${tenantId}/connection/${connectionId}/statistics/trend?metricName=${metricName}&timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}`)),
    );
  }

  getVPNConnectionTrafficTrendById(connectionId: number, tenantId: number, fromDate: number = 7, specificDate: string = undefined): Observable<TrafficKpiDataDisplay[]> {
    let timeUnit = "DAYS";
    let isoDate: string;
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date());
    return this.http.get<TrafficKpiDataDisplay[]>(`vpn/tenant/${tenantId}/connection/${connectionId}/statistics/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}`);
  }

  getVpnConnectionMetricData(
    tenantId: number,
    connectionId: number,
    timeBack: number,
    specificDate: string,
    timeUnit: TimeUnit,
    metricName: VPNConnectionMetric,
    deviceIds: (number|string)[] = []): Observable<VPNConnectionMetricData> {
    return this.http.get<VPNConnectionMetricData>(`vpn/tenant/${tenantId}/connection/${connectionId}/statistics/trend?timeBack=${timeBack}&timeUnit=${timeUnit}&specificDate=${specificDate}&metricName=${metricName}&deviceIds=${deviceIds.join(',')}`);
  }

  getVpnConnectionMetricTrends(
    tenantId: number,
    connectionId: number,
    timeBack: number,
    specificDate: string,
    timeUnit: TimeUnit,
    metricName: VPNConnectionMetric,
    deviceIds: (number|string)[] = []): Observable<VPNConnectionMetricTrend[]> {
    return this.getVpnConnectionMetricData(tenantId, connectionId, timeBack, specificDate, timeUnit, metricName, deviceIds).pipe(
      map(data => {
        const trends: VPNConnectionMetricTrend[] = [];
        Object.keys(data).forEach(key => {
          const keyValues = key.split(' ');
          const metricName = keyValues[0] as VPNConnectionMetric;
          const deviceConnection = keyValues.length == 2 ? keyValues[1] : '';
          trends.push({
            key,
            metricName,
            deviceConnection,
            data: data[key].map(entry => ({
              date: moment(entry.date),
              value: entry.value
            }))
          });
        });
        return trends;
      })
    );
  }

  getCurrentVpnConnectionMetricTrends(connectionId: number,
                                      timeBack: number,
                                      specificDate: string,
                                      timeUnit: TimeUnit,
                                      metricName: VPNConnectionMetric,
                                      deviceIds: (number|string)[] = []): Observable<VPNConnectionMetricTrend[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap(tenantId => this.getVpnConnectionMetricTrends(tenantId, connectionId, timeBack, specificDate, timeUnit, metricName, deviceIds))
    );
  }

  private formatVPNConnectionTrendToDisplayDTO(vpnTrend: TrafficKpiDataDisplay) {
    let valueAndUnit: TrafficValueAndSize = this.kpiDataService.findGroupedTrendsUnits(vpnTrend);
    let vpnGroupedTrends: TrafficKpiDataDisplay[] = [];
    for (let [groupName, groupTrends] of Object.entries(vpnTrend)) {
      vpnGroupedTrends.push(
        {
          type: groupName,
          data: (groupTrends as Array<any>).map((point: any) => {
            return {
              x: moment(point.date),
              y: +this.kpiDataService.divideByTrafficUnit(point.value, valueAndUnit.size, KpiType.Traffic).toFixed(2)
            }
          }),
          unit: valueAndUnit.size
        })
    }
    return vpnGroupedTrends;
  }
}
