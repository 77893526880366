import {Injectable} from '@angular/core';
import {LegacySeverity} from '../models/severity.model';
import {Theme} from '../models/theme.model';
import {ActionCategory} from '../models/actions.model';
import {AnomalyCategory} from "../models/anomalies.model";
import {HealthIndicator} from "../models/health.model";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  readonly CATEGORY_NUMBER = 7;

  constructor() {
  }

  readonly HEALTH_COLORS: string[] = [
    '#F7685B',
    '#FFB946',
    '#2ED47A'
  ];
  readonly ACTIONS_CATEGORY_COLORS = {
    [ActionCategory.Operational]: '#1F78B4',
    [ActionCategory.Security]: '#C2BCE6',
    [ActionCategory.Performance]: '#A6CEE3',
    [ActionCategory.Compliance]: '#8A7DD0',
    [ActionCategory.Configuration]: '#f1cfb1',
  };
  readonly HEALTH_INDICATOR_COLORS = {
    SEVERITY_HIGH: 'severity-high',
    SEVERITY_MEDIUM: 'severity-medium',
    SEVERITY_LOW: 'severity-low'
  };
  readonly SEVERITY_COLORS: string[] = [
    '#000000',
    '#ff8780',
    '#f39915',
    '#7dd090'
  ];
  readonly HEALTH_CHANGES: string[] = [
    '#5FCCCC',
    '#58B8F1',
    '#9E82E2',
    '#467FCA'
  ];
  readonly ANOMALY_CATEGORY_COLORS = {
    [AnomalyCategory.Devices]: '#1f78b4',
    [AnomalyCategory.PacketLoss]: '#58b8f1',
    [AnomalyCategory.Traffic]: '#81afff',
    [AnomalyCategory.Latency]: '#8a7dd0',
    [AnomalyCategory.VpnStatus]: '#a6cee3',
    [AnomalyCategory.VpnTraffic]: '#c1d4ed',
  };

  getHealthTheme() {
    return {
      css: {
        [LegacySeverity.High]: 'health-bad',
        [LegacySeverity.Medium]: 'health-medium',
        [LegacySeverity.Minor]: 'health-good',
      },
      icons: {
        [LegacySeverity.High]: 'severity-high-item-icon',
        [LegacySeverity.Medium]: 'severity-medium-item-icon',
        [LegacySeverity.Minor]: 'severity-low-item-icon',
      },
      colors: {
        // higher the severity is lower the health is:
        // higher issues severity means that the health is bad
        // and vise versa
        [LegacySeverity.High]: this.HEALTH_COLORS[0],
        [LegacySeverity.Medium]: this.HEALTH_COLORS[1],
        [LegacySeverity.Minor]: this.HEALTH_COLORS[2],
      }
    };
  }

  getPriorityTheme(): Theme {
    return {
      css: {
        1: 'priority-high',
        2: 'priority-medium',
        3: 'priority-low'
      },
      icons: {},
      colors: {}
    };
  }

  getCategoryTheme(): Theme {
    return {
      css: {},
      icons: {
        [ActionCategory.Operational]: 'category-item-icon-operational',
        [ActionCategory.Compliance]: 'category-item-icon-compliance',
        [ActionCategory.Performance]: 'category-item-icon-performance',
        [ActionCategory.Security]: 'category-item-icon-security',
      },
      colors: {
        // ILL_TENANTS_FROM: 'rgba(16, 156, 241, 0.7)',
        // ILL_TENANTS_TO: 'rgba(16, 156, 241, 0)',
        // ALL_TENANTS: '#B7BCBE',
        // Category (Values Coming from server)
        [ActionCategory.Operational]: this.ACTIONS_CATEGORY_COLORS[0],
        [ActionCategory.Compliance]: this.ACTIONS_CATEGORY_COLORS[1],
        [ActionCategory.Performance]: this.ACTIONS_CATEGORY_COLORS[2],
        [ActionCategory.Security]: this.ACTIONS_CATEGORY_COLORS[3],
      }
    };
  }

  getSeverityTheme(): Theme {
    return {
      css: {
        [LegacySeverity.Critical]: 'severity-critical',
        [LegacySeverity.High]: 'severity-high',
        [LegacySeverity.Medium]: 'severity-medium',
        [LegacySeverity.Minor]: 'severity-low',
        [LegacySeverity.Zero]: 'severity-graph-zero',
      },
      icons: {
        [LegacySeverity.Critical]: 'severity-critical-item-icon',
        [LegacySeverity.High]: 'severity-high-item-icon',
        [LegacySeverity.Medium]: 'severity-medium-item-icon',
        [LegacySeverity.Minor]: 'severity-low-item-icon',
      },
      colors: {
        [LegacySeverity.Critical]: this.SEVERITY_COLORS[0],
        [LegacySeverity.High]: this.SEVERITY_COLORS[1],
        [LegacySeverity.Medium]: this.SEVERITY_COLORS[2],
        [LegacySeverity.Minor]: this.SEVERITY_COLORS[3]
      }
    };
  }

  /**
   * Apply new theme
   * SOURCE: https://coryrylan.com/blog/theming-angular-apps-with-css-custom-properties
   */
  applyUserBrandingSelection(theme: {}) {
    if (theme) {
      this.setTheme(theme);
    }
  }

  /**
   * Apply the new theme on css variables
   * @param theme The current theme object
   */
  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
