<ng-container *ngIf="disConnectedDevices && disConnectedDevices[0]">
    <div>
        <header class="dis-devices-header-container">
            <mat-list>
                <mat-list-item>
                    <span class="dis-devices-header">{{'data.TOPOLOGY.DISCONNECTED_DEVICES' | translate}}
                        ({{disConnectedDevices.length}})</span>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-list>
        </header>
        <section>
            <div class="row dis-device-row" [ngClass]="isDevice? 'single-device-screen-row' : null">
                <div class="col dis-device-container" *ngFor="let device of disConnectedDevices">
                    <app-single-disconnected-device [device]="device" [isDevice]="isDevice"
                        [isFabricsVenue]="isFabricsVenue"></app-single-disconnected-device>
                </div>
            </div>
        </section>
    </div>
</ng-container>