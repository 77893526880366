import {Injectable} from '@angular/core';
import {ThemeService} from "../../../services/theme.service";
import {TranslateService} from "@ngx-translate/core";
import {TimeManagerService} from "../../../services/time-manager.service";
import {VerticalBarData} from "../../../components/chartjs-components/models/vertical-bar-config";
import {isStacked, KpiTrend, StackedKpiData} from "../../../models/kpi.model";
import {ColorsUtils} from "../../../global-utils/colors-utils";

@Injectable({
  providedIn: 'root'
})
export class KpiBarGeneratorService {

  constructor(
    private themeService: ThemeService,
    private translate: TranslateService,
    private dateConvertor: TimeManagerService) {
  }

  generateBarDataset(trend: KpiTrend | StackedKpiData[]): VerticalBarData {
    let data: VerticalBarData = {
      datasets: [],
      labels: this.getFormattedDates(trend)
    };
    if (isStacked(trend)) {
      trend.forEach((dataset, index) => {
        data.datasets.push({
          data: (dataset as StackedKpiData).data.map(datum => datum.y),
          backgroundColor: ColorsUtils.hexToRgb(this.themeService.HEALTH_CHANGES[index]),
          label: (dataset as StackedKpiData).type,
          tooltipText: (dataset as StackedKpiData).data.map(datum => `${(dataset as StackedKpiData).type}: ${datum.y.toString()}`),
          tooltipTitle: [''],
          barThickness: this.getSingleBarThickness(trend as StackedKpiData[]),
        })
      })
    }
    //Imry: 23.6.21: In case this component will be used for GraphTrendDisplay - Uncomment and make adjustment (For label property)
    // if (!isStacked(trend)) {
    //   data.datasets.push({
    //     data: (trend as KpiTrend).map(datum => datum.value),
    //     backgroundColor: '#109CF1',
    //     label: 'Data',
    //     tooltipText: (trend as KpiTrend).map(datum => datum.value.toString()),
    //     tooltipTitle: [''],
    //     barThickness: trend.length > 20 ? 13 : 15,
    //   })
    // }
    return data;
  }

  /**
   * Return the dates array.
   * For display reasons, only part of the dates will be displayed
   */
  getFormattedDates(trend: KpiTrend | StackedKpiData[]): string[] {
    const dates = isStacked(trend) ?
      (trend as StackedKpiData[])[0].data.map(datum => datum.x) :
      (trend as KpiTrend).map(datum => datum.datetime);
    const format: string = 'HH:mm';
    return dates.map((date, index) => {
      return this.dateConvertor.dateByFormat(date, format);
    })
  }

  /**
   * Return single bar thickness for stacked data
   * @param trend
   * @private
   */
  private getSingleBarThickness(trend: StackedKpiData[]) {
    let longestDatasetLength: number = 0;
    trend.forEach(dataset => {
      if (dataset.data.length > longestDatasetLength) {
        longestDatasetLength = dataset.data.length;
      }
    })
    return longestDatasetLength >= 20 ? 8 : 15;
  }
}
