import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {InsightSummary} from "../../../models/actions.model";
import {InsightSummaryDisplay} from "../../../ag-grid/cell-renderers/analyze-pop-over/models/analyze-pop-over.model";
import {CamelToUpperPipe} from "../../../pipes/camel-to-upper.pipe";
import {NgChanges} from "../../../extend-angular-classes/on-changes";
import {InsightResolutionActions, InsightResolutionActionsMode} from "./insight-resolution-display.model";
import {LegacySeverity} from "../../../models/severity.model";

@Component({
  selector: 'app-insight-resolutions-display',
  templateUrl: './insight-resolutions-display.component.html',
  styleUrls: ['./insight-resolutions-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightResolutionsDisplayComponent {
  @Input() summary: InsightSummary[];
  @Input() rootCauses: string [] = [];
  @Input() solutions: string[] = []
  @Input() iconsName: InsightResolutionActionsMode[] = [];
  @Input() insightsDisplayClass: string;

  @Output() actionSelected: EventEmitter<InsightResolutionActions> = new EventEmitter();
  insightSummary: InsightSummaryDisplay[] = [];

  constructor(private camelToUpperPipe: CamelToUpperPipe) {
  }

  ngOnChanges(changes: NgChanges<InsightResolutionsDisplayComponent>) {
    if (this.isSummary) {
      this.generateSummaryDisplay();
    }
  }

  private generateSummaryDisplay() {
    this.summary.forEach(insight => {
      this.insightSummary.push(
        new InsightSummaryDisplay(this.getInsightCss(insight.severity), this.camelToUpperPipe.transform(insight.category), insight.shortDesc)
      );
    });
  }

  getInsightCss(severity: LegacySeverity) {
    if (severity.toString() === 'Critical') {
      return `severity-high`;
    }
    return `severity-${severity.toString().toLowerCase()}`;
  }

  onActionSelected(actionWithMode: InsightResolutionActionsMode) {
    if (!actionWithMode.isDisabled)
      this.actionSelected.emit(actionWithMode.action);
  }

  get isSummary() {
    return this.summary && this.summary.length > 0;
  }

  get isRootCauses() {
    return this.cleanRootCauses && this.cleanRootCauses.length > 0;
  }

  get isSolutions() {
    return this.cleanSolutions && this.cleanSolutions.length > 0;
  }

  get cleanRootCauses() {
    return this.rootCauses.filter(cause => cause);
  }

  get cleanSolutions() {
    return this.solutions.filter(solution => solution);
  }
}
