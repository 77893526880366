/***
 * Draw a circle above each dataset.
 * Dedicated for VerticalBarComponent
 * The circle radius derived from the barThickness param
 * SOURCES:
 * 1. https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/ellipse
 * 2. https://starbeamrainbowlabs.com/blog/article.php?article=posts%2F135-easy-circles-with-context-ellipse.html
 */
import {BarWithAboveLabelData, VerticalBarThemeConfig} from "../models/vertical-bar-config";
import {Chart} from "chart.js";

/**
 * The method generate circle above relevant bars, according to the aboveLabelValue props
 * The algorithm works as follows:
 * 1. Assign the BarWithAboveLabelData Object by screening each dataset array
 * 2. Calculate the total aboveLabels for each bar
 * 3. Draw the circle above the highest yValue at each bar
 */
export function drawAboveBarLabel(chart: Chart<any>, args, options) {
  const barThickness = +chart.data.datasets[0].barThickness;
  const chartInstance = chart;
  const ctx = chartInstance.ctx as CanvasRenderingContext2D;
  let barWithAboveLabelData: BarWithAboveLabelData[] = [];
  if (chart.data.datasets && chart.data.datasets.length > 0) {
    if ((chart.data.datasets[0] as VerticalBarThemeConfig).aboveBarLabel && (chart.data.datasets[0] as VerticalBarThemeConfig).aboveBarLabel.length > 0) {
      (chart.data.datasets[0] as VerticalBarThemeConfig).aboveBarLabel.forEach((bar, barIndex) => {
        barWithAboveLabelData.push({});
        chart.data.datasets.forEach((dataset, datasetIndex) => {
          if (!(dataset as VerticalBarThemeConfig).isMockedDataset) {
            barWithAboveLabelData[barIndex][datasetIndex] = {
              aboveLabelValue: (dataset as VerticalBarThemeConfig).aboveBarLabel[barIndex],
              yValue: (dataset as VerticalBarThemeConfig).data[barIndex]
            }
          }
        })
      })
    }
  }
  barWithAboveLabelData.forEach((barData, barIndex) => {
    let highestDatasetIndex: number;
    let highestDatasetY: number = 0;
    let totalAboveLabelActions: number = 0;

    for (const [key, value] of Object.entries(barData)) {
      const meta = chartInstance.getDatasetMeta(+key);
      if (value.aboveLabelValue && !meta.hidden) {
        totalAboveLabelActions += value.aboveLabelValue
      }
      if (value.yValue > highestDatasetY) {
        if (!meta.hidden) {
          highestDatasetY = value.yValue;
          highestDatasetIndex = +key;
        }
      }
    }

    if (highestDatasetIndex !== undefined) {
      let meta = chartInstance.getDatasetMeta(highestDatasetIndex);
      const highestBarData = meta.data[barIndex];
      const x = highestBarData.x;
      const y = highestBarData.y;
      const radius = totalAboveLabelActions >= 10 ? barThickness * 1.3 : barThickness / 1.1;
      if (totalAboveLabelActions > 0) {
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle'
        ctx.fillStyle = "#ff8780";
        ctx.beginPath();
        ctx.ellipse(x, y, radius, radius, 0, 0, Math.PI * 2, false);
        ctx.fill();
        ctx.fillStyle = "#ffffff";
        ctx.font = `Helvetica, Arial, sans-serif ${radius * 2 * .8}px`;
        ctx.save();
        ctx.restore();
        ctx.fillText(totalAboveLabelActions.toString(), x, y);
      }
    }
  });
}


export function drawFakeLegend(chart) {
  const barThickness = chart.data.datasets[0].barThickness;
  const chartInstance = chart;
  const ctx = chartInstance.ctx as CanvasRenderingContext2D;
  if (chart.data.datasets.find(dataset => dataset.aboveBarLabel !== undefined)) {
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chartInstance.controller.getDatasetMeta(i);
      const bar = meta.data[meta.data.length - 1]
      const x = bar._model.x;
      const y = bar._model.y;
      const realY = 17;
      const xCircleReducer = 55;
      const xTextReducer = 25;
      const radius = barThickness / 1.1;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      ctx.fillStyle = "#f7685b";
      ctx.beginPath();
      ctx.ellipse(x - xCircleReducer, realY, radius, radius, 0, 0, Math.PI * 2, false);
      ctx.fill();
      ctx.fillStyle = "#9b9b9b";
      ctx.fillText('Open', x - xTextReducer, 10);
    });
  }
}
