import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-with-ellipse',
  templateUrl: './icon-with-ellipse.component.html',
  styleUrls: ['./icon-with-ellipse.component.scss']
})
export class IconWithEllipseComponent implements OnInit {
  @Input() iconPath: string;
  @Input() secondaryIconPath: string;
  @Input() ellipseClass: string;
  @Input() iconClass: string;
  @Input() dotClass: string;

  constructor() { }

  ngOnInit() {
  }
}
