import { Component } from '@angular/core';
import { TrafficOrigin } from '../../../../../../models/legacy-reports.model';


@Component({
  selector: 'app-vpn-deviation-bar',
  templateUrl: './vpn-deviation-bar.component.html',
  styleUrls: ['./vpn-deviation-bar.component.scss']
})
export class VpnDeviationBarComponent {
  origin: TrafficOrigin = TrafficOrigin.VPN_CONN;
}
