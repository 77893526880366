import {Injectable, Injector} from '@angular/core';
import {
  ClientStorageDatasource,
  ClientStorageEntities, ClientUserPreferencesData,
} from "../../models/client-storage.model";
import {FirebaseUserPreferencesService} from "./firebase-services/firebase-user-preferences.service";
import {LocalStorageUserPreferencesService} from "./local-storage-services/local-storage-user-preferences.service";
import {Auth0Service} from "../auth0.service";
import {EnvironmentType} from "../../../../environments/environment.model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SaveUserClientStorageService {
  private readonly environmentType: EnvironmentType = environment.environmentType;

  constructor(
    private injector: Injector
  ) {
  }

  /**
   * Prepare user selected org preferences for saving in outer Storage
   * @param clientSelection
   * @param currentGrid
   * @param prefKey
   * @param prefValue
   */
  prepareUserPreferences(clientSelection: ClientUserPreferencesData, currentGrid: string, prefKey: string, prefValue: any) {
    if (clientSelection && clientSelection.preferences) {
      clientSelection.preferences[currentGrid] ?
        clientSelection.preferences[currentGrid][prefKey] = prefValue :
        clientSelection.preferences[currentGrid] = {[prefKey]: prefValue};
    }
    this.saveInStorageByUser(ClientStorageEntities.USER_PREFERENCES, clientSelection);
  }

  addNonGridPreferencesToClientStorage(clientSelection: ClientUserPreferencesData, prefKey: string, prefValue: any) {
    if (clientSelection && clientSelection.preferences) {
      clientSelection.preferences[prefKey] = prefValue;
    } else {
      clientSelection = {
        preferences: {
          [prefKey]: prefValue
        },
        userEmail: ''
      }
    }
    this.saveInStorageByUser(ClientStorageEntities.USER_PREFERENCES, clientSelection);
  }

  /**
   * Save selected preferences in outer storage based on ClientStorageEntities
   * @param storageEntity
   * @param clientSelection
   * @private
   */
  private saveInStorageByUser(storageEntity: ClientStorageEntities, clientSelection: ClientUserPreferencesData) {
    const datasource = EnvironmentType.isOnPrem(this.environmentType) ? ClientStorageDatasource.LOCAL_STORAGE : ClientStorageDatasource.FIREBASE;
    switch (datasource) {
      case ClientStorageDatasource.FIREBASE: {
        if (storageEntity === ClientStorageEntities.USER_PREFERENCES) {
          const firebaseUserPreferencesService: FirebaseUserPreferencesService = this.injector.get(FirebaseUserPreferencesService);
          firebaseUserPreferencesService.clientSelection = {...clientSelection};
          firebaseUserPreferencesService.updateUserPreferencesSelection();
          break;
        }
        break;
      }
      case ClientStorageDatasource.LOCAL_STORAGE: {
        if (storageEntity === ClientStorageEntities.USER_PREFERENCES) {
          const localStorageUserPreferencesService: LocalStorageUserPreferencesService = this.injector.get(LocalStorageUserPreferencesService);
          localStorageUserPreferencesService.clientSelection = {...clientSelection};
          localStorageUserPreferencesService.updateUserPreferencesSelection();
          break;
        }
        break;
      }
    }
  }
}
