import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICONS_PATH } from 'src/app/config/path';
import { LegacySeverity } from '../../../models/severity.model';
import { SeverityService } from '../../../services/strategies/severity.service';

@Component({
  selector: 'app-severity-cell-renderer',
  // template: `<mat-icon class="severity-icon" mat-list-icon svgIcon="severity_high">severity_high</mat-icon>`,
  styleUrls: ['./severity-cell-renderer.component.scss'],
  templateUrl: './severity-cell-renderer.component.html',
})
export class SeverityCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  /**
   * Same as the params coming from the grid but passed as angular input,
   * usefull if render is used as a regular component and not as ag grid one.
   */
  //@Input() severity: ICellRendererParams;
  Severity = LegacySeverity;
  params: ICellRendererParams;
  @Input() set severity(value: LegacySeverity) {
    this._severity = value;
  }
  get severity(): LegacySeverity {
    return this._severity;
  }
  _severity: LegacySeverity;
  constructor(private severityService: SeverityService) { }
  refresh(params: any): boolean {
    return false;
  }
  ngOnInit() {
    // If called as a regular component
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params && (params as any).severityStrategy) {
      this.severity = (params as any).severityStrategy(params.data)
    } else {
      if (!params || !params.data) {
        this.severity = -1;//Severity.Minor
      } else {
        if (params && params.data.severity != undefined) {
          // console.log(`Severity ${params.data.severity}`)
          this.severity = params.data.severity;
        } else {
          if (params)
            this.severity = this.severityService.serverityByHealth(params.data.health)
        }
      }
    }
  }
  ngOnDestroy(): void {
  }

}
