import { Pipe, PipeTransform, Host, TemplateRef } from '@angular/core';
import { TemplateRegistry } from './template-registry';
@Pipe({
  name: 'stringAsTemplateRef'
})
//SOURCE: https://stackblitz.com/edit/angular-hkwoby?file=src%2Fapp%2Fapp.component.ts
export class StringAsTemplateRefPipe implements PipeTransform {

  constructor(@Host() private registry: TemplateRegistry) { }

  transform(name: string): TemplateRef<any> | undefined {
    return this.registry.templates[name];
  }
}
