export const TOP_FULL_COLORS: string[] = [
  'rgba(46, 161, 248, 1)',
  'rgb(226,133,170, 1)',
  'rgba(189, 96, 161, 1)',
  'rgba(209, 198, 123, 1)',
  'rgba(255, 185, 70, 1)',
  'rgba(247, 104, 91, 1)',
  'rgba(138, 125, 208, 1)',
  'rgba(208, 125, 173, 1)',
  'rgba(158, 209, 119, 1)',
  'rgba(116, 191, 204, 1)',
  'rgba(113, 188, 201, 1)',
  'rgba(106, 196, 120, 1)',
  'rgba(167, 105, 194, 1)',
  'rgba(99, 135, 191, 1)',
  'rgba(46, 212, 122, 1)',
  'rgb(168,117,108, 1)',
  'rgb(202,135,86, 1)',
  'rgb(133,149,14, 1)',
  'rgb(161,106,132, 1)',
  'rgb(20,45,80, 1)',
  'rgb(226,133,170, 1)'
];
export const STACKED_FULL_COLORS: string[] = [
  'rgba(255, 185, 70, 1)',
  'rgba(247, 104, 91, 1)',
  'rgba(138, 125, 208, 1)',
  'rgba(208, 125, 173, 1)',
  'rgba(209, 198, 123, 1)',
  'rgba(158, 209, 119, 1)',
  'rgba(116, 191, 204, 1)',
  'rgba(113, 188, 201, 1)',
  'rgba(173, 108, 196, 1)',
  'rgba(106, 196, 120, 1)',
  'rgba(167, 105, 194, 1)',
  'rgba(99, 135, 191, 1)',
  'rgba(189, 96, 161, 1)',
  'rgba(46, 161, 248, 1)',
  'rgba(46, 212, 122, 1)',
  'rgb(168,117,108, 1)',
  'rgb(202,135,86, 1)',
  'rgb(133,149,14, 1)',
  'rgb(161,106,132, 1)',
  'rgb(20,45,80, 1)',
  'rgb(226,133,170, 1)'
];

export const STACKED_HALF_COLORS: string[] = [
  'rgba(255, 185, 70, 0.7)',
  'rgba(247, 104, 91, 0.7)',
  'rgba(138, 125, 208, 0.7)',
  'rgba(208, 125, 173, 0.7)',
  'rgba(209, 198, 123, 0.7)',
  'rgba(158, 209, 119, 0.7)',
  'rgba(116, 191, 204, 0.7)',
  'rgba(113, 188, 201, 0.7)',
  'rgba(173, 108, 196, 0.7)',
  'rgba(106, 196, 120, 0.7)',
  'rgba(167, 105, 194, 0.7)',
  'rgba(99, 135, 191, 0.7)',
  'rgba(189, 96, 161, 0.7)',
  'rgba(46, 161, 248, 0.7)',
  'rgba(46, 212, 122, 0.7)',
  'rgb(168,117,108, 0.7)',
  'rgb(202,135,86, 0.7)',
  'rgb(133,149,14, 0.7)',
  'rgb(161,106,132, 0.7)',
  'rgb(20,45,80, 0.7)',
  'rgb(226,133,170, 0.7)'
];
export const STACKED_ZERO_COLORS: string[] = [
  'rgba(255, 185, 70, 0)',
  'rgba(247, 104, 91, 0)',
  'rgba(138, 125, 208, 0)',
  'rgba(208, 125, 173, 0)',
  'rgba(209, 198, 123, 0)',
  'rgba(158, 209, 119, 0)',
  'rgba(116, 191, 204, 0)',
  'rgba(113, 188, 201, 0)',
  'rgba(173, 108, 196, 0)',
  'rgba(106, 196, 120, 0)',
  'rgba(167, 105, 194, 0)',
  'rgba(99, 135, 191, 0)',
  'rgba(189, 96, 161, 0)',
  'rgba(46, 161, 248, 0)',
  'rgba(46, 212, 122, 0)',
  'rgb(168,117,108, 0)',
  'rgb(202,135,86, 0)',
  'rgb(133,149,14, 0)',
  'rgb(161,106,132, 0)',
  'rgb(20,45,80, 0)',
  'rgb(226,133,170, 0)'
];


