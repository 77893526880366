import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HoriznotalTimeSpan, HorizontalTimelineMarker } from '../../../components/horizontal-timeline/models/horizontal-time-span.model';
import { EntityAction } from '../../../models/actions.model';

@Component({
  selector: 'app-time-bar-renderer',
  templateUrl: './time-bar-renderer.component.html',
  styleUrls: ['./time-bar-renderer.component.scss']
})
export class TimeBarRendererComponent implements ICellRendererAngularComp {
  timeSpans: HoriznotalTimeSpan[];
  currentAction: EntityAction;
  markerData: HorizontalTimelineMarker;

  constructor() { }

  refresh(params: any): boolean {
    return;
  }
  /**
   * Initiate params for horizontal-timeline.
   */
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    if (params && params.value) {
      this.timeSpans = params.value.timeSpans;
      this.markerData = params.value.markerData;
    }
  }


  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
    return;
  }
}
