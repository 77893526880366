<div class="row dashboard-nav-row" [ngClass]="cssClass">
  <div class="col tabs-col">
    <nav mat-tab-nav-bar class="dashboard-tabs dynamics-nav" [tabPanel]="tabPanel">
      <div *ngFor="let link of formattedNavLinks" class="align-items-center">
        <ng-container *ngIf="link.isDisplayed">
          <a (click)="onTabSelected(link.data)" mat-tab-link [routerLink]="link.data.path" routerLinkActive
            #rla="routerLinkActive" [active]="rla.isActive">
            {{link.data.label}}
          </a>
        </ng-container>
      </div>
    </nav>
  </div>
</div>
<mat-tab-nav-panel #tabPanel class="dashboard-content">
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
