import { Injectable, EventEmitter } from '@angular/core';
import { FlowStepBeforeChangeEvent } from '../event/flow-step-changed-event';

@Injectable()
export class FlowService {
  stepFired: EventEmitter<FlowStepBeforeChangeEvent> = new EventEmitter();

  constructor() { }

  finishStep(step: any, payload: any) {
    this.stepFired.emit(new FlowStepBeforeChangeEvent(step, payload));
  }
  // nextStepStrategy
}
