import { Component, ViewEncapsulation, Input } from '@angular/core';
import { GenericDevice } from 'src/app/shared/models/topology';

@Component({
  selector: 'app-disconnected-devices',
  templateUrl: './disconnected-devices.component.html',
  styleUrls: ['./disconnected-devices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DisconnectedDevicesComponent {
  @Input() disConnectedDevices: GenericDevice<any>[];
  @Input() isFabricsVenue: boolean;
  @Input() isDevice: boolean;
  constructor() { }
}
