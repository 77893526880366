<ng-container *ngIf="timeSpans?.length > 0">
    <div class="horizontal-timeline-main-row" [style.width.px]="width">
        <div class="col no-side-padding span-container" *ngFor="let span of timeSpans">
            <div class="row timespan-row">
                <div class="col no-side-padding timespans-col" [style.height.px]="height"
                    [style.min-width.px]="span.width" [style.background]="span.backgroundColor" #tooltip="matTooltip"
                    [matTooltip]="span.spanTooltipText" [matTooltipPosition]="'above'" matTooltipClass="marker-tooltip"
                    matTooltipShowDelay="1000">
                      <div><span><b>Start:</b></span><span class="time">{{span.start | date: 'MMM d, y HH:mm'}}</span></div>
                      <div><span><b>End:</b></span><span class="time">{{span.end | date: 'MMM d, y HH:mm'}}</span></div>
                      <div><i class="material-icons dot {{span.status}}">fa_circle</i></div>
                </div>
                <mat-icon svgIcon="timeline-marker" *ngIf="span.marker" class="col marker" [ngClass]="marker.cssClass"
                    [timelineMarker]="span" [barHeight]="height" #tooltip="matTooltip" [matTooltip]="markerTooltipData"
                    [matTooltipPosition]="'above'" matTooltipClass="marker-tooltip" matTooltipShowDelay="1000">
                </mat-icon>
            </div>
        </div>
    </div>
</ng-container>
