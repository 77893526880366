import { Component, Input } from '@angular/core';
import { DiagramNode } from '../../models/diagram.model';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent {
  @Input() nodes: DiagramNode<any>[];
  @Input() defaultMessge: string;
  @Input() tooltipClass: string;
  @Input() tooltipDirection: string;
  /**
   * Utils param. Needed in order to set timeout before update the diagram array.
   * The purpose: prevent the isNoNodes return true when data changes (even in cased there are nodes)
   */
  diagramNodes: DiagramNode<any>[] = [];


  constructor() { }

  ngOnChanges() {
    setTimeout(() => {
      if (this.nodes.length > 1)
        this.diagramNodes = [...this.nodes]
    });
  }

  /**
   * Check if No node are drawn.
   * Mainly in order to diaplay the default message
   */
  get isNoNodes(): boolean {
    return this.diagramNodes && this.diagramNodes.length == 0;
  }
}
