import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { take, catchError, of } from "rxjs";
import { CheckBoxListItem, CheckboxField } from "../../components/checkbox-search-list/checkbox-list.model";
import { RestoreBackupHistory, DeviceBackupHistoryDTO, DeviceBackupDTO } from "../../models/device-backup.model";
import { sortArrayByParam } from "../../operators/array.operator";
import { DevicesService } from "../../services/rest-services/devices.service";
import { TimeManagerService } from "../../services/time-manager.service";

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
  restoreForm: UntypedFormGroup;
  options: CheckBoxListItem<RestoreBackupHistory>[];
  disaplayedFields: CheckboxField[] = [{fieldData: [{fieldName: 'date'}]}, {fieldData: [{fieldName: 'fileName'}]}];
  optionsListControl: UntypedFormControl = new UntypedFormControl();
  searchFocus: boolean;
  isLoading: boolean = false;
  backupHisotory: DeviceBackupHistoryDTO[];

  constructor(private matDialogRef: MatDialogRef<RestoreComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: DeviceBackupDTO,
              private fb: UntypedFormBuilder,
              private deviceService: DevicesService,
              private dateConversionService: TimeManagerService) {
  }

  ngOnInit() {
    if (this.dialogData) {
      this.getBackupData();
      this.initateForm();
    }
  }

  getBackupData() {
    this.isLoading = true;
    this.deviceService.getDeviceBackupHistory(this.dialogData.deviceId).pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(error);
      })
    ).subscribe(backupHistory => {
      this.isLoading = false;
      if (backupHistory) {
        this.backupHisotory = backupHistory;
        this.initateArray();
      }
    });
  }

  initateForm() {
    this.restoreForm = this.fb.group({
      deploy: new UntypedFormControl('now'),
      deployDate: new UntypedFormControl(''),
      deployHour: new UntypedFormControl(''),
    });
  }

  initateArray() {
    this.options = this.backupHisotory.map(backup => {
      return {
        data: {
          date: this.dateConversionService.dateByFormat(backup.ranAt, 'DD-MMM-YYYY HH:mm'),
          unFormatted: backup.ranAt,
          fileName: `${backup.firmWareVersion}_${backup.ranAt}`
        }
      } as CheckBoxListItem<RestoreBackupHistory>;

    }).sort(sortArrayByParam('unFormatted', 'desc'));
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  get isLater() {
    if (this.restoreForm) {
      return this.restoreForm.controls['deploy'].value == 'later';
    }
  }

  get isNow() {
    if (this.restoreForm) {
      return this.restoreForm.controls['deploy'].value == 'now';
    }
  }

}
