import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HealthColorsService } from '../../services/strategies/health-colors.service';
import { HeaderData, PropertiesDataType, PropertiesElementType } from '../../components/properties/models/properties-content';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-issues-health-dialog',
  templateUrl: './issues-health-dialog.component.html',
  styleUrls: ['./issues-health-dialog.component.scss'],
})
export class IssuesHealthDialogComponent implements OnInit {
  headerData: HeaderData;
  dialogData: { percentage?: number, reason: string }[];
  propertiesDataType = PropertiesDataType;
  titleAddition: string;
  entityActionsList: string[] = [];

  constructor(private healthColorsService: HealthColorsService,
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<IssuesHealthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        dataContent: HeaderData,
        dataType: PropertiesDataType,
        titleAddition: string
      }) {
    this.headerData = this.data.dataContent;
  }

  ngOnInit() {
    this.initDialogData();
  }

  /**
   * @method initDialogData Receive the data from the server
   * 2.2.19 - Currently there is no Api for this method, so it's mocked
   */
  private initDialogData() {
    this.titleAddition = this.data.titleAddition;
    switch (this.data.dataType) {
      case PropertiesDataType.HEALTH:
        this.dialogData = this.formattedDegradationPercentage(this.headerData.healthDegradationReasons);
        break;
      case PropertiesDataType.ACTIONS:
        this.entityActionsList = this.data.dataContent.actionsList;
        break;
      default:
        break;
    }
    if (this.headerData && this.headerData.elementType === PropertiesElementType.VENUE && this.data.dataType === PropertiesDataType.HEALTH) {
      this.dialogData = [
        { percentage: +this.headerData.health, reason: this.generateDialogReason() },
      ];
    }
  }
  /**
   * Multiple percentage by 100 when health degradations are between 0 and 1
   */
  formattedDegradationPercentage(healthDegradationReasons: { percentage?: number; reason: string; }[]): { percentage?: number; reason: string; }[] {
    if (healthDegradationReasons) {
      healthDegradationReasons.forEach(degradation => {
        if (degradation.percentage > 0 && degradation.percentage < 1)
          degradation.percentage = degradation.percentage * 100;
      })
      return healthDegradationReasons;
    }
    return null;
  }

  /**
   * @method getPercentageColor return colors for the fabric value in the properties header
   * the methode is activated by the template, and inject the restult with ngStyle
   * @param health represent the current element health
   */
  get percentageColor() {
    if (this.headerData)
      return this.healthColorsService.getHealthColors(this.headerData.health);
  }

  get entityTitle() {
    if (this.headerData)
      return this.headerData.firstColumn.main
  }
  get isDialogHealth() {
    if (this.data.dataType == PropertiesDataType.HEALTH)
      return true;
    return false;
  }
  get dataType() {
    switch (this.data.dataType) {
      case PropertiesDataType.HEALTH:
        return this.translate.instant('data.COMMON.HEALTH') + " - ";
      case PropertiesDataType.ACTIONS:
        return this.translate.instant('data.DASHBOARD.ACTIONS') + " - ";
      default:
        break;
    }
  }

  generateDialogReason() {
    return this.headerData.health == 100 ?
      this.translate.instant('data.VENUES.NO_PROBLEMS_FOUND') :
      this.translate.instant('data.VENUES.VENUE_SCORE_CALC');
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
