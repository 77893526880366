import {Fabric, FabricStatus} from "../../../../../shared/models/fabrics.model";

export class FabricDisplay {
  fabric: Fabric;

  constructor(fabric: Fabric) {
    this.fabric = fabric;
  }

  get fabricIconPath() {
    if (this.fabric.fabricStatus.status !== FabricStatus.COMPLETE && this.fabric.fabricStatus.category) {
      return `assets/media/netop/${this.fabric.fabricStatus.category.toLowerCase()}.svg`;
    }
    return 'assets/media/netop/v-icon.svg';
  }

  get fabricColorClass() {
    if (this.fabric.fabricStatus.status !== FabricStatus.COMPLETE) {
      return `venue-fabric-${this.fabric.fabricStatus.status.toLowerCase()}`
    }
    return null;
  }
}
