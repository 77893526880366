import {Injectable} from '@angular/core';
import {D3Link, PathStyle, SingleLink, LinkVendorStatus} from '../../../models/single-link.model';
import {TopologyConfiguration} from '../models/topology-configuration';
import {EditPropertiesService} from '../../properties/services/edit-properties.service';
import {D3TreeEventsService} from './d3-tree-events.service';
import {FormAction} from '../../../modals/models/form-actions.model';
import {take} from 'rxjs/operators';
import * as d3 from 'd3';
import {SingleD3Node} from '../../../models/topology';
import {isDeviceLink} from "../operators/topology-operators";


@Injectable({
  providedIn: 'root'
})
export class D3LinkService {

  constructor(
    private editPropertiesService: EditPropertiesService,
    private d3TreeEventsService: D3TreeEventsService) {
  }

  onLinkClick(link: D3Link, topologyConfigruation: TopologyConfiguration) {
    if (!topologyConfigruation.isDevice && !topologyConfigruation.isFabricsVenue) {
      this.editPropertiesService.notifyIsEditModeObservable$.pipe(take(1)).subscribe(mode => {
        if (mode) {
          this.editPropertiesService.openLeaveFormDialog().afterClosed().subscribe(result => {
            if (result == FormAction.DELETE) {
              this.d3TreeEventsService.onLinkClick(link);
              this.editPropertiesService.isEditMode(false);
            }
          });
        } else {
          this.d3TreeEventsService.onLinkClick(link);
        }
      });
    }
  }

  /**
   * Return the path calculation based on the pathStyle parameter
   */
  getLinkPath(link: D3Link, width: number, pathStyle: PathStyle) {
    return pathStyle == PathStyle.CURVE ?
      this.getLinkCurvePath(link, width) :
      this.getLinkRightAnglePath(link, width);
  }

  /**
   * Return the calculation for curve path, using d3.js funtion
   */
  getLinkCurvePath(link: D3Link, width: number) {
    return d3.linkHorizontal()({
      source: [width - link.source.y, link.source.x],
      target: [width - link.target.y, link.target.x]
    });
  }

  /**
   * Return right angle path.
   * Calculate manually the path
   * Used: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d explanation
   */
  getLinkRightAnglePath(link: D3Link, width: number) {
    const source = {x: width - link.source.y, y: link.source.x};
    const target = {x: width - link.target.y, y: link.target.x};
    const firstXTarget = (source.x - (source.x - target.x) / 3);
    const curvedCornerDistance: number = 6;
    /**
     * In case of more than one child, we want small line comes out of the parent node,
     * And than this line will split to each child.
     * The small line will be calculated has follows:
     * [1] "M": We reduce the difference between the source and target values, and subtruct one third of that from the origin source.x value
     * [2] "L" We draw the line from the source node.
     * [3] "M" We move the pan to the end of the short line
     * [4] "V" We draw vertical line to the same height (y) of the target node
     * [5] "H" We draw horizontal line to the same x of the target node
     */
    if (this.moreThanOneChild(link.source)) {
      const path: string =
        'M' + firstXTarget + ',' + source.y +
        'L' + source.x + ',' + source.y +
        'M' + firstXTarget + ',' + source.y +
        'V' + this.getVerticalLinePosition(link, source, target, curvedCornerDistance) +
        this.addCurveToCorners(link, source, target, firstXTarget, curvedCornerDistance) +
        'H' + target.x;
      return path;
    }
    /**
     * The calculation for two nodes on the same x level is simple:
     * [1] M get the x,y of the target node
     * [2] L gets the x,y of the source
     */
    if (!this.moreThanOneChild(link.source) && this.onlyOneChild(link.source) || this.multiVendorFutile(link)) {
      return 'M' + target.x + ',' + target.y + 'L' + source.x + ',' + source.y;
    }
  }

  multiVendorFutile(link: D3Link): boolean {
    return isDeviceLink(link.data) ? (link.data as SingleLink).origin == LinkVendorStatus.MULTI_VENDOR && (!link.source.children || link.source.children.length == 0) : false;
  }

  /**
   * Return Different line for corner links and regulaer links
   * If corner, The line will be shorter, in order to allow curve
   * @param link Current link
   * @param source Link Source
   * @param target Target Source
   */
  private getVerticalLinePosition(link: D3Link, source: { x: number; y: number; }, target: { x: number; y: number; }, curvedCornerDistance: number) {
    return !this.isCorner(link, source, target) ?
      target.y :
      this.getComputedYByTargetY(source, target, curvedCornerDistance);
  }

  /**
   * If corner, Add Curve element to the link path
   * The curve element consist on the Quadratic Bézier Curve (Upper Q)
   * Inseration: https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
   * @param link Current link
   * @param source Link Source
   * @param target Target Source
   * @param firstXTarget The end of the first line in the link
   */
  private addCurveToCorners(link: D3Link, source: { x: number; y: number; }, target: { x: number; y: number; }, firstXTarget: number, curvedCornerDistance: number) {
    return this.isCorner(link, source, target) ?
      'Q' +
      /** x position of the control point (i.e., the point that determine the radius of the curve) */
      (firstXTarget) + ',' +
      /** y position of the control point (i.e., the point that determine the radius of the curve) */
      (target.y) + ' ' +
      /** x position of the end of the curve */
      (firstXTarget - curvedCornerDistance) + ',' +
      /** y position of the end of the curve */
      target.y
      :
      '';
  }

  /**
   * Return the Lower Y position the original target.y,
   * based on wheater the link.target is lower than the source, or not
   * (e.g., If the target it higher, than adding value to link.target.y will make it shorter)
   */
  private getComputedYByTargetY(source: { x: number; y: number; }, target: { x: number; y: number; }, curvedCornerDistance: number): number {
    return this.isTargetLowerThanSource(source, target) ? (target.y - curvedCornerDistance) : (target.y + curvedCornerDistance);
  }

  /**
   * Determine whether a link is a corner, i.e., the last or the first child of the parent
   */
  private isCorner(link: D3Link, source: { x: number; y: number; }, target: { x: number; y: number; }) {
    return link.source.children[0] == link.target || link.source.children[link.source.children.length - 1] == link.target && source.y !== target.y;
  }

  /**
   * Determine if target is lower than source
   */
  private isTargetLowerThanSource(source: { x: number; y: number; }, target: { x: number; y: number; }) {
    return source.y < target.y;
  }

  /**
   * Return true if the given node has only one child
   */
  private onlyOneChild(node: SingleD3Node<any>) {
    return node.children && node.children.length == 1;
  }

  /**
   * Return true if the given node has more than one child
   */
  private moreThanOneChild(node: SingleD3Node<any>): boolean {
    return node.children && node.children.length > 1;
  }

  /**
   * Return complete result of the checking for link correlation between venue and fabric topology
   * @param fabricTopologyLink The current fabric link
   * @param venueTopologyLink The current venue link
   */
  isLinkConnectSameNodes(fabricTopologyLink: SingleLink, venueTopologyLink: D3Link): boolean {
    return this.fabricLinkConnectLikeVenueLink(fabricTopologyLink, venueTopologyLink) || this.wwwLinkSameForFabricAndVenue(fabricTopologyLink, venueTopologyLink);
  }

  /**
   * Check the specific Case of WWW links.
   * In case that node is a WWW node, the venue links target or source node are assigned manually by the
   * Create tree mechanism with the "666" as id.
   * On the other hand, the fabric links as the original id for those nodes (usually 0)
   * This method checks this specifice case
   *
   */
  private wwwLinkSameForFabricAndVenue(fabricTopologyLink: SingleLink, venueTopologyLink: D3Link) {
    return ((fabricTopologyLink.startDeviceId == 0 && venueTopologyLink.data.startDeviceId == '666' ||
      fabricTopologyLink.startDeviceId == 0 && venueTopologyLink.data.endDeviceId == '666') &&
      (fabricTopologyLink.endDeviceId == venueTopologyLink.data.startDeviceId ||
        fabricTopologyLink.endDeviceId == venueTopologyLink.data.endDeviceId) ||
      (fabricTopologyLink.endDeviceId == 0 && venueTopologyLink.data.startDeviceId == '666' ||
        fabricTopologyLink.endDeviceId == 0 && venueTopologyLink.data.endDeviceId == '666'))
      && (fabricTopologyLink.startDeviceId == venueTopologyLink.data.startDeviceId ||
        fabricTopologyLink.startDeviceId == venueTopologyLink.data.endDeviceId);
  }

  /**
   * Check whether venue link and fabric link as ths same nodes on their edges
   */
  private fabricLinkConnectLikeVenueLink(fabricTopologyLink: SingleLink, venueTopologyLink: D3Link): boolean {
    return ((fabricTopologyLink.startDeviceId == venueTopologyLink.data.startDeviceId ||
      fabricTopologyLink.startDeviceId == venueTopologyLink.data.endDeviceId) &&
      (fabricTopologyLink.endDeviceId == venueTopologyLink.data.startDeviceId ||
        fabricTopologyLink.endDeviceId == venueTopologyLink.data.endDeviceId));
  }

  /**
   * Check if links connect the same devices
   */
  d3SelectedLinkSamsAstopologyLink(selectedLink: D3Link, topologyLink: D3Link): boolean {
    return ((selectedLink.data.startDeviceId == topologyLink.data.startDeviceId ||
      selectedLink.data.startDeviceId == topologyLink.data.endDeviceId) &&
      (selectedLink.data.endDeviceId == topologyLink.data.startDeviceId ||
        selectedLink.data.endDeviceId == topologyLink.data.endDeviceId));
  }
}
