import {EntityType} from './entity-type.enum';
import {HealthIndicator} from './health.model';
import {LegacySeverity} from './severity.model';
import {AnomalyCategory, DeviceAnomalies} from './anomalies.model';
import {FromToDatesStrings} from "./time.model";
import {splitString} from "../operators/string-opertators";
import {Colors} from "../../config/colors";

export class EntityAction {
  id: number;
  tenantId: number;
  venueId: number;
  updatedAt: string;
  entityType: EntityType;
  entityId: number;
  severity: LegacySeverity;
  name: string;
  status: ActionStatus;
  category: ActionCategory;
  categoryName: string;
  type: DeviceAnomalies;
  description: string;
  resolutionType: ResolutionType;
  suggestedOperation: string;
  nextPossibleStates: Array<ActionStatus>;
  anomalyCategory: AnomalyCategory;
  summary: Array<InsightSummary>;
  possibleRootCauses: Array<string>;
  manualSolutions: Array<string>;
  automaticRemediations?: Array<string>;
  // will be deprecated once integrated with BE
  affects: number;
  statusIndicator: HealthIndicator; // e.g. "MOST_SEVER"
  additionalData: ActionAdditionalData;
}

export class InsightSummary {
  category: AnomalyCategory;
  severity: LegacySeverity;
  shortDesc: string;
}

export enum ActionStatus {
  Open = "Open",
  Complete = "Complete",
  Ack = "Ack",
  Ignore = "Ignore",
  InProgress = "InProgress",
  NoOp = "NoOp",
  Analyze = "Analyze"
}

export namespace ActionStatus {
  export function getActionStatusName(status: ActionStatus): string {
    let translations = {
      [ActionStatus.Open]: 'data.ACTION_STATUS.OPEN',
      [ActionStatus.Complete]: 'data.ACTION_STATUS.COMPLETE',
      [ActionStatus.Ack]: 'data.ACTION_STATUS.ACK',
      [ActionStatus.Ignore]: 'data.ACTION_STATUS.IGNORE',
      [ActionStatus.InProgress]: 'data.ACTION_STATUS.IN_PROGRESS',
      [ActionStatus.NoOp]: 'data.ACTION_STATUS.NOOP',
      [ActionStatus.Analyze]: 'data.ACTION_STATUS.ANALYZE',
    };
    return translations[status];
  }

  export function getActionStatusesAsArray(): ActionStatus[] {
    return [
      ActionStatus.Open,
      ActionStatus.Complete
    ]
  }
}

export enum ActionType {
  LogicalNotUsed = "LogicalNotUsed",
  DeviceLimit = "DeviceLimit",
  UnusualTraffic = "UnusualTraffic",
  DeviceStatusNeverUp = "DeviceStatusNeverUp",
  DeviceStatusJustWentDown = "DeviceStatusJustWentDown",
  DeviceStatusDownForLongTime = "DeviceStatusDownForLongTime",
  DeviceStatusLongPeriodOffline = "DeviceStatusLongPeriodOffline",
  PacketLoss = "PacketLoss",
  Latency = "Latency",
  APReboots = "APReboots",
  HighTemp = "HighTemp",
  NetworkNotUsed = "NetworkNotUsed",
  TrafficTooHighForWeekDay = "TrafficTooHighForWeekDay",
  VpnConTrafficTooHighForWeekDay = "VpnConTrafficTooHighForWeekDay",
  TrafficTooLowForWeekDay = "TrafficTooLowForWeekDay",
  VpnStatusJustWentDown = "VpnStatusJustWentDown",
  VpnStatusDownForLongTime = "VpnStatusDownForLongTimAe",
  ConfigurationChange = "ConfigurationChange",
  HighFailuresNo = "HighFailuresNo"
}

export namespace ActionType {
  export function actionTypeToAnomalyCategory(actionType: ActionType): AnomalyCategory {
    switch (actionType) {
      case ActionType.PacketLoss:
        return AnomalyCategory.PacketLoss;
      default:
        break;
    }
  }
}

export namespace ActionType {
  export function getActionTypesAsArray() {
    return [
      ActionType.LogicalNotUsed,
      ActionType.DeviceLimit,
      ActionType.UnusualTraffic,
      ActionType.DeviceStatusNeverUp,
      ActionType.DeviceStatusJustWentDown,
      ActionType.DeviceStatusDownForLongTime,
      ActionType.DeviceStatusLongPeriodOffline,
      ActionType.PacketLoss,
      ActionType.Latency,
      ActionType.APReboots,
      ActionType.HighTemp,
      ActionType.NetworkNotUsed,
      ActionType.TrafficTooHighForWeekDay,
      ActionType.VpnConTrafficTooHighForWeekDay,
      ActionType.TrafficTooLowForWeekDay,
      ActionType.VpnStatusJustWentDown,
      ActionType.VpnStatusDownForLongTime,
      ActionType.ConfigurationChange,
      ActionType.HighFailuresNo
    ]
  }
}

export class ActionTypeDto {
  desc: string;
  value: string;
}

export enum ResolutionType {
  Manual = "Manual",
  Automatic = "Automatic"
}

export enum ActionCategory {
  Operational = "Operational",
  Security = "Security",
  Performance = "Performance",
  Compliance = "Compliance",
  Configuration = "Configuration"

}

export namespace ActionCategory {

  export function getCategoryName(category: ActionCategory | string): string {
    return ActionCategory[category];
  }

  export function getCategoriesAsArray(): string[] {
    return [
      ActionCategory.Operational,
      ActionCategory.Security,
      ActionCategory.Performance,
      ActionCategory.Compliance,
      ActionCategory.Configuration,
    ]
  }

  export function getColor(category: ActionCategory): string {
    switch (category) {
      case ActionCategory.Compliance:
        return Colors.Highcharts.SKY_BLUE;
      case ActionCategory.Configuration:
        return Colors.Highcharts.PURPLE;
      case ActionCategory.Operational:
        return Colors.Highcharts.LIGHT_GREEN;
      case ActionCategory.Performance:
        return Colors.Highcharts.ORANGE;
      case ActionCategory.Security:
        return Colors.Highcharts.RED;
      default:
        return undefined;
    }
  }
}

export type AnomalyInsightsTrend = {
  [key in ActionCategory]: Array<DateValue<Array<BasicActionDTO>>>;
}

export class DateValue<T> {
  date: number;
  value: T;
}

export class BasicActionDTO {
  id: number;
  anomalyCategory: AnomalyCategory;
  actionStatus: ActionStatus;
  actionCategory: ActionCategory;
  deviceId: number;
  deviceName: string;
  venueId: number;
  venueName: string;
  tenantId: number;
  tenantName: string;
}

export class TimeSeriesWrapper<T> {
  dataGranularity: DataGranularity;
  data: T;
}

export enum DataGranularity {
  Hourly = "Hourly",
  HourlySix = "HourlySix",
  HourlyTwelve = "HourlyTwelve",
  Daily = "Daily"
}

export type AnomalyCategoryEnumKeys = keyof typeof AnomalyCategory;

export type AnomalyCategoryWithStatus = {
  [key in AnomalyCategoryEnumKeys]?: {
    [key in ActionStatus]?: number;
  };
}

export type FromToDatesWithAnomalyTrend = { trend: TimeSeriesWrapper<AnomalyInsightsTrend>, fromToDates: FromToDatesStrings }

/**
 * This should have subclasses per action
 */
export class ActionAdditionalData {
  name: string;
  downSince: string;
  serial: string;
}

export class VpnActionAdditionalData extends ActionAdditionalData {
  vpnConnectionId?: number;
}
