import {FailedConnection} from './clients.model';
import {BasicTopologyNode} from './topology';

export class BasicDevice {
  id: string | number;
  description?: string;
  name: string;
  vendorName?: string;
  networkId?: string;
  model?: string;
  venueId?: number;
  lat?: number;
  lon?: number;
  createdAt?: string | number;
  serialNumber?: string;
  type: SingleDeviceType;
  properties?: {};
}


export class SingleDevice extends BasicTopologyNode {
  device: BasicDevice;
  kpis?: {
    latency?: number,
    users?: number,
    traffic?: number,
    loss?: number,
    utilization?: number
  };
  failedClients?: FailedConnection[];
  deviceLatestStatus?: DeviceLatestStatus;
}

export enum SingleDeviceType {
  Switch = 'Switch',
  SwitchStack = 'SwitchStack',
  AccessPoint = 'AccessPoint',
  Camera = 'Camera',
  Router = 'Router',
  SDWAN = 'SDWAN',
  Firewall = 'Firewall',
  WWW = 'WWW',
  ExternalRouter = 'ExternalRouter',
  UnidentifiedDevice = 'UnidentifiedDevice',
  ClientDevice = 'ClientDevice',
  FakeRoot = 'FakeRoot',
  Sase = 'Sase',
  CellularGateway = 'cellular_gateway'
}

export class DeviceLatestStatus {
  state: LatestDeviceState;
  statusTime: Date;
  deviceStatusTxt: string;
  deviceSerial: string;
  originalStatus: string;
  originalStatusTxt: string;
  interfaces: DeviceInterfaceStatus[];
}

export class DeviceInterfaceStatus {
  ifName: string;
  portStatus: number;
  portStatusMsg?: string;
  linkStatus: number;
  linkStatusMsg?: string;
  tunnelStatus: number;
  tunnelStatusMsg?: string;
}

export enum LatestDeviceState {
  Alerting = 'Alerting',
  Disabled = 'Disabled',
  Hibernating = 'Hibernating',
  Offline = 'Offline',
  Online = 'Online',
  Unknown = 'Unknown',
  Unreachable = 'Unreachable',
  Idle = 'Idle'
}
