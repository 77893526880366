import {Injectable} from '@angular/core';
import {KpiTrend, KpiType, TrafficUnits, TrafficValueAndSize} from '../../models/kpi.model';

@Injectable({
  providedIn: 'root'
})
export class KpiDataService {
  readonly MILLION = 1000000;

  constructor() {
  }

  /**
   * @method getKpiUnit return the sotrage unit sign by kpi type
   */
  getKpiUnit(key: KpiType, unit?: TrafficUnits): string {
    switch (key) {
      case KpiType.Loss:
        return "%";
      case KpiType.Health:
        return "%";
      case KpiType.Latency:
        return "ms";
      case KpiType.Clients:
        return "";
      case KpiType.Traffic:
        return `${unit}`;
      case KpiType.Throughput:
        return `${unit}`;
      default:
        break;
    }
  }

  /**
   * @method getTrafficUnitByValue calculate the traffic's storage unit sign by value
   * @param value is the value of the current kpi
   */
  getBytesSizeByValue(countOfDeviation: number): TrafficUnits {
    const zero = TrafficUnits.KB;
    const one = TrafficUnits.MB;
    const two = TrafficUnits.GB;
    const three = TrafficUnits.TB;

    switch (true) {
      case countOfDeviation == 0:
        return zero;
      case countOfDeviation == 1:
        return one;
      case countOfDeviation == 2:
        return two;
      case countOfDeviation == 3:
        return three;
      default:
        break;
    }
  }

  calculateTrafficValue(value: number, count: number = 0): { finalValue: number, countOfDevition: number } {
    if (value > 1024) {
      value = value / 1024;
      count++;
      return this.calculateTrafficValue(value, count);
    }
    if (value <= 1024) {
      let final = {finalValue: +value, countOfDevition: count};
      return final;
    }
  }

  getBytesSizeAfterCalc(value: number): TrafficValueAndSize {
    if (value) {
      let finalValue = this.calculateTrafficValue(value);
      let finalSize = this.getBytesSizeByValue(finalValue.countOfDevition);
      return {value: finalValue.finalValue, size: finalSize};
    }
    if (value == 0) {
      return {value: 0, size: TrafficUnits.KB};
    }
    return null;
  }

  /**
   * Divide given value
   * number of devition will be set by size
   */
  divideByTrafficUnit(value: number, size: TrafficUnits, kpiType?: KpiType) {
    let divideNumber: number = 0;
    const kb = 0;
    const mb = 1;
    const gb = 2;
    const tb = 3;

    switch (true) {
      case size == TrafficUnits.KB:
        divideNumber = kb;
        break;
      case size == TrafficUnits.MB:
      case size == TrafficUnits.MBps:
        divideNumber = mb;
        break;
      case size == TrafficUnits.GB:
        divideNumber = gb;
        break;
      case size == TrafficUnits.TB:
        divideNumber = tb;
        break;
      default:
        break;
    }

    let newSize = value;

    while (divideNumber > 0) {
      newSize = newSize / 1024;
      divideNumber--;
    }

    return newSize;
  }

  findGroupedTrendsUnits(groupedTrends: {}): TrafficValueAndSize {
    let highest: number = 0;
    Object.keys(groupedTrends).forEach(key => {
      if (groupedTrends[key] && groupedTrends[key].length > 0) {
        groupedTrends[key].forEach(trend => {
          if (trend && trend.value && trend.value > highest) {
            highest = trend.value;
          }
        });
      }
    });
    return {value: highest, size: TrafficUnits.KB};
  }

  findTrendsHighestValue(kpiTrends: KpiTrend[]) {
    let highest: number = 0;
    kpiTrends.forEach(trend => {
      trend.forEach((point => {
        if (point.value > highest) {
          highest = point.value;
        }
      }))
    })
    return this.getBytesSizeAfterCalc(highest);
  }

  /**
   * Change given value after comparing current Traffic unit with desired one
   */
  adujstTrafficUnits(value: number, valueUnit: TrafficUnits, desiredUnit: TrafficUnits): number {
    let numOfDevition: number;
    let valueUnitIndex = TrafficUnits.trafficUnitsAsArray().findIndex(unit => unit === valueUnit);
    let desiredUnitIndex = TrafficUnits.trafficUnitsAsArray().findIndex(unit => unit === desiredUnit);
    if (valueUnitIndex > desiredUnitIndex) {
      numOfDevition = valueUnitIndex - desiredUnitIndex;
      while (numOfDevition > 0) {
        value = value * 1024;
        numOfDevition--;
      }
      return value;
    }
    if (desiredUnitIndex > valueUnitIndex) {
      numOfDevition = desiredUnitIndex - valueUnitIndex;
      while (numOfDevition > 0) {
        value = value / 1024;
        numOfDevition--;
      }
      return value;
    }
    return value;
  }

  getTrendYValue(point: any) {
    if (point.hasOwnProperty('value')) {
      return point.value;
    }
    if (point.hasOwnProperty('y')) {
      return point.y;
    }
  }
}

