import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerNoSpaces'
})
export class LowerNoSpacesPipe implements PipeTransform {

  transform(key: any, ...args: any[]): any {
    if (key) {
      key = key.replace(key[0], key[0].toLowerCase());
      return key.replace(/ +/g, "");
    }
  }
}
