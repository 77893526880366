<app-netop-dialog>

    <app-netop-dialog-title (close)="closeDialog(false)">
        {{'data.MAINTENANCE.PERIODIC_BACKUP' | translate}}
    </app-netop-dialog-title>

    <app-netop-dialog-content [cssClass]="'periodic-dialog-content'">
        <ng-container>
            <form class="col branding-col-form-container" [formGroup]="scheduleBackupForm">
                <div class="m-3">
                    <mat-slide-toggle [labelPosition]="'before'" class="pr-2 pl-2 backup-toggle"
                        formControlName="backupEnabled">
                        {{'data.MAINTENANCE.BACKUP_ON' | translate}}
                    </mat-slide-toggle>
                </div>
                <ng-container *ngIf="isMulti">
                    <div>{{'data.MAINTENANCE.MULTI_SELECTION' | translate}}</div>
                </ng-container>

                <app-cron-generator [isDisabled]="!isBackupEnabled" (cronSelected)="onCronSelected($event)"
                    [defaultCron]="defaultCron">
                </app-cron-generator>

                <div class="mr-3 mb-3 mt-4 keep-for-container">
                    <span class="mr-4 keep-backups">Keep Backups For:</span>
                    <mat-form-field class="mat-form-field-fluid mr-3">
                        <mat-select class="dashboard-selection-container" formControlName="keepFor">
                            <mat-option *ngFor="let number of numbersArray" [value]="number">
                                {{number}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="mat-form-field-fluid">
                        <mat-label>period</mat-label>
                        <mat-select class="dashboard-selection-container" formControlName="period">
                            <mat-option *ngFor="let period of periodArray" [value]="period[0]" class="period-text">
                                {{period[1] | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </ng-container>
    </app-netop-dialog-content>

    <app-netop-dialog-actions>
        <!--buttons-->
        <div class="d-flex justify-content-end kt-margin-r-10">
            <mat-dialog-actions>
                <app-buttons (click)="closeDialog(false)" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
                    [btnText]="'data.COMMON.CANCEL'">
                </app-buttons>

                <app-buttons class="kt-margin-l-10" (click)="submitSchedule()" [btnStyle]="buttonColor" [btnClass]=""
                    [btnText]="'data.MAINTENANCE.BACKUP' | translate" [btnDisabled]="!isFormDirty && !isFormValid">
                </app-buttons>
            </mat-dialog-actions>
        </div>
    </app-netop-dialog-actions>

</app-netop-dialog>