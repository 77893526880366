import { Injectable } from '@angular/core';
import { IAppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { GlobalEntitiesService } from './global-entities.service';
import { take, mergeMap } from 'rxjs/operators';
import {Bandwidth, BandwidthLimit, BandwidthLimitHierarchy, BandwidthUtilization} from '../../models/bandwidth.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BandwidthService extends GlobalEntitiesService {


  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getBandwidthLimitHierarchyByTenant(tenantId: number): Observable<BandwidthLimitHierarchy> {
    return this.http.get<BandwidthLimitHierarchy>(`tenants/${tenantId}/bandwidth-limits/hierarchy`);
  }

  getBandwidthLimitHierarchy(): Observable<BandwidthLimitHierarchy> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.getBandwidthLimitHierarchyByTenant(tenantId))
    );
  }

  updateBandwidthLimitForTenant(tenantId: number, bandwidthLimit: BandwidthLimit): Observable<BandwidthLimit> {
    return this.http.post<BandwidthLimit>(`tenants/${tenantId}/bandwidth-limits`, bandwidthLimit);
  }

  updateBandwidthLimit(bandwidthLimit: BandwidthLimit): Observable<BandwidthLimit> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.updateBandwidthLimitForTenant(tenantId, bandwidthLimit))
    );
  }

  getTenantVenuesBandwidth(): Observable<Bandwidth[]> {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<Bandwidth[]>(`tenants/${tenantID}/bandwidth`)));
  }
  getTenantVenuesBandwidthById(tenantId: number): Observable<Bandwidth[]> {
    return this.http.get<Bandwidth[]>(`tenants/${tenantId}/bandwidth`);
  }

  getVenueBandwidth(venueId: number): Observable<Bandwidth> {
    return this.http.get<Bandwidth>(`venues/${venueId}/bandwidth`);
  }

  saveVenueBandwidth(bandwidth: Bandwidth): Observable<Bandwidth> {
    return this.http.post<Bandwidth>(`venues/${bandwidth.venueId}/bandwidth`, bandwidth);
  }

  getVenueUtilization(venueId: number) {
    return this.http.get<BandwidthUtilization>(`venues/${venueId}/utilization`);

  }
}
