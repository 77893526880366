<mat-list class="single-tplg-title">
  <div class="single-venue-fabrics-title">
    <span class="fabrics-list-header">{{listHeader | translate}}</span>
  </div>
  <div>
    <app-venue-fabric-legends
      *ngIf="showLegends"
      class="single-venue-fabric-legend-container"></app-venue-fabric-legends>
    <app-venue-fabric-toggle
      *ngIf="showToggles"
      [itemCounts]="itemCounts"
      [isLoading]="isLoading"
      (toggleChange)="filterFabrics($event)"></app-venue-fabric-toggle>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="fabricsDisplay?.length > 0">
    <div class="fabric-list"
         *ngIf="!showLoading || !isLoading"
         [style.height]="'calc('+listHeight+'vh - '+listHeightOffset+'px)'"
         [style.max-height]="listMaxHeight">
      <div *ngFor="let fabric of fabricsDisplay" class="row fabric-list-item"
           (click)="onFabricSelected(fabric?.fabric)"
           [ngStyle]="{'background-color': fabric?.fabric?.id== this.selectedFabricId ? '#eff6ff': null}"
           [ngbTooltip]="fabric.fabric.fabricStatus.status !== fabricStatus.COMPLETE ? tooltipContent: null"
           [tooltipClass]="'reg-tooltip-container-class'" placement="top-left"
      >
        <ng-template #tooltipContent>
          <div>
            <div class="row row-no-side-margin mb-2" *ngIf="fabric?.fabric?.fabricStatus?.status">
              <div [ngClass]="fabric.fabricColorClass">
                <strong>{{'data.VENUE_FABRICS.STATUS' | translate}} {{fabric?.fabric?.fabricStatus?.status}}</strong></div>
            </div>
            <div class="row row-no-side-margin  mb-2" *ngIf="fabric?.fabric?.fabricStatus?.reason">
              <strong
                class="mr-1">{{'data.VENUE_FABRICS.REASON' | translate}} </strong> {{ fabric?.fabric?.fabricStatus?.reason}}
            </div>
          </div>
        </ng-template>
        <div class="col icon-col">
          <app-icon-with-ellipse [iconPath]="fabric.fabricIconPath"
                                 [dotClass]="fabric.fabricColorClass"></app-icon-with-ellipse>
        </div>
        <div class="col align-center-column">
          <span class="fabric-name" [title]="fabric?.fabric?.name">{{fabric?.fabric?.name}}</span>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container *ngIf="showLoading && isLoading">
    <div class="fabrics-loading-container">
      <mat-spinner class="fabrics-loading" [diameter]="loadingDiameter" [strokeWidth]="loadingStrokeWidth"></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="(!fabricsDisplay || fabricsDisplay.length === 0) && (!isLoading || !showLoading)">
    <div class="no-fabrics-error-container">
      <p class="no-fabrics-error">{{'data.VENUES.NO_FABRICS_FOUND' | translate}}</p>
    </div>
  </ng-container>
</mat-list>
