<div class="row time-range-manager-row">
  <div class="col">
    <mat-button-toggle-group name="Range Picker" aria-label="Range Picker" (change)="onTimeToggleChanged($event)">
      <mat-button-toggle *ngFor="let toggle of toggleButtons"
                         [disabled]="toggle !== currentSelection && isDisableMode"
                         [checked]="toggle.range == currentSelection.range"
                         [value]="toggle" #tooltip="matTooltip"
                         [matTooltip]="toggle.tooltipText"
                         [matTooltipPosition]="'above'"
                         matTooltipClass="marker-tooltip">{{toggle.range}}
        <mat-icon *ngIf="toggle.tooltipText" svgIcon="information2"></mat-icon>
      </mat-button-toggle>

    </mat-button-toggle-group>
  </div>

  <div class="col date-picker-col">
    <mat-form-field class="time-range-manager-date-picker-field">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="formGroup?.controls['day']">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="hour-picker-field wrapper-same-as-picker-height">
      <input matInput [type]="'time'" [formControl]="formGroup?.controls['hour']">
    </mat-form-field>
  </div>

  <div class="col time-range-manager-button">
    <app-buttons class=""
                 (click)="resetTimeSelectionToNow()" [btnStyle]="'primary'"
                 [btnDisabled]="!isTimeDifferentThanDefault"
                 [btnText]="'data.STATISTICS.BACK_TO_NOW' | translate" [btnClass]="'time-range-button'">
    </app-buttons>
  </div>

  <div class="col time-range-manager-button">
    <app-buttons class="" [btnDisabled]="!(isDirty$ | async)"
                 (click)="submitTimeSelection()" [btnStyle]="'primary'"
                 [btnText]="'data.COMMON.APPLY' | translate" [btnClass]="'time-range-button'">
    </app-buttons>
  </div>
</div>
