<!--Dialog Title-->
<app-netop-dialog [className]="'action-kpi-dialog'">
  <app-netop-dialog-title (close)="closeDialog()" [cssClass]="'single-entity-action-dialog-title-text'">
    {{action?.description}}
  </app-netop-dialog-title>

  <app-netop-dialog-content [cssClass]="'single-entity-action-dialog-content-container'">
    <div class="row single-entity-action-sub-row-container">
      <div class="col">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onTimeToggleChanged($event)">
          <mat-button-toggle [checked]="selectedRange === timeRange.XNarrow" [value]="timeRange.XNarrow">2h
          </mat-button-toggle>
          <mat-button-toggle [value]="timeRange.Narrow">48h
          </mat-button-toggle>
          <mat-button-toggle [value]="timeRange.Wide">6d</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <ng-container *ngIf="!isSummary" [ngTemplateOutlet]="suggestedOperationsDisplay">

    </ng-container>
    <ng-container *ngIf="isSummary">
      <div class="action-kpi-insight-container">
        <app-insight-resolutions-display [summary]="summary" [rootCauses]="rootCauses" [solutions]="solutions"
                                         [iconsName]="icons"
                                         [insightsDisplayClass]="'analyze-pop-over-inside-dialog'"></app-insight-resolutions-display>
      </div>
    </ng-container>

    <!--KPI and "Top" data row-->

    <div class="row row-no-side-margin venue-deviation-kpi-row">

      <!--KPI charts section-->

      <div class="col">
        <div [showSpinner]="isLoading">
          <app-kpis-trends *ngIf="kpiTrends" [kpiTrends]="kpiTrends" [height]="graphTrendHeight" [width]="450"
                           [hideTooltip]="true"
                           [annotations]="annotations" [isResponsive]="true">
          </app-kpis-trends>
        </div>
      </div>

      <!--"Top" data section-->

      <div class="col dev-rep-bars-container-col-direction">
        <div class="action-kpi-top-container" [ngStyle]="isConnectionFailureDisplay? {'margin-bottom': 0}: ''">

          <!-- Top apps template-->

          <ng-container *ngIf="!isConnectionFailureDisplay" [ngTemplateOutlet]="topAppsTemplate">
          </ng-container>

          <!-- Template For connection failure anomaly only-->
          <ng-container *ngIf="isConnectionFailureDisplay" [ngTemplateOutlet]="connectionFailuresTemplate">
          </ng-container>
        </div>
        <div class="action-kpi-top-container">

          <!-- Top Devices template-->
          <ng-container [ngTemplateOutlet]="topClientsTemplate">
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Time Visualization section-->

    <div class="row row-no-side-margin action-kpi-data-time-visualization-row"
         *ngIf="timeSpans?.length > 0 || sparkline?.datasets?.length > 0">
      <div class="col action-kpi-time-display-col-prefix">
        <span> {{dataTimePrefix}}</span>
      </div>
      <div class="col action-kpi-time-display-col-visualization">
        <ng-container *ngIf="timeSpans?.length > 0">
          <app-horizontal-timeline [items]="timeSpans" [marker]="markerData" [height]="20" [width]="500">
          </app-horizontal-timeline>

          <ng-container *ngIf="timeSpans?.length == 0">
            <div class="no-data-text"> Missing status
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="sparkline?.datasets?.length > 0">
          <app-sparkline [sparklineConfig]="sparkline" [showAxis]="true" [maxTicksLimitX]="2" [maxTicksLimitY]="2"
                         [stacked]="true" [showLegend]="true" [showTooltip]="true"
                         [showOnlyMarkerPoint]="false" [height]="100" [width]="500">
          </app-sparkline>
        </ng-container>
      </div>
    </div>
  </app-netop-dialog-content>

  <app-netop-dialog-actions [cssClass]="actionsClass">
    <ng-container *ngIf="!isStatusComplete">
      <app-buttons *ngFor="let step of action?.nextPossibleStates" class="single-entity-action-padding"
                   (click)="onAction(step)" [btnStyle]="'primary'" [btnVariant]="getActionButtonVariant(step)"
                   [btnText]="ActionStatus.getActionStatusName(step)">
      </app-buttons>
    </ng-container>
    <ng-container *ngIf="isStatusComplete || !action?.nextPossibleStates || action?.nextPossibleStates?.length == 0">
      <div class="pr-4 single-entity-action-suggested-text-status-container">
        <div class="single-entity-action-suggested-text-status">
            <span
              class="single-entity-action-suggested-text-no-operation pr-5">{{'data.ACTIONS.NO_OPERTIONS_AVAILABLE' | translate}}</span>
          <span>{{'data.DASHBOARD.STATUS' | translate}}:</span>
          <span class="pl-1"><strong>{{action?.status}}</strong></span>
        </div>
      </div>
    </ng-container>
  </app-netop-dialog-actions>
</app-netop-dialog>


<ng-template #suggestedOperationsDisplay>
  <div class="single-entity-action-suggested-container">
                <span
                  class="single-entity-action-suggested-text-key">{{'data.ACTIONS.SUGGESTED_OPEARTIONS' | translate}}:
                </span>
    <span class="single-entity-action-suggested-text">{{action?.suggestedOperation}}</span>
  </div>
</ng-template>


<ng-template #topAppsTemplate>
  <span class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_APPS' | translate}}
    {{topApps$ | async | totalFromTop}}</span>
  <div class="dev-rep-progress-list" [ngStyle]="{'height': graphTrendHeight * .925 + 'px'}">
    <ng-container *ngFor="let progressBar of topApps$ | async | progressBarConvertor : fullColorsList[6]">
      <div class="just-flex dev-rep-dialog-progress-bar-container">
        <app-basic-progress-bar [progressBar]="progressBar">
        </app-basic-progress-bar>
      </div>
    </ng-container>
    <ng-container *ngIf="!(topApps$ | async | progressBarConvertor)">
      <div class="error-message-text">
        {{'data.ERRORS.NO_DATA' | translate}}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #topClientsTemplate>
  <div class="dev-rep-bars-container-with-back">
    <span class="dev-rep-bar-header">{{'data.REPORTS.TOP_VENUE_CLIENTS' | translate}}
      {{topClients$ | async | totalFromTop}}</span>
    <div class="teleported-back-to-venue"></div>
  </div>
  <div class="dev-rep-progress-list" [ngStyle]="{height: graphTrendHeight * .925 + 'px'}">
    <ng-container
      *ngFor="let progressBar of topClients$ | async | progressBarConvertor : fullColorsList[5]">
      <div class="just-flex dev-rep-dialog-progress-bar-container">
        <app-basic-progress-bar [progressBar]="progressBar">
        </app-basic-progress-bar>
      </div>
    </ng-container>
    <ng-container *ngIf="!(topClients$ | async | progressBarConvertor)">
      <div class="error-message-text">
        {{'data.ERRORS.NO_DATA' | translate}}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #connectionFailuresTemplate>
  <span class="dev-rep-bar-header chart">{{'data.KPI.CONNECTION_FAILURES' | translate}}</span>
  <div class="kpi-dialog-connection-failure" [ngStyle]="">
    <ng-container *ngIf="connectionFailureTrend$ | async as connectionFailure">
      <app-single-kpi-display *ngIf="connectionFailure?.length > 0"
                              [tooltipMinWidth]="100"
                              [kpiType]="KpiType.Connection_Failures" [graphHeight]="graphTrendHeight * .9275"
                              [graphWidth]="100" [kpiTrend]="connectionFailure"
                              [verticalOrTrend]="VerticalTrend.Vertical"></app-single-kpi-display>
      <ng-container *ngIf="connectionFailure?.length === 0">
        <div class="error-message-text">
          {{'data.ERRORS.NO_DATA' | translate}}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
