<ng-container>
  <div #topologyContainer class="topology-container">
    <app-topology-header [isDevice]="isDevice" [disConnectedDevices]="disConnectedNodes"
                         [isFabricsVenue]="isFabricsVenue" [selectedFabricName]="selectedFabricName"
                         [isClients]="isClients"
                         [isPropertiesOpen]="isPropertiesOpen"
                         [treeConfiguration]="treeConfiguration" [topologyConfigruation]="topologyConfiguration"
                         [selectedFabricName]="selectedFabricName" [d3Topologydata]="d3TopologyData"
                         (changedTreeConfiguration)="onTopologyChanges($event)" [title]="title">
    </app-topology-header>

    <app-topology-main #topologyMain [treeConfiguration]="treeConfiguration"
                       [topologyConfiguration]="topologyConfiguration"
                       [selectedFabricTopology]="selectedFabricTopology" [isPropertiesOpen]="isPropertiesOpen"
                       [isDevice]="isDevice" [tplgRect]="containerRect" [isTopologyGenerated]="isTopologyGenerated">
    </app-topology-main>

    <app-topology-footer [isDevice]="isDevice" [isWidget]="isWidget">
      <ng-content select="[topologyLeftFotter]" leftFooter></ng-content>
    </app-topology-footer>
  </div>
</ng-container>
