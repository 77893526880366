import {CloudLinkType} from './cloud-network.model';
import {SingleD3Node} from './topology';

export class BasicLink {
  id: string | number;
  type: SingleLinkType | CloudLinkType;
  status?: SingleLinkStatus;
  description?: string;
  startDeviceId: string | number;
  endDeviceId: string | number;
  isZippedMode?: boolean;
  isOutsideBasicDataSource?: boolean;
}


export class SingleLink extends BasicLink {
  logicalConnections?: { id: number, type: ConnectionType, name: string, externalId: string }[];
  startPort?: string;
  startPortDetails?: { key: string, value: string | number } | {};
  endPort?: string;
  endPortDetails?: { key: string, value: string | number } | {};
  interface1?: NetworkInterface;
  interface2?: NetworkInterface;
  origin?: LinkVendorStatus;
}

export class NetworkInterface {
  id: number;
  name: string;
  description: string;
  port: string;
  type: NetworkInterfaceType;
}

export class D3Link {
  source: SingleD3Node<any>;
  target: SingleD3Node<any>;
  data?: BasicLink | SingleLink;
}

export enum SingleLinkType {
  Wired = 'WIRED',
  Wireless = 'WIRELESS',
  Fake = 'Fake',
  Zipper = 'Zipper',
  To_Be_Zipped = "To_Be_Zipped",
  Swith_Stack = 'Switch_Stack'
}

export enum NetworkInterfaceType {
  LAN = 'LAN',
  WAN = 'WAN'
}

export enum LinkVendorStatus {
  VENDOR = 'VENDOR',
  MULTI_VENDOR = 'MULTI_VENDOR'
}

export enum SingleLinkStatus {
  OK = 'OK',
  Warning = 'Warning',
  Error = 'Error'
}

export enum ConnectionType {
  SSID = 'SSID',
  VLAN = 'VLAN'
}

export enum PathStyle {
  CURVE = 1,
  RIGHT_ANGLE = 2
}
