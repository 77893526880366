import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProgressBarDTO } from './models/progress-bar.model';

@Component({
  selector: 'app-basic-progress-bar',
  templateUrl: './basic-progress-bar.component.html',
  styleUrls: ['./basic-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicProgressBarComponent implements OnInit {
  @Input() progressBar: ProgressBarDTO<any>;
  constructor() { }

  ngOnInit() {
  }

}
