<div [classByCondition]="isObscurityMode" [cssClasses]="['obscurity-canvas']">
  <app-vertical-bar [showSpinner]="isLoading" [verticalBarDatasets]="verticalBarDatasets"
                    [isWithArrowDisplay]="true"
                    [suggestedMaxY]="highestActionsNumPerDay + 6"
                    [isObscurityChart]="true" [hideLabels]="true"
                    [height]="verticalBarHeight" [width]="120" (barClicked)="onBarClicked($event)"
                    [maxTicksY]="3" [drawAxisBorderOnly]="true" class="p-0"
  >
  </app-vertical-bar>
</div>
