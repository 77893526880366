<!--Dialog Title-->
<app-netop-dialog>
        <app-netop-dialog-title (close)="closeDialog(false)">
                {{captionText}}
        </app-netop-dialog-title>
        <app-netop-dialog-content>
                <p class="confirmation-content">{{dialogData?.beforeQuestion}} {{questionText}}
                        {{dialogData?.action.toLowerCase()}}?</p>
        </app-netop-dialog-content>

        <ng-container>
                <app-netop-dialog-actions class="confirmation-buttons" [cssClass]="'confirmation-buttons'">
                        <app-buttons [btnStyle]="'primary'" [btnClass]="" [btnText]="'data.COMMON.NO'"
                                (click)="closeDialog(false)">
                        </app-buttons>
                        <app-buttons class="m-2" [btnStyle]="'primary'" [btnClass]="" [btnText]="'data.COMMON.YES'"
                                (click)="closeDialog(true)">
                        </app-buttons>
                </app-netop-dialog-actions>
        </ng-container>
</app-netop-dialog>