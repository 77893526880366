import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import moment from 'moment';
import { Observable, combineLatest, of } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { IAppState } from "src/app/store/state/app.state";
import { PredictionDatasetsName } from "src/app/venues/venues-single-components/legacy/models/venue-kpis.model";
import { DeviceStatus } from "../../models/devices.model";
import { EntityType } from "../../models/entity-type.enum";
import { KpiData, KpiType, KpiValueChange, EntityTypeWithIds, KpiTrend, KpiGroupedBy, TrafficKpiDataDisplay, KpiTrendByDestination, AggregatedKpiTrendByDestination, TrafficValueAndSize, TrafficUnits } from "../../models/kpi.model";
import { drawArrow } from "../../operators/encoded-arrow";
import { KpiDataService } from "../strategies/kpi-data.service";
import { TimeManagerService } from "../time-manager.service";
import { GlobalEntitiesService } from "./global-entities.service";
import { TimeUnit } from "../../models/time.model";

class TimePeriodLimiter {
  timeBack: number;
  timeUnit: TimeUnit;
}

@Injectable({
  providedIn: 'root'
})
class KPIHttpHandler extends HttpHandler {
  limiter: TimePeriodLimiter = {
    timeBack: 6,
    timeUnit: TimeUnit.DAYS
  }

  constructor(private readonly next: HttpHandler) {
    super();
  }

  /** @override */ handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (req.url.includes('/trend')) {
      const params = req.url.split('?')[1].split('&')
        .map(param => param.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        timeUnit: TimeUnit = TimeUnit[params['timeUnit']],
        timeBack: number = parseInt(params['timeBack']),
        limitedParams = this.limit({ timeUnit, timeBack });

      params['timeUnit'] = limitedParams.timeUnit;
      params['timeBack'] = limitedParams.timeBack.toString();

      delete params['NaN'];
      const newParams = `?${new URLSearchParams(params).toString()}`,
        clonedRequest = req.clone({
          url: req.url.split('?')[0] + newParams
        });

      return this.next.handle(clonedRequest);
    }
    else return this.next.handle(req);
  }

  limit(params: { timeUnit: TimeUnit, timeBack: number }): TimePeriodLimiter {
    if (TimeUnit.milliseconds(params.timeUnit) > TimeUnit.milliseconds(this.limiter.timeUnit)) {
      params.timeUnit = this.limiter.timeUnit;
      params.timeBack = this.limiter.timeBack;
    }

    if (TimeUnit.minutes(params.timeBack, params.timeUnit) > TimeUnit.minutes(this.limiter.timeBack, this.limiter.timeUnit)) {
      params.timeBack = this.limiter.timeBack;
    }

    return { ...params };
  }
}

@Injectable({
  providedIn: 'root'
})
export class KpiService extends GlobalEntitiesService {
  private http: HttpClient;

  constructor(private kpiHttpHandler: KPIHttpHandler, store: Store<IAppState>, private kpiDataService: KpiDataService,
    private dateConvertor: TimeManagerService) {
    super(store);
    this.http = new HttpClient(kpiHttpHandler);
  }

  widgetsData: KpiData[];

  /**
   * Get the current kpi value and it's change relative to last value
   * @param kpiType The kpi to get value for
   * @param calculateKpiChange do we need a change of the kpi relative to last value
   */
  getKpiCurrentValue(kpiType: KpiType, calculateKpiChange: boolean = false) {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantID]) => {
        return this.http.get<KpiValueChange>(`kpis/${kpiType}/${currentEntity.type}/${currentEntity.id}/latest?calculateKpiChange=${calculateKpiChange}&tenantId=${tenantID}`)
      }
      ),
      map((kpi) => new KpiValueChange(kpi.value, kpi.change))
    )
  }

  /**
   * Get the current kpi value and it's change relative to last value
   * @param kpiType The kpi to get value for
   * @param time
   */
  getKpiCurrentValueByTime(kpiType: KpiType, time: string) {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntitiy, tenantID]) => this.http.get<KpiValueChange>
        (`kpis/${kpiType}/${currentEntitiy.type}/${currentEntitiy.id}/last/hour?tenantId=${tenantID}&time=${time}`)
      ),
      map((kpi) => new KpiValueChange(kpi.value, kpi.change))
    )
  }

  getKpiPeriodSumPerId(entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    entityTypeWithIds: EntityTypeWithIds,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS): Observable<{ [id: number]: number }> {
    return this.http.post<{ [id: number]: number }>(`kpis/${kpiType}/${entityType}/${entityId}/period-sum-per-id?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${specificDate}&tenantId=${tenantId}`, entityTypeWithIds);
  }

  getCurrentKpiPeriodSumPerId(kpiType: KpiType,
    entityTypeWithIds: EntityTypeWithIds,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS): Observable<{ [id: number]: number }> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => this.getKpiPeriodSumPerId(currentEntity.type, currentEntity.id, tenantId, kpiType, entityTypeWithIds, fromDate, specificDate, timeUnit))
    );
  }

  getKpiTrend(
    entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    deviceIds: (number | string)[] = []): Observable<KpiTrend> {
    if (entityType != EntityType.ORGANIZATION) {
      const requestUrl = specificDate
        ? `kpis/${kpiType}/${entityType}/${entityId}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${specificDate}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`
        : `kpis/${kpiType}/${entityType}/${entityId}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`;
      return this.http.get<KpiTrend>(`${requestUrl}`).pipe(
        map(trend => trend ? this.formatTrendToMoment(trend) : null)
      );
    }
    return of(null);
  }

  getCurrentKpiTrend(kpiType: KpiType,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    deviceIds: (number | string)[] = []): Observable<KpiTrend> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => this.getKpiTrend(currentEntity.type, currentEntity.id, tenantId, kpiType, fromDate, specificDate, timeUnit, deviceIds))
    );
  }

  getKpiTrendByIds(kpiType: KpiType, entityType: EntityType, entityID: number, tenantID: number = 1, fromDate: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS, deviceIds: (number | string)[] = []) {
    let requestUrl: string;
    specificDate ?
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${specificDate}&tenantId=${tenantID}&deviceIds=${deviceIds.join(',')}` :
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantID}&deviceIds=${deviceIds.join(',')}`;
    return this.http.get<KpiTrend>(requestUrl).pipe(
      map((trend) => {
        return this.formatTrendToMoment(trend);
      })
    )
  }

  formatTrendToMoment(trend: KpiTrend): KpiTrend {
    trend = trend.map((point: any) => {
      return {
        datetime: moment(point.datetime || point.date),
        value: +point.value.toFixed(2)
      }
    })
    return trend;
  }

  getMultiKpiTrends(timeBack: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS, groupedBy: KpiGroupedBy = undefined): Observable<TrafficKpiDataDisplay[]> {
    return groupedBy == KpiGroupedBy.Traffic ?
      this.getCurrentKpiSplitTrend(KpiType.Traffic, timeBack, specificDate, timeUnit) :
      this.getCurrentKpiGroupedTrend(KpiType.Traffic, groupedBy, timeBack, specificDate, timeUnit);
  }

  getKpiGroupedTrend(
    entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    groupedBy: KpiGroupedBy,
    timeBack: number,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    isMultiKpiDialog?: boolean,
    deviceIds: (number | string)[] = []): Observable<TrafficKpiDataDisplay[]> {
    let type: EntityType;
    isMultiKpiDialog ? type = EntityType.VENUE : type = entityType;

    let requestUrl: string;
    specificDate ?
      requestUrl = `kpis/${kpiType}/${type}/${entityId}/grouped/${groupedBy}/trend?timeBack=${timeBack}&timeUnit=${timeUnit}&specificDate=${specificDate}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}` :
      requestUrl = `kpis/${kpiType}/${type}/${entityId}/grouped/${groupedBy}/trend?timeBack=${timeBack}&timeUnit=${timeUnit}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`;
    return this.http.get<any>(requestUrl).pipe(
      map(groupedTrends => {
        {
          switch (groupedBy) {
            case KpiGroupedBy.Application:
            case KpiGroupedBy.Failure_Step: {
              return this.formatTrendsToStacked(groupedTrends, kpiType);
            }
            case KpiGroupedBy.Wired: {
              return this.formatTrendToSplit(groupedTrends, kpiType);
            }
          }
        }
      })
    );
  }

  getCurrentKpiGroupedTrend(kpiType: KpiType,
    groupedBy: KpiGroupedBy,
    timeBack: number,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    isMultiKpiDialog?: boolean,
    deviceIds: (number | string)[] = []): Observable<TrafficKpiDataDisplay[]> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => this.getKpiGroupedTrend(currentEntity.type, currentEntity.id, tenantId, kpiType, groupedBy, timeBack, specificDate, timeUnit, isMultiKpiDialog, deviceIds))
    );
  }

  getKpiGroupedTrendByIds(kpiType: KpiType, entityType: EntityType, entityID: number, tenantID: number, groupedBy: KpiGroupedBy = KpiGroupedBy.Application, fromDate: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS): Observable<TrafficKpiDataDisplay[]> {
    let requestUrl: string;
    let isoDate: string;
    //28.1.21 - Imry:
    //Currently this api work with ISO Format while other trends kpi uses the simple string format
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = undefined;
    isoDate ?
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/grouped/${groupedBy}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}&tenantId=${tenantID}` :
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/grouped/${groupedBy}/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&tenantId=${tenantID}`;
    return this.http.get<any>(requestUrl).pipe(
      map((groupedTrends) => {
        return this.formatTrendsToStacked(groupedTrends, kpiType);
      })
    )
  }

  getKpiSplitTrend(
    entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    deviceIds: (number | string)[] = []): Observable<TrafficKpiDataDisplay[]> {
    const requestUrl = specificDate
      ? `kpis/${kpiType}/${entityType}/${entityId}/split/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${specificDate}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`
      : `kpis/${kpiType}/${entityType}/${entityId}/split/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`;
    return this.http.get<any>(requestUrl).pipe(
      map(groupedTrends => this.formatTrendToSplit(groupedTrends, kpiType))
    );
  }

  getCurrentKpiSplitTrend(kpiType: KpiType,
    fromDate: number = 7,
    specificDate: string = undefined,
    timeUnit: TimeUnit = TimeUnit.DAYS,
    deviceIds: (number | string)[] = []): Observable<TrafficKpiDataDisplay[]> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => this.getKpiSplitTrend(currentEntity.type, currentEntity.id, tenantId, kpiType, fromDate, specificDate, timeUnit, deviceIds))
    );
  }

  getKpiSplitTrendByIds(kpiType: KpiType, entityType: EntityType, entityID: number, tenantID: number, fromDate: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS): Observable<TrafficKpiDataDisplay[]> {
    let requestUrl: string;
    let isoDate: string;
    //28.1.21 - Imry:
    //Currently this api work with ISO Format while other trends kpi uses the simple string format
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = undefined;
    isoDate ?
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/split/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}&tenantId=${tenantID}` :
      requestUrl = `kpis/${kpiType}/${entityType}/${entityID}/split/trend?timeBack=${fromDate}&timeUnit=${timeUnit}&tenantId=${tenantID}`;
    return this.http.get<any>(requestUrl).pipe(
      map((groupedTrends) => {
        return this.formatTrendToSplit(groupedTrends, kpiType);
      }))
  }

  getKpiTrendByDestination(
    entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    timeBack: number = 2,
    timeUnit: TimeUnit = TimeUnit.HOURS,
    specificDate: Date | string = undefined,
    deviceIds: (number | string)[] = []): Observable<KpiTrendByDestination> {
    const date = typeof specificDate == 'string' ? specificDate : specificDate?.toISOString();
    const requestUrl = date
      ? `kpis/${kpiType}/${entityType}/${entityId}/trend-by-destination?timeBack=${timeBack}&timeUnit=${timeUnit}&specificDate=${date}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`
      : `kpis/${kpiType}/${entityType}/${entityId}/trend-by-destination?timeBack=${timeBack}&timeUnit=${timeUnit}&deviceIds=${deviceIds.join(',')}&tenantId=${tenantId}`;
    return this.http.get<KpiTrendByDestination>(`${requestUrl}`);
  }

  getCurrentKpiTrendByDestination(kpiType: KpiType,
    timeBack: number = 2,
    timeUnit: TimeUnit = TimeUnit.HOURS,
    specificDate: Date | string = undefined,
    deviceIds: (number | string)[] = []): Observable<KpiTrendByDestination> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => {
        if (currentEntity.type !== EntityType.ORGANIZATION) {
          return this.getKpiTrendByDestination(currentEntity.type, currentEntity.id, tenantId, kpiType, timeBack, timeUnit, specificDate, deviceIds);
        }
        return of({});
      })
    );
  }

  getAggregatedKpiTrendByDestination(entityType: EntityType,
    entityId: number,
    tenantId: number,
    kpiType: KpiType,
    timeBack: number = 2,
    timeUnit: TimeUnit = TimeUnit.HOURS,
    specificDate: Date | string = undefined,
    deviceIds: (number | string)[] = []): Observable<AggregatedKpiTrendByDestination[]> {
    return this.getKpiTrendByDestination(entityType, entityId, tenantId, kpiType, timeBack, timeUnit, specificDate, deviceIds).pipe(
      take(1),
      map(data => {
        const results: AggregatedKpiTrendByDestination[] = [];
        Object.keys(data).forEach(destination => {
          let sum = 0;
          data[destination].forEach(point => sum += point.value);
          results.push({ id: destination, value: +(sum / data[destination].length)?.toFixed(2) });
        });
        return results;
      })
    );
  }

  getCurrentAggregatedKpiTrendByDestination(kpiType: KpiType,
    timeBack: number = 2,
    timeUnit: TimeUnit = TimeUnit.HOURS,
    specificDate: Date | string = undefined,
    deviceIds: (number | string)[] = []): Observable<AggregatedKpiTrendByDestination[]> {
    return combineLatest([this.currentEntity$, this.tenantId$]).pipe(
      take(1),
      mergeMap(([currentEntity, tenantId]) => {
        if (currentEntity.type != EntityType.ORGANIZATION) {
          return this.getAggregatedKpiTrendByDestination(currentEntity.type, currentEntity.id, tenantId, kpiType, timeBack, timeUnit, specificDate, deviceIds);
        }
        return [];
      })
    );
  }

  formatTrendToSplit(groupedTrends: any, kpiType: KpiType): TrafficKpiDataDisplay[] {
    let valueAndUnit: TrafficValueAndSize = this.kpiDataService.findGroupedTrendsUnits(groupedTrends);
    let kpiGroupedTrends: TrafficKpiDataDisplay[] = [];
    for (let [groupName, groupTrends] of Object.entries(groupedTrends)) {
      groupName = KpiService.getSplitKeys(groupName);
      kpiGroupedTrends.push(
        {
          type: groupName,
          data: (groupTrends as Array<any>).map((point: any) => {
            return {
              x: new Date(point.date || point.datetime),
              y: point.value
            }
          }),
          unit: kpiType === KpiType.Throughput ? TrafficUnits.MBps : valueAndUnit.size
        })
    }
    return kpiGroupedTrends;
  }

  formatTrendsToStacked(groupedTrends: any, kpiType: KpiType): TrafficKpiDataDisplay[] {
    let valueAndUnit: TrafficValueAndSize = this.kpiDataService.findGroupedTrendsUnits(groupedTrends);
    let kpiGroupedTrends: TrafficKpiDataDisplay[] = [];
    for (const [groupName, groupTrends] of Object.entries(groupedTrends)) {
      kpiGroupedTrends.push(
        {
          type: groupName,
          data: (groupTrends as Array<any>).map((point: any) => {
            return {
              x: moment(point.datetime || point.date),
              y: point.value
            }
          }),
          unit: valueAndUnit.size
        })
    }
    return kpiGroupedTrends;
  }

  /**
   * Get the current kpi value and it's change relative to last value
   * @param fabricId
   * @param calculateKpiChange do we need a change of the kpi relative to last value
   */
  getKpiSelectedFabricCurrentValue(fabricId: number, calculateKpiChange: boolean = false) {
    return this.tenantId$.pipe(
      take(1),
      mergeMap((tenantID) => this.http.get<KpiValueChange>
        (`kpis/${KpiType.Health}/${EntityType.FABRIC}/${fabricId}/latest?calculateKpiChange=${calculateKpiChange}&tenantId=${tenantID}`)
      ))
  }

  getKpiDeviceStatus(deviceId: number, fromDate: number = 7, specificDate: string = undefined, timeUnit: TimeUnit = TimeUnit.DAYS): Observable<DeviceStatus[]> {
    let isoDate: string;
    specificDate ?
      isoDate = this.dateConvertor.convertJSIsoToSpring(new Date(specificDate)) :
      isoDate = undefined;
    return this.http.get<DeviceStatus[]>(`kpis/${KpiType.Status}/Device/${deviceId}/device-status?timeBack=${fromDate}&timeUnit=${timeUnit}&specificDate=${isoDate}`);
  }

  private static getSplitKeys(groupName: string) {
    switch (true) {
      case groupName.includes("Received"): {
        return "In";
      }
      case groupName.includes("Sent"): {
        return "Out";
      }
      case groupName.includes("Upspeed"): {
        return drawArrow('up');
      }
      case groupName.includes("Downspeed"): {
        return drawArrow('down');
      }
      case groupName.includes("Wireless"): {
        return "Wireless";
      }
      case groupName.includes("Wired"): {
        return "Wired";
      }
      case groupName.includes("TrafficPredictionHighBound"): {
        return PredictionDatasetsName.Higher;
      }
      case groupName.includes("TrafficPredictionLowBound"): {
        return PredictionDatasetsName.Lower;
      }
      case groupName.includes("TrafficPrediction"): {
        return PredictionDatasetsName.Prediction;
      }
      case groupName.includes("TrafficActual"): {
        return PredictionDatasetsName.Actual;
      }
      default:
        break;
    }
  }
}
