<div class="row dialog-properties-header-container no-gutters">
    <!-- <div class="dialog-properties-container-raw"> -->
    <span mat-dialog-title class="col dialog-properties-header" [ngClass]="cssClass" (click)="onTitleClicked()">
        <ng-content></ng-content>
    </span>
    <span class="col dialog-properties-close-icon-container" [ngClass]="closeIconClass" *ngIf="closeIcon">
        <button class="dialog-properties-close-icon" mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </span>
    <!-- </div> -->
</div>