import { Directive, Input, ElementRef } from '@angular/core';
import { D3Service } from '../services/d3.service';

@Directive({
  selector: '[appD3Zoomable]'
})
export class D3ZoomableDirective {

  @Input('appD3Zoomable') zoomableOf: ElementRef;

  constructor(private d3Service: D3Service, private _element: ElementRef) { }

  ngOnInit() {
    this.d3Service.applyZoomableDraggableBehaviour(this.zoomableOf, this._element.nativeElement);
  }
}
