import { VLAN } from "./vlan.model";
import { SSID } from "./ssid.model";
import { EntityWithPolicies } from "./policies.model";
import { Topology } from "./topology";
import { SingleDevice } from "./single-device.model";
import { SingleLink } from "./single-link.model";

export class FabricKpiData {
  fabric: Fabric;
  kpis: {
    latency: number,
    users: number,
    traffic: number,
    utilization: number,
    loss: number,
  };
  health: string;
  healthDegradationReasons?: { percentage?: number, reason: string }[]
  iconPath?: string;
}

export class FabricTotalData {
  fabricData: EntityWithPolicies<FabricKpiData>;
  fabricTopology: Topology<SingleDevice, SingleLink>;
  health: number;
}

export interface FabricSummary {
  id: number;
  name: string;
  numOfAP: number;
  numOfFWs: number;
  numOfSDWAN: number;
  numOfSwitches: number;
}

export interface newFabric {
  fabricType: string;
  fabricName: string;
  password: string;
}

export class Fabric {
  id: number;
  name: string;
  fabricStatus: FabricStatusDTO;
  properties: { [property: string]: string };
  connectedClients: number;
  tenantId: number;
  ssids: SSID[];
  vlans: VLAN[];
}

export class FabricStatusDTO {
  status: FabricStatus;
  category: MisconfigurationCategory;
  reason: string;
  solution: string;
}
export class FabricAdvancedParameters {
  vlanParameters: [{ [name: string]: string }]
  ssidParameters: [{ [name: string]: string }]
}

export class FabricsHealth {
  id: number;
  fabricName: string;
  fabricHealth: number;
  fabricState: FabricStatus;
  statusIndicator?: string; // e.g. "MOST_SEVERE"
  parentVenueName: string;
  numDevices: number;
}

export enum FabricStatus {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
  NoWwwConnectivity = "NoWwwConnectivity"
}

export enum FabricToggleStatus {
  ALL = "All",
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete"
}

export enum FabricAccessControl {
  PUBLIC = "PUBLIC",
  PSK = "PSK"
}
export class CreatFabricParameters {
  fabricConfig: CreateFabricConfig;
  jsonParameters: {};

  constructor(fabricConfig: CreateFabricConfig) {
    this.fabricConfig = fabricConfig;
    this.jsonParameters = {
      "ssid-settings": this.fabricConfig.toJson(),
    }
  }
}

export interface RADIUSserver {
  host: string;
  port: string;
  secret: string
}

export class CreateFabricConfig {
  enabled: boolean = true;
  name: string;
  accessControl: {
    type: FabricAccessControl,
    value?: string
  }

  constructor() {
    this.accessControl = { type: FabricAccessControl.PUBLIC }
  }

  toJson() {
    let accessControl = !this.accessControl || this.accessControl.type == FabricAccessControl.PUBLIC ? {
      type: FabricAccessControl.PUBLIC
    } : {
      type: this.accessControl.type,
      value: this.accessControl.value
    }
    return {
      enabled: this.enabled,
      name: this.name,
      "network-access-control": accessControl
    }
  }
}

export class TrafficLimits {
  maxOutbound: number;
  maxInbound: number;

  toJson?() {
    return {
      "max-inbound": this.maxInbound ? this.maxInbound : 0,
      "max-outbound": this.maxOutbound ? this.maxOutbound : 0
    }
  }

  emptyValues() {
    return {
      "max-inbound": 0,
      "max-outbound": 0
    }
  }
}

export class FabricListDisplay {
  fabric: Fabric;

  constructor(fabric: Fabric) {
    this.fabric = fabric;
  }

  get fabricIconPath() {
    if (this.fabric.fabricStatus.status !== FabricStatus.COMPLETE && this.fabric.fabricStatus.category) {
      return `assets/media/netop/${this.fabric.fabricStatus.category.toLowerCase()}.svg`;
    }
    return 'assets/media/netop/v-icon.svg';
  }

  get fabricColorClass() {
    if (this.fabric.fabricStatus.status !== FabricStatus.COMPLETE) {
      return `venue-fabric-${this.fabric.fabricStatus.status.toLowerCase()}`
    }
    return null;
  }
}

export enum FabricType {
  GUEST_NETWORK = "GUEST_NETWORK",
  SMB_ORG = "SMB_NETWORK",
  FREE_PUBLIC_WIFI = "FREE_PUBLIC_WIFI",
  CONFERENCE_ROOMS = "VOIP_NETWORK",
  IoT = "IoT",
  ENTERPRISE_ORG = "Organization (Enterprise) Network"
}

export namespace FabricType {
  export function getFabricTypeList(): { id: number, name: string, type?: FabricType, status?: string }[] {
    return [
      { id: 0, name: "-- Select Fabric Type --" },
      { id: 1, name: "Guest Network", nameAndStatus: "Guest Network", type: FabricType.GUEST_NETWORK },
      {
        id: 2,
        name: "Organization (SMB) Network",
        nameAndStatus: "Organization (SMB) Network",
        type: FabricType.SMB_ORG
      },
      { id: 3, name: "Conference Rooms/VOIP", nameAndStatus: "Conference Rooms/VOIP", type: FabricType.CONFERENCE_ROOMS },
      {
        id: 4,
        name: "Free Public Wifi",
        nameAndStatus: "Free Public Wifi (*)",
        type: FabricType.FREE_PUBLIC_WIFI,
        status: "Ilustration"
      },
      { id: 5, name: "IoT", nameAndStatus: "IoT (*)", type: FabricType.IoT, status: "Ilustration" },
      {
        id: 6,
        name: "Organization (Enterprise) Network",
        nameAndStatus: "Organization (Enterprise) Network (*)",
        type: FabricType.ENTERPRISE_ORG,
        status: "Ilustration"
      },
    ] as { id: number, name: string, type: FabricType, nameAndStatus?: string, status?: string }[]
  }

  export function GetFabricTypeDisplayName(fabricType: FabricType) {
    let fabricFullData = getFabricTypeList().find(fabric => fabric.type == fabricType);
    return fabricFullData.name;
  }
}

export enum MisconfigurationCategory {
  SSID = 'SSID',
  VLAN = 'VLAN',
  PORT = 'PORT',
  POLICY = 'POLICY'
}

