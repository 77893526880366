import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BaseTopologyToolTip} from "../topology-device-tooltip/base-topology-tooltip";
import {ActivatedRoute, Router} from "@angular/router";
import {TopologyTooltipsTypes} from "../topology-tooltips-types.enum";
import {GenericDevice, NodeEventData} from "../../../../models/topology";
import {SingleDevice, SingleDeviceType} from "../../../../models/single-device.model";
import {HealthColorsService} from "../../../../services/strategies/health-colors.service";
import {BaseHealthTopologyTooltip} from "../topology-device-tooltip/base-health-topology-tooltip";

@Component({
  selector: 'app-cloud-node-tooltip',
  templateUrl: './cloud-node-tooltip.component.html',
  styleUrls: ['./cloud-node-tooltip.component.scss'],
  animations: [
    //Source: https://fireship.io/lessons/angular-animations-examples/
    trigger('hoverTooltip', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('0ms ease-out')),
      transition('hide => show', animate('1500ms ease-in'))
    ])
  ]
})

export class CloudNodeTooltipComponent extends BaseHealthTopologyTooltip {
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected healthColorsService: HealthColorsService) {
    super(TopologyTooltipsTypes.CloudeNodeTooltip, router, activatedRoute, healthColorsService);
  }
}
