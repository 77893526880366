import {HealthIndicator} from './health.model';
import {DeviceLatestStatus, SingleDeviceType} from './single-device.model';
import {SeverityCount} from "./severity.model";

export class DevicesHealth {
  id: number;
  deviceName: string;
  health: number;
  vendorName: string;
  model: string;
  mgmtip: string;
  serialNumber: string;
  type: SingleDeviceType;
  affectedFabrics: number;
  lastKpiUpdate: Date;
  statusIndicator?: HealthIndicator;
  openAnomaliesCount?: number;
  anomalySeverityCount: SeverityCount;
  incidentSeverityCount?: SeverityCount;
  deviceLatestStatus: DeviceLatestStatus;
}

export enum DeviceState {
  Alerting = "alerting",
  Offline = "offline",
  Online = "online",
  Unknown = "unknown",
  Unreachable = "unreachable",
  Fake = "Fake"
}

export class DeviceStatus {
  id: number;
  deviceId: number;
  currentStatus: DeviceState;
  startTime: number;
  endTime: number;
  createdAt: number;
  integrationPointId: number;
  tenantId: number;
  time: number;
  updatedAt: number;
}

export class SwitchStack {
  vendorId: string;
  name: string;
  members: SwitchStackMember[];
}

export class SwitchStackMember {
  deviceId: number;
}
