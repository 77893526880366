<app-properties-title *ngIf="!isDevice" [title]="title"
                      (closeIconClicked)="closeIconClicked.emit(true)"></app-properties-title>
<app-properties-header [headerData]="propertiesData?.headerData" [isDevice]="isDevice"></app-properties-header>
<app-properties-content [propertiesData]="propertiesData?.mainData" [isDevice]="isDevice"
                        (editablePropertiesChanged)="editablePropertiesChanged($event)"
                        [editAbleObject]="editAbleObject" (specialItemStatus)="onSpecialItemStatusChanged($event)">
</app-properties-content>

<ng-container
  *ngIf="propertiesData?.headerData.elementType === nodeElementType && !isDevice && propertiesData?.headerData.type !== wwwType && !isSwitchStack">
  <div class="row align-items-center pt-3 mr-0 ml-0">
    <div class="col">
      <app-buttons [btnStyle]="'primary'" [btnText]="'data.PROPERTIES.SHOW_DEVICE' | translate"
                   [btnClass]="'prop-btn-half-size with-margin'"
                   (click)="moveToDevicePage(propertiesData?.headerData.id)">
      </app-buttons>
      <app-buttons [btnStyle]="'primary'" [btnText]="'data.PROPERTIES.SYNC_DEVICE' | translate"
                   [btnClass]="'prop-btn-half-size'"
                   (click)="syncVenue()">
      </app-buttons>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isEditMode">
  <div class="row align-items-center pt-3 mr-0 ml-0">
    <div class="col">
      <app-buttons (click)="openModal(buttonsType.CANCEL)" [btnClass]="'prop-btn-half-size with-margin'"
                   [btnStyle]="'primary'"
                   [btnText]="'data.COMMON.CANCEL'">
      </app-buttons>
      <app-buttons (click)="savePropertiesChanges()" [btnClass]="'prop-btn-half-size'" [btnStyle]="'primary'"
                   [btnText]="'data.COMMON.SAVE'">
      </app-buttons>
    </div>
  </div>
</ng-container>
