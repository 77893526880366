export enum FormAction {
    SAVE = "Save",
    CANCEL = "Cancel",
    DELETE = "Delete",
    UNCHANGED = "Unchanged",
    LEAVE = "Leave"
}
export enum FormFieldsType {
    SELECT,
    INPUT,
    Multi_Select
}
