import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-menu-cell-renderer',
  templateUrl: './menu-cell-renderer.component.html'
})
export class MenuCellRendererComponent implements ICellRendererAngularComp {
  data: any;
  options: MenuOption[];
  isEmpty: boolean = false;

  agInit(params: MenuCellRendererParams) {
    this.data = params.data;
    this.options = params.options || [];
    this.isEmpty = this.options.filter(option => this.visible(option)).length == 0;
  }

  refresh(params: any): boolean {
    return false;
  }

  visible(option: MenuOption): boolean {
    return option.visible == undefined || option.visible(this.data);
  }
}

export interface MenuCellRendererParams extends ICellRendererParams {
  options: MenuOption[];
}

export class MenuOption {
  name: string;
  onClick: (data: any) => void;
  visible?: (data: any) => boolean;
}
