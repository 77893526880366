<!--Dialog Title-->
<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog()">
        {{dialogTitle}}
    </app-netop-dialog-title>
    <app-netop-dialog-content [cssClass]="'no-overflow-x'">
        <mat-form-field class="input-dialog-form-fields">
            <mat-label>{{ dialogHint}}</mat-label>
            <input matInput (keyup)="onKey($event)" [formControl]="formControl">
        </mat-form-field>
        <div class="input-dialog-explain-text">
            <p>{{dialogExplain}}</p>
        </div>
    </app-netop-dialog-content>
    <app-netop-dialog-actions>
        <app-buttons (click)="closeDialog()" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
            [btnText]="'data.COMMON.CANCEL'">
        </app-buttons>
        <app-buttons class="kt-margin-l-10" [btnClass]="" [btnText]="'data.COMMON.SAVE'" (click)="saveInput()">
        </app-buttons>
    </app-netop-dialog-actions>
</app-netop-dialog>
