import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {KpiGroupedBy, KpiTrendDisplay} from '../../models/kpi.model';
import {EntityType} from '../../models/entity-type.enum';
import {NgChanges} from '../../extend-angular-classes/on-changes';
import {KPIChartJsAnnotation} from '../deviation-reports-dialog/components/venue-deviation-reports-dialog/venue-deviation-reports-dialog.component';

export type DataForKpiRequest = {
  entityType: EntityType;
  entityId: number;
  tenantId: number;
  date: string;
}

@Component({
  selector: 'app-kpis-trends',
  templateUrl: './kpis-trends.component.html',
})
export class KpisTrendsComponent {
  @Input() height: number;
  @Input() width: number;
  @Input() isResponsive: boolean = true;
  @Input() isShowStacked: boolean = true;
  @Input() kpiTrends: KpiTrendDisplay[];
  @Input() maxTicksX: number = 4;
  @Input() maxTicksY: number = 5;
  @Input() annotations: KPIChartJsAnnotation;
  @Input() timeDisplayFormat: string = 'DD-MMM-YYYY HH:mm';
  @Input() minX: number;
  @Input() maxX: number;

  isKpiTrends: boolean = true;


  constructor(private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: NgChanges<KpisTrendsComponent>) {
    if (!this.kpiTrends || this.kpiTrends.length < 1) {
      this.isKpiTrends = false;
    }
    this.cdr.detectChanges();
  }
}
