import {InjectionToken} from '@angular/core';


export const fullColorsList: string[] = [
  'rgba(255, 185, 70, 1)',
  'rgba(247, 104, 91, 1)',
  'rgba(138, 125, 208, 1)',
  'rgba(208, 125, 173, 1)',
  'rgba(209, 198, 123, 1)',
  'rgba(158, 209, 119, 1)',
  'rgba(116, 191, 204, 1)',
  'rgba(113, 188, 201, 1)',
  'rgba(173, 108, 196, 1)',
  'rgba(106, 196, 120, 1)',
  'rgba(167, 105, 194, 1)',
  'rgb(0,73,191)',
  'rgba(189, 96, 161, 1)',
  'rgba(46, 161, 248, 1)',
  'rgba(46, 212, 122, 1)',
  'rgb(168,117,108, 1)',
  'rgb(202,135,86, 1)',
  'rgb(133,149,14, 1)',
  'rgb(161,106,132, 1)',
  'rgb(20,45,80, 1)',
  'rgb(226,133,170, 1)'
];

export const FULL_COLORS_LIST = new InjectionToken<string[]>('Colors List');

export class ColorsUtils {
  public static generateRandomHex() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  public static generateRandomRgb(opacity: number = 1) {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + opacity.toFixed(1) + ')';
  }

  public static hexToRgb(hex: string, opacity: number = 1) {
    if (hex && this.isHex(hex)) {
      // Expand shorthand form (e.g. '03F') to full form (e.g. '0033FF')
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      // tslint:disable-next-line:only-arrow-functions
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
        : null;
    }
    return hex;
  }


  public static isHex(hex: string) {
    return hex && hex.startsWith('#');
  }
}


