import { ForceDirectedGraphNode } from '../models/force-directed-graph-node';
import { ForceDirectedGraphLink } from '../models/force-directed-graph-link';
import { D3Sizes } from 'src/app/shared/models/base-d3.model';
import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';

const FORCES = {
    LINKS: 1 / 27,
    COLLISION: 2.5,
    CHARGE: -6
}
export class ForceDirectedGraph {
    public ticker: EventEmitter<d3.Simulation<ForceDirectedGraphNode<any>, ForceDirectedGraphLink<any>>> = new EventEmitter();
    public simulation: d3.Simulation<any, any>;

    nodes: any[];
    links: any[];
    sizes: D3Sizes;
    root: any;
    tree: any;
    D3root: any;

    constructor(nodes, links) {
        this.nodes = nodes;
        this.links = links;
    }

    initSimulation(sizes: any) {
        if (!sizes || !sizes.width || !sizes.height) {
            throw new Error('missing sizes when initializing simulation');
        }

        /** Creating the simulation */
        if (!this.simulation) {
            const ticker = this.ticker;

            this.simulation = d3.forceSimulation()
                .force('charge',
                    d3.forceManyBody()
                        .strength(d => FORCES.CHARGE * d['r'])
                )
                .force('collide',
                    d3.forceCollide()
                        .strength(FORCES.COLLISION)
                        .radius(d => d['r'] + 5).iterations(2)
                );

            // Connecting the d3 ticker to an angular event emitter
            this.simulation.on('tick', function () {
                ticker.emit(this);
            });

            this.initNodes();
            this.initLinks();
        }

        /** Updating the central force of the simulation */
        this.simulation.force('centers', d3.forceCenter(sizes.width / 2, sizes.height / 2));

        /** Restarting the simulation internal timer */
        this.simulation.restart();
    }

    initNodes() {
        if (!this.simulation) {
            throw new Error('simulation was not initialized yet');
        }
        /** Here the simulation object will calculate nodes position */
        this.simulation.nodes(this.nodes);
    }

    initLinks() {
        if (!this.simulation) {
            throw new Error('simulation was not initialized yet');
        }

        this.simulation.force('links',
            d3.forceLink(this.links)
                .id(d => d['id'])
                .strength(FORCES.LINKS)
        );
    }
}
