<ngx-select #venuePicker [items]="venueOptions" optionTextField="name" optionValueField="id" [placeholder]="'data.TENANTS.ADVANCED.SELECT_VENUE' | translate"
            [keepSelectMenuOpened]="true" [keepSelectedItems]="false" [autoClearSearch]="true" [multiple]="true" (select)="onSelect(venuePicker.optionsSelected)"
            (remove)="onRemove(venuePicker.optionsSelected)" (close)="onClose(venuePicker.optionsSelected)">
  <ng-template ngx-select-option let-option let-text="text">
    <mat-checkbox value="{{option.value}}" [checked]="venuePicker.isOptionSelected(option)">
      <h5 matLine [innerHtml]="text"></h5>
      <p matLine><small> {{option.data.description}} </small></p>
    </mat-checkbox>
  </ng-template>
</ngx-select>
