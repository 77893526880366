export class CheckboxFieldData {
  label?: string;
  fieldName: string;
  appendContent?: string
  errorMessage?: string;
}

export class CheckBoxListItem<T> {
  data: T;
  checkboxClass?: CheckboxListClass;
}

export class CheckboxListClass {
  className: string;
  classContent: string;
}

export class IndexCheckBoxListItem<T> extends CheckBoxListItem<T> {
  checkboxID: string;

  constructor(index: string, data: T, checkboxClass: CheckboxListClass) {
    super();
    this.checkboxID = index;
    this.data = data;
    this.checkboxClass = checkboxClass;
  }
}

export class CheckboxField {
  fieldData: CheckboxFieldData[];
}

export type DisplayNameAndOriginalData<T> = { displayText: string, originalData: T };
