import {Component, Input, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {ThemeService} from "../../../services/theme.service";
import {FromToDisplay} from "../../../components/from-to-display/from-to-display.component";

@Component({
  selector: 'app-anomaly-from-to-cell-renderer',
  templateUrl: './anomaly-from-to-cell-renderer.component.html',
  styleUrls: ['./anomaly-from-to-cell-renderer.component.scss']
})
export class AnomalyFromToCellRendererComponent implements OnInit, ICellRendererAngularComp {
  fromToDisplay: FromToDisplay;

  constructor(private themeService: ThemeService) {
  }

  ngOnInit() {
  }

  agInit(params: ICellRendererParams): void {
    if (params) {
      const anomalyCategory = params.colDef.field;
      if (params.data.entityCategoriesData[anomalyCategory]) {
        const actionCategory = params.data.entityCategoriesData[anomalyCategory].actionCategory;
        this.fromToDisplay = {
          from: null,
          to: params.data.entityCategoriesData[anomalyCategory].total,
          fromBackgroundColor: '#ff8780',
          toBackgroundColor: this.themeService.ACTIONS_CATEGORY_COLORS[actionCategory],
          textColor: '#ffffff'
        };
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
