import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {GenericDevice, NodeEventData, SingleD3Node} from '../../../models/topology';
import {D3Link} from '../../../models/single-link.model';

@Injectable({
  providedIn: 'root'
})
export class D3TreeEventsService {
  /**
   * @param notifyClickedElement receive the clicked element data
   */
  private notifyClickedElement: BehaviorSubject<SingleD3Node<GenericDevice<any>> | GenericDevice<any> | D3Link> = new BehaviorSubject(null);
  notifyClickedElementObservable$ = this.notifyClickedElement.asObservable();

  /**
   * @param notifyToolTip contain the mouse enter/leave events (For the tooltip mechanism)
   */
  private notifyToolTip: BehaviorSubject<NodeEventData<GenericDevice<any>>> = new BehaviorSubject(null);
  notifyToolTipObservable$ = this.notifyToolTip.asObservable();

  constructor() {
  }

  onNodeClick(clickEvent: SingleD3Node<GenericDevice<any>>) {
    if (clickEvent) {
      this.notifyClickedElement.next(clickEvent);
    }
  }

  onLinkClick(clickEvent: D3Link) {
    this.notifyClickedElement.next(clickEvent);
  }

  onDisconnectedDeviceClick(clickEvent: | GenericDevice<any>) {
    this.notifyClickedElement.next(clickEvent);
  }

  onNodeEnter(d3Event: NodeEventData<GenericDevice<any>>) {
    // this.notifyToolTip.next(d3Event);

    setTimeout(() => {
      this.notifyToolTip.next(d3Event);
    }, 200);
  }

  onNodeLeave() {
    // this.notifyToolTip.next(null);

    setTimeout(() => {
      this.notifyToolTip.next(null);
    }, 200);
  }

  onEmptyClickedElementSelection(event?: MouseEvent) {
    this.notifyClickedElement.next(null);
  }
}
