import {selectHierarchy} from "../../../../store/selectors/hierarchy.selector";
import {Hierarchy} from "../../../models/hierarchy.model";
import {ClientsPreferences, ClientStorageEntities} from "../../../models/client-storage.model";
import {Store} from "@ngrx/store";
import {IAppState} from "../../../../store/state/app.state";
import {Logger, LoggerService} from "../../logger.service";
import {environment} from "../../../../../environments/environment";
import {LoadClientStorageService} from "../load-client-storage.service";
import {Inject, InjectionToken} from "@angular/core";
import {GlobalObjectStructure} from "../../../global-utils/global-object-structure";
import jsonPath from 'jsonpath';
// export const jp = require('jsonpath');


export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});

export class BaseLocalStorageOrganization {
  currentStorageEntity: ClientStorageEntities;

  /**
   * Current Org entity as stored on Ngrex
   */
  currentOrgHierarchy: Hierarchy;

  /**
   * Contain Branding selection
   * Default value: netopDefaultUISelection
   */
  clientSelection: ClientsPreferences<any>;

  localStoragePath: string;
  currentOrgPreferences: ClientsPreferences<any>;

  protected logger: Logger;

  constructor(protected store: Store<IAppState>,
              protected loggerFactory: LoggerService,
              protected loadClientStorageService: LoadClientStorageService,
              @Inject(BROWSER_STORAGE) public storage: Storage,
              clientStorageEntity: ClientStorageEntities,
              serviceLoggerName: ('LocalStorageDynamicDashboardService' | 'LocalStorageOrgPreferencesService' | 'LocalStorageUserPreferencesService'),
              defaultPreferencesObj: ClientsPreferences<any>) {
    this.setDefaultServiceValues(clientStorageEntity, serviceLoggerName, defaultPreferencesObj);

  }

  setDefaultServiceValues(firebaseEntity: ClientStorageEntities, serviceName: string, defaultValue: ClientsPreferences<any>) {
    this.currentStorageEntity = firebaseEntity;
    this.logger = this.loggerFactory.getLogger(serviceName);
    this.clientSelection = defaultValue;
  }

  getHighLevelOrgPreferences() {
    this.logger.debug("inside getHighLevelOrgPrefernces");
    this.store.select(selectHierarchy).subscribe(hierarchy => {
      this.logger.debug("hierarchy", hierarchy);
      if (hierarchy.length > 0) {
        if (!this.currentOrgHierarchy || this.currentOrgHierarchy && hierarchy[0].id !== this.currentOrgHierarchy[0].id) {
          if (this.clientSelection.preferences.data.hasOwnProperty("orgBranding")) {
            this.clientSelection.preferences.data.companyName = hierarchy[0].name;
          }
          this.currentOrgHierarchy = hierarchy;
          this.localStoragePath = `['${this.currentStorageEntity}']['${environment.environmentType}']['${this.currentOrgHierarchy[0].id}-${this.currentOrgHierarchy[0].type}']`;
          this.findPreferencesAtLocalStorage();
        }
      }
    })
  }

  /**
   * Find, create or update local Client selections by local storage saved preferences object
   * @private
   */
  private findPreferencesAtLocalStorage() {
    const preferencesObject = JSON.parse(this.storage.getItem('preferences'));
    //if preferencesObject are exist on local storage
    if (preferencesObject) {
      this.createOrUpdateCurrentEntityPreferences(preferencesObject);
    }
    //if preferencesObject are does exist on local storage
    else {
      this.createAndStorePreferencesObject(preferencesObject);
    }
  }

  /**
   * Check if current preferences entity exist on preferences object
   * If yes, load preferences from there,
   * If not, create one
   * @param preferencesObject
   * @private
   */
  private createOrUpdateCurrentEntityPreferences(preferencesObject: any) {
    const entityPreferences = jsonPath.query(preferencesObject, `$..['${this.currentStorageEntity}']`);

    //if current storage entity exist
    if (entityPreferences && entityPreferences.length > 0) {
      this.currentOrgPreferences = jsonPath.query(preferencesObject, `$..${this.localStoragePath}`)[0];
      if (this.currentOrgPreferences) {
        this.changeDataStructure();
        this.applyDefaultAndSelectedPreferences(this.currentOrgPreferences);
        return;
      }
    }
    //if current storage entity does not exist

    const newEntityPreferencesObject = {
      [environment["environmentType"]]: {
        [`${this.currentOrgHierarchy[0].id}-${this.currentOrgHierarchy[0].type}`]: this.clientSelection
      }
    }

    preferencesObject.preferences[this.currentStorageEntity] = newEntityPreferencesObject;
    this.storage.setItem('preferences', JSON.stringify(preferencesObject));
    this.applyDefaultAndSelectedPreferences(this.clientSelection);
  }

  /**
   * If preferences object does not exist - create it
   * @param preferencesObject
   * @private
   */
  private createAndStorePreferencesObject(preferencesObject: any) {
    if (!preferencesObject) {
      const newPreferencesObject = {
        [this.currentStorageEntity]: {
          [environment["environmentType"]]: {
            [`${this.currentOrgHierarchy[0].id}-${this.currentOrgHierarchy[0].type}`]: this.clientSelection
          }
        }
      };
      preferencesObject = {
        preferences: newPreferencesObject
      };
    }
    this.storage.setItem('preferences', JSON.stringify(preferencesObject));
    this.applyDefaultAndSelectedPreferences(false);
  }

  /**
   * Apply and store current storage preferences on loadClientStorageService
   * @param clientStorage
   */
  applyDefaultAndSelectedPreferences(clientStorage: ClientsPreferences<any> | boolean) {
    switch (this.currentStorageEntity) {
      case ClientStorageEntities.DYNAMIC_DASHBOARD_PREFERENCES: {
        this.loadClientStorageService.storeDynamicDashboardPreferences(clientStorage ? (clientStorage as ClientsPreferences<any>).preferences : clientStorage);
        break;
      }
      case ClientStorageEntities.ORG_PREFERENCES: {
        this.loadClientStorageService.storeOrgPreferences(clientStorage as ClientsPreferences<any>);
        break;
      }
    }
  }

  /**
   * Store preferences into local storage
   */
  updateOrgPreferencesSelection() {
    this.savePreferencesInLocalStorage();
  }

  private savePreferencesInLocalStorage() {
    const preferences = JSON.parse(this.storage.getItem('preferences'));
    jsonPath.apply(preferences, `$..${this.localStoragePath}`, (value) => this.clientSelection);
    this.storage.setItem('preferences', JSON.stringify(preferences));
  }

  /**
   * Using Json parse affect the data structure.
   * It need to be converted back into its original form
   * Currently (5.8.21 - without user preferences), it applies only  for side nav list
   * @private
   */
  private changeDataStructure() {
    if (this.currentOrgPreferences && this.currentOrgPreferences.preferences && this.currentOrgPreferences.preferences.data &&
      this.currentOrgPreferences.preferences.data.hasOwnProperty('navList')) {
      this.currentOrgPreferences.preferences.data.navList = GlobalObjectStructure.convertObjToMap(this.currentOrgPreferences.preferences.data.navList);
    }
  }
}
