import {Injectable} from '@angular/core';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {BaseDeviationBarService} from 'src/app/shared/pages/reports/models/base-deviation-bar-service';
import {IBaseDeviationBar} from 'src/app/shared/pages/reports/models/Ibase-deviation-bar';
import {TrafficDeviation, TrafficOrigin, OutlierType} from '../../../../../models/legacy-reports.model';
import {FromToDatesStrings} from "../../../../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class ClientsBarGeneratorService extends BaseDeviationBarService implements IBaseDeviationBar {

  constructor(protected dateConvertor: TimeManagerService) {
    super(dateConvertor)
  }

  getBarDatasets(trafficDeviation: TrafficDeviation[], datesAsString: FromToDatesStrings, origin: TrafficOrigin): VerticalBarData {
    let displayData = this.getDisplayData(trafficDeviation, datesAsString, origin);
    let data: VerticalBarData = {
      datasets: [
        {
          data: displayData.dataDisplay.map(datum => datum.underDeviation),
          backgroundColor: '#f39915',
          label: "Under Mean",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.underDeviation),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.value),
          backgroundColor: '#2ed47a',
          label: "Daily Clients",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.value),
          tooltipTitle: displayData.tooltipTitle,
          tooltipFooter: this.generateFooterText(displayData.dataDisplay),
          barThickness: 20
        },
        {
          data: displayData.dataDisplay.map(datum => datum.aboveDeviation),
          backgroundColor: '#f7685b',
          label: "Above Mean",
          tooltipText: displayData.tooltipDisplay.map(datum => datum.aboveDeviation),
          tooltipTitle: displayData.tooltipTitle,
          barThickness: 20
        }
      ],
      labels: this.getFormattedDates(datesAsString)
    };
    return data;
  }

  getDisplayData(trafficDeviation: TrafficDeviation[], datesAsString: FromToDatesStrings, origin: TrafficOrigin) {
    let dataForDisplay: {
      underDeviation: number,
      aboveDeviation: number,
      value: number
    }[] = [];

    let tooltipForDisplay: {
      underDeviation: string,
      aboveDeviation: string,
      value: string
    }[] = [];
    let tooltipTitle: string[] = [];
    trafficDeviation.forEach((deviationData, index) => {
      if (deviationData) {
        dataForDisplay.push({
          underDeviation: this.getUnderDeviation(deviationData),
          aboveDeviation: this.getAboveDeviation(deviationData),
          value: this.getTraffic(deviationData)
        })
        tooltipForDisplay.push({
          underDeviation: this.getUnderDeviationTooltip(deviationData),
          aboveDeviation: this.getAboveDeviationTooltip(deviationData),
          value: this.getNoDeviationTooltip(deviationData, origin)
        })
        tooltipTitle.push(`${this.getFormattedDates(datesAsString)[index]}`);
      }
    })
    return {dataDisplay: dataForDisplay, tooltipDisplay: tooltipForDisplay, tooltipTitle: tooltipTitle};
  }

  getUnderDeviationTooltip(deviationData: TrafficDeviation) {
    if (this.getUnderDeviation(deviationData) > 0) {
      if (deviationData["outlier-type"] == OutlierType.STRONG) {
        return `${this.getTraffic(deviationData)} significantly smaller than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} clients number ${deviationData.dayOfWeekMean.toFixed(2)}`
      }
      if (deviationData["outlier-type"] == OutlierType.WEAK) {
        return `${this.getTraffic(deviationData)} smaller than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} clients number ${deviationData.dayOfWeekMean.toFixed(2)}`
      }
    }
    return null;
  }

  getAboveDeviationTooltip(deviationData: TrafficDeviation) {
    if (this.getAboveDeviation(deviationData) > 0) {
      if (deviationData["outlier-type"] == OutlierType.STRONG) {
        return `${this.getTraffic(deviationData)} significantly larger than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} clients number ${deviationData.dayOfWeekMean.toFixed(2)} `
      }
      if (deviationData["outlier-type"] == OutlierType.WEAK) {
        return `${this.getTraffic(deviationData)} larger than average ${this.dateConvertor.getWeekDayNameByDate(deviationData.date)} clients number ${deviationData.dayOfWeekMean.toFixed(2)} `
      }
    }
    return null;
  }

  getNoDeviationTooltip(deviationData: TrafficDeviation, origin: TrafficOrigin) {
    if (!deviationData["outlier-type"])
      return origin == TrafficOrigin.DEVICE_BY_CONN_FAILURES ?
        `${this.getTraffic(deviationData)} Connection Failures` :
        `${this.getTraffic(deviationData)} Clients`
    return null;
  }
}
