<div class="row tplg-topology-row" [ngClass]="topologyRowClass">
  <div class="col">
    <app-d3-tree-graph *ngIf="isTopologyGenerated === true" [treeConfiguration]="treeConfiguration"
                       [topologyConfiguration]="topologyConfiguration" [selectedFabricTopology]="selectedFabricTopology"
                       [isPropertiesOpen]="isPropertiesOpen">
    </app-d3-tree-graph>
    <div class="tplg-error-message-container" *ngIf="isTopologyGenerated === false">
      <p class="tplg-error-message-text">{{'data.TOPOLOGY.TOPOLOGY_ERROR' | translate}}</p>
    </div>
  </div>
</div>
<app-topology-tooltips-list *ngIf="!isDevice && isShowTooltips" [tplgRect]="tplgRect">
</app-topology-tooltips-list>
