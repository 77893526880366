import { BasicLink } from './single-link.model';
import { BasicTopologyNode } from './topology';

export class CloudTopology {
  nodes: Array<CloudNodeKPIs>;
  links: Array<CloudLink>;
}

export enum CloudNodeType {
  Vpc = 'Vpc',
  CloudRouter = 'CloudRouter',
  Nat = 'Nat',
  WWW = 'WWW',
  SASE = 'Sase',
  SDWAN = 'SDWAN'
}

export namespace CloudNodeType {
  export function getCloudNodeTypesAsArray() {
    return [
      CloudNodeType.Vpc,
      CloudNodeType.CloudRouter,
      CloudNodeType.Nat,
      CloudNodeType.WWW,
      CloudNodeType.SASE,
      CloudNodeType.SDWAN
    ]
  }
}

export class CloudNodeKPIs extends BasicTopologyNode {
  node: CloudNode;
}

export class CloudNode {
  id: number;
  type: CloudNodeType;
  name: string;
  region: string;
  zone: string;
  projectName: string;
  vendorName: string;
  properties: { [name: string]: any };
}

export class CloudRouterDTO extends CloudNode {
  name: string;
  ASN: number;
}

export class CloudVpcNode extends CloudNode {
  subnets: Array<CloudSubnetDTO>;
  isDefault: boolean;
  ipRange: SubnetDTO;
}

export class CloudSubnetDTO {
  name: string;
  description: string;
  region: string;
  primaryIpRange: SubnetDTO;
  secondaryIpRanges: Array<SubnetDTO>;
}

export class SubnetDTO {
  name: string;
  ip: string
}

export enum CloudLinkType {
  Peering,
  Link
}

export class CloudLink extends BasicLink {
  properties: Array<any>;

}


export class HealthDegradationReason {
  percentage?: number;
  reason: string;
}

export function isCloudVpcNode(node: CloudNode) {
  return node.hasOwnProperty('subnets');
}
