import {AnomalyComponentData, TrendAnomaly} from 'src/app/shared/models/anomalies.model';
import {ActionsDashboardEntityList} from '../../../../../entity-dashboard/entity-list/actions-dashboard-entity-list';
import {InsightsService} from 'src/app/shared/services/rest-services/insights.service';
import {DashboardService} from 'src/app/shared/services/rest-services/dashboard.service';
import {AnomalyTrendService} from '../services/anomaly-trend.service';
import {StoreUserPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {StoreWidgetPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {EntityGridActionsMenuService} from 'src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/entity-grid-actions-menu.service';
import {Directive, Input} from '@angular/core';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {AnomaliesStoreService} from '../../anomalies-display/services/anomalies-store.service';
import {EntityType} from "../../../../../../models/entity-type.enum";
import {StoreDashboardService} from "../../../../services/store-dashboard.service";
import {DashboardType} from "../../../../models/dashboards.model";
import {GridTypes} from "../../../../../../models/client-storage.model";

export interface IBaseAnomalyGrid {
  getLastDateAsString(data: any[], textAddition?: any);

  findEarliestDate(data: any[]);
}

@Directive()
export class BaseAnomalyGrid extends ActionsDashboardEntityList {
  @Input() data: AnomalyComponentData;
  timeBack: string;
  trendAnomaly: TrendAnomaly[];
  rowStyle: string = 'pointer: inherited';
  rowClass: string = 'ag-grid-override-pointer';

  constructor(
    gridType: GridTypes,
    protected anomalyTrendService: AnomalyTrendService,
    gridSizes: string,
    protected insightsService: InsightsService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    entityGridActionsMenuService: EntityGridActionsMenuService,
    protected dashboardService: DashboardService,
    protected dateConvertor: TimeManagerService,
    protected anomaliesStoreService: AnomaliesStoreService,
    protected storeDashboardService: StoreDashboardService
  ) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, dashboardService, gridType, entityGridActionsMenuService);
  }

  ngOnInit() {
    this.initateGridColumns();
  }

  initateGridColumns() {
  }

  removeColumnsByEntity(colDef: any[]) {
    let colsIndexToRemove: number[] = [];
    if (this.storeDashboardService.currentDashboard === DashboardType.DEVICES_HEALTH_DASHBOARD) {
      const tenantColIndex = colDef.findIndex(col => col.field === 'tenantName');
      if (tenantColIndex !== -1) {
        colsIndexToRemove.push(tenantColIndex);
      }
      const venueColIndex = colDef.findIndex(col => col.field === 'venueName');
      if (venueColIndex !== -1) {
        colsIndexToRemove.push(venueColIndex);
      }
      return this.filterColDef(colDef, colsIndexToRemove);
    }
    if (this.data && this.data.currentEntity.type === EntityType.TENANT) {
      const tenantColIndex = colDef.findIndex(col => col.field === 'tenantName');
      if (tenantColIndex !== -1) {
        colsIndexToRemove.push(tenantColIndex);
      }
      const deviceColIndex = colDef.findIndex(col => col.field === 'action.name');
      if (deviceColIndex !== -1) {
        colsIndexToRemove.push(deviceColIndex);
      }
      return this.filterColDef(colDef, colsIndexToRemove);
    }
    if (this.data && this.data.currentEntity.type === EntityType.ORGANIZATION) {
      const deviceColIndex = colDef.findIndex(col => col.field === 'action.name');
      if (deviceColIndex !== -1) {
        colsIndexToRemove.push(deviceColIndex);
      }
      return this.filterColDef(colDef, colsIndexToRemove);
    }
  }

  private filterColDef(colDef: any[], colsIndexToRemove: number[]) {
    colsIndexToRemove.forEach((colIndex, index) => {
      colDef.splice(colIndex - index, 1);
    });
    return colDef;
  }

  protected getEntityForGridApi() {
    if (this.data) {
      return this.data.selectedEntity ? this.data.selectedEntity : this.data.currentEntity
    }
    return null;
  }
}
