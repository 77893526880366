import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SearchComponent} from "./search.component";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {InlineSVGModule} from "ng-inline-svg";

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InlineSVGModule
  ],
  exports: [SearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule {
}
