<app-portlet>
    <app-portlet-main-content>
        <app-portlet-header [title]="barTitle" class="portlet-header" [hideDefaultTools]="true">
            <span class="reports-next-to-text"
                nextToTitle>{{'data.REPORTS.TRAFFIC_DEVIATION_EXPLAIN' | translate}}</span>
        </app-portlet-header>
        <app-portlet-body class="portlet-body trend-graph">
            <app-vertical-bar [verticalBarDatasets]="verticalBarDatasets" [height]="verticalBarHeight"
                (barClicked)="openDialog($event)">
            </app-vertical-bar>
        </app-portlet-body>
    </app-portlet-main-content>
</app-portlet>