import { BaseReportsWidgets } from './base-reports-widgets';
import { DeviationReportsDialogComponent } from 'src/app/shared/modals/deviation-reports-dialog/deviation-reports-dialog.component';
import { ReportsStoreService } from '../services/reports-store.service';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { BaseDeviationBarService } from './base-deviation-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { OpenDialogService } from 'src/app/shared/services/open-dialog.service';
import {ChangeDetectorRef, Directive} from '@angular/core';
import {SelectedBarData} from "../../../components/chartjs-components/models/chart-js-events.model";
import {ReportsDialogData} from "./reports-filter.model";

@Directive()
export class BaseReportsBarWidgets extends BaseReportsWidgets {
    constructor(protected reportsStoreService: ReportsStoreService,
        protected dateConvertor: TimeManagerService,
        protected baseBarService: BaseDeviationBarService,
        protected translate: TranslateService,
        protected openDialogService: OpenDialogService,
        protected cdr: ChangeDetectorRef) {
        super(reportsStoreService, dateConvertor, translate, cdr)
    }

    openDialog(event: SelectedBarData) {
        let datesAsArray = this.baseBarService.getFormattedDates(this.datesAsString, false);
        let reportsDialogData: ReportsDialogData = {
            selectedEntity: this.currentEntity,
            selectedDate: datesAsArray[event.index],
            currentTenant: this.currentTenant,
            source: this.origin ? this.origin : this.kpiType
        }
        this.openDialogService.openDialog('auto',
            'auto', DeviationReportsDialogComponent, reportsDialogData, 1500)
    }
}
