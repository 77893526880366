<!-- TODO: we should not need the kt-subheader__breadcrumbs let's replace it with material 'something' -->
<div class="kt-subheader__breadcrumbs nav-breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let i=index">
    <a [routerLink]="['/' + breadcrumb.url]" [queryParams]="breadcrumb.queryParams" [ngClass]=" { 'breadcrumbs-main-org'
      :i==0, 'breadcrumbs-reg' :i !=0 }"
      class="breadcrumbs-link">{{ breadcrumb.label | translate }}</a>
    <span *ngIf="i < breadcrumbs.length-1" class="breadcrumbs-separator">
      <img src="/assets/media/netop/breadcrumbs-separator.svg">
    </span>
  </ng-container>
</div>
