export function getObjectProperty(obj, path: string) {
  let newObj = {...obj}
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  const a = path.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in newObj) {
      newObj = newObj[k];
    } else {
      return;
    }
  }
  return newObj;
}
