import { MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ActionKpiDialogData} from "../../../modals/action-kpi-dialog/action-kpi-dialog.model";
import {ActionKpiDialogComponent} from "../../../modals/action-kpi-dialog/action-kpi-dialog.component";
import {EntityAction} from "../../../models/actions.model";
import {AnomalyCategory} from "../../../models/anomalies.model";
import {GlobalUtilsSizes} from "../../../global-utils/sizes";
import {ICellRendererParams} from "ag-grid-community";

export class BaseAnalyzeCellRenderer {
  action: EntityAction;
  params: ICellRendererParams;

  constructor(public dialog: MatDialog) {
  }

  openDialog() {
    let height = 850;
    let width = 1500;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      action: this.action,
      graphTrendHeight: this.action.anomalyCategory === AnomalyCategory.VpnTraffic ? GlobalUtilsSizes.MULTI_KPIS_CHARTS_HEIGHT_WITH_VPN : GlobalUtilsSizes.MULTI_KPIS_CHARTS_HEIGHT,
      graphTrendWidth: width / 2.5,
      currentEntity: this.params.context.componentParent.currentEntity
    } as ActionKpiDialogData;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '1300px';
    dialogConfig.panelClass = "no-dialog-overflow"
    this.dialog.open(ActionKpiDialogComponent, dialogConfig);
  }

}
