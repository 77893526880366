import { Injectable } from '@angular/core';
import { GlobalEntitiesService } from './global-entities.service';
import { IAppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Entity } from '../../models/entity.model';
import { KpiType } from '../../models/kpi.model';
import { Observable } from 'rxjs';
import { LossAndLatencyReports } from '../../models/legacy-reports.model';

@Injectable({
  providedIn: 'root'
})
export class LossLatencyService extends GlobalEntitiesService {

  constructor(
    private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getLossAndLatency(currentEntity: Entity, startDate: string, endDate: string, kpiType: KpiType): Observable<LossAndLatencyReports[]> {
    return this.http.get<LossAndLatencyReports[]>(`dashboard/${currentEntity.type}/${currentEntity.id}/KPI/${kpiType.toUpperCase()}?start=${startDate}&end=${endDate}`)

  }
}
