<div class="row" [ngClass]="isSecondRow? 'graph-with-border': ''" [ngStyle]="optionsTrend? {height: '1.3rem'}: ''">
  <div class="col graph-title-container">
    <mat-form-field *ngIf="optionsTrend" class="select-contents-display">
      <mat-select [value]="selectedTrendOption" [ngClass]="{'title-for-annotation': kpiAnnotation}" class="graph-title"
                  (selectionChange)="onTrendToggleChange($event.value)">
        <mat-option *ngFor="let option of trendToggleOptions"
                    [value]="option"
                    class="graph-title"
                    [ngClass]="{'title-for-annotation': kpiAnnotation}">
          {{optionsTrend[option].graphTitle}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span *ngIf="!optionsTrend" class="graph-title"
          [ngClass]="{'title-for-annotation': kpiAnnotation}">{{graphTitle}}</span>
    <ng-container *ngFor="let item of nextToTitle">
      <span class="multi-kpi-title-key"
            [ngStyle]="item.key.includes(downArrowAsString) ? {color: '#a95af3'} : ''">{{item.key}}</span>
      <span class="multi-kpi-title-value"
            [ngStyle]="item.key.includes(downArrowAsString) ? {color: '#a95af3'} : ''">{{item.value}}</span>
    </ng-container>
  </div>
</div>
<app-graph-trend *ngIf="trendLineConfig" [maxTicksX]="maxTicksX" [maxTicksY]="maxTicksY" [stepSize]="graphStepSize"
                 [chartConfig]="trendLineConfig" [graphHeight]="graphHeight" [graphWidth]="graphWidth"
                 [beginAtZero]="isFromZero"
                 [marker]="marker" [maxX]="maxX" [minX]="minX" [stacked]="isStacked"
                 [hideLegend]="hideLegend"
                 [noDataError]="noDataMessage" [isResponsive]="isResponsive" [annotations]="kpiAnnotation"
                 [timeDisplayFormat]="timeDisplayFormat" (onBarHovered)="onBarHovered($event)"
                 [hideTooltip]="hideTooltip">
</app-graph-trend>
