import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {TopologyInformationService} from '../../services/topology-information.service';
import {ClientsTopologyService} from '../../services/clients-topology.service';
import {GenericTopologyService} from '../../services/generic-topology.service';
import {TreeConfiguration, TopologyConfiguration} from '../../models/topology-configuration';
import {Subscription} from 'rxjs';
import {SingleDevice} from 'src/app/shared/models/single-device.model';
import {GenericDevice, Topology} from 'src/app/shared/models/topology';
import {SingleLink} from '../../../../models/single-link.model';
import {D3TreeEventsService} from "../../services/d3-tree-events.service";
import {isAnyFailedClients, isGenericIsClient, isGenericIsMultiClient} from "../../operators/topology-operators";

@Component({
  selector: 'app-topology-header',
  templateUrl: './topology-header.component.html',
  styleUrls: ['./topology-header.component.scss']
})
export class TopologyHeaderComponent implements OnInit {
  @Input() treeConfiguration: TreeConfiguration;
  @Input() topologyConfigruation: TopologyConfiguration;
  @Input() d3Topologydata: Topology<GenericDevice<SingleDevice>, SingleLink>;
  @Input() disConnectedDevices: any[] = [];
  @Input() isClients: boolean = false;
  @Input() isDevice: boolean;
  @Input() isFabricsVenue: boolean;
  @Input() isPropertiesOpen: boolean;
  @Input() selectedFabricName: string;
  @Input() title: string;
  @Output() changedTreeConfiguration: EventEmitter<Topology<GenericDevice<SingleDevice>, SingleLink>> = new EventEmitter();
  @ViewChild('headerCenterDisplayContainer', {read: ElementRef, static: true}) headerCenterDisplayContainer: ElementRef;

  showClientsChecked: boolean = false;
  totalClient: number;
  isClientsAdded: boolean = false;
  isNoClientsFound: boolean = false;
  subsc: Subscription[] = [];
  tplgHeaderMiddleColRect: DOMRect;

  constructor(private topologyInformationService: TopologyInformationService,
              private clientsTopologyService: ClientsTopologyService,
              private d3TreeEventsService: D3TreeEventsService,
              private genericTopologyService: GenericTopologyService) {
  }

  ngOnInit() {
    this.subscribeToChangesInConfiguration();
    this.subscribeToAddedClients()
  }

  /**
   * @method onShowClientsClick Invoked when ever the checkbox is clicked.
   * It is resonsible of getting the client topology from the server and activating creating of combined topology mechanism
   * both devices topology and clients topology
   * If the onShowClients buttons is turn of, the function calls removeNewTopologyFromOriginalConfiguration function
   */
  onShowClientsClick() {
    if (this.showClientsChecked) {
      this.clientsTopologyService.addClientsToTopology(this.d3Topologydata, this.treeConfiguration);
    } else {
      this.isNoClientsFound = false;
      this.clientsTopologyService.removeFailedClientsFromDevices(this.d3Topologydata, this.treeConfiguration);
      if (this.isClientsAdded) {
        const cleanTopology = this.genericTopologyService.removeNewTopologyFromOriginalTopology(this.d3Topologydata);
        this.changedTreeConfiguration.emit(cleanTopology);
      }
    }
  }

  /**
   * @method subscribeToChangesInConfiguration Subscribing to the notifyNewConfigurationAsObservable variable.
   * The method is activated when show client is clicked and start to listen to any changes on the configuration, i.g., changes on the topology pararms.
   */

  subscribeToChangesInConfiguration() {
    let newTopologySubsc = this.genericTopologyService.notifyNewTopologyAsObservable$.subscribe(newTopology => {
      if (this.showClientsChecked) {
        /**
         * Show client error when there are failed cliens
         */
        if (!newTopology || newTopology && newTopology.nodes.length == this.d3Topologydata.nodes.length) {
          this.showNoClientErrorMessage();
        }
      }
      /** Check if clients where added*/
      if (!this.showClientsChecked || newTopology && newTopology.nodes.length > this.d3Topologydata.nodes.length) {
        this.hideNoClientErrorMessage();
      }
      if (newTopology) {
        this.changedTreeConfiguration.emit(newTopology);
      }
    });
    this.subsc.push(newTopologySubsc);
  }

  /**
   * Change the two parameters, so the no client found error will be displayed
   */
  showNoClientErrorMessage() {
    this.isNoClientsFound = true;
    this.isClientsAdded = false;
  }

  /**
   * Change the two parameters, so the no client found error will be removed
   */
  hideNoClientErrorMessage() {
    this.isNoClientsFound = false;
    this.isClientsAdded = true;
  }

  /**
   * Return the class for the component (topology header) container
   */
  get headerRowHeight() {
    return this.topologyInformationService.getHeaderRowHeight(this.isDevice);
  }

  /**
   * Return different class for the disconnected area, per its fabric venue screen or not
   */
  get classForVenueFabricScreen() {
    return this.topologyInformationService.getClassForVenueFabricScreen(this.isFabricsVenue);
  }

  /**
   * Return boolean whether the no client error shouls be displayed
   */
  get showNoClientError() {
    return (this.showClientsChecked && this.isNoClientsFound && this.isClients);
  }

  /**
   * Return boolean whether both configuration object exists
   */
  get isInitiateTopology() {
    return this.treeConfiguration && this.topologyConfigruation;
  }

  get isShowClient(): boolean {
    return !this.isDevice && this.isClients;
  }

  get titleIconPath(): string {
    return this.topologyInformationService.getVendorIcon(this.treeConfiguration);
  }

  get isVenueDisplay() {
    return this.title && !this.isDevice && !this.isFabricsVenue && !this.selectedFabricName;
  }

  ngAfterViewInit() {
    if (this.headerCenterDisplayContainer && this.headerCenterDisplayContainer.nativeElement) {
      let rect = <HTMLElement>this.headerCenterDisplayContainer.nativeElement;
      this.tplgHeaderMiddleColRect = <DOMRect>rect.getBoundingClientRect();
    }
  }

  onTopologyHeaderClicked() {
    if (this.isVenueDisplay) {
      this.d3TreeEventsService.onEmptyClickedElementSelection();
    }
  }

  private subscribeToAddedClients() {
    const subcs = this.clientsTopologyService.notifyClientSelectionAsObservable$.subscribe(clients => {
      if (clients) {
        this.totalClient = clients.length;
      }
    });
    this.subsc.push(subcs);
  }

  ngOnDestroy() {
    this.subsc.forEach(subscription => {
      subscription ? subscription.unsubscribe() : null;
    })
  }
}
