import { drawArrow } from '../operators/encoded-arrow';

export class ExportPolicyDTO {
  id: Number;
  order;
  number;
  networkRules: Array<PolicyRuleDTO>;
  contentFilteringRules: ExportContentFilteringDTO;
  trafficShapingRules: Array<ExportTrafficShapingRuleDTO>;
}

export enum Protocol {
  Any, UDP, TCP, ICMP
}

export enum PolicyAction {
  Allow = "Allow",
  Deny = "Deny"
}

export class PolicyRuleDTO {
  order: number;
  action: PolicyAction;
  srcEndPoints: Array<PolicyEndPointDTO>;
  dstEndPoints: Array<PolicyEndPointDTO>;
  comment: string;
}

export class EndPointValue {
  id: string;
  name: string;
  protocol: Protocol;
  ports: string;
  destination: string;
}

export class PolicyEndPointDTO {
  endPointType: string;// the values here will be added as needed, for now
  value: EndPointValueTypes;
  fabrics: Array<FabricBasicDetails>;
  interfaceName: string;
  section: EndPointSection;
}

export enum EndPointSection {
  Destination, Source, Service, Applications, UrlCategories, Unknown
}

export class FabricBasicDetails {
  fabricId: Number;
  fabricName: string;
}

export type EndPointValueTypes = (PolicyDestinationNamedValue |
  PolicyDestinationProtocolValue |
  PolicyDestinationIpAndPortValue |
  PolicyDestinationVIPValue |
  string |
  SectionAndValue |
  Array<string>)

// for type = ProtocolAndPortEndPoint
export class PolicyDestinationIpAndPortValue {
  ipRange: string;
  port: string;
}

// for type = RemoteIPRangeAndPortEndPoint
export class PolicyDestinationProtocolAndPortValue {
  protocol: Protocol;
  ports: string;
}

// for type = VIPEndPoint
export class PolicyDestinationVIPValue {
  externalIp: string;
  mappedIp: string;
}

// for type = Layer3EndPoint
export class PolicyDestinationProtocolValue {
  protocol: Protocol;
  ports: string;
  destination: string;
}

export class SectionAndValue {
  section: string;
  value: any;
}

// for type = ApplicationEndPoint, ApplicationCategoryEndPoint
export class PolicyDestinationNamedValue {
  id: string;
  name: string;
}

export enum PolicySettingsOption {
  Ignore = "ignore",
  Custom = "custom",
  NetworkDefault = "network default"
}

export class PatternsList {
  settings?: PolicySettingsOption;
  patterns: Array<string>;
}

export class CategoriesList {
  settings: PolicySettingsOption;
  categories: Array<string>;
}

export class ExportContentFilteringDTO {
  allowedUrlPatterns: PatternsList;
  blockedUrlPatterns: PatternsList;
  blockedUrlCategories: CategoriesList;
}

export enum CreatePolicyIntentType {
  ContentFiltering = "ContentFiltering"
}
export class CreatePolicyIntent {
  policyType: CreatePolicyIntentType;
  venues: string[];
}

export class CreateContentFilteringIntent extends CreatePolicyIntent {
  allowedUrlPatterns: PatternsList;
  allowedUrlPatternsOverwrite: boolean;
  blockedUrlPatterns: PatternsList;
  blockedUrlPatternsOverwrite: boolean;
  blockedUrlCategories: CategoriesList;
  blockedUrlCategoriesOverwrite: boolean;
}

export enum BandwidthLimitsLevel {
  Ssid,
  Client,
  PolicyGroup
}

export class ExportTrafficShapingRuleDTO {
  policySettingsOption: PolicySettingsOption;
  bandwidthLimitsLevel: BandwidthLimitsLevel;
  priority: Number;
  dscp: Number;
  maxInbound: Number;
  maxOutbound: Number;
  order: Number;
  dstEndPoints: Array<PolicyEndPointDTO>;
}

export class EntityWithPolicies<T> {
  entityData: T;
  policiesData: ExportPolicyDTO[];
}

export class FirewallRullDisplay {
  id: number;
  action: PolicyAction;
  isSrcIsFabric: boolean;
  endPoints: EndPointDisplay[];
  // ApplicationEndPoint,ApplicationCategoryEndPoint = App icon
  //CountriesEndPoint - Globe icon
  // HostnameEndPoint - host icon
  //IP4PortEndPoint - Port Icon
  //VIPEndPoint, Layer3EndPoint, RemoteIPRangeAndPortEndPoint,RemoteIPRangeAndPortEndPoint  = IP icon
  constructor(id: number,
    action: PolicyAction,
    isSrcIsFabric: boolean,
    endPoints: EndPointDisplay[]) {
    this.id = id;
    this.action = action;
    this.isSrcIsFabric = isSrcIsFabric;
    this.endPoints = endPoints;
  }

  get arrow(): string {
    switch (this.isSrcIsFabric) {
      case true:
        return drawArrow("right")
      case false:
        return drawArrow("left")
      default:
        break;
    }
  }

}

export class EndPointDisplay {
  ruleId: number;
  value: string;
  data: KeyValueIcon[];
  iconName: string;
  action: PolicyAction;
  isSrcIsFabric: boolean;
  comment: string;
  markerIndex: number;

  constructor(ruleId: number, value: string,
    data: KeyValueIcon[],
    iconName: string,
    action: PolicyAction,
    isSrcIsFabric: boolean,
    comment: string) {
    this.value = value;
    this.data = data;
    this.iconName = iconName;
    this.action = action;
    this.isSrcIsFabric = isSrcIsFabric;
    this.ruleId = ruleId;
    this.comment = comment;
  }

  get arrow(): string {
    switch (this.isSrcIsFabric) {
      case true:
        return drawArrow("right")
      case false:
        return drawArrow("left")
      default:
        break;
    }
  }
}

export type KeyValueIcon = { key: string, value: string, iconName: string };

/**
 * For each endPoint type, return icon name
 */
export function getEndPointIconName(key: string): string {
  switch (key) {
    case 'ApplicationEndPoint':
    case 'ApplicationCategoryEndPoint':
      return 'app'
    case 'CountriesEndPoint':
      return 'globe';
    case 'HostnameEndPoint':
    case 'protocol':
      return 'http';
    case 'IP4PortEndPoint':
    case 'ports':
    case 'port':
      return 'port-icon';
    case 'VIPEndPoint':
    case 'Layer3EndPoint':
    case 'RemoteIPRangeAndPortEndPoint':
    case 'destination':
    case 'ipRange':
    case 'SubnetEndPoint':
    case 'IPSpanEndPoint':
      return 'ip-icon';
    case 'fabric':
      return 'fabric';
    default: {
      break;
    }
  }
}
