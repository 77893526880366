import {Injectable} from '@angular/core';
import {TimeRangeWithValue, TimeUnit, UserTimeRangesSelection} from "../../models/time.model";
import {getDateByDaysRange} from "../../operators/time-operator";

@Injectable({
  providedIn: 'root'
})
export class TimeRangeInterpreterService {

  constructor() {
  }

  getDataGranularity(selectedToggle: TimeRangeWithValue): TimeUnit {
    switch (selectedToggle.range) {
      case UserTimeRangesSelection.FOUR_HOURS:
        return TimeUnit.HOURS;
      case UserTimeRangesSelection.DAY:
        return TimeUnit.DAYS;
      case UserTimeRangesSelection.MONTH:
        return TimeUnit.MONTHS;
    }
  }

  getDateByGranularity(value: string, dataGranularity: TimeUnit) {
    return new Date(value);
  }
}
