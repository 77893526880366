<div class="row single-widget-container">
    <div class="col">
        <span class="single-widget-key">{{kpiTitle}}</span>
        <ng-container *ngIf="!notCalculated">
            <div class="single-widget-value">
                <span class="single-widget-value-text"
                    [ngStyle]="{color: percentageColor}">{{widgetDisplayData?.value}}{{kpiUnit}}</span>
                <span class="single-widget-value-last-hour">{{withLastHour}}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="notCalculated" [ngTemplateOutlet]="notCalculatedText">
        </ng-container>

        <div class="col single-widget-icon-container">
            <ng-container *ngIf="widgetContentData?.key === 'Health' && !notCalculated"
                [ngTemplateOutlet]="healthTrendChart">
            </ng-container>
            <ng-container *ngIf="widgetContentData?.key !== 'Health'" [ngTemplateOutlet]="kpiIcon">
            </ng-container>
        </div>


        <ng-template #notCalculatedText>
            <div>
                <span class="single-widget-value-when-error">{{'data.COMMON.NOT_CALCULATED' | translate}}</span>
                <mat-icon class="health-num health-not-defined single-widget-value-undefined" mat-list-icon
                    svgIcon="clock">
                </mat-icon>
            </div>
        </ng-template>
    </div>

    <ng-template #healthTrendChart>
        <div class="col single-chart-container">
            <app-small-graph-trend [graphWidth]="graphWidth" [graphHeight]="graphHeight"></app-small-graph-trend>
        </div>
    </ng-template>

    <ng-template #kpiIcon>
        <i class="col single-widget-ellipse" *ngIf="widgetContentData?.key !== 'Health'"
            [inlineSVG]="'/assets/media/netop/topology/fabric-header-icon-ellipse.svg'"></i>
        <i class="col single-widget-icon" *ngIf="widgetContentData?.key !== 'Health'" [inlineSVG]="widgetIconPath"></i>
    </ng-template>

</div>