import { StackedKpiData } from '../models/kpi.model';

export function isKpiStacked(trendData) {
    if (trendData && (trendData as StackedKpiData[]) && (trendData as StackedKpiData[])[0] && (trendData as StackedKpiData[])[0].data !== undefined)
        return true;
    return false;
}

export function calculateTraficValue(value: number, count: number): { finalValue: number, countOfDevition: number } {
    if (value > 1024) {
        value = value / 1024;
        count++;
        return calculateTraficValue(value, count);
    }
    if (value < 1024) {
        let final = { finalValue: +value, countOfDevition: count };
        return final;
    }
}