import {Injectable} from '@angular/core';
import {Entity} from 'src/app/shared/models/entity.model';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {FromToDates, TimePeriodWithDatesStrings} from "../../../models/time.model";

export type ReportsFiltersFirebase = {
  reportsFilters: ReportFilters;
}

export class ReportsEntities {
  selectedOrg: Entity;
  selectedTenant: Entity;
  selectedEntity: Entity;
}

export class ReportFilters extends ReportsEntities {
  timeSelection: TimePeriodWithDatesStrings;
}


@Injectable({
  providedIn: 'root'
})
export class ReportsStoreService {
  filters: ReportFilters;

  private notifySelectedTimeSelection: BehaviorSubject<FromToDates> = new BehaviorSubject(null);
  notifySelectedTimeSelectionAsObservable$ = this.notifySelectedTimeSelection.asObservable();

  private notifyAllFiltersSelection: BehaviorSubject<any> = new BehaviorSubject(null);
  notifyAllFiltersSelectionObservable$ = this.notifyAllFiltersSelection.asObservable();

  private notifyAllEntitiesSelection: BehaviorSubject<ReportsEntities> = new BehaviorSubject(null);
  notifyAllEntitiesSelectionObservable$ = this.notifyAllFiltersSelection.asObservable();

  private notifyFirebaseSelection: ReplaySubject<ReportFilters> = new ReplaySubject(1);
  notifyFirebaseSelectionObservable$ = this.notifyFirebaseSelection.asObservable();

  constructor() {

  }

  saveTimeSelection(selectedTime: FromToDates) {
    this.notifySelectedTimeSelection.next(selectedTime);
    this.updateAllFilters();
  }

  saveAllEntities(reportsEntities: ReportsEntities) {
    this.notifyAllEntitiesSelection.next(reportsEntities);
    this.updateAllFilters();
  }

  emptyNotifiers() {
    this.notifyAllFiltersSelection.next(null);
    this.notifyAllEntitiesSelection.next(null);
    this.notifySelectedTimeSelection.next(null);
  }

  updateAllFilters() {
    if (this.notifyAllEntitiesSelection.value && this.notifySelectedTimeSelection.value) {
      let filters = {
        allEntities: this.notifyAllEntitiesSelection,
        timeSelection: this.notifySelectedTimeSelection
      };
      this.notifyAllFiltersSelection.next(filters);
    }
  }

  storeFiltersLocally(filters: ReportsFiltersFirebase) {
    this.filters = filters.reportsFilters;
  }
}
