import { Entity } from './entity.model';
import { HealthStatus, HealthIndicator } from './health.model';
import {VenueRawData, VenueSummary} from './venues.model';
import {SeverityCount} from "./severity.model";

export class Tenant extends Entity {
  priority: TenantPriority;
}

export class TenantId {
  id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class TenantDetails extends Tenant {
  organization: number;
  lineOfBusiness: string;
}

export interface TenantSummaryDTO extends Tenant {
  organization: number;
  lineOfBusiness: string;
  numOfVenues?: number;
  numOfTasks?: number;
  numOfFabrics?: number;
  venues?: VenueSummary[];
}

export class TenantDTO extends TenantDetails {
  createdAt: Date;
  venue: VenueRawData;
  externalId: string;
}

export interface NewTenant {
  tenant: {
    organization: number;
    name: string;
    lineOfBusiness: string;
    priority: TenantPriority;
  };
  userInvite?: {
    email: string;
    role: number;
    fullName: string;
  };
}

export interface OrganizationTenants {
  [id: number]: Tenant[];
}

export interface OrganizationLevel {
  id: number;
  name: string;
  tenants: Tenant[];
}

export type OrganizationHierarchy = Array<OrganizationLevel>;

export interface TenantsRowDetails {
  title: string;
  details: string;
}

export interface TenantsRow {
  id: number;
  tenant: TenantsRowDetails;
  action: string;
}

export interface TenantsHealth {
  id: number;
  tenantName: string;
  tenantHealth?: number;
  fabricsHealth: number;
  devicesHealth: number;
  statusIndicator: HealthIndicator; // e.g. "MOST_SEVER"
  priority: number;
  kpiMissingDevicesNo: number;
  venuesHealth: HealthStatus;
  openAnomaliesCount: number;
  anomalySeverityCount: SeverityCount;
  incidentSeverityCount: SeverityCount;
}

export interface TenantsHealthSync extends TenantsHealth {
  tenantSyncStatus: {};
}

export interface TenantsAction {
  id: number;
  tenantName: string;
  category: number;
  categoryName: string;
  description: number;
  affects: number;
  status: string;
  statusIndicator: HealthIndicator;
}

export enum TenantPriority {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High"
}
