<ag-grid-angular [showSpinner]="isLoading" [ngStyle]="{height: '100%', width: '100%'}"
  [ngClass]="widgetClass" class="ag-theme-material entity-list" rowSelection="single" suppressCellFocus="true"
  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
  suppressHorizontalScroll="true" (modelUpdated)="onGridReady($event)"
  [components]="components" [overlayLoadingTemplate]="'&nbsp;'" [sortingOrder]="['asc','desc']"
  [context]="context" [rowClassRules]="rowClassRules" [icons]="icons" rowModelType="infinite" [debug]="debugGrid"
  [cacheBlockSize]="numberOfRawsInPage" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
  (sortChanged)="onSortChange($event)" (dragStopped)="onColDragEnd($event)"
  (viewportChanged)="onViewPortChanges($event)" (viewportChanged)="onViewPortChanges($event)"
  (bodyScroll)="bodyScroll($event)">
</ag-grid-angular>
<!--
    cacheBlockSize is vital for infinte scrolling without pagination.
    When we load only the current page data, we need to set the block size to the number of rows,
    in order to re-activate the api call on every scroll
-->
