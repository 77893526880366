import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { EditAbleFabricKeysValues } from '../models/editableFabricKeys';
import { EditAbleKeys } from '../models/editableKeys';

@Injectable({
  providedIn: 'root'
})
export class EditPropSetValidtorsService {

  constructor() { }

  setValidator(editPropertiesForm: UntypedFormGroup, editAbleKeysValues: EditAbleKeys, prop: string, key: string) {
    if (key == "applianceIp") {
      editPropertiesForm.controls[prop + key].setValidators([Validators.pattern("^(?=.*[^\.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}$")]);
    }
    if (editAbleKeysValues.getPropKeyType(prop, key) === "number") {
      editPropertiesForm.controls[prop + key].setValidators([Validators.pattern("^[0-9]*$")]);
    }
  }
}
