import { Pipe, PipeTransform } from '@angular/core';
import { TopTrafficTotalDisplay, TopTrafficSingleDisplayData } from '../../models/legacy-reports.model';
import { ProgressBarDTO } from 'src/app/shared/components/basic-progress-bar/models/progress-bar.model';

@Pipe({
  name: 'progressBarConvertor'
})
export class ProgressBarConvertorPipe implements PipeTransform {

  transform(topEntities: TopTrafficTotalDisplay, color: string): any {
    if (topEntities) {
      let progressBarData: ProgressBarDTO<TopTrafficSingleDisplayData>[] = [];
      let total: number = topEntities.originalData;
      topEntities.data.forEach(entity => {
        progressBarData.push(
          new ProgressBarDTO<TopTrafficSingleDisplayData>(
            entity,
            total,
            entity.originalData,
            entity.label,
            color,
            entity.isTraffic ? `${entity.calculatedData} (${entity.unit})` :
              `${entity.originalData}`)
        )
      });
      return progressBarData;
    }
    return null;
  }

}
