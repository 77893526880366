import {convertCamelcaseToKebabCased} from '../operators/string-opertators';
import {ThemePalette} from '@angular/material/core';
import {EntityTreeNode} from './hierarchy.model';
import {AngularFireAction, DatabaseSnapshot} from '@angular/fire/compat/database';
import {ClientsPreferences} from './client-storage.model';

export class OrgBranding {
  themeColors: ThemeColorsSelection
  companyName: string;
  buttonPalette: ThemePalette;
  companyLogoAsUrl: string;
  fileName: string;

  constructor(themeColors: ThemeColorsSelection, companyName: string, buttonPalette: ThemePalette, logoFile: BrandingImageFile) {
    this.themeColors = themeColors;
    this.companyName = companyName;
    this.buttonPalette = buttonPalette ? buttonPalette : 'primary';
    //If there is Logo file without url (when the user choose file)
    if (logoFile && logoFile.file && !this.companyLogoAsUrl) {
      this.getDataUrlFromImageFile(logoFile.file);
      this.fileName = logoFile.file.name;
    }
    // If there is uri without file object (when the data arrives from Firebase)
    if (logoFile && logoFile.url && !logoFile.file)
      this.companyLogoAsUrl = logoFile.url;
    // If there is fileName (When the object arrives from firebase)
    if (logoFile && logoFile.fileName)
      this.fileName = logoFile.fileName;

    //If there is no logo file object
    if (!logoFile)
      this.companyLogoAsUrl = null;
  }

  /**
   * Convert current theme object to css format
   * @param theme Current Theme object
   */
  themeToCssVariables(theme: {}) {
    let formmatedTheme = {};
    if (theme)
      Object.keys(theme).forEach(key => {
        formmatedTheme[convertCamelcaseToKebabCased(key)] = theme[key];
      });
    return formmatedTheme;
  }

  /**
   * Get theme object keys as array
   */
  getThemeKeysArray() {
    return Object.keys(this.themeColors);
  }

  /**
   * Retreive the data as url out of the File param
   */
  getDataUrlFromImageFile(imageFile: File) {
    let reader = new FileReader();
    let imageUrl: any = null;
    if (imageFile) {
      reader.readAsDataURL(imageFile);
      reader.onload = (_event) => {
        imageUrl = reader.result.toString()
        this.companyLogoAsUrl = imageUrl;
      }
    }
  }
}

export class ThemeColorsSelection {
  topMenuBackgroundColor: string;
  topMenuForegroundColor: string;
  leftMenuBackgroundColor: string;
  leftMenuForegroundColor: string;
  dialogHeaderBackgroundColor: string;
  dialogHeaderForegroundColor: string;
}

export class FirebaseOrgBranding extends ClientsPreferences<any> {
}

export class BrandingImageFile {
  file?: File;
  url?: any;
  fileName?: string;
}

export type EntityWithBranding = { entityTreeNode: EntityTreeNode, firebaseSnapshot: AngularFireAction<DatabaseSnapshot<FirebaseOrgBranding>> };

export function isFileType(value: any) {
  return value && value.lastModified;
}

export function isBrandingImageFile(value: any) {
  return value && value.file && value.file.lastModified;
}

export function isUrlType(value: any) {
  return value && value.url;
}
