import { Component, OnInit, Input } from '@angular/core';
import { TopologyInformationService } from '../../services/topology-information.service';

@Component({
  selector: 'app-topology-footer',
  templateUrl: './topology-footer.component.html',
  styleUrls: ['./topology-footer.component.scss']
})
export class TopologyFooterComponent implements OnInit {
  @Input() isDevice: boolean;
  @Input() isWidget: boolean;

  constructor(private topologyInformationService: TopologyInformationService) { }

  ngOnInit() {
  }

  get deviceZoomContainerClass() {
    return this.topologyInformationService.getDeviceZoomContainerClass(this.isDevice);
  }

}
