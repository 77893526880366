import { Injectable } from '@angular/core';
import { SingleWidget, WidgetType } from '../models/widget.model';
import { switchItemsInNestedArray } from 'src/app/shared/operators/array.operator';
import {DashboardType} from "../models/dashboards.model";

@Injectable({
  providedIn: 'root'
})
export class DynamicDashboardService {
  currentDashboard: DashboardType;
  constructor() { }

  /**
     * Find row inside multiRowWidgetArray by row id
     */
  findRowByType(type: WidgetType, multiRowWidgetArray: Array<SingleWidget[]>) {
    let currentRowIndex: number;
    for (let i = 0; i < multiRowWidgetArray.length; i++) {
      const widgetArray = multiRowWidgetArray[i];
      if (widgetArray.find(widget => widget.type == type)) {
        currentRowIndex = i;
        break;
      }
    }
    return currentRowIndex;
  }
  /**
   * Check if given widgetArray is bigger than single row (width wise)
   */
  isMoreThanOneRow(widgetArray: SingleWidget[]) {
    let widgetSizesSum = 0;
    if (widgetArray.length > 0)
      widgetArray.forEach(widget => widgetSizesSum += widget.size + 1);
    return widgetSizesSum > 3;
  }
  /**
  * Check if given widgetArray is exactly single row width
  */
  isExactlyOneRow(widgetArray: SingleWidget[]) {
    let widgetSizesSum = 0;
    if (widgetArray.length > 0)
      widgetArray.forEach(widget => widgetSizesSum += widget.size + 1);
    return widgetSizesSum == 3;
  }
  /**
 * Check if given widgetArray is smaller single row (width wise)
 */
  isLessThanOneRow(widgetArray: SingleWidget[]) {
    let widgetSizesSum = 0;
    if (widgetArray.length > 0)
      widgetArray.forEach(widget => widgetSizesSum += widget.size + 1);
    return widgetSizesSum < 3;
  }

  bothRowsFull(multiRowWidgetArray: SingleWidget[][], targetRowIndex: number, originalRowIndex: number) {
    return this.isExactlyOneRow(multiRowWidgetArray[targetRowIndex]) && this.isExactlyOneRow(multiRowWidgetArray[originalRowIndex])
  }
  /**
   * Find position of two widgets in multiRowWidgetArray
   * Switch between them and return the multiRowWidgetArray
   */
  switchItemsFromTwoRows(multiRowWidgetArray: SingleWidget[][], widget1: SingleWidget, widget2: SingleWidget): SingleWidget[][] {
    let widget1RowIndex: number;
    let widget1ItemIndex: number;
    let widget2RowIndex: number;
    let widget2ItemIndex: number;
    multiRowWidgetArray.forEach((row, rowIndex) => {
      let widgetIndex = row.findIndex(widget => widget.type == widget1.type);
      if (widgetIndex && widgetIndex !== -1 || widgetIndex == 0) {
        widget1RowIndex = rowIndex;
        widget1ItemIndex = widgetIndex;
      }
    });
    multiRowWidgetArray.forEach((row, rowIndex) => {
      let widgetIndex = row.findIndex(widget => widget.type == widget2.type);
      if (widgetIndex && widgetIndex !== -1 || widgetIndex == 0) {
        widget2RowIndex = rowIndex;
        widget2ItemIndex = widgetIndex;
      }
    });
    multiRowWidgetArray = switchItemsInNestedArray(multiRowWidgetArray, widget1RowIndex, widget1ItemIndex, widget2RowIndex, widget2ItemIndex);
    return multiRowWidgetArray;
  }
  /**
   * Chack if two items on multiRowWidgetArray are on the same size
   */
  isWidgetsOnSameSize(multiRowWidgetArray: SingleWidget[][], targetRowIndex: number, originalRowIndex: number, event: import("@angular/cdk/drag-drop").CdkDragDrop<string[], string[]>) {
    return (event.currentIndex == event.previousIndex ||
      Math.abs(event.currentIndex - event.previousIndex) == 1) &&
      (multiRowWidgetArray[targetRowIndex][event.previousIndex] &&
        multiRowWidgetArray[targetRowIndex][event.previousIndex].size == multiRowWidgetArray[originalRowIndex][event.previousIndex].size)
  }
}
