import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { LoggerService, Logger } from '../../services/logger.service';
import { CRON_SELECTION_OPTIONS } from './utils/cron.utils';
import { MatSelectChange } from '@angular/material/select';
import { CronBuilderService } from './services/cron-builder.service';

@Component({
  selector: 'app-cron-generator',
  templateUrl: './cron-generator.component.html',
  styleUrls: ['./cron-generator.component.scss']
})
export class CronGeneratorComponent {
  @Input() defaultCron: string;
  @Input() selectedCategory = 'monthly';
  @Input() isDisabled = false;
  @Output() cronSelected: EventEmitter<string> = new EventEmitter();

  cron: string = null;
  selectOptions = CRON_SELECTION_OPTIONS;
  state: any;
  title: string;
  chosenTime: string = "03:45";
  private _defaultCron: string = '0 0 12 15 */4 ?';

  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService,
    private cronBuilderService: CronBuilderService
  ) {
    this.logger = this.loggerFactory.getLogger('CronGeneratorComponent');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.defaultCron) {
      this._defaultCron = this.defaultCron;
    }
    this.initateSchedulingDialog();
  }
  initateSchedulingDialog() {
    this.cron = this._defaultCron;
    this.state = this.getDefaultState;
    this.setDefaultCronValues(this.cron);
  }

  /**
   * Set the state values according to the default cron expression
   */
  private setDefaultCronValues(cron: string) {
    this.logger.debug("Cron", cron);
    this.validate(cron);

    const cronSeven = cron;

    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = cronSeven.split(' ');
    this.logger.debug("seconds", seconds, "minutes", minutes, "hours", hours, "dayOfMonth", dayOfMonth, "month", month, "dayOfWeek", dayOfWeek);

    this.chosenTime = `${hours.length == 1 ? "0" + hours : hours}:${minutes.length == 1 ? "0" + minutes : minutes}`;

    if (month !== "*" && month !== "?") {
      this.logger.debug("This is monthly");
      this.selectedCategory = 'monthly';
      this.state.monthly.specificDay.day = (dayOfMonth.indexOf('W') !== -1) ? dayOfMonth.charAt(0) : dayOfMonth;
      this.state.monthly.specificDay.months = Number(month.substring(2));
    }
    if (dayOfWeek !== "*" && dayOfWeek !== "?") {
      this.logger.debug("This is weekly");
      this.selectedCategory = 'weekly';
      this.selectOptions.days.forEach(weekDay => this.state.weekly[weekDay] = false);
      dayOfWeek.split(',').forEach(weekDay => this.state.weekly[weekDay] = true);
    }
    if (dayOfWeek == "*" || dayOfWeek == "?" && month == "*" || month == "?") {
      this.logger.debug("This is daily");
      this.selectedCategory = 'daily';
      this.state.daily.subTab = 'everyDays';
      this.state.daily.everyDays.days = dayOfMonth !== "*" ? Number(dayOfMonth.substring(2)) : 1;
    }
    this.cronInterpreter();
  }

  private validate(cron: string): void {
    this.state.validation.isValid = false;
    this.state.validation.errorMessage = '';

    if (!cron) {
      this.state.validation.errorMessage = 'Cron expression cannot be null';
      return;
    }

    const cronParts = cron.split(' ');

    const expected = 6;

    this.state.validation.isValid = true;
    return;
  }

  onPeriodSelectionChanges(event: MatSelectChange) {
    this.logger.debug("onPeriodSelectionChanges", event);
    this.cronInterpreter();
  }
  /**
  * Generate cron expression out of the user selections
  */
  cronInterpreter() {
    this.logger.debug("regenerateCron called")

    try {
      switch (this.selectedCategory) {
        case 'daily':
          switch (this.state.daily.subTab) {
            case 'everyDays':
              // tslint:disable-next-line:max-line-length
              this.cron = `${this.selectedSeconds} ${this.selectedMinutes} ${this.selectedHours} 1/${this.state.daily.everyDays.days} * ?`;
              break;
            default:
              throw new Error('Invalid cron daily subtab selection');
          }
          break;
        case 'weekly':
          const days = this.selectOptions.days
            .reduce((acc, day) => this.state.weekly[day] ? acc.concat([day]) : acc, [])
            .join(',');
          this.cron = `${this.selectedSeconds} ${this.selectedMinutes} ${this.selectedHours} ? * ${days}`;
          this.logger.debug("isDayOfWeekSelected", !this.isDayOfWeekSelected());
          break;
        case 'monthly':
          switch (this.state.monthly.subTab) {
            case 'specificDay':
              const day = this.state.monthly.runOnWeekday ? `${this.state.monthly.specificDay.day}W` : this.state.monthly.specificDay.day;
              // tslint:disable-next-line:max-line-length
              this.cron = `${this.selectedSeconds} ${this.selectedMinutes} ${this.selectedHours} ${day} 1/${this.state.monthly.specificDay.months} ?`;
              break;
            case 'specificWeekDay':
              // tslint:disable-next-line:max-line-length
              this.cron = `${this.selectedSeconds} ${this.selectedMinutes} ${this.selectedHours} ? ${this.state.monthly.specificWeekDay.startMonth}/${this.state.monthly.specificWeekDay.months} ${this.state.monthly.specificWeekDay.day}${this.state.monthly.specificWeekDay.monthWeek}`;
              break;
            default:
              throw new Error('Invalid cron monthly subtab selection');
          }
          break;
        default:
          throw new Error('Invalid cron active tab selection');
      }

    } catch (e) {
    }
    this.logger.debug("Cron", this.cron);
    this.cronSelected.emit(this.cron);

  }

  /**
 * Determine whether the user has selected at least on day,
 * in case of this.selectedCategory == 'weekly'
 */
  isDayOfWeekSelected(): boolean {
    let isDaySelected: boolean = false;
    Object.keys(this.state.weekly).forEach(day => {
      if (this.state.weekly[day]) {
        isDaySelected = true;
      }
    });
    return isDaySelected;
  }

  monthDayDisplay(month: string): string {
    if (month === 'L') {
      return 'Last Day';
    } else if (month === 'LW') {
      return 'Last Weekday';
    } else if (month === '1W') {
      return 'First Weekday';
    } else {
      return `${month}${this.cronBuilderService.getOrdinalSuffix(month)} day`;
    }
  }

  showDay(): boolean {
    return this.selectedCategory === 'daily';
  }

  showWeek(): boolean {
    return this.selectedCategory === 'weekly';
  }

  showMonth(): boolean {
    return this.selectedCategory === 'monthly';
  }
  get selectedHours() {
    return this.chosenTime.split(':')[0]
  }
  get selectedMinutes() {
    return this.chosenTime.split(':')[1]
  }
  get selectedSeconds() {
    return "0";
  }

  get getDefaultState() {
    return {
      daily: {
        subTab: 'everyDays',
        everyDays: {
          days: 1,
        },
        everyWeekDay: {
        }
      },
      weekly: {
        MON: true,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
      },
      monthly: {
        subTab: 'specificDay',
        runOnWeekday: false,
        specificDay: {
          day: '1',
          months: 1,
        },
        specificWeekDay: {
          monthWeek: '2#1',
          day: 'MON',
          startMonth: 1,
          months: 4,
        }
      },
      validation: {
        isValid: true,
        errorMessage: ''
      }
    };
  }
}
