import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ForceDirectedGraphComponent} from './force-directed-graph.component';
import {ForceDirectedGraphNodeComponent} from './components/force-directed-graph-node/force-directed-graph-node.component';
import {ForceDirectedGraphLinkComponent} from './components/force-directed-graph-link/force-directed-graph-link.component';
import {DraggableDirective} from './directives/draggable.directive';
import {ZoomableDirective} from './directives/zoomable.directive';
import {SingleForceNodeDisplayComponent} from './components/single-force-node-display/single-force-node-display.component';
import {VpnTypeEditorPipe} from './pipes/vpn-type-editor.pipe';


@NgModule({
  declarations: [
    ForceDirectedGraphComponent,
    ForceDirectedGraphNodeComponent,
    ForceDirectedGraphLinkComponent,
    DraggableDirective,
    ZoomableDirective,
    SingleForceNodeDisplayComponent,
    VpnTypeEditorPipe],
  imports: [
    CommonModule
  ],
  exports: [
    ForceDirectedGraphComponent,
    SingleForceNodeDisplayComponent,
    VpnTypeEditorPipe]
})
export class ForceDirectedGraphModule {
}
