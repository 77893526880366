import { GraphDonutConfig } from 'src/app/shared/components/chartjs-components/models/graph-donut-config';
import { ReportsStoreService } from 'src/app/shared/pages/reports/services/reports-store.service';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { TopTrafficService } from '../services/top-traffic.service';
import { TrafficService } from 'src/app/shared/services/rest-services/traffic.service';
import { DonutConfigService } from 'src/app/shared/components/chartjs-components/services/donut-config.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Directive } from '@angular/core';
import { TopTrafficSingleDisplayData } from '../../../../../models/legacy-reports.model';
import { BaseReportsWidgets } from 'src/app/shared/pages/reports/models/base-reports-widgets';

@Directive()
export class BaseTrafficDonutStatistic extends BaseReportsWidgets {
    donutTitle: string;
    byText: string;
    donutConfig: GraphDonutConfig;
    topNumber: number = 5;
    total: string;
    height: number = 250;
    width: number = 250;
    topTrafficData: TopTrafficSingleDisplayData[];
    noDataMessage: string;


    constructor(
        protected reportsStoreService: ReportsStoreService,
        protected dateConvertor: TimeManagerService,
        protected trafficService: TrafficService,
        protected topTrafficService: TopTrafficService,
        protected donutConfigService: DonutConfigService,
        protected translate: TranslateService,
        protected cdr: ChangeDetectorRef) {
        super(reportsStoreService, dateConvertor, translate, cdr)
    }

    get lowResultsNumber() {
        return this.topTrafficData && (this.topTrafficData.length < 3 && this.topTrafficData.length > 0);
    }
    get isTopTrafficData() {
        return this.topTrafficData && this.topTrafficData.length > 0;
    }
}
