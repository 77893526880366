import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-display',
  templateUrl: './accordion-display.component.html',
  styleUrls: ['./accordion-display.component.scss']
})
export class AccordionDisplayComponent implements OnInit {
  @Input() accordionObj: any;
  panelsOpenState: { [index: number]: boolean } = {};
  constructor() { }

  ngOnInit() {
  }

}
