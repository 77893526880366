import {Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {D3Tree} from '../../../models/d3-tree';
import {D3Service} from '../../../services/d3.service';
import {TopologyConfiguration, TreeConfiguration} from '../../../models/topology-configuration';
import {D3TreeEventsService} from '../../../services/d3-tree-events.service';
import {SingleDeviceType, SingleDevice} from 'src/app/shared/models/single-device.model';
import {SingleLink, SingleLinkType} from 'src/app/shared/models/single-link.model';
import {Topology} from 'src/app/shared/models/topology';
import {NgChanges} from 'src/app/shared/extend-angular-classes/on-changes';

@Component({
  selector: 'app-d3-tree-graph',
  templateUrl: './d3-tree-graph.component.html',
  styleUrls: ['./d3-tree-graph.component.scss']

})
export class D3TreeGraphComponent implements OnInit {
  @Input() treeConfiguration: TreeConfiguration;
  @Input() topologyConfiguration: TopologyConfiguration;
  @Input() selectedFabricTopology: Topology<SingleDevice, SingleLink>;
  @Input() isPropertiesOpen: boolean = false;
  d3Tree: D3Tree;
  nodesWithoutFake: any[] = [];
  linksWithoutFake: any[] = [];
  isSqueezable: boolean = false;

  @ViewChild('d3TreeSvg') svgElement: ElementRef;
  treeViewBox: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private d3Service: D3Service,
    private d3TreeEventsService: D3TreeEventsService) {
  }

  ngOnInit() {
    this.d3Tree = this.d3Service.createTreeObject(this.treeConfiguration, this.topologyConfiguration, this.treeSizes, this.isSqueezable);
    this.reInitlized3TreeObject();
  }

  /**
   * The method assign the nodesWithoutFake and linksWithoutFake arrays with null values
   * The purpose: To update the data in a way that the svg won't be draw twice
   * The "null" assignment activate the ngIf directive and causes Angular to re-draw the element
   */
  ngOnChanges(changes: NgChanges<D3TreeGraphComponent>) {
    if (changes.isPropertiesOpen && this.d3Tree) {
      this.treeViewBox = this.d3Service.calculateD3ViewBox(this.treeConfiguration, this.d3Tree, this.topologyConfiguration, this.isPropertiesOpen);
      return;
    }
    this.nodesWithoutFake = null;
    this.linksWithoutFake = null;
    if (changes.treeConfiguration && !changes.topologyConfiguration)
      this.reInitlized3TreeObject(true);
    this.reInitlized3TreeObject();
  }

  ngAfterViewInit() {
    this.d3Service.svgElement = this.svgElement;
  }

  /**
   * Re initlize the d3tree with configuration data, and initiate nodes and links array
   * Empty any node selection from the node event service
   * The method will be activated when the d3Tree object will be created and when it will be updated
   */
  reInitlized3TreeObject(isReDrawTree: boolean = false) {
    if (this.d3Tree) {
      this.treeViewBox = this.d3Service.calculateD3ViewBox(this.treeConfiguration, this.d3Tree, this.topologyConfiguration, this.isPropertiesOpen);
      this.d3TreeEventsService.onNodeLeave();
      this.d3Tree.treeConfiguration = {...this.treeConfiguration};
      this.d3Tree.topologyConfiguration = {...this.topologyConfiguration};
      this.treeConfiguration.treeDepth = this.d3Service.findDeepestLevel(this.d3Tree.nodesAfterD3);
      if (isReDrawTree) {
        this.d3Tree.createTreeLayout(this.treeConfiguration, this.topologyConfiguration);
      }
      this.initiateNodeAndLinksWithoutFake();
    }
    this.cdr.detectChanges();
    
  }

  /**
   * Initiate the nodes and links arrays without fake ones
   */
  private initiateNodeAndLinksWithoutFake() {
    this.nodesWithoutFake = [];
    this.linksWithoutFake = [];
    this.d3Tree.nodesAfterD3.forEach(node => {
      if (node.data.type !== SingleDeviceType.FakeRoot) this.nodesWithoutFake.push(node);
    });
    this.d3Tree.linksAfterD3.forEach(link => {
      if (link.data.type !== SingleLinkType.Fake) this.linksWithoutFake.push(link);
    });
  }

  /**
   * Initiate d3TreeService Click Only if the event target is the svg (and not node or link)
   * @param event The current click event
   */
  onSvgClick(event: MouseEvent) {
    // if (event.target == this.svgElement.nativeElement) {
    //   this.d3TreeEventsService.onEmptyClickedElementSelection(event);
    // }
  }

  get maxChildrenNumber() {
    return this.d3Tree.maxChildrenNumber ?
      this.d3Tree.maxChildrenNumber : 0
  }

  get treeSizes() {
    return {
      width: this.treeConfiguration.width,
      height: this.treeConfiguration.height
    };
  }

  ngOnDestroy() {
    this.d3TreeEventsService.onEmptyClickedElementSelection();
  }

  reDrawTreeInsideVisibleArea() {
    this.isSqueezable = true;
    this.d3Tree = this.d3Service.createTreeObject(this.treeConfiguration, this.topologyConfiguration, this.treeSizes, this.isSqueezable);
    this.reInitlized3TreeObject();
  }
}
