import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { AnomalySettingsDTO } from 'src/app/shared/models/anomalies.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DynamicSentence } from 'src/app/shared/components/dynamic-wordings/models/dynamic-sentence.model';
import { GenerateDynamicSentenceService } from './generate-dynamic-sentence.service';
import { WordsWithRules } from 'src/app/shared/components/dynamic-wordings/models/rules.model';

@Component({
  selector: 'app-anomaly-setting',
  templateUrl: './anomaly-setting.component.html',
  styleUrls: ['./anomaly-setting.component.scss']
})
export class AnomalySettingComponent implements OnInit {
  @Input() setting: AnomalySettingsDTO;
  dynamicSentence: DynamicSentence;
  @Output() anomalySettingChanged: EventEmitter<AnomalySettingsDTO> = new EventEmitter();
  isEnabled: boolean;

  constructor(private generateDynamicSentenceService: GenerateDynamicSentenceService) { }

  ngOnInit() {
    this.dynamicSentence = this.generateDynamicSentenceService.createDynamicSentence(this.setting);
  }
  /**
   * Change shouldNotify on setting object
   */
  onNotifyClicked(isClicked: boolean) {
    this.setting.shouldNotify = isClicked;
    this.anomalySettingChanged.emit(this.setting)
  }
  /**
   * Change onEnableClicked on setting object
   */
  onEnableClicked(event: MatSlideToggleChange) {
    this.setting.enabled = event.checked;
    this.anomalySettingChanged.emit(this.setting)
  }
  /**
   * Activate When user select one of the editable fields
   * the method find the word on the this.dynamicSentence.words object and
   * then serilize it into the setting object
   *
   */
  onWordChanged(changedWord: WordsWithRules<any>) {
    let dynamicWord = Object.values(this.dynamicSentence.words).find(word => word.id == changedWord.specialWordId);
    if (dynamicWord !== undefined) {
      dynamicWord.value = changedWord.value;
      this.setting = this.generateDynamicSentenceService.serializeSettingsFromDynamicSentence(this.setting, this.dynamicSentence);
    }
    this.anomalySettingChanged.emit(this.setting)
  }
}
