export class FlowStepChangedEvent {
    constructor(public readonly step: string,
        public readonly payload: any,
        public readonly flowState: any,
        public readonly flowDirection: 'Forward' | 'Backward' = 'Forward',
        public readonly flowFinished: boolean = false) { }
}

export class FlowStepBeforeChangeEvent {
    constructor(public readonly step: string, public readonly payload: any) { }
}
