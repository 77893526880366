import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type NetopToggleSelection = { value: string, isSelected: boolean };

@Component({
  selector: 'app-netop-toggle',
  templateUrl: './netop-toggle.component.html',
  styleUrls: ['./netop-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetopToggleComponent {
  @Input() color: string;
  @Input() optionClass: string;
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() toggleOptions: NetopToggleSelection[] = [];

  constructor() {
  }


  onOptionSelected(event: NetopToggleSelection) {
    this.toggleOptions.forEach(option => {
      option.isSelected = option.value === event.value;
    });
    this.optionSelected.emit(event.value);
  }

}
