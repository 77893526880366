export class GlobalObjectStructure {

  public static convertObjToMap(obj: {}) {
    let objAsArray = [];
    Object.keys(obj).map(function (key) {
      objAsArray.push(obj[key]);
    });
    return objAsArray;
  }
}
