import {Component, Input, HostListener, ElementRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {checkIsFileTypeAllowed} from "./file-validator";
import {TranslateService} from "@ngx-translate/core"; //https://angular.io/api/forms/NG_VALUE_ACCESSOR#description

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
  styleUrls: ['./file-upload.component.scss']
})
/**Source: https://github.com/NetanelBasal/ng-file-upload */
export class FileUploadComponent {
  @Input() required: boolean;
  @Input() progress;
  @Input() fileName: string;
  @Input() allowedTypes: string[];
  MAX_FILE_SIZE = 8 * 1024 * 1024; // 5 Mega bits
  onChange: Function;
  file: File | null = null;
  showError: boolean = false;
  _errorText: string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    this._errorText = '';
    if (event && event.length > 0 && checkIsFileTypeAllowed(this.allowedTypes, event.item(0).type) && event.item(0).size < this.MAX_FILE_SIZE) {
      const file = event && event.item(0);
      this.onChange(file);
      this.file = file;
    }
    if (!checkIsFileTypeAllowed(this.allowedTypes, event.item(0).type)) {
      this.file = null;
      this._errorText = this.translate.instant('data.VALIDATORS.ERRORS.WRONG_TYPE', {types: this.allowedTypes.join(', ')});
    }
    if (event.item(0).size >= this.MAX_FILE_SIZE) {
      this._errorText = this.translate.instant('data.VALIDATORS.ERRORS.WRONG_FILE_SIZE');
      this.file = null;
    }
  }

  constructor(private host: ElementRef<HTMLInputElement>, private translate: TranslateService) {
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }

  get isNoFirebaseName(): boolean {
    return !this.fileName && !this.file || this.file !== null;
  }

  get errorText() {
    return this._errorText;
  }
}
