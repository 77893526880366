import {Injectable} from '@angular/core';
import {CloudNode, CloudSubnetDTO, CloudVpcNode, isCloudVpcNode} from "../../../models/cloud-network.model";
import {GenericDevice} from "../../../models/topology";
import {BasicDevice} from "../../../models/single-device.model";
import {isCloudNode} from "../../network-topology/operators/topology-operators";
import {InitatePropertiesDataService} from "./initate-properties-data.service";
import {SingleEntityService} from "../../single-entities/single-entity.service";
import {BaseEntityPropertiesGenerator} from "./base-entity-properties-generator";
import {PanelElement} from "../models/properties-content";

@Injectable({
  providedIn: 'root'
})
export class GenerateNodePanelPropsService extends BaseEntityPropertiesGenerator {

  constructor(protected prepareProps: InitatePropertiesDataService,
              protected singleEntityService: SingleEntityService) {
    super(prepareProps, singleEntityService)
  }

  generateNodePanelElements(node: GenericDevice<any>, originalDataEntity: BasicDevice | CloudNode): PanelElement[] {
    if (!isCloudNode(node)) {
      return [
        {
          key: 'staticInfo', value: this.propsGenerator.flattenProperties(originalDataEntity)
        },
        {
          key: 'currentStatus', value:
            {
              Health: node.originalData.health ? node.originalData.health : 0,
              Issues: this.singleEntityService.calculateNodeAndLinkIssues(node.originalData),
              Latency: node.originalData.kpis.latency,
              Utilization: node.originalData.kpis.utilization,
              Users: node.originalData.kpis.users
            }
        }
      ];
    } else {
      let props: PanelElement[] = [{
        key: 'staticInfo', value: this.getCloudNodeStaticProps(originalDataEntity as CloudNode)
      }];
      if (isCloudVpcNode(originalDataEntity as CloudNode)) {
        props.push({
          key: 'subnets',
          value: this.generateSubnetsDisplay(originalDataEntity as CloudVpcNode),
          isTeleported: true
        });
        if ((originalDataEntity as CloudVpcNode).ipRange) {
          props.push({
            key: 'IP Range',
            value: {name: (originalDataEntity as CloudVpcNode).name, ip: (originalDataEntity as CloudVpcNode).ipRange}
          });
        }
      }
      return props;
    }
  }

  getCloudNodeStaticProps(originalDataEntity: CloudNode) {
    let props = {};
    for (const [key, value] of Object.entries(originalDataEntity)) {
      if (key !== 'id' && key !== 'subnets' && key !== 'properties') {
        if (key === "isDefault") {
          props[key] = value || 'false';
        } else {
          props[key] = value || 'Null';
        }
      }
      if (key === 'properties' && value != null) {
        const formattedProps = this.propsGenerator.flattenProperties(value);
        Object.keys(formattedProps).forEach(key => {
          if (key === "isDefault") {
            props[key] = formattedProps[key] || 'false';
          } else {
            props[key] = formattedProps[key] || 'Null';
          }
        })
      }
    }
    return props
  }

  generateSubnetsDisplay(node: CloudVpcNode): CloudSubnetDTO[] {
    return node.subnets;
  }
}
