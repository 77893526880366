import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'bypassSecurityPipe'
})
export class BypassSecurityPipePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: any, type: SecurityContext): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SecurityContext.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SecurityContext.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SecurityContext.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SecurityContext.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SecurityContext.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

