export enum LogLevel {
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
    SILENT = 5
}

export class LoggerConfig {
    enableColors: boolean;
    levels?: { [name: string]: LogLevel };
    defaultLevel: LogLevel;
}
