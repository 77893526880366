import { Component, OnInit, ViewChild, ComponentFactoryResolver, Input } from '@angular/core';
import { AnomalySummaryByCategory, AnomalyComponent, AnomalyMVC } from 'src/app/shared/models/anomalies.model';
import { AnomalyGridDirective } from '../directives/anomaly-grid.directive';

@Component({
  selector: 'app-anomalies-grids-renderer',
  templateUrl: './anomalies-grids-renderer.component.html',
  styleUrls: ['./anomalies-grids-renderer.component.scss']
})
export class AnomaliesGridsRendererComponent implements OnInit {
  @Input() components: AnomalyMVC[];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  @ViewChild(AnomalyGridDirective, { static: true }) anomalyGridElement: AnomalyGridDirective;

  ngOnInit() {
  }

  loadComponent(anomaly: AnomalySummaryByCategory) {
    const anomalyGrid: AnomalyMVC = this.components.find(anomalyComponent => anomalyComponent && anomalyComponent.data.category == anomaly.anomalyCategory);
    if (anomalyGrid) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(anomalyGrid.component);
      const viewContainerRef = this.anomalyGridElement.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<AnomalyComponent>(componentFactory);
      if (componentRef && componentRef.instance)
        componentRef.instance.data = anomalyGrid.data;
    }
  }

}
