<div class="toggle-bar-container" [ngStyle]="{height: height + 'px'}">
  <div class="col filter-toggle big-chart-toggle-col">
    <mat-button-toggle-group>
      <mat-button-toggle *ngFor="let button of toggleButtons" [value]="button"
                         [checked]="button === kpiGroupedBy.Traffic" (change)="onToggleButtonClick($event)">
        Show {{button}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <app-stacked-bar *ngIf="formattedStackedBarConfig && showStackedData" class="stacked-bar-parent"
                   [ngClass]="stackedBarClass" [stackedBarConfig]="formattedStackedBarConfig" [width]="width"
                   [height]="height"
                   (clickedDataset)="addSelectedDataSetToSplitData($event)">
  </app-stacked-bar>
</div>
