import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {ColDef, IDatasource, IGetRowsParams} from 'ag-grid-community';
import {BaseDashboardEntityList} from 'src/app/shared/components/entity-dashboard/entity-list/base-dashboard-entity-list';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {dashboardRowClassRules} from 'src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers';
import {ClientsService} from 'src/app/shared/services/rest-services/clients.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {OnlyGridScreensService} from 'src/app/shared/services/strategies/only-grid-screens.service';
import {GridSortFilter} from 'src/app/shared/models/sort-filter/grid-sort-filter.model';
import {StoreUserPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {LegacyClient} from 'src/app/shared/models/clients.model';
import {Page} from 'src/app/shared/models/page.model';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {StoreWidgetPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {GridTypes} from "../../../shared/models/client-storage.model";


@Component({
  selector: 'app-clients-history-list',
  templateUrl: './clients-history-list.component.html',
  styleUrls: ['./clients-history-list.component.scss']
})
export class ClientsHistoryListComponent extends BaseDashboardEntityList implements OnInit {
  @Output() total: EventEmitter<number> = new EventEmitter()
  private logger: Logger; components; columnDefs;
  showOnlineClientsChecked: boolean = false;
  totalClients: number;
  dataSource: IDatasource = {
    //rowCount: null,
    getRows: (params: IGetRowsParams) => {
      this.clientsService.getVenueClientsHistory(null, null, null, null).pipe(catchError(e => {
        this.isLoading = false;
        return of(null)
      })).subscribe(data => {
        this.isLoading = false;
        data = this.removeClientWithoutName(data);
        this.loadData(this.logger, params, data)
      }, () => params.failCallback());
    }
  }

  context: any;

  constructor(
    private clientsService: ClientsService,
    private dateConversionService: TimeManagerService,
    public onlyGridScreensService: OnlyGridScreensService,
    private loggerFactory: LoggerService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    private KpiDataService: KpiDataService,
    private translate: TranslateService) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, GridTypes.CLIENTS)
    this.logger = this.loggerFactory.getLogger("ClientsHistoryListComponent");
    this.numberOfRawsInPage = 19;
    // Query server on search changed
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`)
      this.searchText = searchText;
      // Call your function which calls API or do anything you would like do after a lag of 1 sec
      this.gridApi.setDatasource(this.dataSource)
    });
    this.context = {componentParent: this};

    //5.2.19 - Imry: Currently there is no special design that need renderer componenets
    this.components = {};

  }


  ngOnInit() {
    let thisClass = this;
    this.defaultColDef = {
      width: 50,
    };
    this.columnDefs = [
      {

        headerName: this.translate.instant('data.GRIDS_HEADERS.CLIENT_NAME'),
        width: 80,
        field: "clientName",
        sortable: false,
        valueGetter: (params) => {
          if (params.data && params.data.clientName)
            return params.data.clientName;
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.FIRST_SEEN'),
        field: 'firstSeen',
        width: 50,
        sortable: false,
        valueGetter: (params) => {
          if (params.data && params.data.firstSeen)
            return this.dateConversionService.dateByFormat(params.data.firstSeen, 'DD-MMM-YYYY HH:mm')
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.LAST_SEEN'),
        field: 'lastSeen',
        width: 50,
        sortable: false,
        valueGetter: (params) => {
          if (params.data && params.data.lastSeen)
            return thisClass.dateConversionService.dateByFormat(params.data.lastSeen, 'DD-MMM-YYYY HH:mm')
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.SENT'),
        field: 'sent',
        width: 30,
        sortable: false,
        valueGetter: (params) => {
          if (params.data && params.data.sent) {
            let valueAndDevitionCount: { finalValue: number, countOfDevition: number } = this.KpiDataService.calculateTrafficValue(params.data.sent, 0);
            params.data.sent = valueAndDevitionCount.finalValue.toFixed(2);
            return params.data.sent + this.KpiDataService.getBytesSizeByValue(valueAndDevitionCount.countOfDevition)
          }
          return 0;
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.RECV'),
        field: 'received',
        width: 30,
        sortable: false,
        valueGetter: (params) => {
          if (params.data && params.data.recv) {
            let valueAndDevitionCount: { finalValue: number, countOfDevition: number } = this.KpiDataService.calculateTrafficValue(params.data.recv, 0);
            params.data.recv = valueAndDevitionCount.finalValue.toFixed(2);
            return params.data.recv + this.KpiDataService.getBytesSizeByValue(valueAndDevitionCount.countOfDevition)
          }
          return 0;
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.SSID'),
        field: 'ssid',
        width: 30,
        sortable: false,
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.VLAN'),
        field: 'vlan',
        width: 30,
        sortable: false,
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.RECENT_DEVICE_NAME'),
        field: 'recentDeviceName',
        width: 70,
        sortable: false,
      }
    ];

    this.rowClassRules = dashboardRowClassRules;

    this.logger.debug("in ngOnInit of OrgIssuesListComponent")
  }

  /**
   * @method getGridHeight Set the grid height, in relation to the viwo port heigh
   * the number 234 is the result sum of:
   * The header and tab section height (88px combined)
   * the portlet header (48)
   * the grid header(56)
   * the total items div (44)
   * 2px for L&F to look better (regarding the total items div)
   *
   */
  getGridHeight() {
    let vh = window.innerHeight - 218;
    let gridHeight = vh + "px"
    return gridHeight;
  }

  emitTotalClientsNumber() {
    this.total.emit(this.totalClients);
  }

  onShowOnlineClientsClick() {
    this.showOnlineClientsChecked = !this.showOnlineClientsChecked;
    this.isLoading = true;
    this.gridApi.setDatasource(this.dataSource)
  }

  private removeClientWithoutName(data: Page<LegacyClient>): Page<LegacyClient> {
    if (data) {
      let filteredData = data.data.filter(client => client.clientName !== "null");
      if (filteredData.length > 0) {
        data.data = filteredData;
      }
    }
    return data;
  }

  ngOnDestroy(): void {
    this.clientsService.setQueriesForClientGrid(null);
    this.cancelSubscription();
  }

}
