import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEntitiesService } from '../../services/rest-services/global-entities.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IssuesHealthDialogComponent } from '../issues-health-dialog/issues-health-dialog.component';
import { IssueAction, IssueActionType } from './issue-action-data';
import { LoggerService, Logger } from '../../services/logger.service';
import { ThemeService } from '../../services/theme.service';
import { SeverityService } from '../../services/strategies/severity.service';
import { LegacySeverity } from '../../models/severity.model';
export class ContentField {
  field: string;
  label: string;
}

@Component({
  selector: 'app-issue-actions-dialog',
  templateUrl: './issue-actions-dialog.component.html',
  styleUrls: ['./issue-actions-dialog.component.scss']
})
export class IssueActionsDialogComponent implements OnInit {
  readonly deployTimeNow: string = "deployTimeNow"
  readonly deployTimeLater: string = "deployTimeLater"
  private logger: Logger; components; columnDefs;
  severityCssClass: string;
  severityText: string;
  // List of fields be passed and shown in
  // should this come from the row itslef to each grid being only aware of it's fields
  actionsDataFields = {
    [IssueActionType.ACKNOWLEDGE]: [{
      field: "description",
      label: "data.DASHBOARD.DESCRIPTION",
    }, {
      field: "affects",
      label: "data.DASHBOARD.AFFECTS",
    }],
    [IssueActionType.COMPLETE]: [{
      field: "description",
      label: "data.DASHBOARD.DESCRIPTION",
    }, {
      field: "affects",
      label: "data.DASHBOARD.AFFECTS",
    }]
  }

  constructor(private translate: TranslateService,
    private loggerFactory: LoggerService,
    protected themeService: ThemeService,
    private matDialogRef: MatDialogRef<IssuesHealthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public issueAction: IssueAction) {
    this.logger = this.loggerFactory.getLogger("IssueActionsDialogComponent");
    let severityTheme = this.themeService.getSeverityTheme()
    this.severityCssClass = severityTheme.css[this.issueAction.severity];
    this.severityText = this.translate.instant(this.getSeverityText(this.issueAction.severity));
  }

  ngOnInit() {
  }

  get ContentDataList(): ContentField[] {
    let contentDataList = this.actionsDataFields[this.issueAction.type]
    //console.log("this.contentFieldList %o", contentDataList)
    return contentDataList
  }

  private getSeverityText(severity: LegacySeverity) {
    let severityToText = {
      [LegacySeverity.High]: "data.COMMON.HIGHT_SEVERITY",
      [LegacySeverity.Medium]: "data.COMMON.MEDIUM_SEVERITY",
      [LegacySeverity.Minor]: "data.COMMON.MINOR_SEVERITY"
    }
    return severityToText[severity];
  }
  deployTimeChange() {
    this.logger.debug("deployTimeChange called")
  }
  closeDialog() {
    this.matDialogRef.close();
  }

}
