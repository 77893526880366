import {
  ExportPolicyDTO,
  getEndPointIconName,
  PolicyAction,
  PolicyEndPointDTO, PolicyRuleDTO
} from "./policies.model";
import {Page} from "./page.model";
import {stringToCamelcase} from "../operators/string-opertators";
import {IconWithText} from "./utils-classes/icon-with-text.model";
import {drawArrow} from "../operators/encoded-arrow";
import {CamelToUpperPipe} from "../pipes/camel-to-upper.pipe";

export class OpenPortsAndPolicies {
  openPortsView: Page<OpenPortsView>;
  policyView: GlobalPolicyView;
}

export class OpenPortsForExport {
  ['Open Ports']: any[];
  ['Global Policies']: any;
}

export class OpenPortsView {
  exPorts: string[];
  inPorts: string[];
  wanIP: string[];
  localIP: string[];
  protocol: string;
  allow: PolicyDisplay[];
  deny: PolicyDisplay[];

  constructor(nat: DNatView) {
    this.protocol = nat.protocol;
    this.exPorts = nat.externalPort;
    this.inPorts = nat.internalPort;
    this.wanIP = nat.externalIp;
    this.localIP = nat.internalIp;
    this.allow = generatePoliciesArray(nat.policies, PolicyAction.Allow, VenueOpenPortsWidgets.Grid);
    this.deny = generatePoliciesArray(nat.policies, PolicyAction.Deny, VenueOpenPortsWidgets.Grid);
  }
}

export class GlobalPolicyView {
  policyArray: PolicyDisplay[];

  constructor(venuePolicies: Array<ExportPolicyDTO>) {
    this.policyArray = generatePoliciesArray(venuePolicies, null, VenueOpenPortsWidgets.Horizontal_List);
  }
}

export class VenueDNatView {
  nats: Array<DNatView>;
  venuePolicies: Array<ExportPolicyDTO>;
}

export class DNatView {
  externalIp: string[];
  internalIp: string[];
  externalPort: string[];
  internalPort: string[];
  protocol: string;
  policies: Array<ExportPolicyDTO>;
}

export class PolicyDisplay {
  rules: Array<RuleAllowDenyDisplay>;
  order: number;

  constructor(rules: Array<RuleAllowDenyDisplay>, order: number) {
    this.rules = rules;
    this.order = order;
  }
}

export class RuleAllowDenyDisplay {
  action: PolicyAction;
  dstData: IconWithText[];
  srcData: IconWithText[];
  arrow = drawArrow("left");
  widget: VenueOpenPortsWidgets;

  constructor(rule: PolicyRuleDTO, policyOrder: number, widget: VenueOpenPortsWidgets) {
    this.action = rule.action;
    this.srcData = generateIconTextFromSrcEndPoint(rule.srcEndPoints);
    this.dstData = generateIconTextFromDstEndPoint(rule.dstEndPoints);
    this.widget = widget;
  }

  get toPlainText() {
    let dstAsText = this.endPointsToString(this.dstData);
    let srcAsText = this.endPointsToString(this.srcData);
    let finalText = `${stringToCamelcase(this.action)} traffic `;
    if (this.widget === VenueOpenPortsWidgets.Horizontal_List) {
      if (srcAsText && srcAsText.length > 0) {
        finalText += `from ${srcAsText} `;
      }
      if (dstAsText && dstAsText.length > 0) {
        finalText += `to ${dstAsText}`;
      }
    }
    if (this.widget === VenueOpenPortsWidgets.Grid) {
      if (srcAsText && srcAsText.length > 0) {
        finalText += `from ${srcAsText} `;
      }

      if (dstAsText && dstAsText.length > 0) {
        finalText += `to ${dstAsText}`;
      }
    }
    return finalText;
  }

  private endPointsToString(endPoint: IconWithText[]) {
    if (endPoint && endPoint.length > 0) {
      let endPointAsText = '';
      endPointAsText = endPoint.map(data => {
        let dataAsText = '';
        if (data.value) {
          dataAsText += ` ${data.value} `;
        }
        if (data.key) {
          dataAsText += `${data.key}`;
        }
        return dataAsText;
      }).toString();
      return endPointAsText;
    }
    return '';
  }
}

/**
 * return array of src endPoints for display. Based on Icon name, key and value
 * @param endPoints
 */
export function generateIconTextFromSrcEndPoint(endPoints: PolicyEndPointDTO[]) {
  let srcDetails: IconWithText[] = [];
  endPoints.forEach(point => {
    for (const [key, value] of Object.entries(point.value)) {
      srcDetails.push(endPointToIconWithText(key, value));
    }
  })
  return srcDetails;

}

/**
 * return array of dst endPoints for display. Based on Icon name, key and value
 * @param endPoints
 */
export function generateIconTextFromDstEndPoint(endPoints: PolicyEndPointDTO[]) {
  let dstDetails: IconWithText[] = [];
  endPoints.forEach(point => {
    dstDetails.push(new IconWithText(null, 'Interface', point.interfaceName));
    for (const [key, value] of Object.entries(point.value)) {
      if (value.toLowerCase() !== "any") {
        dstDetails.push(endPointToIconWithText(key, value));
      }
    }
  })
  return dstDetails;
}

/**
 * Convert endPoint key and value to IconWithText object
 */
function endPointToIconWithText(key: string, value: any) {
  if (key.toLowerCase() === 'protocol') {
    return (new IconWithText(getEndPointIconName(key), stringToCamelcase(key), value));
  }
  if (key.toLowerCase() === 'iprange') {
    return (new IconWithText(getEndPointIconName(key), `IP range`, value.toLowerCase()));
  }
  if (!SPECIAL_POLICY_KEYS.includes(key.toLowerCase())) {
    return (new IconWithText(getEndPointIconName(key), stringToCamelcase(key), value.toLowerCase()));
  }
}

/**
 * return array of policies Display DTO out of policyDTO
 * @param endPoints
 */
export function exportPolicyDTOToPolicyDisplay(exportPolicyDTO: Array<ExportPolicyDTO>, action: PolicyAction, widget: VenueOpenPortsWidgets) {
  let policies: Array<PolicyDisplay> = [];
  exportPolicyDTO.forEach(policy => {
    let rules: RuleAllowDenyDisplay[] = [];
    policy.networkRules.forEach(rule => {
      if (!action || rule.action === action) {
        rules.push(new RuleAllowDenyDisplay(rule, policy.order, widget))
      }
    })
    if (rules.length > 0) {
      policies.push(new PolicyDisplay(rules, policy.order));
    }
  })
  return policies;
}

/**
 * Generate policies array and sort by order
 * @param venuePolicies
 * @param action
 */
export function generatePoliciesArray(venuePolicies: Array<ExportPolicyDTO>, action: PolicyAction, widget: VenueOpenPortsWidgets) {
  let policiesArray = exportPolicyDTOToPolicyDisplay(venuePolicies, action, widget);
  policiesArray.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    }
    if (b.order > a.order) {
      return -1;
    }
    return 0;
  });
  return policiesArray;
}

export enum VenueOpenPortsWidgets {
  Grid,
  Horizontal_List
}

export const SPECIAL_POLICY_KEYS = [
  'protocol',
  'iprange'
]

