import {Component, OnInit, ChangeDetectorRef, Input} from '@angular/core';
import {catchError, take} from 'rxjs/operators';
import {TrafficService} from 'src/app/shared/services/rest-services/traffic.service';
import {ReportsStoreService} from 'src/app/shared/pages/reports/services/reports-store.service';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {TrafficUnits} from 'src/app/shared/models/kpi.model';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {TrafficDeviationService} from '../services/traffic-deviation.service';
import {TrafficOrigin, TrafficDeviation, TrafficDeviationDisplay} from '../../../../../models/legacy-reports.model';
import {OpenDialogService} from 'src/app/shared/services/open-dialog.service';
import {BaseReportsBarWidgets} from 'src/app/shared/pages/reports/models/base-reports-bar-widgets';
import {of} from 'rxjs';

@Component({
  selector: 'app-traffic-deviation-bar',
  templateUrl: './traffic-deviation-bar.component.html',
  styleUrls: ['./traffic-deviation-bar.component.scss']
})
export class TrafficDeviationBarComponent extends BaseReportsBarWidgets {
  trafficDeviation: TrafficDeviationDisplay[];
  verticalBarDatasets: VerticalBarData;
  verticalBarHeight: number = 300;
  verticalBarWidth: number = 130;
  trafficUnit: TrafficUnits;
  private logger: Logger; components; columnDefs;

  constructor(
    protected reportsStoreService: ReportsStoreService,
    protected dateConvertor: TimeManagerService,
    protected trafficDeviationService: TrafficDeviationService,
    protected translate: TranslateService,
    private trafficService: TrafficService,
    protected cdr: ChangeDetectorRef,
    protected openDialogService: OpenDialogService,
    private loggerFactory: LoggerService) {
    super(reportsStoreService, dateConvertor, trafficDeviationService, translate, openDialogService, cdr)
    this.logger = this.loggerFactory.getLogger("TrafficDeviationBarComponent");
  }

  loadData() {
    if (this.datesAsString && this.currentEntity && (this.currentEntity.type == EntityType.VENUE || this.currentEntity.type == EntityType.TENANT)) {
      this.isLoading = true;
      this.trafficService.getTrafficDeviationHistory(this.currentEntity, this.datesAsString.start, this.datesAsString.end, this.origin as TrafficOrigin).pipe(
        take(1),
        catchError(error => {
          this.isLoading = false;
          return of(null);
        })
      ).subscribe(trafficeDviationData => {
        this.isLoading = false;
        this.logger.debug('trafficDeviation', trafficeDviationData);
        const deviationData = this.trafficDeviationService.adujstResultToDates(trafficeDviationData, this.datesAsString);
        const trafficAndSize = this.trafficDeviationService.getTrafficBySize(deviationData as TrafficDeviation[]);
        if (trafficAndSize) {
          this.trafficDeviation = trafficAndSize.data;
          this.trafficUnit = trafficAndSize.unit;
        }
        ;
        this.verticalBarDatasets = this.trafficDeviationService.getBarDatasets(this.trafficDeviation, this.datesAsString);
        this.logger.debug('verticalBarDatasets In loadData', this.verticalBarDatasets);
      })
    } else {
      this.verticalBarDatasets = null;
    }
    this.cdr.detectChanges();
  }

  get title() {
    return this.translate.instant('data.REPORTS.TRAFFIC_DEVIATION', {
      origin: this.trafficDeviationService.getBarTitle(this.origin as TrafficOrigin)
    }) + ` (${this.trafficUnit})`
  }

  get isVerticalBarDatasets() {
    return this.verticalBarDatasets;
  }

  ngOnDestroy() {
    this.destroyProcedure();
  }

}
