import { Component, OnInit } from '@angular/core';
import { KpiType, KpiData } from 'src/app/shared/models/kpi.model';
import { BaseHealthSingleEntities } from 'src/app/shared/components/single-entities/base-health-single-entities';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from 'src/app/shared/services/logger.service';
import {take, filter, catchError} from 'rxjs/operators';
import { InitatePropertiesDataService } from 'src/app/shared/components/properties/services/initate-properties-data.service';
import { BaseSingleEntityService } from 'src/app/shared/services/base-single-entity.service';
import { StoreKpisBarService } from './store-kpis-bar.service';
import {forkJoin, of} from 'rxjs';
import { BandwidthUtilizationData } from 'src/app/shared/models/bandwidth.model';
import { SingleSizeCalculationService } from 'src/app/venues/services/single-size-calculation.service';
import { BandwidthService } from 'src/app/shared/services/rest-services/bandwidth.service';
import {RestKpiGeneratorService} from "../../../kpi-display-components/services/rest-kpi-generator.service";

@Component({
  selector: 'app-kpis-bar',
  templateUrl: './kpis-bar.component.html',
  styleUrls: ['./kpis-bar.component.scss']
})
export class KpisBarComponent extends BaseHealthSingleEntities implements OnInit {
  selectedWidgetType: KpiType = KpiType.Health;
  widgetsData: KpiData[];
  isWidgetSelected = false;
  venueUtilizationData: BandwidthUtilizationData;
  graphWidth: number;
  graphHeight: number;


  constructor(
    protected translate: TranslateService,
    protected loggerFactory: LoggerService,
    protected baseSingleEntityService: BaseSingleEntityService,
    private restKpiGeneratorService: RestKpiGeneratorService,
    private initatePropertiesDataService: InitatePropertiesDataService,
    private storeKpisBarService: StoreKpisBarService,
    private singleSizeCalculationService: SingleSizeCalculationService,
    private bandwidthService: BandwidthService

  ) {
    super(translate, loggerFactory, baseSingleEntityService);
  }

  ngOnInit() {
    this.subscribeToCurrentEntityDetails();
  }
  subscribeToCurrentEntityDetails() {
    const entitySubsc = this.baseSingleEntityService.notifyEntityDataAsObservable$.pipe(
      filter(entity => !!entity))
      .subscribe(entityDetails => {
        if (entityDetails && !this.isSameVenue(entityDetails)) {
          this.entityDetails = entityDetails;
          if (entityDetails.hasOwnProperty('venue')) {
            this.getVenueUtilization();
          }
          this.initSingleWidgetsBar();
        }
      });
    this.subscription.push(entitySubsc);
  }
  /**
 * @method widgetSelected Save the current clicked widget, and set relevent changes according to that selection
 * 1. Set the chart title
 * 2. set the selected widget type
 * @param prop is the Current widget data
 */
  widgetSelected(prop: any, event: any) {
    this.selectedWidgetType = prop.key;
    this.isWidgetSelected = true;
    this.storeKpisBarService.storeKpiTypeSelection(this.selectedWidgetType);
  }
  /**
* @method initSingleWidgetsBar Recevie all the kpi values
*/
  protected initSingleWidgetsBar() {
    this.isLoading = true;
    let kpiSubsc = this.restKpiGeneratorService.getKpiData().pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(null);
      })
    ).subscribe(
      ([kpiHealth, kpiLatency, kpiLoss, kpiTraffic, kpiUsers]) => {
        this.isLoading = false;
        this.widgetsData = [
          new KpiData(
            KpiType.Health,
            +this.entityDetails.health ? this.initatePropertiesDataService.multiHealthBy100(+this.entityDetails.health) : 0
          ),
          new KpiData(
            KpiType.Latency,
            kpiLatency.value ? kpiLatency.value : 0),
          new KpiData(
            KpiType.Loss,
            kpiLoss.value ? kpiLoss.value : 0),
          new KpiData(
            KpiType.Traffic,
            kpiTraffic.value ? kpiTraffic.value : 0
          ),
          new KpiData(
            KpiType.Clients,
            kpiUsers.value ? kpiUsers.value : 0
          )
        ]
      }
    )
    this.subscription.push(kpiSubsc);
  }

  getVenueUtilization() {
    const utilizationObservables$ = forkJoin({ bandwidth: this.bandwidthService.getVenueBandwidth(this.entityDetails.venue.id), utilization: this.bandwidthService.getVenueUtilization(this.entityDetails.venue.id) });
    this.subscription.push(
      utilizationObservables$.pipe(take(1)).subscribe({
        next: venueUtilizationData => {
          this.venueUtilizationData = venueUtilizationData;
        }
      }))
  }

  /**
* @method calculateBigChartSize Responsible for the responsivness of the chart
*/
  getBigChartSize() {
    let graphSizes: { width: number, height: number };
    graphSizes = this.singleSizeCalculationService.calculateBigChartSize();
    this.graphWidth = graphSizes.width;
    this.graphHeight = graphSizes.height;
  }

  /**
     * @method closeChart Close the chart
     */
  closeChart() {
    this.isWidgetSelected = false;
    this.selectedWidgetType = null;
  }
  get isEntityDevice() {
    return this.entityDetails.hasOwnProperty('device');
  }

  ngOnDestroy() {
    this.storeKpisBarService.deleteKpiTypeSelection();
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}
