import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IGetRowsParams, IDatasource } from 'ag-grid-community';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { dashboardRowClassRules } from 'src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers';
import { GridSortFilter } from 'src/app/shared/models/sort-filter/grid-sort-filter.model';
import { TenantsService } from 'src/app/shared/services/rest-services/tenants.service';
import { BaseDashboardEntityList } from 'src/app/shared/components/entity-dashboard/entity-list/base-dashboard-entity-list';
import { GlobalEntitiesService } from 'src/app/shared/services/rest-services/global-entities.service';
import { StoreUserPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import { Page } from 'src/app/shared/models/page.model';
import { FabricsHealth } from 'src/app/shared/models/fabrics.model';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { StoreWidgetPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {GridTypes} from "../../../shared/models/client-storage.model";

export class FabricConfiguraionControl {
  id: number;
  venueName: string;
  fabricName: string;
  latestChange: string;
  changedAt: string;
}

export enum ConfigurationVersionType {
  FabricCreated,
  FabricNameChanged,
  BandwidthLimitChanged,
  IsolationActivated,
  LandingPageSet,
  UrlFilteringAdded
}

export namespace ConfigurationVersionType {
  export function getConfigurationVersionTypeAsString(configurationType: string, translate: TranslateService, value?: string | number) {
    switch (ConfigurationVersionType[configurationType]) {
      case ConfigurationVersionType.FabricCreated:
        return translate.instant('data.TENANTS.FABRICS.FABRIC_CREATED');
      case ConfigurationVersionType.FabricNameChanged:
        return translate.instant('data.TENANTS.FABRICS.FABRIC_NAME_CHANGED', value);
      case ConfigurationVersionType.BandwidthLimitChanged:
        return translate.instant('data.TENANTS.FABRICS.FABRIC_TRAFFIC_LIMIT_CHANGED', value);
      case ConfigurationVersionType.IsolationActivated:
        return translate.instant('data.TENANTS.FABRICS.FABRIC_ISOLATION_ACTIVATED');
      case ConfigurationVersionType.LandingPageSet:
        return translate.instant('data.TENANTS.FABRICS.LANDING_PAGE_SET');
      case ConfigurationVersionType.UrlFilteringAdded:
        return translate.instant('data.TENANTS.FABRICS.URL_FILTERING_ADDED');
      default:
        break;
    }
  }
  export function getConfigurationVersionTypeAsArray() {
    return [
      ConfigurationVersionType.FabricCreated,
      ConfigurationVersionType.FabricNameChanged,
      ConfigurationVersionType.BandwidthLimitChanged,
      ConfigurationVersionType.IsolationActivated,
      ConfigurationVersionType.LandingPageSet,
      ConfigurationVersionType.UrlFilteringAdded
    ];
  }
}
@Component({
  selector: 'app-fabrics-version-control-list',
  templateUrl: './fabrics-version-control-list.component.html',
  styleUrls: ['./fabrics-version-control-list.component.scss']
})
export class FabricsVersionControlListComponent extends BaseDashboardEntityList implements OnInit {
  private logger: Logger; components; columnDefs;
  dataSource: IDatasource = {
    //rowCount: null,
    getRows: (params: IGetRowsParams) => {
      // TODO;
      // Use startRow and endRow for sending pagination to Backend
      // params.startRow : Start Page
      // params.endRow : End Page
      // use this.searchText to filter the data source
      this.tenantsService.fetchFabricsHealth(new GridSortFilter(params, this.searchText, this.numberOfRawsInPage)).subscribe(data => {
        this.loadData(this.logger, params, this.generateVersionMockedData(data))
      }, () => params.failCallback());
    }
  };
  tenantIdSubscr: Subscription;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantsService: TenantsService,
    private loggerFactory: LoggerService,
    private translate: TranslateService,
    private globalEntitiesService: GlobalEntitiesService,
    private dateConversionService: TimeManagerService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, GridTypes.FABRICS_CONFIGURATION_VSERION);
    this.logger = this.loggerFactory.getLogger("FabricsVersionControlListComponent");
    // Query server on search changed
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`)
      this.searchText = searchText;
      // Call your function which calls API or do anything you would like do after a lag of 1 sec
      this.gridApi.setDatasource(this.dataSource)
    });
    this.tenantIdSubscr = this.globalEntitiesService.tenantId$.subscribe((entity) => {
      this.logger.debug("FabricsVersionControlListComponent current entity changed")
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource)
      }
    })
    this.context = { componentParent: this, enableCellHover: true };
    this.components = {};

  }

  ngOnInit() {

    this.defaultColDef = {
      width: 50,
    };

    let columnDefs = [
      {
        headerName: this.translate.instant('data.DASHBOARD.PARENT_VENUE'),
        field: 'venueName',
        width: 80,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.COMMON.FABRIC'),
        field: 'fabricName',
        width: 90,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.TENANTS.FABRICS.LATEST_CHANGE'),
        field: 'latestChange',
        width: 170,
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.WHEN'),
        field: 'changedAt',
        width: 80,
        sortable: true,
      },

    ];
    this.columnDefs = columnDefs;
    this.rowClassRules = dashboardRowClassRules;


    this.logger.debug("in ngOnInit of TenantVenuesListComponent")
  }
  private generateVersionMockedData(data: Page<FabricsHealth>): Page<FabricConfiguraionControl> {
    let dataLength = data.data.length;
    let newData: Page<FabricConfiguraionControl> = {
      total: data.total,
      data: data.data.map((fabricHealth, index) => {
        let formattedFabricAsVersion: FabricConfiguraionControl = {
          id: fabricHealth.id,
          venueName: fabricHealth.parentVenueName,
          fabricName: fabricHealth.fabricName,
          latestChange: this.generateMockedChange(dataLength, fabricHealth, index),
          changedAt: this.dateConversionService.generateMockedTimePeriods(index)
        }
        return formattedFabricAsVersion;
      })
    }
    return newData;
  }

  generateMockedChange(dataLength: number, fabricHealth: FabricsHealth, currentElementIndex: number): string {
    let type: string = ConfigurationVersionType[currentElementIndex];
    let value: any;
    if (!type)
      type = ConfigurationVersionType[ConfigurationVersionType.FabricCreated];
    if (ConfigurationVersionType[type] == ConfigurationVersionType.FabricNameChanged)
      value = { fabricName: fabricHealth.fabricName };
    if (ConfigurationVersionType[type] == ConfigurationVersionType.BandwidthLimitChanged)
      value = {
        inbound: Math.floor(Math.random() * 1000) + 1,
        outbound: Math.floor(Math.random() * 1000) + 1
      };
    return ConfigurationVersionType.getConfigurationVersionTypeAsString(type, this.translate, value);
  }
  ngOnDestroy(): void {
    this.tenantIdSubscr.unsubscribe()
    this.cancelSubscription();
  }

}
