import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Fabric, FabricStatus, FabricToggleStatus, FabricTotalData} from 'src/app/shared/models/fabrics.model';
import {VenueSingleStoreService} from 'src/app/shared/services/rest-services/venue-single-store.service';
import {take} from 'rxjs/operators';
import {FabricDisplay} from './components/venue-fabric-legends/venue-fabrics-model';


@Component({
  selector: 'app-venue-fabrics-list',
  templateUrl: './venue-fabrics-list.component.html',
  styleUrls: ['./venue-fabrics-list.component.scss']
})
export class VenueFabricsListComponent {
  private _fabrics: Fabric[];
  get fabrics() {
    return this._fabrics;
  }
  @Input() set fabrics (fabrics: Fabric[]) {
    this._fabrics = fabrics;

    if (this.isSortFabrics)
      this.sortFabrics();

    if (this.showToggles)
      this._fabrics?.forEach(fabric => {
        this.itemCounts.all++;
        if (fabric.fabricStatus?.status === FabricStatus.COMPLETE)
          this.itemCounts.complete++;
        else if (fabric.fabricStatus?.status === FabricStatus.INCOMPLETE)
          this.itemCounts.incomplete++;
      });

    this.setFabricsDisplay(this._fabrics);
  };
  @Input() venueName: string;
  @Input() listHeader: string = 'data.VENUES.FABRICS_ANALYTICS';
  @Input() listHeight: number = 100;
  @Input() listHeightOffset: number = 330;
  @Input() listMaxHeight: string = 'unset';
  @Input() showLegends: boolean = true;
  @Input() showToggles: boolean = false;
  @Input() showLoading: boolean = false;
  @Input() loadingDiameter: number = 100;
  @Input() loadingStrokeWidth: number = 10;
  @Input() isSortFabrics: boolean = false;
  @Output() selectedFabricTotalData: EventEmitter<FabricTotalData> = new EventEmitter();

  fabricsDisplay: FabricDisplay[] = [];
  selectedFabricId: number;
  fabricStatus = FabricStatus;
  itemCounts = {all: 0, complete: 0, incomplete: 0}
  isLoading: boolean = true;

  constructor(
    private venueSingleStoreService: VenueSingleStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges() {
    this.cdr.markForCheck();
  }

  private setFabricsDisplay(fabrics: Fabric[]) {
    this.fabricsDisplay = [];
    if (fabrics) {
      fabrics.forEach(fabric => {
        this.fabricsDisplay.push(new FabricDisplay(fabric));
      });

      this.isLoading = false;
    }
  }

  private sortFabrics() {
    let incompleteFabrics: Fabric[] = [];
    let noWwwFabrics: Fabric[] = [];
    let completeFabrics: Fabric[] = [];
    this._fabrics.forEach(fabric => {
      if (fabric.fabricStatus.status === FabricStatus.INCOMPLETE)
        incompleteFabrics.push(fabric);
      else if (fabric.fabricStatus.status === FabricStatus.NoWwwConnectivity)
        noWwwFabrics.push(fabric);
      else if (fabric.fabricStatus.status === FabricStatus.COMPLETE)
        completeFabrics.push(fabric);
    });

    this._fabrics = [...incompleteFabrics, ...noWwwFabrics, ...completeFabrics];
  }

  onFabricSelected(fabric: Fabric) {
    if (fabric) {
      this.selectedFabricId = fabric.id;
      this.venueSingleStoreService.getFabricData(fabric).pipe(take(1))
        .subscribe(([policies, fabricData, fabricTopology, fabricKpi]) => {
          let fabricTotalData: FabricTotalData = {
            fabricData: { entityData: fabricData, policiesData: policies },
            fabricTopology: this.venueSingleStoreService.changeTopologyWWWId(fabricTopology),
            health: +fabricKpi.value.toFixed(0)
          }
          this.selectedFabricTotalData.emit(fabricTotalData);
        })
    }
  }

  filterFabrics(status: FabricToggleStatus) {
    switch (status) {
      case FabricToggleStatus.COMPLETE:
        this.setFabricsDisplay(this.fabrics.filter(fabric => fabric.fabricStatus.status === FabricStatus.COMPLETE));
        break;
      case FabricToggleStatus.INCOMPLETE:
        this.setFabricsDisplay(this.fabrics.filter(fabric => fabric.fabricStatus.status === FabricStatus.INCOMPLETE));
        break;
      default:
        this.setFabricsDisplay(this.fabrics);
        break;
    }
  }
}
