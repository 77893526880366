import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {HealthColorsService} from 'src/app/shared/services/strategies/health-colors.service';
import {KpiData, kpiDisplayData, KpiType} from 'src/app/shared/models/kpi.model';
import {Breakpoints} from '@angular/cdk/layout';
import {GraphTrendSizeService} from 'src/app/shared/services/strategies/graph-trend-size.service';
import {TranslateService} from '@ngx-translate/core';
import {KpiDataService} from 'src/app/shared/services/strategies/kpi-data.service';
import {NgChanges} from "../../../extend-angular-classes/on-changes";


@Component({
  selector: 'app-single-widgets-bar-content',
  templateUrl: './single-widgets-bar-content.component.html',
  styleUrls: ['./single-widgets-bar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleWidgetsBarContent {
  @Input() widgetContentData: KpiData;
  widgetDisplayData: kpiDisplayData;
  kpiUnit: string;
  graphWidth: number;
  graphHeight: number;

  constructor(
    private healthColorsService: HealthColorsService,
    private graphTrendSizeService: GraphTrendSizeService,
    private KpiDataService: KpiDataService,
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: NgChanges<SingleWidgetsBarContent>) {
    this.getKpiUnitsAndValue();
    this.prepareDataForDisplay();
    this.calculateSmallChartSize();
  }

  getKpiUnitsAndValue() {
    this.kpiUnit = this.KpiDataService.getKpiUnit(this.widgetContentData.key);
    if (this.widgetContentData.key == KpiType.Traffic) {
      let valueAndSize = this.KpiDataService.getBytesSizeAfterCalc(this.widgetContentData.value);
      this.kpiUnit = valueAndSize.size
      this.widgetContentData.value = valueAndSize.value;
    }
  }

  prepareDataForDisplay() {
    this.widgetDisplayData = { key: this.widgetContentData.key, value: null };
    if (this.widgetContentData.value >= 0) {
      if (this.widgetContentData.key == KpiType.Clients)
        this.widgetDisplayData.value = this.widgetContentData.value.toLocaleString();
      else
        this.widgetDisplayData.value = this.widgetContentData.value.toFixed(2);
    }
    else
      this.widgetDisplayData.value = this.widgetContentData.value.toString();
  }

  calculateSmallChartSize() {
    let mediaWidth = {
      [Breakpoints.XLarge]: { width: 180, heigh: 50 },
      [Breakpoints.Large]: { width: 140, heigh: 45 },
      [Breakpoints.Medium]: { width: 70, heigh: 40 },
      [Breakpoints.Small]: { width: 40, heigh: 15 },
    }
    const [width, height] = this.graphTrendSizeService.findGraphSize(mediaWidth);
    this.graphWidth = width;
    this.graphHeight = height;
  }

  /**
   * @method getHealthColor recieve color style for the healte precentage value
   * @param widgetsContentData is contain the key for the widget element
   */

  get percentageColor() {
    if (this.widgetContentData && this.widgetContentData.key === KpiType.Health) {
      return this.healthColorsService.getHealthColors(this.widgetContentData.value);
    }
  }
  get withLastHour() {
    if (this.widgetContentData && (this.widgetContentData.key !== KpiType.Health))
      return this.translateService.instant('data.COMMON.LAST_HOUR');
  }
  get notCalculated() {
    if (this.widgetContentData && this.widgetContentData.value == -1)
      return true;
  }

  /**
   * @method getWidgetIconPath recieve the element key, and iconPath.
   * @param key The key value for the current element
   */

  get widgetIconPath() {
    if (this.widgetContentData) {
      switch (this.widgetContentData.key) {
        case KpiType.Loss:
          return '/assets/media/netop/loss.svg';
        case KpiType.Utilization:
          return '/assets/media/netop/topology/utilization-fabric-header.svg'
        case KpiType.Latency:
          return '/assets/media/netop/topology/latency-fabric-header.svg'
        case KpiType.Traffic:
          return '/assets/media/netop/topology/traffic-fabric-header.svg'
        case KpiType.Clients:
          return '/assets/media/netop/topology/users-fabric-header.svg'
        default:
          break;
      }
    }
  }

  get kpiTitle() {
    if (this.widgetDisplayData) {
      if (this.widgetDisplayData.key !== KpiType.Clients)
        return this.widgetDisplayData.key + " (" + this.kpiUnit + ")";
      return this.widgetDisplayData.key;
    }
  }
}
