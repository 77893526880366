import {Component, Inject, OnInit} from '@angular/core';
import {SingleSizeCalculationService} from "../../../venues/services/single-size-calculation.service";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GraphType} from "../../components/graph-type-selector/graph-type.enum";
import {GlobalEntitiesService} from "../../services/rest-services/global-entities.service";
import {Observable} from "rxjs";
import {Entity} from "../../models/entity.model";

@Component({
  selector: 'app-kpi-dialog',
  templateUrl: './kpi-dialog.component.html',
  styleUrls: ['./kpi-dialog.component.scss']
})
export class KpiDialogComponent implements OnInit {
  graphWidth: number;
  graphHeight: number;
  selectedWidgetType: GraphType;
  entity: Observable<Entity>;

  constructor(private singleSizeCalculationService: SingleSizeCalculationService,
              private globalEntitiesService: GlobalEntitiesService,
              private matDialogRef: MatDialogRef<KpiDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public graphType: GraphType
  ) {
    this.selectedWidgetType = graphType;
  }

  ngOnInit() {
    this.entity = this.globalEntitiesService.currentEntity$;
  }

  /**
   * @method calculateBigChartSize Responsible for the responsivness of the chart
   */
  getBigChartSize() {
    let graphSizes: { width: number, height: number };
    graphSizes = this.singleSizeCalculationService.calculateBigChartSize();
    this.graphWidth = graphSizes.width;
    this.graphHeight = graphSizes.height;
  }

  closeDialog() {
    this.matDialogRef.close();
  }

}
