<div class="time-range-picker">
  <div class="time-range-picker-display">
    <div class="time-range-picker-period">
      <app-time-period-input [interval]="interval"
                             [timeUnit]="timeUnit"
                             [timeUnitOptions]="timeUnitOptions"
                             [timeUnitBounds]="timeUnitBounds"
                             [prefix]="periodPrefix"
                             (periodChange)="onTimePeriodChanged($event)">
      </app-time-period-input>
    </div>
    <div [mat-menu-trigger-for]="menu" class="time-range-picker-data-time" (click)="emit = false">{{display}}</div>
    <button mat-raised-button class="back-to-now-text" (click)="onNow()">Go to Now</button>
  </div>
  <mat-menu #menu="matMenu" classList="time-range-picker-menu" (closed)="onClose()">
    <div (click)="$event?.stopPropagation()">
      <app-date-time-picker [date]="date" (dateTimeSelected)="onDateTimeSelected($event)">
      </app-date-time-picker>
    </div>
  </mat-menu>
</div>
