import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgxSelectOption} from "../../../ngx-select/ngx-select.classes";
import {SelectionOption} from "../select-option.model";
import {NgxSelectComponent} from "../../../ngx-select/ngx-select.component";
import {VenuesService} from "../../../services/rest-services/venues.service";
import {GlobalEntitiesService} from "../../../services/rest-services/global-entities.service";
import {take} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-venues-multi-select-list',
  templateUrl: './venues-multi-select-list.component.html',
  styleUrls: ['./venues-multi-select-list.component.scss']
})
export class VenuesMultiSelectListComponent implements OnInit {

  @Input() venuesOptions: SelectionOption[] = [];
  @Input() venuesFormControl: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder: string;
  @Output() venuesChanged = new EventEmitter<NgxSelectOption[]>();
  @Output() venuesRemoved = new EventEmitter<NgxSelectOption[]>();
  @Output() venuesClosed = new EventEmitter<NgxSelectOption[]>();
  @ViewChild('venuesSelect') venuesSelectControl: NgxSelectComponent;

  ignoreIfFired = false;

  constructor(protected venuesService: VenuesService, protected globalEntitiesService: GlobalEntitiesService,
              protected translate: TranslateService) { }

  ngOnInit(): void {
    this.fetchVenues();
  }

  fetchVenues() {
    if (!this.venuesOptions || this.venuesOptions.length == 0) {
      this.fetchTenantVenues();
    }
  }

  fetchTenantVenues() {
    this.venuesService.fetchTenantVenuesSummary().pipe(take(1)).subscribe(venues => {
      this.venuesOptions = this.venuesOptions = venues.map(venue => ({
        id: venue.id,
        name: venue.name,
        description: this.translate.instant('data.TENANTS.ADVANCED.VENUE_DESCRIPTION', venue)
      }));
    });
  }

  isVenueChecked(option: NgxSelectOption): boolean {
    return this.venuesSelectControl.isOptionSelected(option);
  }

  onVenuesSelectionChanged(optionsSelected: NgxSelectOption[]) {
    this.venuesChanged.emit(optionsSelected);
  }

  onVenuesSelectionRemoved(optionsSelected: NgxSelectOption[]) {
    this.venuesRemoved.emit(optionsSelected);
  }

  onVenuesSelectionClosed(optionsSelected: NgxSelectOption[]) {
    this.venuesClosed.emit(optionsSelected);
  }

}
