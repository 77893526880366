import {AfterViewInit, Directive, Input, ViewContainerRef} from '@angular/core';
import {isNotNullandUndefined} from "../operators/object-operators/null-checks";

@Directive({
  selector: '[fromTo]'
})
export class FromToDirective implements AfterViewInit {
  constructor(private viewContainer: ViewContainerRef) {
  }

  @Input('from') from: number = 0;
  @Input('to') to: number = 0;
  @Input('fromColor') fromColor = 'severity-medium';

  ngAfterViewInit() {
    let element = this.viewContainer.element.nativeElement as HTMLElement;
    if (isNotNullandUndefined(this.from) && this.to > 0 && this.from <= this.to)
      element.innerHTML = `<span class="${this.fromColor}">${this.from}</span>/${this.to}`;
    else
      element.innerHTML = `${this.to}`;
  }
}
