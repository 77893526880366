import {Component, OnInit, Output, EventEmitter, TemplateRef, Input} from '@angular/core';

@Component({
  selector: 'app-bot-summary-container',
  templateUrl: './bot-summary-container.component.html',
  styleUrls: ['./bot-summary-container.component.scss']
})
export class BotSummaryContainerComponent implements OnInit {

  @Input() showCreate: boolean;
  @Input() entityId: any;
  @Output() cancelClicked = new EventEmitter();
  @Output() createClicked = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  onCancelClicked() {
    this.cancelClicked.emit();
  }

  onCreateClicked($event: MouseEvent) {
    this.createClicked.emit($event);
  }

}
