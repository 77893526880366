import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {PropertiesContent, PropertiesElementType} from './models/properties-content';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SingleDeviceType} from '../../models/single-device.model';
import {SaveEditedPropertiesComponent} from '../../modals/save-edited-properties/save-edited-properties.component';
import {FormAction} from '../../modals/models/form-actions.model'
import {ModalTextContent} from '../../modals/models/modal-content.model'
import {EditPropertiesService} from './services/edit-properties.service';
import {SourceChanged} from './models/source-changes';
import {DisplaySuccessService} from '../../services/display-success.service';
import {take} from 'rxjs/operators';
import {EditAbleKeys} from './models/editableKeys';
import { MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {VenuesService} from "../../services/rest-services/venues.service";
import {BooleanKey} from "../../models/utils-classes/key-value.model";

@Component({
  selector: 'app-legacy-properties',
  templateUrl: './legacy-properties.component.html',
  styleUrls: ['./legacy-properties.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LegacyPropertiesComponent {
  @Input() title: string;
  @Input() propertiesData: PropertiesContent;
  @Input() isDevice = false;
  @Input() editAbleObject: EditAbleKeys;
  @Output() editablePropsSaved: EventEmitter<any> = new EventEmitter();
  @Output() specialItemStatusChanged: EventEmitter<BooleanKey> = new EventEmitter();
  @Output() closeIconClicked: EventEmitter<boolean> = new EventEmitter<boolean>();


  editablePropsValues: any;
  showButton: string;
  nodeElementType: PropertiesElementType = PropertiesElementType.NODE;
  wwwType: SingleDeviceType = SingleDeviceType.WWW;
  buttonsType = FormAction;
  editedSuccessMessage: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private venuesService: VenuesService,
    private translate: TranslateService,
    private editPropertiesService: EditPropertiesService,
    public dialog: MatDialog,
    private successService: DisplaySuccessService,
  ) {
    this.showButton = this.translate.instant('data.PROPERTIES.SHOW_DEVICE');
    this.editedSuccessMessage = this.translate.instant('data.PROPERTIES.EDITED_SUCCESS_MESSAGE');
  }

  moveToDevicePage(deviceId: any) {
    this.router.navigate(['/device', deviceId], {relativeTo: this.activatedRoute}).catch();
  }

  syncVenue() {
    this.venuesService.syncVenue().pipe(take(1)).subscribe(result => {
      const message = this.translate.instant('data.VENUES.SYNC_STARTED') + " " + this.propertiesData.headerData.firstColumn.main;
      this.successService.openMessage(message);
    });
  }

  /**
   * @method editablePropertiesChanged Show edit buttons if there are changed propertis
   * @param event The entire editable properties - when one of them has changed
   */
  editablePropertiesChanged(event: SourceChanged) {
    if (event) {
      this.editablePropsValues = event;
    }
  }

  /**
   * @method isPropertySaved Emit the entire editable properties when at list one of them is changed
   */
  savePropertiesChanges() {
    this.editPropertiesService.isEditMode(false);
    this.editablePropsSaved.emit(this.editablePropsValues);
    this.editPropertiesService.saveFormActionStatus(FormAction.SAVE);
    this.successService.openMessage(this.editedSuccessMessage);
  }

  /**
   * Open Edit Form modal
   */
  openModal(formAction: FormAction) {
    let type = formAction;
    let modalText: ModalTextContent = {
      title: this.translate.instant('data.PROPERTIES.CANCEL_EDITED_PROPERTIES_TITLE'),
      message: this.translate.instant('data.PROPERTIES.CANCEL_EDITED_PROPERTIES_MESSAGE')
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '357';
    dialogConfig.width = '648px';
    dialogConfig.data = {
      type: type,
      data: modalText
    }

    //Updating the editPropertiesService, so the properties content component will know to re-initlize the properties values
    this.dialog.open(SaveEditedPropertiesComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result == FormAction.DELETE)
        this.editPropertiesService.saveFormActionStatus(result)
      if (result == FormAction.SAVE) {
        this.savePropertiesChanges();
      }
    })
  }

  get isSwitchStack() {
    return this.propertiesData.headerData?.type == SingleDeviceType.SwitchStack;
  }

  get isEditMode() {
    let isEditMode = false;
    let isFormValid = false;
    let canEnterEditMode = false;
    this.editPropertiesService.notifyIsEditModeObservable$.pipe(take(1)).subscribe(mode => {
      isEditMode = mode;
    });

    this.editPropertiesService.notifyFormValidObservable$.pipe(take(1)).subscribe(isValid => {
      isFormValid = isValid;
    });
    if (isEditMode && isFormValid)
      canEnterEditMode = true;
    return canEnterEditMode;
  }

  get isCloudTopology(): boolean {
    return this.propertiesData && this.propertiesData.headerData && this.propertiesData.headerData.elementType == PropertiesElementType.CloudNode;
  }

  onSpecialItemStatusChanged(keyWithStatus: BooleanKey) {
    this.specialItemStatusChanged.emit(keyWithStatus);
  }
}
