import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {PolicyDisplay} from "../../../models/open-ports.model";

@Component({
  selector: 'app-allow-deny-view',
  templateUrl: './allow-deny-view.component.html',
  styleUrls: ['./allow-deny-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllowDenyViewComponent implements OnInit {
  @Input() policies: PolicyDisplay[];
  @Input() cssOverride: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
