import { Directive, TemplateRef, ViewContainerRef, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';
// How to build a structural directive
// https://netbasal.com/the-power-of-structural-directives-in-angular-bfe4d8c44fb1
@Directive({
  selector: '[appFlowStep]'
})
export class FlowStepDirective {
  _hidden: boolean = true;
  @Input('appFlowStep') step: string;
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  @Input() set hidden(condition: boolean) {
    // console.log('step %s called with condition %s', this.step, condition)
    if (condition) {
      this.viewContainer.clear();
    } else {
      if (this._hidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
    this._hidden = condition;
  }
  get hidden() {
    return this._hidden;
  }
}
