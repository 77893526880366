import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { ConfirmationModalComponent } from "src/app/shared/modals/confirmation-modal/confirmation-modal.component";
import { EntitySyncStatus } from "src/app/shared/models/sync-status.model";
import { VenueData, VenueSyncData } from "src/app/shared/models/venues.model";
import { DisplaySuccessService } from "src/app/shared/services/display-success.service";
import { VenuesService } from "src/app/shared/services/rest-services/venues.service";
import { TimeManagerService } from "src/app/shared/services/time-manager.service";

@Component({
  selector: 'app-single-sync-status',
  templateUrl: './single-sync-status.component.html',
  styleUrls: ['./single-sync-status.component.scss']
})
export class SingleSyncStatusComponent implements OnInit {
  @Input() venueData: VenueData;
  syncData: VenueSyncData;
  syncClicked: boolean = false;


  constructor(private dialog: MatDialog,
    private translate: TranslateService,
    private successService: DisplaySuccessService,
    private venuesService: VenuesService,
    private dateConversionService: TimeManagerService,
  ) {}

  ngOnInit() {
    if (!this.venueData) {
      this.venuesService.getVenueMetaData()
        .subscribe(venueData => {
          this.venueData = venueData;
          this.getVenueLastSyncData();
        });
    } else {
      this.getVenueLastSyncData();
    }
  }

  getVenueLastSyncData() {
    this.venuesService.getVenueSyncStatus().pipe(take(1)).subscribe(syncStatus => {
      if (syncStatus) {
        this.syncData = syncStatus;
      }
    });
  }
  /**
   * Sync the current venue, then open success toast and call getVenueLastSyncData after 200 ms, in order to refresh the
   * current sync status
   */
  syncVenue() {
    this.confirmSync().subscribe(confirmed => {
      if (confirmed){
        let message = this.translate.instant('data.VENUES.SYNC_STARTED') + " " + this.venueData.venue.name;
        this.venuesService.syncVenueById(this.venueData.venue.id).subscribe(() => {
          setTimeout(() => {
            this.getVenueLastSyncData()
          }, 200);
          this.successService.openMessage(message)
        });
      }
    });
  }

  confirmSync() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "no-dialog-overflow"
    dialogConfig.data = {
      caption: this.translate.instant('data.DEVICE_TOPOLOGY.SYNC_VENUE'),
      action: `${this.translate.instant('data.VENUES.SYNC_VENUE_CONFIRM')}`
    };

    return this.dialog.open(ConfirmationModalComponent, dialogConfig).afterClosed();
  }

  get venueSyncData(): VenueSyncData {
    return this.syncData;
  }
  get isSyncFinished(): boolean {
    if (this.syncData.syncStatus.toLowerCase() == EntitySyncStatus.SUCCESS.toLowerCase() ||
      this.syncData.syncStatus.toLowerCase() == EntitySyncStatus.FAILED.toLowerCase() ||
      this.syncData.syncStatus.toString() == "NO_DATA_AVAILABLE")
      return true;
    return false;
  }

  get formattedSyncStatus() {
    return EntitySyncStatus[this.syncData.syncStatus];
  }

  get formattedSyncDate() {
    return this.dateConversionService.dateByFormat(parseInt(this.syncData.updatedAt), 'DD-MMM-YYYY HH:mm');
  }

}
