import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditStringsService {

  constructor() { }

  convertKeytoCamelCaseWord(key: string) {
    if (key) {
      let newKey: string;
      newKey = key.replace(key[0], key[0].toUpperCase());
      for (let i = 1; i < newKey.length; i++) {
        if (newKey[i] == newKey[i].toUpperCase()) {
          newKey = newKey.substring(0, i) + " " + newKey.substring(i, newKey.length)
          i++;
        }
      }
      if (newKey)
        return newKey;
    }
  }
  convertUppercasetoCamelCaseWord(word: string) {
    let camelWord = word[0];
    if (word) {
      for (let i = 1; i < word.length; i++) {
        if (word[i] == word[i].toUpperCase())
          camelWord += word[i].replace(word[i], word[i].toLowerCase());
        else camelWord += word[i]
      }
      return camelWord;
    }
  }
/**
 * @method getformattedPropKey Remove all white spaces, and chagne the first char to lowercase
 * @param key Current key
 */
  getformattedPropKey(key: String) {
    if (key) {
      key = key.replace(key[0], key[0].toLowerCase());
      return key.replace(/ +/g, "");
    }
  }
}
