<ng-container *ngIf="showTeleport">
    <div *teleportTo="'.teleported-panel'">
        <mat-toolbar-row class="prop-toolbar-row policy-title">
            <div class="col prop-key-col">
                {{selectedFabricName}}
            </div>
            <div class="col prop-value-col"> {{destinationsTitle}}</div>
        </mat-toolbar-row>
        <mat-toolbar class="fabric-prop-toolbar" [classByCondition]="endPoints.length <= 3"
            [cssClasses]="['fabric-prop-toolbar-min-height']">
            <ng-container *ngFor="let point of endPoints; let i = index; first as isFirst; last as isLast">
                <mat-toolbar-row class="prop-toolbar-row policy-toolbar-row">
                    <div class="row prop-content-row" [classByCondition]="point.markerIndex % 2"
                        [cssClasses]="['policy-row-even']">
                        <ng-container>
                            <div class="col policy-prop-key-col">
                                <span class="prop-content-key" [title]="point">
                                    {{point.action}}
                                </span>
                            </div>
                            <div class="col col-flex-2" [ngClass]="'policy-arrow-' + point?.action?.toLowerCase()"
                                [ngbTooltip]="point?.comment ? tooltipArrowContent: null"
                                [placement]="!isLast || endPoints.length <= 3? 'bottom-left' : 'top-left'"
                                [closeDelay]="200" [openDelay]="100" [tooltipClass]="'policy-arrow-tooltip-container'">
                                {{point?.arrow}}
                            </div>
                            <div class="col policy-prop-value-col">
                                <mat-icon class="venue-fabric-prop-icon" [svgIcon]="point.iconName"></mat-icon>
                                <span class="policy-prop-value-field" [ngClass]="{'fabric-prop-value': point.data}"
                                    [placement]="!isLast || endPoints.length <= 3? 'bottom' : 'top'"
                                    [ngbTooltip]="point?.data ? tooltipContent: null"
                                    [tooltipClass]="'policy-value-tooltip-container'" triggers="click:blur"
                                    [title]="point?.value">
                                    {{point?.value}}
                                </span>
                            </div>
                            <ng-template #tooltipContent>
                                <div class="policy-tooltip-rows-container">
                                    <div class="col pl-0 pr-0" *ngFor="let data of point?.data">
                                        <div class="row">
                                            <mat-icon class="venue-fabric-prop-icon" [svgIcon]="data.iconName">
                                            </mat-icon>
                                            <span><strong>{{data?.key}}</strong>:</span>
                                            <div class="ml-4 policy-tooltip-value"> {{data.value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #tooltipArrowContent>
                                <div class="policy-tooltip-rows-container">
                                    <span>{{point.comment}}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </mat-toolbar-row>
            </ng-container>
        </mat-toolbar>
    </div>
</ng-container>