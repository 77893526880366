import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type ListViewItem<ID> = { id: ID; label: string; icon?: string; };

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent<ID> implements OnInit {
  @Input() items: ListViewItem<ID>[];
  @Output() itemSelect = new EventEmitter<ID>();
  selectedItem?: ListViewItem<ID>;

  constructor() {
  }

  ngOnInit(): void {
    if (this.items?.length > 0) {
      this.selectedItem = this.items[0];
      this.itemSelect.emit(this.selectedItem.id);
    }
  }

  onItemSelected(item: ListViewItem<ID>) {
    if (item.id != this.selectedItem?.id) {
      this.selectedItem = item;
      this.itemSelect.emit(item.id);
    }
  }

  isSelected(id: ID) {
    return id == this.selectedItem?.id;
  }
}
