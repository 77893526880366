<!--buttons-->
<button *ngIf="btnVariant == 'text' || btnVariant == 'cancel' || btnVariant == 'link'" [type]="btnType"
  (click)="handleClick($event)" mat-button [color]="btnStyle" [disabled]="btnDisabled" [ngClass]="btnClass">
  <ng-content></ng-content>
  {{btnText | translate}}
</button>
<button *ngIf="btnVariant == 'raised'" [type]="btnType" (click)="handleClick($event)" mat-raised-button
  [color]="userSelectionButtonStyle? userSelectionButtonStyle: btnStyle" [disabled]="btnDisabled" [ngClass]="btnClass">
  <ng-content></ng-content>
  {{btnText | translate}}
</button>
<button *ngIf="btnVariant == 'flat'" [type]="btnType" (click)="handleClick($event)" mat-flat-button [color]="btnStyle"
  [disabled]="btnDisabled" [ngClass]="btnClass">
  <ng-content></ng-content>
  {{btnText | translate}}
</button>
<button *ngIf="btnVariant == 'stroked'" [type]="btnType" (click)="handleClick($event)" mat-stroked-button
  [color]="userSelectionButtonStyle? userSelectionButtonStyle: btnStyle" [disabled]="btnDisabled" [ngClass]="btnClass">
  <ng-content></ng-content>
  {{btnText | translate}}
</button>