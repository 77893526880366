import { Injectable } from '@angular/core';
import { EntityAction } from 'src/app/shared/models/actions.model';
import { HorizontalTimelineMarker } from '../../../../../horizontal-timeline/models/horizontal-time-span.model';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';
import { HealthColorsService } from 'src/app/shared/services/strategies/health-colors.service';


@Injectable({
  providedIn: 'root'
})
export class BaseAnomalySpanService {

  constructor(
    protected healthColorsService: HealthColorsService,
    protected dateConversion: TimeManagerService) { }

  /**
* Get Marker data for HorizontalTimeline display
*/
  getMarkerData(action: EntityAction): HorizontalTimelineMarker {
    return {
      date: this.dateConversion.convertUnixToDateObject(+action.updatedAt),
      markerTooltipData: action.suggestedOperation ?
        `${action.suggestedOperation}` :
        null,
      cssClass: `arrow-${action.severity.toString().toLowerCase()}`
    };
  }
}
