import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit {
  @Input() hintText: string;
  @Input() hintHeader: string;
  @Input() hintHeaderClass: string;
  @Input() hintBubbleContainerClass: string;
  @Input() hintTextClass: string;
  @Input() arrowClass: string;
  @Input() svgIcon: string = "hint-robot";
  constructor() { }

  ngOnInit() {
  }

}
