import {Component, OnInit, Input} from '@angular/core';
import {DashboardType} from 'src/app/shared/components/dynamic-dashboard/models/dashboards.model';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

export type NavWithBoolean = { data: { path: string[], label: string, currentDashboard?: DashboardType }, isDisplayed: boolean, blockRemoval: boolean };

@Component({
  selector: 'app-nav-for-dynamic',
  templateUrl: './nav-for-dynamic.component.html',
  styleUrls: ['./nav-for-dynamic.component.scss']
})
export class NavForDynamicComponent implements OnInit {
  @Input() set navLinks(navLinks: any[]) {
    if (navLinks && navLinks.length > 0) {
      this._formattedNavLinks = navLinks.map(link => {
        return {data: link, isDisplayed: link.isDisplayed == undefined ? true : link.isDisplayed, blockRemoval: link.label.toLowerCase() === "health", currentDashboard: link.currentDashboard} as NavWithBoolean
      });
      this.currentDashboard = this.formattedNavLinks[0].data.currentDashboard;
    }
  }

  @Input() cssClass: string;

  private _formattedNavLinks: NavWithBoolean[]
  currentDashboard: DashboardType;
  selectedTab = 'Health';
  buttonText: string;
  isDynamicDashboard: boolean = false;
  subscription: Subscription[] = [];

  constructor(
    private translate: TranslateService) {
    this.buttonText = this.translate.instant('data.DYNAMIC_DASHBAORD.ADD_TAB');
  }

  ngOnInit() {
    this.isDynamicDashboard = this.formattedNavLinks && this.formattedNavLinks.length > 0 && this.formattedNavLinks[0].data?.currentDashboard != undefined;
  }

  onTabSelected(tab: { path: any[], label: string, currentDashboard?: any }) {
    this.selectedTab = tab.label;
    this.isDynamicDashboard = tab.currentDashboard != undefined;
  }

  onLinkClick(link: NavWithBoolean) {
    link.isDisplayed = !link.isDisplayed;
  }

  get formattedNavLinks(): NavWithBoolean[] {
    return this._formattedNavLinks;
  }

  get hiddenLinks(): NavWithBoolean[] {
    if (this.formattedNavLinks)
      return this.formattedNavLinks.filter(link => !link.isDisplayed)
  }

  get isHiddenLinks(): boolean {
    if (this.hiddenLinks)
      return this.hiddenLinks.length > 0;
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}
