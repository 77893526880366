import {Injectable} from '@angular/core';
import {EntityType} from '../../../models/entity-type.enum';
import {HeaderData, PropertiesElementType} from '../models/properties-content';
import {take} from 'rxjs/operators';
import {DashboardService} from '../../../services/rest-services/dashboard.service';
import {GridSortFilter} from '../../../models/sort-filter/grid-sort-filter.model';
import {ActionStatus} from '../../../models/actions.model';
import {TimeManagerService} from "../../../services/time-manager.service";
import {SingleDeviceType} from "../../../models/single-device.model";

@Injectable({
  providedIn: 'root'
})
export class PropActionsHealthDataService {

  constructor(private dashboardService: DashboardService, private timeManager: TimeManagerService) { }

  getActionsData(entityActionsList: string[], headerData: HeaderData) {
    entityActionsList = [];
    let entity
    if (headerData.elementType == PropertiesElementType.NODE)
      entity = EntityType.DEVICE;
    else
      entity = headerData.elementType;

    if (entity !== PropertiesElementType.LINK && entity !== PropertiesElementType.CloudNode && headerData?.type != SingleDeviceType.WWW && headerData?.type != SingleDeviceType.SwitchStack) {
      const dates = this.timeManager.getLast24HoursIsoFormat();
      const sortAndFilter = new GridSortFilter(null, undefined, undefined, undefined, dates);
      this.dashboardService.fetchEntityActions(entity, sortAndFilter, headerData.id).pipe(take(1)).subscribe(actions => {
        if (actions) {
          actions.data.forEach(action => {
            if (action.status == ActionStatus.Open)
              entityActionsList.push(action.description);
          })
          headerData.issues = entityActionsList.length;
          headerData.actionsList = entityActionsList;
        }
      })
    }
  }

}
