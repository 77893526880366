import { ICellRendererParams } from 'ag-grid-community';
import { LegacySeverity } from '../../models/severity.model';

export enum IssueActionType {
    COMPLETE,
    ACKNOWLEDGE
}

export class IssueAction {
    constructor(public type: IssueActionType,
        public severity: LegacySeverity,
        public actionData: IssueActionData) { }
}
export class IssueActionData {
    constructor(public category: number,
        public categoryName: string,
        public description: string) { }
}
