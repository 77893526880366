import { Injectable } from "@angular/core";
import { OrganizationDetails } from "src/app/shared/models/organizations.model";
import { MarkerLocation } from "src/app/shared/models/map.model";
import { TranslateService } from "@ngx-translate/core";
import { VenuesService } from "src/app/shared/services/rest-services/venues.service";
import { forkJoin } from "rxjs";
import { TenantSummaryDTO } from "src/app/shared/models/tenants.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HierarchyMapService {
  constructor(private venuesService: VenuesService) { }

  getTenantsVenuesWithAddresses(tenants: TenantSummaryDTO[]) {
    const requests = tenants.map((tenant) => {
      return this.venuesService.fetchVenuesByTenantId(tenant.id, true);
    });

    return forkJoin(requests).pipe(
      map(venues => flatten(venues).filter((v) => v.lat != 0 && v.long != 0)),
      map(venues => venues.map(venue => {
        return {
          lat: venue.lat,
          lng: venue.long,
          markerEntityId: venue.id,
          tenantId: venue.tenantId,
          name: venue.name,
          health: venue.health
        }
      })));

    function flatten(arr) {
      return arr.reduce(function (flat, toFlatten) {
        return flat.concat(
          Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
        );
      }, []);
    }
  }

  getLocationBySettings(
    organizations: OrganizationDetails[],
    selectedSettings: string[]
  ): MarkerLocation[] {
    let markerLocations: MarkerLocation[] = [];
    if (organizations && organizations.length > 0) {
      organizations.forEach((org) => {
        if (
          org.settings &&
          org.address &&
          org.settings.name &&
          selectedSettings.includes(org.settings.name) &&
          org.address.lat &&
          org.address.lon
        )
          markerLocations.push({
            lat: org.address.lat,
            lng: org.address.lon,
            name: `${org.name}  - ${org.settings.name}`,
            markerEntityId: org.id,
          });
      });
    }
    return markerLocations;
  }

  getMenuButtonText(
    organizationsSettingsList: string[],
    selectedSettings: string[],
    translate: TranslateService
  ) {
    let buttonText = { selectedType: "" };
    if (selectedSettings && selectedSettings.length > 0) {
      if (selectedSettings.length == 1)
        buttonText.selectedType = selectedSettings[0];
      if (selectedSettings.length > 1)
        buttonText.selectedType =
          selectedSettings.length + " organizations types";
      if (selectedSettings.length == organizationsSettingsList.length)
        buttonText.selectedType = "all types";
      return translate.instant("data.ORGANIZATIONS.SHOW_ORG_TYPE", buttonText);
    }
    if (!organizationsSettingsList || organizationsSettingsList.length == 0)
      return translate.instant("data.ORGANIZATIONS.NO_ORGS");
    if (
      (organizationsSettingsList &&
        organizationsSettingsList.length > 1 &&
        !selectedSettings) ||
      (selectedSettings && selectedSettings.length == 0)
    ) {
      return translate.instant("data.ORGANIZATIONS.SELECT_SETTINGS");
    }
  }

  generateOrgList(childrenArray: OrganizationDetails[]): OrganizationDetails[] {
    if (childrenArray && childrenArray.length > 0) {
      return childrenArray.filter((child) => {
        if (child.hasOwnProperty("address")) return child;
      });
    }
    return [];
  }
}
