import { createAction, props } from '@ngrx/store';
import { Hierarchy } from 'src/app/shared/models/hierarchy.model';

export const loadHierarchy = createAction(
  '[Hierarchy] Load Hierarchy',
  props<{ hierarchy: Hierarchy }>()
);




