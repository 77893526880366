export enum TimeUnit {
  NANOS = "NANOS",
  MICROS = "MICROS",
  MILLIS = "MILLIS",
  SECONDS = "SECONDS",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  MONTHS = 'MONTHS'
}

export namespace TimeUnit {
  export function milliseconds(timeUnit: TimeUnit) {
    const milliseconds = 1000;
    switch (timeUnit) {
      case TimeUnit.MILLIS:
        return 1;
      case TimeUnit.SECONDS:
        return milliseconds;
      case TimeUnit.MINUTES:
        return 60 * milliseconds;
      case TimeUnit.HOURS:
        return 60 * 60 * milliseconds;
      case TimeUnit.DAYS:
        return 24 * 60 * 60 * milliseconds;
      case TimeUnit.MONTHS:
        return (365 / 12) * 24 * 60 * 60 * milliseconds;
    }
  }

  export function hours(interval: number, timeUnit: TimeUnit): number {
    return (milliseconds(timeUnit) * interval) / 1000 / 60 / 60;
  }

  export function minutes(interval: number, timeUnit: TimeUnit): number {
    return hours(interval, timeUnit) * 60;
  }

  export function equals(interval: number, timeUnit: TimeUnit, otherInterval: number, otherTimeUnit: TimeUnit): boolean {
    return TimeUnit.minutes(interval, timeUnit) == TimeUnit.minutes(otherInterval, otherTimeUnit);
  }
}

export type TimeUnitFormat = 'capital' | 'upper' | 'lower';

export enum TimePeriod {
  LAST_24_HOURS = "Last 24 Hours",
  LAST_WEEK = "Last Week",
  LAST_MONTH = "Last Month",
  CUSTOM_DATE = "Custom Date"
}

export enum UserTimeRangesSelection {
  FOUR_HOURS = '4H',
  DAY = '2 Days',
  MONTH = 'Month'
}

export interface TimeRangeWithValue {
  range: UserTimeRangesSelection;
  value: number;
  tooltipText?: string;
}

export namespace UserTimeRangesSelection {
  export function toArray() {
    return [
      UserTimeRangesSelection.FOUR_HOURS,
      UserTimeRangesSelection.DAY,
      UserTimeRangesSelection.MONTH,
    ];
  }
}

export enum TimeRange {
  Wide,
  Narrow,
  XNarrow
}

export type TimeRangeUnit = 'DAYS' | 'HOURS'

export namespace TimePeriod {
  export function getTimePeriodAsArray() {
    return [TimePeriod.LAST_WEEK, TimePeriod.LAST_MONTH, TimePeriod.CUSTOM_DATE]
  }
}

export type TimeApi = {
  timeBack: number;
  timeUnit: TimeUnit;
  specificDate: string;
}

export enum TimeApiFields {
  TIME_BACK = 'timeBack',
  TIME_UNIT = 'timeUnit',
  SPECIFIC_DATE = 'specificDate'
}

export class FromToDates {
  start: Date;
  end: Date;
}

export class FromToDatesStrings {
  start: string;
  end: string;
}

export class TimePeriodOption {
  label: string;
  interval: number;
  timeUnit: TimeUnit;
  hidden?: boolean;
}

export enum TimePeriodType {
  TimeBack,
  Granularity
}

export class TimePeriodPayload {
  timeBack: number;
  timeBackUnit: TimeUnit;
  granularity: number;
  granularityUnit: TimeUnit;
  option: TimePeriodOption;
  dates: FromToDates;
}

export class TimePeriodWithDates {
  dates: FromToDates;
  timePeriod: TimePeriod;
}

export class TimePeriodWithDatesStrings {
  dates: FromToDatesStrings;
  timePeriod: TimePeriod;
}

export namespace TimePeriods {
  export function getIndex(options: TimePeriodOption[], timeBack: number, timeUnit: TimeUnit) {
    return options?.findIndex(option => option.interval == timeBack && option.timeUnit == timeUnit) ?? 0;
  }
}

export type DateTimeFormatType = 'full' | 'full2' | 'year' | 'month' | 'day' | 'hour' | 'minute';
export namespace DateTimeFormatType {
  export function getFormat(unit: TimeUnit): DateTimeFormatType {
    switch (unit) {
      case TimeUnit.DAYS:
        return 'day';
      case TimeUnit.HOURS:
        return 'hour';
      case TimeUnit.MINUTES:
        return 'minute';
      default:
        return 'full2';
    }
  }
}

export const DATE_TIME_FORMATS: {[key in DateTimeFormatType]: string} = {
  'full': 'MMM/DD/YYYY - HH:mmA',
  'full2': 'MMM/DD/YYYY - HH:mm',
  'year': 'YYYY',
  'month': 'MMM-YYYY',
  'day': 'DD-MMM',
  'hour': 'DD-MMM HH:mm',
  'minute': 'HH:mm'
};
