import { Component } from '@angular/core';
import { TopTrafficOrigin } from '../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-top-clients',
  templateUrl: './top-clients.component.html',
  styleUrls: ['./top-clients.component.scss']
})
export class TopClientsComponent {
  origin: TopTrafficOrigin = TopTrafficOrigin.CLIENTS;
}
