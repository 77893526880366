import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-netop-dialog-title',
  templateUrl: './netop-dialog-title.component.html',
  styleUrls: ['./netop-dialog-title.component.scss']
})
export class NetopDialogTitleComponent implements OnInit {
  @Input() closeIcon: boolean = true
  @Input() cssClass: string;
  @Input() closeIconClass: string;
  @Output() close = new EventEmitter()
  @Output() titleClicked = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }
  closeDialog() {
    this.close.emit()
  }
  onTitleClicked() {
    this.titleClicked.emit(true);
  }
}
