import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { InfoBarColumnsData } from './models/info-bar.model';
@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBarComponent {
  @Input() infoBarColumnsData: InfoBarColumnsData;
  constructor() { }
}
