export enum Period {
    DAY = "Day",
    WEEK = "Week",
    MONTH = "Month",
    SIX_MONTHS = "Six_Months",
    YEAR = "Year"
}

export namespace Period {
    export function getPeriods() {
        return {
            [Period.DAY]: "data.COMMON.DAY",
            [Period.WEEK]: "data.COMMON.WEEK",
            [Period.MONTH]: "data.COMMON.MONTH",
            [Period.SIX_MONTHS]: "data.COMMON.SIX_MONTHS",
            [Period.YEAR]: "data.COMMON.YEAR",
        }
    }
    export function getPeriodsUntilMonth() {
        return {
            [Period.DAY]: "data.COMMON.DAY",
            [Period.WEEK]: "data.COMMON.WEEK",
            [Period.MONTH]: "data.COMMON.MONTH"
        }
    }
    export function getPeriodName(period: Period) {
        let periods = getPeriods()
        return periods[period]
    }
}
