export type AllDirections = ("up" | "down" | "left" | "right");

export function getArrowByValue(value: number) {
    if (value < 0)
        return String.fromCharCode(8595);
    if (value > 0)
        return String.fromCharCode(8593);
}

export function drawArrow(direction: AllDirections) {
    switch (direction) {
        case "up":
            return String.fromCharCode(8593);
        case "down":
            return String.fromCharCode(8595);
        case "left":
            return String.fromCharCode(8592);
        case "right":
            return String.fromCharCode(8594);
        default:
            break;
    }
}

