import {Component, OnInit, Input, ChangeDetectionStrategy, Inject} from '@angular/core';
import {EndPointDisplay} from 'src/app/shared/models/policies.model';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReportsDialogData} from "../../../shared/pages/reports/models/reports-filter.model";
import {PolicyDisplay} from "../../../shared/models/open-ports.model";

@Component({
  selector: 'app-policy-display',
  templateUrl: './policy-display.component.html',
  styleUrls: ['./policy-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyDisplayComponent implements OnInit {

  @Input() endPoints: EndPointDisplay[];
  @Input() showTeleport: boolean = false;
  @Input() selectedFabricName: string;
  @Input() destinationsTitle: string;

  constructor() {
  }

  ngOnInit() {
  }

}
