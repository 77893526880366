import {FromToDates, TimePeriodOption, TimeUnit} from "../../models/time.model";

export class TimeRangePayload {
  interval: number;
  timeUnit: TimeUnit;
  range: FromToDates;
  date: Date;
  hour: number;
  minute: number;
}

export class DateTimePayload {
  date: Date;
  hour: number;
  minute: number;
}

export class TimePayload {
  hour: number;
  minute: number;
}

export class TimePeriodBounds {
  min?: number;
  max?: number;
  step?: number;
}

export class TimePeriodInputPayload {
  interval: number;
  timeUnit: TimeUnit;
}

export type TimeUnitBounds = {[timeUnit in TimeUnit]?: TimePeriodBounds};
export type TimeClock = 'AM' | 'PM';

export const LAST_FOUR_HOURS_OPTION: TimePeriodOption = {label: 'Last 4 hours', interval: 4, timeUnit: TimeUnit.HOURS};
export const LAST_24_HOURS_OPTION: TimePeriodOption = {label: 'Last 24 hours', interval: 24, timeUnit: TimeUnit.HOURS};
export const LAST_WEEK_OPTION: TimePeriodOption = {label: 'Last week', interval: 7, timeUnit: TimeUnit.DAYS};
export const DEFAULT_TIME_PERIOD_OPTIONS: TimePeriodOption[] = [LAST_FOUR_HOURS_OPTION, LAST_24_HOURS_OPTION, LAST_WEEK_OPTION];

export const TIME_UNIT_BOUNDS: TimeUnitBounds = {
  [TimeUnit.MINUTES]: {
    min: 1,
    max: 60
  },
  [TimeUnit.HOURS]: {
    min: 1,
    max: 24
  },
  [TimeUnit.DAYS]: {
    min: 1
  },
  [TimeUnit.MONTHS]: {
    min: 1
  }
}

export const DEFAULT_TIME_BACK_BOUNDS: TimeUnitBounds = {
  [TimeUnit.HOURS]: {
    min: 1,
    max: 23
  },
  [TimeUnit.DAYS]: {
    min: 1,
    max: 30
  },
  [TimeUnit.MONTHS]: {
    min: 1,
    max: 3
  }
};
