import {Injectable} from '@angular/core';
import {Auth0Service} from '../../auth0.service';
import {AngularFireDatabase, AngularFireAction, DatabaseSnapshot, AngularFireObject} from '@angular/fire/compat/database';
import {ClientStorageEntities} from '../../../models/client-storage.model';
import {LoggerService} from '../../logger.service';
import {FirebaseAuthService} from './firebase-auth.service';
import {encodedEmailAddress} from '../../../operators/encoded-mail';
import {take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {BaseUserClientStorage} from "../base-user-client-storage";
import {LoadClientStorageService} from "../load-client-storage.service";

@Injectable({
  providedIn: 'root'
})
export class FirebaseUserPreferencesService extends BaseUserClientStorage {
  private currentUser: AngularFireObject<any>;

  constructor(
    protected authService: Auth0Service,
    protected loadClientStorageService: LoadClientStorageService,
    private db: AngularFireDatabase,
    protected loggerFactory: LoggerService,
    protected firebaseAuthService: FirebaseAuthService) {
    super(loggerFactory, loadClientStorageService, 'FirebaseUserPreferencesService')
  }

  loadUserDetails() {
    this.firebaseAuthService.notifyUserDetailsObservable$.subscribe(email => {
      if (email) {
        this.clientSelection = {
          userEmail: encodedEmailAddress(email),
          preferences: {}
        };
        this.currentUser = this.db.object(`preferences/${ClientStorageEntities.USER_PREFERENCES}/${environment["environmentType"]}/${this.clientSelection.userEmail}`);
        this.logger.debug("currentUser", this.currentUser);
        this.isCurrentUserExist();
      }
    })
  }

  /**
   * Check if the current snapshot has value, if yes, the current user is already exist
   */
  private isCurrentUserExist() {
    this.currentUser.snapshotChanges().pipe(take(1)).subscribe(snapshot => {
      if (snapshot && snapshot.payload.val())
        this.getUserFirebasePrefs(snapshot);
      else
        this.addFirebaseUserPrefs();
    })
  }

  /**
   * Get the user current preferences, if there is one
   */
  private getUserFirebasePrefs(snapshot: AngularFireAction<DatabaseSnapshot<any>>) {
    this.logger.debug("Current user details: %o", snapshot.key, snapshot.payload.val())
    this.clientSelection.preferences = snapshot.payload.val().preferences;
    this.loadClientStorageService.storeUserPreferencesLoaded(this.clientSelection);
  }

  /**
   * Add new user to the fire base users preferences list
   */
  private addFirebaseUserPrefs() {
    this.loadClientStorageService.storeUserPreferencesLoaded(this.clientSelection);
    this.currentUser.set({
      preferences: this.clientSelection.preferences
    })
  }

  /**
   * Update the current user preferences
   */
  updateUserPreferencesSelection() {
    this.currentUser.update({
      preferences: this.clientSelection.preferences
    })
  }
}

