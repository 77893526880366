<div class="row info-bar-row">
    <div class="col no-side-padding" *ngIf="infoBarColumnsData && infoBarColumnsData[0]?.isDisplayColumn">
        <div class="row content-in-center">
            <div class="col col-no-flex">
                <ng-content select="[firstInfoBarIcon]"></ng-content>
            </div>
            <div class="col" *ngIf="infoBarColumnsData && !infoBarColumnsData[0]?.isOnlyIcon">
                <ng-content select="[firstColMain]"></ng-content>
                <ng-content select="[firstColSub]"></ng-content>
            </div>
        </div>
    </div>

    <div class="col no-side-padding" [ngClass]="{'info-narrow-col': infoBarColumnsData[1]?.isOnlyIcon}"
        *ngIf="infoBarColumnsData && infoBarColumnsData[1]?.isDisplayColumn">
        <div class="row content-in-center">
            <div class="col col-no-flex">
                <ng-content select="[secondInfoBarIcon]"></ng-content>
            </div>
            <div class="col" *ngIf="infoBarColumnsData && !infoBarColumnsData[1]?.isOnlyIcon">
                <ng-content select="[secondColMain]"></ng-content>
                <ng-content select="[secondColSub]"></ng-content>
            </div>
        </div>
    </div>

    <div class="col no-side-padding" *ngIf="infoBarColumnsData && infoBarColumnsData[2]?.isDisplayColumn">
        <div class="row content-in-center">
            <div class="col col-no-flex">
                <ng-content select="[thirdInfoBarIcon]"></ng-content>
            </div>
            <div class="col" *ngIf="infoBarColumnsData && !infoBarColumnsData[2]?.isOnlyIcon">
                <ng-content select="[thirdColMain]"></ng-content>
                <ng-content select="[thirdColSub]"></ng-content>
            </div>
        </div>
    </div>
</div>