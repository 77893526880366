<div class="chat-robot-summary-text">
  <ng-content></ng-content>
</div>
<div class="setting-forms-buttons">
  <div class="save-as-table-container">
    <div class="chat-robot-summary-row mt-2">
      <app-buttons class="chat-robot-summary-btn-cancel" (click)="onCancelClicked()" [btnVariant]="'stroked'" [btnStyle]="'primary'"
                   [btnClass]="" [btnText]="'data.COMMON.CANCEL'"></app-buttons>
      <app-buttons class="chat-robot-summary-btn-copy" (click)="onCreateClicked($event)" [btnDisabled]="!showCreate" [btnStyle]="'primary'"
                   [btnClass]="" [btnText]="entityId ? 'data.COMMON.UPDATE' : 'data.COMMON.CREATE'"></app-buttons>
    </div>
  </div>
</div>
