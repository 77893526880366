import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Rect } from './rect.class';
/**
 * Provided by a portal for it's content to be listened in case of portal being 
 * resized e.g. if you want to resize your chart in a portal body when portal 
 * is maxmimazed/de maximaised.
 */
@Injectable()
export class PortletResizeService {
  private resizeSubject: Subject<Rect> = new Subject()
  resize$: Observable<Rect>;

  constructor() {
    this.resize$ = this.resizeSubject.asObservable()
  }

  portletResized(rect: Rect) {
    this.resizeSubject.next(rect);
  }
}
