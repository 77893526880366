<ng-container *ngIf="items && items.length > 0">

  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="{{ariaLabel}}" (click)="$event.stopPropagation();">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button *ngFor="let item of items" mat-menu-item [disabled]="item.enabled == false" (click)="onClick(item.item)">
      <span>{{item.label | translate}}</span>
    </button>
  </mat-menu>
</ng-container>

<ng-container *ngIf="items && items.length == 0">
  <button mat-icon-button aria-label="{{ariaLabel}}" (click)="$event.stopPropagation();">
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-container>
