import {Component, ChangeDetectorRef} from '@angular/core';
import {TrafficService} from 'src/app/shared/services/rest-services/traffic.service';
import {ReportsStoreService} from 'src/app/shared/pages/reports/services/reports-store.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {DonutConfigService} from 'src/app/shared/components/chartjs-components/services/donut-config.service';
import {TopTrafficService} from '../services/top-traffic.service';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {BaseTrafficDonutStatistic} from '../models/base-traffic-donut-statistics';
import {TranslateService} from '@ngx-translate/core';
import {catchError, take} from 'rxjs/operators';
import {TopTrafficOrigin} from '../../../../../models/legacy-reports.model';
import {of} from 'rxjs';

@Component({
  selector: 'app-top-traffic-pie',
  templateUrl: './top-traffic-pie.component.html',
  styleUrls: ['./top-traffic-pie.component.scss']
})
export class TopTrafficPieComponent extends BaseTrafficDonutStatistic {

  constructor(
    protected reportsStoreService: ReportsStoreService,
    protected dateConvertor: TimeManagerService,
    protected trafficService: TrafficService,
    protected topTrafficService: TopTrafficService,
    protected donutConfigService: DonutConfigService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef
  ) {
    super(reportsStoreService, dateConvertor, trafficService, topTrafficService, donutConfigService, translate, cdr)
  }

  loadData() {
    if (this.datesAsString && this.currentEntity && (this.currentEntity.type == EntityType.VENUE || this.currentEntity.type == EntityType.TENANT)) {
      this.isLoading = true;
      this.trafficService.getTopTrafficData(this.currentEntity, this.datesAsString.start, this.datesAsString.end, this.origin as TopTrafficOrigin)
        .pipe(
          take(1),
          catchError(error => {
            this.isLoading = false;
            return of(null);
          })
        ).subscribe(topEntities => {
        this.isLoading = false;
        this.topTrafficData = this.topTrafficService.findTopByNumber(topEntities, this.topNumber);
        this.donutConfig = this.donutConfigService.getTopEntityConfig(this.topTrafficData, topEntities);
        this.donutTitle = this.topTrafficService.getDonutTitle(this.origin as TopTrafficOrigin, this.translate, this.topNumber);
        this.byText = this.topTrafficService.getByText(this.origin as TopTrafficOrigin, this.translate);
        this.total = this.topTrafficService.getTotal(this.isTopTrafficData, this.translate, topEntities)
        this.noDataMessage = this.topTrafficService.getNoDataMessage(this.origin as TopTrafficOrigin, this.translate)
      })
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroyProcedure();
  }
}
