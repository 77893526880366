import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  dialogData: any;
  questionText: string;
  captionText: string;
  btnClass: string;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dialogData = data;
    this.questionText = this.translate.instant('data.COMMON.CONFIRMATION_MODAL_QUESTION');
    this.captionText = this.translate.instant('data.COMMON.CAPTION') + " " + this.dialogData.caption;
  }

  closeDialog(toClose: boolean) {
    this.dialogRef.close(toClose);
  }
}
