<div class="row multi-clients-row-icon-container">
    <div class="col multi-clients-col-icon-container" *ngFor="let icon of iconsData">
        <div class="multi-client-rssi-header">
            <i [inlineSVG]="icon.iconPath"></i>
            <!--The "real" clients number-->
            <ng-container *ngIf="icon.numberOfClients>=1">
                <div class="multi-clients-number" [rssiIconStyler]="icon">
                    {{icon.numberOfClients}}
                </div>
            </ng-container>
            <!--"Fake" div to keep all icons on the same position-->
            <ng-container *ngIf="icon.numberOfClients < 1">
                <div [style.width.px]="26"></div>
            </ng-container>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <i [inlineSVG]="'assets/media/netop/topology/multi-clients-rssi-bar.svg'"></i>
    </div>
</div>

<div class="row multi-client-legend-row">
    <div class="col multi-client-legend-col" *ngFor="let legend of rssiLegend; index as i">
        <span class="multi-client-legend-text">{{legend}}</span>
        <span *ngIf="i == rssiLegend.length-1" class="multi-client-legend-text-infinity">{{rssiLegendInfinity}}</span>
    </div>
</div>
