import { Directive, HostListener } from '@angular/core';
import { D3Service } from '../services/d3.service';

@Directive({
  selector: '[appZoomInButton]'
})
export class ZoomInButtonDirective {

  constructor(private d3Service: D3Service) { }

  @HostListener('click') onZoomInClick() {
    this.d3Service.applyZoomButtonsEvent("in");
  }
}
