import {Injectable} from '@angular/core';
import {KpiService} from "../../../services/rest-services/kpi.service";
import {forkJoin, Observable, zip} from "rxjs";
import {KpiGroupedBy, KpiType} from "../../../models/kpi.model";
import {EntityType} from "../../../models/entity-type.enum";
import {TimeUnit} from "../../../models/time.model";

@Injectable({
  providedIn: 'root'
})
export class RestKpiGeneratorService {

  constructor(
    private kpiService: KpiService,
  ) {
  }

  getKpiTrendsByIds(entityType: EntityType, entityID: number, tenantID: number, fromDate: number = 7, specificDate: string = undefined, groupedBy: KpiGroupedBy, throughputEntityType?: EntityType, throughputEntityId?: number, timeUnit: TimeUnit = TimeUnit.DAYS): Observable<any[]> {
    return forkJoin([
      this.kpiService.getKpiTrendByIds(KpiType.Latency, entityType, entityID, tenantID, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiTrendByIds(KpiType.Loss, entityType, entityID, tenantID, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiTrendByIds(KpiType.Clients, entityType, entityID, tenantID, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiSplitTrendByIds(KpiType.Traffic, entityType, entityID, tenantID, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiSplitTrendByIds(KpiType.Throughput, throughputEntityType, throughputEntityId, tenantID, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiGroupedTrendByIds(KpiType.Traffic, entityType, entityID, tenantID, KpiGroupedBy.Application, fromDate, specificDate, timeUnit),
      this.kpiService.getKpiGroupedTrendByIds(KpiType.Traffic, entityType, entityID, tenantID, KpiGroupedBy.Wired, fromDate, specificDate, timeUnit)]);
    //this.kpiService.getKpiGroupedTrendByIds(KpiType.Traffic, entityType, entityID, tenantID, KpiGroupedBy.Port, fromDate, specificDate)]);
  }

  /**
   * Return the forkJoin of all of the kpi current value request
   */
  getKpiData() {
    return zip(this.kpiService.getKpiCurrentValue(KpiType.Health),
      this.kpiService.getKpiCurrentValue(KpiType.Latency),
      this.kpiService.getKpiCurrentValue(KpiType.Loss),
      this.kpiService.getKpiCurrentValue(KpiType.Traffic),
      this.kpiService.getKpiCurrentValue(KpiType.Clients)
    )
  }
}
