import {Component, OnDestroy, OnInit} from '@angular/core';
import {IGetRowsParams} from "ag-grid-community";
import {TimePeriodPayload, TimeUnit} from "../../shared/models/time.model";
import {Observable, Subscription} from "rxjs";
import {Page} from "../../shared/models/page.model";
import {TenantsHealth} from "../../shared/models/tenants.model";
import {OrganizationsService} from "../../shared/services/rest-services/organizations.service";
import {GridSortFilter} from "../../shared/models/sort-filter/grid-sort-filter.model";
import {ComposableGridRow} from "../../features/tables/core/composable-grid";
import {map} from "rxjs/operators";
import {clearSubscriptions} from "../../shared/operators/rxjs";

@Component({
  selector: 'app-organization-tenants',
  templateUrl: './organization-tenants.component.html',
  styleUrls: ['./organization-tenants.component.scss']
})
export class OrganizationTenantsComponent implements OnInit, OnDestroy {
  orgId: number;
  total: number;
  params: IGetRowsParams;
  rowCount: number;
  searchText: string;
  timeBack: number = 4;
  timeUnit: TimeUnit = TimeUnit.HOURS;
  refresh: boolean = false;
  private subscriptions: Subscription[] = [];


  fetchTenantsCb: (params: IGetRowsParams) => Observable<Page<ComposableGridRow<TenantsHealth>>> = params => {
    this.params = params;
    return this.fetchTenants().pipe(
      map(data => ({...data, data: data.data.map(tenant => ({data: tenant}))}))
    );
  }

  constructor(private organizationsService: OrganizationsService) {
  }

  ngOnInit(): void {
    const subsc = this.organizationsService.orgId$.subscribe(orgId => {
      this.orgId = orgId;
      this.refresh = true;
    });
    this.subscriptions.push(subsc);
  }

  onTimePeriodSelected(payload: TimePeriodPayload) {
    if (payload?.option) {
      this.timeBack = payload.option.interval;
      this.timeUnit = payload.option.timeUnit;
      this.refresh = true;
    }
  }

  ngOnDestroy() {
    clearSubscriptions(this.subscriptions);
  }

  onSearch(searchText: string) {
    this.searchText = searchText;
  }



  fetchTenants() {
    const params = new GridSortFilter(this.params, this.searchText, this.rowCount);
    return this.organizationsService.fetchTenantsHealthById(this.orgId, params, this.timeBack, this.timeUnit);
  }
}
