import { EntityType } from 'src/app/shared/models/entity-type.enum';

export enum CrudActionType {
    Create,
    Delete,
    Edit,
    View,
    ViewForm,
    EditUsers,
    AddIntegration
}

export class CrudAction {
    constructor(public action: CrudActionType, public entityType: EntityType, public entityId: number, public isRootNode: boolean = false) { }
}