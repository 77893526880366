import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NGX_SELECT_OPTIONS, NgxSelectComponent } from './ngx-select.component';
import { NgxSelectOptionDirective, NgxSelectOptionNotFoundDirective, NgxSelectOptionSelectedDirective } from './ngx-templates.directive';
import { INgxSelectOptions } from './ngx-select.interfaces';
/**
 *  Base code taken from here 
 *  https://github.com/optimistex/ngx-select-ex#readme
 * Couldn't use the lib as is becuase:
 * [1] The option where compared by reference instead by value (bug in origin)
 * [2] In multiple mode no option for toggling already selected option existed which 
 * was required for options with checkboxes inside.
 */

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NgxSelectComponent,
        NgxSelectOptionDirective, NgxSelectOptionSelectedDirective, NgxSelectOptionNotFoundDirective
    ],
    exports: [NgxSelectComponent,
        NgxSelectOptionDirective, NgxSelectOptionSelectedDirective, NgxSelectOptionNotFoundDirective
    ]
})
export class NgxSelectModule {
    static forRoot(options: INgxSelectOptions): ModuleWithProviders<NgxSelectModule> {
        return {
            ngModule: NgxSelectModule,
            providers: [{ provide: NGX_SELECT_OPTIONS, useValue: options }]
        };
    }
}
