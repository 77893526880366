import { BaseHealthSingleEntities } from "../../../../shared/components/single-entities/base-health-single-entities";
import { TranslateService } from "@ngx-translate/core";
import { SingleDevice } from 'src/app/shared/models/single-device.model';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { DevicesService } from 'src/app/shared/services/rest-services/devices.service';
import { BaseSingleEntityService } from 'src/app/shared/services/base-single-entity.service';

export class BaseSingleDeviceDashboard extends BaseHealthSingleEntities {
    deviceData: SingleDevice;
    singleEntityHealth: number;
    deviceHealth: number;

    constructor(
        protected translate: TranslateService,
        protected loggerFactory: LoggerService,
        protected devicesService: DevicesService,
        protected baseSingleEntityService: BaseSingleEntityService) {
        super(translate, loggerFactory, baseSingleEntityService)
    }
}