import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  sideNavToggled$: BehaviorSubject<boolean>;

  constructor() {

    this.sideNavToggled$ = new BehaviorSubject<boolean>(false);
  }

  stateChange(state: boolean) {
    setTimeout(() => {
      this.sideNavToggled$.next(state);
    }, 500);
  }

}
