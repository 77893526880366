import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {AnomalyCategory, AnomalySummaryByCategory} from 'src/app/shared/models/anomalies.model';
import {SelectionListItem} from 'src/app/shared/models/utils-classes/selection-classes.model';
import {Entity} from "../../../../../../models/entity.model";
import {Page} from "../../../../../../models/page.model";
import {AnomalyLeanGridDTO} from "../../anomalies-lean-grid/anomaly-lean-grid.model";


@Component({
  selector: 'app-anomalies-list',
  templateUrl: './anomalies-list.component.html',
  styleUrls: ['./anomalies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnomaliesListComponent {
  @Input() isLeanMode: boolean = false;
  @Input() currentEntity: Entity;
  @Input() selectedEntity: Entity;
  @Input() leanGridData: Page<AnomalyLeanGridDTO>;
  @Input() currentAnomalyCategory: AnomalyCategory;
  @Output() entitySelected: EventEmitter<Entity> = new EventEmitter<Entity>();

  @Input() set anomalies(anomalies: AnomalySummaryByCategory[]) {
    this._anomaliesSelection = [];
    if (anomalies && anomalies.length > 0) {
      anomalies.forEach((anomaly, index) => {
        this._anomaliesSelection.push(
          new SelectionListItem(index, anomaly, anomaly.anomalyCategory, null, {
            out: anomaly.active,
            total: anomaly.total
          })
        )
      });
      this.loadFirstComponent();
    }
  }

  @Output() selectedAnomaly: EventEmitter<AnomalySummaryByCategory> = new EventEmitter();

  _anomaliesSelection: SelectionListItem<AnomalySummaryByCategory>[] = [];

  constructor(private cdr: ChangeDetectorRef) {
  }


  loadFirstComponent() {
    /**
     * Add timeout to prevent change detection issues
     */
    setTimeout(() => {
      let selectedAnomaly = this.anomaliesSelection.find(anomaly => anomaly.data.anomalyCategory === this.currentAnomalyCategory) || this.anomaliesSelection[0];
      this.onItemSelected(selectedAnomaly);
      this.cdr.detectChanges();
    });
  }

  onItemSelected(anomaly: SelectionListItem<AnomalySummaryByCategory>) {
    this._anomaliesSelection.forEach(selectionAnomaly => {
      selectionAnomaly.id == anomaly.id ?
        selectionAnomaly.isSelected = true :
        selectionAnomaly.isSelected = false
    });
    if (anomaly) {
      this.selectedAnomaly.emit(anomaly.data);
    }
  }

  get anomaliesSelection(): SelectionListItem<AnomalySummaryByCategory>[] {
    return this._anomaliesSelection.sort();
  }

  onEntitySelected(entity: Entity) {
    this.entitySelected.emit(entity);
  }
}
