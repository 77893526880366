export function isArrayEmpty(array) {
  let isArrayEmpty = true;
  if (Array.isArray(array) && array[0])
    isArrayEmpty = false;
  return isArrayEmpty;
}

export function isObjectEmpty(obj) {
  let isObjEmpty = true;
  if (Object.keys(obj).length > 0)
    isObjEmpty = false;
  return isObjEmpty;
}

export function notObjectOrArray(property) {
  return typeof property !== 'object' && property !== null && !Array.isArray(property);
}

export function objectWithValue(property) {
  return typeof property === 'object' && property !== null && !Array.isArray(property);
}

export function isNotNullandUndefined(value: any) {
  return value !== null && value !== undefined;
}

export function removeNullPropertiesFromObject(obj: any) {
  if (obj) {
    let objWithoutNulls = {};
    for (let [key, value] of Object.entries(obj)) {
      if (notObjectOrArray(value) && value) {
        objWithoutNulls[key] = value
      } else if (value) {
        objWithoutNulls[key] = removeNullPropertiesFromObject(value);
      }
    }
    return objWithoutNulls;
  }
}

export function assertUnreachable(x: never): never {
  throw new Error(`${x} did not fall into any of the "cases" inside the switch statement`);
}

export function cleanObject(value: any): any {
  return JSON.parse(JSON.stringify(value));
}
