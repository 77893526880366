<ng-container *ngIf="inBarDisplay">
  <div class="graph-type-selector row grow space-between" [ngClass]="{
  'graph-type-selector-container':!selected,
  'graph-type-selector-container-selected':selected,
  'graph-type-selector-container-selectable':selectable
}" (click)="onSelectorClick($event)">
    <div class="graph-type-selector-icon column grow">
      <span [inlineSVG]="ICONS_PATH+selectorData.icon"></span>
      <!--media/netop/tenants-graph-selector.svg-->
    </div>
    <div class="graph-type-selector-data-container-row column grow"
      [ngClass]="selectorDataCss">
      <div class="space-between stretch pr-3">
        <div class="graph-type-selector-title column">
          <div class="graph-type-selector-title-text">{{selectorData.title}}</div>
          <!-- <div class="graph-type-selector-change">
        <span [ngClass]="{
          'good-percentage':selectorData.invertSignColor ? change < 0: change >= 0,
          'bad-percentage':selectorData.invertSignColor ? change > 0:change < 0}
        ">{{change > 0 ? "+":""}}{{change}}%</span>
      </div> -->
        </div>
        <div *ngIf="!selectorData.hideIll" class="graph-type-selector-counters justify-content-center column stretch">
          <div>
            <span
              class="graph-type-selector-data graph-type-selector-data-ill {{selectorData.ill_style}}">{{selectorData.ill}}</span>
            <span class="graph-type-selector-total">/</span>
            <span
              class="graph-type-selector-data graph-type-selector-data-total graph-type-selector-total">{{selectorData.total}}</span>
            <span class="graph-type-selector-total" *ngIf="selectorData.units">{{selectorData.units}}</span>
          </div>
        </div>
        <div *ngIf="selectorData.hideIll" class="graph-type-selector-counters-hide-ill column justify-content-center stretch">
          <div>
            <span class="graph-type-selector-total">{{total}}</span>
            <span class="graph-type-selector-total" *ngIf="selectorData.units">{{selectorData.units}}</span>
          </div>
        </div>
        <i *ngIf="selectorData.graphType == graphType.TRAFFIC" class="arrow-graph-type arrow arrow-down"></i>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!inBarDisplay">
  <div class="row grow space-between" [ngClass]="{
  'graph-type-selector-container':!selected,
  'graph-type-selector-container-selected':selected,
  'graph-type-selector-container-selectable':selectable,
  'tenant-operation-graph-selector': addMargin
}" (click)="onSelectorClick($event)">
    <div class="graph-type-selector-data-container aolumn grow align-items-center"
      [ngClass]="selectorDataCss">
      <div  class="graph-type-selector-title row space-between stretch">
        <div class="graph-type-selector-title-text">{{selectorData.title}}</div>
        <div class="graph-type-selector-change">
          <span [ngClass]="{
          'good-percentage':selectorData.invertSignColor ? change < 0: change >= 0,
          'bad-percentage':selectorData.invertSignColor ? change > 0:change < 0}
        ">{{change > 0 ? "+":""}}{{change}}%</span>
        </div>
      </div>
      <div *ngIf="!selectorData.hideIll" class="graph-type-selector-counters justify-content-center stretch">
        <span
          class="graph-type-selector-data graph-type-selector-data-ill {{selectorData.ill_style}}">{{selectorData.ill}}</span>
        <span class="graph-type-selector-total">/</span>
        <span
          class="graph-type-selector-data graph-type-selector-data-total graph-type-selector-total">{{selectorData.total}}</span>
        <span class="graph-type-selector-total" *ngIf="selectorData.units">{{selectorData.units}}</span>
      </div>
      <div *ngIf="selectorData.hideIll"
        class="graph-type-selector-counters align-items-center justify-content-center stretch">
        <span class="graph-type-selector-total">{{total}}</span>
        <span class="graph-type-selector-total" *ngIf="selectorData.units">{{selectorData.units}}</span>
      </div>

    </div>
    <div class="graph-type-selector-icon column grow justify-content-center align-items-center">
      <span [inlineSVG]="ICONS_PATH+selectorData.icon"></span>
      <!-- media/netop/tenants-graph-selector.svg -->
    </div>
  </div>
</ng-container>
