import {Injectable, ElementRef} from '@angular/core';
import {TimeAxis, SparklineConfig, StackedSparklineConfig} from '../models/sparkline-config';
import {BaseLineConfiguration} from './base-line-configuration';

@Injectable({
  providedIn: 'root'
})
export class SparklineConfigService extends BaseLineConfiguration {
  constructor() {
    super()
  }

  /**
   * Return the All values for SparklineConfig except for background
   */
  getTimeseriesConfig(data: TimeAxis[], pointColor?: string): SparklineConfig {
    let fromColor = 'rgba(16, 156, 241, 0.7)';
    let toColor = 'rgba(16, 156, 241, 0)'
    let defaultPointColor = "rgba(58, 150, 253)"
    return {
      datasets: [
        {
          data: data,
          gradient: {
            fromColor,
            toColor
          },
          strokeColor: toColor,
          borderColor: fromColor,
          borderWidth: 2,
          lineTension: 0,
          pointRadius: 5,
          pointHitRadius: 7,
          pointBorderWidth: 3,
          pointBorderColor: pointColor ? pointColor : defaultPointColor,
          pointBackgroundColor: fromColor,
          pointHoverBackgroundColor: toColor,
          pointHighlightStroke: toColor,
          fill: true,
          tooltipText: data.map(point => `${point.y} ${point.data}`)
        }
      ],
      labels: data.map(point => point.x)
    }
  }

  /**
   * Return the All values for SparklineConfig except for background
   */
  getStackedTimeseriesConfig(data: StackedSparklineConfig, sparkline: SparklineConfig, pointColor?: string): SparklineConfig {
    let fullColors = [
      'rgba(58, 150, 253, 1)',
      'rgba(157, 96, 251, 1)'
    ];
    let fromColors = [
      'rgba(58, 150, 253, 0.7)',
      'rgba(157, 96, 251, 0.7)'
    ];
    let toColors = [
      'rgba(58, 150, 253, 0)',
      'rgba(157, 96, 251, 0)',
    ]
    sparkline.labels = data[0].trend.map(point => point.x);
    data.forEach((stackedTrend, index) => {
      let fromColor = fromColors[index];
      let toColor = toColors[index];
      let fullColor = fullColors[index];
      sparkline.datasets.push(
        {
          data: stackedTrend.trend,
          gradient: {
            fromColor,
            toColor
          },
          label: stackedTrend.label,
          backgroundColor: fullColor,
          strokeColor: toColor,
          borderColor: fromColor,
          borderWidth: 2,
          lineTension: 0,
          pointRadius: 1,
          pointHitRadius: 1,
          pointBorderWidth: 1,
          pointBorderColor: toColor,
          pointBackgroundColor: fromColor,
          pointHoverBackgroundColor: toColor,
          pointHighlightStroke: toColor,
          fill: true,
          tooltipText: stackedTrend.trend.map(point => `${point.y} ${data[0].unit}`)
        }
      )
    })
    return sparkline;
  }

  /**
   * Re initiate pointRadius with array, so only marker index will be displayed
   */
  removePointsOtherThanMarker(sparkLineFinalData: SparklineConfig, markerElementIndex: number): SparklineConfig {
    sparkLineFinalData.datasets[0].pointRadius = this.pointRadius(sparkLineFinalData.datasets[0].data, markerElementIndex);
    return sparkLineFinalData;
  }

  /**
   * Return array in same length of the data array. the radius will be higher then 0 only when match the
   * given index
   */
  pointRadius(data: TimeAxis[], markerIndex): number[] {
    let radiusArray: number[] = [];
    data.forEach((point, index) => {
      if (index != markerIndex)
        radiusArray.push(0)
      else
        radiusArray.push(3)
    })
    return radiusArray;
  }

  /**
   * Return Background as gradient
   * This method can only be activated after the Sparkline element exist, for it need the element reference and
   * the Sparkline height
   */
  addGardientBackground(lineConfig: SparklineConfig, sparklineElm: ElementRef, height: number, isStacked: boolean = false): SparklineConfig {
    lineConfig.datasets.forEach(datasetConfig => {
      let config = datasetConfig;
      let gradient = sparklineElm.nativeElement.getContext("2d").createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, config.gradient.fromColor);
      gradient.addColorStop(1, config.gradient.toColor);
      config.backgroundColor = gradient;
    })
    return lineConfig;
  }
}
