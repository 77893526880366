import {IGetRowsParams} from 'ag-grid-community';
import {BaseSortFilter} from './base-sort-filter.model';
import {QueryParamValue} from '../../../features/anomalies/components/main-actions-dashboard/models/actions-list-query-params.model';

export interface ExtraPagingParams extends IGetRowsParams {
  // extra paging parameters passed from the server side
  extraPagingParams?: Record<string, string>;
}

export class GridSortFilter extends BaseSortFilter {

  pageNo: number;
  pageSize: number;
  extraPagingParams?: Record<string, string>;
  queryParams?: QueryParamValue

  constructor(
    params?: ExtraPagingParams,
    searchText: string = '',
    numberOfRawsInPage?: number,
    filterByParamName?: string,
    queryParams?: QueryParamValue
  ) {
    super();
    this.startRow = params ? params.startRow : 0;
    this.endRow = params ? params.endRow : 100;
    this.extraPagingParams = params ? params.extraPagingParams : undefined;
    this.sortBy = (params && params.sortModel[0]) ? params.sortModel[0].colId : '';
    this.sortDir = (params && params.sortModel[0]) ? params.sortModel[0].sort : null;
    this.filterBy = searchText || '';
    this.filterByParamName = filterByParamName ? filterByParamName : "filterBy";
    this.pageSize = numberOfRawsInPage ? numberOfRawsInPage : 100;
    this.pageNo = (this.pageSize && params) ? Math.floor(params.startRow / this.pageSize) : 0;
    this.queryParams = queryParams;
  }

  filterBy: string;
  filterByParamName?: string = "filterBy";

  appendToURL(url: string): string {
    let params = [];
    let sortDir = this.sortDir == "asc" ? "Asc" : "Desc";
    if (this.sortBy !== undefined && sortDir !== undefined) {
      params.push(`sortBy=${this.sortBy}`);
      params.push(`sortDir=${sortDir}`);
    }
    if (this.filterBy !== "") {
      params.push(`${this.filterByParamName}=${this.filterBy}`);
    }
    if (this.startRow !== undefined) {
      params.push(`startRow=${this.startRow}`);
    }
    if (this.endRow !== undefined) {
      params.push(`endRow=${this.endRow}`);
    }
    //16.12.19 - Imry: Next three values added for the benefit of the new alerts api
    if (this.pageNo !== undefined) {
      params.push(`pageNo=${this.pageNo}`);
    }
    if (this.pageSize !== undefined) {
      params.push(`pageSize=${this.pageSize}`);
    }

    if (this.extraPagingParams) {
      Object.keys(this.extraPagingParams).forEach(paramName => {
        params.push(`${paramName}=${this.extraPagingParams[paramName]}`)
      })
    }
    if (this.queryParams) {
      /**
       * Check if param already exist
       * If yes, assign it with new value,
       * if not push the new param to the param array
       */
      Object.keys(this.queryParams).forEach(paramName => {
        const paramIndex = params.findIndex(param => param.slice(0, -1) === paramName);
        if (paramIndex !== -1) {
          params[paramIndex] = params[paramIndex] + `${this.queryParams[paramName]}`;
        } else
          params.push(`${paramName}=${this.queryParams[paramName]}`);
      })
    }
    //debugger
    if (params.length == 0) {
      return url;
    }
    let haveQueryParams = url.indexOf("?") != -1;
    return url + (haveQueryParams ? "&" : "?") + params.join("&");
  }
}
