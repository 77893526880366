<app-single-device-tooltip *ngIf="currentTooltipType == tooltipTypes.SingleDeviceTooltip" [tplgRect]="tplgRect"
                           [nodeEvent]="nodeEvent">
</app-single-device-tooltip>
<app-single-client-device-tooltip *ngIf="currentTooltipType == tooltipTypes.SingleClientTooltip"
                                  [tplgRect]="tplgRect" [nodeEvent]="nodeEvent">
</app-single-client-device-tooltip>
<app-multi-clients-tooltip *ngIf="currentTooltipType == tooltipTypes.MultiClientTooltip" [tplgRect]="tplgRect"
                           [nodeEvent]="nodeEvent">
</app-multi-clients-tooltip>
<app-cloud-node-tooltip *ngIf="currentTooltipType == tooltipTypes.CloudeNodeTooltip" [tplgRect]="tplgRect"
                        [nodeEvent]="nodeEvent"></app-cloud-node-tooltip>
