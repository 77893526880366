import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {getYesterdayDate, getPeriodDates} from '../../operators/time-operator';
import {NgChanges} from '../../extend-angular-classes/on-changes';
import {FromToDates, TimePeriod, TimePeriodWithDates} from "../../models/time.model";

@Component({
  selector: 'app-period-time-selection',
  templateUrl: './period-time-selection.component.html',
  styleUrls: ['./period-time-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodTimeSelectionComponent implements OnInit {
  @Input() selectedTimePeriod: TimePeriod;
  @Input() daysBetweenDatesLimits: number;
  @Input() maxDateLimit = getYesterdayDate();
  @Input() fromDate: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  @Input() toDate: Date = getYesterdayDate();
  @Input() formFieldCss: string;
  @Input() timePeriodArray: TimePeriod[];
  @Input() fromYesterday: boolean = true;
  @Input() emitOnInit: boolean = false;
  @Input() allowSameDaySelection: boolean = false;
  @Output() timeSelected: EventEmitter<TimePeriodWithDates> = new EventEmitter();
  timePeriod = TimePeriod;

  constructor() {
  }

  ngOnInit() {
    if (this.emitOnInit) {
      this.onTimeSelectionChanged(this.selectedTimePeriod);
    }
  }

  ngOnChanges(changes: NgChanges<PeriodTimeSelectionComponent>) {
    if (changes.allowSameDaySelection && this.allowSameDaySelection) {
      this.toDate = new Date();
    }
  }

  /**
   * Save time selection to the reportsStoreService
   */
  onTimeSelectionChanged(period: TimePeriod) {
    if (period !== TimePeriod.CUSTOM_DATE) {
      this.timeSelected.emit({
        timePeriod: period,
        dates: getPeriodDates(period, this.fromYesterday)
      });
    }
  }

  /**
   * Save custome time selection to the reportsStoreService
   */
  onCustomTimeChanges(dates: FromToDates) {
    this.timeSelected.emit({
      timePeriod: TimePeriod.CUSTOM_DATE,
      dates: dates
    });
  }
}
