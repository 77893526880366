<div class="{{cssClass}} bot-step-container chat-robot-row">
  <div class="row chat-robot-row">
    <div class="{{stepQuestionContainerClass}}">
      <div class="chat-robot-container">
        <p>{{question}}</p>
      </div>
    </div>
    <ng-content select="[nextToQuestion]"></ng-content>
  </div>
  <div class="row chat-robot-row">
    <div [ngClass]="stepContentContainerClass">
      <ng-content select="[stepContent]"></ng-content>
    </div>
  </div>
</div>
