<div>
  <ng-container *ngIf="isBackToTenant">
    <div *teleportTo="'.teleported-back-to-venue'" class="reports-dev-back-to as-link">
      <span (click)="backToTenantView()">Back To Tenant View</span>
    </div>
  </ng-container>
  <ng-container *ngIf="currentEntity?.type == EntityType.TENANT">
    <app-tenant-deviation-reports-dialog (selectedEntity)="onEntitySelected($event)" [currentEntity]="currentEntity"
                                         [currentDate]="selectedDate">
    </app-tenant-deviation-reports-dialog>
  </ng-container>
  <ng-container *ngIf="currentEntity?.type == EntityType.VENUE">
    <app-venue-deviation-reports-dialog [currentEntity]="currentEntity"
                                        [currentDateIsoFormat]="{start: selectedDate, end: selectedDate}"
                                        [currentTenant]="currentTenant">
    </app-venue-deviation-reports-dialog>
  </ng-container>
</div>
