import { Injectable } from '@angular/core';
import { TrafficUnits } from 'src/app/shared/models/kpi.model';
import { KpiDataService } from 'src/app/shared/services/strategies/kpi-data.service';
import { LegacyClient } from 'src/app/shared/models/clients.model';
import { VenueVPN } from 'src/app/shared/models/vpn.model';
import { TranslateService } from '@ngx-translate/core';
import { TopTrafficTotalDisplay, TopTrafficSingleDisplayData, TopTrafficOrigin, VPNTrafficData, DeviceTrafficData, ClientsTrafficData, AppsTrafficData, TopVenueData } from '../../../../../models/legacy-reports.model';
import { sortArrayByParam } from 'src/app/shared/operators/array.operator';

@Injectable({
  providedIn: 'root'
})
export class TopTrafficService {


  constructor(private kpiDataService: KpiDataService) { }

  findTopByNumber(topEntities: TopTrafficTotalDisplay, topNumber: number): TopTrafficSingleDisplayData[] {
    if (topEntities) {
      let tops: TopTrafficSingleDisplayData[] = this.getTopTrafficList(topEntities.data, topNumber);
      if (tops.length > 0)
        return this.addOthersToTops(tops, topEntities);
    }
    return null;
  }

  addOthersToTops(tops: TopTrafficSingleDisplayData[], topEntities: TopTrafficTotalDisplay): TopTrafficSingleDisplayData[] {
    let topsTotal: number = 0;
    let topsOriginalTotal: number = 0;
    tops.forEach(trafficData => {
      topsTotal += trafficData.calculatedData;
      topsOriginalTotal += trafficData.originalData;
    });
    if (topEntities.originalData > topsOriginalTotal) {
      let isTraffic = tops[0].isTraffic;
      let originalTraffic = topEntities.originalData - topsOriginalTotal;
      let sizeAndUnit;
      if (isTraffic) {
        sizeAndUnit = this.kpiDataService.getBytesSizeAfterCalc(originalTraffic);
      }

      let others: TopTrafficSingleDisplayData = {
        details: null,
        calculatedData: isTraffic ? +sizeAndUnit.value.toFixed(2) : +originalTraffic.toFixed(2),
        originalData: +originalTraffic.toFixed(2),
        unit: isTraffic ? sizeAndUnit.size : null,
        isTraffic: isTraffic,
        label: "Others",
        displayLabel: `Others\n${(originalTraffic / topEntities.originalData * 100).toFixed(2)}%`
      };
      tops.push(others);
    }
    return tops;
  }
  getTopTrafficList(topEntities: TopTrafficSingleDisplayData[], topNumber: number) {
    let topEntitiesCalculation = [...topEntities];
    let topList: TopTrafficSingleDisplayData[] = [];
    topNumber = topNumber <= topEntities.length ? topNumber : topEntities.length;
    for (let i = 1; i <= topNumber; i++) {
      let highest = this.findHighest(topEntitiesCalculation);
      if (highest.details) {
        topList.push(highest);
      }
      let index = topEntitiesCalculation.findIndex(entity => {
        if (entity.details && highest.details)
          return entity.details.id == highest.details.id
      });
      if (index || index == 0) {
        topEntitiesCalculation.splice(index, 1);
      }
    }
    return topList;
  }

  findHighest(topEntities: TopTrafficSingleDisplayData[]): TopTrafficSingleDisplayData {
    let highest = topEntities[0];
    topEntities.forEach(device => {
      if (device.originalData > highest.originalData)
        highest = device;
    })
    return highest;
  }

  getTrafficBySize(topEntities: TopTrafficTotalDisplay) {
    let sizeUnit: TrafficUnits;
    sizeUnit = this.kpiDataService.getBytesSizeAfterCalc(topEntities.originalData).size;
    return { topEntities: topEntities, unit: sizeUnit };
  }

  getEntityName(details: any) {
    if (details) {
      if (details.hasOwnProperty("mac")) {
        return (details as LegacyClient).description ? (details as LegacyClient).description :
          (details as LegacyClient).mac
      }
      if (details.hasOwnProperty("firstVpnSite"))
        return `${(details as VenueVPN).firstVpnSite.name} - ${(details as VenueVPN).secondVpnSite.name}`;
      if (details.hasOwnProperty("serialNumber")) {
        return details.name;
      }
      if (typeof details === "string") {
        return details;
      }
      if (details.hasOwnProperty("second")) {
        return details.second;
      }
    }
  }

  generateDisplayLabel(entity: any, totalTraffic: number, origin: TopTrafficOrigin) {
    let name: string;
    if (entity.hasOwnProperty("device")) {
      name = this.getEntityName(entity.device);
    }
    if (entity.hasOwnProperty("client")) {
      name = this.getEntityName(entity.client);
    }
    if (entity.hasOwnProperty("vpn-connection")) {
      name = this.getEntityName(entity["vpn-connection"]);
    }
    if (entity.hasOwnProperty("resourceId")) {
      name = entity.resourceId;
    }
    if (entity.hasOwnProperty("resourceId") && entity["resourceId"].hasOwnProperty("first") && entity["resourceId"].hasOwnProperty("second")) {
      name = entity.resourceId.second;
    }
    //Imry: 4.11.20: The following code was meant to prevent display of to long strings on the traffic pie
    if (name) {
      let formattedName = name.substring(0, 25);
      let addition = name.length > 25 ? "..." : "";
      formattedName = formattedName + addition;
      let value = this.getEntityValue(entity, origin);
      let trafficAsPercentage = value ? `${((value / totalTraffic) * 100).toFixed(2)}%` : null;
      return `${formattedName}\n${trafficAsPercentage}`;
    }
  }


  getDonutTitle(origin: TopTrafficOrigin, translate: TranslateService, topNumber: number): string {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
        return translate.instant("data.REPORTS.TOP_CLIENTS", { topNumber: topNumber })
      case TopTrafficOrigin.DEVICES:
        return translate.instant("data.REPORTS.TOP_DEVICES", { topNumber: topNumber })
      case TopTrafficOrigin.VPN_CONNS:
        return translate.instant("data.REPORTS.TOP_VPN", { topNumber: topNumber })
      case TopTrafficOrigin.APPS:
        return translate.instant("data.REPORTS.TOP_APPS", { topNumber: topNumber })
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
        return translate.instant("data.REPORTS.TOP_CONNECTED_CLIENTS", { topNumber: topNumber })
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return translate.instant("data.REPORTS.TOP_CONNECTED_FAILURES", { topNumber: topNumber })
      default:
        break;
    }
  }

  getNoDataMessage(origin: TopTrafficOrigin, translate: TranslateService): string {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'Clients Traffic' })
      case TopTrafficOrigin.DEVICES:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'Devices Traffic' })
      case TopTrafficOrigin.VPN_CONNS:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'VPN Traffic' })
      case TopTrafficOrigin.APPS:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'Apps Traffic' })
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'Device Connections' })
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return translate.instant("data.REPORTS.NO_DATA_TEXT", { origin: 'Connection Failures' })
      default:
        break;
    }
  }

  getByText(origin: TopTrafficOrigin, translate: TranslateService): string {
    if (this.checkIsTraffic(origin)) {
      return translate.instant('data.REPORTS.BY_TRAFFIC');
    }
    if (origin == TopTrafficOrigin.DEVICE_BY_CLIENTS || origin == TopTrafficOrigin.DEVICE_BY_CONN_FAILURES) {
      return translate.instant('data.REPORTS.BY_CLIENTS_NUMBER');
    }
  }

  getTrafficDataByOrigin(deviceTrafficData: any, origin: TopTrafficOrigin) {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
        return (deviceTrafficData as ClientsTrafficData).client;
      case TopTrafficOrigin.DEVICES:
        return (deviceTrafficData as DeviceTrafficData).device;
      case TopTrafficOrigin.VPN_CONNS:
        return (deviceTrafficData as VPNTrafficData)["vpn-connection"];
      case TopTrafficOrigin.APPS:
        return (deviceTrafficData as AppsTrafficData).resourceId;
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return (deviceTrafficData as DeviceTrafficData).device;
      case TopTrafficOrigin.VENUES_BY_CLIENTS:
      case TopTrafficOrigin.VENUES_BY_TRAFFIC:
        return (deviceTrafficData as TopVenueData).resourceId;
      default:
        break;
    }
  }
  getTrafficParamByOrigin(origin: TopTrafficOrigin, sortDirection: ("asc" | "desc")) {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
        return sortArrayByParam('traffic', sortDirection);
      case TopTrafficOrigin.VPN_CONNS:
        return sortArrayByParam('vpn-connection', sortDirection);
      case TopTrafficOrigin.APPS:
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
      case TopTrafficOrigin.DEVICES:
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
      case TopTrafficOrigin.VENUES_BY_CLIENTS:
      case TopTrafficOrigin.VENUES_BY_TRAFFIC:
        return sortArrayByParam('value', sortDirection);
      default:
        break;
    }
  }
  checkIsTraffic(origin: TopTrafficOrigin): boolean {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
      case TopTrafficOrigin.VPN_CONNS:
      case TopTrafficOrigin.DEVICES:
      case TopTrafficOrigin.APPS:
      case TopTrafficOrigin.VENUES_BY_TRAFFIC:
        return true;
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
      case TopTrafficOrigin.VENUES_BY_CLIENTS:
        return false;

      default:
        break;
    }
  }
  getDatumEntityName(origin: TopTrafficOrigin): string {
    switch (origin) {
      case TopTrafficOrigin.CLIENTS:
        return "Clients";
      case TopTrafficOrigin.DEVICES:
        return "Devices";
      case TopTrafficOrigin.VPN_CONNS:
        return "Vpn Connections";
      case TopTrafficOrigin.APPS:
        return "Apps";
      case TopTrafficOrigin.DEVICE_BY_CLIENTS:
        return "Clients";
      case TopTrafficOrigin.DEVICE_BY_CONN_FAILURES:
        return "Connection Failures";
      case TopTrafficOrigin.VENUES_BY_CLIENTS:
      case TopTrafficOrigin.VENUES_BY_TRAFFIC:
        return "Venues";
      default:
        break;
    }
  }

  getEntityValue(deviceData: any, origin: TopTrafficOrigin): number {
    if (origin == TopTrafficOrigin.CLIENTS || origin == TopTrafficOrigin.VPN_CONNS) {
      return deviceData["traffic"];
    }
    return deviceData.value;
  }
  getTotal(isTopTrafficData: boolean, translate: TranslateService, topEntities: TopTrafficTotalDisplay): string {
    if (isTopTrafficData && topEntities) {
      if (topEntities.isTraffic) {
        return `${translate.instant("data.REPORTS.TOTAL_TRAFFIC")}: ${topEntities.calculatedData} (${topEntities.unit})`;
      }
      else {
        return `${translate.instant("data.REPORTS.TOTAL_TRAFFIC")}: ${topEntities.originalData}`;
      }
    }
    return null;
  }
}
