import { Pipe, PipeTransform } from '@angular/core';
import { stringIsNumber } from '../operators/string-opertators';

@Pipe({
  name: 'removeIndexAfterName'
})
export class RemoveIndexAfterNamePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    let splittedValue = value.trim().split('.');
    if (splittedValue.length == 1) {
      return splittedValue[0];
    }
    if (splittedValue.length > 1) {
      if (stringIsNumber(splittedValue[0])) {
        return splittedValue[0];
      }
      return value;
    }
  }
}
