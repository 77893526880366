<!--Dialog Title-->
<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog(formAction.UNCHANGED)">
        {{modalText?.title}}
    </app-netop-dialog-title>
    <app-netop-dialog-content>
        <div class="edit-modal-content-container">
            <p class="edit-modal-content">{{modalText?.message}}</p>
        </div>
    </app-netop-dialog-content>
    <!--
        28.1.20
        Currently each dialog scenerio has it own "ngIf"
        In the future, consider to make it more elegant and dynamic
    -->
    <ng-container *ngIf="data.type == formAction.CANCEL">
        <app-netop-dialog-actions>
            <app-buttons (click)="closeDialog(formAction.UNCHANGED)" class="alerts-button" [btnStyle]="'primary'"
                [btnVariant]="'cancel'" [btnText]="'data.COMMON.NO'">
            </app-buttons>
            <app-buttons (click)="closeDialog(formAction.DELETE)" class="alerts-button" [btnStyle]="'danger'"
                [btnVariant]="'cancel'" [btnText]="'data.COMMON.YES' | translate" [btnDisabled]="btnDisabled"
                [btnClass]="'warning-color'">
            </app-buttons>
        </app-netop-dialog-actions>
    </ng-container>
    <ng-container *ngIf="data.type == formAction.LEAVE">
        <app-netop-dialog-actions>
            <app-buttons (click)="closeDialog(formAction.UNCHANGED)" class="alerts-button" [btnStyle]="'primary'"
                [btnVariant]="'cancel'" [btnText]="'data.COMMON.CANCEL'">
            </app-buttons>
            <app-buttons (click)="closeDialog(formAction.DELETE)" class="alerts-button" [btnStyle]="'danger'"
                [btnVariant]="'cancel'" [btnText]="'data.COMMON.LEAVE' | translate" [btnDisabled]="btnDisabled"
                [btnClass]="'warning-color'">
            </app-buttons>
        </app-netop-dialog-actions>
    </ng-container>
</app-netop-dialog>