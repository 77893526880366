import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-chip-cell-renderer',
  templateUrl: './chips-cell-renderer.component.html',
  styleUrls: ['./chips-cell-renderer.component.scss']
})
export class ChipsCellRendererComponent implements ICellRendererAngularComp {
  chips: any;
  selectable?: boolean;
  multiple?: boolean;
  getOrientation?: string;

  constructor() {}

  agInit(params: ChipsCellRendererParams): void {
    const { chips, selectable, multiple, getOrientation } = JSON.parse(params.valueFormatted);
    this.chips = chips;
    this.selectable = selectable;
    this.multiple = multiple;
    this.getOrientation = getOrientation;
  }

  refresh(params: any): boolean {
    return false;
  }
}

export interface ChipsCellRendererParams extends ICellRendererParams {
  chips: string[];
  selectable?: boolean;
  multiple?: boolean;
  getOrientation?: string;
}
