export function encodedEmailAddress(email: string) {
  let forbiddenChars = '.$[]#/@';
  let encodedEmail = email;
  for (let i = 0; i < email.length; i++) {
    if (forbiddenChars.includes(email[i]))
      encodedEmail = encodedEmail.replace(email[i], encodeURIComponent(email[i]));
    if (email[i] == ".")
      encodedEmail = encodedEmail.replace(".", ":");
  }
  return encodedEmail;
}