import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DateTimePayload, TimePayload} from "../time-range-picker.model";
import {NgChanges} from "../../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit, OnChanges {
  @Input() date: Date = new Date();
  @Output() dateTimeSelected = new EventEmitter<DateTimePayload>();
  hour: number;
  minute: number;

  constructor() { }

  ngOnInit(): void {
    this.hour = this.date?.getHours() ?? 0;
    this.minute = this.date?.getMinutes() ?? 0;
  }

  ngOnChanges(changes: NgChanges<DateTimePickerComponent>) {
    if (changes.date && this.date) {
      this.hour = this.date.getHours();
      this.minute = this.date.getMinutes();
    }
  }

  onDateSelected(date: Date) {
    this.date = date;
    this.setDateTime();
    this.emitSelection();
  }

  onTimeSelected(payload: TimePayload) {
    if (payload) {
      this.hour = payload.hour;
      this.minute = payload.minute;
      this.setDateTime();
      this.emitSelection();
    }
  }

  setDateTime() {
    if (this.date) {
      this.date.setHours(this.hour);
      this.date.setMinutes(this.minute);
    }
  }

  emitSelection() {
    this.dateTimeSelected.emit({
      date: this.date,
      hour: this.hour,
      minute: this.minute
    });
  }
}
