import {HealthIndicator} from './health.model';
import {EntityAction} from "./actions.model";
import {EntitySyncStatus} from './sync-status.model';
import {TopTrafficSingleDisplayData} from './legacy-reports.model';
import {SeverityCount} from "./severity.model";

export interface Venue {
  id?: number;
  type: string;
  name: string;
  lat: number;
  long: number;
  address: string;
  phone: string;
  health?: number;
} 

export class UpdateVenueRequest {
  name: string;
}

/**
 * comes from summary API tenants/1/venues/summary
 */
export class VenueSummary {
  constructor(public id, public name, public deviceCount, public SSIDCount, public vlanCount) {
  }
}

export class VenuesHealth {
  id: number;
  venueName: string;
  venueHealth?: number;
  fabricsHealth: number;
  devicesHealth: number;
  statusIndicator?: HealthIndicator; // e.g. "MOST_SEVERE"
  address: string;
  lat?: number;
  long?: number;
  numFabrics: number;
  numIncompleteFabrics?: number;
  numDevices: number;
  numUnhealthyDevices?: number;
  syncStatus: EntitySyncStatus;
  type: VenueType;
  kpiMissingDevicesNo: number;
  openAnomaliesCount?: number;
  anomalySeverityCount: SeverityCount;
  incidentSeverityCount?: SeverityCount;
}

export class VenueData {
  venue: VenueRawData;
  kpis: {
    latency: number,
    users: number,
    traffic: number,
    utilization: number,
    loss: number,
  };
  health: number;
}

export class VenueRawData {
  id: number;
  name: string;
  address: string;
  lat: number;
  long: number;
  createdAt: string;
  devicesNo: number;
  fabricsNo: number;
  phone: string;
  description: string;
  tenantId: number;
  type: VenueType;
  properties: { key: string, value: string }[];
  iconPath?: string;
}

export class VenueSyncData {
  createdAt: string;
  syncStatus: EntitySyncStatus;
  tenantId: number;
  updatedAt: string;
  venueId: number;
}

export class VenueTopStatsData {
  resourceId: string;
  value: number;
}

export class VenueTopDeviceConnectionTypeData {
  resourceId: string;
  total_traffic: number;
  date: Date;
}

export class VenueTopDeviceDomainData {
  unidentifiedDevice: number;
  switch: number;
  www: number;
  accessPoint: number;
  firewall: number;
}

export class VenueTopDeviceStatsDto {
  domain?: TopTrafficSingleDisplayData[] = [];
  connectionType?: TopTrafficSingleDisplayData[] = [];
}

export class VenueTopClientOnlineDevicesData {
  failed: number;
  total: number;
}

export class VenueTopClientStatsDto {
  applicationDistribution?: TopTrafficSingleDisplayData[] = [];
  manufacturer?: TopTrafficSingleDisplayData[] = [];
  topTraffic?: TopTrafficSingleDisplayData[] = [];
  connectionType?: TopTrafficSingleDisplayData[] = [];
  onlineDevices?: TopTrafficSingleDisplayData[] = [];
}

export enum VenueType {
  CAMPUS = 'CAMPUS',
  BUILDING = 'BUILDING',
  FLOOR = 'FLOOR',
  APARTMENT = 'APARTMENT',
  ROOM = 'ROOM',
  CLOUD = 'CLOUD',
  SASE = 'SASE'
}

export class VenuesAction extends EntityAction {
}

export namespace VenueFeatures {
  export const LEGACY_METRICS_SOURCE = 'LEGACY_METRICS_SOURCE';
}
