//SOURCE: https://stackblitz.com/edit/medium-angular-loading-button?embed=1&file=src/app/mat-button-loading.directive.ts
import { Directive, ComponentFactory, OnChanges, ComponentRef, Input, ComponentFactoryResolver, ViewContainerRef, Renderer2, ElementRef } from '@angular/core';
import { NgChanges } from '../extend-angular-classes/on-changes';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';

@Directive({
  selector: '[showSpinner]'
})
export class SpinnerDirective implements OnChanges {
  private spinnerFactory: ComponentFactory<MatProgressSpinner>;
  private spinner: ComponentRef<MatProgressSpinner>;

  @Input()
  showSpinner: boolean;

  @Input()
  diameter: number = 100;

  color: ThemePalette = "primary";

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private _el: ElementRef,
    private _ren: Renderer2
  ) {
    this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
  }

  ngOnChanges(changes: NgChanges<SpinnerDirective>): void {
    if (!changes.showSpinner) {
      return;
    }

    if (changes.showSpinner.currentValue) {
      this.createSpinner();
    } else if (!changes.showSpinner.firstChange) {
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    this._ren.addClass(this._el.nativeElement, 'spinner-loading');
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
      this.spinner.instance.color = this.color;
      this.spinner.instance.diameter = this.diameter;
      this.spinner.instance.mode = 'indeterminate';
      this._ren.appendChild(this._el.nativeElement, this.spinner.instance._elementRef.nativeElement);
    }
    setTimeout(() => {
      this.destroySpinner();
    }, 45000);
  }

  private destroySpinner(): void {
    this._ren.removeClass(this._el.nativeElement, 'spinner-loading');
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
