import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-netop-dialog-content',
  templateUrl: './netop-dialog-content.component.html',
  styleUrls: ['./netop-dialog-content.component.scss']
})
export class NetopDialogContentComponent implements OnInit {
  @Input() cssClass: string;
  constructor() { }

  ngOnInit() {
  }

}
