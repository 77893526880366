import { Directive, Input, Renderer2, ElementRef, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { rssiIconData } from 'src/app/shared/models/clients.model';
import { HealthColorsService } from 'src/app/shared/services/strategies/health-colors.service';

@Directive({
  selector: '[rssiIconStyler]'
})
export class RssiIconStylerDirective {
  @Input('rssiIconStyler') icon: rssiIconData

  constructor(private _ren: Renderer2, private _el: ElementRef, private cdr: ChangeDetectorRef,
    private healthColorsService: HealthColorsService) { }

  ngOnChanges(changes: SimpleChanges) {
    let iconSize: string = this.icon.numberOfClients < 100 ? '18px' : '26px';
    let cssProperties: { propName: string, propValue: string }[] = [
      { propName: 'color', propValue: this.healthColorsService.getClientDotColor(this.icon.iconColor) },
      { propName: 'border', propValue: `1px solid ${this.healthColorsService.getClientDotColor(this.icon.iconColor)}` },
      { propName: 'width', propValue: iconSize },
      { propName: 'height', propValue: iconSize },
      { propName: 'line-height', propValue: iconSize },
    ]
    cssProperties.forEach(prop => {
      this._ren.setStyle(this._el.nativeElement, prop.propName, prop.propValue);
    });
  }

}
