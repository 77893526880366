<app-graph-trend *ngIf="trendLineConfig && verticalOrTrend === VerticalTrend.Trend" [maxTicksX]="maxTicksX"
                 [maxTicksY]="maxTicksY" [stepSize]="graphStepSize"
                 [chartConfig]="trendLineConfig" [graphHeight]="graphHeight" [graphWidth]="graphWidth"
                 [beginAtZero]="true"
                 [maxX]="maxX" [minX]="minX" [stacked]="isStacked"
                 [hideLegend]="hideLegend"
                 [noDataError]="noDataMessage" [isResponsive]="isResponsive"
                 [timeDisplayFormat]="timeDisplayFormat" (onBarHovered)="onBarHovered($event)"
                 [hideTooltip]="hideTooltip">
</app-graph-trend>

<app-vertical-bar *ngIf="verticalBarDatasets && verticalOrTrend === VerticalTrend.Vertical"
                  [verticalBarDatasets]="verticalBarDatasets" [height]="graphHeight" [tooltipMinWidth]="tooltipMinWidth">
</app-vertical-bar>
