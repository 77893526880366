import { Hierarchy } from 'src/app/shared/models/hierarchy.model';
import { Entity } from 'src/app/shared/models/entity.model';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { GlobalEntitiesService } from 'src/app/shared/services/rest-services/global-entities.service';
import { OrganizationsService } from 'src/app/shared/services/rest-services/organizations.service';
import { VenuesService } from 'src/app/shared/services/rest-services/venues.service';

export class BaseFilterBarService {

  constructor(protected globalEntitiesService: GlobalEntitiesService,
    protected organizationService: OrganizationsService,
    protected venuesService: VenuesService) { }

  getOrgEntity(hierarchy: Hierarchy): Entity {
    if (hierarchy && hierarchy.length > 0 && hierarchy[0].type == EntityType.ORGANIZATION) {
      return hierarchy[0];
    }
  }
  getClosestTenantEntity(hierarchy: Hierarchy): Entity {
    let currentTenant: Entity;
    hierarchy.forEach(entity => {
      if (entity.type == EntityType.TENANT)
        currentTenant = entity
    })
    return currentTenant;
  }

  findCurrentEntity(hierarchy: Hierarchy): Entity {
    let lastEntity = hierarchy[hierarchy.length - 1];
    let venue = hierarchy.find(entity => entity.type == EntityType.VENUE);
    if (venue) {
      return venue;
    }
    if (lastEntity.type == EntityType.TENANT || lastEntity.type == EntityType.ORGANIZATION)
      return lastEntity;
  }
}