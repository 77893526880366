import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TOP_FULL_COLORS} from '../utils/chartjs-colors';
import {GraphDonutConfig, GraphDonutThemeConfig} from '../models/graph-donut-config';
import {TopTrafficSingleDisplayData, TopTrafficTotalDisplay} from '../../../models/legacy-reports.model';
import {TopTrafficService} from '../../dynamic-dashboard/components/traffic-statistics/services/top-traffic.service';
import {KpiDataService} from '../../../services/strategies/kpi-data.service';
import {TrafficUnits} from '../../../models/kpi.model';

@Injectable({
  providedIn: 'root'
})
export class DonutConfigService {
  TOP_COLORS: string[] = TOP_FULL_COLORS

  constructor(private topTrafficService: TopTrafficService, private kpiDataService: KpiDataService,
              protected translate: TranslateService) {
  }

  getTopEntityConfig(topEntities: TopTrafficSingleDisplayData[], allEntitiesDatum: TopTrafficTotalDisplay): GraphDonutConfig {
    let donutConfig = {
      datasets: []
    }
    if (topEntities) {
      topEntities.forEach((entity, index) => {
        let lineConfig: GraphDonutThemeConfig = {
          data: entity.originalData,
          displayLabel: entity.displayLabel,
          label: entity.label,
          backgroundColor: this.TOP_COLORS[index],
          iconCSSClass: this.TOP_COLORS[index],
          tooltipText: entity.isTraffic ? `${entity.label} #${entity.calculatedData} (${entity.unit})` :
            `${entity.label} #${entity.originalData} (${allEntitiesDatum.name})`
        }
        donutConfig.datasets.push(lineConfig);
      });
      return donutConfig;
    }
    return null;
  }

  getDonutConfig(dtos: TopTrafficSingleDisplayData[], title: string = '', numResults = 5): GraphDonutConfig {
    let donutConfig = {
      title: title,
      datasets: []
    };

    if (dtos && title) {
      dtos.forEach((dto, index) => {
        dto.calculatedData = dto.originalData;
        if (dto.originalData && dto.unit) {
          const {value, size} = this.kpiDataService.getBytesSizeAfterCalc(dto.originalData);
          dto.calculatedData = size == TrafficUnits.TB ? Math.round((value + Number.EPSILON) * 100) / 100 : value;
          dto.unit = size;
        }

        let lineConfig: GraphDonutThemeConfig = {
          data: dto.originalData,
          displayLabel: dto.label?.length > 10 ? dto.label.substr(0, 9) + '..' : dto.label,
          label: dto.label,
          backgroundColor: this.TOP_COLORS[index],
          iconCSSClass: this.TOP_COLORS[index],
          tooltipText: `${dto.label} (${dto.calculatedData.toLocaleString()}${dto.unit ? ' ' + TrafficUnits[dto.unit] : ''})`
        };

        donutConfig.datasets.push(lineConfig);
      });
    }

    return donutConfig;
  }
}
