<ag-grid-angular [showSpinner]="isLoading" class="entity-list ag-theme-material" [ngClass]="widgetClass"
                 rowSelection="single"
                 suppressCellFocus="true" (rowClicked)="gotToDevice($event)" [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)" suppressHorizontalScroll="true"

                 (modelUpdated)="onGridReady($event)" [components]="components"
                 [overlayLoadingTemplate]="'&nbsp;'"
                 [sortingOrder]="['asc','desc']" [rowClassRules]="rowClassRules" [cacheBlockSize]="numberOfRawsInPage"
                 [context]="context" [icons]="icons" rowModelType="infinite" [debug]="debugGrid" [rowHeight]="rowHeight"
                 [headerHeight]="headerHeight" (sortChanged)="onSortChange($event)" (dragStopped)="onColDragEnd($event)"
                 (viewportChanged)="onViewPortChanges($event)" (bodyScroll)="bodyScroll($event)"
                 (columnResized)="onColumnResized($event)">
</ag-grid-angular>
