<div class="row main-action-filters-row">
  <div class="main-action-filter-col">
    <app-filters-bar *ngIf="actionsFilters && actionsFilters.length > 0 && initialized" [filters]="actionsFilters" (filterSelected)="onFilterItemSelected($event)"
                     [cssColContainer]="'main-action-bar-col'"></app-filters-bar>
  </div>
  <div class="main-action-time-period-col">
    <app-time-period-picker *ngIf="currentTimePeriodSelectionIndex != null && showFilters" [currentSelectionIndex]="currentTimePeriodSelectionIndex"
                            (optionSelected)="onTimePeriodSelection($event)">
    </app-time-period-picker>
  </div>
</div>
