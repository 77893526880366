import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TimeManagerService } from '../../services/time-manager.service';
import { generateNumbersArray, sortArrayByParam } from '../../operators/array.operator';
import { DeviceBackupDTO } from '../../models/device-backup.model';
import {alphaNumericWithSpecialCharsValidator} from "../../form-validators/form-validators";
import {ERRORS_NAME} from "../../form-validators/form-model";

@Component({
  selector: 'app-clone-dialog',
  templateUrl: './clone-dialog.component.html',
  styleUrls: ['./clone-dialog.component.scss']
})
export class CloneDialogComponent implements OnInit {
  cloneForm: UntypedFormGroup;
  backups: { date: string, unFormatted: string, fileName: string }[];
  selectedBackups: string;
  constructor(
    private dateConversionService: TimeManagerService,
    private fb: UntypedFormBuilder,
    private matDialogRef: MatDialogRef<CloneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DeviceBackupDTO,

  ) { }

  ngOnInit() {
    this.initateForm();
    this.initateBackups();
  }
  initateBackups() {
    this.backups = generateNumbersArray(10).map(option => {
      let data = this.dateConversionService.generateMockedAndFormattedDate(2020);
      return { date: data.formatted, unFormatted: data.unFormatted, fileName: `${this.dialogData.deviceType}_${this.dialogData.firmWareVersion}_${data.unFormatted}` };
    }).sort(sortArrayByParam("unFormatted", "asc"));
  }
  initateForm() {
    this.cloneForm = this.fb.group({
      serial: new UntypedFormControl('', alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)),
      backup: new UntypedFormControl(''),
      deploy: new UntypedFormControl('now'),
      deployDate: new UntypedFormControl(''),
      deployHour: new UntypedFormControl(''),
    })
    this.cloneForm.valueChanges.subscribe(changes => {
      console.log("Clone Form changes", changes);
    })
  }
  onSelectionChanges(event: MatSelectionListChange) {
    this.selectedBackups = event["fileName"];
  }
  get isLater() {
    if (this.cloneForm)
      return this.cloneForm.controls['deploy'].value == 'later';
  }
  get isNow() {
    if (this.cloneForm)
      return this.cloneForm.controls['deploy'].value == 'now';
  }
  get isInput() {
    if (this.cloneForm)
      return this.cloneForm.controls['serial'].value;
  }
  closeDialog() {
    this.matDialogRef.close();
  }

}
