<app-portlet>
    <app-portlet-main-content>
        <app-portlet-header [title]="chartTitle" class="portlet-header" [hideDefaultTools]="true">
            <ng-container portletTools>
                <span class="time-span-label">
                    {{ 'data.DASHBOARD.SHOW' | translate }}:
                </span>
                <div class="dashboard-time-span-selection">
                    <form [formGroup]="selectedTrendPeriod">
                        <mat-form-field>
                            <mat-select class="dashboard-selection-container" formControlName="selectedPeriod">
                                <mat-option [disabled]="period !== 'Week'" *ngFor="let period of trendPeriod"
                                    [value]="period">
                                    {{period}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </ng-container>
        </app-portlet-header>
        <app-portlet-body class="portlet-body trend-graph device-portlet-body-container">
            <app-single-widget-big-chart [cssGraphClass]="'device-trend-container'" [isDevice]="true"
                [kpiType]="selectedWidgetType" [graphWidth]="graphWidth" [graphHeight]="graphHeight" (kpiUnitCalculated)="onUnitCalculated($event)">
            </app-single-widget-big-chart>
        </app-portlet-body>
    </app-portlet-main-content>
</app-portlet>
