import {Topology, GenericDevice, SingleD3Node} from '../../models/topology';
import {KpiData, KpiType} from '../../models/kpi.model';
import {PropertiesContent} from '../properties/models/properties-content';
import {SingleSizeCalculationService} from 'src/app/venues/services/single-size-calculation.service';
import {take} from 'rxjs/operators';
import {ClickedElementService} from '../../services/strategies/clicked-element.service';
import {InitatePropertiesDataService} from '../properties/services/initate-properties-data.service';
import {Subscription} from 'rxjs';
import {DisconnectedDevicesStoreService} from '../network-topology/services/disconnected-devices-store.service';
import {EntityType} from '../../models/entity-type.enum';
import {SingleDevice} from '../../models/single-device.model';
import {FormAction} from '../../modals/models/form-actions.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalTextContent} from '../../modals/models/modal-content.model';
import {EditPropertiesService} from '../properties/services/edit-properties.service';
import {D3TreeEventsService} from '../network-topology/services/d3-tree-events.service';
import {
  isDevice,
  isLink,
  isDisconnected,
  isZipper,
  isZipperChildrenInZipMode
} from '../network-topology/operators/topology-operators';
import {D3Link, SingleLink} from '../../models/single-link.model';
import {GenerateFabricPropertiesService} from "../properties/services/generate-fabric-properties.service";
import {GenerateVenuePropertiesService} from "../properties/services/generate-venue-properties.service";
import {VenueData} from "../../models/venues.model";
import {FabricKpiData} from "../../models/fabrics.model";
import {RestKpiGeneratorService} from "../kpi-display-components/services/rest-kpi-generator.service";
import { MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {animate, style, transition, trigger} from "@angular/animations";
import {BooleanKey} from "../../models/utils-classes/key-value.model";

export const PropertiesAnimation = [
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({opacity: 0}),
          animate('1s ease-out',
            style({opacity: 1}))
        ]
      ),
      transition(
        ':leave',
        [
          style({opacity: 1}),
          animate('500ms ease-in',
            style({opacity: 0}))
        ]
      )
    ]
  )
]

export class BaseSingleEntitiesHealth {
  entityData: FabricKpiData | VenueData | SingleDevice;
  entityType: EntityType;
  widgetsData: KpiData[];
  topology: Topology<SingleDevice, SingleLink>;
  isWidgetSelected = false;
  selectedWidgetType: KpiType;
  singleEntityHealth: number;
  graphWidth: number;
  graphHeight: number;
  tplgHeight: number;
  tplgWidth: number;
  propData: PropertiesContent;
  isPropertiesOpen: boolean = false;
  propTitle: string;
  selectedFabricName: string;
  showTeleport: boolean = false;
  isLoading: boolean = false;
  subscription: Subscription[] = [];
  protected elementWithStatus: BooleanKey = {};


  dialogConfig = new MatDialogConfig();
  type = FormAction.LEAVE;
  modalText: ModalTextContent = {
    title: this.translate.instant('data.PROPERTIES.LEAVING_FORM_TITLE'),
    message: this.translate.instant('data.PROPERTIES.LEAVING_FORM_MESSAGE')
  };

  constructor(
    public dialog: MatDialog,
    protected d3TreeEventsService: D3TreeEventsService,
    protected singleSizeCalculationService: SingleSizeCalculationService,
    protected restKpiGeneratorService: RestKpiGeneratorService,
    protected editPropertiesService: EditPropertiesService,
    protected translate: TranslateService,
    protected preparePropsDataService: InitatePropertiesDataService,
    protected fabricPropsGenerator: GenerateFabricPropertiesService,
    protected venuePropsGenerator: GenerateVenuePropertiesService,
    protected clickedElementService?: ClickedElementService,
    protected disconnectedDeviceStoreService?: DisconnectedDevicesStoreService
  ) {
    this.subscription = [];
    this.dialogConfig.height = '357';
    this.dialogConfig.width = '648px';
    this.dialogConfig.data = {
      type: this.type,
      data: this.modalText
    };
  }

  /**
   * @method calculateBigChartSize Responsible for the responsivness of the chart
   */
  protected getBigChartSize() {
    let graphSizes: { width: number, height: number };
    graphSizes = this.singleSizeCalculationService.calculateBigChartSize();
    this.graphWidth = graphSizes.width;
    this.graphHeight = graphSizes.height;
  }

  /**
   * @method calculateTopologySize Calculate topology size in order to make it responsive
   */
  protected calculateTopologySize() {
    let topologySize: { width: number, height: number };
    topologySize = this.singleSizeCalculationService.calculateTopologySize();
    this.tplgWidth = topologySize.width;
    this.tplgHeight = topologySize.height;
  }

  /**
   * @method initSingleWidgetsBar Recevie all the kpi values
   */
  protected initSingleWidgetsBar() {
    const kpiSubsc = this.restKpiGeneratorService.getKpiData().pipe(take(1)).subscribe(
      ([kpiHealth, kpiLatency, kpiLoss, kpiTraffic, kpiUsers]) => {
        this.widgetsData = [
          new KpiData(
            KpiType.Health,
            this.singleEntityHealth ? this.preparePropsDataService.multiHealthBy100(this.singleEntityHealth) : 0
          ),
          new KpiData(
            KpiType.Latency,
            kpiLatency.value ? kpiLatency.value : 0),
          new KpiData(
            KpiType.Loss,
            kpiLoss.value ? kpiLoss.value : 0),
          new KpiData(
            KpiType.Traffic,
            kpiTraffic.value ? kpiTraffic.value : 0
          ),
          new KpiData(
            KpiType.Clients,
            kpiUsers.value ? kpiUsers.value : 0
          )
        ];
      }
    );
    this.subscription.push(kpiSubsc);
  }

  /**
   * @method widget Save the current clicked widget, and set the isWidgetSelected to true;
   * @param prop
   * @param event
   */
  protected widgetSelected(prop: any, event: any) {
    this.selectedWidgetType = prop.key;
    this.isWidgetSelected = true;
  }

  subscribeToD3TreeClickEvents() {
    const d3EventsSubcs = this.d3TreeEventsService.notifyClickedElementObservable$.subscribe(element => {
      if (!isZipper(element) && !isZipperChildrenInZipMode(element)) {
        this.isPropertiesOpen = true;
        if (element) {
          this.showTeleport = false;
          if (isDevice(element)) {
            this.propData = this.clickedElementService.prepareNodeData(element as SingleD3Node<GenericDevice<any>>);
          }
          if (isLink(element)) {
            this.propData = this.clickedElementService.prepareLinkData(element as D3Link);
          }
          if (isDisconnected(element)) {
            this.propData = this.clickedElementService.prepareNodeData(element as GenericDevice<any>);
          }

        } else if (!this.isEditMode && !isZipper(element)) {
          this.isPropertiesOpen = true;
          this.initEntityData(this.entityData);
        }
      }
    });
    this.isPropertiesOpen = false;
    this.subscription.push(d3EventsSubcs);
  }

  /**
   * @method initEntityData convert the fabric data for the properties data object
   */
  protected initEntityData(data: any) {
    switch (true) {
      case this.entityType == EntityType.FABRIC:
        this.propData = this.fabricPropsGenerator.prepareFabricData(data, this.topology);
        break;
      case this.entityType == EntityType.VENUE:
        this.propData = this.venuePropsGenerator.prepareVenueData(data, this.topology);
      default:
        break;
    }
  }

  /**
   * @method closeChart Close the chart
   */
  protected closeChart() {
    this.isWidgetSelected = false;
    this.selectedWidgetType = null;
  }

  get isEditMode() {
    let isEditMode = false;
    const editModeSubsc = this.editPropertiesService.notifyIsEditModeObservable$.subscribe(mode => {
      isEditMode = mode;
    });
    this.subscription.push(editModeSubsc);
    return isEditMode;
  }

  protected onPanelStatusChanged(event: BooleanKey) {
    this.elementWithStatus[Object.keys(event)[0]] = Object.values(event)[0];
  }

  cancelSubscription() {
    this.subscription.forEach(subsc => {
      subsc.unsubscribe();
    })
  }

  onDestroy() {
    this.cancelSubscription();
  }
}
