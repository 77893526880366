import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Inject, OnDestroy,
  ViewContainerRef
} from '@angular/core';
import {NgControl, ValidationErrors} from "@angular/forms";
import {untilDestroyed} from 'ngx-take-until-destroy';
import {Subscription} from "rxjs";
import {ControlErrorComponent} from "../components/control-error/control-error.component";
import {FORM_ERRORS} from "../form-validators/form-utils";
import {debounceTime} from "rxjs/operators";
import {ERRORS_NAME} from "../form-validators/form-model";

//SOUCRE:https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorDirectiveDirective implements OnDestroy {
  ref: ComponentRef<ControlErrorComponent>;
  subscribe: Subscription

  constructor(private control: NgControl,
              @Inject(FORM_ERRORS) private errors,
              private resolver: ComponentFactoryResolver,
              private vcr: ViewContainerRef,
  ) {
  }

  ngOnInit() {
    if (this.control.valueChanges) {
      this.subscribe = this.control.valueChanges.pipe(
        debounceTime(200),
        untilDestroyed(this)).subscribe((v) => {
        const controlErrors = this.control.errors;
        if (controlErrors && !this.isRequiredButNotTouchedOrDirty(controlErrors)) {
          const firstKey = Object.keys(controlErrors)[0];
          const getError = this.errors[firstKey];
          if (getError && typeof getError == 'function') {
            const text = getError(controlErrors[firstKey]);
            this.setError(text);
          }
        } else if (this.ref) {
          this.setError(null);
        }
      });
    }
  }

  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.vcr.createComponent(factory);
    }
    this.ref.instance.text = text;
  }

  /**
   * Make sure required message won't appear before the user touched the form
   * @param controlErrors
   * @private
   */
  private isRequiredButNotTouchedOrDirty(controlErrors: ValidationErrors) {
    if (controlErrors) {
      const firstKey = Object.keys(controlErrors)[0];
      if (firstKey === ERRORS_NAME.REQUIRED && !this.control.touched && !this.control.dirty)
        return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.subscribe && !this.subscribe.closed) {
      this.subscribe.unsubscribe();
    }
  }

}
