import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-netop-dialog-actions',
  templateUrl: './netop-dialog-actions.component.html',
  styleUrls: ['./netop-dialog-actions.component.scss']
})
export class NetopDialogActionsComponent implements OnInit {
  @Input() cssClass: string;

  constructor() { }

  ngOnInit() {
  }

}
