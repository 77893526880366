import {BasicDevice} from './single-device.model';
import {LegacyClient} from './clients.model';
import {TrafficUnits} from './kpi.model';
import {VenueVPN} from './vpn.model';
import {EntityType} from './entity-type.enum';

export class BaseDeviation {
    date: Date | string;

}
export class TrafficDeviation extends BaseDeviation {
    value: number;
    dayOfWeekStddev: number;
    dayOfWeekMean: number;
    ["outlier-type"]: OutlierType
}
export class TrafficDeviationDisplay extends TrafficDeviation {
    unit: TrafficUnits;
}

export enum OutlierType {
    WEAK = "WEAK",
    STRONG = "STRONG"
}

export class TopTrafficTotalDisplay {
    data: TopTrafficSingleDisplayData[];
    calculatedData: number;
    originalData: number;
    unit: TrafficUnits;
    isTraffic: boolean;
    name: string;
}
export class TopTrafficSingleDisplayData {
    details: any;
    calculatedData: number;
    originalData: number;
    unit: TrafficUnits;
    isTraffic: boolean;
    label: string;
    displayLabel: string;

    init?(originalData: number, unit: TrafficUnits, label: string): TopTrafficSingleDisplayData {
      this.originalData = originalData;
      this.unit = unit;
      this.label = label;
      return this;
    }
}

export class TopTotalTraffic {
    value: number;
    data: [];
}
export class ReportsData {
    value: number;
}
export class DeviceTrafficData extends ReportsData {
    device: BasicDevice;
}
export class ClientsTrafficData extends ReportsData {
    client: LegacyClient;
}
export class VPNTrafficData extends ReportsData {
    ["vpn-connection"]: VenueVPN;
}
export class AppsTrafficData extends ReportsData {
    resourceId: string;
}
export class TopVenueData extends ReportsData {
    resourceId: FirstSecond;
}
export class FirstSecond {
    first: number;
    second: string;
}

export enum TopTrafficOrigin {
    DEVICES = "DEVICES",
    CLIENTS = "CLIENTS",
    VPN_CONNS = "VPN_CONNS",
    APPS = "APPS",
    DEVICE_BY_CLIENTS = "DEVICE_BY_CLIENTS",
    DEVICE_BY_CONN_FAILURES = "DEVICE_BY_CONN_FAILURES",
    SSID_BY_CONN_FAILURES = "SSID_BY_CONN_FAILURES",
    VENUES_BY_TRAFFIC = "VENUES_BY_TRAFFIC",
    VENUES_BY_CLIENTS = "VENUES_BY_CLIENTS"
}
export enum TrafficOrigin {
    DEVICE = "DEVICE",
    VPN_CONN = "VPN_CONN",
    APP = "APP",
    DEVICE_BY_CLIENTS = "DEVICE_BY_CLIENTS",
    DEVICE_BY_CONN_FAILURES = "DEVICE_BY_CONN_FAILURES",
    SSID_BY_CONN_FAILURES = "SSID_BY_CONN_FAILURES"

}

export class LossAndLatencyReports extends BaseDeviation {
    total: number;
    thresholds: LossLatencyThresholds;
}

export class LossLatencyThresholds {
    CRITICAL: number;
    HIGH: number;
    INFO: number
}

export class TopEntity<T> {
    id: number;
    name: string;
    type: EntityType;
    value: string | number;
    unit: string;
    entityDetails: T
}

