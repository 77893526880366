import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddRemoveSpinClassDirective} from './directives/add-remove-spin-class.directive';
import {ClassByConditionDirective} from './directives/class-by-condition.directive';
import {RouterLinkDirective} from './directives/router-link-directive';
import {SyncIconColorDirective} from './directives/sync-icon-color.directive';
import {MarkClickedDirective} from './directives/mark-clicked.directive';
import {ViewModeDirective} from './components/legacy-editable-text/view-mode.directive';
import {EditModeDirective} from './components/legacy-editable-text/edit-mode.directive';
import {TeleportToDirective} from './directives/teleport-to.directive';
import {StylePropertiesDirective} from './components/properties/style-properties.directive';
import {SpinnerDirective} from './directives/spinner.directive';
import {ExportToExcelDirective} from './directives/export-to-excel.directive';
import {horizontalScrollDirective} from "./directives/horizontal-scroll.directive";
import {TimeBackAgoDirective} from './directives/time-back-ago.directive';
import {FromToDirective} from './directives/from-to.directive';
import {CheckboxClassDirective} from './components/checkbox-search-list/checkbox-class.directive';
import {ControlErrorDirectiveDirective} from "./directives/control-error-directive.directive";
import {ResizableDirective} from "./directives/resizable.directive";

const directives = [
  AddRemoveSpinClassDirective,
  ClassByConditionDirective,
  RouterLinkDirective,
  SyncIconColorDirective,
  MarkClickedDirective,
  ViewModeDirective,
  EditModeDirective,
  TeleportToDirective,
  StylePropertiesDirective,
  SpinnerDirective,
  ExportToExcelDirective,
  horizontalScrollDirective,
  TimeBackAgoDirective,
  FromToDirective,
  CheckboxClassDirective,
  ControlErrorDirectiveDirective,
  ResizableDirective
]

@NgModule({
  declarations: [directives],
  imports: [
    CommonModule
  ],
  exports: [directives]
})
export class SharedDirectivesModule {
}
