export enum InsightResolutionActions {
  ANALYZE = 'analyze',
  FIX = 'fix'
}

export namespace InsightResolutionActions {
  export function toArray(): Array<InsightResolutionActionsMode> {
    return [
      {
        isDisabled: true, action: InsightResolutionActions.FIX
      }
    ]
  }
}

export interface InsightResolutionActionsMode {
  isDisabled: boolean;
  action: InsightResolutionActions;
}
