<div class="selection-tree-container selection-tree-text">
  <mat-tree [showSpinner]="isLoading" [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node"
                   [ngClass]="{'disabled-node': !isNodeEnabled(node)}"
                   [style.padding-left.px]="node.level > 0 ? 15 * node.level : 0">
      <div class="selection-tree-child-node selection-tree-node">
        <button mat-icon-button disabled class="tree-disabled-button">
        </button>
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                      [disabled]="!isNodeEnabled(node)" (change)="itemLeafItemSelectionToggle(node)">
          {{node.item}}
        </mat-checkbox>
        <span [title]="node.name | camelToUpper" class="tree-leaf-label span-text">
          {{node.name | camelToUpper}}
        </span>
      </div>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                   [ngClass]="{'tree-node-parent-column': hasSearch(node)}"
                   [style.padding-left.px]="node.level > 0 ? 15 * node.level : 0">
      <div>
        <div class="tree-parent-header selection-tree-node">
          <button mat-icon-button disabled class="tree-disabled-button"></button>
          <img matTreeNodeToggle mat-list-icon [src]="getExpanderIcon(node)" [alt]="node.name" class="selection-tree-expand-icon" (click)="onExpansionClick(node)">
          <span [title]="node.name | camelToUpper" class="tree-leaf-label span-text">{{node.name | camelToUpper}}</span>
        </div>
        <div *ngIf="isNodeLoading(node)" [showSpinner]="true" [diameter]="30" class="node-loading-container"></div>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
