import {CloudNode, HealthDegradationReason} from './cloud-network.model';
import {ClientDevice} from './clients.model';
import {SingleDevice} from './single-device.model';
import {SwitchStack} from "./devices.model";

export class Topology<T, S> {
  nodes: T[];
  links: S[];
  switchStacks?: SwitchStack[];
}

export class GenericDevice<T> {
  id: number | string;
  name: string;
  type: any;
  originalData: T;
  isZipMode?: boolean = false;
  isToBeZipped?: boolean = false;
  isZipper?: boolean= false;

  constructor(id: number | string, type: any, name: string, nodeOriginalData: T) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.originalData = nodeOriginalData;
  }

  iconPath?: string;
  secondaryIcon? : string;
  parent_id?: number | string;
  children?: GenericDevice<T>[];
  x?: number;
  y?: number;

  /**
   * @method convertTypeToGrouped Return the plural version of the client type
   * @param type The current element type
   * @param enumArray The Entire enum array
   */
  public static convertTypeToGrouped(type: any, enumArray: any) {
    return enumArray['Multi' + type.toUpperCase()];
  }

  /**
   * @method convertTypeToGrouped Return the plural version of the client type
   */
  public static InitlitzeName(type: any) {
    return type.toString();
  }
}

export class BasicTopologyNode {
  health?: number;
  issues?: Array<string>;
  healthDegradationReasons?: Array<HealthDegradationReason>;
  lastKpiUpdate?: number;
}

export type NodeEntities = ClientDevice | SingleDevice | CloudNode;

export class SingleD3Node<T> {
  data: T;
  height?: number;
  depth?: number;
  parent?: SingleD3Node<T>;
  children?: SingleD3Node<T>[];
  x?: number;
  y?: number;
}

export class NodeEventData<T> {
  d: SingleD3Node<T>;
  event: MouseEvent;
}
