import { Injectable } from '@angular/core';
import { ClientDevice, ConnectedClientType, RssiLabels, rssiClientsData, rssiClientTable, rssiIconData } from '../shared/models/clients.model';
import { TimeManagerService } from '../shared/services/time-manager.service';
import { GenericDevice } from '../shared/models/topology';
import { ICONS_MULTI_CLIENTS_PATH, GREEN_RSSI, YELLOW_RSSI, RED_RSSI, CLIENTS_TOOLTIP_DOT, UNKNOWN_ICON_PATH } from 'src/app/config/path';
import { ClientTooltipContent } from '../shared/components/network-topology/tooltips/topology-device-tooltip/base-topology-tooltip';



@Injectable({
  providedIn: 'root'
})
export class ClientsTooltipDataService {

  tooltipContent: ClientTooltipContent[];

  constructor(private dateConversionService: TimeManagerService) { }

  prepareMultiClientsTable(rssiBarData: rssiClientsData): rssiClientTable[] {
    let clientsTable: rssiClientTable[] = [];
    let numberOfRows = this.findHighestClientPerColor(rssiBarData);
    for (let i = 0; i < numberOfRows; i++) {
      clientsTable.push({
        [RssiLabels.GREEN]: rssiBarData[RssiLabels.GREEN].clients[i] ? {
          key: rssiBarData[RssiLabels.GREEN].clients[i].originalData.clientName,
          value: rssiBarData[RssiLabels.GREEN].clients[i].originalData.connectionProperties.rssi + " db"
        } : { key: "", value: "" },
        [RssiLabels.YELLOW]: rssiBarData.yellow.clients[i] ? {
          key: rssiBarData[RssiLabels.YELLOW].clients[i].originalData.clientName,
          value: rssiBarData[RssiLabels.YELLOW].clients[i].originalData.connectionProperties.rssi + " db"
        } : { key: "", value: "" },
        [RssiLabels.RED]: rssiBarData[RssiLabels.RED].clients[i] ? {
          key: rssiBarData[RssiLabels.RED].clients[i].originalData.clientName,
          value: rssiBarData[RssiLabels.RED].clients[i].originalData.connectionProperties.rssi + " db"
        } : { key: "", value: "" },
      })
    }
    return clientsTable;
  }

  findHighestClientPerColor(rssiBarData: rssiClientsData) {
    let length = 0;
    RssiLabels.getRssilabelsAsArray().forEach(label => {
      if (rssiBarData[label].clients.length > length)
        length = rssiBarData[label].clients.length;
    })
    return length;
  }

  prepareDataForSingelClient(data: ClientDevice): ClientTooltipContent[] {
    this.tooltipContent = [];
    this.tooltipContent = [
      { key: "Client Name", value: data.clientName },
      { key: "Signal", value: data.connectionProperties.rssi + "db" },
      { key: "Mac", value: data.mac },
      { key: "Is Online", value: data.online },
      {
        key: "Sent", value: data.connectionProperties.sent ?
          data.connectionProperties.sent + "KB" :
          'N/A'
      },
      {
        key: "received", value: data.connectionProperties.received ?
          data.connectionProperties.received + "KB" :
          'N/A'
      },
      { key: "ssid", value: data.connectionProperties.ssid },
      { key: "vlan", value: data.connectionProperties.vlan },
      { key: "First Seen", value: this.dateConversionService.dateByFormat(data.firstSeen,'DD-MMM-YYYY HH:mm') },
      { key: "Last Seen", value: this.dateConversionService.dateByFormat(data.lastSeen, 'DD-MMM-YYYY HH:mm') },
    ]

    return this.tooltipContent;
  }

  countClientByRssi(clients: GenericDevice<ClientDevice>[]) {
    let _rssiBarData = {
      [RssiLabels.GREEN]: { count: 0, clients: [] },
      [RssiLabels.YELLOW]: { count: 0, clients: [] },
      [RssiLabels.RED]: { count: 0, clients: [] }
    };
    clients.forEach(client => {
      let rssi = client.originalData.connectionProperties.rssi;
      if (rssi >= -80 && rssi <= 0) {
        _rssiBarData[RssiLabels.GREEN].count++;
        _rssiBarData[RssiLabels.GREEN].clients.push(client);
      };
      if (rssi < -80 && rssi >= -100) {
        _rssiBarData[RssiLabels.YELLOW].count++;
        _rssiBarData[RssiLabels.YELLOW].clients.push(client);
      }
      if (rssi < -100) {
        _rssiBarData[RssiLabels.RED].count++;
        _rssiBarData[RssiLabels.RED].clients.push(client);
      }
    })
    return _rssiBarData
  }

  initiateIconPaths(clientsType: ConnectedClientType, rssiBarData: rssiClientsData): rssiIconData[] {
    let iconsPaths: rssiIconData[] = [];
    RssiLabels.getRssilabelsAsArray().forEach(label => {
      iconsPaths.push({
        iconColor: RssiLabels[label.toUpperCase()],
        numberOfClients: rssiBarData[RssiLabels[label.toUpperCase()]].count,
        iconPath: this.getIconPath(ConnectedClientType.getLowerCaseSingleClientDeviceType(clientsType), RssiLabels[label.toUpperCase()]),
        dotColorPath: CLIENTS_TOOLTIP_DOT + this.getColorPath(label)
      });
    });
    return iconsPaths;
  }

  getIconPath(clientType: string, color: RssiLabels) {
    if (clientType == "unknown")
      return UNKNOWN_ICON_PATH;
    return ICONS_MULTI_CLIENTS_PATH + clientType + this.getColorPath(color);
  }

  getColorPath(color: RssiLabels) {
    switch (color) {
      case RssiLabels.GREEN:
        return GREEN_RSSI
      case RssiLabels.YELLOW:
        return YELLOW_RSSI
      case RssiLabels.RED:
        return RED_RSSI
      default:
        break;
    }
  }


}
