<div class="search-menu-box px-0{{cssClass ? ' ' + cssClass: ''}}" [style.min-height]="height"
     [style.border]="'1px solid #d8dce6'">
  <div>
    <div class="search-input" [style.width]="'100%'">
      <mat-form-field class="search-menu-box-search">
        <input matInput [(ngModel)]="searchInput" type="search" [placeholder]="'data.COMMON.SEARCH' | translate"
               [style.margin-left.px]="5" autocomplete="off">
        <mat-icon [inlineSVG]="searchIconPath" class="mat-icon" [style.margin-right.px]="5"></mat-icon>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="display">
    <mat-list *ngIf="!dragAndDrop">
      <ng-container *ngFor="let option of filteredDisplay">
        <mat-list-item [ngClass]="getMenuClass(option)" (click)="onOptionSelected(option)">
          <ng-container [ngTemplateOutlet]="listItem"
                        [ngTemplateOutletContext]="{label: option.label, excerpt: option.excerpt}"></ng-container>
        </mat-list-item>
      </ng-container>
    </mat-list>

    <mat-list *ngIf="dragAndDrop" cdkDropList [id]="dropListId" [cdkDropListData]="display"
              [cdkDropListConnectedTo]="dropListConnectedTo" class="drop-list" [style.height]="height"
              (cdkDropListDropped)="onOptionDropped($event)">
      <ng-container *ngFor="let option of filteredDisplay">
        <mat-list-item cdkDrag [ngClass]="getMenuClass(option)" class="drop-item"
                       (mousedown)="onOptionSelected(option)" (cdkDragStarted)="onOptionDragged(option)">
          <ng-container [ngTemplateOutlet]="listItem"
                        [ngTemplateOutletContext]="{label: option.label, excerpt: option.excerpt}"></ng-container>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </ng-container>
</div>

<ng-template #listItem let-label="label" let-excerpt="excerpt">
  <ng-container *ngIf="excerpt">
    <div class="search-menu-box-option-name col px-0">{{label}}</div>
    <div class="search-menu-box-option-excerpt">{{excerpt}}</div>
  </ng-container>
  <span *ngIf="!excerpt" class="search-menu-box-option-name-no-excerpt">{{label}}</span>
</ng-template>
