import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';


import { AgTableComponent } from './ag-table/ag-table.component';
import { PriorityCellRenderer } from './cell-renderers/priority-cell-renderer/priority-cell-renderer.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { CellRendererSvgIconComponent } from './cell-renderers/cell-renderer-svg-icon/cell-renderer-svg-icon.component';
import { CellRendererCellWithDetailsComponent } from './cell-renderers/cell-renderer-cell-with-details/cell-renderer-cell-with-details.component';
import { SeverityCellRendererComponent } from './cell-renderers/severity-cell-renderer/severity-cell-renderer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionsMenuRendererComponent } from './cell-renderers/actions-menu-renderer/actions-menu-renderer.component';
import { MatMenuModule } from '@angular/material/menu';
import { IssuesCategoryRendererComponent } from './cell-renderers/issues-category-renderer/issues-category-renderer.component';
import { TextTooltipCellRendererComponent } from './cell-renderers/text-tooltip-cell-renderer/text-tooltip-cell-renderer.component';
import { HealthCellRendererComponent } from './cell-renderers/health-cell-renderer/health-cell-renderer.component';
import { TranslateModule } from '@ngx-translate/core';
import { SyncChangesRendererComponent } from './cell-renderers/sync-changes-renderer/sync-changes-renderer.component';
import { AnalyzeCellRendererComponent } from './cell-renderers/analyze-cell-renderer/analyze-cell-renderer.component';
import { TimeBarRendererComponent } from './cell-renderers/time-bar-renderer/time-bar-renderer.component';
import { SharedComponentsModule } from '../shared-components.module';
import { MenuOptionsComponent } from './cell-renderers/actions-menu-renderer/menu-options/menu-options.component';
import { TooltipAsCellRendererComponent } from './cell-renderers/tooltip-as-cell-renderer/tooltip-as-cell-renderer.component';
import { SparklineRendererComponent } from './cell-renderers/sparkline-renderer/sparkline-renderer.component';
import { IconWithTextRendererComponent } from './cell-renderers/icon-with-text-renderer/icon-with-text-renderer.component';
import { NoRowsOverlayComponent } from "./cell-renderers/no-rows-overlay/no-rows-overlay.component";
import { AnomalyFromToCellRendererComponent } from './cell-renderers/anomaly-from-to-cell-renderer/anomaly-from-to-cell-renderer.component';
import { AnalyzePopOverComponent } from './cell-renderers/analyze-pop-over/analyze-pop-over.component';
import { NetopDialogModule } from "../modals/netop-dialog/netop-dialog.module";
import { PolicyCellRendererComponent } from "./cell-renderers/allow-deny-cell-renderer/policy-cell-renderer.component";
import { FromToCellRendererComponent } from './cell-renderers/from-to-cell-renderer/from-to-cell-renderer.component';
import { SharedDirectivesModule } from '../shared-directives.module';
import { BarCellRendererComponent } from "./cell-renderers/bar-cell-renderer/bar-cell-renderer.component";
import { IconCellRendererComponent } from "./cell-renderers/icon-cell-renderer/icon-cell-renderer.component";
import { MenuCellRendererComponent } from "./cell-renderers/menu-cell-renderer/menu-cell-renderer.component";
import { MatChipsModule } from '@angular/material/chips';
import { ChipsCellRendererComponent } from './cell-renderers/chips-cell-renderer/chips-cell-renderer.component';

const rendererComponents = [
  PriorityCellRenderer,
  CellRendererSvgIconComponent,
  CellRendererCellWithDetailsComponent,
  SeverityCellRendererComponent,
  ActionsMenuRendererComponent,
  IssuesCategoryRendererComponent,
  TextTooltipCellRendererComponent,
  HealthCellRendererComponent,
  SyncChangesRendererComponent,
  AnalyzeCellRendererComponent,
  TimeBarRendererComponent,
  MenuOptionsComponent,
  TooltipAsCellRendererComponent,
  SparklineRendererComponent,
  IconWithTextRendererComponent,
  NoRowsOverlayComponent,
  AnomalyFromToCellRendererComponent,
  AnalyzePopOverComponent,
  PolicyCellRendererComponent,
  FromToCellRendererComponent,
  BarCellRendererComponent,
  IconCellRendererComponent,
  MenuCellRendererComponent,
  ChipsCellRendererComponent
]
@NgModule({
  declarations: [
    AgTableComponent,
    ...rendererComponents
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    InlineSVGModule,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    SharedComponentsModule,
    NetopDialogModule,
    SharedDirectivesModule,
    AgGridModule
  ],

  exports: [
    AgGridModule,
    AgTableComponent,
    MatChipsModule,
    ...rendererComponents
  ],
})
export class AgGridTablesModule {
}
