<div *ngIf="formGroup" [formGroup]="formGroup" class="time-picker">
  <div class="time-picker-col time-picker-hour">
    <mat-form-field>
      <ng-container *ngIf="formGroup.contains('hour')"></ng-container>
      <mat-select formControlName="hour">
        <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="time-picker-col time-picker-minute">
    <mat-form-field>
      <ng-container *ngIf="formGroup.contains('minute')">
        <mat-select formControlName="minute">
          <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>
  </div>
</div>
