import {Component} from '@angular/core';
import {INoRowsOverlayAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './no-rows-overlay.component.html',
  styleUrls: ['./no-rows-overlay.component.scss']
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  params: any;

  agInit(params): void {
    this.params = params;
  }
}
