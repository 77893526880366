import { Injectable } from '@angular/core';
import { TimeAxis, SparklineMarker } from '../models/sparkline-config';
import { findClosetEarlierDate, calculateDaysNumberBetweenTwoDates } from 'src/app/shared/operators/time-operator';

@Injectable({
  providedIn: 'root'
})
export class SparklineService {

  constructor() { }

  /**
   * Return the index of the first closet date to the marker
   * The method convert the moment to date object and uses the findClosetEarlierDate operator
   */
  findMarkerIndex(data: TimeAxis[], marker: SparklineMarker): number {
    if (marker && marker.date)
      return findClosetEarlierDate(data.map(item => item.x.toDate()), marker.date.toDate());
    return -1;
  }

  /**
   * Calculate time unit of given time series
   */
  calculateTimeUnit(timeSeries: TimeAxis[]): "hour" | "day" | "week" | "month" {
    if (timeSeries.length > 0) {
      let startDate: Date = (timeSeries[0].x instanceof Date) ? timeSeries[0].x : timeSeries[0].x.toDate();
      let endDate: Date = (timeSeries[0].x instanceof Date) ? timeSeries[0].x : timeSeries[0].x.toDate();
      timeSeries.forEach(point => {
        let pointDate = (point.x instanceof Date) ? point.x : point.x.toDate();
        if (pointDate < startDate) {
          startDate = pointDate;
        }
        if (pointDate > endDate) {
          startDate = pointDate;
        }
      })
      let numberOfDays = calculateDaysNumberBetweenTwoDates(endDate, startDate);
      switch (true) {
        case numberOfDays < 1:
          return "hour";
        case (numberOfDays > 1 && numberOfDays <= 7):
          return "day";
        case (numberOfDays > 7 && numberOfDays <= 30):
          return "week";
        case numberOfDays > 30:
          return "month";
        default:
          break;
      }
    }
  }
}
