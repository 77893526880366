import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return value
        }
        if (value.length) {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        }
        return null;
    }

}
