import {BaseFilterBarService} from "./base-filter-bar-service.model";
import {GlobalEntitiesService} from "../../../services/rest-services/global-entities.service";
import {OrganizationsService} from "../../../services/rest-services/organizations.service";
import {VenuesService} from "../../../services/rest-services/venues.service";
import {FilterData, FiltersBar} from "./filters-bar.model";
import {ChangeDetectorRef, Directive} from "@angular/core";
import {EntityType} from "../../../models/entity-type.enum";
import {
  ActionsListEntitiesQueryParamsEnum,
  ActionsListQueryParamsEnum, ActionsListQueryParamValue,
  QueryParamValue
} from "../../../../features/anomalies/components/main-actions-dashboard/models/actions-list-query-params.model";
import {take} from "rxjs/operators";
import {Entity} from "../../../models/entity.model";
import {VenueSummary} from "../../../models/venues.model";
import {TenantSummaryDTO} from "../../../models/tenants.model";
import {OrganizationDetails} from "../../../models/organizations.model";

@Directive()
export class BaseEntitiesFilter extends BaseFilterBarService {

  constructor(
    protected globalEntitiesService: GlobalEntitiesService,
    protected organizationService: OrganizationsService,
    protected venuesService: VenuesService,
  ) {
    super(globalEntitiesService, organizationService, venuesService);
  }

  /**
   * Refresh all entities filter data based on selected entity
   */
  refreshEntitiesFilters(actionsFilters: FiltersBar<any, any>[], item: FilterData<any, any>, cdr: ChangeDetectorRef) {
    if (item && item.type !== EntityType.ORGANIZATION && item.type !== EntityType.TENANT) {
      return;
    }
    if (item && item.filterName == ActionsListQueryParamsEnum.Tenant) {
      const venueFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Venue);
      if (venueFilter) {
        venueFilter.data = [];
        if (item.data) {
          venueFilter.isDisableSelection = false;
          this.venuesService.fetchVenuesSummaryByTenantId(item.data).pipe(take(1)).subscribe(venues => {
            this.enterDataIntoFilter(venues, venueFilter, EntityType.VENUE, cdr);
          });
        } else if (item.isFakeItem) {
          venueFilter.addSelectAllOption();
          venueFilter.isDisableSelection = true;
        }
      }
    }
    if (item.filterName == ActionsListQueryParamsEnum.Organization) {
      const tenantFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Tenant);
      const venueFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Venue);
      if (venueFilter) {
        venueFilter.data = [];
        venueFilter.isDisableSelection = true;
        venueFilter.addSelectAllOption();
      }
      if (tenantFilter) {
        tenantFilter.data = [];
        if (item.data) {
          this.organizationService.fetchTenantsById(item.data).pipe(take(1)).subscribe(tenants => {
            this.enterDataIntoFilter(tenants, tenantFilter, EntityType.TENANT, cdr);
          });
        } else if (item.isFakeItem) {
          tenantFilter.addSelectAllOption();
        }
      }
    }
  }

  /**
   * Check if there is need to add properties to the selected item
   * i.e., in case of entity selection, check if there is need to empty other entities
   */
  getChangedParams(filter: FiltersBar<any, any>, item: FilterData<any, any>): QueryParamValue {
    if (!this.isHighEntitiesFilter(item.filterName)) {
      return {[item.filterName]: item.data};
    } else {
      if (item.filterName == ActionsListQueryParamsEnum.Organization) {
        return {
          [item.filterName]: item.data ? item.data : '',
          [ActionsListQueryParamsEnum.Tenant]: '',
          [ActionsListQueryParamsEnum.Venue]: '',
        };
      }
      if (item.filterName == ActionsListQueryParamsEnum.Tenant) {
        return {
          [item.filterName]: item.data ? item.data : '',
          [ActionsListQueryParamsEnum.Venue]: '',
        };
      }
    }
  }

  /**
   * Fill the filter with the list for each entity
   */
  initiateFiltersWithData(
    filters: FiltersBar<any, any>[],
    entitiesData: (
      { Organization: OrganizationDetails[]; Tenant: TenantSummaryDTO[]; } | { venues: VenueSummary[] }) | ({ Organization: OrganizationDetails[]; Tenant: TenantSummaryDTO[]; Venue: VenueSummary[] } | { Venue: VenueSummary[] }),
    currentEntity: Entity,
    entityForSelection: Entity
  ) {
    Object.keys(entitiesData).forEach(key => {
      if (entitiesData[key] && entitiesData[key].length > 0) {
        const filterName: ActionsListQueryParamsEnum = this.getFilterNameByEntityData(entitiesData[key][0]);
        const entityType: EntityType = this.getEntityTypeByFilterType(filterName);
        const currentFilter = filters.find(filter => filter.name == filterName);
        if (currentFilter !== undefined) {
          entitiesData[key].forEach((entity, index) => {
            currentFilter.data.push({
              id: index + 1,
              name: entity.name,
              type: entityType,
              data: entity.id,
              filterName,
              isSelected: this.isMarkEntityAsSelected(entity, entityType, currentEntity, entityForSelection)
            });
          });
        }
      }
    });
  }

  /**
   * Return filter name by the current entity
   */
  getFilterNameByEntityData(entity: OrganizationDetails | TenantSummaryDTO | VenueSummary): ActionsListQueryParamsEnum {
    switch (true) {
      case entity.hasOwnProperty('tenants'):
        return ActionsListQueryParamsEnum.Organization;
      case entity.hasOwnProperty('numOfVenues'):
        return ActionsListQueryParamsEnum.Tenant;
      default:
        break;
    }
    return ActionsListQueryParamsEnum.Venue;
  }

  getEntityFromFilters(params: ActionsListQueryParamValue): Entity {
    let entity: Entity;
    Object.keys(params).forEach(key => {
      if (params[key] && Object.values(ActionsListEntitiesQueryParamsEnum).includes(key as ActionsListEntitiesQueryParamsEnum)) {
        const currentEntity: Entity = {
          id: params[key],
          name: '',
          type: this.getEntityTypeByFilterType(key as ActionsListQueryParamsEnum)
        };
        if (!entity || entity && EntityType.isEntityTypeHigher(currentEntity.type, entity.type)) {
          entity = currentEntity;
        }
      }
    });
    return entity;
  }

  /**
   * Return true if the current filter contain Organizations or tenants data
   */
  isHighEntitiesFilter(filterName: string) {
    return filterName == ActionsListQueryParamsEnum.Organization ||
      filterName == ActionsListQueryParamsEnum.Tenant;
  }

  /**
   * Check if one of the filters lists are equal to one of the current entities, in order to mark them as selected
   */
  isMarkEntityAsSelected(
    entity: OrganizationDetails | TenantSummaryDTO | VenueSummary,
    entityType: EntityType,
    currentEntity: Entity,
    entityForSelection: Entity,
  ): boolean {
    return (entityForSelection && currentEntity && entityType == entityForSelection.type && entity.id == entityForSelection.id) || entityType == currentEntity.type && entity.id == currentEntity.id;
  }

  /**
   * Return entity type by entity filter
   */
  getEntityTypeByFilterType(filterName: ActionsListQueryParamsEnum): EntityType {
    switch (filterName) {
      case ActionsListQueryParamsEnum.Organization:
        return EntityType.ORGANIZATION;
      case ActionsListQueryParamsEnum.Tenant:
        return EntityType.TENANT;
      case ActionsListQueryParamsEnum.Venue:
        return EntityType.VENUE;
      default:
        break;
    }
  }

  /**
   * Get an array of entities with filter and current entity type and assign the filter.data array with data
   */
  enterDataIntoFilter(entities: VenueSummary[] | TenantSummaryDTO[], filter: FiltersBar<any, any>, entityType: EntityType, cdr: ChangeDetectorRef) {
    entities.forEach((entity, index) => {
      filter.data.push({
        id: index + 1,
        name: entity.name,
        type: entityType,
        data: entity.id,
        filterName: filter.name,
      });
    });
    filter.addSelectAllOption();
    cdr.detectChanges();
  }
}
