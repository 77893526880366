import { GridSortFilter } from './grid-sort-filter.model';
import { IGetRowsParams } from 'ag-grid-community';
import { QueryParamValue } from '../../../features/anomalies/components/main-actions-dashboard/models/actions-list-query-params.model';

export class SortFilterParams extends GridSortFilter {
    constructor(
        params?: IGetRowsParams,
        searchText: string = undefined,
        numberOfRawsInPage?: number,
        paramName?: string,
        queryParams?: QueryParamValue
    ) {
        super(params, searchText, numberOfRawsInPage, paramName, queryParams);
    }
}
