import {ChartTooltipExtensions} from './chart-tooltip-extensions';

export class SparklineTooltip extends ChartTooltipExtensions {

  constructor() {
    super()
  }

  /**
   * Add custom tooltip to the chart.
   * The method sets the tooltip as an dom element that will be added to the dom.
   * In addition, it will set the tooltip's content, styling and positioning
   * Inspired by: https://stackblitz.com/edit/chart-js-html-tooltip
   */
  customSparklineTooltips = (context) => {
    const {chart, tooltip} = context;
    this.chartObj = chart;
    if (this.chartObj) {
      let tooltipEl = this.setCustomTooltipStyling(tooltip, this.chartObj);
      this.setChartCustomTooltipPosition(tooltipEl, tooltip);
    }
  };

  /**
   * Open default tooltip pointer, if givven. Uses the animation => onComplete, chart js life cycle.
   * @param index The index of the dataset that will be marked by the tooltip (selected by date)
   * @param currentObj The "this" object
   */
  openDefaultTooltip(index: number, currentObj: any) {
    // if (currentObj && (currentObj.sparklineConfig as SparklineConfig) .lineConfig.length > 0) {
    setTimeout(() => {
      let mouseMoveEvent, meta, point, rectangle;
      meta = currentObj.sparkline.getDatasetMeta(0);
      rectangle = currentObj.sparkline.canvas.getBoundingClientRect();
      if (meta.data[index]) {
        point = meta.data[index].getCenterPoint();
        mouseMoveEvent = new MouseEvent('mousemove', {
          clientX: rectangle.left + point.x,
          clientY: rectangle.top + point.y
        });
        currentObj.sparkline.canvas.dispatchEvent(mouseMoveEvent);
      }
    }, 200);
    //}
  }
}
