import {ChartTooltipExtensions} from './chart-tooltip-extensions';
import {ChartJsTooltip, ChartJStooltipDirection} from './chartjs-tooltip.model';
import {Chart, TooltipItem, TooltipModel} from "chart.js";

export class VerticalBarTooltip extends ChartTooltipExtensions {
  direction: ChartJStooltipDirection;
  isWithArrowDisplay: boolean = false;

  /**
   * The stacked bar object. will be generated at the end of the לֹnew chart object creation at app-stacked-bar
   */

  constructor(direction: ChartJStooltipDirection, isWithArrowDisplay?: boolean, minWidth: number = 250) {
    super();
    this.isWithArrowDisplay = isWithArrowDisplay;
    this.minWidth = minWidth;
  }

  customVerticalTooltips = (context) => {
    const {chart, tooltip} = context;
    this.chartObj = chart;
    if (this.chartObj) {
      const tooltipEl = this.setCustomTooltipStyling(tooltip, chart, this.minWidth);
      if (this.isWithArrowDisplay) {
        this.setChartCustomTooltipPosition(tooltipEl, tooltip);
      } else {
        this.setCustomTooltipPosition(tooltipEl, tooltip, chart);
      }
    }
  };

  /**
   * Set the stacked bar custom tooltip position
   */
  private setCustomTooltipPosition(tooltipEl: HTMLElement, tooltip: ChartJsTooltip, tooltipThis: any) {
    if (tooltipEl) {
      this.tooltipActualDirection = this.direction;
      tooltipEl.style.top = `${tooltip.caretY - tooltip.height}px`;
      tooltipEl.style.left = `${tooltip.caretX}px`;
      tooltipEl.classList.add(this.tooltipElArrowClass + "-" + this.tooltipActualDirection);
    }
  }


  /**
   * For Justifying the tooltip to the right, the method is calculating the center of the tooltip and its padding,
   * and subtract it from the tooltip.caretX
   * If Calculation suggests that the tooltip will be off scale, it changes the calculation for positive values
   */
  protected setTooltipToTheLeft(tooltip: ChartJsTooltip, tooltipEl: HTMLElement, arrowWidth: number): number {
    this.tooltipActualDirection = 'left';
    let tooltipWidth = tooltipEl.offsetWidth + arrowWidth
    let positionCorrectX = -tooltipWidth / 2 - tooltip.xPadding;
    let tooltipRealXposition = tooltip.caretX - this.chartObj.scales["y-axis-0"].right;
    if (tooltipRealXposition < tooltipWidth) {
      positionCorrectX = tooltipWidth / 2 - tooltip.xPadding;
      this.tooltipActualDirection = 'right';
    }
    return positionCorrectX;
  }

  /**
   * For Justifying the tooltip to the left, the method is calculating the center of the tooltip and its padding,
   * and add it from the tooltip.caretX
   * If Calculation suggests that the tooltip will be off scale, it changes the calculation for negative values
   */
  protected setTooltipToTheRight(tooltip: TooltipModel<any>, tooltipEl: HTMLElement, arrowWidth: number): number {
    this.tooltipActualDirection = 'right';
    let tooltipWidth = tooltipEl.offsetWidth;
    let positionCorrectX = tooltipWidth / 2 - +tooltip.options.padding;
    if (this.chartObj.width - tooltip.caretX + +tooltip.options.caretSize < tooltipWidth) {
      positionCorrectX = -tooltipWidth / 2 - +tooltip.options.padding;
      this.tooltipActualDirection = 'left';
    }
    return positionCorrectX;
  }
}
