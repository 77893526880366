import {TranslateService} from '@ngx-translate/core';
import {PropertiesContent} from '../properties/models/properties-content';
import {Subscription} from 'rxjs';
import {LoggerService} from '../../services/logger.service';
import {BaseSingleEntityService} from '../../services/base-single-entity.service';
import {SingleDevice} from "../../models/single-device.model";
import {VenueData} from "../../models/venues.model";

export class BaseHealthSingleEntities {
  propData: PropertiesContent;
  entityDetails: any;
  isLoading: boolean = false;
  subscription: Subscription[] = [];

  constructor(protected translate: TranslateService, protected loggerFactory: LoggerService, protected baseSingleEntityService: BaseSingleEntityService) {
  }

  isSameVenue(entityDetails: (VenueData | SingleDevice)) {
    return this.entityDetails && entityDetails &&
      (entityDetails.hasOwnProperty('venue') &&
        this.entityDetails.hasOwnProperty('venue') &&
        (entityDetails as VenueData).venue.id === (this.entityDetails as VenueData).venue.id);
  }
}
