import {Injectable} from '@angular/core';
import {FilterData, FiltersBar} from 'src/app/shared/components/filters-bar/models/filters-bar.model';
import {ActionCategory, ActionStatus} from 'src/app/shared/models/actions.model';
import {TranslateService} from '@ngx-translate/core';
import {FormFieldsType} from 'src/app/shared/modals/models/form-actions.model';
import {OrganizationsService} from 'src/app/shared/services/rest-services/organizations.service';
import {VenuesService} from 'src/app/shared/services/rest-services/venues.service';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {BaseFilterBarService} from 'src/app/shared/components/filters-bar/models/base-filter-bar-service.model';
import {CamelToUpperPipe} from 'src/app/shared/pipes/camel-to-upper.pipe';
import {
  ActionsListCommonParamsValue,
  ActionsListCommonQueryParamsEnum,
  ActionsListQueryParamsEnum,
  ActionsListQueryParamValue,
} from '../models/actions-list-query-params.model';
import {LegacySeverity} from "../../../../../shared/models/severity.model";
import {DashboardService} from "../../../../../shared/services/rest-services/dashboard.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ActionsFiltersStateStore} from "./actions-filters-state.service";
import {ActionTypeDescDTO} from "../../../../../shared/models/anomalies.model";

@Injectable({
  providedIn: 'root'
})
export class ActionsCommonFiltersService extends BaseFilterBarService {
  constructor(
    protected globalEntitiesService: GlobalEntitiesService,
    protected organizationService: OrganizationsService,
    protected venuesService: VenuesService,
    private actionsFiltersStateStore: ActionsFiltersStateStore,
    private translate: TranslateService,
    private camelToUpper: CamelToUpperPipe,
    private dashboardService: DashboardService
  ) {
    super(globalEntitiesService, organizationService, venuesService);
  }

  /**
   * Load common filters for all levels (Org, Tenant, Venue)
   */
  loadCommonFilters(viewName: string): Observable<FiltersBar<any, any>[]> {
    return this.dashboardService.fetchActionsTypes().pipe(
      map(actionTypes => {
        const filters: FiltersBar<any, any>[] = [
          new FiltersBar(ActionsListQueryParamsEnum.Severities, FormFieldsType.SELECT, this.getSeveritiesFilterData(viewName, ActionsListQueryParamsEnum.Severities), 'All Severities'),
          new FiltersBar(ActionsListQueryParamsEnum.ActionType, FormFieldsType.Multi_Select, this.getActionTypesFilterData(viewName, ActionsListQueryParamsEnum.ActionType, actionTypes), 'All Types'),
          new FiltersBar(ActionsListQueryParamsEnum.ActionCategory, FormFieldsType.SELECT, this.generateActionCategoriesFilterData(viewName, ActionsListQueryParamsEnum.ActionCategory), 'All Categories'),
          new FiltersBar(ActionsListQueryParamsEnum.ActionStatus, FormFieldsType.SELECT, this.generateActionStatusesFilterData(viewName, ActionsListQueryParamsEnum.ActionStatus), 'All statuses'),
        ];
        filters.forEach(bar => bar.addSelectAllOption());
        return filters;
      })
    );
  }

  getSeveritiesFilterData(viewName: string, filterName: string): FilterData<string, string>[] {
    return LegacySeverity.toArray().map((severity, index) => ({
      id: index + 1,
      name: severity,
      type: severity,
      data: severity,
      filterName: filterName,
      isSelected: this.actionsFiltersStateStore.stateContainsValue(viewName, filterName, severity)
    }));
  }

  generateActionStatusesFilterData(viewName: string, filterName: string): FilterData<string, ActionStatus>[] {
    const filtersData: FilterData<string, ActionStatus>[] = [];
    ActionStatus.getActionStatusesAsArray().forEach((status, index) => {
      if (status) {
        filtersData.push({
          id: index + 1,
          name: this.translate.instant(ActionStatus.getActionStatusName(ActionStatus[status])),
          type: status,
          data: status,
          filterName: filterName,
          isSelected: this.actionsFiltersStateStore.stateEqualsValue(viewName, filterName, status)
        });
      }
    });
    return filtersData;
  }

  generateActionCategoriesFilterData(viewName: string, filterName: string): FilterData<string, ActionCategory>[] {
    const filtersData: FilterData<string, ActionCategory>[] = [];
    ActionCategory.getCategoriesAsArray().forEach((category, index) => {
      filtersData.push({
        id: index + 1,
        name: ActionCategory.getCategoryName(category),
        type: ActionCategory[category],
        data: category,
        filterName: filterName,
        isSelected: this.actionsFiltersStateStore.stateEqualsValue(viewName, filterName, category)
      });
    });
    return filtersData;
  }

  getActionTypesFilterData(viewName: string, filterName: string, actionTypes: ActionTypeDescDTO[]): FilterData<string, string>[] {
    const filtersData: FilterData<string, string>[] = [];
    if (actionTypes) {
      actionTypes
        .forEach((actionType, index) => {
          filtersData.push({
            id: index + 1,
            name: actionType.value,
            type: actionType.value,
            data: actionType.value,
            filterName: filterName,
            isSelected: this.actionsFiltersStateStore.stateContainsValue(viewName, filterName, actionType.value)
          });
        });
    }
    return filtersData;
  }

  getCommonFiltersParams(queryParams: ActionsListQueryParamValue): ActionsListCommonParamsValue {
    let commonFilters: ActionsListCommonParamsValue = {};
    Object.keys(queryParams).forEach(key => {
      if (Object.values(ActionsListCommonQueryParamsEnum).includes(key as ActionsListCommonQueryParamsEnum)) {
        const value = queryParams[key];
        commonFilters[key] = value ? value : '';
      }
    });
    return commonFilters;
  }
}
