<ngx-select #venuesSelect [items]="venuesOptions" [formControl]="venuesFormControl" optionTextField="name" optionValueField="id"
            [placeholder]="placeholder" [keepSelectMenuOpened]="true"
            [keepSelectedItems]="false" [autoClearSearch]="true" [multiple]="true"
            (close)="onVenuesSelectionClosed(venuesSelectControl.optionsSelected)"
            (change)="onVenuesSelectionChanged(venuesSelectControl.optionsSelected)"
            (remove)="onVenuesSelectionRemoved(venuesSelectControl.optionsSelected)">
  <ng-template ngx-select-option let-option let-text="text">
    <mat-checkbox [value]="option.value" [checked]="isVenueChecked(option)">
      <h5 matLine [innerHTML]="text"></h5>
      <p matLine><small>{{option.data.description}}</small></p>
    </mat-checkbox>
  </ng-template>
</ngx-select>
