import { Component, OnInit } from '@angular/core';
import { TrafficOrigin } from '../../../../../../models/legacy-reports.model';

@Component({
  selector: 'app-device-connection-failure',
  templateUrl: './device-connection-failure.component.html',
  styleUrls: ['./device-connection-failure.component.scss']
})
export class DeviceConnectionFailureComponent implements OnInit {
  origin: TrafficOrigin = TrafficOrigin.DEVICE_BY_CONN_FAILURES;

  constructor() { }

  ngOnInit() {
  }

}
