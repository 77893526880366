<ng-container *ngIf="nodesWithoutFake?.length > 0 && linksWithoutFake?.length > 0">
  <span id="topologySqueezer" style="display: none" (click)="reDrawTreeInsideVisibleArea()"></span>
  <svg #d3TreeSvg [attr.viewBox]="treeViewBox" (click)="onSvgClick($event)">
    <g [appD3Zoomable]="d3TreeSvg">
      <g [linkVisual]="link" [treeConfiguration]="treeConfiguration" [selectedFabricTopology]="selectedFabricTopology"
         [topologyConfigruation]="topologyConfiguration" aria-label="link" *ngFor="let link of linksWithoutFake">
      </g>
      <g [nodeVisual]="node" [treeConfiguration]="treeConfiguration" [selectedFabricTopology]="selectedFabricTopology"
         [topologyConfiguration]="topologyConfiguration" [maxChildren]="maxChildrenNumber" [isSqueezable]="isSqueezable" aria-label="node"
         *ngFor="let node of nodesWithoutFake">
      </g>
    </g>
  </svg>
</ng-container>

