import {Injectable} from '@angular/core';
import {KPIChartJsAnnotation} from '../../../modals/deviation-reports-dialog/components/venue-deviation-reports-dialog/venue-deviation-reports-dialog.component';
import {
  ChartJsAnnotation,
  AnnotationLabelPosition
} from 'src/app/shared/components/chartjs-components/models/annotations.model';
import {KpiType} from 'src/app/shared/models/kpi.model';
import {drawArrow} from 'src/app/shared/operators/encoded-arrow';
import {Bandwidth} from 'src/app/shared/models/bandwidth.model';
import {AnomalySettingsDTO} from "../../../models/anomalies.model";
import {ActionSeverity} from "../../../models/action-severity.model";

@Injectable({
  providedIn: 'root'
})
export class ChartjsKpiAnnotationGeneratorService {

  constructor() {
  }

  getKpisAnnotations(defaultAnnotation: ChartJsAnnotation, annotations: KPIChartJsAnnotation, bandwidth: Bandwidth, lossSettings: AnomalySettingsDTO[], latencySettings: AnomalySettingsDTO[], colorsList: string[]): void {
    if (bandwidth) {
      const uplinkLabelPosition: AnnotationLabelPosition = "end";
      const downlinkLabelPosition: AnnotationLabelPosition = "center"

      // if (Math.abs((bandwidth.acquiredUplink - bandwidth.acquiredDownlink)) <= 100) {
      //   downlinkLabelPosition = "bottom";
      // }
      const highestBandwidth = bandwidth.acquiredUplink > bandwidth.acquiredDownlink ? bandwidth.acquiredUplink : bandwidth.acquiredDownlink;
      if (bandwidth.acquiredUplink) {
        let uplinkByTrafficUnit = bandwidth.acquiredUplink;
        let annoationUpLink: ChartJsAnnotation = this.addAnnotation(defaultAnnotation, uplinkByTrafficUnit, colorsList[11], `Bandwidth ${drawArrow("up")}`, uplinkLabelPosition, highestBandwidth + highestBandwidth * 0.125);
        annotations[KpiType.Throughput] = [
          annoationUpLink
        ]
      }
      if (bandwidth.acquiredDownlink) {
        let downlinkByTrafficUnit = bandwidth.acquiredDownlink;
        let annoationDownLink: ChartJsAnnotation = this.addAnnotation(defaultAnnotation, downlinkByTrafficUnit, colorsList[16], `Bandwidth ${drawArrow("down")}`, downlinkLabelPosition);
        if (annotations[KpiType.Throughput] && annotations[KpiType.Throughput].length > 0) {
          annotations[KpiType.Throughput].push(annoationDownLink);
        } else {
          annotations[KpiType.Throughput] = [
            annoationDownLink
          ]
        }
      }
    }
    //Add Loss and Latency annotations
    const lossCritical = +lossSettings.find(setting => setting.actionSeverity.toString() === ActionSeverity.Critical).properties.find(props => props.name === 'percent').value;
    const latencyCritical = +latencySettings.find(setting => setting.actionSeverity.toString() === ActionSeverity.Critical).properties.find(props => props.name === 'latency').value;
    const lossHigh = +lossSettings.find(setting => setting.actionSeverity.toString() === ActionSeverity.High).properties.find(props => props.name === 'percent').value;
    const latencyHigh = +latencySettings.find(setting => setting.actionSeverity.toString() === ActionSeverity.High).properties.find(props => props.name === 'latency').value;
    const highestLoss = lossCritical > lossHigh ? lossCritical : lossHigh;
    const highestLatency = latencyCritical > latencyHigh ? latencyCritical : latencyHigh;
    const annoationLossCritical: ChartJsAnnotation = this.addAnnotation(defaultAnnotation, lossCritical, 'black', 'Critical', "end", highestLoss + 10);
    const annoationLossHigh: ChartJsAnnotation = this.addAnnotation(defaultAnnotation, lossHigh, 'red', 'High', "end");
    annotations[KpiType.Loss] = [annoationLossCritical, annoationLossHigh];

    const annoationLatencyCritical = this.addAnnotation(defaultAnnotation, latencyCritical, 'black', 'Critical', "end", highestLatency + 50);
    const annoationLatencyHigh = this.addAnnotation(defaultAnnotation, latencyHigh, 'red', 'high', "end");
    annotations[KpiType.Latency] = [annoationLatencyCritical, annoationLatencyHigh];

    annotations[KpiType.Clients] = [];
  }

  /**
   * Generate Annotation Object
   */
  addAnnotation(defaultAnnotation: ChartJsAnnotation, value: number, color: string, content: string, labelPosition: AnnotationLabelPosition, topY?: number): ChartJsAnnotation {
    let annoation = {...defaultAnnotation, label: {...defaultAnnotation.label}};
    annoation.value = value;
    annoation.yMax = annoation.yMin = topY;
    annoation.borderColor = color;
    annoation.label.content = content;
    annoation.label.backgroundColor = color;
    annoation.label.position = labelPosition;
    annoation.label.cornerRadius = 5;
    annoation.label.height = '100%';
    return annoation;
  }

  findVenueBandwidth(tenantBandwidth: Bandwidth[], venueId: number) {
    const venueBandwidth = tenantBandwidth.find(bandwidth => bandwidth.venueId === venueId);
    if (venueBandwidth !== undefined) {
      return venueBandwidth;
    }

    return tenantBandwidth[0];
  }
}
