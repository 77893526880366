import { SingleDeviceType } from './single-device.model';

export class ChangeLog {
    time: number;
    venueId: number;
    venueName: string;
    added: AggregateLogChangeTypes;
    removed: AggregateLogChangeTypes;
    modified: AggregateLogChangeTypes;
    changes: SingleChangeLog[];

    constructor(time: number, venueId: number, venueName: string, changes: SingleChangeLog[]) {
        this.time = time;
        this.venueId = venueId;
        this.venueName = venueName;
        this.changes = changes;
        this.aggregateChanges();

    }

    aggregateChanges(): void {
        let aggregateLogChanges: AggregateLogChangesEntities = new AggregateLogChangesEntities();
        this.changes.forEach(change => {
            let formattedChangeEntity: string = change.entityClassName;
            let formattedChangeType: string = change.type.toLowerCase();
            if (Object.values(SingleDeviceType).includes(change.entityClassName as any))
                formattedChangeEntity = "Device";
            if (!aggregateLogChanges[formattedChangeType])
                aggregateLogChanges[formattedChangeType] = { Device: [], Ssid: [], Vlan: [], SwitchStack: [] };
            aggregateLogChanges[formattedChangeType][formattedChangeEntity].push(change);
        });

        this.added = aggregateLogChanges.added ? aggregateLogChanges.added : { Device: [], Ssid: [], Vlan: [], SwitchStack: [] };
        this.removed = aggregateLogChanges.removed ? aggregateLogChanges.removed : { Device: [], Ssid: [], Vlan: [], SwitchStack: [] };
        this.modified = aggregateLogChanges.modified ? aggregateLogChanges.modified : { Device: [], Ssid: [], Vlan: [], SwitchStack: [] };
    }
}

export class SingleChangeLog {
    type: ChangeType;
    time: number;
    entityId: number;
    entityName?: string;
    entityClassName: ChangedEntityType;
    integrationPointId: number;
    changes: SingleChangesLogDetails[];
}

export class SingleChangesLogDetails {
    propertyName: string;
    type: ChangeType;
    newValue?: any;
    oldValue?: any;
}

export enum ChangeType {
    ADDED = "Added",
    REMOVED = "Removed",
    MODIFIED = "Modified"
}
export enum AggregatedChangedEntitites {
    SSID = "Ssid",
    VLAN = "Vlan",
    Device = "Device",
    SwitchStack = "SwitchStack"
}
export type ChangedEntityType = AggregatedChangedEntitites | SingleDeviceType;

export class AggregateLogChangesEntities {
    added: AggregateLogChangeTypes;
    removed: AggregateLogChangeTypes;
    modified: AggregateLogChangeTypes;

    constructor() {
        this.added = null;
        this.removed = null;
        this.modified = null;
    }
}
export type AggregateLogChangeTypes = { [key in AggregatedChangedEntitites]: SingleChangeLog[] };

