import {Injectable} from '@angular/core';
import {WordsWithRules, DynamicWordingRuleType, DynamicWordingCharacters} from '../models/rules.model';
import {DynamicSentence} from '../models/dynamic-sentence.model';

@Injectable({
  providedIn: 'root'
})
export class SentenceToWordsService {

  constructor() {
  }

  buildWordsArray(dynamicSentence: DynamicSentence, dynamicWordingCharacters: DynamicWordingCharacters[]): WordsWithRules<any>[] {
    let wording: WordsWithRules<any>[] = [];
    if (dynamicSentence) {
      const sentenceAsWordsArray = dynamicSentence.sentence.split(" ");
      if (sentenceAsWordsArray && sentenceAsWordsArray.length > 0) {
        sentenceAsWordsArray.forEach((word, wordIndex) => {
          const firstChar = word[0];
          const originalLastChar = word[word.length - 1];
          const lastChar = originalLastChar === ',' ? word[word.length - 2] : originalLastChar;
          let isSpecialWord: boolean = false;
          for (let index = 0; index < dynamicWordingCharacters.length; index++) {
            const specialChars = dynamicWordingCharacters[index];
            if (firstChar == specialChars.startChar && lastChar == specialChars.endChar) {
              const cleanWord = originalLastChar === ',' ? word.substring(1, word.length - 2) : word.substring(1, word.length - 1);
              let dynamicWord = dynamicSentence.words[cleanWord];
              if (dynamicWord && dynamicWord.value) {
                if (originalLastChar === ',') {
                  dynamicWord.value = dynamicWord.value + ',';
                }
                wording.push(
                  new WordsWithRules<any>(
                    wordIndex,
                    dynamicWord.value,
                    dynamicWord.rule,
                    dynamicWord.options,
                    dynamicWord.id,
                    dynamicWord.pattern,
                    dynamicWord.errorMessage
                  ));
              }
              isSpecialWord = true;
              break;
            }
          }
          if (!isSpecialWord) {
            wording.push(
              new WordsWithRules<any>(
                wordIndex,
                word,
                {
                  type: DynamicWordingRuleType.Normal,
                  startChar: null,
                  endChar: null
                },
                null
              )
            );
          }
        });
      }
      return wording;
    }
  }

  regenerateSentence(wording: WordsWithRules<any>[]): string {
    let newSentenceAsWord: string = '';
    wording.forEach(word => {
      newSentenceAsWord += word.value + ' ';
    })
    return newSentenceAsWord;
  }
}
