<app-portlet>
    <!-- <app-portlet-sidebar class="graph-type-selectors">
        <app-graph-type-selectors (typeSelected)="graphTypeSelected($event)"
            [graphTypeSelectorsData]="graphTypeSelectorsData" [selectable]="true" class="graph-type-selectors">
        </app-graph-type-selectors>
    </app-portlet-sidebar> -->
    <app-portlet-main-content>
        <app-portlet-header [title]="trendGraphTitle" class="portlet-header" [hideDefaultTools]="true">
            <ng-container portletTools>
                <span class="time-span-label">
                    {{ 'data.DASHBOARD.SHOW' | translate }}:
                </span>
                <div class="dashboard-time-span-selection">
                    <mat-form-field>
                        <mat-select class="dashboard-selection-container" [value]="initialPeriod">
                            <mat-option [disabled]="period != Period.WEEK" *ngFor="let period of graphPeriods"
                                [value]="period">
                                {{Period.getPeriodName(period) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </app-portlet-header>
        <app-portlet-body class="portlet-body  trend-graph">
            <app-graph-trend [chartConfig]="trendLineConfig" [stepSize]="1" [beginAtZero]="true">
            </app-graph-trend>
        </app-portlet-body>
    </app-portlet-main-content>
</app-portlet>
