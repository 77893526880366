import {GraphDonutConfig} from '../models/graph-donut-config';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import {LoggerService, Logger} from '../../../services/logger.service';
import {DonutTooltip} from '../chartjs-tooltips/donut-tooltip';
import {Chart, ChartOptions} from 'chart.js';
import {NgChanges} from "../../../extend-angular-classes/on-changes";
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-graph-donut',
  templateUrl: './graph-donut.component.html',
  styleUrls: ['./graph-donut.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GraphDonutComponent implements OnInit {
  @Input() isLabelsDisplay: boolean = false;
  @Input() height: number = 200;
  @Input() width: number = 200;
  @Input() noDataText: string;
  @Input() isResponsive: boolean = false;
  @Input() maintainAspectRatio: boolean = true;
  @ViewChild('graphDonut', {static: true}) graphElm: any;
  @ViewChild("graphLegend", {static: true}) legendEl: ElementRef;
  @Input('graphConfig') graphConfig: GraphDonutConfig;
  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService, private cdr: ChangeDetectorRef) {
    this.logger = this.loggerFactory.getLogger("GraphDonutComponent");
  }

  issuesChart: any;
  total: number = 0;
  /**
   * The donut tooltip instance that will be used for genereting a custom tooltip
   */
  donutTooltip: DonutTooltip = new DonutTooltip(200);


  ngOnInit() {
  }

  ngOnChanges(changes: NgChanges<GraphDonutComponent>) {
    this.logger.debug("changes found %o ", changes)
    if (this.graphConfig) {
      this.logger.debug("ngOnChanges called");
      this.drawGraph()
    } else {
      if (this.issuesChart)
        this.issuesChart.destroy();
      this.total = 0;
    }
  }

  drawGraph() {
    if (this.issuesChart)
      this.issuesChart.destroy();
    let data = [], colors = [], iconsStyles = [], labels = [], displayLabels = [], tooltipText = [];
    this.graphConfig.datasets.forEach((config) => {
      data.push(config.data);
      colors.push(config.backgroundColor);
      iconsStyles.push(config.iconCSSClass);
      labels.push(config.label);
      displayLabels.push(config.displayLabel)
      tooltipText.push(config.tooltipText)
    });
    this.total = 0;
    for (let i = 0; i < data.length; i++) {
      this.total += data[i];
    }

    let datasets = [{
      borderWidth: 0,
      data,
      backgroundColor: colors,
      iconsStyles,
      tooltipText
    }];
    let donutData = {
      labels,
      displayLabels,
      datasets
    }
    this.logger.debug("datasets : %o", donutData);
    this.issuesChart = new Chart(this.graphElm.nativeElement, {
      type: 'doughnut',
      data: donutData,
      plugins: [ChartDataLabels],
      options: {
        cutout: '87.5%',
        // NOTE: we do not let the chart to resize itself or it will take up the whole portal
        responsive: this.isResponsive,
        maintainAspectRatio: this.maintainAspectRatio,
        plugins: {
          legend: {
            display: false
          },
          datalabels: this.isLabelsDisplay && donutData.labels && donutData.labels.length > 0 ? {
            formatter: function (value, context) {
              return (context.chart.config.data as any).displayLabels[context.dataIndex];
            },
            color: '#000000',
            anchor: 'start',
            align: 'start',
            textAlign: 'start',
            display: 'auto',
            clamp: false,
            padding: 8
          } : null,
          tooltip: {
            enabled: false,
            mode: 'point',
            position: 'nearest',
            external: this.donutTooltip.customDonutTooltips,
            callbacks: {
              label: function (context) {
                return `${(context.chart.data.datasets[0] as any).tooltipText[context.dataIndex]}`;
              },
            },
          },
        }
      } as ChartOptions
    });
    if (!this.isLabelsDisplay)
      this.legendEl.nativeElement.innerHTML = this.generateLegend();
    //Without the assignment of the new Chart Object the tooltip will not be drawn
    this.donutTooltip.donut = this.issuesChart;
  }

  ngAfterViewInit() {
    if (this.graphConfig && !this.issuesChart) {
      this.drawGraph();
    }
  }

  ngOnDestroy() {
    Chart.unregister({...ChartDataLabels});
  }

  private generateLegend() {
    if (!this.isLabelsDisplay) {
      let text = [];
      text.push('<ul class="issues-donut-legend-list">');
      let datasets = this.issuesChart.data.datasets[0];
      let percents = [];
      let percentsTotal = 0;
      for (var i = 0; i < datasets.data.length; i++) {
        percents[i] = Math.floor((datasets.data[i] / this.total) * 100);
        percentsTotal += percents[i];
      }
      // If the math.floor breaks the 100% sum fill the gap
      percents[0] += 100 - percentsTotal;
      for (let i = 0; i < datasets.data.length; i++) {
        text.push(`<li class="issues-donut-legend-item"><div class="${datasets.iconsStyles[i]}"></div>`);
        if (this.issuesChart.data.labels[i]) {
          text.push(`${this.issuesChart.data.labels[i]} ${percents[i]}%`);
        }
        text.push('</li>');
      }
      text.push('</ul>');
      this.cdr.detectChanges();
      return text.join("");
    }
  }
}
