import {Component, Input, ViewChild, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {ActiveElement, Chart, ChartEvent, PointElement} from 'chart.js';
import {SparklineConfig, SparklineMarker, SparklineThemeConfig} from '../models/sparkline-config';
import {SparklineConfigService} from '../services/sparkline-config.service';
import {SparklineService} from './sparkline.service';
import {SparklineTooltip} from '../chartjs-tooltips/sparkline-tooltip';
import 'chartjs-adapter-moment';


@Component({
  selector: 'app-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.scss']
})
export class SparklineComponent {
  @Input() sparklineConfig: SparklineConfig;
  @Input() marker: SparklineMarker;
  @Input() height: number;
  @Input() width: number;
  @Input() stacked: boolean = false;
  @Input() showAxis: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() maxTicksLimitX: number = 100;
  @Input() maxTicksLimitY: number = 100;
  @Input() showLegend: boolean = false;
  /**
   * It true, the sparkline will only show point on the index that matched the marker index
   */
  @Input() showOnlyMarkerPoint: boolean = true;
  @ViewChild('sparkeline', {static: true}) sparkelineElm: any;
  /**
   * Chart js Object
   */
  sparkline: any;
  /**
   * The tooltip text
   */
  tooltipText: string;
  /**
   * The hovered element inside the dataset
   */
  hoveredElementInDataset: number;
  /**
   * The Hovered Element index
   */
  hoveredElementIndex: number;

  sparklineTooltip: SparklineTooltip = new SparklineTooltip();

  total: number = 0;


  private _hoverElementPosition: { left: number, top: number };

  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService,
              private sparkLineConfigService: SparklineConfigService,
              private sparklineService: SparklineService,
              private cdr: ChangeDetectorRef,
  ) {
    this.logger = this.loggerFactory.getLogger("SparklineComponent");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sparkline)
      this.loadGraphWithData();
  }

  ngAfterViewInit() {
    this.loadGraphWithData();
  }

  loadGraphWithData() {
    if (this.sparkelineElm && this.sparklineConfig && this.sparklineConfig.datasets.length > 0) {
      this.drawGraph();
    }
  }

  drawGraph() {
    this.logger.debug("sparklineConfig", this.sparklineConfig);
    if (this.sparkline)
      this.sparkline.destroy();

    let thisClass = this;
    let sparkLineFinalData: SparklineConfig = this.sparkLineConfigService.addGardientBackground(this.sparklineConfig, this.sparkelineElm, this.height, this.stacked);
    if (this.showOnlyMarkerPoint) {
      sparkLineFinalData = this.sparkLineConfigService.removePointsOtherThanMarker(sparkLineFinalData, this.markerElementIndex);
    }
    this.logger.debug("sparkLine Final Data", sparkLineFinalData);
    this.sparkline = new Chart(this.sparkelineElm.nativeElement, {
      type: 'line',
      data: sparkLineFinalData,
      options: {
        plugins: {
          legend: {
            display: thisClass.showLegend,
            labels: {
              // fontColor: this.isShowLegendToggleMode == false ? '#ffffff' : '#6a707e',
              // show the legend 'icon' in the same style as the point under cursor
              usePointStyle: true,
              // the size of the legend
              boxWidth: 4
            },
            position: "top",
            align: "end"
          },
          tooltip: {
            mode: thisClass.stacked ? 'index' : 'point',
            enabled: false,
            position: 'nearest',
            external: thisClass.showTooltip ? this.sparklineTooltip.customSparklineTooltips : null,
            callbacks: {
              label: (context) => {
                let itemData: SparklineThemeConfig = (thisClass.sparkline.datasets[context.datasetIndex] as SparklineThemeConfig);
                if (itemData.data.length > 0 && itemData.data[context.dataIndex]) {
                  thisClass.logger.debug("context", context);
                  thisClass.logger.debug("itemData", itemData);
                  thisClass.logger.debug("chart", thisClass.sparkline);
                  return `${itemData.tooltipText[context.dataIndex]}`
                }
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 2,
            bottom: 5
          }
        },
        hover: {
          mode: 'index',
          intersect: true,
        },
        onHover: function (event: ChartEvent, elements: ActiveElement[], chart: Chart<any>) {
          if (elements.length > 0 && !thisClass.showTooltip) {
            //Find the element inside the set
            thisClass.hoveredElementIndex = elements[0].index;
            thisClass.hoveredElementInDataset = (elements[0].element as PointElement).parsed.y;
            thisClass.setTooltipText();
            thisClass.setTooltipPosition(elements);
          }
        },
        scales: {
          x: {
            type: 'time', // MANDATORY TO SHOW YOUR POINTS! (THIS IS THE IMPORTANT BIT)
            time: {
              unit: thisClass.timeUnit,
              displayFormats: {
                hour: 'DD-MMM-YYYY'
              }
            },
            ticks: {
              maxRotation: 0,
            },
            display: thisClass.showAxis,
            stacked: thisClass.stacked,
          },
          y: {
            display: thisClass.showAxis,
            stacked: thisClass.stacked,
            beginAtZero: true,
            ticks:
              {
                maxTicksLimit: thisClass.maxTicksLimitY
              }
          }
        },
      }
    })
    if (this.sparkline) {
      this.logger.debug("sparkline Obj", this.sparkline);
      this.sparklineTooltip.chartObj = this.sparkline;
    }
  }

  /**
   * Det the tooltip position
   * The method calculate the current index hoverd item point
   * and set the _hoverElementPosition that will determine the div position
   * that hovered on will trigger the meterial tooltip
   */
  setTooltipPosition(element: any) {
    const point = {x: element[0].element.x, y: element[0].element.y};
    this._hoverElementPosition = {
      left: point.x + 13,
      top: point.y - 8
    }
  }

  /**
   * Set the tooltip text
   * If the date on the current hovered element is matching the marker date
   * the text will be the marker
   * Else
   * The text will be the current point value
   */
  setTooltipText() {
    if (this.hoveredElementIndex == this.markerElementIndex && this.marker) {
      this.tooltipText = this.marker.text;
    } else {
      this.tooltipText = this.hoveredElementInDataset.toString();
    }
  }

  /**
   * Return _hoverElementPosition
   */
  get tooltipPosition(): { left: number, top: number } {
    return this._hoverElementPosition;
  }

  /**
   * Return the index of the time point that matched to the marker date
   */
  get markerElementIndex() {
    return this.sparklineService.findMarkerIndex(this.sparklineConfig.datasets[0].data, this.marker);
  }

  /**
   * Return the index of the time point that matched to the marker date
   * Can add more time types. @see https://www.chartjs.org/docs/latest/axes/cartesian/time.html?h=unit
   */
  get timeUnit(): ("hour" | "day" | "week" | "month") {
    return this.sparklineService.calculateTimeUnit(this.sparklineConfig.datasets[0].data);
  }
}
