<div class="file-drop-area">
  <span class="fake-btn">Choose file</span>
  <!-- <span class="file-msg">{{file ? file.name : 'or drag and drop file here' }}</span> -->
  <span *ngIf="isNoFirebaseName" class="file-msg">{{file ? file.name : 'or drag and drop file here' }}</span>
  <span *ngIf="!isNoFirebaseName" class="file-msg">{{fileName}}</span>
  <input class="file-input" type="file" [required]="required">
</div>
<app-progress-bar *ngIf="progress" [progress]="progress"></app-progress-bar>

<mat-error *ngIf="errorText">
  <strong>{{errorText}}</strong>

</mat-error>
