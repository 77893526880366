import {Injectable} from '@angular/core';
import {
  ActionStatus,
  AnomalyCategoryWithStatus,
  AnomalyInsightsTrend,
  BasicActionDTO,
  DataGranularity,
  DateValue,
  TimeSeriesWrapper
} from 'src/app/shared/models/actions.model';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {TranslateService} from '@ngx-translate/core';
import {AnomalyCategory} from 'src/app/shared/models/anomalies.model';
import {ColorsUtils} from "../../../../../global-utils/colors-utils";

@Injectable({
  providedIn: 'root'
})
export class InsightsAnomaliesBarGeneratorService {
  constructor(
    private themeService: ThemeService,
    private translate: TranslateService,
    private dateConvertor: TimeManagerService) {
  }

  generateBarDataset(trend: TimeSeriesWrapper<AnomalyInsightsTrend>, totalActionsNumber?: number): VerticalBarData {
    let data: VerticalBarData = {
      datasets: [],
      labels: this.getFormattedDates(trend.dataGranularity, trend.data.Performance.map(datum => datum.date))
    };
    for (let [key, values] of Object.entries(trend.data)) {
      data.datasets.push({
        data: values.map(value => value.value.length),
        backgroundColor: values.map(value => ColorsUtils.hexToRgb(this.themeService.ACTIONS_CATEGORY_COLORS[key])),
        label: key,
        tooltipText: this.generateTooltipText(values, key),
        tooltipTitle: [''],
        barThickness: trend.data.Performance.length > 20 ? 8 : 10
      })
    }
    // if (totalActionsNumber > 0) {
    //   data.datasets.push({
    //     data: null,
    //     backgroundColor: Object.values(trend.data).map(value => ColorsUtils.hexToRgb(this.themeService.SEVERITY_COLORS[1])),
    //     label: `Open`,
    //     tooltipText: null,
    //     tooltipTitle: null,
    //     barThickness: 0,
    //     isMockedDataset: true
    //   })
    // }
    return data;
  }

  /**
   * Generate tooltip text array for each date
   * The method initiate the categoriesWithStatus object and than parse it into a string
   */
  generateTooltipText(values: DateValue<BasicActionDTO[]>[], key: string): string[] {
    return values.map(dateValues => {
      if (dateValues.value.length > 0) {
        let categoriesWithStatus: AnomalyCategoryWithStatus;
        dateValues.value.forEach(val => {
          if (categoriesWithStatus) {
            if (!categoriesWithStatus[val.anomalyCategory]) {
              categoriesWithStatus[val.anomalyCategory] = {[val.actionStatus]: 1};
            } else if (categoriesWithStatus[val.anomalyCategory] && categoriesWithStatus[val.anomalyCategory][val.actionStatus]) {
              categoriesWithStatus[val.anomalyCategory][val.actionStatus]++;
            } else {
              categoriesWithStatus[val.anomalyCategory][val.actionStatus] = 1;
            }
          } else {
            categoriesWithStatus = {[val.anomalyCategory]: {[val.actionStatus]: 1}}
          }
        });
        let tooltipText: string = key;
        Object.keys(categoriesWithStatus).forEach(actionKey => {
          tooltipText += `@${AnomalyCategory.anomalyAsString(actionKey)}: `;
          Object.keys(categoriesWithStatus[actionKey]).forEach(statusKey => {
            /**
             * Add @ in favour of tooltip display
             */
            tooltipText += `${categoriesWithStatus[actionKey][statusKey]}
            ${this.translate.instant(ActionStatus.getActionStatusName(statusKey as ActionStatus)).toLowerCase()} `;
          })
        });
        return tooltipText;
      }
    })
  }

  /**
   * Return the dates array.
   * For display reasons, only part of the dates will be displayed
   */
  getFormattedDates(dataGranularity: DataGranularity, dates: number[]): string[] {
    let format: string = dataGranularity == DataGranularity.Daily ? 'MMM-DD' : 'HH:mm';
    return dates.map((date, index) => {
      return this.dateConvertor.dateByFormat(date, format);
    })
  }

  /**
   * Generate sum above each bar label
   * Content: The open actions number for each day
   */
  private generateAboveBarLabel(values: Array<DateValue<Array<BasicActionDTO>>>) {
    let aboveBarLabels: number[] = [];
    values.forEach(val => {
      if (val.value.length > 0) {
        const openActionsNum = val.value.filter(action => action.actionStatus === ActionStatus.Open).length;
        aboveBarLabels.push(openActionsNum);
      } else {
        aboveBarLabels.push(undefined);
      }
    })
    return aboveBarLabels;
  }

  /**
   * Return the total open actions number for the current trend object
   */
  getTotalActionsNumber(trend: TimeSeriesWrapper<AnomalyInsightsTrend>) {
    let totalActions: number = 0;
    for (let [key, values] of Object.entries(trend.data)) {
      values.forEach(val => {
        val.value.forEach(action => {
          if (action.actionStatus === ActionStatus.Open) {
            totalActions += 1;
          }
        })
      })
    }
    return totalActions;
  }

  /**
   * Return the highest actions number per timespan
   */
  getHighestActionsNumPerDay(trend: TimeSeriesWrapper<AnomalyInsightsTrend>) {
    let highestNumber: number = 0;
    for (let [key, values] of Object.entries(trend.data)) {
      values.forEach(val => {
        if (val.value.length > highestNumber) {
          highestNumber = val.value.length;
        }
      })
    }
    return highestNumber;
  }
}
