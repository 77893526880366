import {Pipe, PipeTransform} from '@angular/core';
import {stringToCamelcase} from '../operators/string-opertators';

@Pipe({
  name: 'camelToUpper'
})
export class CamelToUpperPipe implements PipeTransform {

  transform(key: string, ...args: any[]): any {
    return stringToCamelcase(key);
  }

}
