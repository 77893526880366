import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { FabricStatus } from '../../models/fabrics.model';

@Directive({
  selector: '[styleProperties]'
})
export class StylePropertiesDirective {
  @Input() styleProperties: string;
  @Input() key: string;
  element: ElementRef;

  constructor(el: ElementRef, private renderer: Renderer2) {
    this.element = el;
  }
  /**
 * This directive was created in order to change the color of properties elements that their value is incomplete
 */
  ngAfterViewInit() {
    if (this.styleProperties == FabricStatus.INCOMPLETE) {
      this.renderer.addClass(this.element.nativeElement, "fabrics-prop-state-incomplete");
    }

    if (this.styleProperties == FabricStatus.NoWwwConnectivity) {
      this.renderer.addClass(this.element.nativeElement, "fabrics-prop-state-no-www-connectivity");
      this.element.nativeElement.innerText = "No Internet Connectivity"
    }
    if (this.styleProperties && this.styleProperties.toString().toLowerCase() == "ignore") {
      this.renderer.addClass(this.element.nativeElement, "policy-ignore");
    }
    if (this.key && this.key.toLowerCase() === "allowedurlpatterns" ||
      this.key && this.key.toLowerCase() === "blockedurlpatterns" ||
      this.key && this.key.toLowerCase() === "blockedurlcategories") {
      this.renderer.addClass(this.element.nativeElement, "policy-underline");
    }
  }

}
