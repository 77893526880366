import { Injectable } from '@angular/core';
import { SingleDeviceType } from '../../../models/single-device.model';
import { ConnectedClientType } from '../../../models/clients.model';
import { EditPropertiesService } from '../../properties/services/edit-properties.service';
import { D3TreeEventsService } from './d3-tree-events.service';
import { FormAction } from '../../../modals/models/form-actions.model';
import { GenericDevice, SingleD3Node } from '../../../models/topology';
import { take } from 'rxjs/operators';
import { isClient, isMultiClient } from '../operators/topology-operators';
import { TopologyConfiguration } from '../models/topology-configuration';
import { CloudNodeType } from '../../../models/cloud-network.model';
import { TopologyNodeType } from '../models/TopologyTypes.model';
import { TopologyBuilderService } from "./topology-builder.service";

@Injectable({
  providedIn: 'root'
})
export class D3NodeService {

  constructor(
    private editPropertiesService: EditPropertiesService,
    private topologyBuilderService: TopologyBuilderService,
    private d3TreeEventsService: D3TreeEventsService) {
  }

  /**
   * Handle the node click event.
   * The method Check 3 things:
   * 1. If the properties section is on edit mode.
   * 2. If the clicked element is not client
   * 3. If the topology is not one venues fabrics screen
   * if those conditions are passed, the method allows the node click flow continue
   */
  onNodeClick(node: SingleD3Node<GenericDevice<any>>, topologyConfigruation: TopologyConfiguration) {
    if (!isClient(node) && !isMultiClient(node) && !topologyConfigruation.isFabricsVenue) {
      this.editPropertiesService.notifyIsEditModeObservable$.pipe(take(1)).subscribe(mode => {
        if (mode) {
          this.editPropertiesService.openLeaveFormDialog().afterClosed().subscribe(result => {
            if (result == FormAction.DELETE) {
              this.d3TreeEventsService.onNodeClick(node);
              this.editPropertiesService.isEditMode(false);
            }
          })
        } else
          this.d3TreeEventsService.onNodeClick(node);
      })
    }
  }

  getInitialIconPath(type: TopologyNodeType) {
    switch (type) {
      case SingleDeviceType.WWW:
        return '/assets/media/netop/topology/cloud.svg';
      case SingleDeviceType.Router:
      case CloudNodeType.CloudRouter:
        return '/assets/media/netop/topology/router.svg';
      case SingleDeviceType.Switch:
        return '/assets/media/netop/topology/switch.svg';
      case SingleDeviceType.SwitchStack:
        return '/assets/media/netop/topology/switch-stack.svg';
      case SingleDeviceType.Camera:
        return '/assets/media/netop/topology/camera.svg';
      case CloudNodeType.Vpc:
        return 'assets/media/netop/topology/cloud-vpc.svg';
      case CloudNodeType.Nat:
        return 'assets/media/netop/topology/cloude-nat-gateway.svg';
      case SingleDeviceType.SDWAN:
        return '/assets/media/netop/topology/router.svg';
      case SingleDeviceType.CellularGateway:
        return '/assets/media/netop/topology/meraki-cellular.svg';
      case SingleDeviceType.Firewall:
        return '/assets/media/netop/topology/fire-wall.svg';
      case SingleDeviceType.AccessPoint:
        return '/assets/media/netop/topology/access-point.svg';
      case SingleDeviceType.ExternalRouter:
        return '/assets/media/netop/topology/unidentified.svg';
      case SingleDeviceType.UnidentifiedDevice:
        return '/assets/media/netop/topology/unidentified.svg';
      case SingleDeviceType.Sase:
        return '/assets/media/netop/topology/cloud-firewall.svg';
      case ConnectedClientType.LAPTOP:
        return '/assets/media/netop/topology/laptop.svg';
      case ConnectedClientType.MultiLAPTOP:
        return '/assets/media/netop/topology/multi-laptop.svg';
      case ConnectedClientType.MOBILE:
        return '/assets/media/netop/topology/mobile.svg';
      case ConnectedClientType.MultiMOBILE:
        return '/assets/media/netop/topology/multi-mobile.svg';
      case ConnectedClientType.UNKNOWN:
        return '/assets/media/netop/topology/question-mark.svg';
      case ConnectedClientType.MultiUNKNOWN:
        return '/assets/media/netop/topology/question-mark.svg';
      default:
        break;
    }
  }

  getClickedIconPath(type: TopologyNodeType) {
    switch (type) {
      case SingleDeviceType.WWW:
        return '/assets/media/netop/topology/clicked-cloud.svg';
      case SingleDeviceType.Router:
      case CloudNodeType.CloudRouter:
        return '/assets/media/netop/topology/clicked-router.svg';
      case SingleDeviceType.Camera:
        return '/assets/media/netop/topology/clicked-camera.svg';
      case SingleDeviceType.Switch:
        return '/assets/media/netop/topology/clicked-switch.svg';
      case SingleDeviceType.SwitchStack:
        return '/assets/media/netop/topology/switch-stack.svg';
      case CloudNodeType.Vpc:
        return 'assets/media/netop/topology/clicked-cloud-vpc.svg';
      case CloudNodeType.Nat:
        return 'assets/media/netop/topology/clicked-cloud-nat-gateway.svg';
      case SingleDeviceType.SDWAN:
        return '/assets/media/netop/topology/clicked-router.svg';
      case SingleDeviceType.Firewall:
        return '/assets/media/netop/topology/clicked-fire-wall.svg';
      case SingleDeviceType.AccessPoint:
        return '/assets/media/netop/topology/clicked-access-point.svg';
      case SingleDeviceType.ExternalRouter:
        return '/assets/media/netop/topology/clicked-unidentified.svg';
      case SingleDeviceType.UnidentifiedDevice:
        return '/assets/media/netop/topology/clicked-unidentified.svg';
      case SingleDeviceType.Sase:
        return '/assets/media/netop/topology/clicked-cloud-firewall.svg';
      case SingleDeviceType.CellularGateway:
        return '/assets/media/netop/topology/meraki-cellular.svg';
      default:
        break;
    }
  }

  /**
   * Calculate the node size based on max children number and tree depth
   */
  getNodeSize(maxChildrenNumber: number, isSqueezable: boolean = false) {
    if (maxChildrenNumber < this.topologyBuilderService.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY || !maxChildrenNumber) {
      return 20;
    }
    if (isSqueezable) {
      return 6;
    }
    return 15;
  }

  /**
   * Get the icon path for node right-bottom area
   * @param node Each of the topology nodes
   * @param isClicked Is the current node is selected by user
   */
  getRightBottomIcon(node: SingleD3Node<GenericDevice<any>>, isClicked: boolean): string {
    if (node && node.data && node.data.type) {
      switch (true) {
        case node.data.type == SingleDeviceType.Firewall && node.data.originalData?.device?.properties && node.data.originalData.device.properties['is_ha_master'] == 1:
          return '/assets/media/netop/topology/backup-fw.svg';
        case node.data.type == CloudNodeType.CloudRouter: {
          return isClicked ? '/assets/media/netop/topology/clicked-small-cloud.svg' : '/assets/media/netop/topology/small-cloud.svg';
        }
        default:
          break;
      }
    }
  }

  getSecondaryIconPath(type: TopologyNodeType): string {
    return type && type === CloudNodeType.CloudRouter ? '/assets/media/netop/topology/clicked-small-cloud.svg' : null;
  }

  getTopLeftIcon(node: SingleD3Node<GenericDevice<any>>, nodeClicked: boolean) {
    return '/assets/media/netop/collapse.svg';
  }
}
