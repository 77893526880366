<ng-container *ngIf="isLeanMode" [ngTemplateOutlet]="insightAnomaliesLean"></ng-container>
<ng-container *ngIf="!isLeanMode" [ngTemplateOutlet]="insightAnomaliesDetailed"></ng-container>

<ng-template #insightAnomaliesLean>
</ng-template>


<ng-template #insightAnomaliesDetailed>
  <app-portlet>
    <app-portlet-header [title]="'Anomalies Count'" [hideDefaultTools]="true">
      <ng-container *ngIf="anomaliesTitle" nextToTitle>
        <div class="ins-anom-open-action-num">{{anomaliesTitle}}</div>
      </ng-container>
      <ng-container portletTools>
        <app-period-time-selection class="period-time-selection-container" [formFieldCss]="'ml-0 mr-4'"
                                   [selectedTimePeriod]="selectedTimePeriod" [daysBetweenDatesLimits]="30"
                                   [maxDateLimit]="maxDateLimit" [fromDate]="selectedTimeDates.start"
                                   [toDate]="selectedTimeDates.end"
                                   (timeSelected)="onTimeSelectionChanged($event)"
                                   [timePeriodArray]="timePeriodArray"
                                   [fromYesterday]="false" [emitOnInit]="true" [allowSameDaySelection]="true">
        </app-period-time-selection>
      </ng-container>
    </app-portlet-header>
    <app-portlet-body>
      <app-insights-anomalies-bar [height]="anomalyChartHeight"
                                  (barSelected)="onBarDateClicked($event)"
                                  (trendGenerated)="onTrendGenerated($event)"
                                  [dates]="dates"
                                  [isObscurityMode]="isObscurityMode"
                                  (totalActionsCalculated)="totalOpenActions = $event">
      </app-insights-anomalies-bar>
      <app-anomalies [sizes]="anomaliesInsightsSizes" [datesWithTrend]="datesWithTrend"
                     (anomaliesSummaryDetected)="anomalies = $event" [displayDate]="displayDate"
                     [isObscurityMode]="isObscurityMode">
      </app-anomalies>
    </app-portlet-body>
  </app-portlet>
</ng-template>
