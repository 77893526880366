export enum ERRORS_NAME {
  REQUIRED = 'required',
  TO_SHORT = 'toShort',
  EMAIL = 'email',
  FORBIDDEN_CHARS = 'forbiddenCharacter',
  WRONG_PHONE_NUMBER = 'wrongPhoneNumber',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minlength',
  ONLY_STRING = 'onlyStrings',
  ONLY_NUMBER = 'onlyNumbers',
  NOT_IMAGE_TYPE = 'notImageType',
  PATTERN = 'pattern',
  IPV4 = 'notIpv4',
  CIDR = 'notCIDRNotation',
  MAC_ADDRESS = 'notMacAddress',
  PORT = 'notPortPattern',
  COMMA_SEPARATED_URLS = 'commaSeparatedUrls'
}
